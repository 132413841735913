import { WeatherLayer } from '../../models';

export const WeatherLayerOptions: Array<WeatherLayer> = [
  {
    shape_name: 'Wind Speed',
    layer_option: {
      layer_value: 'wind_new',
      legendMin: '0 m/s',
      legendAvg: '100 m/s',
      legendMax: '200 m/s',
      legendTitle: 'Wind Speed',
      scaleClass: 'weather-wind',
    },
  },
  {
    shape_name: 'Temperature',
    layer_option: {
      layer_value: 'temp_new',
      legendMin: '-40 deg F',
      legendAvg: '32 deg F',
      legendMax: '104 deg F',
      legendTitle: 'Temperature',
      scaleClass: 'weather-temperature',
    },
  },
  {
    shape_name: 'Pressure',
    layer_option: {
      layer_value: 'pressure_new',
      legendMin: '949.92 hPa',
      legendAvg: '1013.25 hPa',
      legendMax: '1070.63 hPa',
      legendTitle: 'Pressure',
      scaleClass: 'weather-pressure',
    },
  },
  {
    shape_name: 'Percipitation',
    layer_option: {
      layer_value: 'precipitation_new',
      legendMin: '0 mm',
      legendAvg: '100 mm',
      legendMax: '200 mm',
      legendTitle: 'Snow',
      scaleClass: 'weather-percepitation',
    },
  },
  {
    shape_name: 'Clouds',
    layer_option: {
      layer_value: 'clouds_new',
      legendMin: '0 %',
      legendAvg: '50 %',
      legendMax: '100 %',
      legendTitle: 'Cloud',
      scaleClass: 'weather-cloud',
    },
  },
];
