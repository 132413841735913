import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './modify-trip-details.state';

export const selectModifyTripDetailsState = createFeatureSelector<State>(StateKey);

export const lastUpdate = createSelector(selectModifyTripDetailsState, (state: State) => state.lastUpdate);

export const selectedActivities = createSelector(
  selectModifyTripDetailsState,
  (state: State) => state.selectedActivities
);
