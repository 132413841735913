import { Injectable } from '@angular/core';
import {
  State,
  MemoizedSelector,
  DefaultProjectorFn,
  Store,
  ReducerManager,
  ActionsSubject,
  StateObservable,
} from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PndStore<TState> extends Store<TState> {
  constructor(
    private pndState: State<TState>,
    state$: StateObservable,
    actionsObserver: ActionsSubject,
    reducerManager: ReducerManager
  ) {
    super(state$, actionsObserver, reducerManager);
  }

  /**
   * Returns the entire state for that point in time
   */
  snapshot(): TState {
    return _cloneDeep<TState>(this.pndState.getValue());
  }

  /**
   * Returns a sliced portion of the store from that point in time by applying the given selector
   * @param selector
   */
  selectSnapshot<TResult>(selector: MemoizedSelector<TState, TResult, DefaultProjectorFn<TResult>>): TResult {
    return _cloneDeep<TResult>(selector(this.pndState.getValue()));
  }
}
