export enum ContextMenuItemId {
  COLORS,
  PICKUP_DETAILS,
  PIN_AS_FIRST,
  PIN_AS_LAST,
  UNASSIGN_STOP,
  REASSIGN_STOP,
  SHOW_DETAILS,
  ZOOM,
  EDIT_GEOLOCATION,
  CUSTOMER_PROFILE,
  REASSIGN,
  UNASSIGN,
  ASSIGN_STOP,
  SHOW_BREADCRUMBS,
  HIDE_BREADCRUMBS,
  PIN_LAST_TO_ROUTE,
  REMOVE_FROM_ROUTE,
  SHOW_DRIVER_ACTIVE_TRIP,
  REFRESH_LOCATION,
  START_WATCH,
  STOP_WATCH,
  OPEN_TRIP_DETAILS,
  OPEN_MESSAGING,
  RESCHEDULE,
  RESCHEDULE_OR_CANCEL,
  CANCEL,
}
