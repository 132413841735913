<mat-card class="shadowed">
  <div class="pnd-metrics-item-value">{{ metric.displayValue }}</div>
  <div class="pnd-metrics-item-description">
    <xpo-icon
      *ngIf="isSpecialServiceMetric(metric.valueKeyCd)"
      class="specialServices-svg"
      svgIconName="{{ getSpecialServiceIcon(metric.valueKeyCd) }}"
    ></xpo-icon>
    <span>{{ metric.description }}</span>
  </div>
  <div class="pnd-metrics-item-variance" *ngIf="metric.variance && metric.varianceDesc">
    <ng-container *ngIf="!varianceExclusionList.includes(metric.valueKeyCd)">
      <mat-icon
        *ngIf="!isSpecialServiceMetric(metric.valueKeyCd)"
        class="pnd-metrics-item-variance-icon"
        [ngClass]="getVarianceClassColor(metric.variance)"
      >
        {{ getVarianceIcon(metric.variance) }}
      </mat-icon>
      <div class="pnd-metrics-item-variance-legend">{{ metric.varianceDesc }}</div>
    </ng-container>
  </div>
</mat-card>
