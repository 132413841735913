<div class="pnd-TimeInputFilter-globalDateSelected">
  {{ globalPlanDate$ | async | date: 'MM/dd' }}
</div>
<mat-form-field class="pnd-TimeInputFilter-formField ngx-FormField--inline" floatLabel="never">
  <input
      autocomplete="off"
      matInput
      type="text"
      class="pnd-TimeInputFilter-input"
      [errorStateMatcher]="configuration.matcher"
      [title]="configuration.title"
      [placeholder]="configuration.label"
      [formControl]="searchFormControl" />
  <mat-error>{{ searchFormControl?.hasError('pattern') ? 'Invalid' : '' }}</mat-error>
</mat-form-field>
<mat-icon *ngIf="!searchFormControl?.hasError('pattern')" matSuffix class="pnd-TimeInputFilter--icon-success">
  check_circle</mat-icon>
<mat-icon *ngIf="searchFormControl?.hasError('pattern')" matSuffix class="pnd-TimeInputFilter--icon-error">info
</mat-icon>
