import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'app-alarm-header-renderer',
  template: '<mat-icon>alarm</mat-icon>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmHeaderRendererComponent implements IHeaderAngularComp {
  constructor() {}

  agInit(params: IHeaderParams): void {}

  refresh(params: IHeaderParams): boolean {
    this.agInit(params);
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
