<as-split
    [disabled]="(leftPanelConfig$ | async)?.lockSize || (rightPanelConfig$ | async)?.lockSize"
    (dragEnd)="handleDragEnd($event)"
    [direction]="(leftPanelConfig$ | async)?.direction || (rightPanelConfig$ | async)?.direction"
    [useTransition]="(leftPanelConfig$ | async)?.useTransition || (rightPanelConfig$ | async)?.useTransition"
    [unit]="(leftPanelConfig$ | async)?.unit || (rightPanelConfig$ | async)?.unit"
    class="SplitPanel">
  <as-split-area
      [class.SplitPanel-drawerContent--left]="!!(leftPanelConfig$ | async)"
      class="SplitPanel-drawerContent"
      [visible]="!!(leftPanelConfig$ | async)"
      [size]="(leftPanelConfig$ | async)?.size"
      [minSize]="(leftPanelConfig$ | async)?.minSize"
      [maxSize]="(leftPanelConfig$ | async)?.maxSize"
      [lockSize]="
      (leftPanelConfig$ | async)?.minSize === (leftPanelConfig$ | async)?.maxSize ||
      (leftPanelConfig$ | async)?.size === 0
    "
      [style.padding.px]="(leftPanelConfig$ | async)?.style?.paddingPx">
    <div
        *ngIf="(leftPanelConfig$ | async) && !(leftPanelConfig$ | async)?.hideDefaultPanelHeader"
        class="SplitPanel-drawerContent-header">
      <h1 class="SplitPanel-drawerContent-title">{{ (leftPanelConfig$ | async)?.title }}</h1>
      <button
          data-test="splitPanelCloseLeft"
          class="SplitPanel-drawerContent-btn"
          (click)="handleCloseLeftDrawerClicked()"
          mat-icon-button>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <ng-template view-ref></ng-template>
  </as-split-area>
  <as-split-area
      [size]="100 - ((rightPanelConfig$ | async)?.size || 0) - ((leftPanelConfig$ | async)?.size || 0)"
      class="SplitPanel-mainContent">
    <ng-content></ng-content>
  </as-split-area>
  <as-split-area
      [class.SplitPanel-drawerContent--right]="!!(rightPanelConfig$ | async)"
      [class.SplitPanel-drawerContent--left]="(rightPanelConfig$ | async)?.direction === SplitPanelDirection.Vertical"
      class="SplitPanel-drawerContent"
      [visible]="!!(rightPanelConfig$ | async)"
      [size]="(rightPanelConfig$ | async)?.size"
      [minSize]="(rightPanelConfig$ | async)?.minSize"
      [maxSize]="(rightPanelConfig$ | async)?.maxSize"
      [lockSize]="
      (rightPanelConfig$ | async)?.minSize === (rightPanelConfig$ | async)?.maxSize ||
      (rightPanelConfig$ | async)?.size === 0
    "
      [style.padding.px]="(rightPanelConfig$ | async)?.style?.paddingPx">
    <div
        *ngIf="(rightPanelConfig$ | async) && !(rightPanelConfig$ | async)?.hideDefaultPanelHeader"
        class="SplitPanel-drawerContent-header">
      <h1
          data-test="splitPanelHeader"
          *ngIf="(rightPanelConfig$ | async)?.title"
          class="SplitPanel-drawerContent-title">
        {{ (rightPanelConfig$ | async)?.title }}
      </h1>
      <button
          data-test="splitPanelCloseRight"
          class="SplitPanel-drawerContent-btn"
          (click)="handleCloseRightDrawerClicked()"
          mat-icon-button>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <ng-template view-ref></ng-template>
  </as-split-area>
</as-split>
