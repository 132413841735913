import { ConditioningService } from '@xpo-ltl/common-services';
import { Activity, Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import {
  RouteStatusCd,
  TripNodeActivityCd,
  TripNodeStatusCd,
  TripStatusCd,
  GeoCoordinates,
  ShipmentSpecialServiceCd,
} from '@xpo-ltl/sdk-common';
import { filter as _filter, find as _find, has as _has } from 'lodash';
import { PndTripUtils } from '../../../../../shared/trip-utils';
import { MapUtils } from '../../classes/map-utils';
import { SpecialServicesHelper } from '../../helpers/special-services/special-services.helper';
import { ActivityTypePipe } from '../../pipes';
import { SpecialServicesService } from '../../services';
import { DeliveryWindowService } from '../../services/delivery-window.service';
import { EtaWindowService } from '../../services/eta-window.service';
import { MapMarkersService } from '../../services/map-markers.service';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo, MapMarkerInfoData } from '../map-marker-info.model';
import { ClusterableMarker } from './clusterable-marker';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class AssignedStopMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> implements ClusterableMarker {
  stop: Stop;
  routeInstId: number;
  routeName: string;
  tripInstId: number;
  tripNodeSequenceNbr: number;
  stopSeqNo: number;
  origSeqNo: number;
  tripNodeStatus: TripNodeStatusCd;
  routeStatus: RouteStatusCd;
  color: string;
  infoWindowTopOffset: number = 0;
  infoWindowLeftOffset: number = 0;
  infoWindowSpaceBetweenMarker: number = 0;
  isPickup: boolean = false;

  constructor(
    route: Route,
    stop: Stop,
    color: string,
    driverName: string,
    private mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    isVisible: boolean,
    private activityTypePipe: ActivityTypePipe,
    private etaWindowService: EtaWindowService,
    private deliveryWindowService: DeliveryWindowService,
    private tripStatusCd: TripStatusCd,
    isSelected: boolean,
    private specialServicesService: SpecialServicesService,
    private conditioningService: ConditioningService
  ) {
    super();

    this.stop = stop;
    this.color = color;

    const geoCoordinates: GeoCoordinates = MapUtils.getCoordinates(stop.customer);

    this.latitude = geoCoordinates.lat;
    this.longitude = geoCoordinates.lon;

    this.routeInstId = route.routeInstId;
    this.tripInstId = stop.tripNode.tripInstId;
    this.tripNodeSequenceNbr = stop.tripNode.tripNodeSequenceNbr;
    this.stopSeqNo = stop.tripNode.stopSequenceNbr;
    this.origSeqNo = stop?.tripNode?.['originalStopSequenceNbr'] || stop?.tripNode?.stopSequenceNbr;
    this.tripNodeStatus = this.stop.tripNode.statusCd;
    this.routeStatus = route.statusCd;
    this.isSelected = isSelected;
    this.isVisible = isVisible;

    this.isPickup =
      _find(this.stop.activities || [], (a) => {
        switch (a.tripNodeActivity?.activityCd) {
          case TripNodeActivityCd.PICKUP_SHIPMENTS:
          case TripNodeActivityCd.HOOK_LOADED:
          case TripNodeActivityCd.SPOT_EMPTY:
          case TripNodeActivityCd.HOOK_EMPTY:
            return a;
        }
        return undefined;
      }) !== undefined;

    this.routeName = route.routeName ? route.routeName : `${route.routePrefix}-${route.routeSuffix}`;
    if (!route.routePrefix) {
      this.routeName = '';
    }

    let totalWeight = 0;
    let totalMM = 0;
    let totalPieces = 0;
    let totalPallets = 0;
    let totalLoosePieces = 0;
    let totalCube = 0;
    _filter(stop.activities, (activity: Activity) => _has(activity, 'tripNodeActivity')).forEach((activity) => {
      totalWeight += activity.tripNodeActivity?.totalWeightCount ?? 0;
      totalMM += activity.tripNodeActivity?.totalMmCount ?? 0;
      totalPieces += activity.tripNodeActivity?.totalBillCount ?? 0;
      totalPallets += activity.tripNodeActivity?.totalPalletCount ?? 0;
      activity?.activityShipments?.forEach((activityShipment) => {
        totalLoosePieces += activityShipment?.loosePiecesCount;
      });
      totalCube += activity.tripNodeActivity?.totalCubePercentage ?? 0;
    });

    const stopType = PndTripUtils.aggregateTripNodeActivityCd(stop.activities);

    const markerData: MapMarkerInfoData = {
      totalWeightLbs: totalWeight,
      motorizedPiecesCount: totalMM,
      totalShipmentsCount: totalPieces,
      totalPalletCount: totalPallets,
      totalLoosePieces: totalLoosePieces,
      totalCube: totalCube,
      driverName: driverName,
      stopType,
      color,
      stopWindow: this.stop?.stopWindowSummary,
      activities: this.stop?.activities,
      etaSicCd: this.stop?.tripNode.nodeSicCd ?? '',
    };

    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      this.etaWindowService,
      this.deliveryWindowService,
      this.conditioningService,
      markerData,
      this.routeStatus,
      this.tripStatusCd,
      this.routeInstId ? this.routeInstId.toString() : 'noRoute'
    );

    const specialServices: ShipmentSpecialServiceCd[] = SpecialServicesHelper.getSpecialServicesForStop(stop);

    this.markerInfo.specialServiceMarks = this.specialServicesService.getSpecialServiceAppointmentForMapMarkers(
      stop.appointmentStatusCd
    );
    this.markerInfo.specialServices = specialServices;

    this.specialServicesService.addPendingMarksToSpecialServices(
      this.markerInfo.specialServices,
      this.markerInfo.specialServiceMarks
    );

    // generate initial icon
    this.updateIcon(10, this.mapMarkerService);
  }

  updateIcon(zoomLevel: number, mapMarkerService: MapMarkersService, anchor?: google.maps.Point) {
    const stopType: string = mapMarkerService.activityCdForActivities(this.stop.activities);

    this.icon = mapMarkerService.getMarkerIconAssigned(
      stopType,
      zoomLevel,
      this.isSelected || this.isFocused,
      this.color,
      this.tripNodeStatus,
      this.stopSeqNo,
      anchor,
      this.markerInfo?.specialServices,
      this.markerInfo?.specialServiceMarks
    );
  }
}
