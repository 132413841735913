export class UserPrintPreferences {
  fbdsPrinter: string;
  fbdsTray: string;
  fbdsTwoPartPrinter: string;
  dockPrinter: string;
  pndPrinter: string;
  pndTray: string;
  dsrPrinter: string;
  dsrTray: string;
  outboundPrinter: string;
  outboundTray: string;
  reprint: boolean;
  ebolPrinter: string;
  ebolTray: string;
  dmanPrinter: string;
  dmanTray: string;
  pndRevenuePrinter: string;
  pndRevenueTray: string;
}
