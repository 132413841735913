import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { isEmpty as _isEmpty } from 'lodash';
import { GenericErrorLazyTypedModel, PartialMoreInfo } from '../../models/generic-error-lazy-typed.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent implements OnInit {
  defaultMessage: string;
  defaultMessageWithMoreInfo: string;
  genericError: GenericErrorLazyTypedModel;
  mainMessage: string;
  title: string;

  constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    this.defaultMessage = 'An error has happened. Contact support.';
    this.defaultMessageWithMoreInfo = 'The process did not complete successfully due the following errors.';
    this.genericError = {};
    this.title = 'An Error Occurred';
  }

  ngOnInit() {
    this.genericError = new GenericErrorLazyTypedModel(this.data);

    this.initMainMessage();
  }

  initMainMessage(): void {
    let auxDefault = this.defaultMessage + '';

    const hasMoreInfo = !!this.genericError?.error?.moreInfo?.length;

    if (hasMoreInfo) {
      auxDefault = this.defaultMessageWithMoreInfo;

      this.genericError.error.moreInfo = this.genericError.error.moreInfo.filter(
        (value, index) => !_isEmpty(value?.message)
      );
    }

    this.mainMessage = this.genericError?.error?.message ?? auxDefault;
  }
  trackInfoBy(index, info: PartialMoreInfo): string | null {
    if (!info) {
      return null;
    }
    return info?.message + index;
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
