import { Inject, Injectable } from '@angular/core';
import GoldenLayout, { ComponentConfig } from 'golden-layout';
import {
  cloneDeep as _cloneDeep,
  defaultTo as _defaultTo,
  first as _first,
  forEach as _forEach,
  has as _has,
  map as _map,
  remove as _remove,
  size as _size,
  some as _some,
} from 'lodash';
import { BehaviorSubject, EMPTY, Observable, of, Subject, timer } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { LayoutComponentName } from 'shared/enums/layout-component-name.enum';
import { LayoutConfig } from '../layout-config.interface';
import { XPO_LTL_LAYOUT_PREFERENCES_DEFAULT_LAYOUTS, XPO_LTL_LAYOUT_PREFERENCES_STORAGE } from '../tokens';
import { LayoutManagerService } from './layout-manager.service';
import {
  XpoLtlLayoutPreferencesStorage,
  XpoLtlLayoutPreferencesStorageData,
} from './layout-preferences-storage.interface';

export interface ComponentConfiguration {}
/**
 * Manages available layout (specific grouping of Panels), loading, installing, swapping, and
 * saving them.
 */
export enum DefaultLayout {
  InboundPlanner = 'Inbound Planner',
  Dispatcher = 'Dispatcher',
}

type ItemWithActiveIndex = (LayoutConfig | GoldenLayout.ItemConfig) & { activeItemIndex?: number };

@Injectable({
  providedIn: 'root',
})
export class LayoutPreferenceService {
  private defaultLayoutName: string; // name of the layout to use if none set by user

  private viewChange$ = new Subject<string>();
  private availableLayoutsSubject = new BehaviorSubject<LayoutConfig[]>([]); // set of layouts available (default plus user created)
  readonly availableLayouts$ = this.availableLayoutsSubject.asObservable();

  private activeLayoutSubject = new BehaviorSubject<LayoutConfig>({ content: [] }); // currently active layout
  readonly activeLayout$ = this.activeLayoutSubject.asObservable();
  readonly activeLayoutMode$: Observable<DefaultLayout> = this.activeLayout$.pipe(
    map((activeLayout: LayoutConfig) => {
      return this.defaultLayoutModeForConfig(activeLayout);
    })
  );

  get activeLayout(): LayoutConfig {
    return this.activeLayoutSubject.value;
  }

  get activeLayoutMode(): string {
    return this.defaultLayoutModeForConfig(this.activeLayout);
  }

  private stashedLayout: {
    layout: GoldenLayout.ContentItem;
    reusedComponents: string[];
  };

  private defaultLayoutModeForConfig(layout: LayoutConfig): DefaultLayout {
    const activeLayoutName = layout?.name ?? '';

    if (activeLayoutName === DefaultLayout.InboundPlanner || activeLayoutName === DefaultLayout.Dispatcher) {
      return activeLayoutName;
    } else {
      return (layout?.fromLayout ?? DefaultLayout.InboundPlanner) as DefaultLayout;
    }
  }

  constructor(
    private layoutManagerService: LayoutManagerService,
    @Inject(XPO_LTL_LAYOUT_PREFERENCES_STORAGE) private storage: XpoLtlLayoutPreferencesStorage,
    @Inject(XPO_LTL_LAYOUT_PREFERENCES_DEFAULT_LAYOUTS) private defaultLayouts: LayoutConfig[]
  ) {}

  onViewChange() {
    return this.viewChange$.asObservable();
  }

  /**
   * Initialize layout preferences.
   * Call this AFTER the LayoutManager has initialized and the User is logged in
   */
  initialize$(): Observable<void> {
    if (_size(this.defaultLayouts) === 0) {
      // There are no default layouts. Create an empty one since we must have at least one
      this.defaultLayouts = [
        {
          name: 'default',
          default: true,
          content: [],
        },
      ];
    }

    // use the first defaultLayout as the default
    this.defaultLayoutName = _first(this.defaultLayouts)?.name;

    return this.loadLayoutsFromStorage$();
  }

  /**
   * Loads the passed layout in to GoldenLayout and sets it as the active one
   */
  setActiveLayout(layout: LayoutConfig) {
    layout = this.fixLayoutMap(layout);
    this.fixActiveItemIndex(layout);
    this.activeLayoutSubject.next(layout);

    if (!layout) {
      this.savePrefs$().subscribe();
    }

    // NOTE: Setting the activeLayout actually installs it into GL now.
    // HOWEVER, this recreates the panels, reloading data and update the position when maximize option is used.

    this.installLayout(_cloneDeep(layout));

    const currentView = this.isDispatcherLayout() ? DefaultLayout.Dispatcher : DefaultLayout.InboundPlanner;
    this.viewChange$.next(currentView);
  }

  /**
   * load saved layouts from storage and updates availableLayouts$ and sets
   * activeLayout$ to last used layout
   */
  loadLayoutsFromStorage$(): Observable<void> {
    return this.storage.getData$().pipe(
      map((userPrefs: XpoLtlLayoutPreferencesStorageData) => {
        // Hack: Fix Golden Layout maximised bug after restore
        const userLayouts: LayoutConfig[] = _map(userPrefs?.userLayouts, (layout: LayoutConfig) => {
          layout['maximisedItemId'] = null;
          return layout;
        });
        const layouts = [...this.defaultLayouts, ...userLayouts];
        this.availableLayoutsSubject.next(layouts);

        const lastLayout = this.getLayout(userPrefs?.activeLayout);
        const defaultLayout = this.getLayout(this.defaultLayoutName);
        this.setActiveLayout(_defaultTo(lastLayout, defaultLayout));
      })
    );
  }

  /**
   * Return true if the named layout exists
   * @param layoutName name of the layout to look for (case-insensitive)
   */
  hasLayout(layoutName: string): boolean {
    return this.getLayout(layoutName) !== undefined;
  }

  /**
   * Returns the named Layout
   * @param layoutName name of layout (case-insensitive)
   */
  getLayout(layoutName: string): LayoutConfig {
    if (layoutName && this.availableLayoutsSubject.value.length > 0) {
      const layoutToFind = layoutName.toLowerCase();
      return this.availableLayoutsSubject.value.find((item) => item.name.toLowerCase() === layoutToFind);
    }
    return undefined;
  }

  /**
   * Save the current layout as a new layout with the passed name, replacing existing layout if it
   * it already exists
   */
  saveLayoutAs$(layoutName: string): Observable<void> {
    const newLayout: LayoutConfig = this.layoutManagerService.getLayoutConfig();
    // Hack: Fix Golden Layout maximised bug after restore
    newLayout['maximisedItemId'] = null;

    // If the current layout is  default layout, then prepend 'User' to the name for saving
    const existingDefault = _some(
      this.availableLayoutsSubject.value,
      (item) => item.default && item.name.toLowerCase() === layoutName.toLowerCase()
    );

    if (existingDefault || _size(layoutName) === 0) {
      layoutName = 'User ' + layoutName;
    }

    if (
      !newLayout.fromLayout &&
      (newLayout.name === DefaultLayout.InboundPlanner || newLayout.name === DefaultLayout.Dispatcher)
    ) {
      newLayout.fromLayout = newLayout.name;
    }

    if (!newLayout.fromLayout) {
      // don't know what default layout this came from, so determine from what panels are open
      if (this.layoutManagerService.isPanelOpen(LayoutComponentName.DISPATCHER_TRIPS)) {
        newLayout.fromLayout = DefaultLayout.Dispatcher;
      } else {
        newLayout.fromLayout = DefaultLayout.InboundPlanner;
      }
    }

    newLayout.name = layoutName;
    newLayout.default = false;

    // get list of user layouts, removing the newLayout if it already existed
    const userLayouts = this.availableLayoutsSubject.value.filter(
      (item) => !item.default && item.name.toLowerCase() !== layoutName.toLowerCase()
    );

    // add the newLayout to the list of userLayouts
    userLayouts.push(newLayout);

    // rebuild list of available layouts to include the new layout
    const defaultLayouts = this.availableLayoutsSubject.value.filter((item) => item.default);
    this.availableLayoutsSubject.next([...defaultLayouts, ...userLayouts]);

    // set the newLayout as the active layout
    this.setActiveLayout(this.getLayout(layoutName));

    return this.savePrefs$();
  }

  /**
   * Delete the named layout.  If it is the current layout, then make the default
   * layout active.
   *
   * @param layoutName name of layout to delete
   */
  deleteLayout$(layoutName: string): Observable<void> {
    if (_size(layoutName) === 0) {
      // must supply a name
      return of(undefined);
    }

    // remove the named layout from the list
    const userLayouts = this.availableLayoutsSubject.value.filter((item) => !item.default);
    const removed = _remove(userLayouts, (item) => item.name.toLowerCase() === layoutName.toLowerCase());
    if (_size(removed) === 0) {
      return of(undefined);
    }

    if (this.activeLayout.name === layoutName) {
      // deleting active layout, so make default layout active
      this.setActiveLayout(this.getLayout(this.defaultLayoutName));
    }

    // rebuild list of available layouts to include the new layout
    const defaultLayouts = this.availableLayoutsSubject.value.filter((item) => item.default);
    this.availableLayoutsSubject.next([...defaultLayouts, ...userLayouts]);

    return this.savePrefs$();
  }

  /**
   * Clear all user layouts and resets to the default layout
   * WARNING: This is DESTRUCTIVE! ALL SAVED USER LAYOUTS WILL BE LOST!
   */
  deleteAllLayouts$(): Observable<void> {
    this.availableLayoutsSubject.next(this.defaultLayouts);
    this.setActiveLayout(this.getLayout(this.defaultLayoutName));

    return this.savePrefs$();
  }

  /**
   * Stash the current layout, hiding it, and adding components from the new layout
   * If a component from the new layout already exists, reuse it. else, create a new one
   */
  stashAndLoadLayout$(newLayout: LayoutConfig): Observable<void> {
    // TODO - this is not working!
    if (this.stashedLayout) {
      throw new Error('Already have a stashed layout');
    }

    console.log(`stashing current layout: `, JSON.stringify(this.layoutManagerService.getLayoutConfig()));

    // Gather list of all components currently in GL that we may reuse
    const reusableComponents = this.layoutManagerService.getOpenPanelNames();

    // clone the incoming layout and modify it to mark reusable components
    const newLayoutConfig = _cloneDeep(newLayout) as GoldenLayout.ItemConfigType;
    const reusedComponents = this.markForReuse(newLayoutConfig, reusableComponents);

    // Save the current layout to restore it later
    // NOTE: even though we remove the item from GL, it still exists, so we
    // need to hide it and set height to 0 to ensure it isn't visible
    this.stashedLayout = {
      layout: this.layoutManagerService.getRoot().contentItems[0],
      reusedComponents,
    };
    this.stashedLayout.layout.element.hide();
    this.stashedLayout.layout.config.height = 0;

    // remove existing layout, but don't destroy it
    this.layoutManagerService.getRoot().removeChild(this.stashedLayout.layout as GoldenLayout.Config, true);

    // install the new layout (this creates the ContentItems for the layout from the newLayout config)
    this.layoutManagerService.getRoot().addChild(newLayoutConfig.content[0]);

    // replace all placeholders with components from existing layout
    _forEach(this.stashedLayout.reusedComponents, (existingComponentName: string, index) => {
      this.replaceReusableComponent(
        this.layoutManagerService.getRoot().contentItems[0],
        this.stashedLayout.layout,
        existingComponentName
      );
    });

    return timer(1).pipe(
      tap(() => {
        this.layoutManagerService.updateSize();
        console.log(`set new layout: `, JSON.stringify(this.layoutManagerService.getLayoutConfig()));
        console.log(`stashedLayout: `, this.stashedLayout);
      }),
      mapTo(undefined)
    );
  }

  /**
   * restore the previously stashed layout, reusing existing components from current layout
   */
  restoreStashedLayout$(): Observable<void> {
    // TODO - this is not working!
    if (!this.stashedLayout) {
      return EMPTY;
    } else {
      if (this.layoutManagerService.initialized$) {
        console.log(`current layout: `, JSON.stringify(this.layoutManagerService.getLayoutConfig()));

        const currentLayout = this.layoutManagerService.getRoot().contentItems[0];

        // replace all placeholders with components from existing layout
        _forEach(this.stashedLayout.reusedComponents, (existingComponentName: string, index) => {
          this.replaceReusableComponent(this.stashedLayout.layout, currentLayout, existingComponentName);
        });

        // remove the current layout, destroying it
        currentLayout.remove();

        // show and add the stashed layout
        this.stashedLayout.layout.element.show();
        this.stashedLayout.layout.config.height = 100;

        this.layoutManagerService.getRoot().addChild(this.stashedLayout.layout);

        // clear stashed data
        this.stashedLayout = undefined;

        return timer(1).pipe(
          tap(() => {
            this.layoutManagerService.updateSize();
            console.log('restoreStashedLayout: ', JSON.stringify(this.layoutManagerService.getLayoutConfig()));
          }),
          mapTo(undefined)
        );
      }
    }
  }

  /**
   * Determines if currently selected layout is a dispatcher layout
   */
  isDispatcherLayout(): boolean {
    return this.activeLayoutMode === DefaultLayout.Dispatcher;
  }

  /**
   * Determines if currently selected layout is a inbound planner layout
   */
  isInboundPlannerLayout(): boolean {
    return this.activeLayoutMode === DefaultLayout.InboundPlanner;
  }

  /**
   * Find and return the Component in the passed hiearchy
   */
  private getComponentByName(
    item: GoldenLayout.ContentItem | GoldenLayout.ItemConfigType,
    name: string
  ): GoldenLayout.ContentItem | GoldenLayout.ItemConfigType {
    if (item?.['componentName'] === name) {
      return item;
    } else {
      // look in content items for the component
      const content = _has(item, 'contentItems')
        ? item?.['contentItems']
        : (item?.['content'] as GoldenLayout.ContentItem[] | GoldenLayout.ItemConfigType[]);

      for (let ii = 0; ii < _size(content); ii++) {
        const component = this.getComponentByName(content[ii], name);
        if (component) {
          return component;
        }
      }
      return undefined;
    }
  }

  /**
   * Replace all existing components in the hiearchy with placeholders
   * Returns list of compnentNames that have been marked for reuse
   */
  private markForReuse(
    item: GoldenLayout.ItemConfigType,
    existingComponents: string[],
    reusedComponentNames: string[] = []
  ): string[] {
    if (item.type === 'component') {
      const componentConfig = item as GoldenLayout.ComponentConfig;
      const itemName = item?.['componentName'] as string;
      if (_some(existingComponents, (existing: string) => existing === componentConfig.componentName)) {
        // modify the item to be a marker to be replaced with reused component
        componentConfig.id = `${itemName}-placeholder`;
        componentConfig.type = 'stack';
        componentConfig.componentName = undefined;
        reusedComponentNames.push(itemName);
      }
    }

    // scan child components for existing components to reuse
    _forEach(item.content, (subItem) => {
      this.markForReuse(subItem, existingComponents, reusedComponentNames);
    });

    return reusedComponentNames;
  }

  /**
   * Replace the named reusable component in the container with the componet in reusableLayout
   */
  private replaceReusableComponent(
    targetContainer: GoldenLayout.ContentItem,
    sourceContainer: GoldenLayout.ContentItem,
    componentToReuse: string
  ) {
    // find the placehold for the component
    const componentContainerId = `${componentToReuse}-placeholder`;
    const targetPlaceholder = _first(targetContainer.getItemsById(componentContainerId));
    if (!targetPlaceholder) {
      return;
    }
    const targetParent = targetPlaceholder.parent;

    // find the component in the source
    const sourceComponent = this.getComponentByName(sourceContainer, componentToReuse) as GoldenLayout.ContentItem;
    if (!sourceComponent) {
      return;
    }
    const sourceParent = sourceComponent.parent;
    const sourcePlaceholder: GoldenLayout.ItemConfig = {
      id: componentContainerId,
      type: 'stack',
    };
    sourceParent.replaceChild(sourceComponent as GoldenLayout.ContentItem, sourcePlaceholder);

    // replace the target with the component we want to reuse
    targetParent.replaceChild(targetPlaceholder, sourceComponent);
    if (targetParent.type === 'stack') {
      // activate the component in the stack
      targetParent.setActiveContentItem(sourceComponent);
    }
  }

  /**
   * scan through the layout and fix any activeItemIndex that is out of range.
   * NOTE: This modifies the passed data!
   */
  private fixActiveItemIndex(data: ItemWithActiveIndex) {
    if (_has(data, 'activeItemIndex')) {
      if (data.activeItemIndex < 0 || data.activeItemIndex >= _size(data.content)) {
        data.activeItemIndex = 0;
      }
    }
    _forEach(data.content, (c: ItemWithActiveIndex) => {
      this.fixActiveItemIndex(c);
    });
  }

  /**
   * Load the passed layout into GoldenLayout.
   * If no layout passed, clears the existing components
   */
  private installLayout(layout: LayoutConfig): void {
    if (layout) {
      const resetTranslateXProperty = (item: GoldenLayout.ContentItem, className: string): void => {
        // tslint:disable-next-line: no-any
        Array.from((item.childElementContainer as any)[0].getElementsByClassName(className)).forEach(
          (elem: HTMLElement) => {
            if (elem.style.transform !== 'translateX(0px)') {
              elem.style.transform = 'translateX(0px)';
            }
          }
        );
      };

      // Fix 'ag-header' bug when layout is maximized or exiting Route Balancer and
      // reset ag header container translateX props to 0px
      const resetAgContainerProps = (item: GoldenLayout.ContentItem): void => {
        if (!!_size(item.childElementContainer)) {
          resetTranslateXProperty(item, 'ag-header-container');
          resetTranslateXProperty(item, 'ag-floating-bottom-container');
        }
      };

      // Recursively adds event listeners to all child content items to act on various events that do
      // not propogate to parent content item elements
      const addContentItemListeners = (contentItems: GoldenLayout.ContentItem[]) => {
        _forEach(contentItems, (contentItem: GoldenLayout.ContentItem) => {
          contentItem.on('maximised', () => {
            resetAgContainerProps(contentItem);
          });

          contentItem.on('minimised', () => {
            resetAgContainerProps(contentItem);
          });

          if (_size(contentItem.contentItems)) {
            addContentItemListeners(contentItem.contentItems);
          }
        });
      };

      this.fixActiveItemIndex(layout);

      // add listeners to content items
      addContentItemListeners(this.layoutManagerService.getRoot().contentItems);

      this.layoutManagerService.updateSize();

      // update this layout as the last selected
      this.savePrefs$().subscribe();
    } else {
      // clear out all content leaving no open panels
      this.layoutManagerService.getRoot().contentItems.forEach((item: GoldenLayout.ContentItem) => {
        item.remove();
      });

      this.layoutManagerService.updateSize();
    }
  }

  /**
   * Save the currently activeLayout and all userLayouts
   */
  private savePrefs$(): Observable<void> {
    const userPrefs = {
      activeLayout: this.activeLayout?.name ?? '',
      userLayouts: this.availableLayoutsSubject.value.filter((item) => !item.default),
    };

    return this.storage.setData$(userPrefs);
  }
  /**
   * Set map closable for old layout settings
   */
  private fixLayoutMap(layout: LayoutConfig): LayoutConfig {
    const mapStack = layout.content[0]?.content.find(
      (stack) =>
        !!stack.content?.find((child: ComponentConfig) => child.componentName === LayoutComponentName.PLANNING_MAP)
    );
    if (mapStack && !mapStack.content[0].isClosable) {
      mapStack.content[0].isClosable = true;
    }
    return layout;
  }
}
