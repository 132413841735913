<xpo-ltl-dialog-title>{{ dialogTitle }}</xpo-ltl-dialog-title>

<form [formGroup]="formGroup">
  <div mat-dialog-content class="pnd-ReleaseRoute__content">
    <div *ngIf="showSpinner$ | async" class="pnd-ReleaseRoute__content-loading">
      <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
      <span>Please wait...</span>
    </div>
    <div class="pnd-ReleaseRoute__content-detail">
      <div class="pnd-ReleaseRoute__content-detail-name">
        Route {{ data?.routeName }}
        <span *ngIf="data.routeCategoryCd === RouteCategoryCd.DELIVERY">is {{ data?.routeStatusCd }}</span>
      </div>
      <div class="pnd-ReleaseRoute__content-detail-start-time">
        <mat-form-field
            [style.display]="data.routeCategoryCd === RouteCategoryCd.DELIVERY ? 'block' : 'none'"
            floatLabel="always">
          <input
              #routeStartTime
              matInput
              placeholder="Route Start Time*"
              [formControlName]="ReleaseRouteFormFields.RouteStartTime"
              [errorStateMatcher]="errorStateMatcher"
              autocomplete="off"
              (blur)="handleTimeBlur()" />
          <mat-icon matSuffix>access_time</mat-icon>
          <mat-hint>24hr format 00:00</mat-hint>
          <mat-error *ngIf="formGroup.get(ReleaseRouteFormFields.RouteStartTime)?.invalid">
            <div *ngIf="formGroup.get(ReleaseRouteFormFields.RouteStartTime)?.hasError('required')">Required</div>
            <div *ngIf="formGroup.get(ReleaseRouteFormFields.RouteStartTime)?.hasError('invalidFormat')">Invalid</div>
          </mat-error>
        </mat-form-field>
        <mat-icon
            *ngIf="
            data.routeCategoryCd === RouteCategoryCd.DELIVERY &&
            formGroup.get(ReleaseRouteFormFields.RouteStartTime)?.valid
          "
            matSuffix
            class="pnd-TimeInputFilter--icon-success"
            class="validity-status-icon validity-status-icon--success">check_circle</mat-icon>
        <mat-icon
            *ngIf="
            data.routeCategoryCd === RouteCategoryCd.DELIVERY &&
            formGroup.get(ReleaseRouteFormFields.RouteStartTime)?.invalid
          "
            matSuffix
            class="pnd-TimeInputFilter--icon-error"
            class="validity-status-icon validity-status-icon--error">info</mat-icon>
      </div>
      <div class="pnd-ReleaseRoute__content-detail-door-trailer">
        <mat-form-field floatLabel="always">
          <input
              #doorNumber
              matInput
              maxlength="4"
              placeholder="Door #"
              [formControlName]="ReleaseRouteFormFields.DoorNumber"
              [xpo-allow-characters]="ValidationRegexPatterns.numberCheck | toRegex"
              autocomplete="off" />
          <mat-error *ngIf="hasErrors(ReleaseRouteFormFields.DoorNumber)">
            <div *ngIf="hasError(ReleaseRouteFormFields.DoorNumber, 'invalid')">Invalid</div>
          </mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <input
              #trailerNumber
              matInput
              maxlength="11"
              placeholder="Trailer #"
              [formControlName]="ReleaseRouteFormFields.TrailerNumber"
              [xpo-allow-characters]="ValidationRegexPatterns.trailerInputValidatorPattern | toRegex"
              autocomplete="off"
              (blur)="handleTrailerBlur()" />
          <mat-error *ngIf="hasErrors(ReleaseRouteFormFields.TrailerNumber)">
            <div *ngIf="hasError(ReleaseRouteFormFields.TrailerNumber, 'invalid')">Invalid</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pnd-ReleaseRoute__content-detail-dock-nearest-door">
        <mat-form-field floatLabel="always">
          <input
              #dockLocation
              matInput
              maxlength="11"
              placeholder="Dock Location"
              [formControlName]="ReleaseRouteFormFields.DockLocation"
              autocomplete="off" />
          <mat-error *ngIf="hasErrors(ReleaseRouteFormFields.DockLocation)">
            <div *ngIf="hasError(ReleaseRouteFormFields.DockLocation, 'invalid')">
              May contain letters, numbers, spaces, dash, underscore or @
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <input
              #nearestDoor
              matInput
              maxlength="4"
              placeholder="Nearest Door"
              [formControlName]="ReleaseRouteFormFields.NearestDoor"
              [xpo-allow-characters]="ValidationRegexPatterns.numberCheck | toRegex"
              autocomplete="off" />
          <mat-error *ngIf="hasErrors(ReleaseRouteFormFields.NearestDoor)">
            <div *ngIf="hasError(ReleaseRouteFormFields.NearestDoor, 'invalid')">Invalid</div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="pnd-ReleaseRoute__actionButtons">
      <div class="pnd-ReleaseRoute__actionButtons-left">
        <button
            *ngIf="!data.xdockReleaseInd && this.data.routeCategoryCd === RouteCategoryCd.DELIVERY"
            mat-flat-button
            (click)="saveAndReleaseClicked()"
            [disabled]="(showSpinner$ | async) || !formGroup.valid || !hasValue(ReleaseRouteFormFields.RouteStartTime)"
            data-test="btn-release-route-save-and-release">
          Save and Release
        </button>
        <button
            *ngIf="!data.xdockReleaseInd && this.data.routeCategoryCd === RouteCategoryCd.DELIVERY"
            mat-stroked-button
            (click)="saveAndCloseClicked()"
            [disabled]="(showSpinner$ | async) || !formGroup.valid"
            data-test="btn-release-route-save-and-close">
          Save and Close
        </button>
        <button
            *ngIf="data.xdockReleaseInd || this.data.routeCategoryCd !== RouteCategoryCd.DELIVERY"
            mat-flat-button
            (click)="saveAndCloseClicked()"
            [disabled]="(showSpinner$ | async) || !formGroup.valid"
            data-test="btn-release-route-save-and-close">
          Save and Close
        </button>
      </div>
      <div class="pnd-ReleaseRoute__actionButtons-right">
        <button
            mat-stroked-button
            (click)="cancelClicked()"
            [disabled]="showSpinner$ | async"
            data-test="btn-release-route-cancel">
          Cancel
        </button>
      </div>
    </div>
  </div>
</form>
