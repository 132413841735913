import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'pnd-alert-counter',
  templateUrl: './alert-counter.component.html',
  styleUrls: ['./alert-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertCounterComponent {
  private _alerts$: Observable<string[]> = of([]);
  displayAlerts$: Observable<boolean> = of(false);
  textAlerts$: Observable<string> = of('');

  @Input() set alerts$(alerts$) {
    this._alerts$ = alerts$;
    if (this.alerts$) {
      this.displayAlerts$ = this.alerts$.pipe(
        tap((alerts) => (this.textAlerts$ = of(`${_size(alerts)} ${_size(alerts) > 1 ? 'alerts' : 'alert'}`))),
        map((alerts) => !!_size(alerts))
      );
    } else {
      this.displayAlerts$ = of(false);
      this.textAlerts$ = of('');
    }
  }

  get alerts$(): Observable<string[]> {
    return this._alerts$;
  }
}
