import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pnd-boolean-dot-cell-renderer',
  template: '<div style="display: flex" *ngIf="!!params?.value"><mat-icon>fiber_manual_record</mat-icon></div>',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanDotCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
