<div
    [matTooltip]="alert?.cellHoverText"
    matTooltipPosition="above"
    class="pnd-dispatchExceptionTooltipCellRenderer"
    [class.pnd-dispatchExceptionTooltipCellRenderer--right-align]="rightAlign">
  <div *ngIf="isStatusIndicator && statusIndicatorValue">
    <xpo-status-indicator
        class="pnd-dispatchExceptionTooltipCellRenderer__status-indicator"
        [statusText]="statusIndicatorValue?.statusText"
        [statusColor]="statusIndicatorValue?.statusColor"></xpo-status-indicator>
  </div>
  <div *ngIf="!isStatusIndicator">
    {{ stringValue }}
  </div>
</div>
