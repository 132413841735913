import { Component, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { DockRoutesStoreSelectors, DockRoutesStoreActions } from '@pnd-store/dock-routes-store';
import { DockRoutesActivityId } from '@pnd-store/dock-routes-store/dock-routes.state';
import { PndStoreState, GlobalFilterStoreSelectors } from '@pnd-store/index';
import { PndStore } from '@pnd-store/pnd-store';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { Unsubscriber, ProFormatterPipe, XpoLtlServiceCentersService } from '@xpo-ltl/ngx-ltl';
import { DockActivityCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  ICellRendererParams,
  GridOptions,
  SelectionChangedEvent,
  GridReadyEvent,
  GridApi,
  ColDef,
  RowNode,
} from 'ag-grid-community';
import { defaultTo as _defaultTo, uniqBy as _uniqBy, pick as _pick } from 'lodash';
import { filter, takeUntil, take } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../../core/dialogs/pnd-dialog.service';
import { ActionLinkCellRendererComponent } from '../../../../../shared/components/action-link-cell-renderer/action-link-cell-renderer';
import { NotesCellRendererComponent } from '../../../../../shared/components/notes-cell-renderer/notes-cell-renderer.component';
import { SpecialServicesCellRendererComponent } from '../../../../../shared/components/special-services-cell-renderer/special-services-cell-renderer.component';
import { TotalTextCellRendererComponent } from '../../../../../shared/components/total-text-cell-renderer/total-text-cell-renderer.component';
import { VisibilityCellRendererComponent } from '../../../../../shared/components/visibility-cell-renderer/visibility-cell-renderer.component';
import { GrandTotals, DockRoutesDetailsGrandTotals } from '../../../../../shared/interfaces/grand-totals.interface';
import { DockRoutesService } from '../../../../../shared/services/dock-routes-services/dock-routes.service';
import { SpecialServicesService } from '../../../../../shared/services/special-services.service';
import { ModifyTripDetailsRowHeightConfig } from '../../../modify-trip-details/models/modify-trip-details-row-height-config.model';
import { DockRoutesGridFields } from '../../enums/dock-routes-grid-fields.enum';
import { DockRoutesStopsGridItem, DockRoutesActivityGridItem } from '../../models/dock-routes-grid-item.model';
import { DockRoutesRowHeightConfig } from '../../models/dock-routes-row-height-config.model';
import { DockRoutesDetailGirdColumnDefinition } from './dock-routes-detail-gird-column-definition.class';

export interface DockRoutesDetailsGridRendererParams extends ICellRendererParams {}

@Component({
  selector: 'pnd-dock-routes-detail-grid-renderer',
  templateUrl: './dock-routes-detail-grid-renderer.component.html',
  styleUrls: ['./dock-routes-detail-grid-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DockRoutesDetailGridRendererComponent implements ICellRendererAngularComp, OnDestroy {
  private unsubscriber = new Unsubscriber();
  private gridApi: GridApi;
  lineItemsRowData: DockRoutesActivityGridItem[];
  activityCd: DockActivityCd;
  ActivityCd = DockActivityCd;
  lineItemsGridOptions: GridOptions = {
    frameworkComponents: {
      specialServicesCellRenderer: SpecialServicesCellRendererComponent,
      visibilityCellRenderer: VisibilityCellRendererComponent,
      notesCellRenderer: NotesCellRendererComponent,
      actionLinkCellRenderer: ActionLinkCellRendererComponent,
      totalTextCellRenderer: TotalTextCellRendererComponent,
    },
    enableCellTextSelection: false,
    headerHeight: ModifyTripDetailsRowHeightConfig.HEADER_HEIGHT,
    floatingFilter: false,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    onGridReady: (event) => this.onGridReady(event),
    onSelectionChanged: (event: SelectionChangedEvent) => this.onSelectionChanged(event),
    defaultColDef: {
      resizable: false,
      suppressMenu: true,
      sortable: false,
      filter: false,
    },
    rowClassRules: {
      ['pnd-masterDetail__totals']: (params) => {
        return !!params.node && !!params.node.isRowPinned();
      },
    },
  };
  readonly detailGridMarginTop = DockRoutesRowHeightConfig.DETAIL_MARGIN_TOP;
  readonly detailGridMarginBottom = DockRoutesRowHeightConfig.DETAIL_MARGIN_BOTTOM;

  readonly pickupItemsColDef: ColDef[] = DockRoutesDetailGirdColumnDefinition.pickupLineItemsColDef(
    this.specialServicesService,
    this.proFormatterPipe,
    this.onProNbrClicked.bind(this),
    this.onCallNbrClicked.bind(this)
  );

  readonly deliveryItemsColDef: ColDef[] = DockRoutesDetailGirdColumnDefinition.deliveryLineItemsColDef(
    this.specialServicesService,
    this.proFormatterPipe,
    this.onProNbrClicked.bind(this)
  );

  constructor(
    private specialServicesService: SpecialServicesService,
    private pndStore$: PndStore<PndStoreState.State>,
    private dockRoutesService: DockRoutesService,
    private proFormatterPipe: ProFormatterPipe,
    private pndDialogService: PndDialogService,
    private serviceCentersService: XpoLtlServiceCentersService
  ) {}

  agInit(params: DockRoutesDetailsGridRendererParams): void {
    const item = params.data as DockRoutesStopsGridItem;
    this.lineItemsRowData = item.activities;
    this.activityCd = item.activityCd;
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  refresh(params: DockRoutesDetailsGridRendererParams): boolean {
    return false;
  }

  onGridReady(gridEvent: GridReadyEvent) {
    this.gridApi = gridEvent.api;
    this.subscribeToStoreSelectionChange();
    this.setTotalsRow();
  }

  private subscribeToStoreSelectionChange() {
    this.pndStore$
      .select(DockRoutesStoreSelectors.selectedDockActivities)
      .pipe(
        filter(() => !!this.gridApi),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((selectedActivitiesFromStore) => {
        this.updateNodeSelectionFromStore(selectedActivitiesFromStore);
      });
  }

  private setTotalsRow() {
    const totals: GrandTotals = this.computeTotals();
    this.gridApi.setPinnedBottomRowData([totals]);
  }

  private onSelectionChanged(event: SelectionChangedEvent) {
    let selectedActivities: DockRoutesActivityId[] = this.pndStore$.selectSnapshot(
      DockRoutesStoreSelectors.selectedDockActivities
    );

    this.gridApi.forEachNode((node) => {
      const item = node.data as DockRoutesActivityGridItem;
      if (node.isSelected()) {
        selectedActivities.push(this.getDockActivityIdFromItem(item));
      } else {
        selectedActivities = selectedActivities.filter((selection) => selection.uniqueId !== item.uniqueId);
      }
    });
    this.pndStore$.dispatch(
      new DockRoutesStoreActions.SetSelectedDockActivities({
        selectedDockActivities: _uniqBy(selectedActivities, 'uniqueId'),
      })
    );
  }

  getCurrentRowHeight() {
    return 30;
  }

  private computeTotals(): GrandTotals {
    const deliveryGrandTotals: DockRoutesDetailsGrandTotals = {
      [DockRoutesGridFields.PALLET_COUNT]: 0,
      [DockRoutesGridFields.PIECES_COUNT]: 0,
      [DockRoutesGridFields.MOTOR_MOVE_NBR]: 0,
      [DockRoutesGridFields.WEIGHT_LBS]: 0,
      [DockRoutesGridFields.CUBE_NBR]: 0,
      [DockRoutesGridFields.PRO]: '',
    };

    let totalShipmentsCount: number = 0;

    this.gridApi.forEachNode((rowNode: RowNode) => {
      const data: DockRoutesActivityGridItem = rowNode.data;

      if (data) {
        deliveryGrandTotals[DockRoutesGridFields.PALLET_COUNT] += _defaultTo(data.palletCount, 0);
        deliveryGrandTotals[DockRoutesGridFields.PIECES_COUNT] += _defaultTo(data.piecesCount, 0);
        deliveryGrandTotals[DockRoutesGridFields.WEIGHT_LBS] += _defaultTo(data.weightLbs, 0);
        deliveryGrandTotals[DockRoutesGridFields.MOTOR_MOVE_NBR] += _defaultTo(data.motorMovesNbr, 0);
        deliveryGrandTotals[DockRoutesGridFields.CUBE_NBR] += _defaultTo(data.cubeNbr, 0);

        if (!!data.proNbr) {
          totalShipmentsCount++;
        }
      }
    });

    deliveryGrandTotals[DockRoutesGridFields.PRO] = `${totalShipmentsCount} ${
      totalShipmentsCount === 1 ? 'PRO' : 'PROs'
    }`;

    return <GrandTotals>deliveryGrandTotals;
  }

  private updateNodeSelectionFromStore(selectedActivitiesFromStore: DockRoutesActivityId[]) {
    const selectedActivityIds = new Set<string>();
    selectedActivitiesFromStore.forEach((activityId) => {
      selectedActivityIds.add(activityId.uniqueId);
    });

    this.gridApi.forEachNode((node) => {
      const item: DockRoutesActivityGridItem = node.data as DockRoutesActivityGridItem;
      if (item) {
        const isSelected = selectedActivityIds.has(item.uniqueId);
        node.setSelected(isSelected, false, true);
      }
    });
  }

  getDockActivityIdFromItem(item: DockRoutesActivityGridItem): DockRoutesActivityId {
    const id = _pick(item, [
      'uniqueId',
      'routeInstId',
      'shipmentInstId',
      'proNbr',
      'specialServices',
      'billCount',
      'motorMovesNbr',
      'weightLbs',
    ]) as DockRoutesActivityId;
    return id;
  }

  onProNbrClicked(item: DockRoutesActivityGridItem) {
    this.pndDialogService
      .showShipmentDetailsDialog({ proNbr: item?.proNbr, shipmentInstId: item?.shipmentInstId })
      .pipe(take(1))
      .subscribe();
  }

  onCallNbrClicked(item: DockRoutesActivityGridItem) {
    const sicCd = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    this.serviceCentersService
      .getSicByCd$(sicCd)
      .pipe(take(1))
      .subscribe((serviceCenter: ServiceCenter) => {
        this.dockRoutesService.setPickupRequestConfig(item?.callNbr, item?.pickUpInstId, serviceCenter);
      });
  }
}
