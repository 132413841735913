import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PndStore } from '@pnd-store/pnd-store';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { PluralMaps } from '../../classes/plural-maps';

@Component({
  selector: 'refresh-notification-widget',
  templateUrl: './refresh-notification-widget.component.html',
  styleUrls: ['./refresh-notification-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshNotificationWidgetComponent {
  @Input() set numberOfItemsChanged(value: number) {
    this.numberOfItemsChanged$.next(value);
  }
  readonly numberOfItemsChanged$ = new BehaviorSubject<number>(0);

  @Input() hideNumberItemsChanged: boolean = false;

  @Input() set lastUpdateTime(value: Date) {
    this.lastDateTimeSubject.next(value);
  }
  private lastDateTimeSubject = new BehaviorSubject<Date>(undefined);

  @Output() refreshIntent = new EventEmitter<void>();

  private sicToTimezone$ = this.pndStore$
    .select(GlobalFilterStoreSelectors.globalFilterSic)
    .pipe(switchMap((sicCd: string) => this.timeService.timezoneForSicCd$(sicCd)));

  readonly lastUpdateTimeDisplay$: Observable<string> = this.lastDateTimeSubject.pipe(
    withLatestFrom(this.sicToTimezone$),
    map(([date, timezone]) => {
      this.showRefresh.next(true);
      if (!date) {
        return 'unknown';
      } else {
        return moment.tz(date, timezone).format('DD MMMM YYYY [at] HH:mm:ss z');
      }
    })
  );

  readonly PluralMaps = PluralMaps;

  constructor(private pndStore$: PndStore<PndStoreState.State>, private timeService: XpoLtlTimeService) {}

  private readonly showRefresh = new BehaviorSubject<boolean>(true);
  readonly showRefresh$ = this.showRefresh.asObservable();

  onRefreshIntent(): void {
    this.showRefresh.next(false);
    this.refreshIntent.emit();
  }
}
