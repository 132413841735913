import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  private readonly numberPattern = /^(?:\d{1,9})(?:\.\d{0,2})?$/;
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    let validValue = inputValue?.match(this.numberPattern)?.[0] || '';

    if (validValue === '' && !this.isPasteEvent(event)) {
      validValue = inputValue?.slice(0, inputValue?.length - 1);
    }
    // Update the model value with the valid part of the input
    this.ngControl.control.setValue(validValue, { emitEvent: false });
  }
  private isPasteEvent(event: Event): boolean {
    return event instanceof ClipboardEvent || (event as any).inputType === 'insertFromPaste';
  }
}
