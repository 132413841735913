import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { GlobalFilterStoreSelectors } from '@pnd-store/global-filters-store';
import { PndStore } from '@pnd-store/pnd-store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { concatMap, switchMap, take } from 'rxjs/operators';
import { PndStoreState } from '..';
import { PastDueShipmentsCacheService } from '../../inbound-planning/shared/services/past-due-cache.service';
import { ActionTypes, SetPastDueShipmentsLastUpdate } from './past-due-shipments-store.actions';

@Injectable()
export class PastDueShipmentsStoreEffects {
  constructor(
    private actions$: Actions,
    private pndStore$: PndStore<PndStoreState.State>,
    private pastDueCacheService: PastDueShipmentsCacheService
  ) {}

  refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.refresh),
      concatMap(() => this.pndStore$.select(GlobalFilterStoreSelectors.selectGlobalFilterState).pipe(take(1))),
      switchMap((globalFilterState) =>
        this.pastDueCacheService.searchPastDueShipments({
          routeInstId: null,
          hostDestSicCd: globalFilterState.sic,
          Q: moment(globalFilterState.planDate).format('YYYY-MM-DD'),
        })
      ),
      concatMap(() => {
        return [new SetPastDueShipmentsLastUpdate({ pastDueShipmentsLastUpdate: new Date() })];
      })
    )
  );
}
