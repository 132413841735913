import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { AutoCompleteItem } from '@xpo-ltl/ngx-ltl';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import moment from 'moment-timezone';
import { BehaviorSubject, of } from 'rxjs';
import { TimeUtil } from '../../services/time-format.util';
interface TimeInputOption {
  format12: string;
  format24: string;
}
@Component({
  selector: 'pnd-time-selector-editor',
  templateUrl: './time-selector-editor.component.html',
  styleUrls: ['./time-selector-editor.component.scss'],
})
export class TimeSelectorEditorComponent implements ICellEditorAngularComp {
  private params;
  cellValue: string;
  format12hrs: number = 11;
  format24hrs: number = 24;

  readonly ValidationRegexPatterns = ValidationRegexPatterns;
  private filteredValueOptionsSubject = new BehaviorSubject<TimeInputOption[]>(undefined);
  readonly timeSelectorFormControl = 'timeSelector';
  focus$ = of(true);

  filteredValueOptions$ = this.filteredValueOptionsSubject.asObservable();
  timeOptions: AutoCompleteItem[] = [];
  formGroup = new UntypedFormGroup({
    timeSelector: new UntypedFormControl(''),
  });
  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.timeOptions = this.filterOptions();
    this.formGroup.get(this.timeSelectorFormControl).setValue({
      id: params?.value,
      value: params?.value,
    });
  }

  getGui() {
    return;
  }

  isPopup?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  private filterOptions(): AutoCompleteItem[] {
    const hoursAmPm = [];
    // Manually add 00:30 AM
    hoursAmPm.push(`00:30 AM`);
    const addHours = (suffix) => {
      hoursAmPm.push(`12:00 ${suffix}`);
      for (let i = 1; i <= this.format12hrs; i++) {
        const hh = i < 10 ? `0${i}` : `${i}`;
        hoursAmPm.push(`${hh}:00 ${suffix}`);
        hoursAmPm.push(`${hh}:30 ${suffix}`);
      }
    };
    addHours('AM');
    addHours('PM');

    const options = hoursAmPm.map((amPmHour) => ({
      format12: amPmHour,
      format24: moment(amPmHour, ['h:mm A']).format('HH:mm'),
    }));
    const values: AutoCompleteItem[] = [];

    for (let i = 0; i < options.length; i++) {
      values.push({
        id: options[i].format24,
        value: options[i].format24,
      });
    }

    return values;
  }

  onTextClear($event) {
    this.formGroup.get(this.timeSelectorFormControl).setValue({ id: '', value: '' });
    this.params.api.stopEditing();
  }
  onChange(event): void {
    this.formGroup.get(this.timeSelectorFormControl).setValue({
      id: event?.value,
      value: event?.value,
    });
    this.params.api.stopEditing();
    const rowIndex = this.params?.node?.rowIndex;
    const colKey = this.params?.column?.colId;
    if (rowIndex + 1 < this.params.api.getDisplayedRowCount()) {
      this.params.api.setFocusedCell(rowIndex + 1, colKey);
    } else {
      const numericPart = colKey.match(/\d+$/)[0];
      const newNumericPart = (parseInt(numericPart, 10) + 1).toString();
      const newColkey = colKey.replace(/\d+$/, newNumericPart);
      this.params.api.setFocusedCell(0, newColkey);
    }
  }

  getValue() {
    const value = this.formGroup.get(this.timeSelectorFormControl)?.value?.hasOwnProperty('id');
    if (value) {
      return this.formGroup.get(this.timeSelectorFormControl)?.value?.value;
    }
    this.formGroup.get(this.timeSelectorFormControl)?.setValue({
      id: TimeUtil.formatStringToTimeStringByFillingWithZeros(this.formGroup.get(this.timeSelectorFormControl)?.value),
      value: TimeUtil.formatStringToTimeStringByFillingWithZeros(
        this.formGroup.get(this.timeSelectorFormControl)?.value
      ),
    });
    return this.formGroup.get(this.timeSelectorFormControl)?.value?.value;
  }
}
