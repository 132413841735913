import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CarrierMaster } from '@xpo-ltl/sdk-carriermanagement';
import { NotificationMessageService, NotificationMessageStatus } from 'core';
import { sortBy as _sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { CustomCarrierMasterLocation } from '../../models/carrier-master-location';
import { CartageService } from '../../services/cartage.service';

@Component({
  selector: 'pnd-single-carrier-selector-dialog',
  templateUrl: './single-carrier-selector-dialog.component.html',
  styleUrls: ['./single-carrier-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleCarrierSelectorDialogComponent {
  @ViewChild('filterInput') filterInput: ElementRef;

  selectedCarrierId: number = undefined;
  locationId: number;
  isCarrierSelected: boolean = false;
  displayedColumns: string[] = ['scacCd', 'carrierName', 'selected'];
  showSpinner: boolean = false;
  dataSource = new MatTableDataSource(_sortBy(this.data.carriers, (c) => c?.carrierName));
  componentName = 'pnd-carriers-selector-dialog';

  constructor(
    private notificationMessageService: NotificationMessageService,
    public dialogRef: MatDialogRef<SingleCarrierSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentCarrier: CarrierMaster;
      carriers: CustomCarrierMasterLocation[];
      shipmentsIds: number[];
      mergeStopsCallback$: (carrierId: number, locationId: number) => Observable<boolean>;
    },
    private cartageService: CartageService
  ) {}

  selectCarrier(carrierId: number) {
    this.selectedCarrierId = carrierId;
    this.locationId = this.data.carriers.find((carrier) => carrier.carrierId === carrierId)?.locationId;
    this.isCarrierSelected = true;
  }

  convert() {
    this.showSpinner = true;

    this.data
      .mergeStopsCallback$(this.selectedCarrierId, this.locationId)
      .pipe(
        take(1),
        finalize(() => (this.showSpinner = false))
      )
      .subscribe(
        () => {
          this.dialogRef.close(this.selectedCarrierId);
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Success, 'Cartage Stop Created Successfully')
            .subscribe();
          this.cartageService.setConvertToCartageSuccess(true);
        },
        (error) => {
          this.cartageService.setConvertToCartageSuccess(false);
          this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe();
        }
      );
  }

  cancel() {
    this.dialogRef.close();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
