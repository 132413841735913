import { Pipe, PipeTransform } from '@angular/core';
import { PrintOccurrenceEnum } from '../../../../core/dialogs/print-preference-dialog/print-occurrence.enum';

@Pipe({ name: 'printTypeCd' })
export class PrintTypeCdPipe implements PipeTransform {
  transform(value: PrintOccurrenceEnum): string {
    switch (value || '') {
      case PrintOccurrenceEnum.DoNotAutoPrint:
        return 'Not at all';
      case PrintOccurrenceEnum.TrailerClose:
        return 'At trailer close time';
      case PrintOccurrenceEnum.Dispatch:
        return 'At trip dispatch time';
      case PrintOccurrenceEnum.TripComplete:
        return 'When trip is completed';
      case PrintOccurrenceEnum.TripReturn:
        return 'When trip is returning';
    }
    return value;
  }
}
