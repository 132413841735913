import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp, IHeaderAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-hazmat-icon',
  template: '<xpo-icon *ngIf="isHazmat" class="hazmat-svg" iconName="SS_Hazmat"></xpo-icon>',
  styles: ['.xpo-Icon.hazmat-svg > .mat-icon { padding: 16px 0 0 0; margin: 0; height: 42px; }'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HazmatIconComponent implements ICellRendererAngularComp, IHeaderAngularComp {
  isHazmat: boolean;

  constructor() {}

  agInit(params: any): void {
    this.isHazmat = params.header ? params.header : params.value;
  }

  refresh(): boolean {
    return false;
  }
}
