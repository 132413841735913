import { Pipe, PipeTransform } from '@angular/core';
import { TripStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'tripStatusCd' })
export class TripStatusCdPipe implements PipeTransform {
  transform(value: string | TripStatusCd, args?: any): string {
    switch ((value || '').toUpperCase()) {
      case TripStatusCd.NEW_TRIP.toUpperCase():
      case 'NEW':
        return 'New';
      case TripStatusCd.COMPLETED.toUpperCase():
      case 'COMP':
        return 'Complete';
      case TripStatusCd.DISPATCHED.toUpperCase():
      case 'DISP':
        return 'Dispatched';
      case TripStatusCd.RETURNING.toUpperCase():
      case 'RTN':
        return 'Returning';
    }

    return value;
  }
}
