import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { XpoAgGridBoardState, XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardApi,
  XpoBoardViewDataStore,
  XpoBoardDataSource,
  XpoBoardState,
  XpoBoardView,
  XpoBoardViewUtil,
  XpoSaveViewDialog,
  XpoSaveViewDialogData,
  XpoSaveViewDialogPurpose,
  XpoDeleteViewConfirmDialog,
  XpoDeleteViewConfirmData,
  XpoBoardViewConfig,
} from '@xpo-ltl/ngx-board/core';
import { Unsubscriber, XpoLtlConditioningService, XpoLtlFormatValidationService } from '@xpo-ltl/ngx-ltl';
import { GridApi } from 'ag-grid-community';
import { defaultTo as _defaultTo, includes as _includes, size as _size, find as _find } from 'lodash';
import { Observable, of, ReplaySubject, merge } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
  delay,
  takeUntil,
  skip,
  debounceTime,
} from 'rxjs/operators';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../../core';
import { BoardStatesEnum } from '../../../../../shared/enums/board-states.enum';
import { ComponentChangeUtils } from '../../classes/component-change-utils';
import { GridToolbarAction, GridToolbarOptions, GridToolbarViewItem } from './grid-toolbar.model';
import { GridToolbarService } from './services/grid-toolbar.service';

/*
  NOTE: When adding the grid toolbar to a new component, the companion GridToolbarService needs
  to be injected as a component level provider in the grid component. When using a multi grid
  view (ie. Stops/Shipments), add the provider at the parent component of the child grids
*/

@Component({
  selector: 'pnd-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrls: ['./grid-toolbar.component.scss'],
})
export class GridToolbarComponent implements OnInit, OnDestroy {
  @Input() boardApi: XpoBoardApi;
  @Input() gridApi: GridApi;
  @Input() viewDataStore: XpoBoardViewDataStore;
  @Input() dataSource: XpoBoardDataSource;
  @Input() gridToolbarOptions: GridToolbarOptions;
  @Output() newQuickFilterResults = new EventEmitter<boolean>();

  private readonly DEFAULT_SEARCH_DEBOUNCE_TIME = 100;

  private readonly DEFAULT_PRO_DIGIT_FORMAT_LENGTH: number = 11;

  private readonly stateChangeSubject = new ReplaySubject<XpoAgGridBoardState>(1);
  readonly stateChange$ = this.stateChangeSubject.asObservable();

  boardState$: Observable<XpoBoardState>;

  searchDisplayText: string;
  visibleToolbarActions: GridToolbarAction[] = [];
  extendedToolbarActions: GridToolbarAction[] = [];
  availableViews$: Observable<GridToolbarViewItem[]>;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(
    private dialog: MatDialog,
    private notificationMessageService: NotificationMessageService,
    private gridToolbarService: GridToolbarService,
    private formatService: XpoLtlFormatValidationService,
    private conditioningService: XpoLtlConditioningService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  get showToolbarOptions$() {
    return this.gridToolbarOptions.showToolbarOptions$ || of(true);
  }

  ngOnInit() {
    this.initializeBoardStateWatchers();
    this.initializeGridToolbarActions();
    this.initializeViewStateWatchers();
    this.subscribeToSearchFilterUpdates();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private initializeBoardStateWatchers(): void {
    this.boardState$ = this.dataSource.state$;

    this.boardState$.pipe(delay(500), take(1)).subscribe((state: XpoAgGridBoardState) => {
      this.dataSource['setState']({
        source: BoardStatesEnum.SHOW_GLOBAL_FILTERS,
        viewId: state.viewId,
        changes: ['viewId'],
      });
    });
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  quickFilterResults(value: number) {
    value === 0 ? this.newQuickFilterResults.emit(true) : this.newQuickFilterResults.emit(false);
  }
  trackConfigBy(index, config: GridToolbarAction): string | null {
    if (!config) {
      return null;
    }
    return config?.actionLabel + index;
  }
  private initializeGridToolbarActions(): void {
    const visibleActionCount = this.gridToolbarOptions.visibleActionCount || _size(this.gridToolbarOptions.actions);
    const disableModifyView$ = this.boardState$.pipe(
      map((state: XpoBoardState) => {
        return !state.viewLastSaved || state.isViewSystemDefined;
      }),
      distinctUntilChanged()
    );

    const gridToolbarActions: GridToolbarAction[] = [
      ..._defaultTo(this.gridToolbarOptions.actions, []),
      {
        actionLabel: 'Save View As',
        iconName: 'save',
        isMatIcon: true,
        dataTestAttr: 'grid-action-save-view-as',
        disabled$: of(false),
        visible$: of(true),
        callbackFn: this.handleSaveViewAs.bind(this),
      },
      {
        actionLabel: 'Save View',
        iconName: 'save',
        isMatIcon: true,
        dataTestAttr: 'grid-action-save-view',
        disabled$: of(false),
        visible$: of(true),
        callbackFn: this.handleSaveView.bind(this),
      },
      {
        actionLabel: 'Edit View',
        iconName: 'create',
        isMatIcon: true,
        dataTestAttr: 'grid-action-edit-view',
        disabled$: disableModifyView$,
        visible$: of(true),
        callbackFn: this.handleEditView.bind(this),
      },
      {
        actionLabel: 'Delete View',
        iconName: 'delete',
        isMatIcon: true,
        dataTestAttr: 'grid-action-delete-view',
        disabled$: disableModifyView$,
        visible$: of(true),
        callbackFn: this.handleDeleteView.bind(this),
      },
    ];

    this.visibleToolbarActions = gridToolbarActions.slice(0, visibleActionCount);
    this.extendedToolbarActions = gridToolbarActions.slice(visibleActionCount);
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  private initializeViewStateWatchers(): void {
    const viewStateUpdates$ = this.boardState$.pipe(
      filter(
        (boardState: XpoBoardState) =>
          _includes(boardState.changes, 'availableViews') || _includes(boardState.changes, 'visibleViewData')
      )
    );

    this.availableViews$ = merge(
      this.boardState$.pipe(take(1)), // takes initial state
      viewStateUpdates$ // takes all subsequent states
    ).pipe(
      map((boardState: XpoAgGridBoardState) => {
        const views = boardState.availableViews;
        return views.map(
          (view: XpoAgGridBoardViewConfig) =>
            <GridToolbarViewItem>{
              viewId: view.id,
              name: view.name,
              isActive: view.id === boardState.viewId,
              systemDefined: view.systemDefined,
            }
        );
      })
    );
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  private subscribeToSearchFilterUpdates(): void {
    this.gridToolbarService.searchFilterValue$
      .pipe(
        skip(1),
        debounceTime(this.gridToolbarOptions?.searchDebounceTime || this.DEFAULT_SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        map((searchValue: string) => {
          return this.formatService.isValidProNumber(searchValue)
            ? this.conditioningService.conditionProNumber(
                searchValue,
                this.gridToolbarOptions.proDigitFormatLength || this.DEFAULT_PRO_DIGIT_FORMAT_LENGTH
              )
            : searchValue;
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((searchValue: string) => {
        this.handleSearch(searchValue);
      });
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  handleViewSelected(viewId: string): void {
    this.boardState$
      .pipe(
        take(1),
        filter((boardState: XpoBoardState) => boardState.viewId !== viewId)
      )
      .subscribe((board) => {
        board?.availableViews?.forEach((view) => {
          if (view.id === 'showAll') {
            view['visibleColumns'] = [];
          }
        });
        this.boardApi.activateView(viewId);
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  handleSearch(searchTxt: string): void {
    if (this.gridToolbarOptions.searchCriteriaKey) {
      this.boardApi?.applyFilterCriterion(this.gridToolbarOptions.searchCriteriaKey, searchTxt);
    } else {
      this.gridApi?.setQuickFilter(searchTxt);
      this.quickFilterResults(this.gridApi?.getDisplayedRowCount());
      this.stateChangeSubject?.next({ source: BoardStatesEnum.FILTER_CHANGE, changes: ['criteria'] });
    }
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  private handleAddView(): void {
    this.boardState$.pipe(take(1)).subscribe((boardState: XpoBoardState) => {
      const view: XpoBoardView = boardState.template.createView({
        closeable: true,
        name: XpoBoardViewUtil.getNextViewName(boardState.template),
        visible: true,
      });
      this.saveView(view.toViewConfig(boardState), true).subscribe((newState: XpoBoardState) => {
        if (newState) {
          this.stateChangeSubject.next(newState);
        }
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
    });
  }

  private handleEditView(): void {
    this.boardState$
      .pipe(
        take(1),
        switchMap((boardState: XpoBoardState) => {
          const activeView: XpoBoardView = XpoBoardViewUtil.findActiveView(boardState);
          return this.dialog
            .open<XpoSaveViewDialog, XpoSaveViewDialogData, XpoBoardState | null>(XpoSaveViewDialog, {
              width: '500px',
              data: {
                state: boardState,
                purpose: XpoSaveViewDialogPurpose.Edit,
                saveView: (viewConfig: XpoAgGridBoardViewConfig, saveAs: boolean) =>
                  this.saveView(viewConfig, saveAs, XpoSaveViewDialogPurpose.Edit),
                view: activeView,
              },
            })
            .afterClosed();
        }),
        take(1)
      )
      .subscribe((newState: XpoBoardState) => {
        if (newState) {
          this.dataSource['setState'](newState);
          this.stateChangeSubject.next(newState);
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        }
      });
  }

  private handleDeleteView(): void {
    this.boardState$
      .pipe(
        take(1),
        switchMap((boardState: XpoBoardState) => {
          const activeView: XpoBoardView = XpoBoardViewUtil.findActiveView(boardState);
          return this.dialog
            .open<XpoDeleteViewConfirmDialog, XpoDeleteViewConfirmData, null>(XpoDeleteViewConfirmDialog, {
              width: '500px',
              data: { deleteView: this.deleteView.bind(this, activeView) },
            })
            .afterClosed();
        }),
        take(1)
      )
      .subscribe((newState) => {
        if (newState) {
          this.dataSource['setState'](newState);
          this.stateChangeSubject.next(newState);
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        }
      });
  }

  private handleSaveView(): void {
    this.boardState$
      .pipe(
        take(1),
        switchMap((boardState: XpoBoardState) => {
          // we want to be able to save system defined views
          if (!boardState.viewLastSaved && !boardState.isViewSystemDefined) {
            this.handleSaveViewAs();
            return of(undefined);
          }

          const currentView = XpoBoardViewUtil.findActiveView(boardState);
          return this.saveView(currentView.toViewConfig(boardState), false);
        }),
        take(1)
      )
      .subscribe((newState: XpoBoardState) => {
        if (newState) {
          this.stateChangeSubject.next(newState);
        }
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  private handleSaveViewAs(): void {
    this.boardState$
      .pipe(
        take(1),
        switchMap((boardState: XpoBoardState) => {
          const activeView: XpoBoardView = XpoBoardViewUtil.findActiveView(boardState);
          return this.dialog
            .open<XpoSaveViewDialog, XpoSaveViewDialogData, XpoBoardState | null>(XpoSaveViewDialog, {
              width: '500px',
              data: {
                state: boardState,
                purpose: XpoSaveViewDialogPurpose.Save,
                saveView: (viewConfig: XpoAgGridBoardViewConfig, saveAs: boolean) =>
                  this.saveView(viewConfig, true, XpoSaveViewDialogPurpose.Save),
                view: activeView,
              },
            })
            .afterClosed();
        }),
        take(1)
      )
      .subscribe((newState: XpoBoardState) => {
        if (newState) {
          this.stateChangeSubject.next(newState);
        }
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  private saveView(
    viewConfig: XpoBoardViewConfig,
    saveAs: boolean = false,
    purpose?: XpoSaveViewDialogPurpose
  ): Observable<XpoBoardState> {
    return this.boardState$.pipe(
      take(1),
      switchMap((currentBoardState: XpoAgGridBoardState) => {
        const isDuplicateView = !!_find(
          currentBoardState.availableViews,
          (existingViewConfig: XpoAgGridBoardViewConfig) => {
            return existingViewConfig.name === viewConfig.name;
          }
        );

        const isSaveAsOrEdit =
          purpose === XpoSaveViewDialogPurpose.Edit || (purpose === XpoSaveViewDialogPurpose.Save && saveAs);

        if (isDuplicateView && isSaveAsOrEdit) {
          this.handleViewUpdateError(viewConfig.name, purpose);
          return of(undefined);
        } else {
          return this.viewDataStore.save({ ...viewConfig, lastSaved: new Date() }).pipe(
            take(1),
            withLatestFrom(this.boardState$),
            map(([_, latestBoardState]: [XpoBoardViewConfig, XpoBoardState]) => {
              const newView = latestBoardState.template.createView(viewConfig);
              const nextState = saveAs
                ? XpoBoardViewUtil.addView(newView, latestBoardState, BoardStatesEnum.SAVE_VIEW_AS)
                : XpoBoardViewUtil.updateView(newView, latestBoardState, BoardStatesEnum.SAVE_VIEW);
              this.dataSource['setState'](nextState);
              return nextState;
            })
          );
        }
      })
    );
  }

  private handleViewUpdateError(viewName: string, purpose: XpoSaveViewDialogPurpose): void {
    this.notificationMessageService.openSnackBar(
      `Unable to ${purpose.toLowerCase()} view.`,
      NotificationMessageStatus.Error,
      `View with name '${viewName}' already exists`
    );
  }

  private deleteView(view: XpoBoardView): Observable<XpoBoardState> {
    return this.viewDataStore.delete(view.id).pipe(
      take(1),
      withLatestFrom(this.boardState$),
      map(([deleteSuccess, boardState]: [boolean, XpoBoardState]) => {
        if (deleteSuccess) {
          return XpoBoardViewUtil.deleteView(view.id, boardState, BoardStatesEnum.AVAILABLE_VIEWS_DELETE_VIEW);
        } else {
          return undefined;
        }
      })
    );
  }
}
