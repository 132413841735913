/**
 * Export ngx-ltl-core color definitions to make them accessible outside scss files.
 */

export class XpoColors {
  static readonly XPO_PURPLE_700 = '#6200EA'; // $xpo-purple--700
  static readonly XPO_RED_150 = '#D32F2F'; // $xpo-red--150
  static readonly XPO_RED_200 = '#B71C1C'; // $xpo-red--200
  static readonly XPO_GREEN_350 = '#00C853'; // $xpo-green--350
  static readonly XPO_BLUE_350 = '#2196F3'; // $xpo-blue--350
  static readonly XPO_BLUE_700 = '#0091EA'; // $xpo-blue--700
  static readonly XPO_YELLOW_350 = '#FFD600'; // $xpo-yellow--350
  static readonly XPO_GREY_700 = '#6F6F6F'; // $xpo-grey--700
  static readonly XPO_GREEN_450 = '#4CAF50'; // $xpo-green--600
}
