/**
 * Define the feature flags avaible for PnDr
 */
export enum FeatureTypes {
  // Feature flags go here
  // None = 'NONE', // not a feature
  Optimize = 'PNDOPTIMIZE',
  NewPickupAlert = 'NEWPUALERT',
  LastBestStop = 'LASTBESTSTOP',
  OnDemandMaxDrivers = 'ODDMAXDRIVER',
  OnDemandDuration = 'ODDDURATION',
  OnDemandPingInterval = 'ODDPINGINTRVL',
  DriverMessaging = 'PND_DRIVER_MSG',
  DSR_BLOCK = 'PND_DSR_BLOCK',
  PND_XDOCK_TRLR = 'PND_XDOCK_TRLR',
  PND_INB_PRFL = 'PND_INB_PRFL',
  UnassignedDeliveriesGrouping = 'PND_UNDL_GRPNG',
  PND_DMAN = 'PND_DMAN',
}
