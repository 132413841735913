<div
    *ngIf="{
    isDrawerOpen: isDrawerOpen$ | async
  } as state"
    class="pnd-GridBottomDrawer"
    [@openClose]="state.isDrawerOpen">
  <div class="pnd-GridBottomDrawer__content" [class.pnd-GridBottomDrawer__content--open]="state.isDrawerOpen">
    <ng-content></ng-content>
  </div>
</div>
