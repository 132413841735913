export enum XpoGridCellEditor {
  'DatePicker' = 'xpoGridDatePickerCellEditor',
  'Numeric' = 'xpoGridNumericCellEditor',
  'Select' = 'xpoSelectEditor',
  'Alphanumeric' = 'xpoGridAlphanumericCellEditor',
}

export enum XpoGridCellRenderer {
  'Base' = 'xpoGridCellBaseRenderer',
}
