import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { XpoLtlDmsUtilsModule } from '@xpo-ltl/ngx-ltl';
import { DocumentViewerComponent } from './document-viewer.component';

const components = [DocumentViewerComponent];
@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, MatTooltipModule, MatIconModule, XpoLtlDmsUtilsModule],
})
export class DocumentViewerModule {}
