import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DmanFormFields } from 'core/dialogs/print-dialog/dman-form-fields.enum';
import { DmanShipment } from './dmanData.interface';

@Component({
  selector: 'pnd-dman-panel',
  templateUrl: './dman-panel.component.html',
  styleUrls: ['./dman-panel.component.scss'],
})
export class DmanPanelComponent implements OnInit, OnChanges {
  @Output() dmanSelected: EventEmitter<void> = new EventEmitter();

  @Input() dmanControl: FormGroup;

  @Input()
  routeIndex: number;

  dmanData: DmanShipment[] = [];

  readonly DmanFormFields = DmanFormFields;

  constructor(private formBuilder: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  getDummyArray(input: number) {
    return new Array(input).fill(1);
  }
  trackConsigneeBy(index, consignee: FormControl): number {
    return index;
  }
  onDmanSelected(event, index) {
    const isCheckedControl = this.dmanControl.get(DmanFormFields.IS_CHECKED) as FormControl;

    isCheckedControl.setValue(event?.checked);
    this.dmanSelected.emit();
  }

  focusInput(inputElement: HTMLInputElement): void {
    inputElement.focus();
  }
}
