import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { get as _get } from 'lodash';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

export interface CircleCellRendererParams extends ICellRendererParams {
  field?: string;
  color?: string;
  tooltip?: string;
}

@Component({
  selector: 'pnd-circle-cell-renderer',
  templateUrl: './circle-cell-renderer.component.html',
  styleUrls: ['./circle-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-CircleCellRenderer' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleCellRendererComponent implements ICellRendererAngularComp {
  private readonly defaultColor = 'black';

  visible = false;
  color = this.defaultColor;
  tooltip = undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: CircleCellRendererParams): void {
    this.update(params);
  }

  refresh(params: CircleCellRendererParams): boolean {
    this.update(params);
    return true;
  }

  private update(params: CircleCellRendererParams) {
    // Use _get intentionally because field could be a nested property which doesn't work with elvis operator syntax
    this.visible = !!_get(params, params.field, params.value);
    this.color = params?.color ?? this.defaultColor;
    this.tooltip = params?.tooltip ?? undefined;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }
}
