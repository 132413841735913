import { Pipe, PipeTransform } from '@angular/core';
import { TrailerStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'trailerStatucCd',
})
export class TrailerStatusCdPipe implements PipeTransform {
  transform(value: TrailerStatusCd): string {
    switch (value) {
      case TrailerStatusCd.APPOINTMENT:
        return 'APPT';
      case TrailerStatusCd.ARRIVE:
        return 'ARIV';
      case TrailerStatusCd.CITY_ARRIVE:
        return 'CARV';
      case TrailerStatusCd.CITY_LOADING_AT_DOCK:
        return 'CLDK';
      case TrailerStatusCd.CLOSE_FOR_DELIVERY:
        return 'CLDV';
      case TrailerStatusCd.CLOSE_FOR_LINEHAUL:
        return 'CLOS';
      case TrailerStatusCd.DROP:
        return 'DROP';
      case TrailerStatusCd.EMPTY:
        return 'EMTY';
      case TrailerStatusCd.LOADING_AT_CUSTOMER:
        return 'LDCU';
      case TrailerStatusCd.LOADING_AT_DOCK:
        return 'LDDK';
      case TrailerStatusCd.OVERHEAD:
        return 'OVHD';
      case TrailerStatusCd.PICKUP_AND_DELIVERY:
        return 'PDRT';
      case TrailerStatusCd.TRAP:
        return 'TRAP';
      case TrailerStatusCd.UNLOAD_AT_CUSTOMER:
        return 'UNLC';
      case TrailerStatusCd.UNLOAD_AT_DOCK:
        return 'UNLD';
      default:
        return value;
    }
  }
}
