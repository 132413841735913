import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TripSummary } from '@xpo-ltl/sdk-cityoperations';
import { Observable } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { ModifyTripDetailsService } from '../../inbound-planning/components/trips/modify-trip-details/services/modify-trip-details.service';
import { ActionTypes, SetLastUpdate, SetSelectedActivities } from './modify-trip-details.actions';

@Injectable()
export class ModifyTripsDetailsStoreEffects {
  constructor(private actions$: Actions, private modifyTripDetailsService: ModifyTripDetailsService) {}

  /**
   * Refresh the currently displayed Trip Details
   */
  refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.refresh),
      switchMap(() => this.modifyTripDetailsService.refreshTrip$()),
      concatMap((result: TripSummary) => {
        if (!result) {
          return [new SetLastUpdate({ lastUpdate: new Date() }), new SetSelectedActivities({ selectedActivities: [] })];
        } else {
          return [new SetLastUpdate({ lastUpdate: new Date() })];
        }
      })
    )
  );
}
