import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IHeaderParams } from 'ag-grid-community';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

interface SortItem {
  sort: string;
  next?: SortItem;
}

export const INITIAL_SORT: SortItem = {
  sort: '',
  next: {
    sort: 'asc',
    next: {
      sort: 'desc',
    },
  },
};

export interface IconHeaderRendererParams extends IHeaderParams {
  iconName: string;
  color?: string;
  tooltip?: string;
  isCentered?: boolean;
  sortable?: boolean;
}

@Component({
  selector: 'pnd-icon-header-renderer',
  templateUrl: './icon-header-renderer.component.html',
  styleUrls: ['./icon-header-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconHeaderRendererComponent implements IHeaderAngularComp {
  params: IconHeaderRendererParams;
  iconName: string;
  color: string = 'black';
  tooltip: string;
  isCentered: boolean;
  sortable: boolean;
  sortItem: SortItem = INITIAL_SORT;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: IconHeaderRendererParams): void {
    this.params = params;
    this.iconName = params.iconName;
    this.color = params.color;
    this.tooltip = params.tooltip;
    this.isCentered = params.isCentered;
    this.sortable = !!params.sortable;
  }

  onClickEvent(event) {
    this.sortItem = this.sortItem.next || INITIAL_SORT;
    this.params.setSort(this.sortItem.sort, event.shiftKey);
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  refresh(params: IconHeaderRendererParams): boolean {
    this.agInit(params);
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
