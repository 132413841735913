import { Component, ElementRef, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { ValidationKeys } from '@xpo-ltl/ngx-ag-grid';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { isFunction as _isFunction } from 'lodash';
import { size as _size } from 'lodash';
import { timer } from 'rxjs';

/**
 * Cell editor that will only accept input that passes the provided filter
 */
@Component({
  selector: 'pnd-filtered-cell-editor',
  templateUrl: './filtered-editor.component.html',
  styleUrls: ['./filtered-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params;
  value: string;
  maxLength: number;

  @ViewChild('input') input: ElementRef;

  ngAfterViewInit() {
    timer().subscribe(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, _size(this.value));
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxLength = this.params.maxLength;
  }

  getValue(): any {
    return this.value;
  }

  onKeyDown(event): void {
    // test if this key passes the filter. If not, then ignore it
    if (!this.isDeleting(event.key) && !this.passesFilter(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  // test input using filter function. if no filter provided, always return true
  private passesFilter(value: string): boolean {
    if (!_isFunction(this.params['filter'])) {
      // no filter function provided, so assume we pass
      return true;
    } else {
      return this.params['filter'](value);
    }
  }

  private isDeleting(key): boolean {
    return key === ValidationKeys.BACKSPACE || key === ValidationKeys.DELETE;
  }
}
