import { PipeTransform, Pipe } from '@angular/core';
import { DockActivityCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'dockActivityCdPipe' })
export class DockActivityCdPipe implements PipeTransform {
  // tslint:disable-next-line:no-any
  transform(value: DockActivityCd, ...args: any[]): any {
    switch (value) {
      case DockActivityCd.DELIVERY:
        return 'DL';
      case DockActivityCd.PICKUP:
        return 'PU';
    }

    return value;
  }
}
