<div class="pickup-close-soon-cell" *ngIf="!!params?.value" style="display: flex;">
  <mat-icon
      *ngIf="params?.value === PriorityCd.LOW"
      class="pickup-close-soon-cell-yellow"
      matTooltip="{{ lowPriorityTooltip }}"
      matTooltipPosition="above">
    fiber_manual_record
  </mat-icon>
  <mat-icon
      *ngIf="params?.value === PriorityCd.MEDIUM"
      class="pickup-close-soon-cell-red"
      matTooltip="{{ mediumPriorityTooltip }}"
      matTooltipPosition="above">
    fiber_manual_record
  </mat-icon>
  <mat-icon
      *ngIf="params?.value === PriorityCd.HIGH"
      class="pickup-close-soon-cell-purple"
      matTooltip="{{ highPriorityTooltip }}"
      matTooltipPosition="above">
    fiber_manual_record
  </mat-icon>
</div>
