import { GlobalFilterStoreSelectors, PndStoreState } from '@pnd-store/index';
import { PndStore } from '@pnd-store/pnd-store';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ag-grid';
import { XpoLtlFormatValidationService } from '@xpo-ltl/ngx-ltl';
import { CustomerProfileNote } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { ColDef, ColSpanParams, ICellRendererParams, RowNode, ValueGetterParams } from 'ag-grid-community';
import { TripNodeActivityExtendedCd } from 'app/inbound-planning/shared/models/stop-type.model';
import { Observable } from 'rxjs';
import { BoardUtils } from '../../../../../../shared/board-utils';
import {
  ActivityCdPipe,
  CarrierTenderGroupStatusCdPipe,
  CarrierTenderStatusCdPipe,
  DetailGridToggleCellRendererParams,
  IconHeaderRendererParams,
  SpecialServicesService,
  TotalTextCellRendererParams,
  ZeroAsCellRendererParams,
} from '../../../../shared';
import { PluralMaps } from '../../../../shared/classes/plural-maps';
import { XpoColors } from '../../../../shared/services/xpo-colors';
import { ModifyTripDetailsSharedGridFields } from '../enums/modify-trip-details-shared-grid-fields.enum';
import { ModifyTripDetailsSharedGridHeaders } from '../enums/modify-trip-details-shared-grid-headers.enum';
import {
  ModifyTripDetailsActivityDetailGridItem,
  ModifyTripDetailsBaseGridItem,
  ModifyTripDetailsShipmentGridItem,
  ModifyTripDetailsStopGridItem,
} from '../models/modify-trip-details-grid-item.model';
import { SpecialServiceMark } from './../../../../shared/components/service-icons/model/special-service-mark';

/**
 * Shared Column Definitions for TripDetails grid
 */
export class ModifyTripDetailsSharedColumnDefinitions {
  static [ModifyTripDetailsSharedGridFields.ROW_SELECTED]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.ROW_SELECTED,
    headerValueGetter: () => '',
    field: ModifyTripDetailsSharedGridFields.ROW_SELECTED,
    colId: ModifyTripDetailsSharedGridFields.ROW_SELECTED,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerComponent: 'xpoAgGridSelectAllCheckbox',
    width: 50,
    resizable: false,
    lockPosition: true,
    sortable: false,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const nodeASel = nodeA.isSelected() ? 1 : 0;
      const nodeBSel = nodeB.isSelected() ? 1 : 0;
      return nodeASel - nodeBSel;
    },
    valueGetter: (params) => {
      const item = params.data || params.node.aggData;

      if (BoardUtils.isTotalsRow(params.node)) {
        return item?.[ModifyTripDetailsSharedGridFields.ROW_SELECTED];
      }
    },
    cellRendererSelector: (params: ICellRendererParams) => {
      const totalsRenderer: string = 'totalTextCellRenderer';
      let component: string;
      let componentParams: ICellRendererParams | TotalTextCellRendererParams = params;

      if (BoardUtils.isTotalsRow(params.node)) {
        component = totalsRenderer;

        componentParams = {
          ...params,
          displayValue: PluralMaps.Stops,
          suppressTotalText: !!params.node.aggData,
        } as TotalTextCellRendererParams;
      } else {
        component = null;
      }

      return {
        component: component,
        params: componentParams,
      };
    },
    cellRendererParams: <DetailGridToggleCellRendererParams>{
      smallIconsStyle: true,
    },
    colSpan: (params: ColSpanParams) => {
      if (params.node.isRowPinned() && !!params.node.data?.proNbr) {
        return 1;
      } else {
        return BoardUtils.isTotalsRow(params.node) ? 2 : 1;
      }
    },
  };

  static [ModifyTripDetailsSharedGridFields.MAIN_GROUP_VALUE]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.MAIN_GROUP_VALUE,
    field: ModifyTripDetailsSharedGridFields.MAIN_GROUP_VALUE,
    colId: ModifyTripDetailsSharedGridFields.MAIN_GROUP_VALUE,
    headerValueGetter: () => '',
    rowGroup: true,
    hide: true,
  };

  static [ModifyTripDetailsSharedGridFields.CUSTOMER]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.CUSTOMER,
    field: ModifyTripDetailsSharedGridFields.CUSTOMER,
    colId: ModifyTripDetailsSharedGridFields.CUSTOMER,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.CUSTOMER,
    width: 250,
    minWidth: 90,
    pinned: 'left',
    cellRenderer: 'CustomerNameLinkCellRender',
    cellRendererParams: {
      customerAcctId: (data) => data?.customerAcctId,
    },
  };

  static [ModifyTripDetailsSharedGridFields.CITY]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.CITY,
    field: ModifyTripDetailsSharedGridFields.CITY,
    colId: ModifyTripDetailsSharedGridFields.CITY,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.CITY,
    width: 150,
  };

  static [ModifyTripDetailsSharedGridFields.ZIP_CODE]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.ZIP_CODE,
    field: ModifyTripDetailsSharedGridFields.ZIP_CODE,
    colId: ModifyTripDetailsSharedGridFields.ZIP_CODE,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.ZIP_CODE,
    width: 150,
    valueGetter: (params: ValueGetterParams) => {
      const data = params?.data as ModifyTripDetailsActivityDetailGridItem;
      return data?.zip6 ?? '';
    },
  };

  static [ModifyTripDetailsSharedGridFields.BILLS]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.BILLS,
    field: ModifyTripDetailsSharedGridFields.BILLS,
    colId: ModifyTripDetailsSharedGridFields.BILLS,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.BILLS,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
    width: 80,
    valueGetter: (params: ValueGetterParams) => {
      const activityCdList = [
        TripNodeActivityCd.DELIVER_SHIPMENT,
        TripNodeActivityCd.SPOT_LOADED,
        TripNodeActivityCd.PICKUP_SHIPMENTS,
        TripNodeActivityCd.HOOK_LOADED,
      ];
      const item = params.data as ModifyTripDetailsStopGridItem;
      let totalBillCount = 0;
      const billCount: boolean = item?.billCount && item?.billCount > 0;
      const lineItemsCount = item?.activitiesGridItems ?? [];
      if (lineItemsCount.length > 0 && !billCount) {
        totalBillCount = lineItemsCount.reduce(
          (total, curr: ModifyTripDetailsActivityDetailGridItem) => total + (curr?.billCount || 0),
          0
        );
      } else {
        totalBillCount = item?.billCount;
      }
      // PCT-24130: Bill count should only show for stop with DL, SL, PU or HL activities
      return !activityCdList.includes(item?.activityCd as TripNodeActivityCd) ? '-' : totalBillCount;
    },
  };

  static [ModifyTripDetailsSharedGridFields.PALLETS]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.PALLETS,
    field: ModifyTripDetailsSharedGridFields.PALLETS,
    colId: ModifyTripDetailsSharedGridFields.PALLETS,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.PALLETS,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
    width: 80,
  };

  static [ModifyTripDetailsSharedGridFields.LOOSE_PIECES]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.LOOSE_PIECES,
    field: ModifyTripDetailsSharedGridFields.LOOSE_PIECES,
    colId: ModifyTripDetailsSharedGridFields.LOOSE_PIECES,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.LOOSE_PIECES,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
    width: 80,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.piecesCount;
    },
    cellRenderer: 'zeroAsCellRenderer',
    cellRendererParams: (params: ValueGetterParams) => {
      return {
        displayZeroAs: '',
        isTotalsRow: BoardUtils.isTotalsRow(params.node),
      } as ZeroAsCellRendererParams;
    },
  };
  static [ModifyTripDetailsSharedGridFields.WEIGHT]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.WEIGHT,
    field: ModifyTripDetailsSharedGridFields.WEIGHT,
    colId: ModifyTripDetailsSharedGridFields.WEIGHT,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.WEIGHT,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
    width: 90,
  };

  static [ModifyTripDetailsSharedGridFields.MOTOR_MOVES]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.MOTOR_MOVES,
    field: ModifyTripDetailsSharedGridFields.MOTOR_MOVES,
    colId: ModifyTripDetailsSharedGridFields.MOTOR_MOVES,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.MOTOR_MOVES,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
    width: 80,
    valueGetter: (params: ValueGetterParams) => {
      return params?.data?.motorMovesNbr;
    },
    cellRenderer: 'zeroAsCellRenderer',
    cellRendererParams: (params: ValueGetterParams) => {
      return {
        displayZeroAs: '',
        isTotalsRow: BoardUtils.isTotalsRow(params.node),
      } as ZeroAsCellRendererParams;
    },
  };

  static [ModifyTripDetailsSharedGridFields.CUBE]: ColDef = {
    headerName: ModifyTripDetailsSharedGridHeaders.CUBE,
    field: ModifyTripDetailsSharedGridFields.CUBE,
    colId: ModifyTripDetailsSharedGridFields.CUBE,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.CUBE,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },

    valueGetter: (param) => {
      const item = param?.data as ModifyTripDetailsActivityDetailGridItem;
      return +item.cubeNbr?.toFixed(2);
    },
    width: 90,
  };

  static [ModifyTripDetailsSharedGridFields.DEST_SIC] = {
    headerName: ModifyTripDetailsSharedGridHeaders.DEST_SIC,
    field: ModifyTripDetailsSharedGridFields.DEST_SIC,
    colId: ModifyTripDetailsSharedGridFields.DEST_SIC,
    headerTooltip: ModifyTripDetailsSharedGridHeaders.DEST_SIC,
    width: 80,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as ModifyTripDetailsActivityDetailGridItem;
      return item?.destinationTerminalSicCd;
    },
  };

  static [ModifyTripDetailsSharedGridFields.CALL_NBR] = (
    onCallNbrClicked: (item: ModifyTripDetailsActivityDetailGridItem) => void
  ): ColDef => {
    return {
      headerName: ModifyTripDetailsSharedGridHeaders.CALL_NBR,
      field: ModifyTripDetailsSharedGridFields.CALL_NBR,
      colId: ModifyTripDetailsSharedGridFields.CALL_NBR,
      headerTooltip: ModifyTripDetailsSharedGridHeaders.CALL_NBR,
      width: 75,
      cellRenderer: 'actionLinkCellRenderer',
      cellRendererParams: { onClick: onCallNbrClicked },
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as ModifyTripDetailsActivityDetailGridItem;
        return item?.callNbr;
      },
    };
  };

  static [ModifyTripDetailsSharedGridFields.NOTES] = (onNotesClicked: (node: RowNode) => void): ColDef => {
    return {
      headerValueGetter: () => '',
      headerName: ModifyTripDetailsSharedGridHeaders.NOTES,
      field: ModifyTripDetailsSharedGridFields.NOTES,
      colId: ModifyTripDetailsSharedGridFields.NOTES,
      headerComponent: 'iconHeaderRenderer',
      headerComponentParams: <IconHeaderRendererParams>{
        iconName: 'description',
        tooltip: 'Notes',
        isCentered: true,
      },
      width: 40,
      minWidth: 40,
      pinned: 'left',
      cellStyle: { justifyContent: 'center' },
      cellRenderer: 'gridDetailNotesTriggerCellRender',
      cellRendererParams: {
        onCellClicked: (node: RowNode): void => {
          onNotesClicked(node);
        },
        getNotesCount: (node: RowNode): number => {
          const data = node.data as ModifyTripDetailsShipmentGridItem | ModifyTripDetailsStopGridItem;
          let quantity: number = 0;

          if (data) {
            const customerProfileNote: CustomerProfileNote = (<ModifyTripDetailsStopGridItem>data)
              ?.customerProfileNotes;

            const countMessages = (items: { note?: string; statusMessage?: string }[]): number => {
              return (
                items?.reduce((count, item): number => {
                  if (item.note?.trim()?.length > 0 || item.statusMessage?.trim()?.length > 0) {
                    count += 1;
                  }
                  return count;
                }, 0) ?? 0
              );
            };

            quantity += countMessages(customerProfileNote?.operationNotes);
            quantity += countMessages(customerProfileNote?.noaNotes);
            quantity += countMessages(customerProfileNote?.pickupInstructions);
            quantity += countMessages(customerProfileNote?.deliveryInstructions);
            quantity += countMessages(data?.tenderDetails);
          }

          return quantity;
        },
      },
    };
  };

  // We need to use Functions for these column definitions
  static [ModifyTripDetailsSharedGridFields.ADDRESS] = (
    addressClickCallback: (gridItem: ModifyTripDetailsBaseGridItem) => void
  ): ColDef => {
    return {
      headerName: ModifyTripDetailsSharedGridHeaders.ADDRESS,
      field: ModifyTripDetailsSharedGridFields.ADDRESS,
      colId: ModifyTripDetailsSharedGridFields.ADDRESS,
      headerTooltip: ModifyTripDetailsSharedGridHeaders.ADDRESS,
      width: 200,
      cellRenderer: 'actionLinkCellRenderer',
      cellRendererParams: { onClick: addressClickCallback },
    };
  };

  static [ModifyTripDetailsSharedGridFields.TENDER_STATUS] = (
    isCartageTrip: boolean,
    tenderStatusCdPipe: CarrierTenderGroupStatusCdPipe | CarrierTenderStatusCdPipe
  ): ColDef => {
    return {
      headerName: ModifyTripDetailsSharedGridHeaders.TENDER_STATUS,
      field: ModifyTripDetailsSharedGridFields.TENDER_STATUS,
      colId: ModifyTripDetailsSharedGridFields.TENDER_STATUS,
      headerTooltip: ModifyTripDetailsSharedGridHeaders.TENDER_STATUS,
      width: 220,
      hide: !isCartageTrip,
      valueGetter: (params: ValueGetterParams) => {
        if (BoardUtils.isTotalsRow(params.node)) {
          return '';
        }
        const item = params.data as ModifyTripDetailsActivityDetailGridItem | ModifyTripDetailsStopGridItem;
        return item ? tenderStatusCdPipe.transform(item?.tenderStatus) : '';
      },
    };
  };

  static [ModifyTripDetailsSharedGridFields.BOL] = (
    formatValidationService: XpoLtlFormatValidationService,
    bolClickCallback: (gridItem: ModifyTripDetailsBaseGridItem) => void
  ): ColDef => {
    return {
      headerName: ModifyTripDetailsSharedGridHeaders.BOL,
      field: ModifyTripDetailsSharedGridFields.BOL,
      colId: ModifyTripDetailsSharedGridFields.BOL,
      headerTooltip: ModifyTripDetailsSharedGridHeaders.BOL,
      width: 45,
      cellRenderer: 'actionLinkCellRenderer',
      cellRendererParams: {
        onClick: bolClickCallback,
        useIcon: 'visibility',
        isVisible: (item) => {
          return (
            (item && item.bolInstId > 0) ||
            (item && item.activityCd === TripNodeActivityCd.DELIVER_SHIPMENT && item.proNbr)
          );
        },
      },
      sortable: false,
    };
  };

  static [ModifyTripDetailsSharedGridFields.EXCEPTIONS] = (pndStore$: PndStore<PndStoreState.State>): ColDef => {
    return {
      headerValueGetter: () => '',
      headerName: ModifyTripDetailsSharedGridHeaders.EXCEPTIONS,
      field: ModifyTripDetailsSharedGridFields.EXCEPTIONS,
      colId: ModifyTripDetailsSharedGridFields.EXCEPTIONS,
      cellRenderer: 'OversizeCellRenderer',
      headerComponent: 'iconHeaderRenderer',
      headerComponentParams: <IconHeaderRendererParams>{
        iconName: 'flag',
        color: XpoColors.XPO_RED_150,
        tooltip: 'Exceptions',
        isCentered: true,
      },
      lockVisible: true,
      cellStyle: { justifyContent: 'center' },
      width: 40,
      minWidth: 40,
      pinned: 'left',
      valueGetter: (params: ValueGetterParams) => '',
      cellRendererParams: {
        currentPlanDate$: (): Observable<Date> => pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
        isAvailableForPastDueAlert: false,
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const itemA = nodeA.data as ModifyTripDetailsBaseGridItem;
        const itemB = nodeB.data as ModifyTripDetailsBaseGridItem;

        const handlingUnitAlertsCount = (shipment: ModifyTripDetailsBaseGridItem): number => {
          return shipment?.handlingUnitPartialInd && shipment?.handlingUnitSplitInd
            ? 2
            : shipment?.handlingUnitPartialInd || shipment?.handlingUnitSplitInd
            ? 1
            : 0;
        };

        const countA = handlingUnitAlertsCount(itemA);
        const countB = handlingUnitAlertsCount(itemB);
        return countA - countB;
      },
    };
  };

  static [ModifyTripDetailsSharedGridFields.SPECIAL_SERVICES] = (
    specialServicesService: SpecialServicesService
  ): ColDef => {
    return {
      headerName: ModifyTripDetailsSharedGridHeaders.SPECIAL_SERVICES,
      field: ModifyTripDetailsSharedGridFields.SPECIAL_SERVICES,
      colId: ModifyTripDetailsSharedGridFields.SPECIAL_SERVICES,
      headerTooltip: ModifyTripDetailsSharedGridHeaders.SPECIAL_SERVICES,
      width: 240,
      cellRenderer: 'specialServicesCellRenderer',
      cellRendererParams: {
        getMarks: (params): SpecialServiceMark[] =>
          specialServicesService.getSpecialServiceAppointmentMark(params.data, 'appointmentStatusCd'),
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if (nodeA.group || nodeB.group) {
          return 0;
        }
        if (valueA) {
          if (valueB) {
            return specialServicesService.getSpecialServicesComparator(nodeA, nodeB);
          } else {
            return -1;
          }
        } else {
          return 1;
        }
      },
      valueGetter: (params) => {
        const item = params.data as ModifyTripDetailsBaseGridItem;
        return item?.specialServices;
      },
    };
  };

  /**
   * Open detail grid showing the Pickup/Delivery/Equipment detailGrid
   * If this is a Mixed item, then open the Mixed item rows
   */
  static [ModifyTripDetailsSharedGridFields.ACTIVITY_TYPE] = (tripNodeActivityCdPipe: ActivityCdPipe): ColDef => {
    return {
      headerName: ModifyTripDetailsSharedGridHeaders.ACTIVITY_TYPE,
      field: ModifyTripDetailsSharedGridFields.ACTIVITY_TYPE,
      colId: ModifyTripDetailsSharedGridFields.ACTIVITY_TYPE,
      headerTooltip: ModifyTripDetailsSharedGridHeaders.ACTIVITY_TYPE,
      width: 75,
      minWidth: 75,
      pinned: 'left',
      cellRendererSelector: (params: ICellRendererParams) => {
        const typeCountRenderer: string = 'typeCountCellRenderer';
        const modifyTripDetailsActivityTypeRenderer: string = 'modifyTripDetailsActivityTypeCellRenderer';
        const componentName: string = BoardUtils.isTotalsRow(params.node)
          ? typeCountRenderer
          : modifyTripDetailsActivityTypeRenderer;

        return {
          component: componentName,
          params,
        };
      },
      valueGetter: (params: ValueGetterParams) => {
        const item: ModifyTripDetailsBaseGridItem = params.data;
        const activityType: TripNodeActivityExtendedCd = item?.activityCd;
        if (activityType === TripNodeActivityCd.ARRIVE) {
          return '**';
        } else {
          return tripNodeActivityCdPipe.transform(activityType);
        }
      },
      colSpan: (params: ColSpanParams) => (BoardUtils.isTotalsRow(params.node) ? 3 : 1),
    };
  };
}
