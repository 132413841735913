import { Action } from '@ngrx/store';
import { GlobalFilterMapCoordinate } from '../../inbound-planning/shared/models/global-filter-criteria.model';
import { SicZonesAndSatellites } from '../../inbound-planning/shared/models/sic-zones-and-satellites.model';

export enum ActionTypes {
  setSic = 'globalFilter.setSic',
  setSicLatLng = 'globalFilter.setSicLatLng',
  setSicZonesAndSatellites = 'globalFilter.setSicZonesAndSatellites',
  setPlanDate = 'globalFilter.setPlanDate',
  setGeoFilterArea = 'globalFilter.setGeoFilterArea',
  resetPreferences = 'globalFilter.resetPreferences',
}

export class SetSic implements Action {
  readonly type = ActionTypes.setSic;
  constructor(public payload: { sic: string }) {}
}

export class SetSicLatLng implements Action {
  readonly type = ActionTypes.setSicLatLng;
  constructor(public payload: { sicLatLng: { latitude: number; longitude: number } }) {}
}

export class SetSicZonesAndSatellites implements Action {
  readonly type = ActionTypes.setSicZonesAndSatellites;
  constructor(public payload: { sicZonesAndSatellites: SicZonesAndSatellites }) {}
}

export class SetPlanDate implements Action {
  readonly type = ActionTypes.setPlanDate;
  constructor(public payload: { planDate: Date }) {}
}

export class SetGeoFilterArea implements Action {
  readonly type = ActionTypes.setGeoFilterArea;
  constructor(public payload: { geoFilterArea: GlobalFilterMapCoordinate[] }) {}
}

export class ResetPreferences implements Action {
  readonly type = ActionTypes.resetPreferences;
  constructor() {}
}

export type Actions =
  | SetSic
  | SetSicLatLng
  | SetSicZonesAndSatellites
  | SetPlanDate
  | SetGeoFilterArea
  | ResetPreferences;
