import { UnmappedStopStatus } from '../../inbound-planning/shared/components/unmapped-stops/classes/unmapped-stop-status';
import { UnmappedStopDetail } from '../../inbound-planning/shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../inbound-planning/shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';

export const StateKey = 'geoLocationState';

export interface State {
  readonly status: UnmappedStopStatus;
  readonly stopToEdit: UnmappedStopDetail;
  readonly editMode: UnmappedStopsEditMode;
}

export const initialState: State = {
  status: undefined,
  stopToEdit: undefined,
  editMode: undefined,
};
