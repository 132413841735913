import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Activity } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd, TripNodeStatusCd, ShipmentSpecialServiceCd, PickupTypeCd } from '@xpo-ltl/sdk-common';
import { filter as _filter, forEach as _forEach, size as _size } from 'lodash';
import { PndTripUtils } from '../../../../shared/trip-utils';
import { MapUtils } from '../classes/map-utils';
import { MapLayerType } from '../enums/map-layer-type.enum';
import { MapMarkerIcon } from '../models/markers/map-marker-icon.model';
import { MapMarkerWithInfoWindow } from '../models/markers/map-marker-with-info-window';
import { TripActivityExtendedCd, TripNodeActivityExtendedCd } from '../models/stop-type.model';
import { ActivityCdPipe } from '../pipes/activity-cd.pipe';
import { SpecialServiceMark } from './../components/service-icons/model/special-service-mark';
import { SpecialServicesService } from './special-services.service';
import { DELIVERY_COLOR } from './stop-colors';

const END_CIRCLE_SSS_RATIO: number = 10;
const SS_SEPARATION: number = 2;

export enum DefaultIconColors {
  defaultColor = 'black',
  defaultHighlightedColor = 'rgb(204,204,0)',
}

export interface BuildIconOptions {
  isSelected: boolean;
  width: number;
  reserverWidth?: number;
  height: number;
  parsedColor: string;
  sequenceNumber?: number;
  stopType: string;
  specialServices?: ShipmentSpecialServiceCd[];
  specialServiceMarks?: SpecialServiceMark[];
  stopStatusCd: TripNodeStatusCd;
}

@Injectable({ providedIn: 'root' })
export class MapMarkersService implements OnDestroy {
  constructor(private specialServicesService: SpecialServicesService, private activityCdPipe: ActivityCdPipe) {}

  static layerMarkerZIndex = {
    [MapLayerType.SPOTS_AND_DROPS]: () => 13,
    [MapLayerType.DRIVER_MARKER]: () => 13,
    [MapLayerType.UNASSIGNED_DELIVERIES]: (isPlanning: boolean) => (isPlanning ? 12 : 11),
    [MapLayerType.UNASSIGNED_PICKUPS]: (isDispatcher: boolean) => (isDispatcher ? 12 : 11),
    [MapLayerType.FORECASTED_PICKUPS]: () => 10,
    [MapLayerType.SERVICE_CENTER]: () => 9,
    [MapLayerType.PATHS]: () => 8,
    [MapLayerType.PICKUP_CLUSTER]: () => 7,
    [MapLayerType.DELIVERY_PICKUP_HEATMAP]: () => 6,
    [MapLayerType.DISPATCH_AREAS]: () => 5,
    [MapLayerType.GEO_OVERLAYS]: () => 4,
    [MapLayerType.WEATHER_LAYER]: () => 3,
    [MapLayerType.TRAFFIC_LAYER]: () => 2,
    [MapLayerType.MAP_LAYER]: () => 1,
  };
  private unsubscriber = new Unsubscriber();

  private markersMap = new Map<string, MapMarkerIcon>();

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Return the 2 character abbreviation for the Activities provided
   * (eg, PU, DL, MX, etc.)
   */
  activityCdForActivities(activities: Activity[]): string {
    const activityCd = PndTripUtils.aggregateTripNodeActivityCd(activities);
    return this.activityCdPipe.transform(activityCd);
  }

  /**
   * Resize unassigned stop markers
   * @param mapMarker
   * @param zoomLevel
   * @param anchor
   * @param theme
   */
  async updateUnassignedStopMarker(
    mapMarker: MapMarkerWithInfoWindow,
    zoomLevel: number,
    anchor?: google.maps.Point
  ): Promise<void> {
    const specialServicesFiltered = this.specialServicesService.specialServiceMapSelected;
    const specialServices: ShipmentSpecialServiceCd[] = this.getSpecialServicesFiltered(
      mapMarker.specialServices,
      specialServicesFiltered
    );

    // THIS IS A COMPLETE HACK... we need to go back and revisit how stop type is set on map markers...
    let stopType = mapMarker?.['pickup']?.pickupHeader?.pickupTypeCd;
    if (stopType) {
      switch (stopType) {
        case 'PU':
          stopType = TripNodeActivityCd.PICKUP_SHIPMENTS;
          break;
        case 'HL':
          stopType = TripNodeActivityCd.HOOK_LOADED;
          break;
        case 'HE':
          stopType = TripNodeActivityCd.HOOK_EMPTY;
          break;
        case 'SE':
          stopType = TripNodeActivityCd.SPOT_EMPTY;
          break;
      }
    } else {
      stopType = mapMarker.markerInfo.stopType || TripNodeActivityCd.DELIVER_SHIPMENT;
    }

    mapMarker.icon = await this.getMarkerIconUnassigned(
      stopType,
      zoomLevel,
      mapMarker.isSelected || mapMarker.isFocused,
      mapMarker.markerInfo.color,
      mapMarker.markerInfo.textAndBorderColor,
      anchor,
      specialServices,
      mapMarker.markerInfo?.specialServiceMarks,
      mapMarker.isPreAssigned
    );
  }

  private getSpecialServicesFiltered(
    list?: ShipmentSpecialServiceCd[],
    filter?: ShipmentSpecialServiceCd[]
  ): ShipmentSpecialServiceCd[] {
    if (filter && list) {
      return _filter(list, (specialService) => filter.some((filterItem) => specialService === filterItem));
    }
  }

  /**
   * Get marker icon for service center
   */
  getServiceCenterIconPath(): MapMarkerIcon {
    const anchor: google.maps.Point = new google.maps.Point(20, 20);
    const url: string = `data:image/svg+xml;utf-8,
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <defs>
        <filter id="shadowSic" x="-16" y="-16" width="32" height="32">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
          <feOffset dx="0" dy="0" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle cx="20" cy="20" r="11.5" fill="white" stroke="black" stroke-width="1" filter="url(%23shadowSic)" />
      <polygon
        transform='rotate(0 20 20)'
        points="10,26.5 15,30.5 16,31 24,31 25,30.5 30,26.5 20,40"
        fill="black"
        stroke="black"
        stroke-width="0.1"
      />

      <g transform="translate(10,10.5)">
        <path
          fill="%23CC0000"
          d="M4.8,9.8l2.4,3.6H4.8l-0.7-1.1c-0.2-0.4-0.3-0.6-0.5-1c-0.1,0.3-0.3,0.7-0.5,1l-0.6,1.1H0l2.5-3.6L0,6.6h2.4
              c0,0,1.1,1.5,1.3,1.9c0.1-0.3,1.1-1.9,1.1-1.9h2.5L4.8,9.8z"
        />
        <path
          fill="%23CC0000"
          d="M10.1,6.6c1.1,0,1.6,0.1,2.1,0.4c0.6,0.4,1,1.1,1,2c0,0.7-0.2,1.2-0.7,1.7c-0.4,0.4-1,0.6-1.9,0.6H9.4v2.1H7.3
              V6.6H10.1z M9.4,9.7h0.8c0.6,0,0.9-0.2,0.9-0.7c0-0.5-0.3-0.7-0.9-0.7H9.4V9.7z"
        />
        <path
          fill="%23CC0000"
          d="M20,10c0,2.1-1.4,3.5-3.4,3.5c-2.1,0-3.5-1.4-3.5-3.5c0-2.1,1.4-3.6,3.5-3.6C18.6,6.4,20,7.9,20,10 M15.3,10
              c0,1.2,0.4,1.9,1.3,1.9c0.8,0,1.3-0.7,1.3-2c0-1.1-0.4-1.8-1.3-1.8C15.7,8.1,15.3,8.8,15.3,10"
        />
      </g>
    </svg>`;

    return new MapMarkerIcon(url, anchor);
  }

  getGeoAreaIcon(geoAreaName: string, color: string = null, fontSize = 14): MapMarkerIcon {
    const fillColor = !color ? this.getStringToHslColor(geoAreaName, 100, 30) : this.getParsedColor(color);
    return new MapMarkerIcon(
      `data:image/svg+xml;utf-8,
      <svg class="geo-area-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="50" viewBox="0 0 100 50">
        <g>
          <text font-family="Roboto-Bold, Roboto, sans-serif" font-size="${fontSize}" font-weight="bold" fill="${fillColor}">
          <tspan x="50" y="35" text-anchor="middle">${geoAreaName}</tspan>
          </text>
        </g>
      </svg>`,
      null
    );
  }

  pickupClusterIcon(): MapMarkerIcon {
    const fillColor = 'hsl(0, 0%, 0%)';
    const [width, height, outerRadius, innerRadius] = [18, 18, 9, 5];
    const anchor: google.maps.Point = new google.maps.Point(width / 2, height / 2);

    return new MapMarkerIcon(
      `data:image/svg+xml;utf-8,
      <svg class="geo-area-icon" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
        <g>
          <circle cx="${width / 2}" cy="${height / 2}" r="${outerRadius}"  fill="${fillColor}" />
          <circle cx="${width / 2}" cy="${height /
        2}" r="${innerRadius}" stroke="white" stroke-width="3" fill="${fillColor}" />
        </g>
      </svg>`,
      anchor
    );
  }

  /**
   * Get driver marker icon with initials and route color
   * @param initials Driver initials
   * @param color
   * @param highlighted: true if highlighted
   * @param isStreetView
   * @param isWatched
   * @param directionAngle Default 0 (South). 90 (West). 180 (North). 270 (East)
   */
  getDriverIconWithInitials(
    initials: string,
    color?: string,
    highlighted: boolean = false,
    scaleMultiplier: number = 1,
    isStreetView: boolean = false,
    isWatched: boolean = false,
    directionAngle: number = 0
  ): MapMarkerIcon {
    if (isStreetView) {
      return this.getDriverIconWithInitialsStreetView(initials, color, scaleMultiplier, isWatched);
    } else {
      const parsedColor = this.getParsedColor(color || DefaultIconColors.defaultColor);
      const width: number = 34;
      const height: number = 34;

      const anchorX = width / 2;
      const anchorY = height / 2;
      const anchor = new google.maps.Point(anchorX, anchorY);

      const animation = `
        <rect
          x="8"
          y="8"
          width="18"
          height="18"
          rx="1"
          fill="${parsedColor}"
          stroke="${parsedColor}"
          transform-origin="center"
        >
          <animateTransform
              attributeName="transform"
              type="scale"
              dur="1.5s"
              values="1.0; 1.1; 1.2; 1.3; 1.3; 1.3;"
              repeatCount="indefinite"
              additive="sum">
          </animateTransform>
          <animate attributeName="opacity" dur="1.5s" keyTimes="0.0; 0.2; 0.4; 0.6; 0.8; 1.0; 1.0; 1.0; 1.0; 1.0"
              values="0.8; 0.6; 0.4; 0.2; 0.0; 0.0; 0.0; 0.0; 0.0; 0.0"
              repeatCount="indefinite"
              begin="0s"
          />
        </rect>
      `;

      const url = `data:image/svg+xml;utf-8,
        <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          ${isWatched ? animation : ''}

          <rect x="8" y="8" width="18" height="18" rx="1" fill="white" stroke="${parsedColor}" />
          <text x="17" y="21" text-anchor="middle" fill="${parsedColor}" font-family="Roboto-Bold, Roboto, sans-serif"
            font-size="11" font-weight="bold">
            ${initials}
          </text>

          <polygon
              transform="rotate(${directionAngle} 17 17)"
              points="11,26 17,34 23,26"
              fill="${parsedColor}"
              stroke="${parsedColor}"
              stroke-width="0.1"
          />
        </svg>`;

      return new MapMarkerIcon(url, anchor, new google.maps.Size(width * scaleMultiplier, height * scaleMultiplier));
    }
  }

  private getDriverIconWithInitialsStreetView(
    initials: string,
    color?: string,
    scaleMultiplier: number = 1,
    isWatched: boolean = false
  ): MapMarkerIcon {
    const parsedColor = this.getParsedColor(color || DefaultIconColors.defaultColor);
    const width: number = 40;
    const height: number = 55;

    const anchorX = width / 2;
    const anchorY = height / 2 + 75;
    const anchor = new google.maps.Point(anchorX, anchorY);

    const whiteBorder = `
      <path
        transform="translate(8 7)"
        d="M1.32414 24.1229L12 34L22.6759 24.1229C23.5187 23.3431 24 22.2322 24 21.0665V4.11193C24 1.84098 22.2091 0 20 0H4C1.79086 0 0 1.84098 0 4.11193V21.0665C0 22.2322 0.481287 23.3431 1.32414 24.1229Z"
        fill="white"
        fill-opacity="0.45"
      />`;

    const watchedAnimation = `
      <path
        d="M1.32414 24.1229L12 34L22.6759 24.1229C23.5187 23.3431 24 22.2322 24 21.0665V4.11193C24 1.84098 22.2091 0 20 0H4C1.79086 0 0 1.84098 0 4.11193V21.0665C0 22.2322 0.481287 23.3431 1.32414 24.1229Z"
        fill="${parsedColor}"
      >
      <animateTransform
        attributeName="transform"
        type="translate"
        dur="1.5s"
        values="8, 7; 6.4, 5.4; 4.9, 3.8; 3.4, 2.4; 1.9, 1.0; 0.6, -0.2; 0.6, -0.2; 0.6, -0.2"
        repeatCount="indefinite">
      </animateTransform>
      <animateTransform
        attributeName="transform"
        type="scale"
        dur="1.5s"
        values="1.0; 1.1; 1.2; 1.3; 1.4; 1.5; 1.6; 1.6; 1.6; 1.6"
        repeatCount="indefinite"
        additive="sum">
      </animateTransform>
      <animate attributeName="opacity" dur="1.5s" keyTimes="0.0; 0.2; 0.4; 0.6; 0.8; 1.0; 1.0; 1.0; 1.0; 1.0"
        values="0.8; 0.6; 0.4; 0.2; 0.0; 0.0; 0.0; 0.0; 0.0; 0.0"
        repeatCount="indefinite"
        begin="0s"
      />
    </path>`;

    const url = `data:image/svg+xml;utf-8,
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
        ${isWatched ? watchedAnimation : ''}
        ${whiteBorder}
        <path
          transform="translate(8 7)"
          d="M4 2.5H20C20.8284 2.5 21.5 3.17157 21.5 4V21.1093C21.5 21.5345 21.3195 21.9398 21.0034 22.2242L12 30.3273L2.99655 22.2242C2.68048 21.9398 2.5 21.5345 2.5 21.1093V4C2.5 3.17157 3.17157 2.5 4 2.5Z"
          fill="${parsedColor}"
          stroke="${parsedColor}" />
        <rect x="11" y="10" width="18" height="19" rx="1" fill="white"/>
        <text x="20" y="24" text-anchor="middle" fill="${parsedColor}" font-family="Roboto-Bold, Roboto, sans-serif"
          font-size="11" font-weight="bold">
          ${initials}
        </text>
      </svg>`;

    return new MapMarkerIcon(url, anchor, new google.maps.Size(width * scaleMultiplier, height * scaleMultiplier));
  }

  /**
   * Get marker icon for unassigned stops
   * @param label two-letter label to render for this marker
   * @param zoomLevel from 0 to 20. 0 means 'World' and 20 'Zoomed in'
   * @param isSelected if the marker is selected or hovered
   * @param iconColor icon color (hexadecimal)
   */
  getMarkerIconUnassignedSvg(
    label: string = 'DL',
    isZoomed: boolean,
    isSelected: boolean = false,
    iconColor: string = DELIVERY_COLOR,
    textAndBorderColor: string = 'white',
    isForecasted = false,
    specialServices?: ShipmentSpecialServiceCd[],
    specialServiceMarks?: SpecialServiceMark[],
    isPreAssigned?: boolean,
    opacity?: number
  ): { svg: string; width: number; height: number } {
    let width: number = 0;
    let height: number = 0;

    const lightThemeColor: string = this.getParsedColor(iconColor); // `%23${iconColor}`;

    const color = lightThemeColor;
    const borderColor = this.getParsedColor(textAndBorderColor);
    const strokeWidth = isSelected ? 5.5 : 1;

    let svg: string = '';

    if (isZoomed) {
      width = this.getIconWidth(MapUtils.MARKER_SIZE.width, specialServices);
      height = MapUtils.MARKER_SIZE.height;
      const filterId = isSelected ? 'shadowUn4' : 'shadowUn3';
      const radius = isSelected ? 13 : 9.5;
      svg += this.createSVG(
        MapUtils.MARKER_SIZE.width,
        width,
        height,
        filterId,
        radius,
        color,
        borderColor,
        strokeWidth,
        label,
        isZoomed,
        isSelected,
        true,
        specialServices,
        specialServiceMarks,
        isPreAssigned,
        opacity
      );
    } else {
      width = this.getIconWidth(MapUtils.MARKER_SIZE_EAGLE_VIEW.width, specialServices);
      height = MapUtils.MARKER_SIZE_EAGLE_VIEW.height;
      const filterId = isSelected ? 'shadowUn2' : 'shadowUn1';
      const radius = isSelected ? 5.5 : 4;
      svg += this.createSVG(
        MapUtils.MARKER_SIZE_EAGLE_VIEW.width,
        width,
        height,
        filterId,
        radius,
        color,
        borderColor,
        strokeWidth,
        label,
        isZoomed,
        isSelected,
        false,
        specialServices,
        specialServiceMarks,
        isPreAssigned
      );
    }

    return { svg, width, height };
  }

  /**
   * Create an SVG with the passed parameters
   */
  private createSVG(
    width: number,
    reserverWidth: number,
    height: number,
    filterId: string,
    radius: number,
    backgroundColor: string,
    textAndBorderColor: string,
    strokeWidth: number,
    stopType: string,
    isZoomed: boolean,
    isSelected: boolean,
    showSpecialServices: boolean,
    specialServices?: ShipmentSpecialServiceCd[],
    specialServiceMarks?: SpecialServiceMark[],
    isPreAssigned?: boolean,
    opacity?: number
  ): string {
    const selectedComponent = `<circle cx="19" cy="19" r="9" fill="${backgroundColor}" />`;
    const zoomedComponent = `
    ${isSelected ? selectedComponent : ''}
    <text x="19" y="23" text-anchor="middle" fill="${textAndBorderColor}" font-family="Roboto-Bold, Roboto, sans-serif"
        font-size="11" font-weight="bold">
        ${stopType}
    </text>
    `;

    return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${reserverWidth}" height="${height}">
    <defs>
        <style type="text/css">
          .ssst0{fill:rgb(255,255,255)}
          .red{fill:red}
          .orange{fill:rgb(230,81,0)}
          .purple{fill:purple}
        </style>

        <filter id="${filterId}" x="${-width / 2}" y="${-height / 2}" width="${width}" height="${height}">
        <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
        <feOffset dx="0" dy="0" />
        <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
        </feMerge>
        </filter>
    </defs>
    ${
      showSpecialServices && !!specialServices && specialServices.length
        ? this.specialServiceSvgContainer(reserverWidth, width, backgroundColor, textAndBorderColor)
        : ''
    }
    <circle cx="${width / 2}" cy="${width / 2}" r="${radius}"
    fill="${isSelected ? textAndBorderColor : backgroundColor}"
    stroke="${isSelected ? backgroundColor : textAndBorderColor}"
    stroke-width="${strokeWidth}" filter="url(%23${filterId})"
    opacity="${opacity || 1}" />
    ${isZoomed ? zoomedComponent : ''}
    ${showSpecialServices ? this.getSpecialServiceSvgList(width, specialServices, specialServiceMarks) : ''}
    ${isPreAssigned ? this.getMarkerIconPreAssignedPickup(isSelected, backgroundColor) : ''}
    </svg>`;
  }

  private getMarkerIconPreAssignedPickup(isSelected: boolean, backgroundColor: string) {
    return `<circle cx="27" cy="${isSelected ? 8 : 9}" r="${isSelected ? 8 : 7}" fill="${backgroundColor}" />
    <text x="27" y="18" text-anchor="middle" fill="white" font-family="Roboto-Bold, Roboto, sans-serif" font-size="18" font-weight="bold">
      <tspan>*</tspan>
    </text>`;
  }

  private specialServiceSvgContainer(width, pointerWidth, contentColor, borderColor): string {
    const rectX = pointerWidth / 2;
    const rectWidth = width - END_CIRCLE_SSS_RATIO * 2 - rectX - 5;
    return `
    <circle cx="${width -
      END_CIRCLE_SSS_RATIO * 2 -
      5}" cy="19" r="${END_CIRCLE_SSS_RATIO}" style="fill:${contentColor};stroke-width:1;stroke:${borderColor}"  />
    <rect x="${rectX}" y="9" width="${rectWidth}" height="20" style="fill:${contentColor};stroke-width:1;stroke:${borderColor};stroke-dasharray: ${rectWidth},20,0,0" />
      `;
  }

  private async svgToPng(svgImg: string, width: number, height: number): Promise<string> {
    return new Promise((resolve) => {
      const pixelRatio = 3;
      const canvas = new OffscreenCanvas(width * pixelRatio, height * pixelRatio);
      const ctx: OffscreenCanvasRenderingContext2D = canvas.getContext('2d');
      ctx.imageSmoothingEnabled = false;
      ctx.scale(pixelRatio, pixelRatio);

      const img = new Image();
      img.onload = async () => {
        ctx.drawImage(img, 0, 0);
        const blob = await canvas.convertToBlob();
        resolve(URL.createObjectURL(blob));
      };
      img.src = svgImg;
    });
  }

  async getMarkerIconUnassigned(
    stopType: TripNodeActivityExtendedCd | PickupTypeCd,
    zoomLevel: number = 10,
    isSelected: boolean = false,
    iconColor: string = DELIVERY_COLOR,
    textAndBorderColor: string = 'white',
    anchor?: google.maps.Point,
    specialServices?: ShipmentSpecialServiceCd[],
    specialServiceMarks?: SpecialServiceMark[],
    isPreAssigned?: boolean,
    opacity?: number
  ): Promise<MapMarkerIcon> {
    const specialServicesToShow = this.getSpecialServicesToShow(specialServices);
    if (specialServicesToShow.length > 0) {
      this.specialServicesService.addPendingMarksToSpecialServices(specialServicesToShow, specialServiceMarks);
    }

    // NOTE: PickupTypeCd is already a 2-letter code that ActivityCdPipe will
    // just return without transforming it. We have to coerce the type here to get it to work.
    const typeLabel = this.activityCdPipe.transform(stopType as TripNodeActivityExtendedCd);
    const isForecasted = stopType === TripActivityExtendedCd.ForecastedPickup;
    const isZoomed = zoomLevel > MapUtils.EAGLE_VIEW_LEVEL;

    const args = JSON.stringify({
      typeLabel: typeLabel,
      isZoomed: isZoomed,
      isSelected: isSelected,
      iconColor: iconColor,
      textAndBorderColor: textAndBorderColor,
      isForecasted: isForecasted,
      specialServicesToShow: specialServicesToShow,
      specialServiceMarks: specialServiceMarks,
      isPreAssigned: isPreAssigned,
      opacity: opacity,
    });
    const oldMapMarkerIcon: MapMarkerIcon = this.markersMap.get(args);
    let newMapMarkerIcon: MapMarkerIcon;

    if (oldMapMarkerIcon) {
      newMapMarkerIcon = new MapMarkerIcon(
        oldMapMarkerIcon.url,
        anchor,
        new google.maps.Size(oldMapMarkerIcon.scaledSize.width, oldMapMarkerIcon.scaledSize.height)
      );
    } else {
      const icon = this.getMarkerIconUnassignedSvg(
        typeLabel,
        isZoomed,
        isSelected,
        iconColor,
        textAndBorderColor,
        isForecasted,
        specialServicesToShow,
        specialServiceMarks,
        isPreAssigned,
        opacity
      );

      let url = 'data:image/svg+xml;utf-8,' + icon.svg;
      url = await this.svgToPng(url, icon.width, icon.height);

      newMapMarkerIcon = new MapMarkerIcon(url, anchor, new google.maps.Size(icon.width, icon.height));
      this.markersMap.set(args, newMapMarkerIcon);
    }

    if (!anchor) {
      anchor = new google.maps.Point(newMapMarkerIcon.scaledSize.width / 2, newMapMarkerIcon.scaledSize.height / 2);
    }
    newMapMarkerIcon.anchor = anchor;

    return newMapMarkerIcon;
  }

  private getSpecialServicesToShow(specialServices: ShipmentSpecialServiceCd[]): ShipmentSpecialServiceCd[] {
    if (this.specialServicesService.showSpecialServiceMapSelected) {
      const specialServicesSelected = this.specialServicesService.specialServiceMapSelected;
      const specialServiceFiltered = this.getSpecialServicesFiltered(specialServices, specialServicesSelected);
      return _filter(
        specialServiceFiltered,
        (service) => !!this.specialServicesService.getSpecialServiceModel(service)
      );
    } else {
      return [];
    }
  }

  /**
   * Get marker icon for assigned stops
   * @param stopType DL, MX, MS, etc.
   * @param zoomLevel from 0 to 20. 0 means 'World' and 20 'Zoomed in'
   * @param isSelected if the marker is selected or hovered
   * @param color
   * @param tripStatus
   * @param sequenceNumber Optional. Sequence number
   * @param anchor
   */
  getMarkerIconAssigned(
    stopType: string,
    zoomLevel: number,
    isSelected: boolean,
    color: string,
    stopStatusCd: TripNodeStatusCd,
    sequenceNumber?: number,
    anchor?: google.maps.Point,
    specialServices?: ShipmentSpecialServiceCd[],
    specialServiceMarks?: SpecialServiceMark[]
  ): MapMarkerIcon {
    let url: string = 'data:image/svg+xml;utf-8,';
    const parsedColor: string = this.getParsedColor(color);
    const specialServiceSanitized = this.getSpecialServicesToShow(specialServices);
    if (zoomLevel > MapUtils.EAGLE_VIEW_LEVEL) {
      // Render the full icon
      const { width, height } = MapUtils.MARKER_SIZE;

      url += this.buildIcon({
        isSelected,
        width,
        height,
        parsedColor,
        sequenceNumber,
        stopType,
        stopStatusCd,
        specialServices: specialServiceSanitized,
        reserverWidth: this.getIconWidth(MapUtils.MARKER_SIZE.width, specialServiceSanitized),
        specialServiceMarks,
      });
    } else {
      const reserverWidth = this.getIconWidth(MapUtils.MARKER_SIZE_EAGLE_VIEW.width, specialServiceSanitized);

      const { width, height } = MapUtils.MARKER_SIZE_EAGLE_VIEW;

      if (isSelected) {
        url += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${reserverWidth || width}" height="${height}">
          <style type="text/css">
            .st0{transform:scale(0.4) translate(10px, 10px);}
            .ssst0{fill:rgb(255,255,255)}
            .red{fill:red}
            .orange{fill:rgb(230,81,0)}
            .purple{fill:purple}
          </style>
          <defs>
              <filter id="shadowAs2" x="-9" y="-9" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          ${
            !!specialServiceSanitized && specialServiceSanitized.length
              ? this.specialServiceSvgContainer(reserverWidth, width, parsedColor, 'rgb(255,255,255)')
              : ''
          }
          <circle cx="9" cy="9" r="4.5" fill="${parsedColor}" stroke="white" stroke-width="1.5" filter="url(%23shadowAs2)" />
          ${
            stopStatusCd === TripNodeStatusCd.COMPLETED
              ? '<path class="st0" fill="white" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>'
              : ''
          }
          ${!!specialServiceSanitized ? this.getSpecialServiceSvgList(width, specialServices) : ''}
          </svg>`;
      } else {
        url += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <style type="text/css">
            .st0{transform:scale(0.4) translate(10px, 10px);}
            .ssst0{fill:rgb(255,255,255)}
            .red{fill:red}
            .orange{fill:rgb(230,81,0)}
            .purple{fill:purple}
          </style>
          <defs>
              <filter id="shadowAs1" x="-9" y="-9" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          ${
            !!specialServiceSanitized && specialServiceSanitized.length
              ? this.specialServiceSvgContainer(reserverWidth, width, parsedColor, 'rgb(255,255,255)')
              : ''
          }

          <circle cx="9" cy="9" r="4.5" fill="${parsedColor}" stroke="white" stroke-width="1" filter="url(%23shadowAs1)" />
          ${
            stopStatusCd === TripNodeStatusCd.COMPLETED
              ? '<path class="st0" fill="white" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>'
              : ''
          }
          ${!!specialServiceSanitized ? this.getSpecialServiceSvgList(width, specialServices) : ''}
          </svg>`;
      }
    }

    if (!anchor) {
      anchor = new google.maps.Point(MapUtils.MARKER_SIZE.width / 2, MapUtils.MARKER_SIZE.height / 2);
    }

    return new MapMarkerIcon(url, anchor);
  }

  getMarkerIconSpotsAndDrops(): MapMarkerIcon {
    // const anchor: google.maps.Point = new google.maps.Point(20, 20);
    let url: string = 'data:image/svg+xml;utf-8,';
    const width: number = 40;
    const height: number = 40;

    url += `
      <svg version="1.2" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <title>spotsAndDrops-svg</title>
      <style>
      .s0 { fill: ${this.getParsedColor('#4a4a4a')} }
      .s1 { fill: ${this.getParsedColor('#ffffff')} }
      </style>
      <circle class="s0" cx="14" cy="14" r="14"/>
      <circle class="s1" cx="14" cy="14" r="12"/>
      <path id="Layer" fill-rule="evenodd" class="s0" d="m24 17v2h-8c0 1.7-1.3 3-3 3-1.7 0-3-1.3-3-3h-4v-10h16v8zm-11 1.5c0-0.5-0.5-1-1-1-0.5 0-1 0.5-1 1 0 0.5 0.5 1 1 1 0.5 0 1-0.5 1-1z"/>
    </svg>
    `;

    return new MapMarkerIcon(url, {
      x: 18,
      y: 18,
    } as google.maps.Point);
  }

  getFocusedMarkerIconSpotsAndDrops(): MapMarkerIcon {
    // const anchor: google.maps.Point = new google.maps.Point(20, 20);
    let url: string = 'data:image/svg+xml;utf-8,';
    const width: number = 40;
    const height: number = 40;

    url += `
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
    <title>spotsAndDrops-svg-svg-svg</title>
    <style>
    .s0 { fill: ${this.getParsedColor('#4a4a4a')} }
    .s1 { fill: ${this.getParsedColor('#ffffff')} }
    </style>
    <circle id="Capa 1 copy" class="s0" cx="16" cy="16" r="16"/>
    <circle id="Capa 1" class="s1" cx="16" cy="16" r="12"/>
    <path id="Layer" fill-rule="evenodd" class="s0" d="m26 19v2h-8c0 1.7-1.3 3-3 3-1.7 0-3-1.3-3-3h-4v-10h16v8zm-11 1.5c0-0.5-0.5-1-1-1-0.5 0-1 0.5-1 1 0 0.5 0.5 1 1 1 0.5 0 1-0.5 1-1z"/>
  </svg>
    `;

    return new MapMarkerIcon(url, {
      x: 20,
      y: 20,
    } as google.maps.Point);
  }

  /**
   * Get marker icon for clusters
   * @param stopType DL, PU, etc.
   * @param clusteredMarkers Number of clustered markers
   */
  getClusterMarkerIcon(markerType: string, clusteredMarkers: number): MapMarkerIcon {
    let url: string = 'data:image/svg+xml;utf-8,';
    const width: number = 40;
    const height: number = 40;

    url += `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
        <defs>
            <filter id="shadowCluster" x="-15" y="-15" width="${width}" height="${height}">
            <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
            <feOffset dx="0" dy="0" />
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
            </filter>
        </defs>
        <circle cx="${width / 2}" cy="${height /
      2}" r="16" fill="white" stroke="black" stroke-width="1" filter="url(%23shadowCluster)" />
        <text x="${width /
          2}" y="24" text-anchor="middle" fill="black" font-family="Roboto-Bold, Roboto, sans-serif" font-size="11" font-weight="bold">
            ${clusteredMarkers} ${markerType}
        </text>
      </svg>
    `;

    return new MapMarkerIcon(url, new google.maps.Point(width / 2, height / 2));
  }

  private convertToDecimal(hexColor: string): number {
    return parseInt(hexColor, 16);
  }

  /**
   * Returns an RGB type color
   * @param color hexa type color (#AABBCC)
   */
  private getParsedColor(color: string): string {
    if (color) {
      if (color.includes('#')) {
        return `rgb(${this.convertToDecimal(color.slice(1, 3))}, ${this.convertToDecimal(
          color.slice(3, 5)
        )}, ${this.convertToDecimal(color.slice(5, 7))})`;
      } else {
        return color;
      }
    } else {
      return DefaultIconColors.defaultColor;
    }
  }

  private buildIcon(options: BuildIconOptions): string {
    const icon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${options.reserverWidth || options.width}" height="${
      options.height
    }">
      <style type="text/css">
        .st0{transform:scale(0.8) translate(8px, 9px);}
        .ssst0{fill:rgb(255,255,255)}
        .red{fill:red}
        .orange{fill:rgb(230,81,0)}
        .purple{fill:purple}
      </style>
      <defs>
        <filter id="${options.isSelected ? 'shadowSq3' : 'shadowSq1'}" x="-19" y="-19" width="${
      options.width
    }" height="${options.height}">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
          <feOffset dx="0" dy="0" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      ${
        !!options.specialServices && options.specialServices.length
          ? this.specialServiceSvgContainer(
              options.reserverWidth,
              options.width,
              options.parsedColor,
              'rgb(255,255,255)'
            )
          : ''
      }
      <circle
      cx="19"
      cy="19"
      r="${options.isSelected ? '13' : '9.5'}"
      fill="${options.isSelected ? 'white' : options.parsedColor}"
      stroke="${options.isSelected ? options.parsedColor : 'white'}"
      stroke-width="${options.isSelected ? '5.5' : '1'}"
      filter="url(%23shadowSq${options.isSelected ? '3' : '1'})" />
      ${options.isSelected ? '<circle cx="19" cy="19" r="9" fill="' + options.parsedColor + '" />' : ''}
      ${this.getIconStopStatusTemplate(options.stopStatusCd, options.stopType, '19')}
      ${this.getIconSequenceNumber(options.parsedColor, options.sequenceNumber, options.isSelected)}
      ${
        !!options.specialServices
          ? this.getSpecialServiceSvgList(options.width, options.specialServices, options.specialServiceMarks)
          : ''
      }
    </svg>`;

    return icon;
  }

  getIconStopStatusTemplate(stopStatusCd: TripNodeStatusCd, stopType: string, x?: string): string {
    if (stopStatusCd === TripNodeStatusCd.COMPLETED) {
      return `
        <svg
          x="4"
          y="1">
          <path
            class="st0"
            fill="white"
            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
        </svg>`;
    }

    return `
      <text
        x="${x || '50%'}"
        y="23"
        text-anchor="middle"
        fill="white"
        font-family="Roboto-Bold, Roboto, sans-serif"
        font-size="11"
        font-weight="bold">
        ${stopType}
      </text>`;
  }

  getIconSequenceNumber(parsedColor: string, sequenceNumber: number, isSelected: boolean) {
    return `<circle cx="27" cy="${isSelected ? 8 : 9}" r="${isSelected ? 8 : 7}" fill="${parsedColor}" />
            <text x="27" y="12" text-anchor="middle" fill="white" font-family="Roboto-Bold, Roboto, sans-serif" font-size="9" font-weight="bold">
              <tspan>${sequenceNumber ? sequenceNumber : ''}</tspan>
            </text>`;
  }

  getStringToHslColor(str: string, s: number, l: number) {
    // str = input string, s = saturation or color, l = lightness of color
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  private getIconWidth(baseWidth: number, specialServices?: ShipmentSpecialServiceCd[]): number {
    let finalWidth: number = baseWidth;

    if (_size(specialServices) > 0) {
      // Adding 2px for spacing
      _forEach(
        specialServices,
        (specialService: ShipmentSpecialServiceCd) =>
          (finalWidth += this.specialServicesService.getSpecialServiceModel(specialService).width + SS_SEPARATION)
      );

      // Adding width for end circle radio
      finalWidth += END_CIRCLE_SSS_RATIO * 2;
    }

    return finalWidth;
  }

  private getSpecialServiceSvgList(
    baseWidth: number,
    specialServices?: ShipmentSpecialServiceCd[],
    specialServicesMarks?: SpecialServiceMark[]
  ) {
    if ((!specialServices && !specialServicesMarks) || !this.specialServicesService.showSpecialServiceMapSelected) {
      return '';
    }

    let result = '';
    let width = baseWidth;

    specialServices.forEach((ss) => {
      const mark = specialServicesMarks?.find((ssMark) => ssMark.specialService === ss && ssMark.active);
      const iconModel = this.specialServicesService.getSpecialServiceModel(ss);

      result = result.concat(`
        <g transform="translate(${width - 5}, 9)"  fill="none" viewBox="0 0 20 20">
          <rect transform="translate(2, 2)" width="${iconModel.width - 4}" height="${iconModel.height -
        4}" fill="white" />
          ${iconModel.svg}
        </g>
        ${
          mark
            ? `<circle  transform="translate(${width +
                5}, 9)" cx="5" cy="5" r="5" fill="red" stroke="black" stroke-width="1" />`
            : ''
        }
      `);
      // Adding 2 px for spacing
      width += iconModel.width + SS_SEPARATION + (mark ? 5 : 0);
    });

    return result;
  }
}
