<div class="pnd-ModifyTripDetailsDetailGridRenderer">
  <ng-container [ngSwitch]="detailType">
    <ng-container *ngSwitchCase="ModifyTripDetailsDetailType.LINE_ITEMS">
      <ng-container *ngTemplateOutlet="lineItems"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ModifyTripDetailsDetailType.NOTES">
      <ng-container *ngTemplateOutlet="notes"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #pickupGrid>
  <ag-grid-angular
    [columnDefs]="pickupLineItemsColumnDefs"
    [rowData]="lineItemsRowData"
    [gridOptions]="lineItemsGridOptions"
    [rowHeight]="getCurrentRowHeight()"
    domLayout="autoHeight"
    rowSelection="multiple"
  >
  </ag-grid-angular>
</ng-template>

<ng-template #deliveryGrid>
  <ag-grid-angular
    [columnDefs]="deliveryLineItemsColumnDefs"
    [rowData]="lineItemsRowData"
    [gridOptions]="lineItemsGridOptions"
    [rowHeight]="getCurrentRowHeight()"
    domLayout="autoHeight"
    rowSelection="multiple"
  >
  </ag-grid-angular>
</ng-template>

<ng-template #equipmentGrid>
  <ag-grid-angular
    [columnDefs]="equipmentLineItemsColumnDefs"
    [rowData]="lineItemsRowData"
    [gridOptions]="lineItemsGridOptions"
    [rowHeight]="getCurrentRowHeight()"
    domLayout="autoHeight"
    rowSelection="multiple"
  >
  </ag-grid-angular>
</ng-template>

<ng-template #lineItems>
  <div
    class="pnd-ModifyTripDetailsDetailGridRenderer__lineItems"
    [style.marginTop.px]="detailGridMarginTop"
    [style.marginBottom.px]="detailGridMarginBottom"
  >
    <ng-container [ngSwitch]="entityTypeCd">
      <ng-container *ngSwitchCase="EntityTypeCd.Pickup">
        <ng-container *ngTemplateOutlet="pickupGrid"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="EntityTypeCd.Shipment">
        <ng-container *ngTemplateOutlet="deliveryGrid"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="EntityTypeCd.Equipment">
        <ng-container *ngTemplateOutlet="equipmentGrid"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #notes>
  <div class="pnd-ModifyTripDetailsDetailGridRenderer__notes">
    <div class="pnd-ModifyTripDetailsDetailGridRenderer__notes-title">
      INSTRUCTIONS & NOTES
    </div>

    <ng-container *ngIf="tenderDetails?.length">
      <div class="pnd-ModifyTripDetailsDetailGridRenderer__note">
        <!-- Note Title -->
        <div class="pnd-ModifyTripDetailsDetailGridRenderer__note-subtitle">
          Carrier Tender Notes ({{ carrierName }})

          <button
            mat-button
            xpoSmallButton
            class="pnd-ModifyTripDetailsDetailGridRenderer__note-copy"
            (click)="copyTenderNotesToClipboard()"
          >
            Copy
          </button>
        </div>

        <!-- Note Description -->
        <div
          *ngFor="let tenderDetail of tenderDetails; trackBy: trackDetailBy"
          [style.height.px]="notesRowHeight"
          class="pnd-ModifyTripDetailsDetailGridRenderer__note-description"
        >
          {{ tenderDetail.statusMessage }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngFor="let item of customerProfileNotes | keyvalue; trackBy: trackItemBy">
      <div *ngIf="item.value && item.value.length > 0" class="pnd-ModifyTripDetailsDetailGridRenderer__note">
        <!-- Note Title -->
        <div class="pnd-ModifyTripDetailsDetailGridRenderer__note-subtitle">
          <ng-container [ngSwitch]="item.key">
            <span *ngSwitchCase="'deliveryInstructions'">Delivery Instruction</span>
            <span *ngSwitchCase="'pickupInstructions'">Pickup Instruction</span>
            <span *ngSwitchCase="'operationNotes'">Operations Note</span>
            <span *ngSwitchCase="'noaNotes'">NOA Note</span>
          </ng-container>

          <button
            mat-button
            xpoSmallButton
            class="pnd-ModifyTripDetailsDetailGridRenderer__note-copy"
            (click)="copyToClipboard(item.value)"
          >
            Copy
          </button>
        </div>

        <!-- Note Description -->
        <div
          *ngFor="let customerProfileNote of item.value; trackBy: trackNotesBy"
          [style.height.px]="notesRowHeight"
          class="pnd-ModifyTripDetailsDetailGridRenderer__note-description"
        >
          {{ customerProfileNote?.note }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
