<xpo-ltl-dialog-title
  [options]="{
    closeButton: false
  }"
  >&nbsp;
  <h1 class="pnd-PdfViewer__title">{{ data.title }}</h1>
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</xpo-ltl-dialog-title>
<div mat-dialog-content class="pnd-PdfViewer">
  <div class="pnd-PdfViewer__message" data-test="pnd-PdfViewer__message">
    {{ displayMessage$ | async }}
    <mat-progress-spinner *ngIf="spinner$ | async" mode="indeterminate"></mat-progress-spinner>
  </div>
  <ng-container *ngFor="let content of pdfContent$ | async; trackBy: trackContentBy">
    <pdf-viewer
      data-test="pnd-PdfViewer__document"
      [src]="content"
      [render-text]="true"
      [fit-to-page]="true"
      [original-size]="true"
      [show-all]="true"
    ></pdf-viewer>
  </ng-container>
</div>
