import { sortBy as _sortBy, uniqBy as _uniqBy } from 'lodash';
import { tassign } from 'tassign';
import { Actions, ActionTypes } from './trips-store.actions';
import { initialState, State } from './trips-store.state';

export function tripsReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        criteria: action.payload.criteria,
      });
    }
    case ActionTypes.setLastUpdate: {
      return tassign(state, {
        lastUpdate: action.payload.lastUpdate,
      });
    }
    case ActionTypes.setSelected: {
      return tassign(state, {
        selectedTrips: action.payload.selectedTrips,
      });
    }

    case ActionTypes.setGridScrollPosition: {
      return tassign(state, {
        gridScrollPosition: action.payload.position,
      });
    }

    case ActionTypes.setSelectedRoutes: {
      return tassign(state, {
        selectedRoutes: _sortBy(_uniqBy(action.payload.selectedRoutes, 'routeInstId'), 'routeInstId'),
      });
    }

    case ActionTypes.setClickedRouteStop: {
      return tassign(state, { clickedRouteStop: action.payload.clickedRouteStop });
    }

    case ActionTypes.setFocusedRoute: {
      return tassign(state, {
        focusedRoute: action.payload.focusedRoute,
      });
    }

    case ActionTypes.setFocusedStopForSelectedRoute: {
      return tassign(state, {
        focusedStopForSelectedRoute: action.payload.focusedStopForSelectedRoute,
      });
    }

    case ActionTypes.setStopsForSelectedRoutes: {
      return tassign(state, {
        stopsForSelectedRoutes: action.payload.stopsForSelectedRoutes,
      });
    }

    case ActionTypes.setSelectedStopsForSelectedRoutes: {
      return tassign(state, {
        selectedStopsForSelectedRoutes: action.payload.selectedStopsForSelectedRoutes,
      });
    }

    case ActionTypes.setShowCompletedStops: {
      return tassign(state, { showCompletedStops: action.payload.showCompletedStops });
    }

    default: {
      return state;
    }
  }
}
