import { Injectable } from '@angular/core';
import { size as _size } from 'lodash';
import { BreadcrumbSegment } from '../../interfaces/breadcrumb-segment.interface';
import { TargetSegmentInterface } from '../../interfaces/target-segment.interface';
import { TripRenderInfo } from '../../interfaces/trip-render-info';

@Injectable({
  providedIn: 'root',
})
export class TripBreadcrumbsHelper {
  /**
   * 350 meters by default
   */
  static readonly connectionLossDistanceThreshold: number = 350;

  /**
   * 100 meters by default
   */
  static readonly distanceThreshold: number = 100;

  /**
   * Returns breadcrumbs split by connection losses. Segments marked as 'connectionLost' will include the tail and head.
   * @param locations
   */
  detectConnectionLossesInBreadcrumbs(locations: google.maps.LatLng[]): BreadcrumbSegment[] {
    const segmentsSplitByConnectionLosses: BreadcrumbSegment[] = [];
    let pointsAccumulator: google.maps.LatLng[] = [];

    for (let i = 0; i < _size(locations); i++) {
      const currentLocation: google.maps.LatLng = locations[i];
      const nextLocation: google.maps.LatLng = locations[i + 1];

      pointsAccumulator.push(currentLocation);

      if (nextLocation) {
        if (
          google.maps.geometry.spherical.computeDistanceBetween(currentLocation, nextLocation) >=
          TripBreadcrumbsHelper.connectionLossDistanceThreshold
        ) {
          segmentsSplitByConnectionLosses.push({
            connectionLost: false,
            points: pointsAccumulator,
          });

          segmentsSplitByConnectionLosses.push({
            connectionLost: true,
            points: [currentLocation, nextLocation],
          });

          pointsAccumulator = [];
        }
      } else {
        segmentsSplitByConnectionLosses.push({
          connectionLost: false,
          points: pointsAccumulator,
        });
      }
    }

    return segmentsSplitByConnectionLosses;
  }

  /**
   * Check if the target location is present in breadcrumbs. It also returns the next index to check so you can
   * improve performance.
   * @param tripRenderInfo
   * @param target
   * @param lastIndexChecked
   */
  breadcrumbsForTargetSegment(
    tripRenderInfo: TripRenderInfo,
    target: google.maps.LatLng,
    lastIndexChecked: number
  ): TargetSegmentInterface {
    const targetSegment: TargetSegmentInterface = {
      nextIndexToCheck: lastIndexChecked,
      targetFoundInBreadcrumb: false,
      points: [],
    };

    let previousDistance: number = -1;
    for (
      let i = lastIndexChecked;
      i < _size(tripRenderInfo.driverLocationBreadcrumbs) && !targetSegment.targetFoundInBreadcrumb;
      i++
    ) {
      const breadcrumb = tripRenderInfo.driverLocationBreadcrumbs[i];
      const currentDistance = google.maps.geometry.spherical.computeDistanceBetween(breadcrumb, target);

      targetSegment.points.push(breadcrumb);

      if (currentDistance < TripBreadcrumbsHelper.distanceThreshold) {
        if (previousDistance !== -1) {
          if (currentDistance < previousDistance) {
            previousDistance = currentDistance;

            if (i === _size(tripRenderInfo.driverLocationBreadcrumbs) - 1) {
              targetSegment.targetFoundInBreadcrumb = true;
              targetSegment.nextIndexToCheck = i + 1;
            }
          } else {
            targetSegment.targetFoundInBreadcrumb = true;
            targetSegment.nextIndexToCheck = i;
            targetSegment.points.pop();
          }
        } else if (i === _size(tripRenderInfo.driverLocationBreadcrumbs) - 1) {
          targetSegment.targetFoundInBreadcrumb = true;
          targetSegment.nextIndexToCheck = i + 1;
        } else {
          previousDistance = currentDistance;
        }
      }
    }

    return targetSegment;
  }
}
