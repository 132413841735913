import { GoogleMapsAPIWrapper } from '@agm/core';
import { Component, Output, EventEmitter, ChangeDetectionStrategy, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'map-zoom-widget',
  templateUrl: './map-zoom-widget.component.html',
  styleUrls: ['./map-zoom-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapZoomWidgetComponent implements AfterViewInit, OnDestroy {
  @Output()
  zoomIn = new EventEmitter<void>();
  @Output()
  zoomOut = new EventEmitter<void>();
  @Output()
  drawModeChange = new EventEmitter<boolean>();

  private googleMap: google.maps.Map;
  constructor(private googleMapsApi: GoogleMapsAPIWrapper) {}

  ngAfterViewInit(): void {
    this.googleMapsApi.getNativeMap().then((map) => {
      if (map) {
        this.googleMap = (map as any) as google.maps.Map;
        this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
          document.getElementsByClassName('pnd-map-zoom')[0]
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.googleMap) {
      this.googleMap.controls[google.maps.ControlPosition.BOTTOM_RIGHT].clear();
    }
  }

  onPolygonDrawToggle(inDrawMode: boolean): void {
    this.drawModeChange.emit(inDrawMode);
  }

  onZoomIn(event: any): void {
    this.zoomIn.emit();
  }

  onZoomOut(event: any): void {
    this.zoomOut.emit();
  }
}
