import { Component, ViewEncapsulation } from '@angular/core';
import { TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ModifyTripDetailsBaseGridItem } from '../../../components/trips/modify-trip-details/models/modify-trip-details-grid-item.model';
import { XpoColors } from '../../services/xpo-colors';

@Component({
  selector: 'pnd-modify-trip-details-activity-type-cell-renderer',
  templateUrl: './modify-trip-details-activity-type-cell-renderer.html',
  styleUrls: ['./modify-trip-details-activity-type-cell-renderer.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-ModifyTripDetailsActivityTypeCellRenderer' },
})
export class ModifyTripDetailsActivityTypeCellRendererComponent implements ICellRendererAngularComp {
  readonly XpoColors = XpoColors;
  activityType: string;
  showPreLabel = false;
  isClickable = false;

  agInit(params: ICellRendererParams): void {
    const gridItem = params.data as ModifyTripDetailsBaseGridItem;
    this.activityType = params.value;
    this.showPreLabel = gridItem && gridItem.tripNodeStatusCd === TripNodeStatusCd.PRE_ASSIGNED;
    this.isClickable = !!params.colDef.onCellClicked;
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
}
