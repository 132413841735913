import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DockRoutesStoreEffects } from '@pnd-store/dock-routes-store/dock-routes.effects';
import { dockRoutesReducer } from '@pnd-store/dock-routes-store/dock-routes.reducer';
import { StateKey } from '@pnd-store/dock-routes-store/dock-routes.state';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, dockRoutesReducer),
    EffectsModule.forFeature([DockRoutesStoreEffects]),
  ],
})
export class DockRoutesStoreModule {}
