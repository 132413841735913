import { LatLong } from '@xpo-ltl/sdk-common';
import { MapMarkerIcon } from './map-marker-icon.model';

export class MapMarker implements LatLong {
  latitude: number;
  longitude: number;
  icon: MapMarkerIcon;
}

export class InteractiveMapMarker extends MapMarker {
  isSelected: boolean;
  isFocused: boolean;
  isVisible: boolean;
  placement?: 'left' | 'right' | 'top' | 'bottom';
}

export class DraggableMapMarker extends InteractiveMapMarker {
  draggable: boolean;
  infowindowsOpen: boolean;
}

export class UnmappedStopMarker extends DraggableMapMarker {
  constructor(latitude: number, longitude: number, icon: MapMarkerIcon, draggable: boolean, infowindowsOpen: boolean) {
    super();
    this.latitude = latitude;
    this.longitude = longitude;
    this.icon = icon;
    this.draggable = draggable;
    this.infowindowsOpen = infowindowsOpen;
  }
}

/**
 * AgmMarker dragstart, drag, dragend, mouseover, and mouseout events return this instead of
 * the MouseEvent the interface claims
 */
export interface MarkerDragEvent {
  coords: google.maps.LatLngLiteral;
}
