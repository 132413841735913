import { EventItem, PastDueShipmentIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';

export const StateKey = 'pastDueShipmentsState';

export interface RouteError {
  routeName: string;
  error: string;
}

export interface State {
  readonly selectedPastDueShipments: EventItem<PastDueShipmentIdentifier>[];
  readonly pastDueShipmentsLastUpdate: Date;
}

export const initialState: State = {
  selectedPastDueShipments: [],
  pastDueShipmentsLastUpdate: new Date(0),
};
