import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

@Component({
  selector: 'app-sic-marker-compass',
  templateUrl: './sic-marker-compass.component.html',
  styleUrls: ['./sic-marker-compass.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SicMarkerCompassComponent implements OnChanges {
  @Input() rotationAngle: number = 0;
  transform: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['rotationAngle']) {
      this.transform = `rotate(${this.rotationAngle} 20 20)`;
      ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    }
  }
}
