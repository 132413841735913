import { Component, ViewEncapsulation } from '@angular/core';
import { XpoGridCellEditorParams } from '../../models/inline-editing-error.model';
import { XpoGridBaseCellEditor } from '../base-editor.class';

export interface XpoGridNumericCellEditorParams extends XpoGridCellEditorParams {
  maxLength?: number;
  maxValue?: number;
}

@Component({
  selector: 'xpo-numeric-cell-editor',
  templateUrl: './numeric-editor.component.html',
  styleUrls: ['./numeric-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--numeric',
  },
})
export class XpoGridNumericCellEditorComponent extends XpoGridBaseCellEditor {
  maxLength: number;
  maxValue: number;
  declare value: number;

  inputPattern: string = '^$|^([-]{0,1}[0-9]*[.,]{0,1}[0-9]+)$';

  agInit(params: XpoGridNumericCellEditorParams): void {
    super.agInit(params);
    this.maxLength = params.maxLength;
    this.maxValue = params.maxValue;
  }

  isCancelAfterEnd(): boolean {
    return this.value && this.value.toString().length > this.maxLength;
  }

  evaluateKey(event): boolean {
    if (this.isTextSelected() && this.isKeyPressedNumeric(event.key)) {
      return true;
    }

    if (!this.isValidKey(event.key) && !this.isKeyPressedNumeric(event.key)) {
      return false;
    }

    if (!this.isValidKey(event.key) && this.maxLength && event.target.value.length > this.maxLength - 1) {
      return false;
    }

    return true;
  }

  private isKeyPressedNumeric(value): boolean {
    const regex = new RegExp('^[0-9,.-]$');
    return regex.test(value);
  }
}
