import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

@Component({
  selector: 'app-radio-button-selection-cell-renderer',
  templateUrl: './radio-button-selection-cell-renderer.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonSelectionCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: any): void {
    this.params = params;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  refresh(params: any): boolean {
    this.params = params;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    return true;
  }
}
