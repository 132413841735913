import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusChipComponent implements ICellRendererAngularComp {
  status = 'planning';
  chipCssClass: string;

  agInit(params: any): void {
    switch (params.value) {
      case 'ASGN':
        this.status = 'Assigned';
        break;
      case 'COMP':
        this.status = 'Completed';
        break;
      case 'PEND':
        this.status = 'Pending';
        break;
      case 'PREA':
        this.status = 'Pre-assigned';
        break;
    }
    this.chipCssClass = `pnd-StatusChip pnd-StatusChip-${this.status.toLowerCase()}`;
  }

  refresh(): boolean {
    return false;
  }
}
