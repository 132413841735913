import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { StopWindow, StopWindowNote } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface GridDetailsWithNotesRenderModel extends ICellRendererParams {
  getDetailRowData: (node) => void;
  getNotes: (node: RowNode) => GridDetailsNote[];
}

export interface GridDetailsNote {
  title: string;
  value: string;
}

export enum GridDetailNotesRenderHeight {
  HEADER = 56,
  TITLE = 42,
  NOTE = 16,
  FOOTER = 16,
  TYPE_SEPARATOR = 32,
}

@Component({
  selector: 'pnd-grid-details-notes-render',
  templateUrl: './grid-details-notes-render.component.html',
  styleUrls: ['./grid-details-notes-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-GridDetailsNotesRenderComponent' },
})
export class GridDetailsNotesRenderComponent implements ICellRendererAngularComp {
  // TODO: Refactor all grids that use stop window to return GridDetailsNote[]
  stopsWindows: StopWindow[] = [];

  notes: GridDetailsNote[];

  refresh(params: GridDetailsWithNotesRenderModel): boolean {
    return false;
  }
  trackNotesBy(index, note: GridDetailsNote) {
    if (!note) {
      return null;
    }
    return note?.value + index;
  }
  trackStopBy(index, stop: StopWindowNote): string | null {
    if (!stop) {
      return null;
    }
    return stop?.note + index;
  }
  agInit(params: GridDetailsWithNotesRenderModel): void {
    if (params.data?.stopWindow) {
      this.stopsWindows = params?.data?.stopWindow ?? [];
    } else {
      this.notes = params?.getNotes(params.node) ?? [];
    }
  }
}
