<h1 mat-dialog-title data-test="confirm-cancel-title" class="xpo-ConfirmDialog-header">{{ title }}</h1>

<div mat-dialog-content>
  <div data-test="confirm-cancel-content" [innerHTML]="content"></div>
</div>

<div mat-dialog-actions>
  <div class="pnd-ConfirmCancel-actionButtons--left">
    <ng-container *ngIf="confirmButtonTitle">
      <button
          data-test="confirm-cancel-confirmBtn"
          type="button"
          mat-flat-button
          [mat-dialog-close]="ConfirmCancelAction.CONFIRM">
        {{ confirmButtonTitle }}
      </button>
    </ng-container>

    <ng-container *ngIf="cancelButtonTitle">
      <button
          data-test="confirm-cancel-cancelBtn"
          type="button"
          mat-stroked-button
          [mat-dialog-close]="ConfirmCancelAction.CANCEL">
        {{ cancelButtonTitle }}
      </button>
    </ng-container>
  </div>
  <div class="pnd-ConfirmCancel-actionButtons--right">
    <ng-container *ngIf="altActionButtonTitle">
      <button
          data-test="confirm-cancel-altBtn"
          type="button"
          mat-stroked-button
          [mat-dialog-close]="ConfirmCancelAction.ALT_ACTION">
        {{ altActionButtonTitle }}
      </button>
    </ng-container>
  </div>
</div>
