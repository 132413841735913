import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouteStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ModifyTripDetailsMainGroupType } from '../../../components/trips/modify-trip-details/enums/modify-trip-details-main-group-type.enum';
import { ModifyTripDetailsStopGridItem } from '../../../components/trips/modify-trip-details/models/modify-trip-details-grid-item.model';

@Component({
  selector: 'pnd-modify-trip-details-group-cell-renderer',
  templateUrl: './modify-trip-details-group-cell-renderer.component.html',
  styleUrls: ['./modify-trip-details-group-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyTripDetailsCellRendererComponent implements ICellRendererAngularComp {
  groupName: string;
  rowCount: number;
  trailerNbr: string;
  trailerInfo: string;
  trailerStatusCd: string;
  isCldv: boolean = false;
  agInit(params: ICellRendererParams): void {
    const gridItem: ModifyTripDetailsStopGridItem = params?.node?.allLeafChildren?.[0]?.data;
    if (gridItem && gridItem.mainGroupType === ModifyTripDetailsMainGroupType.ROUTE) {
      this.trailerNbr = gridItem.trailerNbr;
      const liftGateInd: boolean = gridItem?.liftGateInd;
      const trailerLength: number = gridItem?.trailerLength;
      const feetAvailable: number = gridItem?.feetAvailable;

      this.trailerInfo = this.trailerNbr;
      this.trailerStatusCd = gridItem?.trailerStatusCd;
      this.isCldv = gridItem?.trailerStatusCd?.toLocaleLowerCase().includes('closed for delivery');
      if (
        [RouteStatusCd.NEW, RouteStatusCd.DISPATCHED, RouteStatusCd.RETURNING, RouteStatusCd.COMPLETE].includes(
          gridItem?.routeStatusCd
        )
      ) {
        if (trailerLength && liftGateInd) {
          this.trailerInfo += ` (${trailerLength}', LG)`;
        } else if (trailerLength) {
          this.trailerInfo += ` (${trailerLength}')`;
        }
      }

      if ([RouteStatusCd.DISPATCHED, RouteStatusCd.RETURNING].includes(gridItem?.routeStatusCd) && feetAvailable) {
        this.trailerInfo += ` ${feetAvailable}' Avail`;
      }
    }

    this.groupName = params.value;
    this.rowCount = params.node && params.node.allChildrenCount ? params.node.allChildrenCount : 0;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
