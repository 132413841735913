import { TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { TripNodeActivityExtendedCd } from '../../inbound-planning/shared/models/stop-type.model';

export const StateKey = 'modifyTripDetailsState';

/**
 * Uniquely identifies a trip node activity
 */
export interface ModifyTripActivityId {
  tripInstId: number;
  routeInstId: number;
  tripNodeSequenceNbr: number;
  tripNodeActivitySequenceNbr: number;
  activityCd: TripNodeActivityExtendedCd;

  pickupRequestInstId?: number;
  shipmentInstId?: number;
  proNbr?: string;

  uniqueId?: string; // Row Id used to uniquely identify this activity in a grid
  tripNodeStatusCd?: TripNodeStatusCd;
  callNbr?: string;
  sicCd?: string;
  customer_name?: string;
  customer_acct_instId?: number;
  customer_address?: string;
  customer_city?: string;
  customer_state?: string;
}

export interface State {
  readonly lastUpdate: Date;
  readonly selectedActivities: ModifyTripActivityId[];
}

export const initialState: State = {
  lastUpdate: new Date(0),
  selectedActivities: [],
};
