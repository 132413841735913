import { FaultResponse, MoreInfo } from '@xpo-ltl/sdk-common';

export interface PartialMoreInfo extends Partial<MoreInfo> {
  message?: string;
  location?: string;
}

export interface PartialFaultResponse extends Partial<Pick<FaultResponse, Exclude<keyof FaultResponse, 'moreInfo'>>> {
  message?: string;
  errorCode?: string;
  trace?: string;
  moreInfo?: PartialMoreInfo[];
  rootCause?: FaultResponse;
}

// LEONARDO TODO
// interface PartialEnvelope extends Partial<Pick<Envelope, Exclude<keyof Envelope, 'code' | 'error'>>> {
//   first?: string;
//   code?: string | number;
//   transactionTimestamp?: Date;
//   error?: PartialFaultResponse;
// }

export class GenericErrorLazyTypedModel {
  first?: string;
  code?: string | number;
  transactionTimestamp?: Date;
  error?: PartialFaultResponse;

  constructor(props?: any) {
    if (props) {
      this.first = props.first ? props.first : undefined;
      this.code = props.code ? props.code.toString() : undefined;
      this.transactionTimestamp = props.transactionTimestamp ? props.transactionTimestamp : undefined;
      this.error = props.error ? props.error : undefined;
    }
  }
}
