import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { MapMarkerInfo } from '../map-marker-info.model';
import { InteractiveMapMarker } from '../markers/map-marker';

export class MapMarkerWithInfoWindow<INFO_TYPE = MapMarkerInfo> extends InteractiveMapMarker {
  markerInfo?: INFO_TYPE;
  isInfoWindowOpened?: boolean = false;
  panOnOpen?: boolean = false;
  isDragging?: boolean = false;
  specialServices?: ShipmentSpecialServiceCd[] = [];
  isPreAssigned?: boolean = false;
}
