import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { XpoGridSelectEditorOption } from './models/select-editor-options.models';

@Component({
  selector: 'xpo-select-cell-editor',
  templateUrl: './select-editor.component.html',
  styleUrls: ['./select-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--select',
  },
})
export class XpoGridSelectCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  options: XpoGridSelectEditorOption[];
  params: any;
  selectedValue: any;
  @ViewChild('optionsList', { static: false }) selectRef: MatSelect;

  agInit(params): void {
    this.params = params;
    this.options = params.optionsEnum ? Object.values(params.optionsEnum) : params.options;
    this.selectedValue = this.params.value;
  }

  ngAfterViewInit(): void {
    // if the select the select popup open previous to the component creation
    // it would take the grid's width, i tried looking for the hookCycles
    // but none seems to work for this scenario
    setTimeout(() => {
      this.selectRef.open();
    });
  }

  onFocusOut(e): void {
    if (!e.relatedTarget) {
      this.params.api.stopEditing();
    }
  }
  trackOptionsBy(index, option: XpoGridSelectEditorOption): string | null {
    if (!option) {
      return null;
    }
    return index + option?.label;
  }
  onOptionSelected(event: any): void {
    this.selectedValue = event;
    this.params.api.stopEditing();
  }

  getValue(): any {
    return this.selectedValue ?? this.params.value;
  }
}
