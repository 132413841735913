import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './driver-store.state';

export const driversState = createFeatureSelector<State>(StateKey);

export const driversCurrentLocation = createSelector(driversState, (state: State) => state.driversCurrentLocation);

export const highlightedDriver = createSelector(driversState, (state: State) => state.highlightedDriver);

export const focusedDriver = createSelector(driversState, (state: State) => state.focusedDriver);
