import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppointmentStatusCd, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { RowNode } from 'ag-grid-community';
import { parseXML } from 'jquery';
import { size as _size, get as _get } from 'lodash';
import { BehaviorSubject, Observable, merge, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { SelectionSummaryData } from './../components/selection-summary/selection-summary-data.class';
import { SpecialServiceMark } from './../components/service-icons/model/special-service-mark';

export interface SpecialServiceSvg {
  width: number;
  height: number;
  svg: string;
}

@Injectable({
  providedIn: 'root',
})
export class SpecialServicesService {
  get specialServiceMapSelected() {
    return this.specialServiceMapSelectedSubject.value;
  }
  get showSpecialServiceMapSelected() {
    return this.showSpecialServiceMapSelectedSubject.value;
  }

  constructor(private http: HttpClient) {}

  static specialServiceMapSelectable = [
    {
      specialService: ShipmentSpecialServiceCd.APPOINTMENT,
      url: 'assets/xpo-icons/ss_appointment.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.SHOW,
      url: 'assets/xpo-icons/ss_show.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.BULK_LIQUID,
      url: 'assets/xpo-icons/ss_bulk_liquid.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.CONSTRUCTION_AND_UTILITY_SITES_DELIVERY,
      url: 'assets/xpo-icons/ss_construction_and_utility_sites_delivery.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.DRIVER_COLLECT,
      url: 'assets/xpo-icons/ss_cash_on_delivery.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.EXCESSIVE_LENGTH,
      url: 'assets/xpo-icons/ss_excessive_length.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.FOOD,
      url: 'assets/xpo-icons/ss_food.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.FORK_LIFT,
      url: 'assets/xpo-icons/ss_fork_lift.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.FREEZABLE,
      url: 'assets/xpo-icons/ss_freezable.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.GUARANTEED,
      url: 'assets/xpo-icons/ss_guaranteed.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.GUARANTEED_BY_NOON,
      url: 'assets/xpo-icons/ss_guaranteed_by_noon.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.HAZMAT,
      url: 'assets/xpo-icons/ss_hazmat.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.INSIDE_PICKUP,
      url: 'assets/xpo-icons/ss_inside_pickup.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.LIFT_GATE,
      url: 'assets/xpo-icons/ss_lift_gate.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.PUP_ONLY,
      url: 'assets/xpo-icons/ss_pup_only.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.PALLET_JACK,
      url: 'assets/xpo-icons/ss_pallet_jack.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE,
      url: 'assets/xpo-icons/ss_rapid_remote_service.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.RESIDENTIAL_DELIVERY,
      url: 'assets/xpo-icons/ss_residential_delivery.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.RETAIL,
      url: 'assets/xpo-icons/ss_retail_rollout.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.PREMIUM,
      url: 'assets/xpo-icons/ss_premium_services.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.MUST_ARRIVE_BY_DATE,
      url: 'assets/xpo-icons/ss_premium_services.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.TIME_DATE_CRITICAL,
      url: 'assets/xpo-icons/ss_time_date_critical.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.TRAP_SERVICE,
      url: 'assets/xpo-icons/ss_trap_service.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.VOLUME_PICKUP,
      url: 'assets/xpo-icons/ss_volume_pickup.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.STRAND,
      url: 'assets/xpo-icons/ss_strand.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.AFTER_HOURS_PICKUP,
      url: 'assets/xpo-icons/ss_after_hours.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.DESTINATION_APPOINTMENT,
      url: 'assets/xpo-icons/ss_destination_appointment.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.LIVE,
      url: 'assets/xpo-icons/ss_live_delivery.svg',
    },
    {
      specialService: ShipmentSpecialServiceCd.LIMITED_ACCESS,
      url: 'assets/xpo-icons/ss_limited_access.svg',
    },
  ];

  private specialServiceModel: Map<ShipmentSpecialServiceCd, SpecialServiceSvg> = new Map<
    ShipmentSpecialServiceCd,
    SpecialServiceSvg
  >();

  private readonly specialServiceMapSelectedSubject = new BehaviorSubject<ShipmentSpecialServiceCd[]>([]);
  readonly specialServiceMapSelected$ = this.specialServiceMapSelectedSubject.asObservable();

  private readonly showSpecialServiceMapSelectedSubject = new BehaviorSubject<boolean>(false);
  readonly showSpecialServiceMapSelected$ = this.showSpecialServiceMapSelectedSubject.asObservable();

  readonly specialServiceChanges$ = merge(this.specialServiceMapSelected$, this.showSpecialServiceMapSelected$);

  private readonly appointmentMarkService = ShipmentSpecialServiceCd.APPOINTMENT;

  static getSpecialServiceFilterEnum() {
    return {
      [ShipmentSpecialServiceCd.APPOINTMENT]: 'Appointment',
      [ShipmentSpecialServiceCd.BULK_LIQUID]: 'Bulk Liquid',
      [ShipmentSpecialServiceCd.CONSTRUCTION_AND_UTILITY_SITES_DELIVERY]: 'Construction Delivery',
      [ShipmentSpecialServiceCd.CUSTOMS_IN_BOND]: 'Customs In-Bond',
      [ShipmentSpecialServiceCd.DRIVER_COLLECT]: 'Driver Collect',
      [ShipmentSpecialServiceCd.EXCESSIVE_LENGTH]: 'Excessive Length',
      [ShipmentSpecialServiceCd.FOOD]: 'Food',
      [ShipmentSpecialServiceCd.FORK_LIFT]: 'Fork Lift',
      [ShipmentSpecialServiceCd.FREEZABLE]: 'Freezable',
      [ShipmentSpecialServiceCd.GUARANTEED_BY_NOON]: 'G12',
      [ShipmentSpecialServiceCd.GUARANTEED]: 'Guaranteed',
      [ShipmentSpecialServiceCd.HAZMAT]: 'Hazmat',
      [ShipmentSpecialServiceCd.INSIDE_PICKUP]: 'Inside Delivery',
      [ShipmentSpecialServiceCd.LIFT_GATE]: 'LiftGate',
      [ShipmentSpecialServiceCd.NOTIFY_ON_ARRIVAL]: 'Notify On Arrival',
      [ShipmentSpecialServiceCd.PUP_ONLY]: 'Pup Only',
      [ShipmentSpecialServiceCd.PALLET_JACK]: 'Pallet Jack',
      [ShipmentSpecialServiceCd.RESIDENTIAL_DELIVERY]: 'Residential Delivery',
      [ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE]: 'RRS',
      // [ShipmentSpecialServiceCd.RETAIL]: 'Retail',
      [ShipmentSpecialServiceCd.PREMIUM]: 'Premium',
      [ShipmentSpecialServiceCd.SHOW]: 'Show',
      [ShipmentSpecialServiceCd.TIME_DATE_CRITICAL]: 'TDC',
      [ShipmentSpecialServiceCd.TRAP_SERVICE]: 'Trap',
      [ShipmentSpecialServiceCd.VOLUME_PICKUP]: 'Volume Pickup',
      [ShipmentSpecialServiceCd.STRAND]: 'Strand',
      [ShipmentSpecialServiceCd.AFTER_HOURS_PICKUP]: 'After Hours, Pickup',
      [ShipmentSpecialServiceCd.DESTINATION_APPOINTMENT]: 'Destination Appointment',
      [ShipmentSpecialServiceCd.LIVE]: 'Live',
      [ShipmentSpecialServiceCd.LIMITED_ACCESS]: 'Limited Access',
    };
  }

  updateSpecialServiceMapSelected(selected: ShipmentSpecialServiceCd[]) {
    this.specialServiceMapSelectedSubject.next(selected);
  }

  updateShowSpecialServiceMapSelected(selected: boolean) {
    this.showSpecialServiceMapSelectedSubject.next(selected);
  }

  // If the viewbox is undefined, null, or zero return the attribute value
  // We need this if a vewbox is not defined
  getDimensionValue(viewBoxValue: number | null | undefined, attributeValue: number | null | undefined): number {
    if (typeof viewBoxValue === 'number' && viewBoxValue > 0) {
      return viewBoxValue;
    } else if (typeof attributeValue === 'number' && attributeValue > 0) {
      return attributeValue;
    } else {
      return 32; // Default value
    }
  }

  loadSpecialServiceIconMarks(): Observable<Subscription> {
    return merge(
      SpecialServicesService.specialServiceMapSelectable.map((selectable) => {
        return this.http
          .get(selectable.url, { responseType: 'text' })
          .pipe(take(1))
          .subscribe((response: string) => {
            try {
              const xml: XMLDocument = parseXML(response);
              const svg: SVGSVGElement = xml.getElementsByTagName('svg').item(0);

              this.specialServiceModel.set(selectable.specialService, {
                width: Math.ceil(this.getDimensionValue(svg?.viewBox?.baseVal?.width, svg?.width?.baseVal?.value)),
                height: Math.ceil(this.getDimensionValue(svg?.viewBox?.baseVal?.height, svg?.height?.baseVal?.value)),
                svg: svg?.innerHTML?.replace(new RegExp('#', 'g'), '%23'),
              });
            } catch (err) {
              console.error(`SpecialServicesService.loadSpecialServiceIconMarks error: `, err);
            }
          });
      })
    ).pipe(tap(() => this.specialServiceMapSelectedSubject.next(this.specialServiceMapSelectedSubject.value)));
  }

  getSpecialServiceModel(specialService: ShipmentSpecialServiceCd): SpecialServiceSvg {
    return this.specialServiceModel.get(specialService);
  }

  getSpecialServiceAppointmentMark(data, appointmentStatusCdPath: string): SpecialServiceMark[] {
    const appointmentStatusCd: AppointmentStatusCd = _get(
      data,
      appointmentStatusCdPath,
      AppointmentStatusCd.NO_APPOINTMENT
    );

    if (appointmentStatusCd !== null && appointmentStatusCd !== AppointmentStatusCd.NO_APPOINTMENT) {
      return [
        {
          specialService: this.appointmentMarkService,
          active: appointmentStatusCd === AppointmentStatusCd.APPOINTMENT_MISSING,
        },
      ];
    }
  }

  getSpecialServiceAppointmentSummaryMark(source, accumulator: SelectionSummaryData, appointmentStatusCdPath: string) {
    const appointmentStatusCd: AppointmentStatusCd = _get(
      source,
      appointmentStatusCdPath,
      AppointmentStatusCd.NO_APPOINTMENT
    );

    if (appointmentStatusCd !== AppointmentStatusCd.NO_APPOINTMENT) {
      const specialServiceMark = accumulator.specialServicesMarks.find(
        (mark) => mark.specialService === this.appointmentMarkService && mark.active
      );

      if (!specialServiceMark) {
        accumulator.specialServicesMarks = [
          {
            specialService: this.appointmentMarkService,
            active: appointmentStatusCd === AppointmentStatusCd.APPOINTMENT_MISSING,
          },
        ];
      }
    }
  }

  getSpecialServiceAppointmentForMapMarkers(appointmentStatusCd: AppointmentStatusCd) {
    const result: SpecialServiceMark[] = [];

    if (appointmentStatusCd !== AppointmentStatusCd.NO_APPOINTMENT) {
      result.push({
        specialService: this.appointmentMarkService,
        active: appointmentStatusCd === AppointmentStatusCd.APPOINTMENT_MISSING,
      });
    }

    return result;
  }

  addPendingMarksToSpecialServices(
    specialServices: ShipmentSpecialServiceCd[],
    specialServiceMarks: SpecialServiceMark[]
  ): void {
    specialServiceMarks?.forEach((mark) => {
      if (!specialServices.some((specialService) => specialService === mark.specialService)) {
        specialServices.unshift(mark.specialService);
      }
    });
  }

  // Score special services so that they sort according to the number of them
  getSpecialServicesComparator(nodeA: RowNode, nodeB: RowNode): number {
    const countsA = nodeA?.data?.route ?? nodeA?.data;
    const servicesA = countsA?.specialServices
      ? countsA.specialServices
      : countsA?.specialServiceSummary
      ? countsA.specialServiceSummary
      : [];

    const nodeAScore = _size(servicesA) ?? 0;

    const countsB = nodeB?.data?.route ?? nodeB?.data;
    const servicesB = countsB?.specialServices
      ? countsB.specialServices
      : countsB?.specialServiceSummary
      ? countsB.specialServiceSummary
      : [];

    const nodeBScore = _size(servicesB) ?? 0;

    if (nodeAScore === nodeBScore && nodeAScore > 0) {
      // same number of services, so sort by service name

      if (servicesA[0].specialService) {
        return servicesA[0].specialService.localeCompare(servicesB[0].specialService);
      } else {
        return servicesA[0].localeCompare(servicesB[0]);
      }
    } else {
      return nodeAScore - nodeBScore;
    }
  }
}
