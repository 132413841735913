import { RouteStatusCd, RouteCategoryCd } from '@xpo-ltl/sdk-common';
import { ReleaseRouteFormFields } from './release-route-form-fields';

export class ReleaseRouteDialogData {
  focusedField: ReleaseRouteFormFields;
  routeName: string;
  routeInstId: number;
  startTime: string; // 24hr format HH:MM
  doorNbr: string;
  trailerNbr: string;
  dockLocation: string;
  nearestDoorNbr: string;
  routeStatusCd: RouteStatusCd;
  routeCategoryCd: RouteCategoryCd;
  xdockReleaseInd: boolean;
  sicCd: string;
}
