import { Injectable } from '@angular/core';
import { PndStore } from '@pnd-store/pnd-store';
import { EquipmentPipe } from '@xpo-ltl/ngx-ltl';
import { PickupFormTypeEnum } from '@xpo-ltl/ngx-ltl-pickup-request';
import {
  CityOperationsApiService,
  HookEquipmentStop,
  ListHookEquipmentStopsPath,
  ListHookEquipmentStopsQuery,
  ListHookEquipmentStopsResp,
  ListPnDUnassignedPickupsResp,
  ListPnDUnassignedPickupsRqst,
  UnassignedPickup,
} from '@xpo-ltl/sdk-cityoperations';
import {
  AccountDetail,
  HookStatusCd,
  PickupLineItemSearchFilter,
  PickupLineItemSearchRecord,
  PickupRequest,
  PickupTypeCd,
  UnassignPickupsSourceCd,
  XrtSearchQueryHeader,
} from '@xpo-ltl/sdk-common';
import { NotificationMessageService, NotificationMessageStatus } from 'core';
import { flatten as _flatten, map as _map, sortBy as _sortBy, uniqWith as _uniqWith } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { BoardUtils } from 'shared/board-utils';
import * as GlobalFilterStoreSelectors from '../../../store/global-filters-store/global-filters-store.selectors';
import * as PndStoreState from '../../../store/pnd-store.state';
import { UnassignedPickupsSearchCriteria } from '../../../store/unassigned-pickups-store/unassigned-pickups-search-criteria.interface';
import { UnassignedPickupsGroupName } from '../../components/unassigned-pickups/enums/unassigned-pickups-group-name.enum';
import { UnassignedPickupsServiceFields } from '../../components/unassigned-pickups/enums/unassigned-pickups-service-fields.enum';
import { UnassignedPickupsSummary } from '../../components/unassigned-pickups/models/unassigned-pickup-summary';
import { SpecialServicesHelper } from '../helpers/special-services/special-services.helper';
import { PickupTypeCdPipe } from '../pipes';
import { UnassignedPickupsCriteriaService } from './unassigned-pickups-criteria.service';

const MIN_TIME_BETWEEN_SEARCHES = 0; // min time between searches when criteria is the same
@Injectable({
  providedIn: 'root',
})
export class UnassignedPickupsService {
  private lastSearchCriteriaUpdate = 0;
  cityAreaNames: BehaviorSubject<string[]> = new BehaviorSubject([]);
  cityAreaNames$ = this.cityAreaNames.asObservable();

  private readonly header: XrtSearchQueryHeader = {
    fields: Object.values(UnassignedPickupsServiceFields),
    pageNumber: 1,
    pageSize: 10000,
    sortExpressions: [],
    excludeFields: [],
  };

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private unassignedPickupsCriteriaService: UnassignedPickupsCriteriaService,
    private pndStore$: PndStore<PndStoreState.State>,
    private equipmentPipe: EquipmentPipe,
    private pickupTypeCdPipe: PickupTypeCdPipe,
    private notificationMessageService: NotificationMessageService
  ) {}

  private selectedPickupLineItemsSubject = new BehaviorSubject<any[]>([]);
  readonly selectedPickupLineItems$ = this.selectedPickupLineItemsSubject.asObservable();
  get selectedPickupLineItems(): any[] {
    return this.selectedPickupLineItemsSubject.value;
  }

  private onParentRowSelectionUpdatedSubject = new BehaviorSubject<string>(null);
  readonly onParentRowSelectionUpdated$ = this.onParentRowSelectionUpdatedSubject.asObservable();

  private triggerPickupRequestPanelFromMapSubject = new Subject<any>();
  readonly triggerPickupRequestPanelFromMap$ = this.triggerPickupRequestPanelFromMapSubject.asObservable();

  updatedFromChangeFeedSubject = new BehaviorSubject<boolean>(false);
  readonly updatedFromChangeFeed$ = this.updatedFromChangeFeedSubject.asObservable();

  private mergeCompleteSubject = new BehaviorSubject<boolean>(false);
  readonly mergeComplete$ = this.mergeCompleteSubject.asObservable();

  wasUpdatedFromChangeFeed: boolean;

  // cache of all unassigned pickups from the last search
  private unassignedPickupsSubject = new BehaviorSubject<UnassignedPickupsSummary[]>([]);
  readonly unassignedPickups$ = this.unassignedPickupsSubject.asObservable();
  get unassignedPickups() {
    return this.unassignedPickupsSubject.value;
  }

  // cache of all unassigned pickups from the last search
  private pastDuePickupsSubject = new BehaviorSubject<UnassignedPickupsSummary[]>([]);
  readonly pastDuePickups$ = this.pastDuePickupsSubject.asObservable();
  get pastDuePickups() {
    return this.pastDuePickupsSubject.value;
  }

  // cache of all unmapped pickups from the last search
  private unmappedPickupsSubject = new BehaviorSubject<UnassignedPickupsSummary[]>([]);
  readonly unmappedPickups$ = this.unmappedPickupsSubject.asObservable();
  get unmappedPickups(): UnassignedPickupsSummary[] {
    return this.unmappedPickupsSubject.value;
  }

  // cache of all unassigned pickups from the last search
  private loadingUnassignedPickupsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingUnassignedPickups$ = this.loadingUnassignedPickupsSubject.asObservable();
  setMergeComplete(value: boolean) {
    this.mergeCompleteSubject.next(value);
  }
  updateSelectedPickupLineItems(lineItems): void {
    this.selectedPickupLineItemsSubject.next(lineItems);
  }

  getSelectedPickLineItemsData(): any[] {
    const allPickupsData = [...this.unassignedPickups, ...this.unmappedPickups, ...this.pastDuePickups];

    const selectedPickupLineItems = this.selectedPickupLineItems;

    const allLineItemsMap = new Map();

    allPickupsData.forEach((pickup: UnassignedPickupsSummary) => {
      if (pickup.pickupLines) {
        pickup.pickupLines.forEach((lineItem, index) => {
          const id = pickup.callNbr + '.' + index;
          allLineItemsMap.set(id, lineItem);
        });
      }
    });

    return selectedPickupLineItems.map((lineItem) => allLineItemsMap.get(lineItem));
  }

  triggerParentRowSelectionEvent(callNbr: string): void {
    this.onParentRowSelectionUpdatedSubject.next(callNbr);
  }

  triggerPickupRequestPanel(formType: PickupFormTypeEnum, pickupHeader?: PickupLineItemSearchRecord) {
    this.triggerPickupRequestPanelFromMapSubject.next({
      formType: formType,
      pickupHeader: pickupHeader,
    });
  }

  /**
   * Set the list of unassigned pickups without making API call
   */
  updateUnassignedPickups(unassignedPickups: UnassignedPickupsSummary[]) {
    this.unassignedPickupsSubject.next(unassignedPickups);
  }

  /**
   * Set the list of unassigned pickups without making API call
   */
  updatePastDuePickups(unassignedPickups: UnassignedPickupsSummary[]) {
    this.pastDuePickupsSubject.next(unassignedPickups);
  }

  /**
   * Set the list of unmapped pickups without making API call
   */
  updateUnmappedPickups(unassignedPickups: UnassignedPickupsSummary[]) {
    this.unmappedPickupsSubject.next(unassignedPickups);
  }

  /**
   * Sets the loading state of loading unassigned pickups subject
   */
  setLoadingUnassignedPickups(loading: boolean) {
    this.loadingUnassignedPickupsSubject.next(loading);
  }

  /**
   * Search for past due pickups based on provided criteria.
   */
  searchPastDuePickups(criteria: UnassignedPickupsSearchCriteria): Observable<UnassignedPickup[]> {
    return this.unassignedPickupsCriteriaService.filterFromCriteria(criteria).pipe(
      switchMap((filter) => {
        // Fetch past due pickups separately
        return this.fetchPastDuePickups(filter, criteria);
      }),
      catchError((error) => {
        console.error('Error fetching past due pickups', error);
        return of([]);
      })
    );
  }
  /**
   * Get from server all unassigned and unmapped pickups matching search criteria
   */
  searchUnassignedPickups(criteria: UnassignedPickupsSearchCriteria): Observable<UnassignedPickup[]> {
    return this.unassignedPickupsCriteriaService.filterFromCriteria(criteria).pipe(
      switchMap((filter) => {
        return forkJoin([
          this.fetchUnassignedPickups(filter, criteria),
          this.fetchUnmappedPickups(filter, criteria),
        ]).pipe(
          switchMap((results) => {
            // Combine the results with fetchHooks return
            return this.fetchHooks().pipe(
              map((hooksResults) => {
                return _flatten([...results, hooksResults]);
              })
            );
          })
        );
      })
    );
  }

  isValidCriteriaChange(
    previousCriteria: UnassignedPickupsSearchCriteria,
    newCriteria: UnassignedPickupsSearchCriteria
  ) {
    if (this.lastSearchCriteriaUpdate === 0 && !newCriteria.pickupDate) {
      // Fixes the case where the PlanDate is not set on the first load.
      return false;
    }

    if (
      !BoardUtils.areCriteriaObjectsEquivalent(previousCriteria, newCriteria) ||
      Date.now() - this.lastSearchCriteriaUpdate > MIN_TIME_BETWEEN_SEARCHES
    ) {
      this.lastSearchCriteriaUpdate = Date.now();
      return true;
    }

    return false;
  }

  /**
   * Fetch list of all past due pickups matching criteria and update lists.
   */
  private fetchPastDuePickups(
    filter: PickupLineItemSearchFilter,
    criteria: UnassignedPickupsSearchCriteria
  ): Observable<UnassignedPickupsSummary[]> {
    const fetchPastDuePage = (
      pageNumber: number = 1,
      allPastDuePickups: UnassignedPickupsSummary[] = []
    ): Observable<UnassignedPickupsSummary[]> => {
      const pastDueFilter = {
        ...new PickupLineItemSearchFilter(),
        hostOriginTerminalSicCd: filter.hostOriginTerminalSicCd,
        shipper_geoCoordinatesGeo: filter.shipper_geoCoordinatesGeo,
      };

      let pastDueRequest: ListPnDUnassignedPickupsRqst = this.getPickupRequestHeader(
        pastDueFilter,
        UnassignPickupsSourceCd.PAST_DUE
      );

      // clone request to avoid setting pageNumber and pageSize for all subsequent requests
      pastDueRequest = JSON.parse(JSON.stringify(pastDueRequest));

      pastDueRequest.header.pageNumber = pageNumber;
      pastDueRequest.header.pageSize = 1000;

      return this.cityOperationsService.listPnDUnassignedPickups(pastDueRequest).pipe(
        catchError((error) => {
          if (error) {
            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Error,
                `Unable to load past due pickups: ${error.error?.rootCause?.message}`
              )
              .subscribe(() => {});
          }
          return of(null);
        }),
        mergeMap((response: ListPnDUnassignedPickupsResp) => {
          if (!response || response.unassignedPickups.length === 0) {
            // No more data or error occurred, return the collected pickups so far
            return of(allPastDuePickups);
          } else {
            const pickups: UnassignedPickupsSummary[] = this.getUnassignedPickupSummaries(response, true);
            const sortedPickups = _sortBy(pickups, (x) => x.pickupHeader?.pickupRequestInstId);
            sortedPickups.forEach((pickup: UnassignedPickupsSummary) => {
              pickup.specialServiceSummary = SpecialServicesHelper.sortSpecialServiceSummary(
                pickup?.specialServiceSummary
              );
            });
            // Add current page pickups to allPickups and fetch next page
            return fetchPastDuePage(pageNumber + 1, allPastDuePickups.concat(sortedPickups));
          }
        })
      );
    };

    return fetchPastDuePage().pipe(
      tap((pickups) => {
        this.updatePastDuePickups(pickups);
      })
    );
  }
  /**
   * Fetch list of all unassigned pickups matching criteria and update lists.
   */
  private fetchUnassignedPickups(
    filter: PickupLineItemSearchFilter,
    criteria: UnassignedPickupsSearchCriteria
  ): Observable<UnassignedPickupsSummary[]> {
    const request: ListPnDUnassignedPickupsRqst = this.getPickupRequestHeader(
      filter,
      UnassignPickupsSourceCd.UNASSIGNED
    );

    return this.cityOperationsService.listPnDUnassignedPickups(request).pipe(
      catchError((error) => {
        this.updateUnassignedPickups([]);
        return of([]);
      }),
      map((response: ListPnDUnassignedPickupsResp) => {
        let unassignedPickups: UnassignedPickupsSummary[] = this.getUnassignedPickupSummaries(response, false);

        unassignedPickups = _sortBy(unassignedPickups, (x) => x.pickupHeader?.pickupRequestInstId);
        unassignedPickups.forEach((pickup: UnassignedPickupsSummary) => {
          pickup.specialServiceSummary = SpecialServicesHelper.sortSpecialServiceSummary(pickup?.specialServiceSummary);
        });

        this.updateUnassignedPickups(unassignedPickups);

        return unassignedPickups;
      })
    );
  }

  private getPickupRequestHeader(
    filter: PickupLineItemSearchFilter,
    source: UnassignPickupsSourceCd
  ): ListPnDUnassignedPickupsRqst {
    return {
      header: this.header,
      filter,
      unassignPickupsSourceCd: source,
      sicCds: [],
    };
  }

  private getUnassignedPickupSummaries(response: ListPnDUnassignedPickupsResp, pastDueInd): UnassignedPickupsSummary[] {
    let pickups = (response?.unassignedPickups || []) as UnassignedPickupsSummary[];
    pickups = _map(pickups, (pickup) => {
      pickup.gridGroupingName = this.pickupTypeCdPipe.transform(pickup?.pickupHeader?.pickupTypeCd || PickupTypeCd.PU);
      pickup.pastDueInd = pastDueInd;
      return pickup;
    });
    return pickups;
  }

  /**
   * fetch all unmapped pickups that match the search criteria
   */
  private fetchUnmappedPickups(
    filter: PickupLineItemSearchFilter,
    criteria: UnassignedPickupsSearchCriteria
  ): Observable<UnassignedPickupsSummary[]> {
    if (criteria?.shipperGeoCoordinatesGeo) {
      return of(undefined);
    } else {
      const request = this.getPickupRequestHeader(
        { ...filter, dispatchGroupId: undefined, shipper_geoCoordinatesGeo: undefined },
        UnassignPickupsSourceCd.UNMAPPED
      );

      return this.cityOperationsService.listPnDUnassignedPickups(request).pipe(
        catchError((error) => {
          return of({});
        }),
        map((response: ListPnDUnassignedPickupsResp) => {
          const unassignedPickups = _map(response?.unassignedPickups, (pickup) => {
            pickup.callNbr = pickup.pickupHeader.header.callNbr;
            return pickup;
          });
          const allUnmappedPickups = (unassignedPickups || []) as UnassignedPickupsSummary[];
          const uniquePickups = _uniqWith(
            allUnmappedPickups,
            (a: UnassignedPickupsSummary, b: UnassignedPickupsSummary) => {
              return a?.pickupHeader?.shipper?.acctInstId === b?.pickupHeader?.shipper?.acctInstId;
            }
          );
          this.updateUnmappedPickups(uniquePickups);
          return uniquePickups;
        })
      );
    }
  }

  private fetchHooks(): Observable<UnassignedPickupsSummary[]> {
    const sicCd = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    const path = { ...new ListHookEquipmentStopsPath(), sicCd };
    const query = { ...new ListHookEquipmentStopsQuery(), statusCds: [HookStatusCd.UNASSIGNED] };
    return this.cityOperationsService.listHookEquipmentStops(path, query).pipe(
      take(1),
      catchError(() => of(null)),
      map((response: ListHookEquipmentStopsResp) => {
        const items: UnassignedPickupsSummary[] =
          response?.hookEquipmentStops?.map((hook: HookEquipmentStop) => {
            const item: UnassignedPickupsSummary = {
              ...new UnassignedPickupsSummary(),
              gridGroupingName: UnassignedPickupsGroupName.HOOK_DROPPED,
              // pickupInstId: 0,
              pastDueInd: false,
              callNbr: this.equipmentPipe.transform(hook.equipmentIdPrefix, hook.equipmentIdSuffix),
              pickupHeader: {
                ...new PickupLineItemSearchRecord(),
                pickupRequestInstId: hook?.hookInstId,
                shipper: {
                  ...new AccountDetail(),
                  acctInstId: hook?.equipmentLocation?.acctInstId,
                  addressLine1: hook?.equipmentLocation?.addressLine1,
                  addressLine2: hook?.equipmentLocation?.addressLine2,
                  name1: hook?.equipmentLocation?.name1,
                  name2: hook?.equipmentLocation?.name2,
                  cityName: hook?.equipmentLocation?.cityName,
                  countryCd: hook?.equipmentLocation?.countryCd,
                  postalCd: hook?.equipmentLocation?.postalCd,
                  stateCd: hook?.equipmentLocation?.stateCd,
                  zip4RestUs: hook?.equipmentLocation?.zip4RestUs,
                  latitudeNbr: hook?.equipmentLocation?.geoCoordinatesGeo?.lat,
                  longitudeNbr: hook?.equipmentLocation?.geoCoordinatesGeo?.lon,
                },
                // Uncomment this line once 'HK' is added to PickupTypeCd enum
                // pickupTypeCd: PickupTypeCd.HK
                locationType: 'Drop Location',
                header: {
                  ...new PickupRequest(),
                  originTerminalSicCd: hook?.terminalSicCd,
                  pickupDate: null, // hook?.pickupDate?.substring(0, 10),
                },
              },
              pickupLines: [],
              specialServiceSummary: [],
            };
            return item;
          }) || [];

        const allItems: UnassignedPickupsSummary[] = [...this.unassignedPickupsSubject.value, ...items];
        this.updateUnassignedPickups(allItems);
        return items;
      })
    );
  }
}
