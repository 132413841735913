import { Action } from '@ngrx/store';
import { EventItem, UnassignedPickupIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedPickupsSearchCriteria } from './unassigned-pickups-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'unassignedPickups.setSearchCriteria',
  setLastUpdate = 'unassignedPickups.setLastUpdate',
  setSelectedUnassignedPickups = 'unassignedPickups.setSelectedUnassignedPickups',
  setFocusedUnassignedPickup = 'unassignedPickups.setFocusedUnassignedPickup',
  setFilteredUnassignedPickupIds = 'unassignedPickups.setFilteredUnassignedPickupIds',
  setPickupDate = 'unassignedPickups.setPickupDate',
  refresh = 'unassignedPickups.refresh',
}

export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: UnassignedPickupsSearchCriteria }) {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { lastUpdate: Date }) {}
}

export class SetSelectedUnassignedPickups implements Action {
  readonly type = ActionTypes.setSelectedUnassignedPickups;
  constructor(public payload: { selectedPickups: EventItem<UnassignedPickupIdentifier>[] }) {}
}

export class SetFocusedUnassignedPickup implements Action {
  readonly type = ActionTypes.setFocusedUnassignedPickup;
  constructor(public payload: { focusedPickup: EventItem<UnassignedPickupIdentifier> }) {}
}

export class SetFilteredUnassignedPickupIds implements Action {
  readonly type = ActionTypes.setFilteredUnassignedPickupIds;
  constructor(public payload: { pickupIds: { [key: number]: boolean } }) {}
}

export class SetPickupDate implements Action {
  readonly type = ActionTypes.setPickupDate;
  constructor(public payload: { pickupDate: { min: Date; max: Date } }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions =
  | SetSearchCriteria
  | SetLastUpdate
  | SetSelectedUnassignedPickups
  | SetFocusedUnassignedPickup
  | SetFilteredUnassignedPickupIds
  | SetPickupDate
  | Refresh;
