import { Action } from '@ngrx/store';
import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { StoreSourcesEnum } from '../../inbound-planning/shared/enums/store-sources.enum';
import {
  EventItem,
  PlanningRouteShipmentIdentifier,
  RouteItemIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { AssignedStopMapMarker } from '../../inbound-planning/shared/models/markers/assigned-stop-map-marker.model';
import { RoutesSearchCriteria } from './routes-search-criteria.interface';

export enum ActionTypes {
  // Misc
  updateUnassignedDeliveriesGrid = 'routes.updateUnassignedDeliveriesGrid',
  setRouteMarkers = 'routes.setRouteMarkers',

  // Planning Routes
  setSearchCriteria = 'routes.planning.setSearchCriteria',
  setPlanningRoutesLastUpdate = 'routes.planning.setPlanningRoutesLastUpdate',
  setPlanningRoutesLoading = 'routes.planning.setPlanningRoutesLoading',

  setFocusedPlanningRoute = 'routes.planning.setFocusedPlanningRoute',
  setFocusedPlanningRouteShipment = 'routes.planning.setFocusedPlanningRouteShipment',
  setFilteredPlanningRouteIds = 'routes.planning.setFilteredPlanningRouteIds',

  setSelectedPlanningRoutes = 'routes.planning.setSelectedPlanningRoutes',
  setStopsForSelectedPlanningRoutesLastUpdate = 'routes.planning.setStopsForSelectedPlanningRoutesLastUpdate',
  setStopsForSelectedPlanningRoutes = 'routes.planning.setStopsForSelectedPlanningRoutes',

  setSelectedPlanningRoutesShipments = 'routes.planning.setSelectedPlanningRoutesShipments',

  refreshPlanningRoutes = 'routes.planning.refreshPlanningRoutes',
}

// #region Misc
export class SetRouteMarkersAction implements Action {
  readonly type = ActionTypes.setRouteMarkers;
  constructor(public payload: { routeMarkers: AssignedStopMapMarker[] }) {}
}

export class UpdateUnassignedDeliveriesGridAction implements Action {
  readonly type = ActionTypes.updateUnassignedDeliveriesGrid;
  constructor(public payload: { updateUnassignedDeliveriesGrid: { source: StoreSourcesEnum; date: Date } }) {}
}

// #endregion

// #region Planning Routes
export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: RoutesSearchCriteria }) {}
}

export class RefreshPlanningRoutes implements Action {
  readonly type = ActionTypes.refreshPlanningRoutes;
  constructor() {}
}

export class SetPlanningRoutesLoading implements Action {
  readonly type = ActionTypes.setPlanningRoutesLoading;
  constructor(public payload: { loading: boolean }) {}
}

export class SetPlanningRoutesLastUpdate implements Action {
  readonly type = ActionTypes.setPlanningRoutesLastUpdate;
  constructor(public payload: { planningRoutesLastUpdate: Date }) {}
}

export class SetFocusedPlanningRouteAction implements Action {
  readonly type = ActionTypes.setFocusedPlanningRoute;
  constructor(public payload: { focusedPlanningRoute: EventItem<RouteItemIdentifier> }) {}
}

export class SetFocusedPlanningRouteShipmentAction implements Action {
  readonly type = ActionTypes.setFocusedPlanningRouteShipment;
  constructor(public payload: { focusedPlanningRouteShipment: EventItem<PlanningRouteShipmentIdentifier> }) {}
}

export class SetSelectedPlanningRoutesAction implements Action {
  readonly type = ActionTypes.setSelectedPlanningRoutes;
  constructor(public payload: { selectedPlanningRoutes: number[] }) {}
}

export class SetStopsForSelectedPlanningRoutesLastUpdate implements Action {
  readonly type = ActionTypes.setStopsForSelectedPlanningRoutesLastUpdate;
  constructor(public payload: { stopsForSelectedPlanningRoutesLastUpdate: Date }) {}
}

export class SetStopsForSelectedPlanningRoutes implements Action {
  readonly type = ActionTypes.setStopsForSelectedPlanningRoutes;
  constructor(public payload: { stopsForSelectedPlanningRoutes: { routeInstId: number; stops: UnassignedStop[] }[] }) {}
}

export class SetSelectedPlanningRoutesShipmentsAction implements Action {
  readonly type = ActionTypes.setSelectedPlanningRoutesShipments;
  constructor(public payload: { selectedPlanningRoutesShipments: EventItem<PlanningRouteShipmentIdentifier>[] }) {}
}

export class SetFilteredPlanningRouteIds implements Action {
  readonly type = ActionTypes.setFilteredPlanningRouteIds;
  constructor(public payload: { filteredPlanningRouteIds: { [key: number]: boolean } }) {}
}

// #endregion

export type Actions =
  | UpdateUnassignedDeliveriesGridAction
  | SetRouteMarkersAction
  | SetSearchCriteria
  | RefreshPlanningRoutes
  | SetPlanningRoutesLoading
  | SetPlanningRoutesLastUpdate
  | SetFocusedPlanningRouteAction
  | SetFocusedPlanningRouteShipmentAction
  | SetSelectedPlanningRoutesAction
  | SetFilteredPlanningRouteIds
  | SetStopsForSelectedPlanningRoutesLastUpdate
  | SetSelectedPlanningRoutesShipmentsAction
  | SetStopsForSelectedPlanningRoutes;
