import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { KeyboardShortcutsComponent } from './keyboard-shortcuts.component';
import { KeyboardKeyConverterPipe } from './pipes/keyboard-key-converter.pipe';

const pipes = [KeyboardKeyConverterPipe];
const components = [KeyboardShortcutsComponent];
@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  imports: [CommonModule, XpoButtonModule, MatTableModule, KeyboardShortcutsModule, XpoDialogModule],
})
export class PndKeyboardShortcutsModule {}
