import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './global-filters-store.state';

export const selectGlobalFilterState = createFeatureSelector<State>(StateKey);

export const globalFilterSic = createSelector(selectGlobalFilterState, (state: State) => state.sic);

export const globalFilterSicLatLng = createSelector(selectGlobalFilterState, (state: State) => state.sicLatLng);

export const globalFilterSicZonesAndSatellites = createSelector(
  selectGlobalFilterState,
  (state: State) => state.sicZonesAndSatellites
);

export const globalFilterPlanDate = createSelector(selectGlobalFilterState, (state: State) => state.planDate);

export const geoFilterArea = createSelector(selectGlobalFilterState, (state: State) => state.geoFilterArea);
