export class ConfirmCancelData {
  heading: string;
  body: string;
  cancelButtonTitle: string;
  confirmButtonTitle: string;
  altActionButtonTitle: string;

  constructor(
    heading: string,
    body: string,
    cancelButtonTitle: string,
    confirmButtonTitle: string,
    altActionButtonTitle?: string
  ) {
    this.heading = heading;
    this.body = body;
    this.confirmButtonTitle = confirmButtonTitle;
    this.cancelButtonTitle = cancelButtonTitle;
    this.altActionButtonTitle = altActionButtonTitle;
  }
}
