import { tassign } from 'tassign';
import { Actions, ActionTypes } from './spots-and-drops-store.actions';
import { initialState, State } from './spots-and-drops-store.state';

export function spotsAndDropsReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        criteria: action.payload.criteria,
      });
    }
    case ActionTypes.setSelected: {
      return tassign(state, {
        selectedTrips: action.payload.selectedSpotsAndDropsTripsIds,
      });
    }
    case ActionTypes.setSelectedTrip: {
      return tassign(state, {
        selectedTrip: action.payload.selectedSpotsAndDropsTripId,
      });
    }
    case ActionTypes.setLastUpdate: {
      return tassign(state, {
        lastUpdate: action.payload.spotsAndDropsLastUpdate,
      });
    }
    case ActionTypes.setFocusedSpotAndDrop: {
      return tassign(state, {
        focusedSpotAndDrop: action.payload.focusedSpotAndDrop,
      });
    }
    default: {
      return state;
    }
  }
}
