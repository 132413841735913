import { Injectable } from '@angular/core';
import { Activity, Stop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import {
  CustomerApiService,
  GetCustomerLocationOperationsProfilePath,
  GetCustomerLocationOperationsProfileResp,
} from '@xpo-ltl/sdk-customer';
import { size as _size, countBy as _countBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

// Extend TripNodeActivityCd type to allow 'MX' as a valid code
export type TripNodeActivityExtCd = TripNodeActivityCd | 'MX';

@Injectable({ providedIn: 'root' })
export class StopDetailsService {
  constructor(private customerService: CustomerApiService) {}

  loadCustomerProfile(customerAccountNumber: number): Observable<GetCustomerLocationOperationsProfileResp> {
    return this.customerService
      .getCustomerLocationOperationsProfile({
        ...new GetCustomerLocationOperationsProfilePath(),
        custAcctNbr: customerAccountNumber,
      })
      .pipe(
        catchError(() => {
          return of(undefined);
        }),
        take(1)
      );
  }

  getArrivalDateTime(stop: Stop): Date {
    const arrivalActivity = (stop?.activities ?? []).find(
      (activity: Activity) => activity.tripNodeActivity.activityCd === TripNodeActivityCd.ARRIVE
    );

    let arrivalDateTime: Date;

    if (arrivalActivity) {
      arrivalDateTime = arrivalActivity?.tripNodeActivity?.actualActivityDateTime;
    }

    return arrivalDateTime;
  }

  getDepartureDateTime(stop: Stop): Date {
    const departureActivity = (stop?.activities ?? []).find(
      (activity: Activity) => activity.tripNodeActivity.activityCd === TripNodeActivityCd.DEPART_DISPATCH
    );
    let departureDateTime: Date;

    if (departureActivity) {
      departureDateTime = departureActivity?.tripNodeActivity?.actualActivityDateTime;
    }

    return departureDateTime;
  }

  /**
   * Return the overall Activity Code for this stop. If there is more then one type of activity, then returns MX
   */
  getStopType(stop: Stop): TripNodeActivityExtCd {
    const activityTypes = _countBy(stop?.activities ?? [], (a) => a?.tripNodeActivity?.activityCd ?? '');
    delete activityTypes[TripNodeActivityCd.ARRIVE];
    delete activityTypes[TripNodeActivityCd.DEPART_DISPATCH];
    const activityType =
      Object.keys(activityTypes).length > 1 ? 'MX' : (Object.keys(activityTypes)[0] as TripNodeActivityExtCd);
    return activityType;
  }

  getStopTypes(stop: Stop): TripNodeActivityExtCd {
    const activityTypes = _countBy(stop?.activities ?? [], (a) => a?.tripNodeActivity?.activityCd ?? '');

    if (
      (_size(activityTypes) === 1 && activityTypes[TripNodeActivityCd.ARRIVE] !== undefined) ||
      (_size(activityTypes) === 2 &&
        activityTypes[TripNodeActivityCd.ARRIVE] !== undefined &&
        activityTypes[TripNodeActivityCd.DEPART_DISPATCH] !== undefined)
    ) {
      delete activityTypes[TripNodeActivityCd.DEPART_DISPATCH];
    } else {
      delete activityTypes[TripNodeActivityCd.DEPART_DISPATCH];
      delete activityTypes[TripNodeActivityCd.ARRIVE];
    }
    const activityType =
      Object.keys(activityTypes).length > 1 ? 'MX' : (Object.keys(activityTypes)[0] as TripNodeActivityExtCd);
    return activityType;
  }
}
