import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input } from '@angular/core';
import { Metric } from '@xpo-ltl/sdk-cityoperations';
import { MetricVarianceCd, MetricValueKeyCd } from '@xpo-ltl/sdk-common';
import { MetricsUtils } from '../../../classes/metrics-utils';

@Component({
  selector: 'pnd-metric-bar-card',
  templateUrl: 'metric-bar-card.component.html',
  styleUrls: ['metric-bar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-MetricBarCard' },
})
export class MetricBarCardComponent {
  @Input()
  metric: Metric;

  readonly specialServiceMetrics: MetricValueKeyCd[] = [
    MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING,
    MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING,
    MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING,
  ];

  readonly varianceExclusionList: MetricValueKeyCd[] = [
    MetricValueKeyCd.DELIVERY_STOPS_PER_TRIP,
    MetricValueKeyCd.DELIVERY_STOPS_PER_HOUR,
    // MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING,
    // MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING,
    // MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING,
  ];
  constructor() {}

  isSpecialServiceMetric(key: MetricValueKeyCd): boolean {
    return this.specialServiceMetrics.includes(key);
  }

  getSpecialServiceIcon(key: MetricValueKeyCd): string {
    switch (key) {
      case MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING:
        return 'ss_show';
      case MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING:
        return 'ss_premium';
      case MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING:
        return 'ss_guaranteed';
      default:
        return;
    }
  }

  getVarianceIcon(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceIcon(variance);
  }

  getVarianceClassColor(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceClassColor(variance);
  }
}
