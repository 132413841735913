import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'xpo-accordion-group-cell-renderer',
  templateUrl: './xpo-accordion-group-cell-renderer.component.html',
  styleUrls: ['./xpo-accordion-group-cell-renderer.component.scss'],
})
export class XpoAccordionGroupCellRendererComponent implements ICellRendererAngularComp {
  groupName: string;
  rowCount: number;
  isRowExpanded: boolean = false;
  node: RowNode;

  agInit(params: any): void {
    this.groupName = params.value;
    this.rowCount = params.node && params.node.allChildrenCount ? params.node.allChildrenCount : 0;
    this.node = params.node;
  }

  refresh(): boolean {
    return false;
  }
}
