import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { DispatchGroup } from '@xpo-ltl/sdk-cityoperations';
import {
  filter as _filter,
  findIndex as _findIndex,
  join as _join,
  map as _map,
  orderBy as _orderBy,
  size as _size,
} from 'lodash';
import * as md5 from 'md5';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DefaultLayout, LayoutPreferenceService } from '../../../../../shared/layout-manager';
import { ComponentChangeUtils } from '../../classes/component-change-utils';
import { DispatchAreaService } from '../../services/dispatch-area/dispatch-area.service';

@Component({
  selector: 'pnd-dispatch-area-selector',
  templateUrl: './dispatch-area-selector.component.html',
  styleUrls: ['./dispatch-area-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispatchAreaSelectorComponent implements OnInit, OnDestroy {
  isHidden$: Observable<boolean>;
  selectedOptions: DispatchGroup[];

  options: DispatchGroup[] = [];
  readonly placeholder: string = 'Dispatch Group';

  private previousSelectedOptions: DispatchGroup[];
  private unsubscriber: Unsubscriber = new Unsubscriber();

  private lastDispatchGroupRegionsHash: string = '';

  constructor(
    private layoutPreferenceService: LayoutPreferenceService,
    private dispatchAreaService: DispatchAreaService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isHidden$ = this.layoutPreferenceService.activeLayoutMode$.pipe(
      map((mode: DefaultLayout) => mode !== DefaultLayout.Dispatcher)
    );

    this.dispatchAreaService.dispatchGroupRegions$
      .pipe(
        map((regions) => {
          return md5(JSON.stringify(regions));
        }),
        filter((regionHash) => regionHash !== this.lastDispatchGroupRegionsHash),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((regionHash) => {
        this.lastDispatchGroupRegionsHash = regionHash;

        const dispatchGroups = this.dispatchAreaService.getDispatchGroups();
        this.options = [
          { ...new DispatchGroup(), groupName: 'All', groupDescription: 'Dispatch Areas' },
          ..._orderBy(dispatchGroups || [], (dispatchGroup) => dispatchGroup.groupName),
        ];
        this.selectedOptions = [...this.options];
        this.previousSelectedOptions = [...this.options];

        this.dispatchAreaService.setSelectedDispatchGroup(this.selectedOptions);
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
  trackOptionsBy(index, option: DispatchGroup): number {
    if (!option) {
      return null;
    }
    return option?.groupId;
  }
  handleSelectionChange(): void {
    if (this.allSelected(this.selectedOptions) && _size(this.selectedOptions) !== _size(this.options)) {
      if (
        this.allSelected(this.selectedOptions) &&
        this.allSelected(this.previousSelectedOptions) &&
        _size(this.selectedOptions) !== _size(this.previousSelectedOptions)
      ) {
        this.selectedOptions = _filter(this.selectedOptions.filter((option) => option.groupName !== 'All'));
      } else {
        this.selectedOptions = this.options;
      }
    } else if (this.allSelected(this.previousSelectedOptions) && !this.allSelected(this.selectedOptions)) {
      this.selectedOptions = [];
    } else if (_size(this.selectedOptions) + 1 === _size(this.options) && !this.allSelected(this.selectedOptions)) {
      this.selectedOptions = [...this.options];
    }

    this.previousSelectedOptions = [...this.selectedOptions];

    this.dispatchAreaService.setSelectedDispatchGroup(this.selectedOptions);
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  getSelectedOptionsDisplay(): string {
    const allSelected: boolean = this.allSelected(this.selectedOptions);

    const groupNames = allSelected
      ? 'All'
      : _join(
          _map(this.selectedOptions, (option) => option.groupName),
          ', '
        );

    return allSelected || this.selectedOptions?.length > 1
      ? `Dispatch Areas - ${groupNames}`
      : `Dispatch Area - ${groupNames}`;
  }

  allSelected(options: DispatchGroup[]): boolean {
    return _findIndex(options, (option) => option.groupName === 'All') >= 0;
  }
}
