import { Injectable } from '@angular/core';
import {
  CityOperationsApiService,
  CreatePnDOptimizerDriversResp,
  CreatePnDOptimizerDriversRqst,
  CreatePnDOptimizerEquipmentResp,
  CreatePnDOptimizerEquipmentRqst,
  CreatePnDOptimizerResp,
  CreatePnDOptimizerRqst,
  CreatePnDOptimizerShipmentsRqst,
  GetPnDOptimizerStatusQuery,
  GetPnDOptimizerStatusResp,
  GetPnDOptimizerSummaryPath,
  GetPnDOptimizerSummaryQuery,
  GetPnDOptimizerSummaryResp,
  OptimizerShipment,
  PdoRequestDriver,
  PdoRequestEquipment,
  PdoRequestShipment,
  StartPnDOptimizerPath,
  UpdatePnDOptimizerDriversPath,
  UpdatePnDOptimizerDriversRqst,
  UpdatePnDOptimizerEquipmentPath,
  UpdatePnDOptimizerEquipmentRqst,
  UpdatePnDOptimizerStatusResp,
  ListPnDOptimizerResultsPath,
  ListPnDOptimizerParametersQuery,
  OptimizerParameter,
  UpdatePnDOptimizerStatusRqst,
  ListPnDOptimizerResultsResp,
  CreatePnDOptimizerCommodityDimensionsPath,
  UpdatePnDOptimizerShipmentsRqst,
  UpdatePnDOptimizerShipmentsPath,
  OptimizerRouteStop,
  ListPnDOptimizerRoutesByRouteInstIdsRqst,
  ListPnDOptimizerRoutesByRouteInstIdsResp,
  CreatePnDOptimizerShipmentsResp,
  ListOptimizerSameAddressShipmentsPath,
  ListOptimizerSameAddressShipmentsResp,
  PdoEnrouteSameAddressShipment,
  ListPnDOptimizerParametersResp,
} from '@xpo-ltl/sdk-cityoperations';
import { OptimizerStatusCd, OptimizerParameterTypeCd, OptimizerParameterCd } from '@xpo-ltl/sdk-common';
import { map as _map, defaultTo as _defaultTo } from 'lodash';
import moment from 'moment-timezone';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OptimizeService {
  private readonly pdoRequestShipmentsSubject = new BehaviorSubject<PdoRequestShipment[]>(undefined);
  readonly pdoRequestShipments$ = this.pdoRequestShipmentsSubject.asObservable();

  private readonly pdoRequestDriversSubject = new BehaviorSubject<PdoRequestDriver[]>(undefined);
  readonly pdoRequestDrivers$ = this.pdoRequestDriversSubject.asObservable();

  private readonly pdoRequestEquipmentSubject = new BehaviorSubject<PdoRequestEquipment[]>(undefined);
  readonly pdoRequestEquipment$ = this.pdoRequestEquipmentSubject.asObservable();

  private readonly sameAddressShipmentsSubject = new BehaviorSubject<PdoEnrouteSameAddressShipment[]>(undefined);
  readonly sameAddressShipments$ = this.sameAddressShipmentsSubject.asObservable();

  private readonly runNbrSubject = new BehaviorSubject<number>(undefined);
  readonly runNbr$ = this.runNbrSubject.asObservable();

  private readonly selectedShipmentsSubject = new BehaviorSubject<number>(0);
  readonly selectedShipments$ = this.selectedShipmentsSubject.asObservable();

  private readonly deselectedShipmentsSubject = new BehaviorSubject<number>(0);
  readonly deselectedShipments$ = this.deselectedShipmentsSubject.asObservable();

  private readonly selectedEquipmentsSubject = new BehaviorSubject<PdoRequestEquipment[]>(undefined);
  readonly selectedEquipments$ = this.selectedEquipmentsSubject.asObservable();

  private readonly optimizeParamsSubject = new BehaviorSubject<OptimizerParameter[]>([]);
  readonly optimizeParams$ = this.optimizeParamsSubject.asObservable();

  private readonly optimizerRouteStopsSubject = new BehaviorSubject<OptimizerRouteStop[]>([]);
  readonly optimizerRouteStops$ = this.optimizerRouteStopsSubject.asObservable();

  private readonly isIncrementalOptimizationSubject = new BehaviorSubject<boolean>(false);
  readonly isIncrementalOptimization$ = this.isIncrementalOptimizationSubject.asObservable();

  private readonly isNextStepReadySubject = new BehaviorSubject<boolean>(false);
  readonly isNextStepReady$ = this.isNextStepReadySubject.asObservable();

  get isIncrementalOptimization(): boolean {
    return this.isIncrementalOptimizationSubject.value;
  }
  get optimizerRouteStops(): OptimizerRouteStop[] {
    return this.optimizerRouteStopsSubject.value;
  }

  get pdoRequestShipments() {
    return this.pdoRequestShipmentsSubject.value;
  }

  get pdoRequestDrivers(): PdoRequestDriver[] {
    return this.pdoRequestDriversSubject.value;
  }

  get pdoRequestEquipment(): PdoRequestEquipment[] {
    return this.pdoRequestEquipmentSubject.value;
  }

  get sameAddressShipments(): PdoEnrouteSameAddressShipment[] {
    return this.sameAddressShipmentsSubject.value;
  }

  get selectedEquipments(): PdoRequestEquipment[] {
    return this.selectedEquipmentsSubject.value;
  }

  get optimizeParams(): OptimizerParameter[] {
    return this.optimizeParamsSubject.value;
  }

  get runNbr(): number {
    return this.runNbrSubject.value;
  }

  getOptimizeParam(param: OptimizerParameterCd | string): string {
    const item = this.optimizeParams.find((x) => x.parmCd === param);
    return item ? item.settingValue : undefined;
  }

  constructor(private cityOperationsApiService: CityOperationsApiService) {}

  runOptimize(
    terminalSicCode: string,
    planDate: Date,
    shipmentsSelectedForOptimizationInd: boolean
  ): Observable<CreatePnDOptimizerResp> {
    this.setNextStepReadyState(false);

    const request: CreatePnDOptimizerRqst = {
      ...new CreatePnDOptimizerRqst(),
      terminalSicCode,
      planDate: moment(planDate).format('YYYY-MM-DD'),
      statusCd: OptimizerStatusCd.NEW,
      shipmentsSelectedForOptimizationInd,
    };

    return this.cityOperationsApiService.createPnDOptimizer(request).pipe(
      tap((result) => {
        this.runNbrSubject.next(result.runNbr);
        this.isIncrementalOptimizationSubject.next(!shipmentsSelectedForOptimizationInd);
      })
    );
  }

  createPnDOptimizerCommodityDimensions(): Observable<void> {
    const path: CreatePnDOptimizerCommodityDimensionsPath = {
      ...new CreatePnDOptimizerCommodityDimensionsPath(),
      runNbr: this.runNbr,
    };

    return this.cityOperationsApiService.createPnDOptimizerCommodityDimensions(path, path);
  }

  executeOptimizeProcess(sicCd: string): Observable<void> {
    const request: StartPnDOptimizerPath = {
      ...new StartPnDOptimizerPath(),
      runNbr: this.runNbr,
    };

    const path: StartPnDOptimizerPath = {
      ...new StartPnDOptimizerPath(),
      runNbr: this.runNbr,
    };

    const queryParams: ListPnDOptimizerParametersQuery = {
      ...new ListPnDOptimizerParametersQuery(),
      optimizerParameterTypeCd: OptimizerParameterTypeCd.OPTIMIZER,
      sicCd,
    };

    return this.cityOperationsApiService.listPnDOptimizerParameters(queryParams).pipe(
      switchMap((paramsResult: ListPnDOptimizerParametersResp) => {
        this.optimizeParamsSubject.next(paramsResult.optimizerParameters);
        return this.cityOperationsApiService.startPnDOptimizer(request, path);
      })
    );
  }

  abortOptimizationProcess(abortReason: string): Observable<UpdatePnDOptimizerStatusResp> {
    const request: UpdatePnDOptimizerStatusRqst = {
      ...new UpdatePnDOptimizerStatusRqst(),
      runNbr: this.runNbr,
      abortReason,
      statusCd: OptimizerStatusCd.ABORTED,
    };

    return this.cityOperationsApiService.updatePnDOptimizerStatus(request);
  }

  cancelOptimizationProcess(): Observable<UpdatePnDOptimizerStatusResp> {
    const request: UpdatePnDOptimizerStatusRqst = {
      ...new UpdatePnDOptimizerStatusRqst(),
      runNbr: this.runNbr,
      statusCd: OptimizerStatusCd.CANCELLED,
    };

    return this.cityOperationsApiService.updatePnDOptimizerStatus(request).pipe(tap(() => this.reset()));
  }

  setTimeOutOptimizationProcess(): Observable<OptimizerStatusCd> {
    const request: UpdatePnDOptimizerStatusRqst = {
      ...new UpdatePnDOptimizerStatusRqst(),
      runNbr: this.runNbr,
      statusCd: OptimizerStatusCd.TIME_OUT,
    };

    return this.cityOperationsApiService.updatePnDOptimizerStatus(request).pipe(
      map(() => OptimizerStatusCd.TIME_OUT),
      tap(() => this.reset())
    );
  }

  createOptimizerShipments(optimizerShipments: OptimizerShipment[]): Observable<CreatePnDOptimizerShipmentsResp> {
    if (!this.isIncrementalOptimization) {
      const request: CreatePnDOptimizerShipmentsRqst = {
        ...new CreatePnDOptimizerShipmentsRqst(),
        runNbr: this.runNbr,
        optimizerShipments,
      };

      this.selectedShipmentsSubject.next(optimizerShipments.length);

      return this.cityOperationsApiService.createPnDOptimizerShipments(request).pipe(
        switchMap((result: CreatePnDOptimizerShipmentsResp) => {
          return this.createPnDOptimizerCommodityDimensions().pipe(map(() => result));
        }),
        switchMap((result: CreatePnDOptimizerShipmentsResp) => {
          return this.listPnDOptimizerSameAddressShipments().pipe(map(() => result));
        }),
        tap((value: CreatePnDOptimizerShipmentsResp) => {
          this.pdoRequestShipmentsSubject.next(value.pdoRequestShipments);
        })
      );
    } else {
      return of(new CreatePnDOptimizerShipmentsResp());
    }
  }

  getPndSummary(optimizerSummaryType: OptimizerParameterTypeCd): Observable<GetPnDOptimizerSummaryResp> {
    const path: GetPnDOptimizerSummaryPath = {
      ...new GetPnDOptimizerSummaryPath(),
      runNbr: this.runNbr,
    };
    const query: GetPnDOptimizerSummaryQuery = {
      ...new GetPnDOptimizerSummaryQuery(),
      optimizerSummaryTypeCds: [optimizerSummaryType],
    };

    return this.cityOperationsApiService.getPnDOptimizerSummary(path, query);
  }

  createOptimizerDrivers(sicCd: string, planDate: Date): Observable<CreatePnDOptimizerDriversResp> {
    if (!!this.pdoRequestDrivers) {
      this.setNextStepReadyState(true);
      return this.pdoRequestDrivers$.pipe(
        map((value) => ({
          ...new CreatePnDOptimizerDriversResp(),
          pdoRequestDrivers: value,
        }))
      );
    } else {
      const request: CreatePnDOptimizerDriversRqst = {
        ...new CreatePnDOptimizerDriversRqst(),
        runNbr: this.runNbr,
        sicCd,
        planDate: moment(planDate).format('YYYY-MM-DD'),
      };

      return this.cityOperationsApiService.createPnDOptimizerDrivers(request).pipe(
        tap((result) => {
          this.setNextStepReadyState(true);
          this.pdoRequestDriversSubject.next(result.pdoRequestDrivers);
        })
      );
    }
  }

  updateOptimizerDrivers(pdoRequestDrivers: PdoRequestDriver[]): Observable<void> {
    const request: UpdatePnDOptimizerDriversRqst = {
      ...new UpdatePnDOptimizerDriversRqst(),
      pdoRequestDriver: _map(pdoRequestDrivers, (driver: PdoRequestDriver) => {
        return {
          ...driver,
          includeInd: _defaultTo(driver.includeInd, true),
        };
      }),
    };

    const path: UpdatePnDOptimizerDriversPath = {
      ...new UpdatePnDOptimizerDriversPath(),
      runNbr: this.runNbr,
    };

    return this.cityOperationsApiService.updatePnDOptimizerDrivers(request, path);
  }

  createOptimizerEquipments(sicCd: string): Observable<CreatePnDOptimizerEquipmentResp> {
    if (!!this.pdoRequestEquipment) {
      return this.pdoRequestEquipment$.pipe(
        map((value) => ({
          ...new CreatePnDOptimizerEquipmentRqst(),
          pdoRequestEquipment: value,
        }))
      );
    } else {
      const path: CreatePnDOptimizerEquipmentRqst = {
        ...new CreatePnDOptimizerEquipmentRqst(),
        runNbr: this.runNbr,
        sicCd,
      };

      return this.cityOperationsApiService.createPnDOptimizerEquipment(path).pipe(
        tap((result) => {
          this.pdoRequestEquipmentSubject.next(result.pdoRequestEquipment);
        })
      );
    }
  }

  updateOptimizerEquipments(pdoRequestEquipment: PdoRequestEquipment[]): Observable<boolean> {
    const request: UpdatePnDOptimizerEquipmentRqst = {
      ...new UpdatePnDOptimizerEquipmentRqst(),
      pdoRequestEquipment: _map(pdoRequestEquipment, (equipment: PdoRequestEquipment) => {
        return {
          ...equipment,
          includeInd: _defaultTo(equipment.includeInd, true),
        };
      }),
    };

    const path: UpdatePnDOptimizerEquipmentPath = {
      ...new UpdatePnDOptimizerEquipmentPath(),
      runNbr: this.runNbr,
    };

    return this.cityOperationsApiService.updatePnDOptimizerEquipment(request, path).pipe(
      tap(() => this.selectedEquipmentsSubject.next(pdoRequestEquipment)),
      map(() => true)
    );
  }

  updateOptimizerShipments(pdoRequestShipments: PdoRequestShipment[]): Observable<boolean> {
    const request: UpdatePnDOptimizerShipmentsRqst = {
      ...new UpdatePnDOptimizerShipmentsRqst(),
      pdoRequestShipments,
    };

    const path: UpdatePnDOptimizerShipmentsPath = {
      ...new UpdatePnDOptimizerShipmentsPath(),
      runNbr: this.runNbr,
    };

    const deselectedShipments = pdoRequestShipments.filter((shipment) => !shipment.includeInd);
    this.deselectedShipmentsSubject.next(deselectedShipments.length);

    return this.cityOperationsApiService.updatePnDOptimizerShipments(request, path).pipe(map(() => true));
  }

  getPndOptimizerStatus(): Observable<GetPnDOptimizerStatusResp> {
    const query: GetPnDOptimizerStatusQuery = {
      ...new GetPnDOptimizerStatusQuery(),
      runNbr: this.runNbr,
    };
    return this.cityOperationsApiService.getPnDOptimizerStatus(query);
  }

  listPnDOptimizerResults(): Observable<ListPnDOptimizerResultsResp> {
    const path: ListPnDOptimizerResultsPath = {
      ...new ListPnDOptimizerResultsPath(),
      runNbr: this.runNbr,
      runSequenceNbr: this.getOptimizeParam('OPZPRIMRY'),
    };

    return this.cityOperationsApiService.listPnDOptimizerResults(path);
  }

  listPnDOptimizerRoutesByRouteInst(routeInstIds: number[]): Observable<OptimizerRouteStop[]> {
    const query: ListPnDOptimizerRoutesByRouteInstIdsRqst = {
      ...new ListPnDOptimizerRoutesByRouteInstIdsRqst(),
      routeInstIds,
    };
    return this.cityOperationsApiService.listPnDOptimizerRoutesByRouteInstIds(query).pipe(
      catchError(() => of({ optimizerRouteStops: [] } as ListPnDOptimizerRoutesByRouteInstIdsResp)),
      map((resp: ListPnDOptimizerRoutesByRouteInstIdsResp) => resp.optimizerRouteStops),
      tap((optimizerRouteStops: OptimizerRouteStop[]) => {
        this.optimizerRouteStopsSubject.next(optimizerRouteStops);
      })
    );
  }

  listPnDOptimizerSameAddressShipments(): Observable<ListOptimizerSameAddressShipmentsResp> {
    if (!!this.sameAddressShipments) {
      return this.sameAddressShipments$.pipe(
        map((value) => ({
          ...new ListOptimizerSameAddressShipmentsResp(),
          sameAddressShipments: value,
        }))
      );
    } else {
      const path: ListOptimizerSameAddressShipmentsPath = {
        ...new ListOptimizerSameAddressShipmentsPath(),
        runNbr: this.runNbr,
      };

      return this.cityOperationsApiService
        .listOptimizerSameAddressShipments(path)
        .pipe(tap((response) => this.sameAddressShipmentsSubject.next(response.sameAddressShipments)));
    }
  }

  setNextStepReadyState(isReady: boolean): void {
    this.isNextStepReadySubject.next(isReady);
  }

  reset() {
    this.pdoRequestShipmentsSubject.next(undefined);
    this.pdoRequestDriversSubject.next(undefined);
    this.pdoRequestEquipmentSubject.next(undefined);
    this.sameAddressShipmentsSubject.next(undefined);
    this.runNbrSubject.next(undefined);
    this.selectedShipmentsSubject.next(0);
    this.optimizeParamsSubject.next([]);
    this.isNextStepReadySubject.next(false);
  }
}
