import { Actions, ActionTypes } from '@pnd-store/dock-routes-store/dock-routes.actions';
import { initialState, State } from '@pnd-store/dock-routes-store/dock-routes.state';
import { tassign } from 'tassign';

export function dockRoutesReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setDockStops:
      return tassign(state, {
        dockStops: action.payload.dockStops,
      });
    case ActionTypes.setSelectedDockActivities:
      return tassign(state, {
        selectedDockActivities: action.payload.selectedDockActivities,
      });
    case ActionTypes.setIncompleteDockStops:
      return tassign(state, {
        incompleteDockStops: action.payload.incompleteDockStops,
      });
    default:
      return state;
  }
}
