import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core/tooltip';
import { LayoutPreferenceSelectorComponent } from './components/layout-preference-selector.component';
import { XpoLtlSaveLayoutDialog } from './components/save-layout-dialog.component';

const components = [LayoutPreferenceSelectorComponent, XpoLtlSaveLayoutDialog];

const materialModules = [
  FormsModule,
  MatMenuModule,
  MatButtonModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatIconModule,
  MatInputModule,
  MatDialogModule,
];

const xpoModules = [
  XpoDialogModule,
  XpoIconModule,
  XpoButtonModule,
  XpoConfirmDialogModule,
  XpoSelectModule,
  XpoTooltipModule,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ...materialModules, ...xpoModules],
})
export class LayoutManagerModule {}
