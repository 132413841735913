import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { PickupTypeCd, ShipmentSpecialServiceCd, TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { has as _has, toNumber as _toNumber } from 'lodash';
import { StoreSourcesEnum } from '../enums/store-sources.enum';

export interface EventItem<
  IDENTIFIER_TYPE extends
    | AssignedStopIdentifier
    | UnassignedDeliveryIdentifier
    | UnassignedPickupIdentifier
    | RouteItemIdentifier
    | PlanningRouteShipmentIdentifier
    | PlanningRouteShipmentIdentifier[]
    | PastDueShipmentIdentifier
    | PastDueShipmentIdentifier[]
    | DriverIdentifier
> {
  id: IDENTIFIER_TYPE;
  source: StoreSourcesEnum;
}

export interface AssignedStopIdentifier {
  routeInstId: number;
  seqNo: number;
  origSeqNo: number;
  stopType?: string;
  customerAcctIntId?: number;
  customerName?: string;
  address?: string;
  stopStatus?: TripNodeStatusCd;
  tripInstId?: number;
  tripNodeSequenceNbr?: number;
}

export interface PinnedStopIdentifier {
  routeInstId: number;
  origSeqNo: number;
}

/**
 * Return true if the passed AssignedStopIdentifiers are equal. Only tests routeInstId and seqNo.
 */
export function areStopsEqual(stopA: AssignedStopIdentifier, stopB: AssignedStopIdentifier): boolean {
  if (!stopA || !stopB) {
    return false;
  } else {
    return stopA.routeInstId === stopB.routeInstId && stopA.seqNo === stopB.seqNo;
  }
}

export interface AssignedStopPositionIdentifier extends AssignedStopIdentifier {
  latitudeNbr: number;
  longitudeNbr: number;
}

export interface ConsigneeIdentifier {
  consignee: {
    acctInstId: number;
    name1: string;
    latitudeNbr: number;
    longitudeNbr: number;
  };
}

// return true if the UnassignedDeliveryIdentifier are equal (consignee and shipmentInstId)
export function unassignedDeliveryIdentifierEqual(
  a: UnassignedDeliveryIdentifier,
  b: UnassignedDeliveryIdentifier
): boolean {
  if (consigneeToId(a) === consigneeToId(b)) {
    if (!!a.shipmentInstId || !!b.shipmentInstId) {
      return _toNumber(a.shipmentInstId) === _toNumber(b.shipmentInstId);
    } else {
      return true;
    }
  }
  return false;
}

/**
 * return a string ID for the given ConsigneeIdentifier
 */
export function consigneeToId(id: ConsigneeIdentifier): string {
  const acctInstId = id?.consignee?.acctInstId;
  return acctInstId ? `${acctInstId}` : undefined;
}

/**
 * Return a string id representing the passed identifier, including any
 * shipmentInstId in the identifier object.
 */
export function consigneeShipmentToId(id: UnassignedDeliveryIdentifier): string {
  const consigneeId = consigneeToId(id);
  if (!consigneeId) {
    return undefined;
  } else {
    const shipmentInstId = id?.shipmentInstId || 'none';
    return `${consigneeId}-${shipmentInstId}`;
  }
}

export interface UnassignedDeliveryIdentifier extends ConsigneeIdentifier {
  shipmentInstId?: number;
}

export interface RouteStopIdentifier extends ConsigneeIdentifier {
  routeInstId?: number;
}

export interface PlanningRouteShipmentIdentifier extends RouteStopIdentifier {
  shipmentInstId?: number;
  proNbr?: string;
}

export interface PastDueShipmentIdentifier extends RouteStopIdentifier {
  shipmentInstId?: number;
  proNbr?: string;
}

/**
 * Return a string ID for the given RouteStopIdentifier.
 * It can also be used with a DeliveryShipmentSearchRecord.
 * returns undefined if id is undefined or does not contain both the routeInstId and consignee.acctInstId
 */
export function routeStopToId(id: RouteStopIdentifier | DeliveryShipmentSearchRecord): string {
  if (!id || !_has(id, 'routeInstId')) {
    return undefined;
  } else {
    return `${id.routeInstId}-${id?.consignee?.acctInstId || 'undefined'}`;
  }
}

export interface UnassignedPickupIdentifier {
  pickupInstId: number;
  tripInstId?: number;
  shipper: {
    latitudeNbr: number;
    longitudeNbr: number;
  };
  motorMovesNbr: number;
  loosePiecesCount: number;
  palletsCount: number;
  weightLbs: number;
  specialServiceSummary: ShipmentSpecialServiceCd[];
  pickupTypeCd: PickupTypeCd;
  gridGroupName?: string;
}

export interface RouteItemIdentifier {
  routeInstId: number;
  tripInstId?: number;
}

export interface DriverIdentifier {
  driverId: string;
}
