import { Injectable } from '@angular/core';
import {
  GetZoneAndSatelliteBySicPath,
  GetZoneAndSatelliteBySicQuery,
  GetZoneAndSatelliteBySicResp,
  LocationApiService,
  ZoneSatelliteInfo,
} from '@xpo-ltl-2.0/sdk-location';
import { uniq as _uniq } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, map, retryWhen, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SicZonesAndSatellitesService {
  constructor(private locationService: LocationApiService) {}

  // key is sicCd, value is a list of satellite sics for sicCd
  private sicSatellitesCache: { [sicCd: string]: string[] } = {};

  /**
   * Get the zone and satellite info for the selected host sic.

   * @param sicCd
    * @param zoneInd
    * @param satelliteInd
    */
  getZoneSatelliteInfo(sicCd: string, zoneInd: boolean, satelliteInd: boolean): Observable<ZoneSatelliteInfo[]> {
    const path: GetZoneAndSatelliteBySicPath = { ...new GetZoneAndSatelliteBySicPath(), sicCd };
    const query: GetZoneAndSatelliteBySicQuery = {
      ...new GetZoneAndSatelliteBySicQuery(),
      zoneInd,
      satelliteInd,
    };
    return this.locationService.getZoneAndSatelliteBySic(path, query).pipe(
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((error, i) => {
            if (i >= 5) {
              return throwError({ ...error });
            }
            return of(error).pipe(delay(500));
          })
        );
      }),
      map((response: GetZoneAndSatelliteBySicResp) => {
        return response?.zoneSatelliteInfo ?? [];
      }),
      tap((zoneSatelliteInfoArray) => this.saveSatellitesToCache(zoneSatelliteInfoArray))
    );
  }

  /**
   * Save the relations between a sic (host or zone) with its satellites,
   * in order to make them available for the getSatellitesForSicFromCache method.
   *
   * @param zoneSatelliteInfoArray
   */
  private saveSatellitesToCache(zoneSatelliteInfoArray: ZoneSatelliteInfo[]) {
    zoneSatelliteInfoArray.forEach((zoneSatelliteInfo) => {
      if (zoneSatelliteInfo.satelliteParent) {
        const prevValue = this.sicSatellitesCache[zoneSatelliteInfo.satelliteParent] || [];
        this.sicSatellitesCache[zoneSatelliteInfo.satelliteParent] = _uniq([
          ...prevValue,
          zoneSatelliteInfo.locationSic,
        ]);
      }
    });
  }

  /**
   * Return all the satellite sics for a given sic from the cache.
   * Use it when we know that the sic satellite info has already been fetched with getZoneSatelliteInfo.
   *
   * @param sic
   */
  getSatellitesForSicFromCache(sic: string): string[] {
    if (this.sicSatellitesCache[sic]) {
      return this.sicSatellitesCache[sic];
    } else {
      console.error(`Satellites for sic '${sic}' not found in cache`);
      return [];
    }
  }

  /**
   * Return all the satellite sics for a given sic.
   *
   * @param sic
   */
  fetchSatellitesForSic(sic: string): Observable<string[]> {
    const path: GetZoneAndSatelliteBySicPath = { ...new GetZoneAndSatelliteBySicPath(), sicCd: sic };
    const query: GetZoneAndSatelliteBySicQuery = { ...new GetZoneAndSatelliteBySicQuery(), satelliteInd: true };
    return this.locationService.getZoneAndSatelliteBySic(path, query).pipe(
      map((response: GetZoneAndSatelliteBySicResp) => {
        const validZones: ZoneSatelliteInfo[] = response?.zoneSatelliteInfo ?? [];
        return validZones.map((z) => z.locationSic);
      })
    );
  }
}
