import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-board/ag-grid';
import { NotificationMessageService } from 'core';
import { Observable } from 'rxjs';
import { PndMultiGridBoardViewService } from 'shared/classes/pnd-multi-grid-board-view.service';
import { PnDViewDataStoreBase } from 'shared/classes/pnd-view-data-store-base.class';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';

// default View tabs
export enum UnassignedDeliveriesViewId {
  ShowAll = 'showAll',
  Arrived = 'arrived',
  ArrivalByNoon = 'arrivalByNoon',
  ByTrailer = 'byTrailer',
}

/**
 * Shared View Data source for Stops and Shipments views
 */
export class UnassignedDeliveriesViewDataStore extends PnDViewDataStoreBase {
  protected readonly defaultViews: XpoAgGridBoardViewConfig[];

  // trip details uses multigrid board view service to save all preferences,
  // but adding for proper base class extension
  constructor(
    protected userPreferencesService: UserPreferencesService,
    protected notificationMessageService: NotificationMessageService,
    protected preferences: XpoAgGridBoardViewConfig[],
    protected componentName: string,
    private boardType: string,
    private multiGridBoardViewService: PndMultiGridBoardViewService,
    private sicCd: string
  ) {
    super(userPreferencesService, notificationMessageService, preferences, componentName);

    this.defaultViews = [
      this.showAllConfig(),
      this.arrivedConfig(this.sicCd),
      this.arrivalByNoonConfig(),
      this.byTrailerConfig(),
    ];
  }

  protected loadViewsFromUserPreferences(): XpoAgGridBoardViewConfig[] {
    const boardViewConfigs: XpoAgGridBoardViewConfig[] = super.loadViewsFromUserPreferences();
    const showAllview = boardViewConfigs.find((view) => view.id === 'showAll');
    const index = boardViewConfigs.indexOf(showAllview);
    boardViewConfigs.splice(index, 1);
    boardViewConfigs.unshift(showAllview);
    return boardViewConfigs;
  }

  updateDataStore(viewConfigs: XpoAgGridBoardViewConfig[]): Observable<XpoAgGridBoardViewConfig[]> {
    return this.multiGridBoardViewService.updateViewsForBoard$(viewConfigs, this.boardType, this.componentName);
  }

  private showAllConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {},
      id: UnassignedDeliveriesViewId.ShowAll,
      name: 'Show All',
      visible: true,
      systemDefined: true,
    };
  }

  private arrivalByNoonConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {
        scheduleETA: '12:00',
      },
      id: UnassignedDeliveriesViewId.ArrivalByNoon,
      name: 'Arrival By Noon',
      visible: true,
      systemDefined: true,
    };
  }

  private arrivedConfig(sicCd: string): XpoAgGridBoardViewConfig {
    const config = {
      closeable: false,
      criteria: {
        currentSicCd: [sicCd],
      },
      id: UnassignedDeliveriesViewId.Arrived,
      name: 'Arrived',
      visible: true,
      systemDefined: true,
    };
    return config;
  }

  private byTrailerConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {},
      id: UnassignedDeliveriesViewId.ByTrailer,
      name: 'By Trailer',
      visible: true,
      systemDefined: true,
    };
  }
}
