import { Injectable } from '@angular/core';
import { PndStoreState } from '@pnd-store/.';
import { PndStore } from '@pnd-store/pnd-store';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ActivityShipment, DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { ScheduleStatusCd, MovementStatusCd } from '@xpo-ltl/sdk-common';
import { isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
import { PndRouteUtils } from '../../../../shared/route-utils';

@Injectable({ providedIn: 'root' })
export class ShipmentLocationService {
  constructor(private timeService: XpoLtlTimeService, private pndStore$: PndStore<PndStoreState.State>) {}

  /**
   * Given XRT delivery shipment search record, assemble the shipment's current location based on movement history and ETA.
   *  - We use the Current SIC, Current Trailer, Trailer SIC (schedule destination), Schedule ETA and OnDock/OnTrailer/OutForDelivery info
   *
   * @param deliveryShipment - XRT delivery shipment record used to assemble current shipment's location.
   *
   * SEE PCT-6690 for rules around label format...
   */
  getShipmentCurrentLocation(sic: string, deliveryShipment: DeliveryShipmentSearchRecord): string {
    return this.getCurrentLocationDescription(
      sic,
      deliveryShipment.scheduleStatusCd,
      deliveryShipment.scheduleDestinationSicCd,
      deliveryShipment.shipmentLocationSicCd,
      deliveryShipment.scheduleETA,
      deliveryShipment.currentTrailer,
      deliveryShipment.movementStatusCd,
      PndRouteUtils.getRouteId({
        routePrefix: deliveryShipment.routePrefix,
        routeSuffix: deliveryShipment.routeSuffix,
      }),
      deliveryShipment.dockName
    );
  }

  /**
   * Given ActivityShipment, assemble the shipment's current location based on movement history and ETA.
   *  - We use the Current SIC, Current Trailer, Trailer SIC (schedule destination), Schedule ETA and OnDock/OnTrailer/OutForDelivery info
   *
   * @param activityShipment - ActivityShipment used to assemble current shipment's location.
   * @param routeName - routePrefix-routeSuffix
   *
   * SEE PCT-10070 for rules around label format...
   */
  getShipmentCurrentLocationByActivity(activityShipment: ActivityShipment, routeName: string): string {
    return this.getCurrentLocationDescription(
      activityShipment.destinationTerminalSicCd,
      activityShipment.scheduleStatusCd,
      activityShipment.scheduleDestinationSicCd,
      activityShipment.currentSicCd,
      activityShipment.scheduledEta,
      activityShipment.currentTrailer,
      activityShipment.currentStatus,
      routeName
    );
  }

  private getCurrentLocationDescription(
    sic: string,
    scheduleStatusCd: ScheduleStatusCd,
    scheduleDestinationSicCd: string,
    shipmentLocationSicCd: string,
    scheduleETA: Date,
    currentTrailer: string,
    movementStatusCd: string,
    routeName: string,
    dockName?: string
  ) {
    let locationDescription: string = '';
    let location: string = '';
    let eta: string = '';
    let mvmtStat: string = '';
    let trailer: string = '';

    if (scheduleStatusCd === ScheduleStatusCd.ENROUTE || scheduleStatusCd === ScheduleStatusCd.DELAYED) {
      locationDescription = 'Enroute to';
    } else {
      locationDescription = 'At';
    }

    if (scheduleStatusCd === ScheduleStatusCd.ENROUTE || scheduleStatusCd === ScheduleStatusCd.DELAYED) {
      location = scheduleDestinationSicCd;
    } else if (!_isEmpty(shipmentLocationSicCd)) {
      location = shipmentLocationSicCd;
      if (_isEqual(location, sic)) {
        routeName = ''; // Remove routeName when its at current destination
      }
    } else {
      location = 'Location unknown';
      locationDescription = ''; // Remove At
      routeName = ''; // Remove routeName  when its at Location unknown
    }

    const etaValue = this.timeService.formatDate(scheduleETA, 'HH:mm MM/DD', scheduleDestinationSicCd);

    if (scheduleStatusCd === ScheduleStatusCd.ENROUTE || scheduleStatusCd === ScheduleStatusCd.DELAYED) {
      if (scheduleStatusCd === ScheduleStatusCd.ENROUTE && scheduleETA) {
        eta = `ETA ${etaValue}`;
      } else if (scheduleStatusCd === ScheduleStatusCd.DELAYED && scheduleETA) {
        eta = `ETA ${etaValue} (delayed)`;
      } else {
        eta = 'ETA unknown';
      }
    }

    if (_isEmpty(currentTrailer) && movementStatusCd === MovementStatusCd.ON_DOCK) {
      mvmtStat = 'on dock';
    }
    if (movementStatusCd === MovementStatusCd.OUT_FOR_DLVRY) {
      mvmtStat = 'out for delivery';
    }

    if (!_isEmpty(currentTrailer)) {
      trailer = `on trailer ${currentTrailer}`;
    }

    let assembledDescription = '';

    if (!_isEmpty(locationDescription)) {
      assembledDescription = locationDescription + ' ' + location;
    } else {
      assembledDescription = location;
    }
    if (!_isEmpty(eta)) {
      assembledDescription += ', ' + eta;
    }
    if (!_isEmpty(mvmtStat)) {
      assembledDescription += ', ' + mvmtStat;
    }
    if (!_isEmpty(trailer)) {
      assembledDescription += ', ' + trailer;
    }
    if (!_isEmpty(routeName)) {
      assembledDescription += ', assigned to route ' + routeName;
    }
    if (!_isEmpty(dockName)) {
      assembledDescription += mvmtStat.toLocaleLowerCase() === 'on dock' ? ` ${dockName}` : ` on dock ${dockName}`;
    }
    return assembledDescription;
  }
}
