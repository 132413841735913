export enum DockRoutesGridFields {
  ROW_SELECTED = 'rowSelected',
  MAIN_GROUP_VALUE = 'mainGroupValue',
  PRO_CALL_NBR = 'proOrCallNbr',
  ACTIVITY_CD = 'activityCd',
  CUSTOMER_NAME = 'customerName',
  ADDRESS = 'address',
  CONSIGNEE_CITY = 'consigneeCity',
  BILL_COUNT = 'billCount',
  FBDS_PRINT_COUNT_CD = 'fbdsPrintCountCd',
  PIECES_COUNT = 'piecesCount',
  PALLET_COUNT = 'palletCount',
  WEIGHT_LBS = 'weightLbs',
  CUBE_NBR = 'cubeNbr',
  MOTOR_MOVE_NBR = 'motorMovesNbr',
  SPECIAL_SERVICES = 'specialServices',
  CALL_NBR = 'callNbr',
  PRO = 'proNbr',
  ORIGIN_SIC = 'originSic',
  DEST_SIC = 'destinationSic',
  DEST_ZIP = 'destinationZip6',
}
