import { Injectable } from '@angular/core';
import { NumberToValueMap } from '@pnd-store/number-to-value-map';
import { PndStore } from '@pnd-store/pnd-store';
import * as PndStoreState from '@pnd-store/pnd-store.state';
import * as TripsStoreActions from '@pnd-store/trips-store/trips-store.actions';
import * as TripsStoreSelectors from '@pnd-store/trips-store/trips-store.selectors';
import { Unsubscriber, XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  ListPnDOptimizerPickupStopsPath,
  ListPnDOptimizerPickupStopsResp,
  ListPnDRoutesByGeofenceResp,
  ListPnDRoutesByGeofenceRqst,
  ListPnDTripsPath,
  ListPnDTripsQuery,
  Route,
  RouteDetail,
  Stop,
  TripDetail,
  TripDetailChangeRecord,
} from '@xpo-ltl/sdk-cityoperations';
import { LatLong, TripStatusCd } from '@xpo-ltl/sdk-common';
import {
  cloneDeep as _cloneDeep,
  Dictionary,
  keyBy as _keyBy,
  map as _map,
  reduce as _reduce,
  size as _size,
  toString as _toString,
  uniq as _uniq,
} from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, Subscriber } from 'rxjs';
import { catchError, filter, finalize, map, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PndRouteUtils } from 'shared/route-utils';
import { FeatureTypes } from '../../../../core/services/features/feature-types.enum';
import { TripsSearchCriteria } from '../../../store/trips-store/trips-search-criteria.interface';
import { TripPlanningGridItem } from '../../components/trip-planning/models/trip-planning-grid-item.model';
import { RouteItemIdentifier } from '../interfaces/event-item.interface';
import { PndXrtChangedDocument } from './auto-refresh-base.service';
import { DriverContactsService } from './driver-contacts.service';
import { OptimizeService } from './optimize/optimize.service';
import { StopsCacheService } from './stops-cache.service';
import { TripPlanningAutoRefreshService } from './trip-planning-auto-refresh.service';
import { TripsGridItemConverterService } from './trips-grid-item-converter/trips-grid-item-converter.service';

export interface ForecastedPickupStop {
  routeInstId: number;
  stops: Stop[];
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class TripsService {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  // cache of all TripDetails from the last search
  private readonly tripsSubject = new BehaviorSubject<TripDetail[]>([]);
  readonly trips$ = this.tripsSubject.asObservable();

  // return the current list of Trips
  get trips() {
    return this.tripsSubject.value;
  }

  // emits true when loading Trips, false when done loading
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject.asObservable();

  private loadingStopsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingStops$ = this.loadingStopsSubject.asObservable();

  private cachedForecastedPickupsByRoute: NumberToValueMap<Stop[]> = {};

  private isNewerUpdateSubject = new BehaviorSubject<boolean>(false);
  readonly isNewerUpdate$ = this.isNewerUpdateSubject.asObservable();

  private prevTripSubject = new BehaviorSubject<TripPlanningGridItem>(new TripPlanningGridItem());
  readonly prevTrip$ = this.prevTripSubject.asObservable();
  get prevTrip() {
    return this.prevTripSubject.value;
  }

  private newTripSubject = new BehaviorSubject<TripPlanningGridItem>(new TripPlanningGridItem());
  readonly newTrip$ = this.newTripSubject.asObservable();
  get newTrip() {
    return this.newTripSubject.value;
  }

  constructor(
    private cityOperationsApiService: CityOperationsApiService,
    private featuresService: XpoLtlFeaturesService,
    private optimizeService: OptimizeService,
    private tripsGridItemConverterService: TripsGridItemConverterService,
    private driverContactsService: DriverContactsService,
    private stopsCacheService: StopsCacheService,
    private pndStore$: PndStore<PndStoreState.State>,
    private tripPlanningAutoRefreshService: TripPlanningAutoRefreshService
  ) {
    this.subscribeToAutoRefreshUpdates();
  }

  private subscribeToAutoRefreshUpdates(): void {
    this.tripPlanningAutoRefreshService.changedDocuments$
      .pipe(
        filter((docs) => !!docs?.length),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((changedDocuments: PndXrtChangedDocument[]) => {
        this.updateTripsFromAutoRefresh(changedDocuments);
      });
  }

  updateLoadingStops(val: boolean): void {
    this.loadingStopsSubject.next(val);
  }

  /**
   * Set the list of Trips without making an API call
   */
  updateTrips(trips: TripDetail[]) {
    this.tripsSubject.next(trips);
  }

  /**
   * Get from server all trips matching search criteria
   */
  searchTrips(criteria: TripsSearchCriteria, planDate: Date): Observable<TripDetail[]> {
    // TODO - API currently does not support using Filter data here. So, just pass along the Criteria
    return this.fetchTrips(criteria);
  }

  /**
   * Find the Route in the list of Trips and update it. If a trip is modified with the updated route,
   * then update the trips$
   *
   * Returns true if a Trip was modified, else false
   */
  updateRouteInTrips(updatedRoute: Route) {
    const updatedRouteInstId = updatedRoute?.routeInstId;
    if (updatedRouteInstId) {
      const updatedTrips: TripDetail[] = [];
      let foundRouteToUpdate = false;

      this.trips.forEach((trip: TripDetail) => {
        // see if the route is in this trip. If so, then we need to update
        // that route.
        const routes = trip?.route ?? [];
        const foundRoute =
          routes.findIndex((routeDetail) => routeDetail.route.routeInstId === updatedRoute.routeInstId) !== -1;

        if (foundRoute) {
          foundRouteToUpdate = true;
          trip.route = routes.map((oldRoute) => {
            const oldRouteInstId = oldRoute?.route?.routeInstId;
            if (oldRouteInstId === updatedRouteInstId) {
              // replace with modified Route
              return { ...new RouteDetail(), route: updatedRoute };
            } else {
              return oldRoute;
            }
          });
        }
        // route is not in this trip, so just push it on to the list
        updatedTrips.push(trip);
      });

      if (foundRouteToUpdate) {
        this.updateTrips(updatedTrips);
      }
    }
  }

  /**
   * Fetch the Stops for the specified RouteInstId.
   * If tripInstId is provided, pickups are being added to the array of stops
   */
  fetchStopsForRoute(routeInstId: number, tripInstId?: number): Observable<{ routeInstId: number; stops: Stop[] }> {
    return this.stopsCacheService
      .request({ routeInstId, tripInstId })
      .pipe(map((stops: Stop[]) => ({ routeInstId, stops })));
  }

  /**
   * Fetch the Forecasted Pickups for the specified RouteInstId
   */
  fetchForecastedPickupsForRoute(routeInstId: number, color: string): Observable<ForecastedPickupStop> {
    const pathParams: ListPnDOptimizerPickupStopsPath = {
      ...new ListPnDOptimizerPickupStopsPath(),
      routeInstId: `${routeInstId}`,
    };

    if (this.cachedForecastedPickupsByRoute[routeInstId]) {
      return of({ routeInstId, stops: this.cachedForecastedPickupsByRoute[routeInstId], color: color ?? 'red' });
    } else {
      return this.cityOperationsApiService.listPnDOptimizerPickupStops(pathParams).pipe(
        take(1),
        map((response: ListPnDOptimizerPickupStopsResp) => {
          this.cachedForecastedPickupsByRoute[routeInstId] = response.pickupStops;
          return { routeInstId, stops: response.pickupStops, color: color };
        })
      );
    }
  }

  /**
   * Clear cached stops except the ones you want to keep
   * @param routesInstIds Cached stops to keep (by routeInstId)
   */
  keepCachedStops(routesInstIds: number[]): void {
    if ((routesInstIds?.length ?? 0) > 0) {
      const keysToDelete = this.stopsCacheService.keys.filter((key: string) => {
        return !routesInstIds.includes(+key);
      });

      this.stopsCacheService.delete(keysToDelete);
    } else {
      this.stopsCacheService.clear();
    }
  }

  /**
   * Clear the given cached stops
   * @param routesInstIds Cached stops to clear (by routeInstId)
   */
  clearCachedStops(routesInstIds: number[]): void {
    if ((routesInstIds?.length ?? 0) > 0) {
      const keysToDelete = this.stopsCacheService.keys.filter((key: string) => {
        return routesInstIds.includes(+key);
      });
      this.stopsCacheService.delete(keysToDelete);
    }
  }

  /**
   * Request trips located withing the specified polygon
   */
  fetchTripGridItemsByGeofence(
    latLngArray: LatLong[],
    sizZonesAndSatellites: string[],
    planDate: Date
  ): Observable<TripPlanningGridItem[]> {
    return new Observable((observer: Subscriber<TripPlanningGridItem[]>) => {
      if (_size(latLngArray)) {
        const request: ListPnDRoutesByGeofenceRqst = {
          geofences: latLngArray,
          hostDestinationSicCds: sizZonesAndSatellites,
          planDate: moment(planDate).format('YYYY-MM-DD'),
        };

        this.cityOperationsApiService
          .listPnDRoutesByGeofence(request)
          .pipe(
            takeUntil(this.unsubscriber.done$),
            catchError(() => of([]))
          )
          .subscribe((response: ListPnDRoutesByGeofenceResp) => {
            if (response && response.routeInstIds && response.routeInstIds.length > 0) {
              this.trips$.subscribe((trips: TripDetail[]) => {
                const tripGridItems: TripPlanningGridItem[] = this.tripsGridItemConverterService.getTripsGridItems(
                  trips
                );
                const tripsToSelect: TripPlanningGridItem[] = tripGridItems.filter(
                  (tripItem: TripPlanningGridItem) =>
                    response.routeInstIds.includes(tripItem.routeInstId) && tripItem.routeInstId !== 0
                );
                observer.next(tripsToSelect);
                observer.complete();
              });
            } else {
              observer.next([]);
              observer.complete();
            }
          });
      } else {
        observer.next([]);
        observer.complete();
      }
    });
  }

  /**
   * Fetch list of Trips that match the search criteria
   */
  private fetchTrips(criteria: TripsSearchCriteria): Observable<TripDetail[]> {
    this.loadingSubject.next(true);

    const path: ListPnDTripsPath = {
      sicCd: criteria.sicCd,
    };

    const query: ListPnDTripsQuery = {
      zoneIndicatorCd: criteria.zoneIndicatorCd,
      tripStatusCd: [...criteria.tripStatusCd],
      tripDate: criteria.tripDate,
      tripDetailCds: undefined,
    };

    const shouldLoadTripPSEValues = this.featuresService.getFeatureValue(criteria.sicCd, FeatureTypes.Optimize) === 'Y';

    // Refresh the avaiable driver contacts for messaging
    this.driverContactsService.refreshContacts(criteria);

    return this.cityOperationsApiService.listPnDTrips(path, query).pipe(
      pluck('tripDetails'),
      switchMap((trips: TripDetail[]) => {
        if (shouldLoadTripPSEValues) {
          const tripRouteInstIds: number[] = _reduce(
            trips,
            (routeIds: number[], currentTrip: TripDetail) => {
              const currentRouteIds: number[] = _map(currentTrip.route, (routeDetail: RouteDetail) => {
                return routeDetail.route.routeInstId;
              });
              return [...routeIds, ...currentRouteIds];
            },
            []
          );

          return this.optimizeService
            .listPnDOptimizerRoutesByRouteInst(_uniq(tripRouteInstIds))
            .pipe(switchMap(() => of(trips)));
        } else {
          return of(trips);
        }
      }),
      catchError(() => of([])),
      tap((trips: TripDetail[]) => {
        this.updateTrips(trips);
      }),
      finalize(() => {
        this.loadingSubject.next(false);
      })
    );
  }

  getRouteNamesForRouteIds(routeInstIds: string[]): string[] {
    const routeNames: string[] = [];
    this.trips.forEach((trip: TripDetail) => {
      trip.route.forEach((tripRoute) => {
        if (routeInstIds.includes(_toString(tripRoute?.route?.routeInstId))) {
          routeNames.push(PndRouteUtils.getRouteId(tripRoute.route));
        }
      });
    });
    return routeNames;
  }

  /**
   * Return the matching Routes from the available Trips
   */
  routesForRouteIds(routeIds: RouteItemIdentifier[]): Route[] {
    const routes: Route[] = [];

    if (_size(routeIds) > 0) {
      const routeInstIds = routeIds.map((id) => id.routeInstId);

      this.trips.forEach((trip: TripDetail) => {
        trip.route.forEach((tripRoute) => {
          if (routeInstIds.includes(tripRoute.route.routeInstId)) {
            routes.push(tripRoute.route);
          }
        });
      });
    }

    return routes;
  }

  private updateTripsFromAutoRefresh(changedDocuments: PndXrtChangedDocument[]): void {
    const currentTripStatusCdFilters: TripStatusCd[] = this.pndStore$.selectSnapshot(TripsStoreSelectors.searchCriteria)
      .tripStatusCd;

    const tripsMap: Dictionary<TripDetail> = _keyBy(this.trips, (trip: TripDetail) => trip.trip.tripInstId);

    changedDocuments?.forEach((changedDocument: PndXrtChangedDocument) => {
      const updatedRecord: TripDetailChangeRecord = changedDocument.record;
      const currentTrip: TripDetail = tripsMap[updatedRecord.tripInstId];

      const tripStatusCd: TripStatusCd = updatedRecord.tripStatusCd[0];
      const isMatchingTripStatusCd: boolean = currentTripStatusCdFilters.some((statusCd) => statusCd === tripStatusCd);
      const isCancelledTrip: boolean = tripStatusCd === TripStatusCd.CANCELLED;

      const isNewTrip: boolean = !currentTrip && !!updatedRecord;
      const isNewerUpdate: boolean =
        new Date(updatedRecord.record?.trip?.auditInfo?.updatedTimestamp) >
        new Date(currentTrip?.trip?.auditInfo?.updatedTimestamp);

      if (isNewerUpdate) {
        this.prevTripSubject.next(_cloneDeep(this.tripsGridItemConverterService.getTripsGridItems([currentTrip]))?.[0]);
        this.newTripSubject.next(
          _cloneDeep(this.tripsGridItemConverterService.getTripsGridItems([updatedRecord.record]))?.[0]
        );
      }
      const shouldUpdateTripsRecord: boolean = isCancelledTrip || isNewerUpdate || isNewTrip;

      if (shouldUpdateTripsRecord) {
        // remove old record if present so we can replace it with updated value if needed
        delete tripsMap[updatedRecord.tripInstId];

        // if trip is not cancelled and matches current status cd filter, add the updated trip
        if (!isCancelledTrip && isMatchingTripStatusCd) {
          tripsMap[updatedRecord.tripInstId] = updatedRecord.record;
        }

        // update trips
        this.updateTrips(Object.values(tripsMap));
        this.pndStore$.dispatch(new TripsStoreActions.SetLastUpdate({ lastUpdate: new Date() }));
        this.isNewerUpdateSubject.next(isNewerUpdate);
      }
    });
  }
}
