import { Pipe, PipeTransform } from '@angular/core';
import { QualifierCodes } from '../enums/qualifier-codes.enum';

@Pipe({ name: 'deliveryQualifierCd' })
export class DeliveryQualifierCdPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    switch ((value || '').toUpperCase()) {
      case 'A':
        return 'Over';
      case 'B':
        return 'Trapped';
      case 'C':
        return 'Hold for Customer';
      case 'D':
        return 'Undelivered';
      case 'E':
        return 'Appointment';
      case 'F':
        return 'Spotted';
      case 'G':
        return 'Cartage';
      case QualifierCodes.ATTEMPTED:
        return 'Attempted';
      case 'J':
        return 'All Short';
      case QualifierCodes.PARTIAL_SHORT:
        return 'Partial Short';
      case QualifierCodes.REFUSED:
        return 'Refused';
      case QualifierCodes.DAMAGED:
        return 'Damaged';
      case 'N':
        return 'Suspense';
      case 'O':
        return 'Wait';
      case 'P':
        return 'Purge Blocked';
      case QualifierCodes.RETURNED:
        return 'Returned';
      case 'T':
        return 'Transfer';
      case QualifierCodes.FINAL:
        return 'Final Deliver';
    }

    return value;
  }
}
