import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PndStoreState } from '../index';
import { State, StateKey } from './spots-and-drops-store.state';

export const SpotsAndDropsState = createFeatureSelector<PndStoreState.State, State>(StateKey);

export const searchCriteria = createSelector(SpotsAndDropsState, (state: State) => state.criteria);

export const lastUpdate = createSelector(SpotsAndDropsState, (state: State) => state.lastUpdate);

export const selectedTrips = createSelector(SpotsAndDropsState, (state: State) => state.selectedTrips);

export const selectedTrip = createSelector(SpotsAndDropsState, (state: State) => state.selectedTrip);

export const SpotsAndDropsFocused = createSelector(SpotsAndDropsState, (state: State) => state.focusedSpotAndDrop);
