import { tassign } from 'tassign';
import { Actions, ActionTypes } from './geo-area-store.actions';
import { initialState, State } from './geo-area-store.state';

export function geoAreaReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setGeoAreas:
      return tassign(state, {
        geoAreas: action.payload.geoAreas,
      });

    case ActionTypes.setSicBoundaries:
      return tassign(state, {
        sicBoundaries: action.payload.sicBoundaries,
      });

    default: {
      return state;
    }
  }
}
