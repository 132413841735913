import { InjectionToken } from '@angular/core';
import { LayoutConfig } from './layout-config.interface';
import { XpoLtlLayoutPreferencesStorage } from './services/layout-preferences-storage.interface';

/**
 * Provide the list of default LayoutConfigs. If not provided or empty, then a default empty layout is created
 */
export const XPO_LTL_LAYOUT_PREFERENCES_DEFAULT_LAYOUTS = new InjectionToken<LayoutConfig[]>(
  'XpoLtlLayoutPreferencesDefaultLayouts'
);

/**
 * Provide the storage object used to save/load layout preference data
 */
export const XPO_LTL_LAYOUT_PREFERENCES_STORAGE = new InjectionToken<XpoLtlLayoutPreferencesStorage>(
  'XpoLtlLayoutPreferencesStorage'
);
