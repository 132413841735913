<div class="pnd-ModifyTripDetailsActivityTypeCellRenderer">
  <div
      class="pnd-ModifyTripDetailsActivityTypeCellRenderer__activity"
      [class.pnd-ModifyTripDetailsActivityTypeCellRenderer__link]="isClickable">
    {{ activityType }}
  </div>
  <xpo-status-indicator
      *ngIf="showPreLabel"
      class="pnd-ModifyTripDetailsActivityTypeCellRenderer__label"
      statusText="PRE"
      [statusColor]="XpoColors.XPO_YELLOW_350"
      isLabel="true"></xpo-status-indicator>
</div>
