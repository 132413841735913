import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComponentChangeUtils } from '../../classes/component-change-utils';
import { PluralKeyValue } from '../../classes/plural-maps';

export interface TotalTextCellRendererParams extends ICellRendererParams {
  displayValue: PluralKeyValue;
  suppressTotalText?: boolean;
  suppressUppercaseTotalsText?: boolean;
  suppressDisplay?: boolean;
}

@Component({
  selector: 'app-total-text-cell-renderer',
  templateUrl: './total-text-cell-renderer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalTextCellRendererComponent implements ICellRendererAngularComp {
  private countSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  count$: Observable<number> = this.countSubject.asObservable();

  displayValue: PluralKeyValue;
  suppressTotalText: boolean;
  suppressUppercaseTotalsText: boolean;
  totalsText: string = 'TOTAL';
  suppressDisplay: boolean = false;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: TotalTextCellRendererParams): void {
    this.countSubject.next(params.value || 0);
    this.displayValue = params.displayValue;
    this.suppressTotalText = params.suppressTotalText;
    this.suppressUppercaseTotalsText = params.suppressUppercaseTotalsText;
    this.suppressDisplay = params?.suppressDisplay ?? false;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  refresh(params: TotalTextCellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
}
