import { Injectable } from '@angular/core';
import { GlobalFilterStoreSelectors } from '@pnd-store/global-filters-store';
import { PndStore } from '@pnd-store/pnd-store';
import {
  CarrierManagementApiService,
  CarrierMasterLocation,
  CreateTenderPayload,
  ListCarriersQuery,
  ListCarriersResp,
  StartTenderChEnsembleRqst,
  TenderNotification,
} from '@xpo-ltl/sdk-carriermanagement';
import {
  CityOperationsApiService,
  ConvertRouteRqst,
  DispatchTrip,
  PnDTrip,
  TripDetail,
} from '@xpo-ltl/sdk-cityoperations';
import { CarrierDetailCd, CarrierTenderStatusCd, CmsTripTypeCd, TenderDetail } from '@xpo-ltl/sdk-common';
import { DispatcherTripsGridItem } from 'app/inbound-planning/components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { TripPlanningGridItem } from 'app/inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import { NotificationMessageService } from 'core/services/notification-message.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { PndAppUtils } from 'shared/app-utils';
import { PndStoreState } from '../../../store';

const TENDER_NOTIFICATION_DURATION = 0;

export class OrganizedTenders {
  [key: string]: TenderDetail[];
}
@Injectable({
  providedIn: 'root',
})
export class CartageService {
  private readonly carrierMasterLocationsSubject = new BehaviorSubject<CarrierMasterLocation[]>([]);
  readonly carrierMasterLocations$: Observable<
    CarrierMasterLocation[]
  > = this.carrierMasterLocationsSubject.asObservable();

  private readonly convertToCartageSuccessSubject = new BehaviorSubject<boolean>(false);
  readonly convertToCartageSuccess$ = this.convertToCartageSuccessSubject.asObservable();

  static isCartageTrip(
    trip: PnDTrip | TripDetail | DispatchTrip | DispatcherTripsGridItem | TripPlanningGridItem
  ): boolean {
    let isCartage: boolean = false;

    if (trip) {
      if ('trip' in trip) {
        isCartage = !!(<TripDetail | PnDTrip>trip)?.trip?.cmsCarrierId;
      } else {
        isCartage = !!(<DispatchTrip | DispatcherTripsGridItem | TripPlanningGridItem>trip)?.cmsCarrierId;
      }
    }

    return isCartage;
  }

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private carrierManagementService: CarrierManagementApiService,
    private cityOperationsService: CityOperationsApiService,
    private notificationMessageService: NotificationMessageService
  ) {
    this.subscribeToGlobalFilters();
  }

  private subscribeToGlobalFilters(): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(
        filter((sic: string) => !!sic),
        switchMap((sic: string) =>
          this.carrierManagementService.listCarriers({
            ...new ListCarriersQuery(),
            sicCd: sic,
            carrierDetailCds: [CarrierDetailCd.CARRIERS, CarrierDetailCd.LOCATION_SIC, CarrierDetailCd.LOCATIONS],
          })
        ),
        PndAppUtils.retry(5, 200, 50),
        catchError(() => of({ ...new ListCarriersResp() })),
        map((response: ListCarriersResp) => {
          const carrierMasterLocations: CarrierMasterLocation[] = [];
          response?.carrierMasterLocations?.forEach((carrierMasterLocation) => {
            carrierMasterLocations.push(carrierMasterLocation);
          });
          this.carrierMasterLocationsSubject.next(carrierMasterLocations);
        })
      )
      .subscribe();
  }

  convertRoute$(routeInstId: number, carrierId: number, dispatchType: CmsTripTypeCd): Observable<void> {
    const request = { ...new ConvertRouteRqst(), routeInstId, carrierId, cmsTripTypeCd: dispatchType };
    return this.cityOperationsService.convertRoute(request);
  }

  tenderRoute(sic: string, tripInstId: number, carrierName: string, routeName: string): void {
    const request: StartTenderChEnsembleRqst = {
      ...new StartTenderChEnsembleRqst(),
      ensembleName: 'CreateCartageTender',
      businessKey1: sic,
      businessKey2: `${tripInstId}`,
      businessKey3: carrierName,
      payload: { ...new CreateTenderPayload(), tripInstId },
    };
    this.carrierManagementService.startTenderChEnsemble(request).subscribe(
      () => {
        this.notificationMessageService
          .openNotificationMessage(
            NotificationMessageStatus.Success,
            `Tender for ${routeName} has been successfully sent to ${carrierName}.`
          )
          .subscribe(() => {});
      },
      (error) => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});
      }
    );
  }

  getCarrierName(cmsCarrierId: number): string {
    const carrier = this.carrierMasterLocationsSubject?.value?.find((c) => c.carrierMaster.carrierId === cmsCarrierId);
    return carrier?.carrierMaster.carrierName || '';
  }

  checkEmptyCarrierList(): boolean {
    return this.carrierMasterLocationsSubject?.value.length === 0;
  }
  checkEmptyCarrierLocationsList(): boolean {
    return this.carrierMasterLocationsSubject.value?.[0]?.carrierLocations?.length === 0;
  }

  setConvertToCartageSuccess(isSuccess: boolean) {
    this.convertToCartageSuccessSubject.next(isSuccess);
  }

  getOrganizedTenders(notification: TenderNotification): OrganizedTenders {
    const tenders = {};

    notification.tenderDetails
      .filter((tender) => tender.statusCd !== CarrierTenderStatusCd.TENDERED)
      .forEach((tender) => {
        if (tenders[tender.statusCd]) {
          tenders[tender.statusCd] = [...tenders[tender.statusCd], tender];
        } else {
          tenders[tender.statusCd] = [tender];
        }
      });

    return tenders;
  }

  notifyTender(organizedTenders, notification, navigationFn) {
    if (organizedTenders[CarrierTenderStatusCd.TENDER_FAILED]) {
      this.errorTenderNotification(organizedTenders[CarrierTenderStatusCd.TENDER_FAILED], notification);
    } else if (organizedTenders[CarrierTenderStatusCd.ACCEPTED_WITH_EXCEPTION]) {
      this.acceptedWithExceptionsTenderNotification(
        organizedTenders[CarrierTenderStatusCd.ACCEPTED_WITH_EXCEPTION],
        notification,
        navigationFn
      );
    } else if (organizedTenders[CarrierTenderStatusCd.ACCEPTED]) {
      this.acceptedTenderNotification(organizedTenders[CarrierTenderStatusCd.ACCEPTED], notification, navigationFn);
    } else if (organizedTenders[CarrierTenderStatusCd.REJECTED]) {
      this.rejectedTenderNotification(notification, navigationFn);
    }
  }

  private errorTenderNotification(tenderDetails: TenderDetail[], notification: TenderNotification) {
    const title =
      tenderDetails.length === 1
        ? `Shipment ${tenderDetails[0].proNbr} could not be tendered due the following error.`
        : `Some shipments in ${notification.routeName} could not be tendered due the following errors.`;

    const detail = notification.tenderMessages.join('<br>');

    this.tenderNotificationMessage(title, detail, NotificationMessageStatus.Error);
  }

  private acceptedWithExceptionsTenderNotification(
    tenderDetails: TenderDetail[],
    notification: TenderNotification,
    navigationFn: (tripId: number) => void
  ) {
    const isPartial: boolean = tenderDetails.length !== notification.tenderDetails.length;
    const title: string = `${notification.carrierName} has ${isPartial ? 'partial ' : ''}accepted tender for ${
      notification.routeName
    } with exceptions.`;

    this.tenderNotificationMessage(title, '', NotificationMessageStatus.Warn, 'View Trip', () =>
      navigationFn(notification.tripId)
    );
  }

  private acceptedTenderNotification(
    tenderDetails: TenderDetail[],
    notification: TenderNotification,
    navigationFn: (tripId: number) => void
  ) {
    const isPartial: boolean = tenderDetails.length !== notification.tenderDetails.length;
    const title: string = `${notification.carrierName} has ${isPartial ? 'partial ' : ''}accepted tender for ${
      notification.routeName
    }.`;
    const status = isPartial ? NotificationMessageStatus.Warn : NotificationMessageStatus.Info;

    this.tenderNotificationMessage(title, '', status, 'View Trip', () => navigationFn(notification.tripId));
  }

  private rejectedTenderNotification(notification: TenderNotification, navigationFn: (tripId: number) => void) {
    const title: string = `${notification.carrierName} has rejected tender for ${notification.routeName}.`;
    this.tenderNotificationMessage(title, '', NotificationMessageStatus.Warn, 'View Trip', () =>
      navigationFn(notification.tripId)
    );
  }

  private tenderNotificationMessage(
    title: string,
    description: string,
    status: NotificationMessageStatus,
    actionText?: string,
    actionFn?: () => void
  ) {
    this.notificationMessageService.openSnackBar(
      title,
      status,
      description,
      TENDER_NOTIFICATION_DURATION,
      actionText,
      actionFn,
      TENDER_NOTIFICATION_DURATION
    );
  }
}
