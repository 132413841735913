export enum ModifyTripDetailsSharedGridFields {
  ROW_SELECTED = 'rowSelected',
  MAIN_GROUP_VALUE = 'mainGroupValue',
  SEQUENCE_NBR = 'stopSequenceNbr',

  ACTIVITY_TYPE = 'activityCd',
  EXCEPTIONS = 'exceptions',

  FBDS_PRINT_COUNT_CD = 'fbdsPrintCountCd',
  FBDS_PRINT_COUNT = 'fbdsPrintCount',

  CUSTOMER = 'customerName',
  ADDRESS = 'address',
  CITY = 'consigneeCity',
  ZIP_CODE = 'zip6',
  BOL = 'bol',
  NOTES = 'notes',

  PALLETS = 'palletCount',
  BILLS = 'billCount',
  LOOSE_PIECES = 'piecesCount',
  WEIGHT = 'weightLbs',
  MOTOR_MOVES = 'motorMovesNbr',
  CUBE = 'cubeNbr',
  SPECIAL_SERVICES = 'specialServices',

  DEST_SIC = 'destinationSic',
  CALL_NBR = 'callNbr',
  TENDER_STATUS = 'tenderStatus',
}
