import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PndStore } from '@pnd-store/pnd-store';
import { XpoFilterComponentBase, XpoFilterCriteria } from '@xpo-ltl/ngx-board/core';
import { defaultTo as _defaultTo } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { PndStoreState } from '../../../../store';
import { GlobalFilterStoreSelectors } from '../../../../store/global-filters-store';
import { TimeValidationEnum } from '../../enums/time-validation.enum';
import { TimeUtil } from '../../services/time-format.util';
import { TimeFilter } from './time-filter';

@Component({
  selector: 'pnd-time-filter',
  templateUrl: 'time-filter.component.html',
  styleUrls: ['time-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': `this.searchFormControl.valid
      ? 'pnd-TimeInputFilter xpo-FilterChip pnd-TimeInputFilter--without-errors'
      : 'pnd-TimeInputFilter xpo-FilterChip pnd-TimeInputFilter--with-errors'`,
    '[attr.id]': 'id',
  },
})
export class TimeFilterComponent extends XpoFilterComponentBase<TimeFilter> {
  searchFormControl: UntypedFormControl = new UntypedFormControl();
  globalPlanDate$: Observable<Date> = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate);

  private previousValue: string = '';
  private timeFormatWithAnchorsRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);

  constructor(private pndStore$: PndStore<PndStoreState.State>) {
    super();
    this.inline = true;
  }

  protected initialize(): void {
    this.initFormControlSubscriptions();
    this.initValidators();

    // link disable/enable control to the disable$ observables
    this.configuration.disabled$.pipe(takeUntil(this.componentDestroyed)).subscribe((disabled) => {
      if (disabled) {
        this.searchFormControl.disable();
      } else {
        this.searchFormControl.enable();
      }
    });
  }

  protected onCriteriaModified(fieldValue: string, criteria: XpoFilterCriteria): void {
    if (fieldValue !== this.previousValue) {
      this.searchFormControl.setValue(fieldValue, { emitEvent: true });
    }
  }

  /**
   * Initializes validators
   */
  private initValidators(): void {
    const validators = this.configuration.validators;

    if (validators && validators.length) {
      this.searchFormControl.setValidators(validators);
      this.searchFormControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  /**
   * Initializes form control subscriptions
   */
  private initFormControlSubscriptions() {
    this.searchFormControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed))
      .subscribe((timeValue: string) => {
        this.setPreviousValue(timeValue);
        this.storeCriteriaIfValid(timeValue);
      });
  }

  /**
   * Stores passed in criteria into filter service if valid and different from current stored value
   * @param criteriaValue: value to be stored in criteria
   */

  private storeCriteriaIfValid(criteriaValue: string): void {
    if (
      (this.timeFormatWithAnchorsRegExp.test(criteriaValue) &&
        this.filtersService.criteria[this.configuration.field] !== criteriaValue) ||
      criteriaValue === ''
    ) {
      this.storeCriteria(criteriaValue);
    }
  }

  /**
   * Conditions passed in criteria into and formats search form control/previous value to valid time format
   * @param criteriaValue: value to be stored in criteria
   */
  private setPreviousValue(nextValue: string): void {
    if (nextValue) {
      const timeInput = {
        previousValue: this.previousValue,
        nextValue: nextValue,
      };

      const timeFixed = TimeUtil.inputTimeFormControlEnlightening(timeInput);

      this.previousValue = _defaultTo(timeFixed.previousValue, undefined);

      if (timeFixed.nextValue === TimeValidationEnum.ShouldReplace) {
        this.searchFormControl.setValue(_defaultTo(timeFixed.previousValue, undefined), { emitEvent: false });
      }
    } else {
      this.previousValue = undefined;
    }
  }
}
