import * as mapTypes from '@agm/core/services/google-maps-types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { State } from '@pnd-store/global-filters-store/global-filters-store.state';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { LatLong } from '@xpo-ltl/sdk-common';
import { ComponentChangeUtils } from 'app/inbound-planning/shared/classes/component-change-utils';

import { MapUtils } from 'app/inbound-planning/shared/classes/map-utils';
import { MapLayerType } from 'app/inbound-planning/shared/enums/map-layer-type.enum';
import { PndDialogService } from 'core';
import { isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { PndZoneUtils } from 'shared/zone-utils';
import { LayoutPreferenceService } from '../../../../../../../shared/layout-manager/services/layout-preference.service';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../../../store';
import { EventItem, UnassignedDeliveryIdentifier } from '../../../../interfaces/event-item.interface';
import { MapDataStyleItem, MapToolbarService } from '../../../../services';
import { MapMarkersService } from '../../../../services/map-markers.service';
import { GeoAreaDraw } from '../../classes/GeoAreaDraw';
import { GeoAreaDrawMode } from '../../classes/GeoAreaDrawMode';
import { GeoAreaColorService } from '../../services/geo-area-color.service';
import { GeoAreaDrawerService } from '../../services/geo-area-drawer.service';

const LIGHT_STROKE_COLOR = '#304FFE';

@Component({
  selector: 'pnd-geo-area-drawer-detail',
  templateUrl: './geo-area-drawer-detail.component.html',
  styleUrls: ['./geo-area-drawer-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoAreaDrawerDetailComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() geoAreaDraw: GeoAreaDraw;
  @Output() geoAreaSaved = new EventEmitter<{ geoArea: GeoAreaDraw; originalState: GeoAreaDraw; edited: boolean }>();
  @Output() geoAreaEdit = new EventEmitter<GeoAreaDraw>(null);
  @Output() geoAreaDisabled = new EventEmitter<void>();
  @Output() geoAreaDeleted = new EventEmitter<{ geoArea: GeoAreaDraw; cancel: boolean }>();
  @Output() geoAreaReady = new EventEmitter<GeoAreaDraw>();
  @Input() isCreating: boolean;
  @Input() showGeoAreaDetails: boolean;
  @Input() googleMap: google.maps.Map;

  readonly GeoAreaDrawMode = GeoAreaDrawMode;
  private currentGeoArea: google.maps.Polygon;
  markers: EventItem<UnassignedDeliveryIdentifier>[] = [];
  inDrawMode = false;

  sicZoneSatellite: string[] = [];
  sicControl = new UntypedFormControl(null, Validators.required);
  geoAreaName = new UntypedFormControl(null, Validators.pattern('[a-zA-Z0-9]*'));

  private disabledSubject = new BehaviorSubject<boolean>(false);
  readonly disabled$ = this.disabledSubject.asObservable();

  private polygonCompleteListener: mapTypes.MapsEventListener;
  private polygonDragend: mapTypes.MapsEventListener;
  private polygonDragstart: mapTypes.MapsEventListener;
  private polygonSetAt: mapTypes.MapsEventListener[] = [];
  private unsubscriber = new Unsubscriber();
  drawing: boolean;
  private strokeColor: string = LIGHT_STROKE_COLOR;
  private styleObj: MapDataStyleItem = {
    strokeColor: this.strokeColor,
    strokeWeight: 3,
    fillColor: this.strokeColor,
    fillOpacity: 0.4,
    clickable: false,
  };
  private editModeStateSubject = new BehaviorSubject<boolean>(false);
  readonly editModeState$ = this.editModeStateSubject.asObservable();
  private drawingManager: google.maps.drawing.DrawingManager;
  private geoAreaOriginal: GeoAreaDraw = null;

  geoAreaZIndex = MapMarkersService.layerMarkerZIndex[MapLayerType.GEO_OVERLAYS]();

  constructor(
    private mapToolbarService: MapToolbarService,
    private pndStore$: PndStore<PndStoreState.State>,
    private layoutPreferences: LayoutPreferenceService,
    private geoAreaColor: GeoAreaColorService,
    private markersService: MapMarkersService,
    private pndDialogService: PndDialogService,
    private geoAreaDrawerService: GeoAreaDrawerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeToGeoFilter();
    this.sicControl.setValue(this.geoAreaDraw?.sicCd);
  }

  private subscribeToGeoFilter(): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.selectGlobalFilterState)
      .pipe(
        takeUntil(this.unsubscriber.done$),
        filter((res) => !!res)
      )
      .subscribe((res: State) => {
        this.sicZoneSatellite = PndZoneUtils.getCurrentSelectionSics(res.sicZonesAndSatellites, true);
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  setSicZone(sicZone: string) {
    this.geoAreaDraw.sicCd = sicZone;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geoAreaDraw?.currentValue?.mode === GeoAreaDrawMode.CREATE) {
      this.togglePolygonDraw(true);
      this.editModeStateSubject.next(true);
    }
    if (
      !changes.showGeoAreaDetails?.firstChange &&
      changes.showGeoAreaDetails?.previousValue &&
      !changes.showGeoAreaDetails?.currentValue
    ) {
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }
      this.cancelEdit();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    if (this.googleMap) {
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'polygoncomplete');
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'dragend');
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'dragstart');
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'set_at');
      google.maps.event.removeListener(this.polygonCompleteListener);
      google.maps.event.removeListener(this.polygonDragend);
      google.maps.event.removeListener(this.polygonDragstart);
      this.clearPathChangeEvent();
      this.drawingManager?.setMap(null);
      this.geoAreaDraw?.mapPolygon?.setMap(null);
      this.geoAreaDraw?.areaLabelMaker?.setMap(null);
      this.currentGeoArea?.setMap(null);
      this.currentGeoArea = null;
      this.geoAreaDisabled.emit();
    }
  }

  ngAfterViewInit(): void {
    this.subscribeToGeoAreaDrawerState();

    this.subscribeToActiveLayout();

    this.mapToolbarService.updatePolygonLayerStyle(this.googleMap, this.styleObj, 'geoFilterLayer');

    this.drawCurrentGeoArea();
  }

  private subscribeToActiveLayout() {
    this.layoutPreferences.activeLayout$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((layout) => {
      this.inDrawMode = false;
      this.disabledSubject.next(false);
      ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    });
  }

  private subscribeToGeoAreaDrawerState() {
    this.mapToolbarService.isGeoAreasDrawerActive$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((active) => {
      if (!active && this.googleMap) {
        this.togglePolygonDraw(false);
        this.ngOnDestroy();
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      }
    });
  }
  trackValBy(index, satellite: string): string | null {
    if (!satellite) {
      return null;
    }
    return satellite + index;
  }

  editGeoArea(): void {
    this.geoAreaOriginal = { ...this.geoAreaDraw };
    this.editModeStateSubject.next(true);
    this.geoAreaDraw.mode = GeoAreaDrawMode.EDIT;
    this.geoAreaDraw.mapPolygon?.setOptions({
      draggable: true,
      editable: true,
      strokeWeight: 4,
    });
    this.googleMap.setCenter(this.geoAreaDraw.areaLabelMaker?.getPosition());
    this.geoAreaEdit.emit(this.geoAreaDraw);
  }

  deleteGeoArea(geoArea: GeoAreaDraw): void {
    this.pndDialogService
      .showConfirmCancelDialog(
        'Atention',
        `Are you sure you want to delete geo area ${geoArea.geoAreaName} ?`,
        '',
        'Yes',
        'No'
      )
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.geoAreaDeleted.emit({ geoArea, cancel: false });
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        }
      });
  }

  cancelEdit() {
    this.onPolygonDrawn();
    if (!this.geoAreaDraw.areaInstId) {
      this.geoAreaDeleted.emit({ geoArea: this.geoAreaDraw, cancel: true });
    } else {
      this.geoAreaDraw.mapPolygon?.setMap(null);
      this.geoAreaDraw.areaLabelMaker?.setMap(null);
      if (this.geoAreaOriginal) {
        this.geoAreaDraw = { ...this.geoAreaOriginal };
      }
      this.editModeStateSubject.next(false);
      this.geoAreaDraw.mode = GeoAreaDrawMode.VIEW;
      this.geoAreaDraw.mapPolygon?.setOptions({
        draggable: false,
        editable: false,
        strokeWeight: 2,
      });
      this.drawCurrentGeoArea();
      const areaCard = document.getElementById('areaCard-' + this.geoAreaDraw.areaInstId);
      if (areaCard) {
        areaCard.classList.remove('focus');
      }
      this.geoAreaEdit.emit(this.geoAreaDraw);
    }
  }

  saveEdit(): void {
    let edited = false;
    if (this.geoAreaDraw.mode === GeoAreaDrawMode.EDIT) {
      edited = true;
      if (this.geoAreaDraw.mapPolygon && this.geoAreaDraw.mapPolygon?.getEditable()) {
        this.geoAreaDraw.mapPolygon?.setEditable(false);
        this.geoAreaDraw.mapPolygon?.setDraggable(false);
      }
    }
    if (this.geoAreaDraw.mode === GeoAreaDrawMode.CREATE && this.currentGeoArea?.getEditable()) {
      this.currentGeoArea.setEditable(false);
      this.currentGeoArea.setDraggable(false);
      this.currentGeoArea = null;
    }
    if (this.geoAreaDraw.areaLabelMaker && this.geoAreaDraw.areaLabelMaker?.getMap()) {
      this.geoAreaDraw.areaLabelMaker?.setMap(null);
      this.geoAreaDraw.areaLabelMaker = null;
      this.geoAreaDraw.areaLabelMaker = Object.assign(
        this.setGeoAreaName(
          this.parsePolygon(this.geoAreaDraw),
          this.geoAreaDraw.geoAreaName,
          this.geoAreaColor.getColorForGeoArea(+this.geoAreaDraw.areaInstId || -1)
        )
      );
    }

    this.geoAreaDraw.geoAreaName = this.geoAreaDraw.geoAreaName.toUpperCase();
    this.geoAreaDraw.mode = GeoAreaDrawMode.VIEW;
    this.togglePolygonDraw(false);
    this.editModeStateSubject.next(false);
    const areaCard = document.getElementById('areaCard-' + this.geoAreaDraw.areaInstId);
    if (areaCard) {
      areaCard.classList.remove('focus');
    }
    this.geoAreaSaved.emit({ geoArea: this.geoAreaDraw, originalState: this.geoAreaOriginal, edited });
  }

  private drawCurrentGeoArea(): void {
    this.geoAreaDraw.mapPolygon?.setMap(null);
    this.geoAreaDraw.mapPolygon = null;
    this.geoAreaDraw.areaLabelMaker?.setMap(null);
    this.geoAreaDraw.areaLabelMaker = null;
    if (this.geoAreaDraw.mode === GeoAreaDrawMode.VIEW) {
      const color = this.geoAreaColor.getColorForGeoArea(+this.geoAreaDraw.areaInstId || -1);
      const latLngArray: google.maps.LatLngLiteral[] = this.parsePolygon(this.geoAreaDraw);
      const paths = latLngArray.map((coords) => {
        return new google.maps.LatLng({ lat: coords.lat, lng: coords.lng });
      });
      const kink = latLngArray.length > 300 ? latLngArray.length * 2 : latLngArray.length;
      const reducedDp = this.geoAreaDrawerService.reducerDP(paths, kink);
      this.geoAreaDraw.mapPolygon = new google.maps.Polygon({
        paths: reducedDp,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.4,
        zIndex: this.geoAreaZIndex,
        clickable: this.showGeoAreaDetails,
      });

      this.geoAreaDraw.mapPolygon?.setMap(this.googleMap);
      this.geoAreaDraw.geoAreaJson = JSON.stringify(MapUtils.polygonToGeoJson(this.geoAreaDraw.mapPolygon));

      google.maps.event.clearListeners(this.googleMap.getDiv(), 'click');
      this.geoAreaDraw.mapPolygon?.addListener('click', () => {
        this.editGeoArea();
        this.editModeStateSubject.next(true);
      });

      google.maps.event.clearListeners(this.googleMap.getDiv(), 'dragstart');
      google.maps.event.removeListener(this.polygonDragstart);
      this.polygonDragstart = this.geoAreaDraw.mapPolygon?.addListener('dragstart', () => this.clearPathChangeEvent());

      google.maps.event.clearListeners(this.googleMap.getDiv(), 'dragend');
      google.maps.event.removeListener(this.polygonDragend);
      this.polygonDragend = this.geoAreaDraw.mapPolygon?.addListener('dragend', () => {
        this.geoAreaReady.emit(this.geoAreaDraw);
        if (this.polygonSetAt.length === 0) {
          this.addPathChangeEvent(this.geoAreaDraw.mapPolygon);
        }
      });

      this.addPathChangeEvent(this.geoAreaDraw.mapPolygon);

      this.geoAreaDraw.areaLabelMaker = Object.assign(
        this.setGeoAreaName(latLngArray, this.geoAreaDraw.geoAreaName, color)
      );
      this.geoAreaDraw.areaLabelMaker?.setOptions({
        clickable: this.showGeoAreaDetails,
      });
    }
  }

  private addPathChangeEvent(polygon: google.maps.Polygon) {
    polygon
      .getPaths()
      .getArray()
      .forEach((path: google.maps.MVCArray<google.maps.LatLng>) => {
        this.polygonSetAt.push(
          google.maps.event.addListener(path, 'set_at', () => this.geoAreaReady.emit(this.geoAreaDraw))
        );
      });
  }

  private clearPathChangeEvent() {
    google.maps.event.clearListeners(this.googleMap.getDiv(), 'set_at');
    this.polygonSetAt.forEach((event) => {
      google.maps.event.removeListener(event);
    });
  }

  togglePolygonDraw(inDrawMode: boolean): void {
    this.inDrawMode = inDrawMode;
    if (inDrawMode) {
      this.disableMap();
      this.initPolygonSelection();
    } else {
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }
      google.maps.event.removeListener(this.polygonCompleteListener);
      google.maps.event.removeListener(this.polygonDragend);
      google.maps.event.removeListener(this.polygonDragstart);
      this.clearPathChangeEvent();
    }
  }

  private initPolygonSelection(): void {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    } else {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          draggable: true,
          strokeColor: this.geoAreaColor.getColorForGeoArea(+this.geoAreaDraw.areaInstId || -1),
          fillColor: this.geoAreaColor.getColorForGeoArea(+this.geoAreaDraw.areaInstId || -1),
          strokeWeight: 4,
          strokeOpacity: 0.8,
          fillOpacity: 0.4,
        },
      });
    }
    this.drawingManager.setMap(this.googleMap);
    this.polygonCompleteListener = google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygon: google.maps.Polygon) => {
        this.drawingManager.setMap(null);
        const parsedLatLonArray = polygon
          .getPath()
          .getArray()
          .map((point) => {
            return { latitude: point.lat(), longitude: point.lat() } as LatLong;
          });
        this.currentGeoArea = polygon;
        this.geoAreaDraw.polygon = parsedLatLonArray;
        this.geoAreaDraw.geoAreaJson = JSON.stringify(MapUtils.polygonToGeoJson(this.currentGeoArea));
        this.geoAreaDraw.mapPolygon = Object.assign(this.currentGeoArea);
        this.geoAreaDraw.mapPolygon?.addListener('click', () => {
          this.editGeoArea();
          this.editModeStateSubject.next(true);
        });
        this.geoAreaReady.emit(this.geoAreaDraw);
        this.onPolygonDrawn();
      }
    );
  }

  private setGeoAreaName(
    coordinates: { lat: number; lng: number }[] | google.maps.LatLng[],
    geoAreaName: string,
    color: string
  ): google.maps.Marker {
    const bounds = new google.maps.LatLngBounds();
    coordinates?.forEach((coords) => bounds.extend(coords));
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng()),
      icon: this.markersService.getGeoAreaIcon(geoAreaName, color, 16),
      optimized: true,
      visible: true,
    });
    google.maps.event.clearListeners(this.googleMap.getDiv(), 'click');
    marker.addListener('click', () => {
      this.editGeoArea();
      this.editModeStateSubject.next(true);
    });
    marker.setMap(this.googleMap);
    return marker;
  }

  private parsePolygon(
    geoArea: GeoAreaDraw
  ): {
    lat: number;
    lng: number;
  }[] {
    return geoArea.polygon?.map((point) => {
      return { lat: point.latitude, lng: point.longitude };
    });
  }

  private disableMap() {
    this.drawing = true;
    this.googleMap.setOptions({
      draggable: false,
    });
  }

  private enableMap() {
    this.drawing = false;
    this.googleMap.setOptions({
      draggable: true,
    });
  }

  private onPolygonDrawn(): void {
    this.togglePolygonDraw(false);
    this.enableMap();
  }

  isNullOrEmpty(value: string): boolean {
    return !value || /^ *$/.test(value) || _isEmpty(value.trim());
  }
}
