import { Injectable } from '@angular/core';
import { XpoFilterCriteria } from '@xpo-ltl/ngx-board/core';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchFilter,
  DeliveryShipmentSearchRecord,
  ListPnDUnassignedStopsResp,
  ListPnDUnassignedStopsRqst,
  Route,
  UnassignedStop,
  ListPnDRoutesByGeofenceRqst,
  ListPnDRoutesByGeofenceResp,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtSearchQueryHeader, LatLong } from '@xpo-ltl/sdk-common';
import {
  defaultTo as _defaultTo,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  reduce as _reduce,
  size as _size,
  isEqual as _isEqual,
} from 'lodash';
import { Observable, BehaviorSubject, of, Subscriber } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PndXrtService } from '../../../../core/services/pnd-xrt.service';
import { NumberToValueMap } from '../../../store/number-to-value-map';
import { PlanningRouteDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/planning-route-deliveries-search-criteria.interface';
import { consigneeToId, PlanningRouteShipmentIdentifier } from '../interfaces/event-item.interface';
import { Consignee } from './unassigned-deliveries-cache.service';

/**
 * Provides cache of large Data types instead of storing them in the Store
 */
@Injectable({
  providedIn: 'root',
})
export class PlanningRoutesCacheService {
  // list of all routes available
  private planningRoutes = new Map<number, Route>();

  // map of all Stops for a set of routeInstIds
  private stopsForSelectedPlanningRoutes = new Map<number, UnassignedStop[]>();

  private loadingStopsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingStops$ = this.loadingStopsSubject.asObservable();

  private filterCriteriaSubject = new BehaviorSubject<XpoFilterCriteria>({});
  readonly filterCriteria$ = this.filterCriteriaSubject.asObservable();

  constructor(private pndXrtService: PndXrtService, private cityOperationsService: CityOperationsApiService) {}

  /**
   * Clear existing routes and store the new set of routes
   * @param routes new set of Routes
   */
  setPlanningRoutes(routes: Route[]) {
    this.planningRoutes.clear();
    _forEach(routes, (route) => {
      this.planningRoutes.set(route.routeInstId, route);
    });
  }

  setFilterCriteria(criteria): void {
    const oldCriteria = this.filterCriteriaSubject.value;
    if (!_isEqual(oldCriteria, criteria)) {
      this.filterCriteriaSubject.next(criteria);
    }
  }

  /**
   * Return all Routes as an Array
   *
   */
  getAllPlanningRoutes(): Route[] {
    const routes = Array.from(this.planningRoutes.values());
    return routes;
  }

  getPlanningRouteIds(): number[] {
    return Array.from(this.planningRoutes.keys());
  }

  /**
   * Return the planningRoute with the requested routeInstId, or undefined
   * if not found
   */
  getPlanningRoute(routeInstId: number): Route {
    return this.planningRoutes.get(routeInstId);
  }

  /**
   * Returns the DOCK DROP route
   */
  getDockDropRoute(): Route {
    const allRoutes = this.getAllPlanningRoutes();
    return allRoutes.find(
      (route) => route.routePrefix.toUpperCase() === 'DOCK' && route.routeSuffix.toUpperCase() === 'DROP'
    );
  }

  /**
   * Set the mapping of RouteInstIds to the Stops for that route
   */
  setStopsForSelectedPlanningRoutes(stopsMap: NumberToValueMap<UnassignedStop[]>) {
    this.stopsForSelectedPlanningRoutes.clear();
    _forOwn(stopsMap, (stops, routeInstId) => {
      this.stopsForSelectedPlanningRoutes.set(+routeInstId, stops);
    });
  }

  /**
   * Return map of all Routes and their Stops
   */
  getStopsForSelectedPlanningRoutes(): Map<number, UnassignedStop[]> {
    return this.stopsForSelectedPlanningRoutes;
  }

  /**
   * Return the list of Stops for the specified Route.
   */
  getStopsForRoute(routeInstId: number): UnassignedStop[] {
    const stops = this.stopsForSelectedPlanningRoutes.get(routeInstId);
    return _defaultTo(stops, []);
  }

  /**
   * Find and return the DeliveryShipmentSearchRecord that matches the shipmentId passed in
   */
  getDeliveryShipmentSearchRecord(
    shipmentId: PlanningRouteShipmentIdentifier,
    mapApptMissingIndFromStop?: boolean
  ): DeliveryShipmentSearchRecord {
    const shipmentConsigneeId = consigneeToId(shipmentId);

    // find the route for the shipment
    const stops: UnassignedStop[] = this.getStopsForRoute(shipmentId.routeInstId);

    // find the shipment in the stop
    const selectedStop = _find(stops, (stop) => consigneeToId(stop) === shipmentConsigneeId);

    // find the shipment at the stop
    const shipment: DeliveryShipmentSearchRecord = _find(
      selectedStop?.deliveryShipments ?? [],
      (delShip: DeliveryShipmentSearchRecord) => delShip.shipmentInstId === shipmentId.shipmentInstId
    );

    if (!!mapApptMissingIndFromStop) {
      shipment.appointmentStatusCd = selectedStop.appointmentStatusCd;
    }

    return shipment;
  }

  /**
   * Search all Stops for a Route and return the first instance of the requested Shipment
   */
  getDeliveryShipmentSearchRecordInRoute(routeInstId: number, shipmentInstId: number): DeliveryShipmentSearchRecord {
    const stops: UnassignedStop[] = this.getStopsForRoute(routeInstId);
    const record = _reduce(
      stops,
      (shipment, stop) => {
        return (
          shipment ||
          _find(
            stop?.deliveryShipments ?? [],
            (delShip: DeliveryShipmentSearchRecord) => delShip.shipmentInstId === shipmentInstId
          )
        );
      },
      undefined
    );

    return record;
  }

  /**
   * Get from server all planning route shipments that match the search criteria
   * TODO - This makes no sense...why would we have unassigned stops in a route?
   */
  searchPlanningRouteShipments(
    criteria: PlanningRouteDeliveriesSearchCriteria
  ): Observable<ListPnDUnassignedStopsResp> {
    const header: XrtSearchQueryHeader = {
      fields: null,
      pageNumber: 1,
      pageSize: 10000,
      sortExpressions: [],
      excludeFields: [],
    };

    const listPnDUnassignedStopsRqst: ListPnDUnassignedStopsRqst = {
      planByTrailerInd: false,
      header: header,
      filter: {
        ...new DeliveryShipmentSearchFilter(),
        hostDestSicCd: this.pndXrtService.toXrtFilterEquals(criteria.hostDestSicCd),
        routeInstId: this.pndXrtService.toXrtFilterEquals(criteria.routeInstId),
        consignee_geoCoordinatesGeo: this.pndXrtService.toXrtFilterPoints(criteria.consigneeGeoCoordinatesGeo),
      },
      unmappedInd: false,
      pastDueShipmentsInd: false,
      unbilledInd: true,
    };

    return this.cityOperationsService.listPnDUnassignedStops(listPnDUnassignedStopsRqst).pipe(
      map((response: ListPnDUnassignedStopsResp) => {
        const stopsWithConsignee = response.unassignedStops.filter((stop) => stop.consignee);
        const stopsWithoutConsignee = response.unassignedStops.filter((stop) => !stop.consignee);

        const updatedStops = stopsWithoutConsignee.map((stop) => ({
          ...stop,
          stopId: 0,
          consignee: new Consignee(),
          deliveryShipments: stop.deliveryShipments,
        }));

        const finalUnassignedStops = [...stopsWithConsignee, ...updatedStops];

        return { ...response, unassignedStops: finalUnassignedStops };
      })
    );
  }

  /**
   *  Remove the planningRoute with the requested routeInstId
   */
  removePlanningRoute(routeInstId: number): boolean {
    return this.planningRoutes.delete(routeInstId);
  }

  /**
   *  Remove all the planningRoutes with the requested routeInstIds
   */
  removePlanningRoutes(routeInstIds: number[]): void {
    routeInstIds.forEach((routeInstId) => {
      this.removePlanningRoute(routeInstId);
    });
  }

  updateLoadingStops(isLoading: boolean): void {
    this.loadingStopsSubject.next(isLoading);
  }

  fetchPlanningRoutesByGeofence(latLngArray: LatLong[], sizZonesAndSatellites: string[]): Observable<number[]> {
    return new Observable((observer: Subscriber<number[]>) => {
      if (_size(latLngArray)) {
        const request: ListPnDRoutesByGeofenceRqst = {
          geofences: latLngArray,
          hostDestinationSicCds: sizZonesAndSatellites,
          planDate: undefined,
        };

        this.cityOperationsService
          .listPnDRoutesByGeofence(request)
          .pipe(catchError(() => of([])))
          .subscribe((response: ListPnDRoutesByGeofenceResp) => {
            const routesFound: number[] = response?.routeInstIds ?? [];
            const eligibleRouteIds: number[] = this.getPlanningRouteIds();
            const result: number[] = eligibleRouteIds.filter((routeId) => routesFound.includes(routeId));

            observer.next(result);
            observer.complete();
          });
      } else {
        observer.next([]);
        observer.complete();
      }
    });
  }
}
