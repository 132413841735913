<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
  <defs>
    <filter id="shadowSic" x="-16" y="-16" width="32" height="32">
      <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
      <feOffset dx="0" dy="0" />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
  <circle cx="20" cy="20" r="11.5" fill="white" stroke="black" stroke-width="1" filter="url(#shadowSic)" />
  <polygon
      [attr.transform]="transform"
      points="13,29 15,30.5 16,31 24,31 25,30.5 27,29 20,40"
      fill="black"
      stroke="black"
      stroke-width="0.1" />

  <g transform="translate(10,10.5)">
    <path
        fill="#CC0000"
        d="M4.8,9.8l2.4,3.6H4.8l-0.7-1.1c-0.2-0.4-0.3-0.6-0.5-1c-0.1,0.3-0.3,0.7-0.5,1l-0.6,1.1H0l2.5-3.6L0,6.6h2.4
          c0,0,1.1,1.5,1.3,1.9c0.1-0.3,1.1-1.9,1.1-1.9h2.5L4.8,9.8z" />
    <path
        fill="#CC0000"
        d="M10.1,6.6c1.1,0,1.6,0.1,2.1,0.4c0.6,0.4,1,1.1,1,2c0,0.7-0.2,1.2-0.7,1.7c-0.4,0.4-1,0.6-1.9,0.6H9.4v2.1H7.3
          V6.6H10.1z M9.4,9.7h0.8c0.6,0,0.9-0.2,0.9-0.7c0-0.5-0.3-0.7-0.9-0.7H9.4V9.7z" />
    <path
        fill="#CC0000"
        d="M20,10c0,2.1-1.4,3.5-3.4,3.5c-2.1,0-3.5-1.4-3.5-3.5c0-2.1,1.4-3.6,3.5-3.6C18.6,6.4,20,7.9,20,10 M15.3,10
          c0,1.2,0.4,1.9,1.3,1.9c0.8,0,1.3-0.7,1.3-2c0-1.1-0.4-1.8-1.3-1.8C15.7,8.1,15.3,8.8,15.3,10" />
  </g>
</svg>
