import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './trips-store.state';

export const tripsState = createFeatureSelector<State>(StateKey);

export const searchCriteria = createSelector(tripsState, (state: State) => state.criteria);

export const selectedTrips = createSelector(tripsState, (state: State) => state.selectedTrips);

export const tripsLastUpdate = createSelector(tripsState, (state: State) => state.lastUpdate);

export const tripsGridScrollPosition = createSelector(tripsState, (state: State) => state.gridScrollPosition);

export const selectedRoutes = createSelector(tripsState, (state: State) => state.selectedRoutes);
export const routeStopClicked = createSelector(tripsState, (state: State) => state.clickedRouteStop);

export const stopsForSelectedRoutes = createSelector(tripsState, (state: State) => state.stopsForSelectedRoutes);
export const selectedStopsForSelectedRoutes = createSelector(
  tripsState,
  (state: State) => state.selectedStopsForSelectedRoutes
);

export const focusedRoute = createSelector(tripsState, (state: State) => state.focusedRoute);
export const focusedStopForSelectedRoute = createSelector(
  tripsState,
  (state: State) => state.focusedStopForSelectedRoute
);

export const showCompletedStops = createSelector(tripsState, (state: State) => state.showCompletedStops);
