<div (click)="onClick($event)">
  <ng-container *ngIf="params.isIcon then useIcon else useValue"></ng-container>
</div>

<ng-template #useValue>
  {{ params.formatValue(params.getValue()) }}
</ng-template>

<ng-template #useIcon>
  <mat-icon>{{ params.isIcon }}</mat-icon>
</ng-template>
