import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pnd-metric-bar-skeleton-card',
  templateUrl: 'metric-bar-skeleton-card.component.html',
  styleUrls: ['metric-bar-skeleton-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-MetricBarSkeletonCard' },
})
export class MetricBarSkeletonCardComponent {}
