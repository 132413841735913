<div class="pnd-unmapped-stops__header">
  <h1 attr.data-test="unmappedDLCnt">
    {{ title$ | async }}
  </h1>
  <button mat-icon-button (click)="endEdit()" data-test="closeBtn">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="pnd-unmapped-stops__stops-container">
  <div class="pnd-unmapped-stops__stops-list">
    <div
      *ngFor="let stop of stopsToEdit$ | async; trackBy: trackStopBy"
      class="pnd-unmapped-stops__stop"
      data-test="unmappedStop-detail"
    >
      <pnd-unmapped-stop-detail
        [stopDetail]="stop"
        (reassignedToRoute)="stopDetailReassigned($event)"
        (edit)="beginEdit($event)"
      >
      </pnd-unmapped-stop-detail>
    </div>
  </div>
</div>
<mat-progress-spinner *ngIf="showSpinner$ | async" mode="indeterminate"></mat-progress-spinner>
