import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { reduce as _reduce } from 'lodash';

@Component({
  selector: 'pnd-accordion-group-cell-action-renderer',
  templateUrl: './accordion-group-cell-action-renderer.component.html',
  styleUrls: ['./accordion-group-cell-action-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PndAccordionGroupCellActionRendererComponent implements ICellRendererAngularComp {
  params: any;
  title: string;
  totalMotorMoves: number;
  totalBills: number;

  agInit(params: any): void {
    this.setDataValues(params);
  }

  refresh(params: any): boolean {
    this.setDataValues(params);
    return true;
  }

  onActionClick() {
    if (this.params.onClick) {
      this.params.onClick(this.params);
    }
  }

  private setDataValues(params: any): void {
    this.params = params;
    this.title = this.params.value;
    this.totalBills = this.params?.node?.allLeafChildren?.length || 0;
    this.totalMotorMoves = _reduce(
      this.params?.node?.allLeafChildren || [],
      (sum, child) => {
        return sum + (child?.data?.motorizedPiecesCount || 0);
      },
      0
    );
  }
}
