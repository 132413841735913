<div class="print-preference-dialog-component" *ngIf="{ isLoading: isLoading$ | async } as modalState">
  <xpo-responsive-loading-spinner *ngIf="modalState.isLoading"></xpo-responsive-loading-spinner>
  <h1 mat-dialog-title xmlns="http://www.w3.org/1999/html">
    Print Settings
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div mat-dialog-content class="dialog-content">
    <form [formGroup]="formGroup">
      <div class="print-preference-dialog-component__flex-display">
        <div class="print-preference-dialog-component__flex-display--form-options">
          <div>
            <div>
              <h3 class="ltl_heading_h5">P&D Manifests</h3>
            </div>
            <div class="common_input">
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Occurrence"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.PndManifestTriggerCd"
                [group]="formGroup"
                [items]="pndFbdsTriggerCdList"
                label="Occurrence"
              >
              </pnd-autocomplete>
              <p class="form-label">Printer</p>
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Printer"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.PndManifestPrinter"
                [group]="formGroup"
                [items]="activePrintersList$ | async"
              >
              </pnd-autocomplete>
              <p class="form-label">Tray</p>
              <pnd-autocomplete
                [ngClass]="{ disabled: this.pndPrinter === '' }"
                floatLabel="always"
                maxlength="15"
                placeholder="Select Tray"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.PndManifestTray"
                [group]="formGroup"
                [items]="activePndTrays$ | async"
              >
              </pnd-autocomplete>
            </div>
          </div>
          <div>
            <div>
              <h3 class="ltl_heading_h5">Outbound Manifests</h3>
            </div>

            <div class="common_input">
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Occurrence"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.ObBreakoutTriggerCd"
                [group]="formGroup"
                [items]="dsrOutboundTriggerCdList"
                label="Occurrence"
              >
              </pnd-autocomplete>
              <p class="form-label">Printer</p>
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Printer"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.ObBreakoutPrinter"
                [group]="formGroup"
                [items]="activePrintersList$ | async"
              >
              </pnd-autocomplete>
              <p class="form-label">Tray</p>
              <pnd-autocomplete
                [ngClass]="{ disabled: this.outboundPrinter === '' }"
                floatLabel="always"
                maxlength="15"
                placeholder="Select Tray"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.ObBreakoutTray"
                [group]="formGroup"
                [items]="activeOutboundTrays$ | async"
              >
              </pnd-autocomplete>
            </div>
          </div>
          <mat-checkbox
            class="print-preference-dialog-component__flex-display--outBound-reprint"
            labelPosition="after"
            [formControlName]="printPreferenceFormFields.ObBreakoutSlcInd"
          >
            Print only for Shipper Load & Count
          </mat-checkbox>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="print-preference-dialog-component__flex-display--form-options">
          <div>
            <div>
              <h3 class="ltl_heading_h5">DSR Check-In Report</h3>
            </div>
            <div class="common_input">
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Occurrence"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.DsrCheckinTriggerCd"
                [group]="formGroup"
                [items]="dsrOutboundTriggerCdList"
                label="Occurrence"
              >
              </pnd-autocomplete>
              <p class="form-label">Printer</p>
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Printer"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.DsrCheckinPrinter"
                [group]="formGroup"
                [items]="activePrintersList$ | async"
              >
              </pnd-autocomplete>
              <p class="form-label">Tray</p>
              <pnd-autocomplete
                [ngClass]="{ disabled: this.dsrPrinter === '' }"
                floatLabel="always"
                maxlength="15"
                placeholder="Select Tray"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.DsrCheckinTray"
                [group]="formGroup"
                [items]="activeDsrTrays$ | async"
              >
              </pnd-autocomplete>
            </div>
          </div>
          <div>
            <div>
              <h3 class="ltl_heading_h5">FBDS Auto-Print</h3>
            </div>

            <div class="common_input">
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Occurrence"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.FbdsTriggerCd"
                [group]="formGroup"
                [items]="pndFbdsTriggerCdList"
                label="Occurrence"
              >
              </pnd-autocomplete>
              <p class="form-label">Printer</p>
              <pnd-autocomplete
                floatLabel="always"
                maxlength="15"
                placeholder="Select Printer"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.FbdsPrinter"
                [group]="formGroup"
                [items]="activePrintersList$ | async"
              >
              </pnd-autocomplete>
              <p class="form-label">Tray</p>
              <pnd-autocomplete
                [ngClass]="{ disabled: this.fbdsPrinter === '' }"
                floatLabel="always"
                maxlength="15"
                placeholder="Select Tray"
                [sortItems]="sortOrder"
                [strictSelection]="strictSelection"
                [controlName]="printPreferenceFormFields.FbdsTray"
                [group]="formGroup"
                [items]="activeFbdsTrays$ | async"
              >
              </pnd-autocomplete>
            </div>
          </div>
          <mat-checkbox
            class="print-preference-dialog-component__flex-display--fbds-reprint"
            labelPosition="after"
            [formControlName]="printPreferenceFormFields.FbdsReprintInd"
          >
            Reprint already printed delivery receipts
          </mat-checkbox>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="print-preference-dialog-component__flex-display--language-options">
        <h3 class="ltl_heading_h5">Language</h3>
        <mat-radio-group [formControlName]="printPreferenceFormFields.FbdsLanguage">
          <mat-radio-button [value]="FbdsVersionCd.DEFAULT">{{ LanguageTypeEnum.ENGLISH_ONLY }}</mat-radio-button>
          <mat-radio-button [value]="FbdsVersionCd.BILINGUAL">{{
            LanguageTypeEnum.ENGLISH_FRENCH_BOTH
          }}</mat-radio-button>
          <mat-radio-button [value]="FbdsVersionCd.FRENCH">{{ LanguageTypeEnum.FRENCH_ONLY }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <xpo-button-group>
      <button mat-flat-button xpoButton (click)="save()" [disabled]="isSaveDisabled$ | async">Save</button>
      <button mat-stroked-button xpoButton (click)="cancel()">Cancel</button>
    </xpo-button-group>
  </div>
</div>
