import { DispatcherTripsSearchCriteria } from './dispatcher-trips-search-criteria.interface';

export const StateKey = 'dispatcherTripsState';

export interface WatchedDriver {
  driverId: string;
  tripInstId: number;
  initials: string;
  color: string;
  lastUpdate: Date;
  timerCounter: number;
  numberOfAdditions: number;
  filterHash?: string;
  isPulse?: boolean;
}
export interface State {
  readonly criteria: DispatcherTripsSearchCriteria;
  readonly lastUpdate: Date;
  readonly selectedTrips: number[];
  readonly watchedDrivers: WatchedDriver[];

  readonly gridScrollPosition: number;
}

export const initialState: State = {
  criteria: {},
  lastUpdate: new Date(0),
  selectedTrips: [],
  watchedDrivers: [],

  gridScrollPosition: 0,
};
