<div class="pnd-dispatchTripExceptionsCellRenderer" *ngIf="data$ | async as data">
  <button mat-icon-button (click)="onFlagClicked($event)" class="pnd-dispatchTripExceptionsCellRenderer__button">
    <mat-icon [xpoPopoverTriggerFor]="popover" class="pnd-dispatchTripExceptionsCellRenderer__icon">flag</mat-icon>
  </button>
  <xpo-popover
    #popover="xpoPopover"
    [position]="data.position"
    [color]="data.color"
    [caretPosition]="data.caretPosition"
    triggerOn="hover"
  >
    <xpo-popover-content>
      <div class="pnd-dispatchTripExceptionsCellRenderer__header">
        <div class="pnd-dispatchTripExceptionsCellRenderer__header-driver-name">
          {{ data.driverName }}
        </div>
        <div class="pnd-dispatchTripExceptionsCellRenderer__header-trip-start-time">
          Trip start time: {{ data.formattedTripStartTime }}
        </div>
        <hr class="pnd-dispatchTripExceptionsCellRenderer__hr" />
      </div>

      <div
        *ngFor="let alert of data.alerts; trackBy: trackAlertBy"
        class="pnd-dispatchTripExceptionsCellRenderer__alert"
      >
        <xpo-status-indicator
          statusText="Alert"
          class="pnd-dispatchTripExceptionsCellRenderer__alert-label"
          [statusColor]="XpoColors.XPO_RED_200"
          isLabel="true"
        ></xpo-status-indicator>
        <div class="pnd-dispatchTripExceptionsCellRenderer__alert-wrapper">
          <div class="pnd-dispatchTripExceptionsCellRenderer__alert-main">
            {{ alert.flagHoverMainText }}
          </div>
          <div class="pnd-dispatchTripExceptionsCellRenderer__alert-line-1">
            {{ alert.flagHoverSecondaryTextLine1 }}
          </div>
          <div class="pnd-dispatchTripExceptionsCellRenderer__alert-line-2">
            {{ alert.flagHoverSecondaryTextLine2 }}
          </div>
        </div>
        <hr class="pnd-dispatchTripExceptionsCellRenderer__hr" />
      </div>
    </xpo-popover-content>
  </xpo-popover>
</div>
