import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { PriorityCd } from '../../../../../shared/enums/priority.enum';

@Component({
  selector: 'pnd-pickup-close-soon-cell-renderer',
  templateUrl: './pickup-close-soon-cell-renderer.component.html',
  styleUrls: ['./pickup-close-soon-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupCloseSoonCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  readonly PriorityCd = PriorityCd;
  readonly lowPriorityTooltip = 'Small PU Window';
  readonly mediumPriorityTooltip = 'Close Time in <1 Hr';
  readonly highPriorityTooltip = 'Passed Close Time';

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
