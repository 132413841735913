<div *ngIf="stopDetail" class="pnd-UnmappedStopDetail-container">
  <div *ngIf="isSaving$ | async" class="pnd-UnmappedStopDetail-saving">
    <mat-progress-spinner [diameter]="50" [mode]="'indeterminate'"></mat-progress-spinner>
    <span>Saving</span>
  </div>
  <div
      (click)="beginEdit()"
      (keyup.enter)="beginEdit()"
      class="pnd-UnmappedStopDetail-inner-container"
      [ngClass]="{
      active: active$ | async,
      opaque: (disabled$ | async) || (isSaving$ | async)
    }">
    <div class="pnd-UnmappedStopDetail__name-and-type">
      <div>
        <div class="pnd-UnmappedStopDetail__name-and-type-svg" [innerHTML]="stopSvg"></div>
        <span class="pnd-UnmappedStopDetail__name-and-type-consignee">{{ stopDetail?.stopName }}</span>
      </div>
      <div>
        <mat-menu #unmappedMenu="matMenu" [ngSwitch]="stopDetail?.stopTypeCd">
          <ng-container *ngSwitchCase="TripNodeActivityCd.DELIVER_SHIPMENT">
            <button mat-menu-item (click)="showShipmentDetails()">Shipment Details</button>
            <div
                class="pnd-UnmappedStopDetail__name-and-type-tooltip-wrapper"
                [matTooltip]="getTooltip()"
                matTooltipClass="assign-button-tooltip">
              <button
                  id="assign_button"
                  [disabled]="isRouteBalancingActive$ | async"
                  mat-menu-item
                  (click)="assignDelivery()">
                Assign
              </button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="TripNodeActivityCd.PICKUP_SHIPMENTS">
            <button mat-menu-item (click)="assignPickup()">Assign</button>
          </ng-container>
        </mat-menu>
        <button
            mat-icon-button
            [matMenuTriggerFor]="unmappedMenu"
            (click)="$event.stopPropagation()"
            class="pnd-UnmappedStopDetail__name-and-type-options">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </div>
    </div>
    <div class="pnd-UnmappedStopDetail__original-address">{{ originalAddress }}</div>
    <div class="pnd-UnmappedStopDetail__address-input" (click)="$event.stopPropagation()">
      <mat-form-field floatLabel="always" hideRequiredMarker>
        <input
            matInput
            placeholder="Search (by address or name, city)"
            [disabled]="disabled$ | async"
            [(ngModel)]="stopDetail.address"
            required />
      </mat-form-field>
      <button mat-icon-button [disabled]="disabled$ | async" (click)="beginEdit(true)">
        <mat-icon
            [ngClass]="{ 'pnd-UnmappedStopDetail-inner-container-opaque': isSearching$ | async }"
            matTooltip="Find">
          search
        </mat-icon>
        <span *ngIf="isSearching$ | async" class="pnd-UnmappedStopDetail__address-input__spinner"></span>
      </button>
    </div>
    <div *ngIf="hasErrors$ | async" class="pnd-UnmappedStopDetail__address-errors">
      {{ (status$ | async)?.message }}
    </div>
  </div>
</div>
