<div class="pnd-map-zoom">
  <pnd-zoom-in-button matTooltipPosition="left" matTooltip="Zoom In" (click)="onZoomIn($event)"></pnd-zoom-in-button>
  <pnd-zoom-out-button
      matTooltipPosition="left"
      matTooltip="Zoom Out"
      (click)="onZoomOut($event)"></pnd-zoom-out-button>
  <pnd-selective-zoom
      matTooltipPosition="left"
      matTooltip="Click and draw a square on the map to selectively zoom into an area"
      (toggleDrawMode)="onPolygonDrawToggle($event)">
  </pnd-selective-zoom>
</div>
