export enum DockRoutesGridHeaders {
  ROW_SELECTED = '',
  MAIN_GROUP_VALUE = '',
  PRO_CALL_NBR = 'Pro/Call #',
  ACTIVITY_CD = 'Activity',
  CUSTOMER_NAME = 'Customer Name',
  ADDRESS = 'Address',
  CONSIGNEE_CITY = 'City',
  BILL_COUNT = 'Bills',
  FBDS_PRINT_COUNT_CD = 'Print Count',
  PIECES_COUNT = 'LP',
  PALLET_COUNT = 'Pallets',
  WEIGHT_LBS = 'Weight',
  CUBE_NBR = 'Cube',
  MOTOR_MOVE_NBR = 'MM',
  SPECIAL_SERVICES = 'Special Services',
  CALL_NBR = 'Call #',
  PRO = 'PRO',
  ORIGIN_SIC = 'Origin SIC',
  DEST_SIC = 'Dest. SIC',
  DEST_ZIP = 'Dest. ZIP',
}
