<div *ngIf="!(isHidden$ | async)" class="pnd-DispatchAreaSelector" data-test="pnd-DispatchAreaSelector">
  <mat-form-field class="ngx-FormField--inline">
    <mat-select
      [(value)]="selectedOptions"
      [placeholder]="placeholder"
      (selectionChange)="handleSelectionChange()"
      panelClass="pnd-DispatchAreaSelector__panel"
      data-test="pnd-DispatchAreaSelector-select"
      multiple
    >
      <mat-select-trigger>
        <span>{{ getSelectedOptionsDisplay() }}</span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let option of options; trackBy: trackOptionsBy; let i = index"
        [value]="option"
        class="dispatch-option"
        attr.data-test="pnd-DispatchAreaSelector-option-{{ i }}"
      >
        <div class="pnd-DispatchAreaSelector__option">
          <xpo-select-two-lines-container>
            <span>{{ option.groupName }}</span>
            <span>{{ option.groupDescription }}</span>
          </xpo-select-two-lines-container>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
