<h1 mat-dialog-title data-test="confirm-profile-title" class="dialog-header">
  <p class="error-message"><xpo-icon iconName="error" class="error-icon"></xpo-icon>{{ title }}</p>
</h1>

<div mat-dialog-content class="content-padding">
  <h1>
    <p class="message">
      Unassigned Deliveries and Cross Dock Trailers should use the<br />same Inbound Planning Profile.
      <br />
      <br />
      <b>Choose a profile to apply to both.</b>
    </p>
  </h1>
  <p class="form-label">Profile</p>
  <mat-form-field class="form-field" appearance="standard" floatLabel="always">
    <mat-select placeholder="Select Profile" xpoSelect (selectionChange)="setPlanningProfile($event.value)">
      <mat-option *ngFor="let profile of data.profiles; trackBy: trackProfileBy" [value]="profile">
        {{ profile.profileName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <div class="pnd-ConfirmCancel-actionButtons--left">
    <ng-container>
      <button
        [disabled]="!selectedProfile"
        data-test="confirm-cancel-confirmBtn"
        type="button"
        mat-flat-button
        (click)="confirmAction()"
      >
        CONFIRM
      </button>
    </ng-container>

    <ng-container>
      <button data-test="confirm-cancel-cancelBtn" type="button" mat-stroked-button (click)="cancelAction()">
        CANCEL
      </button>
    </ng-container>
  </div>
</div>
