import { ViewChild, Directive, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { XpoInlineEditingError, XpoGridCellEditorParams } from '../models/inline-editing-error.model';
import { XPO_GRID_INTERNAL_CUSTOM_EVENTS } from '../shared/enums/custom-events.enum';
import { ErrorDescriptions, ErrorTypes } from './../models/inline-editing-error.model';
import {
  XpoGridInternalCleanCellErrorEvent,
  XpoGridInternalEditedErrorValueEvent,
} from './models/editor-cell-events.model';
import { ValidationKeys } from './models/validations-keys.models';

@Directive()
export abstract class XpoGridBaseCellEditor implements ICellEditorAngularComp {
  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
  params: XpoGridCellEditorParams;

  value: any;
  allowEmptyValues: boolean;

  patternError: boolean = false;
  emptyValueError: boolean = false;
  customValidationErrorMessage: string = null;
  errorType: string;
  inputPattern: string = '.*';

  afterGuiAttached(): void {
    // The following setTimeout is necessary to handle the select after the content is rendered on
    // the input. The approach was taken after several tries with other ways, like lifecycles.
    // Many projects within XPO and even Material resolves this problem in the same way.
    setTimeout(() => {
      this.input.nativeElement.select();
    });
  }

  agInit(params: XpoGridCellEditorParams): void {
    this.params = params;
    this.allowEmptyValues =
      params.colDef.cellRendererParams?.allowEmptyValues === false
        ? params.colDef.cellRendererParams.allowEmptyValues
        : true;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value;
  }

  /**
   * check the evaluate Key method and prevents a value to be written
   * must be applied on the keyDown event
   * @param event
   * @returns
   */
  onKeyDown(event): void {
    if (this.evaluateKey(event)) {
      return;
    }
    event.preventDefault();
  }

  /**
   * checks for error in pattern, empty field, custom validations
   * and finally emits the errors to the inline editing state in case they might exist
   */
  onKeyUp(): void {
    this.errorType = null;
    this.evaluatePattern();
    this.evaluateEmptyValue();
    this.evaluateCustomValidations();
    this.evaluateErrorConditions();
  }

  onFocusIn(): void {
    this.input.nativeElement.select();
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  /**
   * return true if the key valid false if is not
   * and the component will prevent it to be written
   */
  abstract evaluateKey(event): boolean;
  /**
   * compare the current value against the defined pattern form the editor
   */
  evaluatePattern(): void {
    const regex = new RegExp(this.inputPattern);
    if (!regex.test(String(this.value))) {
      this.errorType = ErrorTypes.ErrorPattern;
    }
  }

  /**
   * check if the input can and is empty
   * @returns
   */
  evaluateEmptyValue(): void {
    if (this.allowEmptyValues) {
      return;
    }

    if (this.value === '') {
      this.errorType = ErrorTypes.EmptyValue;
      return;
    }
  }

  /**
   * takes the custom validations from the params and evaluates each one of them against the current value
   * @returns
   */
  evaluateCustomValidations(): void {
    if (!this.params.customValidations) {
      return;
    }
    for (let index = 0; index < this.params.customValidations.length; index++) {
      const validation = this.params.customValidations[index];
      if (validation.condition(this.value)) {
        this.customValidationErrorMessage = validation.errorMessage;
        return;
      }
    }
    this.customValidationErrorMessage = null;
  }

  /**
   * clean any error from the cell, then evaluates all error conditions with the new value pressed by the user
   * and emits an error to the inline editing state if one is found
   */
  evaluateErrorConditions(): void {
    this.cleanCellError();
    const keyField = this.params.keyField;
    const error: XpoInlineEditingError = {
      columnId: this.params.column.getColId(),
      keyField,
      id: this.params.data[keyField],
      value: this.value,
      errorDescription: this.customValidationErrorMessage || ErrorDescriptions[this.errorType],
    };

    const errorValueEvent: XpoGridInternalEditedErrorValueEvent = {
      type: XPO_GRID_INTERNAL_CUSTOM_EVENTS.inlineEditing.onErrorValue,
      error,
    };
    this.params.api.dispatchEvent(errorValueEvent);
  }

  /**
   * checks if the key pressed is valid for generic inputs
   * @param key
   * @returns
   */
  isValidKey(key): boolean {
    return Object.values(ValidationKeys).some((validKey) => validKey === key);
  }

  /**
   * emits an event to let know the inline editing state
   * that must clear all errors from this cell
   */
  private cleanCellError(): void {
    const event: XpoGridInternalCleanCellErrorEvent = {
      type: XPO_GRID_INTERNAL_CUSTOM_EVENTS.inlineEditing.cleanCellError,
      columnId: this.params.column.getColId(),
      id: this.params.data[this.params.keyField],
    };
    this.params.api.dispatchEvent(event);
  }

  /**
   * check is the input text is selected, if so return true
   * @returns
   */
  protected isTextSelected(): boolean {
    // by the time the input is focused it will automatically
    // select all the text input de-selecting any other previous text
    // making this a safe validation by the moment its executed
    const doc = document.getSelection();
    return doc.rangeCount > 0;
  }
}
