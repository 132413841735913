import { DriverMapMarkerInfo } from './../driver-map-marker-info.model';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class DriverMapMarker extends MapMarkerWithInfoWindow<DriverMapMarkerInfo> {
  initials: string;
  highlighted?: boolean;
  isWatched?: boolean;
  color?: string;
  directionAngle?: number;
}
