import { ConditioningService } from '@xpo-ltl/common-services';
import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd, GeoCoordinates, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { map as _map } from 'lodash';
import { MapUtils } from '../../classes/map-utils';
import { SpecialServicesHelper } from '../../helpers/special-services/special-services.helper';
import { SpecialServicesService } from '../../services';
import { DeliveryWindowService } from '../../services/delivery-window.service';
import { EtaWindowService } from '../../services/eta-window.service';
import { MapMarkersService } from '../../services/map-markers.service';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo } from '../map-marker-info.model';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class UnassignedDeliveryShipmentsMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> {
  readonly rowData: UnassignedStop;

  get mappable(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  constructor(
    unassignedStop: UnassignedStop,
    private mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    color: string,
    private etaWindowService: EtaWindowService,
    private deliveryWindowService: DeliveryWindowService,
    private specialServicesService: SpecialServicesService,
    private conditioningService: ConditioningService
  ) {
    super();

    this.rowData = unassignedStop;
    this.specialServices = _map(unassignedStop.specialServiceSummary, (summary) => summary.specialService);

    const geoCoordinates: GeoCoordinates = MapUtils.getCoordinates(unassignedStop.consignee);

    this.latitude = geoCoordinates.lat;
    this.longitude = geoCoordinates.lon;

    let routeName: string;
    if (this.rowData.deliveryShipments && this.rowData.deliveryShipments.length > 0) {
      routeName = `${this.rowData.deliveryShipments[0].routePrefix}-${this.rowData.deliveryShipments[0].routeSuffix}`;
    }

    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      this.etaWindowService,
      this.deliveryWindowService,
      this.conditioningService,
      {
        ...unassignedStop,
        color,
      },
      undefined,
      undefined,
      undefined,
      routeName
    );

    const specialServices: ShipmentSpecialServiceCd[] = SpecialServicesHelper.getSpecialServicesForSummary(
      unassignedStop.specialServiceSummary
    );

    this.markerInfo.specialServiceMarks = this.specialServicesService.getSpecialServiceAppointmentForMapMarkers(
      unassignedStop.appointmentStatusCd
    );

    this.markerInfo.specialServices = specialServices;

    this.specialServicesService.addPendingMarksToSpecialServices(
      this.markerInfo.specialServices,
      this.markerInfo.specialServiceMarks
    );
  }

  async createIcon(): Promise<UnassignedDeliveryShipmentsMapMarker> {
    this.icon = await this.mapMarkerService.getMarkerIconUnassigned(
      TripNodeActivityCd.DELIVER_SHIPMENT,
      10,
      false,
      this.markerInfo.color,
      undefined,
      undefined,
      this.specialServices,
      this.markerInfo?.specialServiceMarks
    );

    return this;
  }
}
