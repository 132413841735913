import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import { some as _some } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, concatMapTo, switchMap, take, withLatestFrom, tap } from 'rxjs/operators';
import { UnassignedPickupsSummary } from '../../inbound-planning/components/unassigned-pickups/models/unassigned-pickup-summary';
import { UnassignedPickupIdentifier, EventItem } from '../../inbound-planning/shared/interfaces';
import { UnassignedPickupsService } from '../../inbound-planning/shared/services/unassigned-pickups.service';
import * as PndStoreState from '../pnd-store.state';
import {
  ActionTypes,
  Refresh,
  SetFocusedUnassignedPickup,
  SetLastUpdate,
  SetSearchCriteria,
  SetSelectedUnassignedPickups,
} from './unassigned-pickups-store.actions';
import * as UnassignedPickupsStoreSelectors from './unassigned-pickups-store.selectors';

@Injectable()
export class UnassignedPickupsStoreEffects {
  constructor(
    private actions$: Actions,
    private unassignedPickupsService: UnassignedPickupsService,
    private store$: PndStore<PndStoreState.State>
  ) {}

  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new Refresh()])
  );

  // refresh the pickups based on current searchCriteria
  @Effect()
  refresh$: Observable<Action> = this.actions$.pipe(
    ofType<Refresh>(ActionTypes.refresh),
    tap(() => this.unassignedPickupsService.setLoadingUnassignedPickups(true)),
    concatMap(() => this.store$.select(UnassignedPickupsStoreSelectors.searchCriteria).pipe(take(1))),

    switchMap((criteria) =>
      // Main operation: Fetch unassigned pickups.
      this.unassignedPickupsService.searchUnassignedPickups(criteria).pipe(
        // Side operation: Fetch past due pickups in parallel, but do not wait for it.
        tap(() => this.unassignedPickupsService.searchPastDuePickups(criteria).subscribe()),
        catchError(() => {
          this.unassignedPickupsService.setLoadingUnassignedPickups(false);
          return of(undefined);
        }),
        withLatestFrom(this.store$.select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected)),
        switchMap(
          ([unassignedPickups, currentSelection]: [
            UnassignedPickupsSummary[],
            EventItem<UnassignedPickupIdentifier>[]
          ]) => {
            // remove from current selection any pickups that no longer exist
            let filteredSelectedPickups = [];

            const pickupsInstId: number[] = unassignedPickups.map(
              (pickup) => pickup?.pickupHeader?.pickupRequestInstId
            );
            filteredSelectedPickups = currentSelection.filter((pickup) =>
              _some(pickupsInstId, (pickupAcctInstId) => pickupAcctInstId === pickup?.id?.pickupInstId)
            );

            this.unassignedPickupsService.setLoadingUnassignedPickups(false);

            return [
              new SetLastUpdate({ lastUpdate: new Date() }),
              new SetFocusedUnassignedPickup({ focusedPickup: undefined }),
              new SetSelectedUnassignedPickups({ selectedPickups: filteredSelectedPickups }),
            ];
          }
        ),

        catchError(() => {
          this.unassignedPickupsService.setLoadingUnassignedPickups(false);
          return of(undefined);
        })
      )
    )
  );
}
