import { AfterViewChecked, ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { EditorKeys } from '../../classes/editor-keys';

@Component({
  selector: 'xpo-text-cell-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--text',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements ICellEditorAngularComp, AfterViewChecked {
  @ViewChild('input', { static: true }) input;
  params: any;
  value: string;
  maxLength: number;
  regExp: string | RegExp;

  ngAfterViewChecked(): void {
    this.input.nativeElement.focus();
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxLength = this.params.maxLength;
    this.regExp = this.params.regExp;
  }

  getValue(): string {
    return this.value;
  }

  isCancelAfterEnd(): boolean {
    return this.value && this.value.toString().length > this.maxLength;
  }

  onFocusIn(): void {
    this.input.nativeElement.select();
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (!this.isEventKeyValid(event.key) && this.regExp && !this.isKeyValid(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  isKeyValid(key: string): boolean {
    return RegExp(this.regExp).test(key);
  }

  private isEventKeyValid(key: string): boolean {
    return key === EditorKeys.BACKSPACE || key === EditorKeys.DELETE || key === EditorKeys.TAB;
  }
}
