import { ModifyTripActivityId } from '@pnd-store/modify-trip-details-store/modify-trip-details.state';
import { ShipmentSpecialServiceCd, ShipmentSpecialServiceSummary, TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { RowNode } from 'ag-grid-community';
import { TripNodeActivityExtendedCd } from 'app/inbound-planning/shared/models/stop-type.model';
import {
  differenceWith as _differenceWith,
  filter as _filter,
  isUndefined as _isUndefined,
  pick as _pick,
  uniq as _uniq,
  uniqWith as _uniqWith,
  without as _without,
} from 'lodash';
import { EntityTypeCd } from '../enums/entity-type-cd.enum';
import {
  ModifyTripDetailsShipmentGridItem,
  ModifyTripDetailsStopGridItem,
} from '../models/modify-trip-details-grid-item.model';

/**
 * Set of utility classes for the Trip Details panel
 */
export class ModifyTripDetailsUtils {
  /**
   * Return a ModifyTripActivityId for the passed gridItem
   */
  static getModifyTripActivityIdFromItem(
    item: ModifyTripDetailsStopGridItem | ModifyTripDetailsShipmentGridItem
  ): ModifyTripActivityId {
    const id = _pick(item, [
      'uniqueId',
      'tripInstId',
      'routeInstId',
      'tripNodeSequenceNbr',
      'tripNodeActivitySequenceNbr',
      'activityCd',
      'shipmentInstId',
      'proNbr',
      'pickupRequestInstId',
      'tripNodeStatusCd',
      'callNbr',
      'sicCd',
      'customer_name',
      'customer_acct_instId',
      'customer_address',
      'customer_city',
      'customer_state',
    ]) as ModifyTripActivityId;
    return id;
  }

  /**
   * Return the ActivityId for the valid items in passed list
   */
  static getActivityIdsFromActivityItems(
    activityItems: (ModifyTripDetailsStopGridItem | ModifyTripDetailsShipmentGridItem)[]
  ): ModifyTripActivityId[] {
    return activityItems
      .filter((item) => !!item)
      .map((item: ModifyTripDetailsShipmentGridItem | ModifyTripDetailsStopGridItem) =>
        ModifyTripDetailsUtils.getModifyTripActivityIdFromItem(item)
      );
  }

  /**
   * Get the new activities selection, based on the selection
   * changes from the grid and the current selection from the store.
   *
   * @param currentSelection RowNode[] with data of type ModifyTripDetailsActivityGridItem
   * @param prevSelection  RowNode[] with data of type ModifyTripDetailsActivityGridItem
   * @param currentStoreSelection
   * @param tripInstId
   */
  static getNewSelectionFromSelectionChanges(
    currentSelection: RowNode[],
    prevSelection: RowNode[],
    currentStoreSelection: ModifyTripActivityId[]
  ): ModifyTripActivityId[] {
    const selectedActivityNodes = _without(currentSelection, ...prevSelection);
    const deselectedActivityNodes = _without(prevSelection, ...currentSelection);

    const newlySelectedActivityIds: ModifyTripActivityId[] = ModifyTripDetailsUtils.getActivityIdsFromActivityItems(
      selectedActivityNodes.map((node) => node.data as ModifyTripDetailsShipmentGridItem)
    );
    const newlyDeselectedActivityIds: ModifyTripActivityId[] = ModifyTripDetailsUtils.getActivityIdsFromActivityItems(
      deselectedActivityNodes.map((node) => node.data as ModifyTripDetailsShipmentGridItem)
    );

    return ModifyTripDetailsUtils.getNewSelectionState(
      newlySelectedActivityIds,
      newlyDeselectedActivityIds,
      currentStoreSelection
    );
  }

  static getNewSelectionState(
    newlySelectedActivityIds: ModifyTripActivityId[],
    newlyDeselectedActivityIds: ModifyTripActivityId[],
    currentStoreSelection: ModifyTripActivityId[]
  ): ModifyTripActivityId[] {
    const activityEquals = (activityIdA: ModifyTripActivityId, acitvityIdB: ModifyTripActivityId) => {
      return (
        activityIdA.tripNodeSequenceNbr === acitvityIdB.tripNodeSequenceNbr &&
        activityIdA.tripNodeActivitySequenceNbr === acitvityIdB.tripNodeActivitySequenceNbr
      );
    };

    let selectedActivities: ModifyTripActivityId[];

    // Remove deselected
    selectedActivities = _differenceWith(currentStoreSelection, newlyDeselectedActivityIds, activityEquals);

    // Add selected
    selectedActivities.push(...newlySelectedActivityIds);

    return _uniqWith(selectedActivities, activityEquals);
  }

  static getEntityTypeFromActivityType(activityType: TripNodeActivityExtendedCd): EntityTypeCd {
    switch (activityType) {
      case TripNodeActivityCd.DELIVER_SHIPMENT:
      case TripNodeActivityCd.SPOT_LOADED:
        return EntityTypeCd.Shipment;
      case TripNodeActivityCd.PICKUP_SHIPMENTS:
      case TripNodeActivityCd.HOOK_LOADED:
      case TripNodeActivityCd.SPOT_EMPTY:
      case TripNodeActivityCd.HOOK_EMPTY:
        return EntityTypeCd.Pickup;
      case TripNodeActivityCd.HOOK_EQUIPMENT:
      case TripNodeActivityCd.DROP_EQUIPMENT:
        return EntityTypeCd.Equipment;
      default:
        return undefined;
    }
  }

  /**
   * Return true if the passed Stop GridItem belongs ot a virtual Mixed stop
   */
  static isChildOfMixedStop(item: ModifyTripDetailsStopGridItem): boolean {
    return item && !_isUndefined(item.mixedStopIndex);
  }

  /**
   * Return list of all uniq the SpecialServiceCds in the passed list of special service summaries
   */
  static aggregateSpecialServiceCds(specialServices: ShipmentSpecialServiceSummary[]): ShipmentSpecialServiceCd[] {
    const specialServicesCds = _uniq(_filter(specialServices, (ss) => ss.applicableInd).map((ss) => ss.specialService));
    return specialServicesCds;
  }
}
