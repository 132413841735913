import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { timer, BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export interface GridDetailNotesTriggerParams extends ICellRendererParams {
  noteExpandedByDefault?: (node: RowNode) => boolean;
  getDetailHeight?: (node: RowNode) => number;
  getNotesCount?: (node: RowNode) => number;
  onCellClicked?: (node: RowNode) => void;
}

@Component({
  selector: 'pnd-grid-detail-notes-trigger',
  templateUrl: './grid-detail-notes-trigger.component.html',
  styleUrls: ['./grid-detail-notes-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-GridDetailNotesTriggerComponent' },
})
export class GridDetailNotesTriggerComponent implements ICellRendererAngularComp {
  params: GridDetailNotesTriggerParams;

  private readonly notesCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly notesCount$: Observable<number> = this.notesCountSubject.asObservable().pipe(distinctUntilChanged());

  get notesCount(): number {
    return this.notesCountSubject.value;
  }

  agInit(params: GridDetailNotesTriggerParams): void {
    this.params = params;
    const node: RowNode = params.node;
    if (params.noteExpandedByDefault) {
      if (params.getDetailHeight) {
        node.detailNode.setRowHeight(params.getDetailHeight(node));
        this.params.api.onRowHeightChanged();
      }
    }
    this.setNotesCount();
  }

  refresh(params: GridDetailNotesTriggerParams): boolean {
    const prevNotesCount: number = this.notesCount;
    this.params = params;
    this.setNotesCount();

    const shouldCollapseDetailGrid: boolean = !!prevNotesCount && !this.notesCount && params.node?.expanded;

    if (shouldCollapseDetailGrid) {
      params.node.setExpanded(false);
    }

    return true;
  }

  onClick(event: MouseEvent) {
    const node: RowNode = this.params.node;

    if (node.data.proNbr != null) {
      node.setExpanded(!node.expanded);
    } else {
      node.setExpanded(!node.expanded);

      // need to add slight delay to allow for detail grid to expand
      timer(10).subscribe(() => {
        const detailNodeRows = node.detailNode.detailGridInfo?.columnApi['columnModel']?.rowModel?.rowsToDisplay;

        if (detailNodeRows) {
          for (const row of detailNodeRows) {
            if (row.data?.stopWindow[0].notes != null) {
              row.setExpanded(!row.expanded);
            }
          }
        }
      });
    }

    if (this.params.getDetailHeight) {
      // need to add slight delay to allow for detail grid to expand
      timer(10).subscribe(() => {
        node.detailNode?.setRowHeight(this.params.getDetailHeight(node));
        this.params.api.onRowHeightChanged();
      });
    }

    if (this.params.onCellClicked) {
      this.params.onCellClicked(node);
    }
    event.preventDefault();
    event.stopPropagation();
  }

  setNotesCount(): void {
    const notesCount = this.params?.getNotesCount ? this.params.getNotesCount(this.params?.node) : 0;
    this.notesCountSubject.next(notesCount);
  }
}
