import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ApplyChangesDialogData, ApplyChangesMode } from './apply-changes-dialog-data';

export enum ApplyChangesModalEnum {
  DO_NOT_SAVE = 'DO_NOT_SAVE',
  CANCEL = 'CANCEL',
}

@Component({
  selector: 'pnd-apply-changes-modal',
  templateUrl: './apply-changes-modal.component.html',
  styleUrls: ['./apply-changes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyChangesModalComponent implements OnInit {
  content: string = '';

  constructor(
    public dialogRef: MatDialogRef<ApplyChangesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ApplyChangesDialogData
  ) {}

  ngOnInit() {
    this.content =
      this.data.mode === ApplyChangesMode.SAVING
        ? 'You have unsaved information on this page. Leaving without saving will remove all changes.'
        : 'You have made changes to the stops on this page. Leaving without merging will remove all changes.';
  }

  doNotSave() {
    this.dialogRef.close(ApplyChangesModalEnum.DO_NOT_SAVE);
  }

  cancel() {
    this.dialogRef.close(ApplyChangesModalEnum.CANCEL);
  }
}
