import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { ViewRefDirective } from '../../directives/view-ref.directive';
import { InjectionKeys } from '../../enums/injection-keys.enum';
import { InjectionComponentConfig } from '../../models/injection-component-config.model';
import { SplitPanelService } from '../../services/split-panel-service/split-panel.service';

export class SplitPanelComponentRef {
  constructor(public componentRef?: ComponentRef<any>) {}

  setComponentRef(ref: ComponentRef<any>): void {
    this.componentRef = ref;
  }

  clearComponentRef(): void {
    this.componentRef = undefined;
  }
}

export enum SplitPanelDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum SplitPanelUnit {
  Percent = 'percent',
  Pixel = 'pixel',
}

@Component({
  selector: 'pnd-split-panel',
  templateUrl: './split-panel.component.html',
  styleUrls: ['./split-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitPanelComponent implements AfterViewInit, OnDestroy {
  private onCloseHandler: Function;
  private leftDrawerComponentRef: SplitPanelComponentRef = new SplitPanelComponentRef();
  private rightDrawerComponentRef: SplitPanelComponentRef = new SplitPanelComponentRef();
  private unsubscriber: Unsubscriber = new Unsubscriber();

  readonly SplitPanelDirection = SplitPanelDirection;

  @Output() closeRightPanelClicked: EventEmitter<void> = new EventEmitter();
  @Output() closeLeftPanelClicked: EventEmitter<void> = new EventEmitter();
  @Output() panelResized: EventEmitter<{ width: number; height: number }> = new EventEmitter();

  @ViewChildren(ViewRefDirective) drawers: QueryList<ViewRefDirective>;

  readonly showRightPanel$ = this.splitPanelService.showRightPanel$;
  readonly showLeftPanel$ = this.splitPanelService.showLeftPanel$;
  readonly rightPanelConfig$ = this.splitPanelService.rightPanelComponentConfig$;
  readonly leftPanelConfig$ = this.splitPanelService.leftPanelComponentConfig$;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public splitPanelService: SplitPanelService
  ) {}

  ngAfterViewInit() {
    this.initWatchers();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private initWatchers(): void {
    this.rightPanelConfig$.pipe(distinctUntilChanged()).subscribe((panelConfig: InjectionComponentConfig) => {
      panelConfig
        ? this.addDrawerComponent(panelConfig, this.getRightDrawerViewRef(), this.rightDrawerComponentRef)
        : this.removeDrawerComponent(this.getRightDrawerViewRef(), this.rightDrawerComponentRef);
    });

    this.leftPanelConfig$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((panelConfig: InjectionComponentConfig) => {
        panelConfig
          ? this.addDrawerComponent(panelConfig, this.getLeftDrawerViewRef(), this.leftDrawerComponentRef)
          : this.removeDrawerComponent(this.getLeftDrawerViewRef(), this.leftDrawerComponentRef);
      });
  }

  private addDrawerComponent(
    config: InjectionComponentConfig,
    viewRef: ViewContainerRef,
    currentComponentRef: SplitPanelComponentRef
  ): void {
    this.onCloseHandler = config.onClose;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(config.componentRef);
    let componentRef: ComponentRef<any> = currentComponentRef.componentRef;
    // if instantiating a component that is not already injected, clear viewRef and create new component
    if (componentRef?.componentType?.name !== config.componentRef.name) {
      viewRef.clear();

      const injector = Injector.create({
        providers: [
          {
            provide: InjectionKeys.DYNAMIC_COMPONENT_DATA,
            useValue: config,
          },
        ],
      });

      componentRef = viewRef.createComponent(componentFactory, 0, injector);
      currentComponentRef.setComponentRef(componentRef);

      // if listening to outputs via injection, component must have unsubscriber variable that
      // calls complete onDestroy. output key must match output event emitter
      if (config.outputs) {
        Object.keys(config.outputs).forEach((outputKey) => {
          if (componentRef.instance[outputKey]) {
            (componentRef.instance[outputKey] as EventEmitter<any>)
              .pipe(takeUntil(componentRef.instance.unsubscriber.done$))
              .subscribe(config.outputs[outputKey]);
          }
        });
      }

      // if input data, inject into componentRef
      if (config.inputs) {
        Object.assign(componentRef.instance, config.inputs);
      }
    } else if (config.updateInputs) {
      // When the component is already instantiated, the 'updateInputs' field provides a way for optionally setting new inputs
      Object.assign(componentRef.instance, config.updateInputs);
    }
  }

  private removeDrawerComponent(viewRef: ViewContainerRef, componentRef: SplitPanelComponentRef): void {
    viewRef.clear();
    componentRef.clearComponentRef();
  }

  private getLeftDrawerViewRef() {
    return this.drawers.first.viewContainerRef;
  }

  private getRightDrawerViewRef() {
    return this.drawers.last.viewContainerRef;
  }

  handleCloseRightDrawerClicked(): void {
    this.onCloseHandler();
    this.rightDrawerComponentRef.clearComponentRef();
    this.closeRightPanelClicked.emit();
  }

  handleCloseLeftDrawerClicked(): void {
    this.onCloseHandler();
    this.leftDrawerComponentRef.clearComponentRef();
    this.closeLeftPanelClicked.emit();
  }

  handleDragEnd({ sizes }: { sizes: number[] }): void {
    if (this.splitPanelService.rightPanelComponentConfig) {
      this.splitPanelService.updateRightPanel({
        updateInputs: undefined,
        size: sizes[1],
      });
    } else if (this.splitPanelService.leftPanelComponentConfig) {
      this.splitPanelService.updateLeftPanel({
        updateInputs: undefined,
        size: sizes[1],
      });
    }

    this.panelResized.emit({ width: sizes[1], height: sizes[0] });
  }
}
