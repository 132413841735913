<div class="pnd-iconHeaderRenderer">
  <button
      class="pnd-iconHeaderRenderer__button"
      [ngClass]="{ 'pnd-iconHeaderRenderer__button--centered': isCentered }"
      mat-icon-button
      [matTooltip]="tooltip"
      matTooltipPosition="above"
      (click)="onClickEvent($event)">
    <div class="pnd-iconHeaderRenderer__button--container">
      <mat-icon [style.color]="color">{{ iconName }}</mat-icon>

      <ng-container *ngIf="sortable">
        <span
            ref="eSortAsc"
            class="ag-header-icon ag-sort-ascending-icon ag-hidden"
            [ngClass]="{ 'ag-hidden': sortItem.sort !== 'asc' }"
            aria-hidden="true"><span class="ag-icon ag-icon-asc" unselectable="on"></span></span>
        <span
            ref="eSortDesc"
            class="ag-header-icon ag-sort-descending-icon"
            [ngClass]="{ 'ag-hidden': sortItem.sort !== 'desc' }"
            aria-hidden="true"><span class="ag-icon ag-icon-desc" unselectable="on"></span></span>

        <span
            ref="eSortNone"
            class="ag-header-icon ag-sort-none-icon"
            aria-hidden="true"
            [ngClass]="{ 'ag-hidden': sortItem.sort !== '' }"><span class="ag-icon ag-icon-none"
              unselectable="on"></span></span>
      </ng-container>
    </div>
  </button>
</div>
