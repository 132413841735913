import { Injectable } from '@angular/core';
import * as DockRoutesActions from '@pnd-store/dock-routes-store/dock-routes.actions';
import { DockRoutesActivityId } from '@pnd-store/dock-routes-store/dock-routes.state';
import { PndStoreState } from '@pnd-store/index';
import { PndStore } from '@pnd-store/pnd-store';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import {
  XpoLtlPickupRequestComponentConfig,
  PickupRequestData,
  AppName,
  PickupFormTypeEnum,
} from '@xpo-ltl/ngx-ltl-pickup-request';
import {
  ListDockRouteStopsRqst,
  CityOperationsApiService,
  DockStop,
  UnassignPnDShipmentsFromRouteRqst,
  UnassignPnDShipmentsFromRoutePath,
  ListDockRouteStopsResp,
} from '@xpo-ltl/sdk-cityoperations';
import { ShipmentId } from '@xpo-ltl/sdk-common';
import { size as _size, pick as _pick } from 'lodash';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DockRoutesMainGroupType } from '../../../components/trips/dock-routes/enums/dock-routes-main-group-type.enum';
import {
  DockRoutesShipmentGridItem,
  DockRoutesActivityGridItem,
} from '../../../components/trips/dock-routes/models/dock-routes-grid-item.model';

@Injectable({
  providedIn: 'root',
})
export class DockRoutesService {
  private readonly routesSubject = new BehaviorSubject<number[]>(undefined);
  readonly routes$ = this.routesSubject.asObservable();

  private readonly routeStopsSubject = new BehaviorSubject<DockStop[]>(undefined);
  readonly routeStops$ = this.routeStopsSubject.asObservable();

  private loadingStopsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingStops$ = this.loadingStopsSubject.asObservable();

  private showGridSubject = new BehaviorSubject<boolean>(true);
  readonly showGrid$ = this.showGridSubject.asObservable();

  // tslint:disable-next-line:no-any
  private errorSubject = new BehaviorSubject<any>(undefined);
  readonly error$ = this.errorSubject.asObservable();

  private expandGroupSubject = new BehaviorSubject<string[]>([DockRoutesMainGroupType.INCOMPLETE_DOCK_PICKUPS]);
  readonly expandGroup$ = this.expandGroupSubject.asObservable();

  dockPickupInstId: number = undefined;

  private pickupRequestComponentConfig: XpoLtlPickupRequestComponentConfig;
  private pickupRequestSubject = new BehaviorSubject<XpoLtlPickupRequestComponentConfig>(undefined);
  readonly pickupRequest$ = this.pickupRequestSubject.asObservable();

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: PndStore<PndStoreState.State>
  ) {}

  getCurrentRouteInstId(): number[] {
    return this.routesSubject.value;
  }

  getGroupsToExpand(): string[] {
    return this.expandGroupSubject.value;
  }

  setGroupsToExpand(groups: string[]): void {
    return this.expandGroupSubject.next(groups);
  }

  getDockPickUpInstID() {
    return this.dockPickupInstId;
  }

  setRoutes(routInstId: number[], reload = true) {
    if (!routInstId) {
      this.routeStopsSubject.next(undefined);
      this.dockPickupInstId = undefined;
    }
    this.routesSubject.next(routInstId);
    this.pndStore$.dispatch(new DockRoutesActions.Refresh());
  }

  refreshStops$(): Observable<DockStop[]> {
    const routeInstIds = this.getCurrentRouteInstId();
    if (routeInstIds) {
      this.loadingStopsSubject.next(true);
      const listDockRouteStopsRqst = new ListDockRouteStopsRqst();
      listDockRouteStopsRqst.routeInstIds = routeInstIds;
      return this.cityOperationsService.listDockRouteStops(listDockRouteStopsRqst).pipe(
        catchError((error) => {
          this.errorSubject.next(error);
          this.loadingStopsSubject.next(false);
          return of(undefined);
        }),
        map((response: ListDockRouteStopsResp) => {
          this.routeStopsSubject.next(response?.dockStops);
          this.loadingStopsSubject.next(false);
          return response.dockStops;
        })
      );
    } else {
      return of(undefined);
    }
  }

  unassignDockShipments$(activityIds: DockRoutesActivityId[]): Observable<void> {
    if (!_size(activityIds)) {
      return of(undefined);
    }

    const shipmentIds = [] as ShipmentId[];

    activityIds.forEach((activityId: DockRoutesActivityId) => {
      const shipmentId: ShipmentId = {
        shipmentInstId: activityId.shipmentInstId.toString(),
        proNumber: undefined,
        pickupDate: undefined,
      };

      shipmentIds.push(shipmentId);
    });

    const request: UnassignPnDShipmentsFromRouteRqst = { ...new UnassignPnDShipmentsFromRouteRqst(), shipmentIds };
    const pathParams: UnassignPnDShipmentsFromRoutePath = {
      ...new UnassignPnDShipmentsFromRoutePath(),
      routeInstId: this.dockPickupInstId,
    };

    return this.cityOperationsService.unassignPnDShipmentsFromRoute(request, pathParams);
  }

  getDockActivityIdFromItem(item: DockRoutesActivityGridItem | DockRoutesShipmentGridItem): DockRoutesActivityId {
    const id = _pick(item, [
      'uniqueId',
      'routeInstId',
      'shipmentInstId',
      'proNbr',
      'specialServices',
      'billCount',
      'motorMovesNbr',
      'weightLbs',
    ]) as DockRoutesActivityId;
    return id;
  }

  setPickupRequestConfig(callNbr: string, pickupInstId: number, serviceCenter: ServiceCenter) {
    const pickupData: PickupRequestData = {
      appName: AppName.PND,
      pickupIdCallsNbrList: [{ callNbr: callNbr, id: pickupInstId }],
      serviceCenter: serviceCenter,
    };

    this.pickupRequestComponentConfig = {
      pickupFormType: PickupFormTypeEnum.UPDATE_PICKUP,
      data: pickupData,
      closeAction: {
        tooltip: 'Close',
        dataTestAttr: 'pickupRequestActionTileRight',
        iconName: 'close',
        disabled$: of(false),
        callbackFn: () => {
          this.closeCallBack();
        },
      },
    } as XpoLtlPickupRequestComponentConfig;

    this.pickupRequestSubject.next(this.pickupRequestComponentConfig);
  }

  closeCallBack() {
    this.pickupRequestSubject.next(undefined);
  }
}
