<div
  class="pnd-MapDispatchArea"
  data-test="pnd-MapDispatchArea__container"
  [ngClass]="{ expanded: isMapToolbarExpanded, options: isDrawOptionsPanelOpened }"
>
  <button
    [disabled]="disabled$ | async"
    mat-stroked-button
    (click)="createAnotherArea()"
    class="pnd-MapDispatchArea-createNew xpoTriggerHideContentButton"
  >
    CREATE ANOTHER AREA
  </button>

  <div *ngFor="let dispatchGroupRegion of dispatchGroupRegions$ | async; trackBy: trackRegionBy">
    <app-map-dispatch-area-card
      (scrollTo)="scrollTo($event)"
      [pndDispatchGroupRegion]="dispatchGroupRegion"
    ></app-map-dispatch-area-card>
  </div>
</div>
