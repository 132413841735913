<ng-container *ngIf="value$ | async as value">
  <div class="pnd-DispatchDriverCellRendererParent">
    <xpo-icon
      iconName="phone"
      [matTooltip]="'Personal: ' + dsrCellPhoneNbr + '\nHandheld: ' + handheldPhoneNbr"
      *ngIf="showContactInfo"
      matTooltipClass="contactTooltip"
      matTooltipPosition="above"
    >
    </xpo-icon>
    <div
      [matTooltip]="alert?.cellHoverText"
      matTooltipPosition="above"
      class="pnd-DispatchDriverCellRenderer"
      [class.pnd-DispatchDriverCellRenderer--total]="value?.isTotal"
    >
      <p class="pnd-DispatchDriverCellRenderer__driver-name">
        {{ value?.driverName }}
        <xpo-icon *ngIf="displayDriverNotification$ | async" iconName="circle" class="xpo-Icon circle"></xpo-icon>
      </p>
      <ng-container *ngIf="value?.labels?.length">
        <div class="pnd-DispatchDriverCellRenderer__label" *ngFor="let label of value.labels; trackBy: trackLablelBy">
          <xpo-status-indicator
            class="pnd-DispatchDriverCellRenderer__label"
            [statusText]="label.text"
            [statusColor]="label.color"
            isLabel="true"
          ></xpo-status-indicator>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
