import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { StoreSourcesEnum } from '../../inbound-planning/shared/enums/store-sources.enum';
import {
  EventItem,
  PlanningRouteShipmentIdentifier,
  RouteItemIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { AssignedStopMapMarker } from '../../inbound-planning/shared/models/markers/assigned-stop-map-marker.model';
import { RoutesSearchCriteria } from './routes-search-criteria.interface';

export const StateKey = 'routesState';

export interface RouteError {
  routeName: string;
  error: string;
}

export interface State {
  // Misc
  readonly updateUnassignedDeliveriesGrid: { source: StoreSourcesEnum; date: Date };
  readonly routeMarkers: AssignedStopMapMarker[];

  // Planning Routes
  readonly searchCriteria: RoutesSearchCriteria;
  readonly planningRoutesLastUpdate: Date;
  readonly loadingPlanningRoutes: boolean;

  readonly focusedPlanningRoute: EventItem<RouteItemIdentifier>;
  readonly focusedPlanningRouteShipment: EventItem<PlanningRouteShipmentIdentifier>;

  readonly selectedPlanningRoutes: number[]; // list of selected routeInstId
  readonly stopsForSelectedPlanningRoutesLastUpdate: Date;
  readonly stopsForSelectedPlanningRoutes: { routeInstId: number; stops: UnassignedStop[] }[];

  readonly selectedPlanningRoutesShipments: EventItem<PlanningRouteShipmentIdentifier>[];
  readonly filteredPlanningRouteIds: { [key: number]: boolean };
}

export const initialState: State = {
  // Misc
  updateUnassignedDeliveriesGrid: { source: undefined, date: undefined },
  routeMarkers: [],

  // Planning Routes
  searchCriteria: {},
  loadingPlanningRoutes: false,
  planningRoutesLastUpdate: new Date(0),

  focusedPlanningRoute: { id: undefined, source: undefined },
  focusedPlanningRouteShipment: { id: undefined, source: undefined },

  selectedPlanningRoutes: [],
  stopsForSelectedPlanningRoutesLastUpdate: new Date(0),

  selectedPlanningRoutesShipments: [],
  filteredPlanningRouteIds: {},
  stopsForSelectedPlanningRoutes: [],
};
