import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { get as _get } from 'lodash';

export interface CheckmarkCellRendererParams extends ICellRendererParams {
  field?: string;
  color?: string;
}

@Component({
  selector: 'pnd-checkmark-cell-renderer',
  templateUrl: './checkmark-cell-renderer.component.html',
  styleUrls: ['./checkmark-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-CheckmarkCellRenderer' },
})
export class CheckmarkCellRendererComponent implements ICellRendererAngularComp {
  private readonly defaultColor = 'black';

  visible = false;
  color = this.defaultColor;

  agInit(params: CheckmarkCellRendererParams): void {
    this.update(params);
  }

  refresh(params: CheckmarkCellRendererParams): boolean {
    this.update(params);
    return true;
  }

  private update(params: CheckmarkCellRendererParams) {
    // Use _get intentionally because field could be a nested property which doesn't work with elvis operator syntax
    this.visible = !!_get(params, params.field, params.value);
    this.color = params?.color ?? this.defaultColor;
  }
}
