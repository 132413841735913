import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  chunk as _chunk,
  first as _first,
  flatten as _flatten,
  forEach as _forEach,
  map as _map,
  size as _size,
} from 'lodash';
import * as md5 from 'md5';
import { Observable, Subscriber } from 'rxjs';
import { ConfigManagerProperties } from '../../../../../../../core/enums';
import { Leg } from '../interfaces/leg.interface';
import { SnapToRoadsResponse } from '../interfaces/snap-to-roads-response.interface';
import { SnappedPoint } from '../interfaces/snapped-point.interface';
import { TripRenderingHelper } from './helpers/trip-rendering.helper';

@Injectable({
  providedIn: 'root',
})
export class GoogleRenderingService {
  /**
   * 25 waypoints by default (Google Directions API limitation)
   */
  static readonly directionsChunkSize: number = 25;

  /**
   * 10 minutes by default
   */
  static readonly maxCachingThreshold: number = 60 * 10;

  private cachedRequests: { [key: string]: { legs: Leg[]; lastUpdated: Date } } = {};
  private gmDirectionsService: google.maps.DirectionsService;

  constructor(
    private configManagerService: ConfigManagerService,
    private httpBackend: HttpBackend,
    private tripRenderingHelper: TripRenderingHelper
  ) {}

  /**
   * Transforms a list of geo-locations into a list of waypoints
   * @param locations
   * @private
   */
  private buildWaypoints(locations: google.maps.LatLng[]): google.maps.DirectionsWaypoint[] {
    return locations.map((location: google.maps.LatLng) => {
      return {
        location: location,
        stopover: true,
      } as google.maps.DirectionsWaypoint;
    });
  }

  /**
   * Returns an optimized list of Google Directions requests
   * Example (waypoints):
   * Result [[a,b,c],[c,d,e],[e,f,g]]
   * @param directionRequest
   * @private
   */
  optimizeDirectionRequests(directionRequest: google.maps.DirectionsRequest): google.maps.DirectionsRequest[] {
    if (_size(directionRequest.waypoints) > 25) {
      const optimizedDirectionRequests: google.maps.DirectionsRequest[] = [];

      const waypointsToSplit: google.maps.LatLng[] = [
        directionRequest.origin as google.maps.LatLng,
        ...directionRequest.waypoints.map((w) => w.location as google.maps.LatLng),
        directionRequest.destination as google.maps.LatLng,
      ];

      const waypointChunks: google.maps.LatLng[][] = _chunk(
        waypointsToSplit,
        GoogleRenderingService.directionsChunkSize + 1
      );

      // Push the previous chunk tail
      for (let i = 0; i < waypointChunks.length - 1; i++) {
        waypointChunks[i].push(_first(waypointChunks[i + 1]));
      }

      // Generate optimized requests
      _forEach(waypointChunks, (waypointChunk: google.maps.LatLng[]) => {
        if (_size(waypointChunk) >= 2) {
          optimizedDirectionRequests.push({
            optimizeWaypoints: false,
            travelMode: google.maps.TravelMode.DRIVING,
            origin: waypointChunk.shift(),
            destination: waypointChunk.pop(),
            waypoints: this.buildWaypoints(waypointChunk),
          });
        }
      });

      return optimizedDirectionRequests;
    } else {
      return [directionRequest];
    }
  }

  /**
   * Returns a list of legs given a directions request
   * @param directionsRequest
   */
  calculateDirectionLegs(directionsRequest: google.maps.DirectionsRequest): Observable<Leg[]> {
    return new Observable((observer) => {
      if (!this.gmDirectionsService) {
        this.gmDirectionsService = new google.maps.DirectionsService();
      }

      const legs: Leg[] = [];

      const key = md5(JSON.stringify(directionsRequest));

      if (
        this.cachedRequests[key] &&
        !this.tripRenderingHelper.isOverdue(
          this.cachedRequests[key].lastUpdated,
          GoogleRenderingService.maxCachingThreshold
        )
      ) {
        observer.next(this.cachedRequests[key].legs);
        observer.complete();
      } else {
        this.gmDirectionsService.route(directionsRequest, (directionResult, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            _forEach(directionResult.routes[0].legs, (leg: google.maps.DirectionsLeg, i) => {
              const pointsAccumulator: google.maps.LatLng[] = _flatten(_map(leg.steps, (step) => step.path));

              legs.push({
                segmentId: i,
                hasCompletedStops: false,
                isBreadcrumb: false,
                isConnector: false,
                color: '',
                route: undefined,
                points: pointsAccumulator,
              });
            });

            this.cachedRequests[key] = {
              legs,
              lastUpdated: new Date(),
            };

            observer.next(legs);
            observer.complete();
          } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
            observer.error('Unable to calculate driver directions: Over query limit');
          } else {
            observer.error('Unable to calculate driver directions');
          }
        });
      }
    });
  }

  /**
   * Tries to snap the given coordinates to the closest road
   * @param coordinates List of points to smooth
   */
  smoothPath(coordinates: google.maps.LatLng[]): Observable<google.maps.LatLng[]> {
    return new Observable<google.maps.LatLng[]>((observer: Subscriber<google.maps.LatLng[]>) => {
      new HttpClient(this.httpBackend)
        .get('https://roads.googleapis.com/v1/snapToRoads', {
          params: {
            interpolate: 'true',
            key: this.configManagerService.getSetting<string>(ConfigManagerProperties.googleApiLicenseKey),
            path: coordinates.map((coordinate) => `${coordinate.lat()},${coordinate.lng()}`).join('|'),
          },
        })
        .subscribe(
          (response: SnapToRoadsResponse) => {
            let snappedCoordinates: google.maps.LatLng[] = coordinates;

            if (response?.snappedPoints) {
              snappedCoordinates = _map(
                response.snappedPoints,
                (point: SnappedPoint) => new google.maps.LatLng(point.location.latitude, point.location.longitude)
              );
            }

            observer.next(snappedCoordinates);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
