export enum ModifyTripDetailsSharedGridHeaders {
  ROW_SELECTED = '',
  MAIN_GROUP_VALUE = '',

  SEQUENCE_NBR = 'SEQ',

  ACTIVITY_TYPE = 'Activity',
  EXCEPTIONS = 'Exceptions',

  FBDS_PRINT_COUNT_CD = 'Print Status',
  FBDS_PRINT_COUNT = 'Print Count',

  CUSTOMER = 'Customer',
  ADDRESS = 'Address',
  CITY = 'City',
  ZIP_CODE = 'Zip Code',
  BOL = 'BOL',
  NOTES = 'Notes',

  PALLETS = 'Pallets',
  BILLS = 'Bills',
  LOOSE_PIECES = 'LP',
  WEIGHT = 'Weight',
  MOTOR_MOVES = 'MM',
  CUBE = 'Cube (%)',
  SPECIAL_SERVICES = 'Special Services',

  DEST_SIC = 'Dest. SIC',
  CALL_NBR = 'Call #',
  TENDER_STATUS = 'Tender Status',
}
