import {
  Component,
  ViewEncapsulation,
  Input,
  ViewChild,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Metric, ShipmentMetric } from '@xpo-ltl/sdk-cityoperations';
import { MetricValueKeyCd, MetricVarianceCd } from '@xpo-ltl/sdk-common';
import GoldenLayout from 'golden-layout';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LayoutManagerService, LayoutPreferenceService } from 'shared/layout-manager';
import { MetricsUtils } from '../../classes/metrics-utils';
import { SkeletonView } from '../../classes/skeleton-view';
import { MetricShipmentsComponent } from './metric-shipments/metric-shipments.component';

@Component({
  selector: 'pnd-metric-bar',
  templateUrl: 'metric-bar.component.html',
  styleUrls: ['metric-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricBarComponent implements OnInit {
  @Input()
  set shipmentMetrics(value: ShipmentMetric[]) {
    if (value) {
      this._shipmentMetrics = value;
    }
  }

  get shipmentMetrics(): ShipmentMetric[] {
    return this._shipmentMetrics;
  }

  @Input()
  set metrics(value: Metric[]) {
    if (value) {
      this._metrics = value;
    }

    this.displaySkeletonViews = !this.metrics;
  }

  get metrics(): Metric[] {
    return this._metrics;
  }

  constructor(
    private layoutManagerService: LayoutManagerService,
    private layoutPreferenceService: LayoutPreferenceService,
    private dialog: MatDialog
  ) {}
  private _metrics: Metric[] = [];
  private _shipmentMetrics: ShipmentMetric[] = [];

  @Output() onCardClicked = new EventEmitter();

  displaySkeletonViews: boolean;

  specialServiceMetrics: MetricValueKeyCd[] = [
    MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING,
    MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING,
    MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING,
  ];

  metricsForOverview: MetricValueKeyCd[] = [
    MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING,
    MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING,
    MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING,
    // Hiding these for the time being until we can get more accurate data for these metrics
    // MetricValueKeyCd.DELIVERY_PPMH,
    // MetricValueKeyCd.DELIVERY_WEIGHT_PER_TRIP,
    // MetricValueKeyCd.DELIVERY_COST_PER_TRIP,
  ];

  skeletonMetrics = Array(6).fill(new SkeletonView());
  @ViewChild(MatExpansionPanel, { static: false }) panel: MatExpansionPanel;
  private defaultLayoutHeigh: number = 0;
  private defaultCurrentLayout: string;
  private layoutManager: GoldenLayout.ContentItem['layoutManager'];
  private anyMaximizedItem: GoldenLayout.ContentItem;
  panelTitle = 'Expand';

  private readonly showRefresh = new BehaviorSubject<boolean>(true);
  readonly showRefresh$ = this.showRefresh.asObservable();

  trackMetricBy(index, metric: Metric) {
    if (!metric) {
      return null;
    }
    return metric?.metricId + index;
  }
  ngOnInit(): void {
    this.subscribeToLayoutChanges();
  }

  onMetricClick(metric: Metric) {
    if (this.isSpecialServiceMetric(metric.valueKeyCd)) {
      this.openShipmentsDialog(metric);
      this.onCardClicked.emit(metric);
    }
  }

  openShipmentsDialog(metric: Metric) {
    const valueKeyCd: MetricValueKeyCd = metric?.valueKeyCd;
    const { deliveryShipments } = this.shipmentMetrics?.filter(
      (shipmentMetric: ShipmentMetric) => shipmentMetric?.valueKeyCd === valueKeyCd
    )[0];

    return this.dialog.open(MetricShipmentsComponent, {
      data: { valueKeyCd: metric?.valueKeyCd, shipments: deliveryShipments },
      disableClose: true,
      hasBackdrop: true,
      width: '2400px',
    });
  }

  isSpecialServiceMetric(key: MetricValueKeyCd): boolean {
    return this.specialServiceMetrics.includes(key);
  }

  getOverviewDescription(metric: Metric): string {
    return this.isSpecialServiceMetric(metric.valueKeyCd) ? 'Shipments' : metric.description;
  }

  getSpecialServiceIcon(key: MetricValueKeyCd): string {
    switch (key) {
      case MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING:
        return 'ss_show';
      case MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING:
        return 'ss_premium';
      case MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING:
        return 'ss_guaranteed';
      default:
        return;
    }
  }

  getVarianceIcon(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceIcon(variance);
  }

  getVarianceClassColor(variance: MetricVarianceCd): string {
    return MetricsUtils.getVarianceClassColor(variance);
  }

  subscribeToLayoutChanges() {
    this.layoutPreferenceService.activeLayout$.subscribe((layout) => {
      if (!this.defaultCurrentLayout) {
        this.defaultCurrentLayout = layout.name;
      } else if (this.defaultCurrentLayout !== layout.name) {
        this.defaultCurrentLayout = layout.name;
        this.panel.close();
        this.onToggle();
      }
    });

    this.layoutManagerService.panelActivated$.subscribe((res) => {
      if (res && this.panel.expanded) {
        this.onPanelChangeWhileExpand();
      }
    });
  }

  onRefresh(event: MouseEvent): void {
    this.showRefresh.next(false);
    // We don't want to expand or collapse the metric bar when refresh is clicked
    event.stopPropagation();
    this.onCardClicked.emit();
    // TODO: showRefresh true on API observable complete
    setTimeout(() => {
      this.showRefresh.next(true);
    }, 2000);
  }

  onToggle() {
    this.anyMaximizedItem = this.layoutManagerService.findMaximisedStack();
    this.layoutManager = this.layoutManagerService.getOpenedPanels()[0].layoutManager;
    this.defaultLayoutHeigh = this.defaultLayoutHeigh === 0 ? this.layoutManager.height : this.defaultLayoutHeigh;
    this.panel._bodyAnimationDone.pipe(take(1)).subscribe(() => {
      if (this.panel.expanded) {
        this.panelTitle = 'Collapse';
        this.layoutManager.updateSize(
          this.layoutManager.width,
          this.defaultLayoutHeigh - this.panel._body.nativeElement.offsetHeight
        );
        if (this.anyMaximizedItem) {
          this.anyMaximizedItem
            .getActiveContentItem()
            ['container'].getElement()
            .removeAttr('style')
            .attr(
              'style',
              `width:${this.layoutManager.width}px; height:${this.defaultLayoutHeigh -
                this.panel._body.nativeElement.offsetHeight -
                14}px`
            );
        }
      } else {
        this.layoutManager.updateSize(
          this.layoutManager.width,
          this.defaultLayoutHeigh + this.panel._body.nativeElement.offsetHeight
        );
        if (this.anyMaximizedItem) {
          this.anyMaximizedItem
            .getActiveContentItem()
            ['container'].getElement()
            .removeAttr('style')
            .attr(
              'style',
              `width:${this.layoutManager.width}px; height:${this.defaultLayoutHeigh +
                this.panel._body.nativeElement.offsetHeight -
                14}px`
            );
        }
        this.panelTitle = 'Expand';
      }
    });
  }

  onPanelChangeWhileExpand() {
    this.layoutManager.updateSize(
      this.layoutManager.width,
      this.defaultLayoutHeigh - this.panel._body.nativeElement.offsetHeight
    );
    if (this.anyMaximizedItem) {
      this.anyMaximizedItem
        .getActiveContentItem()
        ['container'].getElement()
        .removeAttr('style')
        .attr(
          'style',
          `width:${this.layoutManager.width}px; height:${this.defaultLayoutHeigh -
            this.panel._body.nativeElement.offsetHeight -
            14}px`
        );
    }
  }
}
