import { Injectable } from '@angular/core';
import { FormatChecker } from '@xpo-ltl/ngx-ag-grid';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { Route, RouteDetail, TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { forEach as _forEach, get as _get, toString as _toString } from 'lodash';
import moment from 'moment';
import { TripPlanningGridItem } from '../../../components/trip-planning/models/trip-planning-grid-item.model';
import { TripValidationEnum } from '../../enums/trip-validation.enum';
import { TimeUtil } from '../time-format.util';

@Injectable({
  providedIn: 'root',
})
export class TripsGridItemConverterService {
  static isEligibleForRelease(tripItem: TripPlanningGridItem, timeService: XpoLtlTimeService): boolean {
    const isReleased = tripItem?.route?.xdockReleaseInd ?? false;
    const estDepartTime: string = TripsGridItemConverterService.getEstDepartDateTime(tripItem, timeService);

    return !isReleased && estDepartTime !== '00:00';
  }

  static getEstDepartDateTime(tripItem: TripPlanningGridItem, timeService: XpoLtlTimeService): string {
    const estDepartTime = tripItem?.estimatedDepartDateTime;
    const timeResult = estDepartTime
      ? timeService.to24Time(estDepartTime, tripItem?.route?.terminalSicCd)
      : TripsGridItemConverterService.timeValueGetter(tripItem?.route, 'deliveryRouteDepartTime');

    return timeResult;
  }

  // TODO: refactor pending. The methods timeValueGetter also are being used on optimizer driver template
  static timeValueGetter(data: any, field: string): string {
    // Use _get because properties may be nested which is unhandled by elvis operator
    const fieldValue = _get(data, field, '');
    let time: moment.Moment;

    if (!!fieldValue) {
      time = moment(TimeUtil.formatStringToTimeStringByFillingWithZeros(fieldValue), 'HH:mm:ss');
    }

    return time && time.isValid() ? time.format('HH:mm') : fieldValue;
  }

  static isDockLocationFormatValid(value: string) {
    return /^[\d\w@ -]*$/.test(value);
  }

  static isDockLocationValueValid(dockLocation: string, nearest: string, door: string, trailer: string): FormatChecker {
    let [fail, errorDescription]: [boolean, string] = [false, undefined];

    if (!TripsGridItemConverterService.isDockLocationFormatValid(dockLocation)) {
      [fail, errorDescription] = [true, TripValidationEnum.WhiteListCharacters];
    } else if (dockLocation) {
      if (door) {
        [fail, errorDescription] = [true, TripValidationEnum.DoorDockExclusive];
      } else if (trailer) {
        [fail, errorDescription] = [true, TripValidationEnum.TrailerDockExclusive];
      } else if (!nearest) {
        [fail, errorDescription] = [true, TripValidationEnum.NearestDoorRequired];
      }
    }

    return {
      fail,
      errorDescription,
    };
  }

  static isNearestValueValid(nearest: string, dockLocation: string): FormatChecker {
    let [fail, errorDescription]: [boolean, string] = [false, undefined];

    if (nearest && _toString(nearest).length > 4) {
      [fail, errorDescription] = [true, TripValidationEnum.FormatInvalid];
    } else if (nearest && !dockLocation) {
      [fail, errorDescription] = [true, TripValidationEnum.DockLocationRequired];
    }

    return {
      fail,
      errorDescription,
    };
  }

  getTripsGridItems(tripDetails: TripDetail[]): TripPlanningGridItem[] {
    const gridItems: TripPlanningGridItem[] = [];
    _forEach(tripDetails, (tripDetail) => {
      if (tripDetail?.route?.length === 0) {
        const keyField: string = (tripDetail?.trip?.tripInstId ?? '').toString();
        const tripDsrName: string = tripDetail?.tripDriver?.dsrName ?? '';
        const route = new Route();
        route.routeInstId = 0;
        gridItems.push(this.tripOnlyGridItem(tripDetail, route, keyField, tripDsrName));
      } else {
        let routeNumber = 0;
        _forEach(tripDetail?.route, (routeDetail) => {
          routeNumber += 1;
          const numberOfRoutes = tripDetail.route.length;
          let tripDsrName = tripDetail?.tripDriver?.dsrName ?? '';
          if (numberOfRoutes > 1) {
            if (tripDsrName === '') {
              tripDsrName = `${tripDetail.trip.tripInstId}`;
            }
            tripDsrName += `(${numberOfRoutes})`;
          }
          const keyField = (tripDetail?.trip?.tripInstId ?? '') + '-' + routeNumber; // creating a unique keyField

          const tripPlanningGridItem: TripPlanningGridItem = this.tripOnlyGridItem(
            tripDetail,
            routeDetail.route,
            keyField,
            tripDsrName,
            routeDetail
          );
          gridItems.push(tripPlanningGridItem);
        });
      }
    });
    return gridItems;
  }

  tripOnlyGridItem(
    tripDetail: TripDetail,
    route: Route,
    keyField: string,
    tripDsrName: string,
    routeDetail?: RouteDetail
  ): TripPlanningGridItem {
    route.tripInstId = tripDetail?.trip?.tripInstId;

    const tripPlanningGridItem: TripPlanningGridItem = new TripPlanningGridItem();
    tripPlanningGridItem.keyField = keyField;
    tripPlanningGridItem.tripDsrName = tripDsrName;
    tripPlanningGridItem.tripStopCount = tripDetail?.trip?.stopCount;
    tripPlanningGridItem.tripInstId = tripDetail?.trip?.tripInstId;
    // take estimatedDepartDateTime from origin node
    tripPlanningGridItem.estimatedDepartDateTime = tripDetail?.tripStops?.[0]?.tripNode?.estimatedDepartDateTime;
    tripPlanningGridItem.routeInstId = route?.routeInstId;
    tripPlanningGridItem.route = route;
    tripPlanningGridItem.metrics = tripDetail?.metrics;
    tripPlanningGridItem.estimatedEmptyDateTime = tripDetail?.trip?.estimatedEmptyDateTime;
    tripPlanningGridItem.statusCd = tripDetail?.trip?.statusCd;
    tripPlanningGridItem.specialServices = route?.specialServices ?? [];
    tripPlanningGridItem.terminalSicCd = tripDetail?.trip?.terminalSicCd;
    tripPlanningGridItem.cmsCarrierId = tripDetail?.trip?.cmsCarrierId;
    tripPlanningGridItem.carrierTenderGroupStatusCd = tripDetail?.carrierTenderGroupStatusCd;
    tripPlanningGridItem.dsrEmployeeId = tripDetail?.trip?.dsrEmployeeId;
    tripPlanningGridItem.tenderMessages = tripDetail?.tenderMessages;
    tripPlanningGridItem.carrierName = tripDetail?.carrier?.carrierName;
    tripPlanningGridItem.auditInfo = tripDetail?.trip?.auditInfo;
    tripPlanningGridItem.cmsTripTypeCd = tripDetail?.trip?.cmsTripTypeCd;
    tripPlanningGridItem.totalLoadedShipmentCount = routeDetail?.totalLoadedShipmentCount;
    tripPlanningGridItem.totalShipmentCount = routeDetail?.totalShipmentCount;
    return tripPlanningGridItem;
  }
}
