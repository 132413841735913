import { Injectable } from '@angular/core';
import { ShipmentMetric } from '@xpo-ltl/sdk-cityoperations';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetricBarService {
  constructor() {}
  private shipmentMetricsSubject = new BehaviorSubject<ShipmentMetric[]>([]);
  readonly shipmentMetrics$ = this.shipmentMetricsSubject.asObservable();

  setShipmentMetrics(shipmentMetrics: ShipmentMetric[]): void {
    this.shipmentMetricsSubject.next(shipmentMetrics);
  }
}
