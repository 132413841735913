import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { PickupSearchRecord } from '@xpo-ltl/sdk-pickuprequest';
import { RowNode } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-merge-pickups-confirm-dialog',
  templateUrl: './merge-pickups-confirm-dialog.component.html',
  styleUrls: ['./merge-pickups-confirm-dialog.component.scss'],
})
export class MergePickupsConfirmDialogComponent implements OnInit {
  private mergingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  merging$: Observable<boolean> = this.mergingSubject.asObservable();
  private output: {
    success?: boolean;
    error?: any;
    data: { fromPUR: PickupSearchRecord[]; toPUR: PickupSearchRecord };
  };

  fromPUR: PickupSearchRecord = null;
  toPUR: PickupSearchRecord = null;

  mergeRqst: { fromPUR: PickupSearchRecord[]; toPUR: PickupSearchRecord };

  constructor(
    private dialogRef: MatDialogRef<MergePickupsConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RowNode[]
  ) {}

  ngOnInit(): void {
    this.fromPUR = this.data[0].data;
    this.toPUR = this.data[1].data;
    this.mergeRqst = { fromPUR: [this.fromPUR], toPUR: this.toPUR };
  }

  onMergeComplete(complete: {
    success?: boolean;
    error?: any;
    data: { fromPUR: PickupSearchRecord[]; toPUR: PickupSearchRecord };
  }): void {
    this.output = complete;
    if (complete?.error) {
      this.mergingSubject.next(false);
    } else if (complete?.success) {
      this.closeDialog();
    }
  }

  initiateMerge(): void {
    this.mergingSubject.next(true);
  }

  closeDialog(): void {
    this.dialogRef.close(this.output);
  }
}
