import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OperationsCustomerProfileService } from '../../services/operations-customer-profile/operations-customer-profile.service';

export interface CustomerNameLinkCellRenderParams extends ICellRendererParams {
  onClick?: (data) => void;
  onHover?: (data) => void;
  customerAcctId: (data) => number;
  isIcon?: string;
  isVisible?: (data) => boolean;
}

@Component({
  selector: 'pnd-customer-name-link-cell-render',
  templateUrl: './customer-name-link-cell-render.html',
  styleUrls: ['./customer-name-link-cell-render.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-CustomerNameLinkCellRender' },
})
export class CustomerNameLinkCellRender implements ICellRendererAngularComp {
  constructor(protected operationsCustomerProfileService: OperationsCustomerProfileService) {}
  params: CustomerNameLinkCellRenderParams;

  agInit(params: CustomerNameLinkCellRenderParams): void {
    this.params = params;
  }

  refresh(params: CustomerNameLinkCellRenderParams): boolean {
    this.agInit(params);
    return true;
  }

  onClick(clickEvent: MouseEvent): void {
    if (this.params.customerAcctId) {
      const customerAcctId = this.params.customerAcctId(this.params.data);

      if (customerAcctId) {
        this.operationsCustomerProfileService.openDialogProfile(customerAcctId);
      }
    }

    if (this.params.onClick) {
      this.params.onClick(this.params.data);
      clickEvent.stopImmediatePropagation();
    }
    clickEvent.stopPropagation();
  }
}
