import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { XpoLtlFormatValidationService } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { xpoGridInternalInlineSingleCellError } from '../constants/event-listeners.constants';
import { XPO_GRID_INTERNAL_CUSTOM_EVENTS } from '../shared/enums/custom-events.enum';
import { XpoGridUpdateCellValidStateEvent as XpoGridUpdateCellErrorEvent } from './../inline-editing.state';
import { XpoGridEditedValue } from './../models/edited-values.model';

interface XpoGridCellRendererParams extends ICellRendererParams {
  keyField: string;
  col: string;
  action: (params: any) => void;
  validate: (params: any) => boolean;
}

export interface XpoGridGetSingleCellErrorEvent {
  type: string;
  id: string;
  colId: string;
}

@Component({
  selector: 'xpo-base-cell-renderer',
  templateUrl: './base-cell-renderer.component.html',
  styleUrls: ['./base-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xpo-base-cell-renderer',
  },
})
export class XpoGridBaseCellRendererComponent implements ICellRendererAngularComp {
  value: any;
  showSelectIcon: boolean;
  error: string;
  isInvalid: boolean;
  params: XpoGridCellRendererParams;
  eventReference: (event: XpoGridUpdateCellErrorEvent) => void;

  constructor(private cd: ChangeDetectorRef, private formatValidationService: XpoLtlFormatValidationService) {}

  agInit(params: XpoGridCellRendererParams): void {
    this.showSelectIcon = params?.colDef.cellEditor === 'agRichSelectCellEditor';
    this.params = params;
    this.value = params.valueFormatted ?? params.value;
    this.requestCellError();
  }

  /**
   * whenever the value changes wether is from cell editing
   * or setting the values via inlineEditing api this method will trigger
   * @param params
   * @returns
   */
  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ?? params.value;
    return true;
  }

  /**
   * takes the received change object and updates the component according to it
   * @param change
   */
  updateErrorState(change: XpoGridEditedValue): void {
    if (change && !change.isValid) {
      this.error = change.errorDescription;
      this.isInvalid = true;
    } else {
      this.isInvalid = false;
      this.error = '';
    }
    this.cd.markForCheck();
  }

  /**
   * checks if it's listening to the xpo internal internal grid event
   * by checking is the event reference is empty, if its not, it create a new listening event
   * right after that it request the errors for this cell in order to trigger the event just created.
   *
   *
   * This is necessary because the cell needs to be aware every time the value changes to check
   * if there is an error along with the change
   */
  private requestCellError(): void {
    if (!this.eventReference) {
      this.eventReference = (event: XpoGridUpdateCellErrorEvent) => this.updateErrorState(event.change);
      this.params.api.addEventListener(
        xpoGridInternalInlineSingleCellError(this.params.data[this.params.keyField], this.params.column.getColId()),
        this.eventReference
      );
    }

    const getCellErrorEvent: XpoGridGetSingleCellErrorEvent = {
      type: XPO_GRID_INTERNAL_CUSTOM_EVENTS.inlineEditing.getSingleCellError,
      id: this.params.data[this.params.keyField],
      colId: this.params.column.getColId(),
    };
    this.params.api.dispatchEvent(getCellErrorEvent);
  }

  /**
   * removes the listening event
   */
  destroy(): void {
    this.params.api.removeEventListener(
      xpoGridInternalInlineSingleCellError(this.params.data[this.params.keyField], this.params.column.getColId()),
      this.eventReference
    );
  }

  formatChildPro(childPro: string): string {
    if (childPro && !childPro.includes('-')) {
      childPro = childPro?.length === 11 ? childPro.slice(1, 11) : childPro;
      const firstPart = childPro.substring(0, 4);
      const secondPart = childPro.substring(firstPart.length, childPro.length);
      childPro = `${firstPart}-${secondPart}`;
    }
    return childPro;
  }
}
