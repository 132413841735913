<div class="pnd-timeSelector">
  <pnd-autocomplete
    floatLabel="always"
    maxlength="5"
    placeholder="HH:MM"
    icon="query_builder"
    [focus$]="focus$"
    [strictSelection]="false"
    [optionTemplate]="timeSelectorTemplate"
    [controlName]="timeSelectorFormControl"
    [group]="formGroup"
    [items]="timeOptions"
    (textClear)="onTextClear($event)"
    (selectionChanged)="onChange($event)"
  >
  </pnd-autocomplete>
</div>

<ng-template #timeSelectorTemplate let-item>
  <span>
    {{ item.value }}
  </span>
</ng-template>
