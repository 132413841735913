<mat-expansion-panel class="pnd-metric-bar" expanded="false" hideToggle="true" (click)="onToggle()">
  <mat-expansion-panel-header>
    <mat-panel-title> {{ panelTitle }} Metrics Panel </mat-panel-title>
    <button
      [ngClass]="{ invisible: (showRefresh$ | async) === false }"
      xpoSmallButton
      mat-button
      type="button"
      class="pnd-refresh-notification-action"
      (click)="onRefresh($event)"
    >
      Refresh
    </button>
    <div class="metrics-overview">
      <ng-container *ngFor="let metric of metrics; trackBy: trackMetricBy">
        <div class="metric-overview-item" *ngIf="metricsForOverview.includes(metric.valueKeyCd) && metric.variance">
          <div class="metric-title">
            <xpo-icon
              *ngIf="isSpecialServiceMetric(metric.valueKeyCd)"
              class="specialServices-svg"
              svgIconName="{{ getSpecialServiceIcon(metric.valueKeyCd) }}"
            ></xpo-icon>

            <span> {{ getOverviewDescription(metric) }} </span>
          </div>
          <div class="mertic-overview-desc">
            <mat-icon
              *ngIf="!isSpecialServiceMetric(metric.valueKeyCd)"
              class="pnd-metrics-item-variance-icon"
              [ngClass]="getVarianceClassColor(metric.variance)"
            >
              {{ getVarianceIcon(metric.variance) }}
            </mat-icon>
            <span>{{ metric.varianceDesc }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel-header>
  <pnd-carousel class="pnd-metrics" [items]="metrics || skeletonMetrics" [$item]="itemTemplate">
    <ng-template #itemTemplate let-item let-i="index">
      <pnd-metric-bar-card
        (click)="onMetricClick(item)"
        [metric]="item"
        *ngIf="!displaySkeletonViews; else skeletonView"
      ></pnd-metric-bar-card>
      <ng-template #skeletonView>
        <pnd-metric-bar-skeleton-card></pnd-metric-bar-skeleton-card>
      </ng-template>
    </ng-template>
  </pnd-carousel>
</mat-expansion-panel>
