import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStoreState } from '@pnd-store/index';
import { PndStore } from '@pnd-store/pnd-store';
import {
  CityOperationsApiService,
  ListPnDInboundSelectionProfilesQuery,
  ListPnDInboundSelectionProfilesResp,
  SelectionProfile,
} from '@xpo-ltl/sdk-cityoperations';

import { map as _map } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { NotificationMessageStatus } from '../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../core/services/notification-message.service';
import { PndAppUtils } from '../../../shared/app-utils';
import { globalFilterSic } from '../global-filters-store/global-filters-store.selectors';
import { PlanningProfileInterface } from './planning-profile.interface';
import {
  ActionTypes,
  LoadPlanningProfilesAction,
  SetPlanningProfilesStoreAction,
} from './planning-profiles-store.actions';

@Injectable()
export class PlanningProfilesStoreEffects {
  constructor(
    private actions$: Actions,
    private cityOperationsService: CityOperationsApiService,
    private store$: PndStore<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService
  ) {}

  @Effect()
  loadPlanningProfiles$: Observable<Action> = this.actions$.pipe(
    ofType<LoadPlanningProfilesAction>(ActionTypes.loadPlanningProfiles),
    withLatestFrom(this.store$.select(globalFilterSic)),
    switchMap(([_, sic]: [Action, string]) => {
      return this.loadPnDInboundSelectionProfiles$(sic);
    }),
    switchMap((response: ListPnDInboundSelectionProfilesResp) => {
      const planningProfiles = _map(response?.selectionProfiles ?? [], (selectionProfile: SelectionProfile) => {
        return <PlanningProfileInterface>{
          profileId: selectionProfile.selectionProfileId,
          profileName: selectionProfile.profileName,
        };
      });
      return [new SetPlanningProfilesStoreAction({ planningProfiles: planningProfiles })];
    })
  );

  /// Utility methods

  private loadPnDInboundSelectionProfiles$(sicCd: string): Observable<ListPnDInboundSelectionProfilesResp> {
    const queryParams = { ...new ListPnDInboundSelectionProfilesQuery(), sicCd };
    return this.cityOperationsService.listPnDInboundSelectionProfiles(queryParams).pipe(
      PndAppUtils.retry(5, 200, 50),
      catchError((err) => {
        const parsedErrorMessage: string = `Error fetching planning profiles: ${this.notificationMessageService.parseErrorMessage(
          err
        )}`;
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, parsedErrorMessage)
          .pipe(take(1))
          .subscribe();

        return of({ ...new ListPnDInboundSelectionProfilesResp(), selectionProfiles: [] });
      })
    );
  }
}
