<div class="pnd-DockRoutesGridRenderer">
  <ng-container>
    <ng-container *ngTemplateOutlet="lineItems"></ng-container>
  </ng-container>
</div>

<ng-template #lineItems>
  <div
      class="pnd-DockRoutesGridRenderer__lineItems"
      [style.marginTop.px]="detailGridMarginTop"
      [style.marginBottom.px]="detailGridMarginBottom">
    <ng-container [ngSwitch]="activityCd">
      <ng-container *ngSwitchCase="ActivityCd.PICKUP">
        <ng-container *ngTemplateOutlet="pickupGrid"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ActivityCd.DELIVERY">
        <ng-container *ngTemplateOutlet="deliveryGrid"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #pickupGrid>
  <ag-grid-angular
      [columnDefs]="pickupItemsColDef"
      [rowData]="lineItemsRowData"
      [gridOptions]="lineItemsGridOptions"
      [rowHeight]="getCurrentRowHeight()"
      domLayout="autoHeight"
      rowSelection="multiple">
  </ag-grid-angular>
</ng-template>

<ng-template #deliveryGrid>
  <ag-grid-angular
      [columnDefs]="deliveryItemsColDef"
      [rowData]="lineItemsRowData"
      [gridOptions]="lineItemsGridOptions"
      [rowHeight]="getCurrentRowHeight()"
      domLayout="autoHeight"
      rowSelection="multiple">
  </ag-grid-angular>
</ng-template>
