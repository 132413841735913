import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'pnd-checkmark-header-renderer',
  templateUrl: 'checkmark-header-renderer.component.html',
  styleUrls: ['checkmark-header-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckmarkHeaderRendererComponent implements IHeaderAngularComp {
  constructor() {}

  agInit(params: IHeaderParams): void {}

  refresh(params: IHeaderParams): boolean {
    this.agInit(params);
    return true;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}
}
