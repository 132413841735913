import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isNumber as _isNumber, isNil as _isNil } from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface ITemplateData {
  value: unknown;
  isValidValue: boolean;
  isValueAPositiveNumber: boolean;
}
@Component({
  selector: 'pnd-notes-count-cell-renderer',
  templateUrl: './notes-count-cell-renderer.component.html',
  styleUrls: ['./notes-count-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesCountCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  private paramsSubject = new BehaviorSubject<ICellRendererParams>(undefined);
  readonly params$ = this.paramsSubject.asObservable();
  templateData$: Observable<ITemplateData> = this.params$.pipe(map((params) => this.getTemplateData(params?.value)));

  isPositiveNumber(value: unknown): boolean {
    return _isNumber(value) && value > 0;
  }

  getTemplateData(value: unknown): ITemplateData {
    const isValidValue = !_isNil(value);
    const isValueAPositiveNumber = this.isPositiveNumber(value);

    return {
      value,
      isValidValue,
      isValueAPositiveNumber,
    };
  }

  agInit(params: ICellRendererParams): void {
    this.paramsSubject.next(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.paramsSubject.next(params);
    return true;
  }
}
