<div class="pnd-actionsCellRenderer" *ngIf="!shouldHide">
  <ng-container *ngIf="stopExceptionInd">
    <xpo-icon
      [xpoPopoverTriggerFor]="popover"
      iconName="priority-high"
      class="pnd-actionsCellRenderer__exceptionIcon"
    ></xpo-icon>
    <xpo-popover #popover="xpoPopover" triggerOn="hover" position="right" color="primary" caretPosition="center">
      <xpo-popover-content>
        <div>
          {{ 'Failed Incomplete Stops' }}
        </div>
      </xpo-popover-content>
    </xpo-popover>
  </ng-container>
  <ng-container *ngIf="actions && actions.length > 0">
    <ng-container *ngFor="let action of actions;trackBy:trackActionsBy">
      <button
        mat-icon-button
        class="pnd-actionsCellRenderer__button"
        (click)="stopEventPropagation($event); action.onClick(data)"
        [matTooltip]="action?.tooltip"
        matTooltipPosition="above"
      >
        <mat-icon class="pnd-actionsCellRenderer__icon">{{ action.iconName }}</mat-icon>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="menus && menus.length > 0">
    <ng-container *ngFor="let menu of menus;trackBy:trackMenuBy">
      <ng-container *ngIf="!menu.shouldHide || !(menu.shouldHide(data) | async)">
        <button
          mat-icon-button
          class="pnd-actionsCellRenderer__button"
          matTooltipPosition="above"
          [matTooltip]="menu?.tooltip"
          [matMenuTriggerFor]="optionsMenu"
          (click)="stopEventPropagation($event)"
        >
          <mat-icon class="pnd-actionsCellRenderer__icon">{{ menu.iconName }}</mat-icon>
        </button>

        <mat-menu #optionsMenu="matMenu" class="xpo-Popover-panel">
          <ng-container *ngFor="let option of menu.options;trackBy:trackOptionsBy">
            <button
              *ngIf="!(option.shouldHide(data) | async)"
              mat-menu-item
              (click)="option.onClick(data, gridApi, rowIndex, rowNode, $event)"
            >
              {{ option.text || option.getText(data) }}
              <ng-container *ngIf="option?.icon?.shouldHide">
                <xpo-icon
                  *ngIf="!(option?.icon.shouldHide(data) | async)"
                  iconName="{{option.icon.name}}"
                  class="{{option.icon.name}}"
                ></xpo-icon>
              </ng-container>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
