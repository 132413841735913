<div class="pnd-GridDetailsNotesRenderComponent">
  <div class="pnd-GridDetailsNotesRenderComponent__header">
    <p class="pnd-GridDetailsNotesRenderComponent__header__title">NOTES AND COMMENTS</p>
  </div>
  <div class="pnd-GridDetailsNotesRenderComponent__content">
    <ng-container *ngFor="let stop of stopsWindows; trackBy: trackStopBy">
      <div
        class="pnd-GridDetailsNotesRenderComponent__content__note"
        *ngFor="let stopNote of stop.notes; trackBy: trackNotesBy"
      >
        <p class="pnd-GridDetailsNotesRenderComponent__content__note__title">
          {{ stopNote.type | stopWindowNote
          }}<a
            class="pnd-GridDetailsNotesRenderComponent__content__note__title-action"
            xpo-regular-link
            ngxClipboard
            [cbContent]="stopNote.note"
            >Copy</a
          >
        </p>
        <p class="pnd-GridDetailsNotesRenderComponent__content__note__text">
          {{ stopNote.note }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="notes">
      <div class="pnd-GridDetailsNotesRenderComponent__content__note" *ngFor="let note of notes; trackBy: trackNotesBy">
        <p class="pnd-GridDetailsNotesRenderComponent__content__note__title">
          {{ note.title }}
          <a
            class="pnd-GridDetailsNotesRenderComponent__content__note__title-action"
            xpo-regular-link
            ngxClipboard
            [cbContent]="noteValue.innerText"
            >Copy</a
          >
        </p>
        <p #noteValue class="pnd-GridDetailsNotesRenderComponent__content__note__text" [innerHTML]="note.value"></p>
      </div>
    </ng-container>
  </div>
</div>
