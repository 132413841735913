<xpo-ltl-dialog-title>Stop View - {{ name$ | async }}</xpo-ltl-dialog-title>

<div mat-dialog-content class="pnd-StopMap-content">
  <ng-container *ngIf="!(location$ | async)">
    <div class="loading-box"></div>
  </ng-container>
  <ng-container *ngIf="location$ | async as location">
    <agm-map
        [latitude]="location?.lat"
        [longitude]="location?.lng"
        [zoom]="zoom$ | async"
        mapTypeControl="true"
        (mapReady)="mapReady($event)">
      <ng-container *ngIf="shouldShowMarkers">
        <agm-marker [latitude]="location?.lat" [longitude]="location?.lng" [zIndex]="4"> </agm-marker>
      </ng-container>
    </agm-map>
  </ng-container>
</div>
