import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import { filter as _filter, some as _some } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, concatMap, concatMapTo, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { DispatcherTripsService } from '../../inbound-planning/shared/services/dispatcher-trips.service';
import * as PndStoreState from '../pnd-store.state';
import { ActionTypes, Refresh, SetLastUpdate, SetSearchCriteria, SetSelected } from './dispatcher-trips-store.actions';
import { searchCriteria, selectedTrips } from './dispatcher-trips-store.selectors';

@Injectable()
export class DispatcherTripsStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: PndStore<PndStoreState.State>,
    private dispatchTripsService: DispatcherTripsService
  ) {}

  /**
   * Trigger a Refresh when the Search Criteria is changed
   */
  setSearchCriteria$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria), concatMapTo([new Refresh()]))
  );

  /**
   * Refresh the Dispatch Trip data, remove non-existing trips from selection, and update lastUpdated time
   */
  refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Refresh>(ActionTypes.refresh),
      concatMap(() => this.store$.select(searchCriteria).pipe(take(1))),
      switchMap((criteria) => this.dispatchTripsService.fetchTrips$(criteria)), // cancel out fetchTrips call if another refresh action is fired rapidly
      withLatestFrom(this.store$.select(selectedTrips)),
      concatMap(([dispatchTrips, currentSelectedTrips]) => {
        // remove selected trips that no longer exist
        const selTrips = _filter([...currentSelectedTrips], (curSelTrip: number) => {
          return _some(dispatchTrips, (trip) => curSelTrip === trip.tripInstId);
        });

        return [
          new SetLastUpdate({ dispatcherTripsLastUpdate: new Date() }),
          new SetSelected({ selectedDispatcherTripsIds: selTrips }),
        ];
      }),
      catchError(() => {
        // error loading trips, so clear existing ones
        return [
          new SetLastUpdate({ dispatcherTripsLastUpdate: new Date() }),
          new SetSelected({ selectedDispatcherTripsIds: [] }),
        ];
      })
    )
  );
}
