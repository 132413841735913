import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoLtlDriverContact } from '@xpo-ltl/ngx-ltl-messaging';
import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { LastStopStatusCd } from 'app/inbound-planning/components/dispatcher-trips/enums/last-stop-status-cd.enum';
import { PndRouteUtils } from '../../../../shared/route-utils';
import { ActivityTypePipe } from '../pipes';

export interface DriverRouteInfo {
  id: string;
  feetAvailable: number;
  routeInstId: number;
}

export class DriverMapMarkerInfo {
  readonly driverId: string;
  readonly driverName: string;
  readonly driverDeviceId: string;
  readonly driverTerminalSic: string;
  readonly phone: string;
  readonly lastUpdated: string;
  readonly remainingStops: string;
  readonly remainingPickupStops: string;
  readonly remainingDeliveryStops: string;
  readonly completedPickupStops: string;
  readonly completedDeliveryStops: string;
  readonly lastStopType: string;
  readonly alerts: string[];
  readonly timeOfCompletion: string;
  readonly speed: string;
  readonly routes: DriverRouteInfo[];
  readonly tripInstId: number;
  readonly routeInstId: number;
  readonly estimatedClearTime: string;
  readonly equipment: string;
  readonly lastStopName: string;
  readonly nextStopName: string;
  readonly lastStopStatus: string;
  readonly devicePhoneNbr: string;

  currentRoute: DriverRouteInfo;

  driverContactInfo: XpoLtlDriverContact;

  get unreadMessages(): boolean {
    return this?.driverContactInfo?.unreadMessages > 0 ?? false;
  }

  constructor(
    driverLocation: DriverLocation,
    timeService: XpoLtlTimeService,
    activityTypePipe: ActivityTypePipe,
    alerts: string[]
  ) {
    this.lastStopName = driverLocation?.routeStop?.lastStopName || '';
    this.lastStopStatus = driverLocation?.routeStop?.lastStopStatus || '';
    this.nextStopName = driverLocation?.routeStop?.nextStopName || '';
    this.driverId = driverLocation?.tripDriver?.dsrEmployeeId || driverLocation?.currentLocation?.employeeId;
    this.driverName = driverLocation?.tripDriver?.dsrName || '';
    this.phone = driverLocation?.employeeMobileDevice?.devicePhoneNbr || '';
    this.lastStopType = activityTypePipe.transform(driverLocation?.trip?.lastStopTypeCd || 'dl');
    this.alerts = alerts;
    this.remainingStops = (
      (driverLocation?.trip?.stopCount || 0) - (driverLocation?.trip?.stopCompletedCount || 0)
    ).toString();
    this.remainingDeliveryStops = (
      (driverLocation?.trip?.deliveryStopCount || 0) - (driverLocation?.trip?.deliveryCompletedCount || 0)
    ).toString();
    this.remainingPickupStops = (
      (driverLocation?.trip?.pickupStopCount || 0) - (driverLocation?.trip?.pickupCompletedCount || 0)
    ).toString();
    this.completedDeliveryStops = (driverLocation?.trip?.deliveryCompletedCount || '').toString();
    this.completedPickupStops = (driverLocation?.trip?.pickupCompletedCount || '').toString();
    this.speed = driverLocation?.currentLocation?.speedNbr || '';
    this.tripInstId = driverLocation?.trip?.tripInstId;
    this.devicePhoneNbr = driverLocation?.employeeMobileDevice?.devicePhoneNbr;

    let lastStopDateTime: number | Date = null;
    if (this.lastStopStatus === LastStopStatusCd.ARRIVED) {
      lastStopDateTime = driverLocation?.routeStop?.lastStopDateTime || driverLocation?.trip?.lastStopArrivalDateTime;
    } else if (this.lastStopStatus === LastStopStatusCd.DEPARTED) {
      lastStopDateTime = driverLocation?.routeStop?.lastStopDateTime || driverLocation?.trip?.lastStopDateTime;
    }
    if (lastStopDateTime && lastStopDateTime > 0) {
      this.timeOfCompletion = timeService.formatDate(
        lastStopDateTime,
        'LT',
        driverLocation?.currentLocation?.domicileSic || ''
      );
    }

    const eventDateTime = driverLocation?.currentLocation?.eventDateTimeUtc;
    if (eventDateTime) {
      this.lastUpdated = timeService.formatDate(
        eventDateTime,
        'MMM DD, y, HH:mm:ss A',
        driverLocation?.currentLocation?.domicileSic || ''
      );
    }

    const clearTime = driverLocation?.trip?.estimatedEmptyDateTime;
    if (clearTime) {
      this.estimatedClearTime = timeService.formatDate(
        clearTime,
        'HH:mm',
        driverLocation?.currentLocation?.domicileSic || ''
      );
    }

    const routesToShow: DriverRouteInfo[] = [];
    if (driverLocation.tractors && driverLocation.tripDriver) {
      for (const tractor of driverLocation.tractors) {
        if (tractor.equipmentTypeCd === 'P' || tractor.equipmentTypeCd === 'Z') {
          this.equipment = tractor?.equipmentIdPrefix + '-' + tractor?.equipmentIdSuffixNbr;
        }
      }
    }

    if (driverLocation.routeDetails && driverLocation.tripDriver) {
      for (const routeDetails of driverLocation.routeDetails) {
        const tripEquipment = routeDetails?.trailer?.tripEquipment;
        if (tripEquipment?.equipmentTypeCd === 'P' || tripEquipment?.equipmentTypeCd === 'Z') {
          this.equipment = tripEquipment?.equipmentIdPrefix + '-' + tripEquipment?.equipmentIdSuffixNbr;
        }
        routesToShow.push({
          id: PndRouteUtils.getRouteId(routeDetails?.route),
          feetAvailable: tripEquipment?.lengthUOMFeetNbr,
          routeInstId: routeDetails?.route?.routeInstId,
        });
      }
    }

    this.routes = routesToShow;
  }

  /**
   * Find current route with the driverLocation information and populates the currentRoute property
   * @param driverLocation
   */
  setCurrentRoute(driverLocation: DriverLocation): void {
    this.currentRoute = this.routes.find((route) => route.routeInstId === driverLocation?.routeStop?.routeInstId);
  }
}
