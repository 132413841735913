import { Action } from '@ngrx/store';
import { EventItem, RouteItemIdentifier } from 'app/inbound-planning/shared';
import { SpotsAndDropsSearchCriteria } from './spots-and-drops-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'spotsAndDrops.setSearchCriteria',
  setLastUpdate = 'spotsAndDrops.setLastUpdate',
  refresh = 'spotsAndDrops.refresh',
  setSelected = 'spotsAndDrops.setSelected',
  setSelectedTrip = 'spotsAndDrops.setSelectedTrip',
  setFocusedSpotAndDrop = 'spotsAndDrops.setFocusedSpotAndDrop',
}

export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: SpotsAndDropsSearchCriteria }) {}
}

export class SetSelected implements Action {
  readonly type = ActionTypes.setSelected;
  constructor(public payload: { selectedSpotsAndDropsTripsIds: number[] }) {}
}

export class SetSelectedTrip implements Action {
  readonly type = ActionTypes.setSelectedTrip;
  constructor(public payload: { selectedSpotsAndDropsTripId: number }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { spotsAndDropsLastUpdate: Date }) {}
}

export class SetFocusedSpotAndDrop implements Action {
  readonly type = ActionTypes.setFocusedSpotAndDrop;
  constructor(public payload: { focusedSpotAndDrop: EventItem<RouteItemIdentifier> }) {}
}

export type Actions =
  | SetSearchCriteria
  | SetSelected
  | SetSelectedTrip
  | Refresh
  | SetLastUpdate
  | SetFocusedSpotAndDrop;
