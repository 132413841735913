import { DeltaSignCd, DeltaVarianceTypeCd, MetricVarianceCd } from '@xpo-ltl/sdk-common';

export class MetricsUtils {
  static getVarianceIcon(variance: MetricVarianceCd): string {
    switch (variance) {
      case MetricVarianceCd.ABOVE: {
        return 'arrow_upward';
      }
      case MetricVarianceCd.BELOW: {
        return 'arrow_downward';
      }
      default: {
        return 'remove';
      }
    }
  }

  static getDeltaSignIcon(sign: DeltaSignCd): string {
    if (sign === DeltaSignCd.POSITIVE) {
      return 'arrow_upward';
    } else {
      return 'arrow_downward';
    }
  }

  static getVarianceClassColor(variance: MetricVarianceCd): string {
    switch (variance) {
      case MetricVarianceCd.ABOVE: {
        return 'variance-above';
      }
      case MetricVarianceCd.BELOW: {
        return 'variance-below';
      }
      default: {
        return 'variance-neutral';
      }
    }
  }

  static getDeltaVarianceClassColor(variance: DeltaVarianceTypeCd): string {
    switch (variance) {
      case DeltaVarianceTypeCd.GOOD: {
        return 'variance-above';
      }
      case DeltaVarianceTypeCd.BAD: {
        return 'variance-below';
      }
      default: {
        return 'variance-neutral';
      }
    }
  }
}
