import { AssignShipmentsSplitPanelParams } from '../../../assign-shipments/models/assign-shipments-split-panel-params.model';
import { CustomerProfileSplitPanelParams } from '../../../planning-map-splitter/customer-profile-split-panel-params.model';

export interface MapSplitPanelItemData {
  title: string;
  subtitle?: string;
  id: string;
  expanded: boolean;
  orderIndex: number; // used to set the position of the Split Panel Item (first, second, etc.), in relation with other Split Panel Items

  params?: AssignShipmentsSplitPanelParams | CustomerProfileSplitPanelParams | any;
}

export enum SplitPanelComponentType {
  AssignShipments,
  AssignPickups,
  CustomerProfile,
}

export class MapSplitPanelItem {
  constructor(public component: SplitPanelComponentType, public data: MapSplitPanelItemData) {}
}
