import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ZeroAsCellRendererParams extends ICellRendererParams {
  displayZeroAs?: number | string;
  isTotalsRow: boolean;
}

@Component({
  selector: 'app-zero-as-cell-renderer',
  templateUrl: './zero-as-cell-renderer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZeroAsCellRendererComponent implements ICellRendererAngularComp {
  displayZeroAs: number | string;
  isTotalsRow?: boolean;
  paramValue: number | string;
  agInit(params: ZeroAsCellRendererParams): void {
    this.displayZeroAs = params?.displayZeroAs ?? '';
    this.paramValue = params?.value ?? params?.valueFormatted ?? ' ';
    this.paramValue = this.paramValue === '' ? 0 : this.paramValue;
    this.isTotalsRow = params?.isTotalsRow;
  }

  refresh(params: ZeroAsCellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
}
