import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-board/ag-grid';
import { XpoBoardViewDataStoreBase, XpoGridBoardViewConfig, XpoSortExpression } from '@xpo-ltl/ngx-board/core';
import { GenericErrorLazyTypedModel } from 'app/inbound-planning/shared/models/generic-error-lazy-typed.model';
import { NotificationMessageService } from 'core';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import { chain as _chain } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserPreferencesService } from '../../app/inbound-planning/shared/services/user-preferences.service';

export interface XpoGridBoardVisibleColumn {
  colId?: string;
  headerName: string;
  width: number;
  pinned?: string;
  children?: any[];
  rowGroup?: boolean; // NGXLTL-1147: regarding an edge case where some child is the owner of a row group definition
  groupId?: string;
  field?: string;
  hide?: boolean;
}

export abstract class PnDViewDataStoreBase extends XpoBoardViewDataStoreBase {
  protected abstract readonly defaultViews: XpoAgGridBoardViewConfig[];
  protected readonly defaultSortOrder: XpoSortExpression[] = [];

  // https://xpo.atlassian.net/browse/PCT-12978 at the time of this ticket, headerNames are primary
  // key when loading board views, if you update you need to store an old/new value map
  // Will be changing the board to use field as a key as well https://xpo.atlassian.net/browse/NGXLTL-1030
  protected readonly gridHeadedNameUpdateMap: { [key: string]: string } = {};

  constructor(
    protected userPreferencesService: UserPreferencesService,
    protected notificationMessageService: NotificationMessageService,
    protected preferences: XpoAgGridBoardViewConfig[],
    protected componentName: string
  ) {
    super();
  }

  getAll(): Observable<XpoAgGridBoardViewConfig[]> {
    if (this.preferences?.length === 0) {
      return of(this.defaultViews);
    } else {
      return of(this.loadViewsFromUserPreferences());
    }
  }

  protected loadViewsFromUserPreferences(): XpoAgGridBoardViewConfig[] {
    const viewArray: XpoAgGridBoardViewConfig[] =
      this.preferences?.map((element: XpoAgGridBoardViewConfig) => ({
        ...element,
        systemDefined: !!element.systemDefined,
        children: element.children ?? [],
        sortOrder: element.sortOrder?.length > 0 ? element.sortOrder : this.defaultSortOrder,
        templateId: element.templateId ?? '',
        rowHeight: element.rowHeight ?? undefined,
        colInfo: element.colInfo ?? undefined,
      })) ?? [];

    viewArray.push(...this.defaultViews);

    // make sure system defined views are at start of array so that default view shows on load
    const boardViewConfigs: XpoAgGridBoardViewConfig[] = _chain(viewArray)
      .uniqBy((v: XpoGridBoardViewConfig) => v.id)
      .sortBy((v: XpoGridBoardViewConfig) => !v.systemDefined)
      .valueOf();

    boardViewConfigs?.forEach((config: XpoAgGridBoardViewConfig) => {
      config.name = config.name === 'New' ? 'Show All' : config.name;

      // some header names have changed since originally implemented so we need to map them to the current
      // defined value if they exist in visibleColumns
      if (config.name !== 'Show All') {
        config.visibleColumns = config.visibleColumns?.map((colDef: string) => {
          const headerName: string =
            ((colDef as unknown) as XpoGridBoardVisibleColumn)?.headerName ?? (colDef as string);
          let mappedColDef: string | XpoGridBoardVisibleColumn = colDef;
          if (headerName) {
            mappedColDef = headerName;
          }
          return mappedColDef;
        });
      } else {
        config.visibleColumns = [];
        config.colInfo = [];
      }
    });

    return boardViewConfigs;
  }

  updateDataStore(viewConfigs: XpoAgGridBoardViewConfig[]): Observable<XpoAgGridBoardViewConfig[]> {
    return this.userPreferencesService
      .updatePreferencesFor<XpoAgGridBoardViewConfig[]>(this.componentName, viewConfigs)
      .pipe(
        catchError((err: GenericErrorLazyTypedModel) => {
          const message: string = this.notificationMessageService.parseErrorMessage(err);

          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, `Unable to update views. ${message}`)
            .subscribe(() => {});

          return throwError(err);
        })
      );
  }
}
