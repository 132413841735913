import { Injectable } from '@angular/core';
import { SetPastDueShipmentsLastUpdate } from '@pnd-store/past-due-shipments-store/past-due-shipments-store.actions';
import { PndStore } from '@pnd-store/pnd-store';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchFilter,
  DeliveryShipmentSearchRecord,
  ListPnDUnassignedStopsRqst,
  Route,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { MovementStatusCd, XrtAttributeFilter, XrtSearchQueryHeader } from '@xpo-ltl/sdk-common';
import { PastDueShipmentsGridItem } from 'app/inbound-planning/components/past-due-shipments';
import { forEach as _forEach, pick as _pick, size as _size } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PndRouteUtils } from 'shared/route-utils';
import { PndXrtService } from '../../../../core/services/pnd-xrt.service';
import { PndStoreState } from '../../../store';
import { PlanningRouteDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/planning-route-deliveries-search-criteria.interface';
import { routeStopToId } from '../interfaces/event-item.interface';
import { InterfaceAcctNamePipe } from '../pipes';

/**
 * Provides cache of large Data types instead of storing them in the Store
 */
@Injectable({
  providedIn: 'root',
})
export class PastDueShipmentsCacheService {
  private pastDueShipments = new Map<number, DeliveryShipmentSearchRecord>();

  private readonly pastDueShipmentsSubject = new BehaviorSubject<PastDueShipmentsGridItem[]>([]);
  pastDueShipments$ = this.pastDueShipmentsSubject.asObservable();
  private loadingPastDueShipmentsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingPastDueShipments$ = this.loadingPastDueShipmentsSubject.asObservable();
  private showDispatchedSubject = new BehaviorSubject<boolean>(true);
  readonly showDispatched$ = this.showDispatchedSubject.asObservable();
  constructor(
    protected pndStore$: PndStore<PndStoreState.State>,
    private cityOperationsApi: CityOperationsApiService,
    private pndXrtService: PndXrtService,
    protected interfaceAcctNamePipe: InterfaceAcctNamePipe
  ) {}

  get showDispatched(): boolean {
    return this.showDispatchedSubject.value;
  }

  toggleShowDispatched(value: boolean) {
    this.showDispatchedSubject.next(value);
  }

  searchPastDueShipments(criteria: PlanningRouteDeliveriesSearchCriteria): Observable<void> {
    return new Observable<void>((observer) => {
      this.loadingPastDueShipmentsSubject.next(true);

      const header: XrtSearchQueryHeader = {
        fields: [],
        pageNumber: 1,
        pageSize: 10000,
        sortExpressions: [],
        excludeFields: [],
      };

      const listPnDUnassignedStopsRqst: ListPnDUnassignedStopsRqst = {
        header: header,
        filter: {
          ...new DeliveryShipmentSearchFilter(),
          hostDestSicCd: this.pndXrtService.toXrtFilterEquals(criteria.hostDestSicCd),
          serviceDateTime: {
            ...new XrtAttributeFilter(),
            equals: criteria.Q || '',
          },
          onExcludedTrailerInd: {
            ...new XrtAttributeFilter(),
            equals: 'false',
          },
        },
        unmappedInd: false,
        planByTrailerInd: false,
        pastDueShipmentsInd: true,
        unbilledInd: false,
      };

      this.cityOperationsApi
        .listPnDUnassignedStops(listPnDUnassignedStopsRqst)
        .pipe(take(1))
        .subscribe(
          (response) => {
            let stops: UnassignedStop[];
            if (!this.showDispatched) {
              stops = this.filterOutForDeliveryShipments(response.unassignedStops);
              // since there's a lot of data, we need to double check for shipments on MovementStatusCd.OUT_FOR_DLVRY
              if (
                stops.find((stop) =>
                  stop.deliveryShipments.find(
                    (shipment) => shipment.movementStatusCd === MovementStatusCd.OUT_FOR_DLVRY
                  )
                )
              ) {
                stops = this.filterOutForDeliveryShipments(stops);
              }
            } else {
              stops = response.unassignedStops;
            }
            this.setPastDueShipments(stops);
            const items: PastDueShipmentsGridItem[] = [];
            stops.forEach((unassignedStops: UnassignedStop) => {
              items.push(this.transformStopsToGridItems(unassignedStops));
            });
            this.pastDueShipmentsSubject.next(items);
            this.loadingPastDueShipmentsSubject.next(false);
            this.pndStore$.dispatch(new SetPastDueShipmentsLastUpdate({ pastDueShipmentsLastUpdate: new Date() }));
            observer.next();
            observer.complete();
          },
          (error) => {
            this.loadingPastDueShipmentsSubject.next(false);
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  private transformStopsToGridItems(pastDueStop: UnassignedStop): PastDueShipmentsGridItem {
    const route = {
      ...new Route(),
      routeInstId: pastDueStop.deliveryShipments[0]?.routeInstId,
      routePrefix: pastDueStop.deliveryShipments[0]?.routePrefix,
      routeSuffix: pastDueStop.deliveryShipments[0]?.routeSuffix,
      routeTypeCd: pastDueStop.deliveryShipments[0]?.routeTypeCd,
      routeLstUpdtTmst: pastDueStop.deliveryShipments[0]?.routeLstUpdtTmst,
      terminalSicCd: pastDueStop.shipmentLocationSicCd,
    } as Route;

    const routeId = PndRouteUtils.getRouteId(route);

    const item = <PastDueShipmentsGridItem>{
      routeIdWithTerminalSicCd: `${routeId ? routeId : Date.now().toString()} - ${route?.terminalSicCd}`,
      routeId: routeId,
      routeInstId: route?.routeInstId ? route.routeInstId : Date.now().toString(),
      consignee: pastDueStop.consignee,
      totalShipmentsCount: pastDueStop.totalShipmentsCount,
      totalWeightLbs: pastDueStop.totalWeightLbs,
      motorizedPiecesCount: pastDueStop.motorizedPiecesCount,
      loosePcsCnt: pastDueStop.loosePcsCnt,
      totalCubePercentage: pastDueStop.totalCubePercentage,
      footprintPercentage: pastDueStop.footprintPercentage,
      currentSicCd: pastDueStop.currentSicCd,
      serviceDateMessage: pastDueStop.serviceDateMessage,
      deliveryQualifiers: pastDueStop.deliveryQualifiers,
      specialServiceSummary: pastDueStop.specialServiceSummary,
      deliveryShipments: pastDueStop.deliveryShipments,
      acctInstId: pastDueStop.consignee.acctInstId,
      stopWindow: pastDueStop.stopWindow,
      uniqId: routeStopToId({
        routeInstId: route?.routeInstId ? route.routeInstId : Date.now(),
        consignee: _pick(pastDueStop.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
      }),
      searchableDetailRows: '',
      appointmentStatusCd: pastDueStop.appointmentStatusCd,
      fbdsPrintCountCd: pastDueStop.fbdsPrintCountCd,
    };

    item.handlingUnitPartialInd = false;
    item.handlingUnitSplitInd = false;

    if (_size(pastDueStop.deliveryShipments) > 0) {
      item.searchableDetailRows = pastDueStop.deliveryShipments
        .map((shipment) => {
          item.handlingUnitPartialInd = item.handlingUnitPartialInd || !!shipment.handlingUnitPartialInd;
          item.handlingUnitSplitInd = item.handlingUnitSplitInd || !!shipment.handlingUnitSplitInd;

          // Add searchable fields separated by a space
          let searchableText: string = `${shipment.proNbr}`;
          const shipperName = this.interfaceAcctNamePipe.transform(shipment?.shipper);
          const shipperMadCd = shipment?.shipper?.acctMadCd;

          if (shipperName) {
            searchableText += ',' + shipperName;
          }

          if (shipperMadCd) {
            searchableText += ',' + shipperMadCd;
          }

          return searchableText;
        })
        .join(' ');
    }
    return item;
  }

  setPastDueShipments(stops: UnassignedStop[]) {
    this.pastDueShipments.clear();
    _forEach(stops, (stop) => {
      _forEach(stop.deliveryShipments, (shipment) => {
        this.pastDueShipments.set(shipment.shipmentInstId, shipment);
      });
    });
  }

  getAllPastDueShipments(): DeliveryShipmentSearchRecord[] {
    const pastDueShipments = Array.from(this.pastDueShipments.values());
    return pastDueShipments;
  }

  getPastDueShipmentsIds(): number[] {
    return Array.from(this.pastDueShipments.keys());
  }

  getPastDueShipmentSearchRecord(shipmentInstId: number): DeliveryShipmentSearchRecord {
    return this.pastDueShipments.get(shipmentInstId);
  }

  filterOutForDeliveryShipments(unassignedStops: UnassignedStop[]): UnassignedStop[] {
    const filteredStops = unassignedStops.filter((stops) =>
      stops.deliveryShipments.find((shipment) => shipment.movementStatusCd !== MovementStatusCd.OUT_FOR_DLVRY)
    );
    filteredStops.forEach((unassignedStop) => {
      unassignedStop.deliveryShipments.forEach((shipment, index) => {
        if (shipment.movementStatusCd === MovementStatusCd.OUT_FOR_DLVRY) {
          unassignedStop.deliveryShipments.splice(index, 1);
        }
      });
    });
    return filteredStops;
  }
}
