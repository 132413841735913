import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './past-due-shipments-store.state';

export const pastDueShipmentsState = createFeatureSelector<State>(StateKey);

export const selectedPastDueShipments = createSelector(
  pastDueShipmentsState,
  (state: State) => state.selectedPastDueShipments
);

export const pastDueShipmentsLastUpdate = createSelector(
  pastDueShipmentsState,
  (state: State) => state.pastDueShipmentsLastUpdate
);
