import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { StatusIndicatorCellRendererParams } from './status-indicator-cell-renderer-params.interface';

@Component({
  selector: 'pnd-status-indicator-cell-renderer',
  templateUrl: './status-indicator-cell-renderer.component.html',
  styleUrls: ['./status-indicator-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorCellRendererComponent implements ICellRendererAngularComp {
  statusText: string;
  statusColor: string;
  isLabel: boolean;

  constructor() {}

  agInit(params: StatusIndicatorCellRendererParams): void {
    this.statusText = params?.getStatusText(params);
    this.statusColor = params?.getStatusColor(params);
    this.isLabel = params?.isLabel ?? false;
  }

  refresh(): boolean {
    return false;
  }
}
