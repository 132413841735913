import { Route, Metric } from '@xpo-ltl/sdk-cityoperations';
import {
  TripStatusCd,
  ShipmentSpecialServiceCd,
  CarrierTenderGroupStatusCd,
  AuditInfo,
  CmsTripTypeCd,
} from '@xpo-ltl/sdk-common';

export class TripPlanningGridItem {
  keyField: string;
  tripDsrName: string;
  tripStopCount: number;
  tripInstId: number;
  estimatedDepartDateTime: Date;
  routeInstId: number;
  route: Route;
  metrics: Metric[];
  estimatedEmptyDateTime: Date;
  statusCd: TripStatusCd;
  specialServices: ShipmentSpecialServiceCd[];
  terminalSicCd: string;
  cmsCarrierId?: number;
  carrierTenderGroupStatusCd?: CarrierTenderGroupStatusCd;
  dsrEmployeeId?: string;
  carrierName?: string;
  tenderMessages?: string[];
  auditInfo?: AuditInfo;
  cmsTripTypeCd?: CmsTripTypeCd;
  totalShipmentCount?: number;
  totalLoadedShipmentCount?: number;
}
