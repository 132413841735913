import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { geoAreaReducer } from './geo-area-store.reducer';
import { StateKey } from './geo-area-store.state';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(StateKey, geoAreaReducer)],
})
export class GeoAreaStoreModule {}
