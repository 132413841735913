import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import { CityOperationsApiService } from '@xpo-ltl/sdk-cityoperations';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { PndStoreState } from '..';
import {
  ActionTypes,
  FetchDriversCurrentLocationAction,
  SetDriversCurrentLocationAction,
} from './driver-store.actions';

@Injectable()
export class DriverStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: PndStore<PndStoreState.State>,
    private cityOpsService: CityOperationsApiService
  ) {}

  @Effect()
  fetchDriversCurrentLocationForSelectedSic$: Observable<Action> = this.actions$.pipe(
    ofType<FetchDriversCurrentLocationAction>(ActionTypes.fetchDriversCurrentLocation),
    switchMap((action) => {
      return this.cityOpsService
        .listPnDDriverLocations(
          {
            sicCd: action.payload.sicCd,
          },
          {
            zoneIndicatorCd: action.payload.zoneIndicatorCd,
          }
        )
        .pipe(
          take(1),
          catchError(() => of(undefined))
        );
    }),
    catchError(() => of(undefined)),
    map((response) => {
      const driverLocations = response?.driverLocations ?? [];
      return new SetDriversCurrentLocationAction({ driversCurrentLocation: driverLocations });
    })
  );
}
