import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GRID_BOTTOM_DRAWER_HIDE_DELAY } from '../grid-bottom-drawer/grid-bottom-drawer.component';
import { SelectionSummaryData } from './selection-summary-data.class';

@Component({
  selector: 'pnd-selection-summary',
  templateUrl: './selection-summary.component.html',
  styleUrls: ['./selection-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionSummaryComponent {
  private summaryDisplaySubject: BehaviorSubject<SelectionSummaryData> = new BehaviorSubject(undefined);
  readonly summaryDisplay$: Observable<SelectionSummaryData> = this.summaryDisplaySubject.asObservable().pipe(
    switchMap((summary: SelectionSummaryData) => {
      // when no selection is made, grid bottom drawer uses delay before hiding summary component.
      // Adds delay if summary is undefined before unsetting
      return summary ? of(summary) : timer(GRID_BOTTOM_DRAWER_HIDE_DELAY).pipe(map(() => summary));
    })
  );

  @Input() dataTestPrefix = '';

  @Input() set summary(summary: SelectionSummaryData) {
    this.summaryDisplaySubject.next(summary);
  }
}
