export enum UnassignedDeliveriesGridFields {
  GROUP_NAME = 'gridGroupingName',
  ROW_SELECTED = 'rowSelected',
  BILLS = '*',
  NOTES = 'Notes',
  FBDS_PRINT_COUNT_CD = 'fbdsPrintCountCd',
  FBDS_PRINT_COUNT = 'fbdsPrintCount',
  OVERSIZED_FLAG = 'oversized',
  BOL = 'bolInstId',
  CONSIGNEE = 'consigneeName',
  ADDRESS = 'consigneeAddress',
  CITY = 'consigneeCity',
  AREA_NAME = 'areaName',
  ZIP_CODE = 'consignee.postalCd',
  PICKUP_DATE = 'pickupDate',
  SPECIAL_SERVICES = 'specialServiceSummary',
  WEIGHT = 'totalWeightLbs',
  MM = 'motorizedPiecesCount',
  LP = 'loosePcsCnt',
  CUBE = 'totalCubePercentage',
  DELIVERY_WINDOW_TYPE = 'deliveryWindowType',
  DELIVERY_WINDOW_TIME = 'deliveryWindowTime',
  DELIVERY_WINDOW_DATE = 'deliveryWindowDate',
  EARLIEST_ETA = 'earliestDestSicEta',
  LATEST_ETA = 'latestDestSicEta',
  PRO = 'proNbr',
  HANDLING_UNITS = 'HU',
  SERVICE_DATE = 'estimatedDeliveryDate',
  DESTINATION_ETA = 'destSicEta',
  DESTINATION_SIC = 'destinationSicCd',
  CURRENT_SIC = 'shipmentLocationSicCd',
  TRAILER = 'currentTrailer',
  TRAILER_SIC = 'trailerCurrSicCd',
  EXCEPTIONS = 'deliveryExceptions',
  SCHEDULED_ETA = 'scheduleETA',
  SCHEDULED_DESTINATION = 'scheduleDestinationSicCd',
  DELIVERY_QUALIFIERS = 'deliveryQualifiers',
  BILL_CLASS = 'billClass',
  PROFILE_ID = 'profileId',
  CURRENT_LOCATION = 'currentLocation',
  SHOW_EXCLUDED_TRAILER_SHIPMENTS = 'onExcludedTrailerInd',
  SHIPPER_NAME = 'shipperName',
  SHIPPER_MAD_CD = 'shipperMadCd',
  DOCK_NAME = 'dockName',
}
