<ng-container *ngIf="isLayerVisible$ | async">
  <ng-container *ngFor="let pickupCluster of pickupClusterPoints; trackBy: trackPickupClusterBy">
    <agm-circle
      [pnd-map-circle]="pickupCluster"
      (circleRightClickWithEvent)="onRightClick(pickupCluster.pointData, $event)"
      [latitude]="pickupCluster.pointData.latitudeNbr"
      [longitude]="pickupCluster.pointData.longitudeNbr"
      [radius]="pickupCluster.radius"
      [strokeColor]="pickupCluster.strokeColor"
      [strokeWeight]="pickupCluster.strokeWeight"
      [fillOpacity]="pickupCluster.opacity"
      [fillColor]="pickupCluster.fillColor"
      [zIndex]="zIndex"
      [pnd-map-circle]="pickupCluster"
      (mouseOver)="onMouseOver(pickupCluster)"
      (mouseOut)="onMouseOut(pickupCluster)"
    >
    </agm-circle>

    <agm-marker
      *ngIf="pickupCluster.pickupClusterMarker"
      [pnd-map-marker]="pickupCluster.pickupClusterMarker"
      (markerRightClickWithEvent)="onRightClick(pickupCluster.pointData, $event)"
      [latitude]="pickupCluster.pointData.latitudeNbr"
      [longitude]="pickupCluster.pointData.longitudeNbr"
      [iconUrl]="pickupCluster.pickupClusterMarker.icon"
      [zIndex]="zIndex"
      (mouseOver)="onMouseOver(pickupCluster)"
    >
    </agm-marker>

    <agm-snazzy-info-window
      [isOpen]="pickupCluster.openInfoWindow"
      [latitude]="pickupCluster?.infoWindowPos?.lat"
      [longitude]="pickupCluster?.infoWindowPos?.lng"
      [closeWhenOthersOpen]="true"
      [panOnOpen]="false"
      [showCloseButton]="false"
      [border]="{ width: '2px', color: 'white' }"
      borderRadius="2px"
      padding="8px"
      [maxWidth]="400"
    >
      <ng-template>
        <app-pickup-cluster-info
          [assignedRoutes]="pickupCluster.assignedRoutes"
          [point]="pickupCluster.pointData"
        ></app-pickup-cluster-info>
      </ng-template>
    </agm-snazzy-info-window>
  </ng-container>
  <pnd-pickup-cluster-context-menu [menuItems]="contextMenuItems"></pnd-pickup-cluster-context-menu>
</ng-container>
