import * as mapTypes from '@agm/core/services/google-maps-types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ModifyTripDetailsActions, ModifyTripDetailsSelectors } from '@pnd-store/.';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '@pnd-store/dispatcher-trips-store';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  ActivityShipment,
  DeliveryShipmentSearchRecord,
  RouteSummary,
  Stop,
  UnassignedPickup,
} from '@xpo-ltl/sdk-cityoperations';
import { LatLong, PickupTypeCd } from '@xpo-ltl/sdk-common';

import { DispatcherTripsGridItem } from 'app/inbound-planning/components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { DispatcherTripsService } from 'app/inbound-planning/shared/services/dispatcher-trips.service';
import { MappingService } from 'app/inbound-planning/shared/services/mapping.service';
import { ModifyTripDetailsSplitPanelService } from 'app/inbound-planning/shared/services/modify-trip-details-split-panel/modify-trip-details-split-panel.service';
import { NotificationMessageService } from 'core';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import {
  filter as _filter,
  flatten as _flatten,
  forEach as _forEach,
  has as _has,
  isEqual as _isEqual,
  map as _map,
  pick as _pick,
  some as _some,
} from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, takeWhile } from 'rxjs/operators';
import { LayoutPreferenceService } from '../../../../../../../shared/layout-manager/services/layout-preference.service';
import { PndZoneUtils } from '../../../../../../../shared/zone-utils';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreActions,
  RoutesStoreSelectors,
  TripsStoreActions,
  TripsStoreSelectors,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../../../../store';
import { TripPlanningGridItem } from '../../../../../components/trip-planning/models/trip-planning-grid-item.model';
import { ModifyTripDetailsService } from '../../../../../components/trips/modify-trip-details/services/modify-trip-details.service';
import { MapUtils } from '../../../../classes/map-utils';
import { StoreSourcesEnum } from '../../../../enums/store-sources.enum';
import {
  areStopsEqual,
  AssignedStopIdentifier,
  consigneeToId,
  EventItem,
  PlanningRouteShipmentIdentifier,
  UnassignedDeliveryIdentifier,
  UnassignedPickupIdentifier,
} from '../../../../interfaces/event-item.interface';
import { MapToolbarService, UnassignedPickupsService } from '../../../../services';
import { PlanningRoutesCacheService } from '../../../../services/planning-routes-cache.service';
import { TripsService } from '../../../../services/trips.service';
import { UnassignedDeliveriesCacheService } from '../../../../services/unassigned-deliveries-cache.service';
import { DrawOption, DrawOptionTypes } from '../draw-options-panel/draw-options-panel.model';
import { ModifyTripActivityId } from './../../../../../../store/modify-trip-details-store/modify-trip-details.state';

@Component({
  selector: 'pnd-polygon-selection',
  templateUrl: './polygon-selection.component.html',
  styleUrls: ['./polygon-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolygonSelectionComponent implements OnDestroy, AfterViewInit {
  @Output() toggleDrawMode = new EventEmitter<boolean>();
  private unsubscriber = new Unsubscriber();
  private disabledSubject = new BehaviorSubject<boolean>(false);
  readonly disabled$ = this.disabledSubject.asObservable();

  private googleMap: google.maps.Map;
  private polygonDrawnEventListener: google.maps.MapsEventListener;
  private newShape: google.maps.Polyline;
  private mouseMoveDrawer: mapTypes.MapsEventListener;
  private mouseUpDrawer: mapTypes.MapsEventListener;
  private mouseDownDrawer: mapTypes.MapsEventListener;
  private drawing: boolean;
  private strokeColor: string = '#304FFE';
  inDrawMode = false;
  drawPanelOptionsMap = new Map<DrawOptionTypes, boolean>();

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private mapToolbarService: MapToolbarService,
    private unassignedPickupsService: UnassignedPickupsService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private modifyTripDetailsService: ModifyTripDetailsService,
    private tripsService: TripsService,
    private layoutPreferences: LayoutPreferenceService,
    private unassignedDeliveriesCacheService: UnassignedDeliveriesCacheService,
    private dispatcherTripsService: DispatcherTripsService,
    private notificationMessageService: NotificationMessageService,
    private modifyTripDetailsSplitPanelService: ModifyTripDetailsSplitPanelService,
    private mappingService: MappingService
  ) {}

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.inDrawMode) {
      this.togglePolygonDraw();
    }

    this.toggleDrawModeOnKeyPress(event);
  }

  private toggleDrawModeOnKeyPress(event: KeyboardEvent): void {
    this.mappingService.isCursorOnMap$.pipe(take(1)).subscribe((isCursorOnMap) => {
      if (isCursorOnMap) {
        if (event?.key?.toLowerCase() === 'd' && this.inDrawMode) {
          this.togglePolygonDraw();
        } else if (event?.key?.toLowerCase() === 'd' && !this.inDrawMode) {
          this.togglePolygonDraw();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    if (this.polygonDrawnEventListener) {
      google.maps.event.removeListener(this.polygonDrawnEventListener);
    }
    if (this.googleMap) {
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'mousedown');
    }
  }

  ngAfterViewInit(): void {
    this.mapToolbarService.mapInstance$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((thisMap: google.maps.Map) => {
        this.googleMap = thisMap;
      });

    this.mapToolbarService.drawOptionsChange$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((drawOptions: DrawOption[]) => {
        drawOptions.forEach((option) => {
          this.drawPanelOptionsMap.set(option.name, option.checked);
          if (option.optionsList?.options) {
            option.optionsList.options.forEach((opt) => {
              this.drawPanelOptionsMap.set(opt.name, opt.checked);
            });
          }
        });
      });

    this.mapToolbarService.setDrawModeState$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((enabled) => {
      if (this.inDrawMode && !enabled) {
        this.togglePolygonDraw();
      }
      this.disabledSubject.next(this.inDrawMode ? false : enabled);
    });

    this.mapToolbarService.toggleDrawModeOff$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.inDrawMode) {
        this.togglePolygonDraw();
      }
    });

    this.layoutPreferences.activeLayout$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((layout) => {
      this.inDrawMode = false;
      this.disabledSubject.next(false);
      this.mapToolbarService.handleDrawOptionsPanelChange(false);
    });
  }

  togglePolygonDraw(): void {
    this.inDrawMode = !this.inDrawMode;
    this.mapToolbarService.setDrawModeState(this.inDrawMode);
    this.toggleDrawMode.emit(this.inDrawMode);

    if (this.inDrawMode) {
      this.mouseDownDrawer = google.maps.event.addListener(this.googleMap, 'mousedown', () => {
        if (this.inDrawMode && !this.drawing) {
          this.disableMap();
          this.initPolygonSelection();
        }
      });
    } else {
      google.maps.event.removeListener(this.mouseDownDrawer);
    }
  }

  private initPolygonSelection(): void {
    const polylineOptions: google.maps.PolylineOptions = {
      map: this.googleMap,
      strokeColor: this.strokeColor,
      strokeWeight: 3,
      clickable: false,
      zIndex: 1,
      editable: false,
    };

    this.newShape = new google.maps.Polyline(polylineOptions);
    this.mouseMoveDrawer = google.maps.event.addListener(this.googleMap, 'mousemove', ($event) => {
      this.newShape.getPath().push($event.latLng);
    });
    this.mouseUpDrawer = google.maps.event.addListener(this.googleMap, 'mouseup', () => {
      google.maps.event.removeListener(this.mouseMoveDrawer);

      const path = this.newShape.getPath();

      this.newShape.setMap(null);
      const arrayofLatLng = path.getArray();
      const zoomLevel = this.googleMap.getZoom();
      const arrayForPolygontoUse = this.reducerDP(arrayofLatLng, this.getKink(zoomLevel));

      const polygonOptions: google.maps.PolygonOptions = {
        ...polylineOptions,
        map: this.googleMap,
        paths: arrayForPolygontoUse,
      };
      const newShapePolygon = new google.maps.Polygon(polygonOptions);
      this.onPolygonDrawn(newShapePolygon);
      setTimeout(() => {
        newShapePolygon.setMap(null);
      }, 100);
    });
  }

  private disableMap() {
    this.drawing = true;
    this.googleMap.setOptions({
      draggable: false,
    });
  }

  private enableMap() {
    this.drawing = false;
    this.googleMap.setOptions({
      draggable: true,
    });
  }

  // User select all items contained within the polygon
  private onPolygonDrawn(polygon: google.maps.Polygon): void {
    google.maps.event.removeListener(this.mouseUpDrawer);

    // Is valid if atleast one draw panel option is selected
    const isValidDrawAction = Array.from(this.drawPanelOptionsMap.values()).some((val) => !!val);

    if (isValidDrawAction) {
      if (this.drawPanelOptionsMap.get(DrawOptionTypes.UnassignedDeliveries)) {
        this.selectUnassignedDeliveries(polygon);
      }

      if (
        this.drawPanelOptionsMap.get(DrawOptionTypes.UnassignedPickups) ||
        this.drawPanelOptionsMap.get(DrawOptionTypes.UnPickupsHE) ||
        this.drawPanelOptionsMap.get(DrawOptionTypes.UnPickupsHL) ||
        this.drawPanelOptionsMap.get(DrawOptionTypes.UnPickupsSE)
      ) {
        this.selectUnassignedPickups(polygon);
      }

      if (this.drawPanelOptionsMap.get(DrawOptionTypes.PlanningRouteShipments)) {
        // TODO: Reverting this behaviour to how it was earlier based on PCT-10279,
        // Only planning route shipments that are already on the map will get selected
        this.selectPlanningRouteShipments(polygon);
      }

      if (this.modifyTripDetailsSplitPanelService.isPanelOpen()) {
        if (this.drawPanelOptionsMap.get(DrawOptionTypes.Stops)) {
          this.selectModifyTripStops(polygon);
        }
      } else {
        if (this.layoutPreferences.isDispatcherLayout()) {
          if (this.drawPanelOptionsMap.get(DrawOptionTypes.Trips)) {
            this.selectDispatcherTrips();
          }
        } else {
          // Inbound Planner Layout is selected

          if (
            this.drawPanelOptionsMap.get(DrawOptionTypes.Routes) &&
            this.drawPanelOptionsMap.get(DrawOptionTypes.Stops)
          ) {
            this.selectPlanningTrips(polygon, true);
          } else {
            if (this.drawPanelOptionsMap.get(DrawOptionTypes.Routes)) {
              this.selectPlanningTrips(polygon, false);
            }
            if (this.drawPanelOptionsMap.get(DrawOptionTypes.Stops)) {
              this.selectAssignedRouteStops(polygon);
            }
          }
        }
      }
    } else {
      // No draw options selected
      this.notificationMessageService
        .openNotificationMessage(
          NotificationMessageStatus.Info,
          'Please select at least one option in the draw tool to view results'
        )
        .subscribe(() => {});
    }

    this.enableMap();
  }

  /**
   * Add Dispatched Trips that are inside the polygon to the current selection
   */
  private selectDispatcherTrips(): void {
    this.mapToolbarService.updateDrawOptionsPanelLoading(true);
    let geofenceArray: LatLong[] = [];
    if (this.newShape) {
      geofenceArray = this.newShape
        .getPath()
        .getArray()
        .map((latLng) => {
          return { latitude: latLng.lat(), longitude: latLng.lng() };
        });
    }

    const sizZonesAndSatellites = this.pndStore$.selectSnapshot(
      GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites
    );
    const planDate = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterPlanDate);
    const sicCd = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    const selectedTrips = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.selectedTrips);
    const hostDestinationSicCds = PndZoneUtils.getCurrentSelectionSics(sizZonesAndSatellites, false);

    this.dispatcherTripsService
      .fetchDispatcherTripGridItemsByGeofence(geofenceArray, hostDestinationSicCds, planDate, sicCd)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((tripsToSelect: DispatcherTripsGridItem[]) => {
        const newSelectionIds = tripsToSelect.map((trip) => {
          return trip.tripInstId;
        });

        selectedTrips.forEach((tripInstId) => {
          if (!_some(newSelectionIds, (id) => id === tripInstId)) {
            newSelectionIds.push(tripInstId);
          }
        });

        this.pndStore$.dispatch(
          new DispatcherTripsStoreActions.SetSelected({
            selectedDispatcherTripsIds: newSelectionIds,
          })
        );

        this.mapToolbarService.updateDrawOptionsPanelLoading(false);
      });
  }

  selectPlanningRoutes(polygon: google.maps.Polygon) {
    this.mapToolbarService.updateDrawOptionsPanelLoading(true);

    let geofenceArray: LatLong[] = [];
    if (this.newShape) {
      geofenceArray = this.newShape
        .getPath()
        .getArray()
        .map((latLng) => {
          return { latitude: latLng.lat(), longitude: latLng.lng() };
        });
    }
    combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites),
      this.pndStore$.select(RoutesStoreSelectors.selectedPlanningRoutes),
    ])
      .pipe(take(1))
      .subscribe(([sizZonesAndSatellites, currentSelection]) => {
        const hostDestinationSicCds = PndZoneUtils.getCurrentSelectionSics(sizZonesAndSatellites, false);
        this.planningRoutesCacheService
          .fetchPlanningRoutesByGeofence(geofenceArray, hostDestinationSicCds)
          .subscribe((planningRoutesToSelect: number[]) => {
            currentSelection.forEach((routeInstId: number) => {
              if (!_some(planningRoutesToSelect, (item) => item === routeInstId)) {
                planningRoutesToSelect.push(routeInstId);
              }
            });

            this.pndStore$.dispatch(
              new RoutesStoreActions.SetSelectedPlanningRoutesAction({
                selectedPlanningRoutes: planningRoutesToSelect,
              })
            );

            if (planningRoutesToSelect.length > 0) {
              this.planningRoutesCacheService.loadingStops$
                .pipe(
                  debounceTime(200),
                  takeWhile((loading: boolean) => loading, true),
                  filter((loading: boolean) => !loading)
                )
                .subscribe(() => {
                  this.selectPlanningRouteShipments(polygon);
                  this.mapToolbarService.updateDrawOptionsPanelLoading(false);
                });
            } else {
              this.mapToolbarService.updateDrawOptionsPanelLoading(false);
            }
          });
      });
  }

  selectPlanningTrips(polygon: google.maps.Polygon, shouldSelectStops: boolean): void {
    this.mapToolbarService.updateDrawOptionsPanelLoading(true);
    let geofenceArray: LatLong[] = [];
    if (this.newShape) {
      geofenceArray = this.newShape
        .getPath()
        .getArray()
        .map((latLng) => {
          return { latitude: latLng.lat(), longitude: latLng.lng() };
        });
    }

    const sizZonesAndSatellites = this.pndStore$.selectSnapshot(
      GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites
    );
    const planDate = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterPlanDate);
    const selectedTrips = this.pndStore$.selectSnapshot(TripsStoreSelectors.selectedTrips);
    const hostDestinationSicCds = PndZoneUtils.getCurrentSelectionSics(sizZonesAndSatellites, false);

    this.tripsService
      .fetchTripGridItemsByGeofence(geofenceArray, hostDestinationSicCds, planDate)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((tripsToSelect: TripPlanningGridItem[]) => {
        selectedTrips.forEach((trip: TripPlanningGridItem) => {
          if (!_some(tripsToSelect, (item: TripPlanningGridItem) => item.routeInstId === trip.routeInstId)) {
            tripsToSelect.push(trip);
          }
        });

        this.pndStore$.dispatch(
          new TripsStoreActions.SetSelected({
            selectedTrips: tripsToSelect,
          })
        );
        if (shouldSelectStops && tripsToSelect.length > 0) {
          this.tripsService.loadingStops$
            .pipe(
              debounceTime(200),
              takeWhile((loading: boolean) => loading, true),
              filter((loading: boolean) => !loading)
            )
            .subscribe(() => {
              this.selectAssignedRouteStops(polygon);
              this.mapToolbarService.updateDrawOptionsPanelLoading(false);
            });
        } else {
          this.mapToolbarService.updateDrawOptionsPanelLoading(false);
        }
      });
  }

  /**
   * Add Unassigned Deliveries that are inside the polygon to the current selection
   */
  private selectUnassignedDeliveries(polygon: google.maps.Polygon) {
    forkJoin([
      this.unassignedDeliveriesCacheService.unassignedDeliveries$.pipe(take(1)),
      this.pndStore$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected).pipe(
        take(1),
        map((selected: EventItem<UnassignedDeliveryIdentifier>[]) => _map(selected, (item) => item.id))
      ),
    ]).subscribe(
      ([allDeliveries, currentSelection]: [UnassignedDeliveryIdentifier[], UnassignedDeliveryIdentifier[]]) => {
        const newSelection: UnassignedDeliveryIdentifier[] = [];
        const filteredIds: { [key: number]: boolean } = this.pndStore$.selectSnapshot(
          UnassignedDeliveriesStoreSelectors.unassignedDeliveriesFilteredIds
        );

        _forEach(allDeliveries, (stop) => {
          const position = new google.maps.LatLng(stop.consignee.latitudeNbr, stop.consignee.longitudeNbr);
          if (google.maps.geometry.poly.containsLocation(position, polygon) && filteredIds?.[consigneeToId(stop)]) {
            newSelection.push(stop);
          }
        });

        // add previous selections that aren't already in the new selections
        _forEach(currentSelection, (stop) => {
          // if the newSelection already contains the consginee, we dont need to add it
          // from the previous selection
          if (!_some(newSelection, (item) => consigneeToId(item) === consigneeToId(stop))) {
            newSelection.push(stop);
          }
        });

        this.pndStore$.dispatch(
          new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
            selectedDeliveries: _map(newSelection, (item) => {
              return {
                id: {
                  consignee: _pick(item.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
                  shipmentInstId: item.shipmentInstId,
                },
                source: StoreSourcesEnum.POLYGON_SELECTION,
              };
            }),
          })
        );
      }
    );
  }

  /**
   * Add Unassigned Pickups that are inside the polygon to the current selection
   */
  private selectUnassignedPickups(polygon: google.maps.Polygon) {
    forkJoin([
      this.unassignedPickupsService.unassignedPickups$.pipe(take(1)),
      this.pndStore$.select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected).pipe(
        take(1),
        map((selected: EventItem<UnassignedPickupIdentifier>[]) => _map(selected, (item) => item.id))
      ),
    ]).subscribe(([allPickups, currentSelection]: [UnassignedPickup[], UnassignedPickupIdentifier[]]) => {
      const newSelection: UnassignedPickupIdentifier[] = [];

      _forEach(allPickups, (stop) => {
        const position: google.maps.LatLng = MapUtils.getGoogleCoordinates(stop.pickupHeader.shipper);
        const stopPickupTypeCd =
          stop.pickupHeader.header.pickupTypeCd === PickupTypeCd.PU
            ? DrawOptionTypes.UnassignedPickups
            : stop.pickupHeader.header.pickupTypeCd;

        if (
          google.maps.geometry.poly.containsLocation(position, polygon) &&
          this.drawPanelOptionsMap.get(<any>stopPickupTypeCd)
        ) {
          newSelection.push({
            pickupInstId: stop.pickupHeader.header.pickupRequestInstId,
            shipper: {
              latitudeNbr: position.lat(),
              longitudeNbr: position.lng(),
            },
            motorMovesNbr: stop.pickupHeader.motorMovesNbr,
            palletsCount: stop.pickupHeader.palletsCount,
            loosePiecesCount: stop.pickupHeader.loosePiecesCount,
            weightLbs: stop.pickupHeader.weightLbs,
            specialServiceSummary: stop.specialServiceSummary,
            pickupTypeCd: stop.pickupHeader.pickupTypeCd,
          });
        }
      });

      // add previous selections that aren't already in the new selections
      _forEach(currentSelection, (stop) => {
        // if the newSelection already contains the pickup, we dont need to add it
        // from the previous selection
        if (!_some(newSelection, (item) => _isEqual(item.pickupInstId, stop.pickupInstId))) {
          newSelection.push(stop);
        }
      });

      this.pndStore$.dispatch(
        new UnassignedPickupsStoreActions.SetSelectedUnassignedPickups({
          selectedPickups: _map(newSelection, (item) => {
            return {
              id: {
                shipper: _pick(item.shipper, ['latitudeNbr', 'longitudeNbr']),
                pickupInstId: item.pickupInstId,
                motorMovesNbr: item.motorMovesNbr,
                palletsCount: item.palletsCount,
                loosePiecesCount: item.loosePiecesCount,
                weightLbs: item.weightLbs,
                specialServiceSummary: item.specialServiceSummary,
                pickupTypeCd: item.pickupTypeCd,
              },
              source: StoreSourcesEnum.POLYGON_SELECTION,
            };
          }),
        })
      );
    });
  }

  /**
   * Add Stops selected on the map to the current selected activities.
   */
  private selectModifyTripStops(polygon: google.maps.Polygon) {
    this.modifyTripDetailsService.tripSummary$
      .pipe(
        filter((tripSummary) => !!tripSummary),
        map((tripSummary) => tripSummary.routeSummary),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((summaries) => {
        const routeSummary: RouteSummary[] = _flatten(_map(_flatten(summaries)));
        const selectedActivities: ModifyTripActivityId[] = this.pndStore$.selectSnapshot(
          ModifyTripDetailsSelectors.selectedActivities
        );
        const selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[] = this.pndStore$.selectSnapshot(
          TripsStoreSelectors.selectedStopsForSelectedRoutes
        );

        _forEach(routeSummary, (route) => {
          _forEach(route.stops, (stop) => {
            const position: google.maps.LatLng = MapUtils.getGoogleCoordinates(stop.customer);

            if (google.maps.geometry.poly.containsLocation(position, polygon)) {
              _forEach(stop.activities, (activity) => {
                _forEach(
                  _filter(activity.activityShipments, (filteringActivity) => !!filteringActivity.proNbr),
                  (activityShipment: ActivityShipment) => {
                    const newItem = {
                      tripInstId: stop.tripNode.tripInstId,
                      routeInstId: route?.route?.routeInstId,
                      tripNodeSequenceNbr: activity.tripNodeActivity.tripNodeSequenceNbr,
                      tripNodeActivitySequenceNbr: activity.tripNodeActivity.tripNodeActivitySequenceNbr,
                      activityCd: activity.tripNodeActivity.activityCd,
                      pickupRequestInstId: activity.tripNodeActivity.pickupRequestInstId,
                      shipmentInstId: activity.tripNodeActivity.shipmentInstId,
                      proNbr: activityShipment.proNbr,
                      uniqueId: activityShipment?.['uniqueId'],
                    };

                    const alreadySelectedIndex = selectedActivities.findIndex(
                      (item) => item.uniqueId === newItem.uniqueId
                    );

                    if (alreadySelectedIndex === -1) {
                      selectedActivities.push(newItem);
                    }
                  }
                );
              });

              if (
                !selectedStopsForSelectedRoutes.some(
                  (selectedStop) =>
                    selectedStop?.id?.routeInstId === route?.route?.routeInstId &&
                    selectedStop?.id?.seqNo === stop?.tripNode?.stopSequenceNbr
                )
              ) {
                selectedStopsForSelectedRoutes.push({
                  id: {
                    origSeqNo: stop?.tripNode?.stopSequenceNbr,
                    seqNo: stop?.tripNode?.stopSequenceNbr,
                    routeInstId: route?.route?.routeInstId,
                  },
                  source: StoreSourcesEnum.POLYGON_SELECTION,
                });
              }
            }
          });
        });

        // Set selected Activities
        this.pndStore$.dispatch(
          new ModifyTripDetailsActions.SetSelectedActivities({
            selectedActivities: selectedActivities,
          })
        );

        // Set selected Stops
        this.pndStore$.dispatch(
          new TripsStoreActions.SetSelectedStopsForSelectedRoutes({
            selectedStopsForSelectedRoutes: [...selectedStopsForSelectedRoutes],
          })
        );
      });
  }

  /**
   * Add Planning Routes Shipments that are inside the polygon to the current selection
   */
  private selectPlanningRouteShipments(polygon: google.maps.Polygon) {
    forkJoin([
      this.pndStore$.select(RoutesStoreSelectors.selectedPlanningRoutes).pipe(take(1)),
      this.pndStore$.select(RoutesStoreSelectors.selectedPlanningRoutesShipments).pipe(
        take(1),
        map((selection) => _map(selection, (item) => item.id))
      ),
    ]).subscribe(([selectedPlanningRoutes, selectedPlanningRoutesShipments]) => {
      const newSelection: PlanningRouteShipmentIdentifier[] = [];

      // for each planning route, see if any of their shipments are within the polygon. If so,
      // we need to select them.
      _forEach(selectedPlanningRoutes, (routeId) => {
        const stopsForRoute = this.planningRoutesCacheService.getStopsForRoute(routeId);

        _forEach(stopsForRoute, (stop) => {
          const position = new google.maps.LatLng(stop.consignee.latitudeNbr, stop.consignee.longitudeNbr);
          if (google.maps.geometry.poly.containsLocation(position, polygon)) {
            // add all shipments for this stop to the selection
            _forEach(stop.deliveryShipments, (shipment: DeliveryShipmentSearchRecord) => {
              newSelection.push(shipment);
            });
          }
        });
      });

      // add any currently selected shipments back in to the new selection
      _forEach(selectedPlanningRoutesShipments, (shipment) => {
        if (!_some(newSelection, (item) => item.shipmentInstId === shipment.shipmentInstId)) {
          newSelection.push(shipment);
        }
      });

      // set the new selection
      this.pndStore$.dispatch(
        new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
          selectedPlanningRoutesShipments: _map(newSelection, (item) => {
            return {
              id: {
                consignee: _pick(item.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
                shipmentInstId: item.shipmentInstId,
                routeInstId: item.routeInstId,
                proNbr: item.proNbr,
              },
              source: StoreSourcesEnum.POLYGON_SELECTION,
            };
          }),
        })
      );
    });
  }

  /**
   * Add Planning Routes Shipments that are inside the polygon to the current selection
   */
  private selectAssignedRouteStops(polygon: google.maps.Polygon) {
    const selectedRoutes = this.pndStore$
      .selectSnapshot(TripsStoreSelectors.selectedRoutes)
      .map((routeId) => routeId.routeInstId);

    const selectedStopsForSelectedRoutes = this.pndStore$
      .selectSnapshot(TripsStoreSelectors.selectedStopsForSelectedRoutes)
      .map((selection: EventItem<AssignedStopIdentifier>) => selection.id);

    const stopsforSelectedRoutes = this.pndStore$.selectSnapshot(TripsStoreSelectors.stopsForSelectedRoutes);

    const newSelection: AssignedStopIdentifier[] = [];

    // for each route, see if any of their stops are within the polygon. If so,
    // we need to select them.
    _forEach(selectedRoutes, (selectedRouteInstId) => {
      const stopsForRoute = stopsforSelectedRoutes?.[selectedRouteInstId];

      _forEach(stopsForRoute, (stop: Stop) => {
        if (_has(stop, 'customer')) {
          const position: google.maps.LatLng = MapUtils.getGoogleCoordinates(stop.customer);

          if (google.maps.geometry.poly.containsLocation(position, polygon)) {
            newSelection.push({
              routeInstId: selectedRouteInstId,
              seqNo: stop?.tripNode?.stopSequenceNbr || null,
              origSeqNo: stop?.tripNode?.stopSequenceNbr || null,
            });
          }
        }
      });
    });

    // add any currently selected stops back in to the new selection
    _forEach(selectedStopsForSelectedRoutes, (stop) => {
      if (!_some(newSelection, (item) => areStopsEqual(item, stop))) {
        newSelection.push(stop);
      }
    });

    // set the new selection
    this.pndStore$.dispatch(
      new TripsStoreActions.SetSelectedStopsForSelectedRoutes({
        selectedStopsForSelectedRoutes: _map(newSelection, (item) => {
          return {
            id: _pick(item, ['routeInstId', 'seqNo', 'origSeqNo']),
            source: StoreSourcesEnum.POLYGON_SELECTION,
          };
        }),
      })
    );
  }

  /* Stack-based Douglas Peucker line simplification routine
  https://en.wikipedia.org/wiki/Ramer–Douglas–Peucker_algorithm
   returned is a reduced GLatLng array
  */

  getKink = (zoom: number): number => {
    let kink;
    if (zoom <= 10) {
      kink = 500;
    }
    if (zoom > 10 && zoom < 15) {
      kink = 100;
    }
    if (zoom >= 15) {
      kink = 1;
    }
    return kink;
  };

  private reducerDP(source: google.maps.LatLng[], kink: number) {
    /* source Input coordinates in GLatLngs 	*/
    /* kink	in metres, kinks above this depth kept  */
    /* kink depth is the height of the triangle abc where a-b and b-c are two consecutive line segments */

    let nSource: number,
      nStack: number,
      nDest: number,
      start: number,
      end: number,
      i: number,
      sig: number,
      devSqr: number,
      maxDevSqr: number,
      bandSqr: number,
      kain12: number,
      y12: number,
      d12: number,
      x13: number,
      y13: number,
      d13: number,
      x23: number,
      y23: number,
      d23: number;
    const F = (Math.PI / 180.0) * 0.5;
    const index = new Array();
    const sigStart = new Array();
    const sigEnd = new Array();

    if (source.length < 3) {
      return source;
    }

    nSource = source.length;
    bandSqr = (kink * 360.0) / (2.0 * Math.PI * 6378137.0); /* Now in degrees */
    bandSqr *= bandSqr;
    nDest = 0;
    sigStart[0] = 0;
    sigEnd[0] = nSource - 1;
    nStack = 1;

    /* while the stack is not empty  ... */
    while (nStack > 0) {
      start = sigStart[nStack - 1];
      end = sigEnd[nStack - 1];
      nStack--;

      if (end - start > 1) {
        /* any intermediate points ? */
        /* ... yes, so find most deviant intermediate point to
          either side of line joining start & end points */

        kain12 = source[end].lng() - source[start].lng();
        y12 = source[end].lat() - source[start].lat();

        if (Math.abs(kain12) > 180.0) {
          kain12 = 360.0 - Math.abs(kain12);
        }

        kain12 *= Math.cos(F * (source[end].lat() + source[start].lat()));
        /* use avg lat to reduce lng */
        d12 = kain12 * kain12 + y12 * y12;

        for (i = start + 1, sig = start, maxDevSqr = -1.0; i < end; i++) {
          x13 = source[i].lng() - source[start].lng();
          y13 = source[i].lat() - source[start].lat();
          if (Math.abs(x13) > 180.0) {
            x13 = 360.0 - Math.abs(x13);
          }
          x13 *= Math.cos(F * (source[i].lat() + source[start].lat()));
          d13 = x13 * x13 + y13 * y13;

          x23 = source[i].lng() - source[end].lng();
          y23 = source[i].lat() - source[end].lat();
          if (Math.abs(x23) > 180.0) {
            x23 = 360.0 - Math.abs(x23);
          }

          x23 *= Math.cos(F * (source[i].lat() + source[end].lat()));
          d23 = x23 * x23 + y23 * y23;

          if (d13 >= d12 + d23) {
            devSqr = d23;
          } else if (d23 >= d12 + d13) {
            devSqr = d13;
          } else {
            devSqr = ((x13 * y12 - y13 * kain12) * (x13 * y12 - y13 * kain12)) / d12; // solve triangle
          }

          if (devSqr > maxDevSqr) {
            sig = i;
            maxDevSqr = devSqr;
          }
        }

        if (maxDevSqr < bandSqr) {
          /* is there a sig. intermediate point ? */
          /* ... no, so transfer current start point */
          index[nDest] = start;
          nDest++;
        } else {
          /* ... yes, so push two sub-sections on stack for further processing */
          nStack++;
          sigStart[nStack - 1] = sig;
          sigEnd[nStack - 1] = end;
          nStack++;
          sigStart[nStack - 1] = start;
          sigEnd[nStack - 1] = sig;
        }
      } else {
        /* ... no intermediate points, so transfer current start point */
        index[nDest] = start;
        nDest++;
      }
    }

    /* transfer last point */
    index[nDest] = nSource - 1;
    nDest++;

    /* make return array */
    const r = new Array();
    for (let ix = 0; ix < nDest; ix++) {
      r.push(source[index[ix]]);
    }
    return r;
  }
}
