import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { PluralKeyValue } from '../../classes/plural-maps';
import { SpecialServiceMark } from './../service-icons/model/special-service-mark';

// track summary of shipments selected
export class SelectionSummaryData {
  stops = 0;
  shipments = undefined;
  loosePieces = undefined;
  palletsCount = undefined;
  motorMoves = 0;
  weight = 0;
  deliveryWeight = undefined;
  pickupWeight = undefined;
  specialServices: ShipmentSpecialServiceCd[] = [];
  specialServicesMarks: SpecialServiceMark[] = [];
  selectedDisplayName: PluralKeyValue = undefined;
}
