import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { EventItem, UnassignedDeliveryIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedDeliveriesSearchCriteria } from './unassigned-deliveries-search-criteria.interface';

export const StateKey = 'unassignedDeliveriesState';

export interface State {
  readonly criteria: UnassignedDeliveriesSearchCriteria;
  readonly lastUpdate: Date;

  readonly selectedDeliveries: EventItem<UnassignedDeliveryIdentifier>[];
  readonly focusedDelivery: EventItem<UnassignedDeliveryIdentifier>;
  readonly clickedDelivery: DeliveryShipmentSearchRecord;
  readonly activeBoardView: string;
  readonly filteredUnassignedDeliveryIds: { [key: number]: boolean };
}

export const initialState: State = {
  activeBoardView: undefined,
  criteria: undefined,
  lastUpdate: new Date(0),
  selectedDeliveries: [],
  focusedDelivery: {
    id: undefined,
    source: undefined,
  },
  clickedDelivery: undefined,
  filteredUnassignedDeliveryIds: {},
};
