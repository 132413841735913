<ng-container *ngIf="summaryDisplay$ | async as summary">
  <div class="pnd-selectionSummary" *ngIf="summary && summary?.stops > 0" [attr.data-test]="dataTestPrefix + 'summary'">
    <div class="pnd-selectionSummary__customer" [attr.data-test]="dataTestPrefix + 'summary-selected'">
      <ng-container *ngIf="!summary?.selectedDisplayName">{{ summary?.stops }} SELECTED</ng-container>
      <ng-container *ngIf="summary?.selectedDisplayName">{{ summary?.stops }}
        {{ summary?.stops | i18nPlural: summary?.selectedDisplayName | uppercase }} SELECTED
      </ng-container>
    </div>
    <div class="pnd-selectionSummary__shipments" *ngIf="summary?.shipments !== undefined">
      <span class="pnd-selectionSummary__label">BILLS: </span>
      <span class="pnd-selectionSummary__data" [attr.data-test]="dataTestPrefix + 'summary-bills'">{{
        summary?.shipments
      }}</span>
    </div>
    <div class="pnd-selectionSummary__mm" *ngIf="summary?.loosePieces !== undefined">
      <span class="pnd-selectionSummary__label">LP:</span>
      <span class="pnd-selectionSummary__data" [attr.data-test]="dataTestPrefix + 'summary-lp'">{{
        summary?.loosePieces
      }}</span>
    </div>
    <div class="pnd-selectionSummary__mm" *ngIf="summary?.palletsCount !== undefined">
      <span class="pnd-selectionSummary__label">PC:</span>
      <span class="pnd-selectionSummary__data" [attr.data-test]="dataTestPrefix + 'summary-pc'">{{
        summary?.palletsCount
      }}</span>
    </div>
    <div class="pnd-selectionSummary__mm" *ngIf="summary?.motorMoves !== undefined">
      <span class="pnd-selectionSummary__label">MM:</span>
      <span class="pnd-selectionSummary__data" [attr.data-test]="dataTestPrefix + 'summary-mm'">{{
        summary?.motorMoves
      }}</span>
    </div>
    <div class="pnd-selectionSummary__weight" *ngIf="summary?.deliveryWeight !== undefined">
      <span class="pnd-selectionSummary__label">DL WEIGHT:</span>
      <span class="pnd-selectionSummary__data"
          [attr.data-test]="dataTestPrefix + 'summary-delivery-weight'">{{ summary?.deliveryWeight | number }}
        LBS</span>
    </div>
    <div class="pnd-selectionSummary__weight" *ngIf="summary?.pickupWeight !== undefined">
      <span class="pnd-selectionSummary__label">PU WEIGHT:</span>
      <span class="pnd-selectionSummary__data"
          [attr.data-test]="dataTestPrefix + 'summary-pickup-weight'">{{ summary?.pickupWeight | number }} LBS</span>
    </div>
    <div class="pnd-selectionSummary__weight" *ngIf="summary?.weight !== undefined">
      <span class="pnd-selectionSummary__label">TOTAL WEIGHT:</span>
      <span class="pnd-selectionSummary__data"
          [attr.data-test]="dataTestPrefix + 'summary-weight'">{{ summary?.weight | number }} LBS</span>
    </div>
    <div class="pnd-selectionSummary__specialServices">
      <span class="pnd-selectionSummary__label">SPECIAL SERVICES:</span>
      <div class="pnd-selectionSummary__data">
        <ng-container *ngIf="summary?.specialServices.length > 0">
          <pnd-special-services
              [value]="summary?.specialServices"
              [attr.data-test]="dataTestPrefix + 'summary-special-services'"
              [specialServiceMarks]="summary?.specialServicesMarks"></pnd-special-services>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
