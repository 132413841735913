import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import { SicZoneSelectionType } from '../app/inbound-planning/shared/enums/sic-zone-selection-type';
import { SicZonesAndSatellites } from '../app/inbound-planning/shared/models/sic-zones-and-satellites.model';

export class PndZoneUtils {
  /**
   * Return all sics for current zone selection (optionally including the satellites)
   *
   * @param sicZonesAndSatellites
   * @param includeSatellites
   */
  static getCurrentSelectionSics(sicZonesAndSatellites: SicZonesAndSatellites, includeSatellites: boolean): string[] {
    let sics: string[];
    if (sicZonesAndSatellites.currentSelection === SicZoneSelectionType.HOST_ONLY) {
      sics = [sicZonesAndSatellites.host, ...(includeSatellites ? sicZonesAndSatellites.hostSatellites : [])];
    } else if (sicZonesAndSatellites.currentSelection === SicZoneSelectionType.ZONES_ONLY) {
      sics = [...sicZonesAndSatellites.zones, ...(includeSatellites ? sicZonesAndSatellites.zoneSatellites : [])];
    } else {
      sics = [
        sicZonesAndSatellites.host,
        ...(includeSatellites ? sicZonesAndSatellites.hostSatellites : []),
        ...sicZonesAndSatellites.zones,
        ...(includeSatellites ? sicZonesAndSatellites.zoneSatellites : []),
      ];
    }
    return sics;
  }

  static getZoneIndicatorCd(sicZonesAndSatellites: SicZonesAndSatellites): ZoneIndicatorCd {
    switch (sicZonesAndSatellites?.currentSelection) {
      case SicZoneSelectionType.HOST_AND_ZONES:
        return ZoneIndicatorCd.INCLUDE_ZONES;
      case SicZoneSelectionType.ZONES_ONLY:
        return ZoneIndicatorCd.ZONES_ONLY;
      case SicZoneSelectionType.HOST_ONLY:
        return ZoneIndicatorCd.NO_ZONES;
      default:
        return undefined;
    }
  }

  static getSicAndZones(sicZonesAndSatellites: SicZonesAndSatellites, sic: string): string[] {
    const sicZoneArr: string[] = [];

    sicZoneArr.push(sic);

    if (sicZonesAndSatellites) {
      const zones: string[] = sicZonesAndSatellites.zones;
      if (zones) {
        zones.forEach((element) => {
          sicZoneArr.push(element);
        });
      }
    }

    return sicZoneArr;
  }
}
