import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';
import { TripFormFields } from './create-trip/enums/trip-form-fields';

export class TripManagerValidators {
  static hasAutoCompleteItemValue(formGroup: UntypedFormGroup, formField: TripFormFields): boolean {
    if (!formGroup) {
      return false;
    }

    const inputValue = formGroup.get(formField)?.value;

    return inputValue?.hasOwnProperty('id') ? !_isEmpty(inputValue.value) : false;
  }

  static hasValue(formGroup: UntypedFormGroup, formField: TripFormFields): boolean {
    if (!formGroup) {
      return false;
    }

    const inputValue = formGroup.get(formField)?.value;

    return inputValue?.hasOwnProperty('id') ? !_isEmpty(inputValue.value) : !_isEmpty(inputValue);
  }

  static hasValueControl(control: AbstractControl): boolean {
    if (!control) {
      return false;
    }

    const inputValue = control.value;

    return inputValue?.hasOwnProperty('id') ? !_isEmpty(inputValue.value) : !_isEmpty(inputValue);
  }
}
