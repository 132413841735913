<div class="metric-shipments">
  <xpo-ltl-dialog-title>
    <div class="titleWrapper">
      <xpo-icon class="specialServices-svg" svgIconName="{{ iconName }}"></xpo-icon>
      Shipments for Planning ({{ data?.shipments?.length }})
    </div>
  </xpo-ltl-dialog-title>
  <div mat-dialog-content>
    <xpo-ag-grid class="tableWrapper">
      <ag-grid-angular [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"></ag-grid-angular>
    </xpo-ag-grid>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button (click)="cancel()">
      CLOSE
    </button>
  </div>
</div>
