import { Action } from '@ngrx/store';
import { UnmappedStopStatus } from '../../inbound-planning/shared/components/unmapped-stops/classes/unmapped-stop-status';
import { UnmappedStopDetail } from '../../inbound-planning/shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../inbound-planning/shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';

export enum ActionTypes {
  setStatus = 'geoLocation.setStatus',
  setEditMode = 'geoLocation.setEditMode',
  setStopToEdit = 'geoLocation.setStopToEdit',
  endEdit = 'geoLocation.endEdit',
}

export class SetStopToEdit implements Action {
  readonly type = ActionTypes.setStopToEdit;
  constructor(public payload: UnmappedStopDetail) {}
}

export class EndEdit implements Action {
  readonly type = ActionTypes.endEdit;
  constructor() {}
}

export class SetStatus implements Action {
  readonly type = ActionTypes.setStatus;
  constructor(public payload: UnmappedStopStatus) {}
}

export class SetEditMode implements Action {
  readonly type = ActionTypes.setEditMode;
  constructor(public payload: UnmappedStopsEditMode) {}
}

export type Actions = SetStopToEdit | EndEdit | SetStatus | SetEditMode;
