import { EventItem, PinnedStopIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { ResequencingRouteDataMap } from './route-balancing-types';

export const StateKey = 'RouteBalancingState';

export interface State {
  readonly openRouteBalancingPanel: boolean;
  readonly openMergeModePanel: boolean;
  readonly routes: number[];
  readonly pinFirst: EventItem<PinnedStopIdentifier>;
  readonly pinLast: EventItem<PinnedStopIdentifier>;
  readonly canOpenRouteBalancing: boolean;
  readonly resequencedRouteData: ResequencingRouteDataMap;
}

export const initialState: State = {
  openRouteBalancingPanel: false,
  openMergeModePanel: false,
  routes: [],
  pinFirst: undefined,
  pinLast: undefined,
  canOpenRouteBalancing: true,
  resequencedRouteData: {},
};
