import { tassign } from 'tassign';
import { Actions, ActionTypes } from './past-due-shipments-store.actions';
import { initialState, State } from './past-due-shipments-store.state';

export function routesReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSelectedPastDueShipments: {
      return tassign(state, {
        selectedPastDueShipments: action.payload.setSelectedPastDueShipments,
      });
    }
    case ActionTypes.setPastDueShipmentsLastUpdate: {
      return tassign(state, {
        pastDueShipmentsLastUpdate: action.payload.pastDueShipmentsLastUpdate,
      });
    }
    default: {
      return state;
    }
  }
}
