import { GeoArea } from '@xpo-ltl/sdk-cityoperations';
import { GeoAreaDrawMode } from './GeoAreaDrawMode';

export class GeoAreaDraw extends GeoArea {
    mode?: GeoAreaDrawMode;
    mapPolygon?: google.maps.Polygon;
    areaLabelMaker?: google.maps.Marker;
    areaColor?: string;
    geoAreaJson?: string;
}
