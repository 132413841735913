import { ICellEditorParams } from 'ag-grid-community';
export interface XpoInlineEditingError {
  errorDescription?: string;
  columnId: string;
  keyField: string;
  id: string;
  value: any;
}

export interface XpoGridCellEditorParams extends ICellEditorParams {
  keyField: string;
  customValidations: XpoGridCellEditorCustomValidation[];
}

export interface XpoGridCellEditorCustomValidation {
  condition: (value: any) => boolean;
  errorMessage: string;
}

// Cell error descriptions
// TODO: Make this configurable from project side
export enum ErrorDescriptions {
  EmptyValue = 'Can\'t be empty',
  ErrorPattern = 'Wrong pattern for this input',
}

// Cell error types
export enum ErrorTypes {
  EmptyValue = 'EmptyValue',
  ErrorPattern = 'ErrorPattern',
}
