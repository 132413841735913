import {
  ProFormatterPipe,
  XpoLtlShipmentDescriptor,
  XpoLtlTimeService,
  XpoLtlFormatValidationService,
} from '@xpo-ltl/ngx-ltl';
import { ColDef, ColSpanParams, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { BoardUtils } from '../../../../../../shared/board-utils';
import { CarrierTenderStatusCdPipe, TotalTextCellRendererParams } from '../../../../shared';
import { SpecialServicesService } from '../../../../shared/services/special-services.service';
import { ModifyTripDetailsSharedColumnDefinitions } from '../classes/modify-trip-details-shared-column-definitions';
import { ModifyTripDetailsDetailGridFields } from '../enums/modify-trip-details-detail-grid-fields.enum';
import { ModifyTripDetailsDetailGridHeaders } from '../enums/modify-trip-details-detail-grid-headers.enum';
import {
  GridActivityEquipment,
  ModifyTripDetailsActivityDetailGridItem,
  ModifyTripDetailsBaseGridItem,
} from '../models/modify-trip-details-grid-item.model';
import { ModifyTripDetailsShipmentsColumnDefinitions } from '../modify-trip-details-shipments/modify-trip-details-shipments-column-definitions';

/**
 * Grid column definitions for Detail grids when opening
 * a Pickup, Delivery, or Equipment detailGrid
 */
export class ModifyTripDetailsDetailColumnDefinitions {
  private static emptySpaceExpandColDef: ColDef = {
    colId: 'emptySpaceExpandedCol',
    headerName: '',
    headerValueGetter: () => '',
    valueGetter: (params) => '',
    width: 50,
    headerClass: 'pnd-ModifyTripDetailsDetailGridRenderer__empty-space-header pnd-first-col',
    cellClass: 'pnd-ModifyTripDetailsDetailGridRenderer__empty-space-cell pnd-first-col',
    sortable: false,
    resizable: false,
    lockPosition: true,
  };

  /**
   * Returns column definitions for Pickups detail grid
   */
  static pickupLineItemsColumnDefs = (
    formatValidationService: XpoLtlFormatValidationService,
    specialServicesService: SpecialServicesService,
    proFormatterPipe: ProFormatterPipe,
    isCartageTrip: boolean,
    tenderStatusCdPipe: CarrierTenderStatusCdPipe,
    shipmentClickCallback: (id: XpoLtlShipmentDescriptor) => void,
    bolClickCallback: (data: ModifyTripDetailsBaseGridItem) => void,
    onCallNbrClick: (item: ModifyTripDetailsActivityDetailGridItem) => void
  ): ColDef[] => {
    return [
      ModifyTripDetailsDetailColumnDefinitions.emptySpaceExpandColDef,
      {
        ...ModifyTripDetailsSharedColumnDefinitions.rowSelected,
        headerCheckboxSelection: false,
        cellRendererSelector: (params: ICellRendererParams) => {
          return ModifyTripDetailsSharedColumnDefinitions.rowSelected.cellRendererSelector({
            ...params,
            suppressUppercaseTotalsText: true,
          } as TotalTextCellRendererParams);
        },
        colSpan: (params: ColSpanParams) => (BoardUtils.isTotalsRow(params.node) ? 3 : 1),
      },
      ModifyTripDetailsSharedColumnDefinitions.bol(formatValidationService, bolClickCallback),
      ModifyTripDetailsSharedColumnDefinitions.callNbr(onCallNbrClick),
      ModifyTripDetailsShipmentsColumnDefinitions.proNbr(shipmentClickCallback, proFormatterPipe),
      {
        headerName: ModifyTripDetailsDetailGridHeaders.TRAILER_NBR,
        field: ModifyTripDetailsDetailGridFields.TRAILER_NBR,
        colId: ModifyTripDetailsDetailGridFields.TRAILER_NBR,
        width: 100,
        valueGetter: (params: ValueGetterParams) => {
          const item = params.data as ModifyTripDetailsActivityDetailGridItem;
          return item?.trailerNbr ?? item?.equipment?.trailers[0];
        },
      },
      ModifyTripDetailsSharedColumnDefinitions.destinationSic,
      {
        headerName: ModifyTripDetailsDetailGridHeaders.ZIP_CODE,
        field: ModifyTripDetailsDetailGridFields.ZIP_CODE,
        colId: ModifyTripDetailsDetailGridFields.ZIP_CODE,
        width: 130,
        valueGetter: (params) => {
          const item = params.data as ModifyTripDetailsActivityDetailGridItem;
          return item?.zip6;
        },
      },
      ModifyTripDetailsSharedColumnDefinitions.palletCount,
      ModifyTripDetailsSharedColumnDefinitions.piecesCount,
      ModifyTripDetailsSharedColumnDefinitions.weightLbs,
      ModifyTripDetailsSharedColumnDefinitions.motorMovesNbr,
      ModifyTripDetailsSharedColumnDefinitions.cubeNbr,
      ModifyTripDetailsSharedColumnDefinitions.specialServices(specialServicesService),
      ModifyTripDetailsSharedColumnDefinitions.tenderStatus(isCartageTrip, tenderStatusCdPipe),
    ];
  };

  /**
   * Returns column definitions for Deliveries detail grid
   */
  static deliveryLineItemsColumnDefs = (
    formatValidationService: XpoLtlFormatValidationService,
    specialServicesService: SpecialServicesService,
    proFormatterPipe: ProFormatterPipe,
    timeService: XpoLtlTimeService,
    isCartageTrip: boolean,
    tenderStatusCdPipe: CarrierTenderStatusCdPipe,
    shipmentClickCallback: (id: XpoLtlShipmentDescriptor) => void,
    bolClickCallback: (gridItem: ModifyTripDetailsBaseGridItem) => void
  ): ColDef[] => {
    return [
      ModifyTripDetailsDetailColumnDefinitions.emptySpaceExpandColDef,
      {
        ...ModifyTripDetailsSharedColumnDefinitions.rowSelected,
        headerCheckboxSelection: false,
        width: 50,
        cellRendererSelector: (params: ICellRendererParams) => {
          return ModifyTripDetailsSharedColumnDefinitions.rowSelected.cellRendererSelector({
            ...params,
            suppressUppercaseTotalsText: true,
          } as TotalTextCellRendererParams);
        },
      },

      ModifyTripDetailsSharedColumnDefinitions.bol(formatValidationService, bolClickCallback),
      ModifyTripDetailsShipmentsColumnDefinitions.proNbr(shipmentClickCallback, proFormatterPipe),
      ModifyTripDetailsShipmentsColumnDefinitions.handlingUnits,
      ModifyTripDetailsSharedColumnDefinitions.billCount,
      ModifyTripDetailsShipmentsColumnDefinitions.originSic,
      ModifyTripDetailsShipmentsColumnDefinitions.serviceDate,
      ModifyTripDetailsShipmentsColumnDefinitions.loadedInd,
      ModifyTripDetailsShipmentsColumnDefinitions.destSicEta(timeService),
      ModifyTripDetailsSharedColumnDefinitions.palletCount,
      ModifyTripDetailsSharedColumnDefinitions.piecesCount,
      ModifyTripDetailsSharedColumnDefinitions.weightLbs,
      ModifyTripDetailsSharedColumnDefinitions.motorMovesNbr,
      ModifyTripDetailsSharedColumnDefinitions.cubeNbr,
      ModifyTripDetailsSharedColumnDefinitions.specialServices(specialServicesService),
      ModifyTripDetailsSharedColumnDefinitions.tenderStatus(isCartageTrip, tenderStatusCdPipe),
    ];
  };

  /**
   * Returns column definitions for Equipment detail grid
   */
  static equipmentLineItemsColumnDefs = (): ColDef[] => {
    const equipmentDef = (headerName: string, field: string, index: number): ColDef => {
      return {
        headerName,
        colId: field,
        width: 100,
        valueGetter: (params: ValueGetterParams) => {
          const equipment = params.data.equipment as GridActivityEquipment;
          return equipment ? equipment[field][index] : '';
        },
      };
    };

    return [
      ModifyTripDetailsDetailColumnDefinitions.emptySpaceExpandColDef,
      equipmentDef('Trailer 1', 'trailers', 0),
      equipmentDef('Dolly 1', 'dollies', 0),
      equipmentDef('Trailer 2', 'trailers', 1),
      equipmentDef('Dolly 2', 'dollies', 1),
      equipmentDef('Trailer 3', 'trailers', 2),
    ];
  };
}
