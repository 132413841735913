<div class="carousel-container" resize-observer (resize)="onResize($event)">
  <div
    #leftContainer
    class="carousel-actions carousel-actions__left"
    [style.cursor]="canScrollLeft ? 'pointer' : 'not-allowed'"
    (click)="onLeft()"
  >
    <div
      *ngIf="scrollWhileDragging && (dragging || isDragging)"
      class="carousel-actions__left-overlay"
      [style.top]="leftOverlayPosition.top + 'px'"
      [style.left]="leftOverlayPosition.left + 'px'"
      [style.height]="leftOverlayPosition.height + 'px'"
      [style.width]="leftOverlayPosition.width + 'px'"
      (mouseover)="onMouseOverLeft()"
      (mouseout)="onMouseOutLeft()"
    ></div>
    <ng-template *ngIf="$prev; else defaultPrev" [ngTemplateOutlet]="$prev"></ng-template>
    <ng-template #defaultPrev>
      <div class="carousel-actions-info carousel-actions-info__left" *ngIf="canScrollLeft">
        <span *ngIf="displayRemainingItems && remainingLeft !== 0 && !hideRemainingItemsText"
          >{{ remainingLeft }} More</span
        >
        <mat-icon>arrow_back_ios</mat-icon>
      </div>
    </ng-template>
  </div>

  <div
    #carouselItems
    [style.overflow-x]="displayScrollBar ? 'scroll' : 'hidden'"
    [cdkDropListDisabled]="!sortable"
    (cdkDropListDropped)="onItemOrderChange($event)"
    cdkScrollable
    cdkDropList
    cdkDropListOrientation="horizontal"
    class="carousel-items"
  >
    <div
      class="carousel-item"
      [style.scroll-snap-align]="scrollSnapAlign"
      *ngFor="let item of items; trackBy: trackItemBy; let i = index"
      cdkDrag
    >
      <ng-template
        [ngTemplateOutlet]="$item"
        [ngTemplateOutletContext]="{
          $implicit: item,
          index: i
        }"
      ></ng-template>
    </div>
  </div>

  <div
    #rightContainer
    class="carousel-actions carousel-actions__right"
    [style.cursor]="canScrollRight ? 'pointer' : 'not-allowed'"
    (click)="onRight()"
  >
    <div
      *ngIf="scrollWhileDragging && (dragging || isDragging)"
      class="carousel-actions__right-overlay"
      [style.top]="rightOverlayPosition.top + 'px'"
      [style.left]="rightOverlayPosition.left + 'px'"
      [style.height]="rightOverlayPosition.height + 'px'"
      [style.width]="rightOverlayPosition.width + 'px'"
      (mouseover)="onMouseOverRight()"
      (mouseout)="onMouseOutRight()"
    ></div>
    <ng-template *ngIf="$next; else defaultNext" [ngTemplateOutlet]="$next"></ng-template>
    <ng-template #defaultNext>
      <div class="carousel-actions-info carousel-actions-info__right" *ngIf="canScrollRight">
        <mat-icon>arrow_forward_ios</mat-icon>
        <span *ngIf="displayRemainingItems && remainingRight !== 0 && !hideRemainingItemsText"
          >{{ remainingRight }} More</span
        >
      </div>
    </ng-template>
  </div>
</div>
