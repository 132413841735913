import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';

export enum SicZoneSelectionType {
  HOST_ONLY = 'HostOnly',
  ZONES_ONLY = 'ZonesOnly',
  HOST_AND_ZONES = 'HostAndZones',
}

export const ZoneIndicatorCdMap = {
  [SicZoneSelectionType.HOST_AND_ZONES]: ZoneIndicatorCd.INCLUDE_ZONES,
  [SicZoneSelectionType.HOST_ONLY]: ZoneIndicatorCd.NO_ZONES,
  [SicZoneSelectionType.ZONES_ONLY]: ZoneIndicatorCd.ZONES_ONLY,
};
