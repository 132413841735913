import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';

export enum TripActivityExtendedCd {
  MixedActivity = 'Mixed',
  ForecastedPickup = 'ForecastedPU',
}

/**
 * union of TripNodeActivityCd and MixedActivityCd
 */
export type TripNodeActivityExtendedCd = TripNodeActivityCd | TripActivityExtendedCd;
