
import { Injectable } from '@angular/core';
import { LatLong } from '@xpo-ltl/sdk-common';
import { forEach as _forEach, size as _size } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapMarkersService } from './map-markers.service';

export const DEFAULT_ZOOM = 17;
export const DEFAULT_PADDING = 40;
export const BREAKPOINT_ZOOM = 15;

@Injectable({ providedIn: 'root' })
export class MappingService {
  constructor(private markersService: MapMarkersService) {}

  private geoPoints: Array<LatLong> = [];
  private geoPointsSubject = new BehaviorSubject<LatLong[]>(undefined);
  readonly geoPoints$ = this.geoPointsSubject.asObservable();

  private mapBoundsSubject = new Subject<google.maps.LatLngBounds>();
  readonly mapBounds$ = this.mapBoundsSubject.asObservable();

  private isCursorOnMapSubject = new BehaviorSubject<boolean>(false);
  readonly isCursorOnMap$ = this.isCursorOnMapSubject.asObservable();

  setCursorOnMapSubject(isCursorOnMap: boolean) {
    this.isCursorOnMapSubject.next(isCursorOnMap);
  }

  setMapBounds(bounds: google.maps.LatLngBounds) {
    this.mapBoundsSubject.next(bounds);
  }

  updateMarkersBounds(items: { latitudeNbr: number; longitudeNbr: number }[], map: google.maps.Map) {
    const mapBounds = map.getBounds();
    let boundsExtended = false;

    _forEach(items, (item) => {
      const lat = item?.latitudeNbr;
      const lng = item?.longitudeNbr;
      if (lat && lng) {
        const coordinates = { lat, lng };
        if (!mapBounds.contains(coordinates)) {
          // when hovering, items will come only with that individual hovered item, so we just pan to it
          if (_size(items) === 1) {
            map.panTo(coordinates);

            // when selecting, we need to account for multiple items, hence extending bounds
          } else {
            mapBounds.extend(coordinates);
            boundsExtended = true;
          }
        }
      }
    });

    if (boundsExtended) {
      map.fitBounds(mapBounds);
      map.setCenter(mapBounds.getCenter());
    }
  }

  /**
   * Tries to center the map given lat lng bounds
   */
  centerZoomMap(bounds: google.maps.LatLngBounds, map: google.maps.Map, fixedZoom: boolean) {
    if (bounds) {
      map.setCenter(bounds.getCenter());

      if (fixedZoom) {
        map.setZoom(DEFAULT_ZOOM);
      } else {
        map.fitBounds(bounds, DEFAULT_PADDING);

        if (map.getZoom() >= BREAKPOINT_ZOOM) {
          map.setZoom(map.getZoom() - 1);
        }
      }
    }
  }
}
