import { Action } from '@ngrx/store';
import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import { DriverIdentifier, EventItem } from 'app/inbound-planning/shared';

export enum ActionTypes {
  setDriversCurrentLocation = 'drivers.setDriversCurrentLocation',
  fetchDriversCurrentLocation = 'drivers.fetchDriversCurrentLocation',
  updateDriversCurrentLocation = 'drivers.updateDriversCurrentLocation',
  updateHighlightedDriver = 'drivers.updateHighlightedDriver',
  updateFocusedDriver = 'drivers.updateFocusedDriver',
}

export class SetDriversCurrentLocationAction implements Action {
  readonly type = ActionTypes.setDriversCurrentLocation;
  constructor(public payload: { driversCurrentLocation: DriverLocation[] }) {}
}

export class FetchDriversCurrentLocationAction implements Action {
  readonly type = ActionTypes.fetchDriversCurrentLocation;
  constructor(public payload: { sicCd: string; zoneIndicatorCd: ZoneIndicatorCd }) {}
}

export class UpdateDriversCurrentLocationAction implements Action {
  readonly type = ActionTypes.updateDriversCurrentLocation;
  constructor(public payload: { updatedDriverLocations: DriverLocation[] }) {}
}

export class UpdateHighlightedDriver implements Action {
  readonly type = ActionTypes.updateHighlightedDriver;
  constructor(public payload: { highlightedDriverEmpId: string }) {}
}

export class SetFocusedDriver implements Action {
  readonly type = ActionTypes.updateFocusedDriver;
  constructor(public payload: { focusedDriver: EventItem<DriverIdentifier> }) {}
}

export type Actions =
  | SetDriversCurrentLocationAction
  | FetchDriversCurrentLocationAction
  | UpdateDriversCurrentLocationAction
  | UpdateHighlightedDriver
  | SetFocusedDriver;
