import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { DriverIdentifier, EventItem } from 'app/inbound-planning/shared';

export const StateKey = 'driversState';

export interface State {
  readonly driversCurrentLocation: DriverLocation[];
  readonly highlightedDriver: string; // TODO - not sure what the point of this is
  readonly focusedDriver: EventItem<DriverIdentifier>;
}

export const initialState: State = {
  driversCurrentLocation: new Array<DriverLocation>(),
  highlightedDriver: '',
  focusedDriver: { id: undefined, source: undefined },
};
