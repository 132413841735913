import { cloneDeep as _cloneDeep } from 'lodash';
import { tassign } from 'tassign';
import { Actions, ActionTypes } from './geo-location-store.actions';
import { initialState, State } from './geo-location-store.state';

export function geoLocationReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setStatus: {
      return tassign(state, {
        status: action.payload,
      });
    }

    case ActionTypes.setEditMode: {
      return tassign(state, {
        editMode: action.payload,
      });
    }

    case ActionTypes.setStopToEdit: {
      return tassign(state, {
        stopToEdit: _cloneDeep(action.payload),
      });
    }

    case ActionTypes.endEdit: {
      return tassign(state, {
        stopToEdit: undefined,
        status: undefined,
        editMode: undefined,
      });
    }

    default: {
      return state;
    }
  }
}
