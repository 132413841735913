export enum PrintPreferenceFormFields {
  PndManifestTriggerCd = 'pndManifestTriggerCd',
  PndManifestPrinter = 'pndManifestPrinter',
  PndManifestTray = 'P&pndManifestTray',
  ObBreakoutTriggerCd = 'obBreakoutTriggerCd',
  ObBreakoutPrinter = 'obBreakoutPrinter',
  ObBreakoutTray = 'obBreakoutTray',
  FbdsTriggerCd = 'fbdsTriggerCd',
  FbdsLanguage = 'fbdsLanguage',
  FbdsPrinter = 'fbdsPrinter',
  FbdsTray = 'fbdsTray',
  DsrCheckinTriggerCd = 'dsrCheckinTriggerCd',
  DsrCheckinPrinter = 'dsrCheckinPrinter',
  DsrCheckinTray = 'dsrCheckinTray',
  FbdsReprintInd = 'fbdsReprintInd',
  ObBreakoutSlcInd = 'obBreakoutSlcInd',
}
