import { Directive, Input, AfterViewInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { fromEvent, Observable } from 'rxjs';
import { switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { LayoutManagerService } from '../../../../shared/layout-manager';

@Directive({
  selector: '[xpoTriggerMenu]',
})
export class XpoTriggerMenuDirective implements AfterViewInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  @Input() trigger: ElementRef;
  @Input() refreshPosition$: Observable<void>;
  @Input() isActive$: Observable<boolean>;

  private lastPanelStatus: boolean = false;
  private elContent: any;
  private elIndicatorContent: any;
  private elShowButton: any;
  private elHideButton: any;
  private positionRefreshInterval: any;

  constructor(public el: ElementRef, public renderer: Renderer2, public layoutManagerService: LayoutManagerService) {
    renderer.addClass(el.nativeElement, 'xpo-map-bar');
  }
  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  ngAfterViewInit(): void {
    this.elContent = this.el.nativeElement.getElementsByClassName('xpoTriggerMenuContent')[0];
    this.elIndicatorContent = this.el.nativeElement.getElementsByClassName('xpoTriggerMenuIndicator')[0];

    this.renderer.addClass(this.elIndicatorContent, 'xpo-bar-open');
    this.renderer.addClass(this.elContent, 'xpo-bar-closed');

    this.elShowButton = this.el.nativeElement.getElementsByClassName('xpoTriggerShowContentButton')[0];
    this.elHideButton = this.el.nativeElement.getElementsByClassName('xpoTriggerHideContentButton')[0];

    // To set container position
    this.setElementPosition(this.el.nativeElement);
    this.initSubscriptions();
  }

  initSubscriptions() {
    if (this.isActive$) {
      this.isActive$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((value) => {
        if (!value) {
          this.hideAll();
          if (this.positionRefreshInterval) {
            clearInterval(this.positionRefreshInterval);
          }
        } else {
          this.onUpdatePanelStatus(value, value);

          this.positionRefreshInterval = setInterval(() => {
            this.setElementPosition(this.el.nativeElement);
          }, 100);
        }
      });
    }

    if (this.refreshPosition$) {
      this.refreshPosition$
        .pipe(
          switchMap(() => this.isActive$),
          takeUntil(this.unsubscriber.done$)
        )
        .subscribe((value) => {
          this.hideAll();
          setTimeout(() => this.onUpdatePanelStatus(value, value, this.trigger?.['_elementRef']?.nativeElement), 500);
        });
    }

    if (this.elShowButton) {
      fromEvent(this.elShowButton, 'click')
        .pipe(withLatestFrom(this.isActive$), takeUntil(this.unsubscriber.done$))
        .subscribe(([event, isActive]) => this.onUpdatePanelStatus(true, isActive));
    }

    if (this.elHideButton) {
      fromEvent(this.elHideButton, 'click')
        .pipe(withLatestFrom(this.isActive$), takeUntil(this.unsubscriber.done$))
        .subscribe(([event, isActive]) => this.onUpdatePanelStatus(false, isActive));
    }

    this.layoutManagerService.stateChanged$
      .pipe(
        switchMap(() => this.isActive$),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((value) => {
        this.onUpdatePanelStatus(this.lastPanelStatus, value);
      });
  }

  setElementPosition(refEl: Element) {
    const element = this.trigger?.['_elementRef']?.nativeElement;
    const elementRect = element.getBoundingClientRect();
    const currentElRect = refEl.getBoundingClientRect();

    const bodyRect = this.el?.nativeElement?.parentElement?.parentElement?.getBoundingClientRect();
    const maxTop = bodyRect.height - currentElRect.height;

    if (elementRect.y > maxTop) {
      this.el.nativeElement.style.top = '';
      this.el.nativeElement.style.bottom = `0`;
    } else {
      this.el.nativeElement.style.bottom = '';
      this.el.nativeElement.style.top = `${elementRect.y}px`;
    }
    this.el.nativeElement.style.left = `${elementRect.x - currentElRect.width}px`;
  }

  onUpdatePanelStatus(isPanelOpen: boolean, isPanelActive: boolean, trigger = this.el.nativeElement): void {
    if (this.elContent && this.elIndicatorContent) {
      if (isPanelActive) {
        if (isPanelOpen) {
          // show content and hide indicator
          this.renderer.removeClass(this.elContent, 'xpo-bar-closed');
          this.renderer.addClass(this.elContent, 'xpo-bar-open');

          this.renderer.addClass(this.elIndicatorContent, 'xpo-bar-closed');
          this.renderer.removeClass(this.elIndicatorContent, 'xpo-bar-open');
        } else {
          // show indicator and hide content
          this.renderer.addClass(this.elContent, 'xpo-bar-closed');
          this.renderer.removeClass(this.elContent, 'xpo-bar-open');

          this.renderer.removeClass(this.elIndicatorContent, 'xpo-bar-closed');
          this.renderer.addClass(this.elIndicatorContent, 'xpo-bar-open');
        }
        this.lastPanelStatus = isPanelOpen;
      } else {
        this.hideAll();
      }

      this.setElementPosition(trigger);
    }
  }

  hideAll() {
    // show indicator and hide content
    this.renderer.removeClass(this.elContent, 'xpo-bar-open');
    this.renderer.removeClass(this.elIndicatorContent, 'xpo-bar-open');

    this.renderer.addClass(this.elContent, 'xpo-bar-closed');
    this.renderer.addClass(this.elIndicatorContent, 'xpo-bar-closed');
  }
}
