import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalFiltersStoreEffects } from './global-filters-store.effects';
import { globalFilterReducer } from './global-filters-store.reducers';
import { StateKey } from './global-filters-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, globalFilterReducer),
    EffectsModule.forFeature([GlobalFiltersStoreEffects]),
  ],
})
export class GlobalFiltersStoreModule {}
