import { tassign } from 'tassign';
import { Actions, ActionTypes } from './unassigned-pickups-store.actions';
import { initialState, State } from './unassigned-pickups-store.state';

export function unassignedPickupsReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        criteria: action.payload.criteria,
      });
    }

    case ActionTypes.setLastUpdate: {
      return tassign(state, {
        lastUpdate: action.payload.lastUpdate,
      });
    }

    case ActionTypes.setSelectedUnassignedPickups: {
      return tassign(state, {
        selectedPickups: action.payload.selectedPickups,
      });
    }

    case ActionTypes.setFocusedUnassignedPickup: {
      return tassign(state, {
        focusedPickup: action.payload.focusedPickup,
      });
    }

    case ActionTypes.setFilteredUnassignedPickupIds: {
      return tassign(state, {
        filteredUnassignedPickupIds: action.payload.pickupIds,
      });
    }

    case ActionTypes.setPickupDate: {
      return tassign(state, {
        pickupDate: action.payload.pickupDate,
      });
    }

    default: {
      return state;
    }
  }
}
