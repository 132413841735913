import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SpotsAndDropsStoreEffects } from './spots-and-drops-store.effects';
import { spotsAndDropsReducer } from './spots-and-drops-store.reducers';
import { StateKey } from './spots-and-drops-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, spotsAndDropsReducer),
    EffectsModule.forFeature([SpotsAndDropsStoreEffects]),
  ],
})
export class SpotsAndDropsStoreModule {}
