import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'employeeStatusCd' })
export class EmployeeStatusCdPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    switch ((value || '').toUpperCase()) {
      case 'A':
        return 'Active';
      case 'P':
        return 'Leave with pay';
      case 'L':
        return 'Leave without pay';
      case 'S':
        return 'Suspended';
      case 'D':
        return 'Deceased';
      case 'R':
        return 'Retired';
      case 'Q':
        return 'Retired with pay';
      case 'T':
        return 'Terminated';
      default:
        return value;
    }
  }
}
