import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'past-due-notification',
  templateUrl: './past-due-notification.component.html',
  styleUrls: ['./past-due-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastDueNotificationComponent {
  @Input() set pastDuePickupsCount(value: number) {
    this.pastDuePickupsCount$.next(value);
  }

  @Input() isPastDuePickup: boolean;

  readonly pastDuePickupsCount$ = new BehaviorSubject<number>(0);

  @Output() viewIntent = new EventEmitter<boolean>();

  onViewIntent(show: boolean): void {
    this.viewIntent.emit(show);
  }
}
