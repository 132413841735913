import { EquipmentPipe } from '@xpo-ltl/ngx-ltl';
import { Activity, DispatchRoute, DispatchTrip, Route, TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { TripStatusCd, TripNodeActivityCd, TrailerStatusCd } from '@xpo-ltl/sdk-common';
import { PdoEquipmentStatusPipe } from 'app/inbound-planning/shared/pipes/pdo-equipment-status.pipe';
import { TrailerStatusCdPipe } from 'app/inbound-planning/shared/pipes/trailer-status-cd.pipe';
import { size as _size } from 'lodash';
import {
  TripNodeActivityExtendedCd,
  TripActivityExtendedCd,
} from '../app/inbound-planning/shared/models/stop-type.model';
import { AssignedStopIdentifier } from './../app/inbound-planning/shared/interfaces/event-item.interface';
import { PndRouteUtils } from './route-utils';

export class PndTripUtils {
  static getTripId(trip: TripDetail | DispatchTrip): number {
    if (trip) {
      return (trip as DispatchTrip).tripInstId || (trip as TripDetail).trip.tripInstId;
    } else {
      return undefined;
    }
  }

  static getTripDate(trip: TripDetail | DispatchTrip): string {
    return (trip as DispatchTrip)?.tripDate || (trip as TripDetail)?.trip?.tripDate;
  }

  static getTripStatusCd(trip: TripDetail | DispatchTrip): TripStatusCd {
    return (trip as DispatchTrip)?.tripStatusCd || (trip as TripDetail)?.trip?.statusCd;
  }

  /**
   * Return the Route trailer number for the provided Trip
   */
  static getTrailerNbr(trip: TripDetail | DispatchTrip, routeInstId: number, equipmentPipe: EquipmentPipe): string {
    if (!trip) {
      return undefined;
    } else if ('trip' in trip) {
      // TripDetail
      const route: Route = ((trip as TripDetail).route || [])
        .map((rd) => rd.route)
        .find((r) => r.routeInstId === routeInstId);
      return route ? equipmentPipe.transform(route.equipmentIdPrefix, route.equipmentIdSuffixNbr) : undefined;
    } else {
      // DispatchTrip
      const dispatchRoute: DispatchRoute = ((trip as DispatchTrip).dispatchRoutes || []).find(
        (r) => r.routeInstId === routeInstId
      );
      return dispatchRoute ? dispatchRoute.trailerNbr : undefined;
    }
  }

  /**
   * Return the Route trailer status for the provided Trip
   */
  static getTrailerStatusCd(
    trip: TripDetail | DispatchTrip,
    routeInstId: number,
    equipmentStatusPipe: PdoEquipmentStatusPipe,
    trailerStatusCdPipe: TrailerStatusCdPipe
  ): string {
    if (!trip) {
      return undefined;
    } else if ('trip' in trip) {
      // TripDetail
      const routeIndex = trip?.route?.findIndex((r) => r?.route?.routeInstId === routeInstId);
      return routeIndex !== -1
        ? equipmentStatusPipe.transform(
            trailerStatusCdPipe.transform(
              trip?.route[routeIndex]?.trailer?.trailerLoad?.currentStatus as TrailerStatusCd
            )
          )
        : undefined;
    } else {
      // DispatchTrip
      const dispatchRoute: DispatchRoute = trip?.dispatchRoutes?.find((r) => r?.routeInstId === routeInstId);
      return dispatchRoute
        ? equipmentStatusPipe.transform(trailerStatusCdPipe.transform(dispatchRoute?.trailerStatusCd))
        : undefined;
    }
  }

  /**
   * Return the Route name for the provided Trip
   */
  static getRouteId(trip: TripDetail | DispatchTrip, routeInstId: number): string {
    if (!trip) {
      return '';
    } else if ('trip' in trip) {
      // TripDetail
      const route: Route = ((trip as TripDetail).route || [])
        .map((rd) => rd.route)
        .find((r) => r.routeInstId === routeInstId);
      return route ? PndRouteUtils.getRouteId(route) : '';
    } else {
      // DispatchTrip
      const dispatchRoute: DispatchRoute = ((trip as DispatchTrip).dispatchRoutes || []).find(
        (r) => r.routeInstId === routeInstId
      );
      return dispatchRoute ? PndRouteUtils.getRouteId(dispatchRoute) : '';
    }
  }

  /**
   * Return the TripNodeActivityCd for the passed set of activities.  If there
   * is more than one type of Activity, return MixedActivityCd.MIXED_ACTIVITY
   */
  static aggregateTripNodeActivityCd(activities: Activity[]): TripNodeActivityExtendedCd {
    let code: TripNodeActivityExtendedCd;

    for (let i = 0; i < _size(activities) && code !== TripActivityExtendedCd.MixedActivity; i++) {
      const activityCd = activities[i].tripNodeActivity.activityCd;
      if (activityCd !== TripNodeActivityCd.ARRIVE && activityCd !== TripNodeActivityCd.DEPART_DISPATCH) {
        if (!code) {
          code = activityCd;
        } else if (activityCd && code !== activityCd) {
          code = TripActivityExtendedCd.MixedActivity;
        }
      }
    }

    return code;
  }

  /**
   * return a string ID for the given AssignedStopIdentifier
   */
  static routeToId(id: AssignedStopIdentifier): string {
    const routeInstId = id?.routeInstId;
    return routeInstId ? `${routeInstId}` : undefined;
  }
}
