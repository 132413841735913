import { Pipe, PipeTransform } from '@angular/core';
import platform from 'platform-detect';

@Pipe({ name: 'keyboardKeyConverter' })
export class KeyboardKeyConverterPipe implements PipeTransform {
  transform(value: string): string {
    switch (value.toLowerCase()) {
      case 'shift':
        return '⇧';
      case 'alt':
        return platform.macos ? '⌥' : 'alt';
    }

    return value;
  }
}
