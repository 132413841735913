import { DispatchTrip } from '@xpo-ltl/sdk-cityoperations';
import {
  AppointmentStatusCd,
  CarrierTenderGroupStatusCd,
  CmsTripTypeCd,
  ShipmentSpecialServiceCd,
  TrailerStatusCd,
  TripStatusCd,
} from '@xpo-ltl/sdk-common';
import { PndTripDriver } from '../../../shared/models/trip-driver.model';
import { LastStopStatusCd } from '../enums/last-stop-status-cd.enum';

export class DispatcherTripsGridItem {
  dispatchTrip: DispatchTrip;
  dsrCellPhoneNbr: string;
  handheldPhoneNbr: string;
  uniqTripId: string;
  tripInstId: number;
  sicCd: string;
  tripStatusCd: TripStatusCd;
  driver: PndTripDriver;
  displayNoAutoLabel: boolean;
  driverStart: string;
  driverLunch: string;
  driverEnd: string;
  equipmentTractor: string;
  tripStart: Date;
  tripClear: Date;
  tripComplete: Date;
  remainingDeliveries: number;
  completedDeliveries: number;
  remainingPickups: number;
  completedPickups: number;
  returnCount: number;
  totalStopCount: number;

  lastStopTypeCd: string;
  lastStopStatus: LastStopStatusCd;
  lastStopDateTime: Date;
  lastStopArrivalDateTime: Date;
  lastStopDwellTime: number;
  weightRemainingDeliveries: number;
  weightRemainingPickups: number;
  weightCompletedDeliveries: number;
  weightCompletedPickups: number;
  specialServices: ShipmentSpecialServiceCd[];
  routeIndex: number;
  routes: DispatcherTripsDetailGridItem[];

  cmsCarrierId?: number;
  cmsTripTypeCd?: CmsTripTypeCd;
  carrierTenderGroupStatusCd?: CarrierTenderGroupStatusCd;

  // Used for the TOTALS row at the bottom of the grid
  totalRecordCount?: string;
  appointmentStatusCd: AppointmentStatusCd;

  isDockDrop?: boolean;
  isDockPickup?: boolean;

  fbdsPrintCountCd: string;
  stopExceptionsInd?: boolean;
}

export class DispatcherTripsDetailGridItem {
  uniqRouteId: string;
  routePrefix: string;
  routeSuffix: string;
  routeSIC: string;
  trailerNbr: string;
  trailerStatusCd: TrailerStatusCd;
  trailerFeetAvailable: number;
  trailerYear: number;
  plannedDoor: string;
  eventDoor: string;
  sequenced: boolean;
}
