import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'unmapped-notification',
  templateUrl: './unmapped-notification.component.html',
  styleUrls: ['./unmapped-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnmappedNotificationComponent {
  @Input() set unmappedPickupsCount(value: number) {
    this.unmappedPickupsCount$.next(value);
  }

  @Input() serviceType: string;

  readonly unmappedPickupsCount$ = new BehaviorSubject<number>(0);

  @Output() viewIntent = new EventEmitter<boolean>();

  onViewIntent(show: boolean): void {
    this.viewIntent.emit(show);
  }
}
