<button
    mat-stroked-button
    [disabled]="disabled$ | async"
    class="pnd-PolygonSelection"
    (click)="togglePolygonDraw()"
    [ngClass]="{ 'pnd-PolygonSelection--checked': inDrawMode }">
  <div class="icon">
    <xpo-icon iconName="draw-polygon"></xpo-icon>
  </div>
  <div class="desc">
    Draw
  </div>
</button>
