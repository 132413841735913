import { Component, ViewEncapsulation } from '@angular/core';
import { Route } from '@xpo-ltl/sdk-cityoperations';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface RowMouseOverResponse {
  metrics: any;
  route: Route;
  routeInstId: number;
  tripDsrName: string;
}

export interface ActionLinkCellRendererParams extends ICellRendererParams {
  onClick?: (data: any) => void;
  onHover?: (data: any) => void;
  useIcon?: string;
  isVisible?: (data: any) => boolean;
  hideIcon?: boolean;
}

@Component({
  selector: 'pnd-action-link-cell-renderer',
  templateUrl: './action-link-cell-renderer.html',
  styleUrls: ['./action-link-cell-renderer.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-ActionLinkCellRenderer' },
})
export class ActionLinkCellRendererComponent implements ICellRendererAngularComp {
  params: ActionLinkCellRendererParams;

  agInit(params: ActionLinkCellRendererParams): void {
    this.params = params;
  }

  refresh(params: ActionLinkCellRendererParams): boolean {
    this.params = params;
    return true;
  }

  mouseOverFunction(clickEvent: MouseEvent): void {
    if (this.params.onHover) {
      const isUnfocus = clickEvent.type === 'mouseout';
      this.params.onHover({ focusedRow: this.params.data, unFocus: isUnfocus });
      clickEvent.stopImmediatePropagation(); // Prevents selecting the row when link clicked.
    }
  }

  onClickFunction(clickEvent: MouseEvent): void {
    if (this.params.onClick) {
      this.params.onClick(this.params.data);
      clickEvent.stopImmediatePropagation();
    }
  }

  isVisible(): boolean {
    return !this.params.isVisible || this.params.isVisible(this.params.data);
  }

  isIconVisible(): boolean {
    return !this.params.hideIcon;
  }
}
