<div class="NotesCellRenderer">
  <div class="NotesCellRenderer__Header">
    <span class="NotesCellRenderer__Header__NoteType">{{ note?.type | noteTypeCd }} </span>
    <button class="NotesCellRenderer__Header__Copy" type="button" ngxClipboard [cbContent]="note?.note">Copy</button>
  </div>
  <div class="NotesCellRenderer__Note">
    <span class="NotesCellRenderer__Note__Text">
      {{ note?.note }}
    </span>
  </div>
</div>
