import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigManagerModule } from '@xpo-ltl/config-manager';
import { RoutesStoreEffects } from './routes-store.effects';
import { routesReducer } from './routes-store.reducers';
import { StateKey } from './routes-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigManagerModule,
    StoreModule.forFeature(StateKey, routesReducer),
    EffectsModule.forFeature([RoutesStoreEffects]),
  ],
})
export class RoutesStoreModule {}
