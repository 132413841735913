import { Action } from '@ngrx/store';
import { DockRoutesActivityId } from '@pnd-store/dock-routes-store/dock-routes.state';
import { DockStop } from '@xpo-ltl/sdk-cityoperations';

export enum ActionTypes {
  setDockStops = 'dockRoutes.setDockStops',
  setSelectedDockActivities = 'dockRoutes.setSelectedDockActivities',
  setIncompleteDockStops = 'dockRoutes.setIncompleteDockActivities',
  refresh = 'dockRoutes.refresh',
}

export class SetDockStops implements Action {
  readonly type = ActionTypes.setDockStops;
  constructor(public payload: { dockStops: DockStop[] }) {}
}

export class SetIncompleteDockStops implements Action {
  readonly type = ActionTypes.setIncompleteDockStops;
  constructor(public payload: { incompleteDockStops: DockStop[] }) {}
}
export class SetSelectedDockActivities implements Action {
  readonly type = ActionTypes.setSelectedDockActivities;
  constructor(public payload: { selectedDockActivities: DockRoutesActivityId[] }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions = SetDockStops | SetSelectedDockActivities | SetIncompleteDockStops | Refresh;
