import {
  NumericEditorComponent,
  SelectEditorComponent,
  CellEditionErrorCellRendererComponent,
} from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridPaginatedSelectAllCheckbox } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoStringFilterComponent,
  XpoDateTimeFilterComponent,
  XpoTimeRangeFilterComponent,
  XpoBooleanFilterComponent,
  XpoDateOnlyFilterComponent,
  XpoNumberFilterComponent,
  XpoTimeFilterComponent,
  XpoEnumFilterComponent,
} from '@xpo-ltl/ngx-board/core';
import { DockRoutesDetailGridRendererComponent } from '../../components/trips/dock-routes/components/dock-routes-detail-grid-renderer';
import { ModifyTripDetailsDetailGridRendererComponent } from '../../components/trips/modify-trip-details/modify-trip-details-detail-grid-renderer/modify-trip-details-detail-grid-renderer.component';
import { PndAccordionGroupCellActionRendererComponent } from './accordion-group-cell-action-renderer/accordion-group-cell-action-renderer.component';
import { ActionLinkCellRendererComponent } from './action-link-cell-renderer/action-link-cell-renderer';
import { ActionsCellRendererComponent } from './actions-cell-renderer';
import { XpoAgGridSelectAllCheckbox } from './ag-grid-select-all';
import { AlarmHeaderRendererComponent } from './alarm-header-renderer';
import { AutocompleteEditorComponent } from './autocomplete-editor/autocomplete-editor.component';
import { BooleanDotCellRendererComponent } from './boolean-dot-cell-renderer/boolean-dot-cell-renderer.component';
import { CheckmarkCellRendererComponent } from './checkmark-cell-render/checkmark-cell-renderer.component';
import { CheckmarkHeaderRendererComponent } from './checkmark-header-renderer/checkmark-header-renderer.component';
import { CircleAndTextCellRendererComponent } from './circle-and-text-cell-renderer/circle-and-text-cell-renderer.component';
import { CircleCellRendererComponent } from './circle-cell-renderer/circle-cell-renderer.component';
import { XpoBaseFeature } from './configurable-features/base-feature.class';
import { XpoGridBaseCellRendererComponent } from './configurable-features/inline-editing';
import {
  XpoGridAlphanumericCellEditorComponent,
  XpoGridNumericCellEditorComponent,
} from './configurable-features/inline-editing/cell-editors';
import { CustomAccordionGroupCellRendererComponent } from './custom-accordion-group-cell-renderer/custom-accordion-group-cell-renderer.component';
import { CustomerNameLinkCellRender } from './customer-name-link-cell-render/customer-name-link-cell-render';
import { DeliveryWindowTimeRendererComponent } from './delivery-window-time-renderer/delivery-window-time-renderer.component';
import { DetailGridToggleCellRendererComponent } from './detail-grid-toggle-cell-renderer/detail-grid-toggle-cell-renderer.component';
import { DispatchDriverCellRendererComponent } from './dispatch-driver-cell-renderer/dispatch-driver-cell-renderer.component';
import { DispatchExceptionTooltipCellRendererComponent } from './dispatch-exception-tooltip-cell-renderer';
import { DispatchTripExceptionsCellRendererComponent } from './dispatch-trip-exceptions-cell-renderer';
import { DriverEditorComponent } from './driver-editor/driver-editor.component';
import { FilteredEditorComponent } from './filtered-editor/filtered-editor.component';
import { XpoAccordionGroupCellRendererComponent } from './grid-accordian-cell-renderer/xpo-accordion-group-cell-renderer.component';
import { GridDetailNotesTriggerComponent } from './grid-detail-notes-trigger/grid-detail-notes-trigger.component';
import { GridDetailsNotesRenderComponent } from './grid-details-notes-render/grid-details-notes-render.component';
import { GroupedCountCellRendererComponent } from './grouped-count-cell-renderer/grouped-count-cell-renderer.component';
import { HandlingUnitsCellRendererComponent } from './handling-units-cell-renderer';
import { IconHeaderRendererComponent } from './icon-header-renderer';
import { MatTooltipCellRendererComponent } from './mat-tooltip-cell-renderer/mat-tooltip-cell-renderer.component';
import { ModifyTripDetailsActivityTypeCellRendererComponent } from './modify-trip-details-activity-type-cell-renderer';
import { ModifyTripDetailsCellRendererComponent } from './modify-trip-details-group-cell-renderer';
import { NotesCellRendererComponent } from './notes-cell-renderer/notes-cell-renderer.component';
import { NotesCountCellRendererComponent } from './notes-count-cell-renderer/notes-count-cell-renderer.component';
import { NotesHeaderRendererComponent } from './notes-header-renderer/notes-header-renderer.component';
import { OptimizerUpdateShipmentAlertCellRenderComponent } from './optimizer-update-shipment-alert-cell-render/optimizer-update-shipment-alert-cell-render.component';
import { OversizeCellRendererComponent } from './oversize-cell-renderer/oversize-cell-renderer.component';
import { PickupCallNbrCellRendererComponent } from './pickup-call-number-cell-renderer/pickup-call-nbr-cell-renderer.component';
import { PickupCloseSoonCellRendererComponent } from './pickup-close-soon-cell-renderer/pickup-close-soon-cell-renderer.component';
import { PickupCloseTimeCellRendererComponent } from './pickup-close-time-cell-renderer/pickup-close-time-cell-renderer.component';
import { PickupDateCellRendererComponent } from './pickup-date-cell-renderer/pickup-date-cell-renderer.component';
import { RadioButtonSelectionCellRendererComponent } from './radio-button-selection-cell-renderer/radio-button-selection-cell-renderer.component';
import { ReleaseRouteToggleCellRendererComponent } from './release-route-toggle-cell-renderer';
import { RouteActionLinkCellRendererComponent } from './route-action-link-cell-renderer/route-action-link-cell-renderer';
import { RouteTimelineCellRendererComponent } from './route-timeline-cell-renderer/route-timeline-cell-renderer';
import { RouteTimelineHeaderRendererComponent } from './route-timeline-header-renderer/route-timeline-header-renderer.component';
import { XpoGridSelectCellEditorComponent } from './select-editor/select-editor.component';
import { SelectOptionEditorComponent } from './select-option-editor/select-option-editor.component';
import { DriverCollectIconComponent } from './service-icons/driver-collect-icon.component';
import { GuaranteedIconComponent } from './service-icons/guaranteed-icon.component';
import { HazmatIconComponent } from './service-icons/hazmat-icon.component';
import { LiftGateIconComponent } from './service-icons/lift-gate-icon.component';
import { SpecialServicesCellRendererComponent } from './special-services-cell-renderer/special-services-cell-renderer.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { StatusIndicatorCellRendererComponent } from './status-indicator-cell-renderer/status-indicator-cell-renderer.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { TimeEditorComponent } from './time-editor/time-editor.component';
import { TimeSelectorCellRendererComponent } from './time-selector-cell-renderer/time-selector-cell-renderer.component';
import { TimeSelectorEditorComponent } from './time-selector-editor/time-selector-editor.component';
import { TotalTextCellRendererComponent } from './total-text-cell-renderer/total-text-cell-renderer.component';
import { TypeCountCellRendererComponent } from './type-count-cell-renderer/type-count-cell-renderer.component';
import { UnassignedPickupDetailGridRendererComponent } from './unassigned-pickup-detail-grid-renderer/unassigned-pickup-detail-grid-renderer.component';
import { ZeroAsCellRendererComponent } from './zero-as-cell-renderer/zero-as-cell-renderer.component';

/**
 * Map all of the Angular CellRendererFramework components so they can be referenced by name
 * in column definitions.
 *
 * NOTE: WE MUST DO THIS in order for grid transposing to work!
 */
export const pndFrameworkComponents = {
  xpoGridNumericCellEditorComponent: XpoGridNumericCellEditorComponent,
  xpoAlphaNumericCelleditorComponent: XpoGridAlphanumericCellEditorComponent,
  xpoBaseCellRenderer: XpoGridBaseCellRendererComponent,
  xpoBaseFeature: XpoBaseFeature,
  handlingUnitsCellRenderer: HandlingUnitsCellRendererComponent,
  statusIndicatorCellRenderer: StatusIndicatorCellRendererComponent,
  actionLinkCellRenderer: ActionLinkCellRendererComponent,
  actionsCellRenderer: ActionsCellRendererComponent,
  routeActionLinkCellRenderer: RouteActionLinkCellRendererComponent,
  routeTimelineCellRenderer: RouteTimelineCellRendererComponent,
  routeTimelineHeaderRenderer: RouteTimelineHeaderRendererComponent,
  guaranteedIconCellRenderer: GuaranteedIconComponent,
  hazmatIconCellRenderer: HazmatIconComponent,
  specialServicesCellRenderer: SpecialServicesCellRendererComponent,
  statusChipCellRenderer: StatusChipComponent,
  deliverWindowTimeCellRenderer: DeliveryWindowTimeRendererComponent,
  driverCollectIconCellRenderer: DriverCollectIconComponent,
  liftGateIconCellRenderer: LiftGateIconComponent,
  checkmarkCellRenderer: CheckmarkCellRendererComponent,
  checkmarkHeaderRenderer: CheckmarkHeaderRendererComponent,
  circleCellRenderer: CircleCellRendererComponent,
  circleAndTextCellRenderer: CircleAndTextCellRendererComponent,
  releaseRouteToggleCellRenderer: ReleaseRouteToggleCellRendererComponent,
  totalTextCellRenderer: TotalTextCellRendererComponent,
  typeCountCellRenderer: TypeCountCellRendererComponent,
  groupedCountCellRenderer: GroupedCountCellRendererComponent,
  booleanDotCellRenderer: BooleanDotCellRendererComponent,
  pickupCloseSoonCellRenderer: PickupCloseSoonCellRendererComponent,
  pickupCloseTimeCellRenderer: PickupCloseTimeCellRendererComponent,
  pickupCallNbrCellRenderer: PickupCallNbrCellRendererComponent,
  pickupDateCellRenderer: PickupDateCellRendererComponent,
  OversizeCellRenderer: OversizeCellRendererComponent,
  detailGridToggleCellRenderer: DetailGridToggleCellRendererComponent,
  dispatchDriverCellRendererComponent: DispatchDriverCellRendererComponent,
  unassignedPickupDetailGridRenderer: UnassignedPickupDetailGridRendererComponent,
  alarmHeaderRenderer: AlarmHeaderRendererComponent,
  iconHeaderRenderer: IconHeaderRendererComponent,
  dispatchTripExceptionsRenderer: DispatchTripExceptionsCellRendererComponent,
  dispatchExceptionTooltipCellRenderer: DispatchExceptionTooltipCellRendererComponent,
  notesHeaderRenderer: NotesHeaderRendererComponent,
  notesCountCellRenderer: NotesCountCellRendererComponent,
  notesCellRenderer: NotesCellRendererComponent,
  radioButtonSelectionCellRenderer: RadioButtonSelectionCellRendererComponent,
  xpoStringFilterComponent: XpoStringFilterComponent,
  xpoDateTimeFilterComponent: XpoDateTimeFilterComponent,
  xpoTimeFilterComponent: XpoTimeFilterComponent,
  xpoTimeRangeFilterComponent: XpoTimeRangeFilterComponent,
  xpoEnumFilterComponent: XpoEnumFilterComponent,
  xpoDateOnlyFilterComponent: XpoDateOnlyFilterComponent,
  xpoNumberFilterComponent: XpoNumberFilterComponent,
  xpoBooleanFilterComponent: XpoBooleanFilterComponent,
  xpoAccordionGroupCellRendererComponent: XpoAccordionGroupCellRendererComponent,
  numericCellEditor: NumericEditorComponent,
  selectEditor: SelectEditorComponent,
  xpoSelectEditor: XpoGridSelectCellEditorComponent,
  timeEditor: TimeEditorComponent,
  filteredEditor: FilteredEditorComponent,
  textCellEditor: TextEditorComponent,
  pndAccordionGroupCellActionRendererComponent: PndAccordionGroupCellActionRendererComponent,
  modifyTripDetailsCellRendererComponent: ModifyTripDetailsCellRendererComponent,
  modifyTripDetailsDetailGridRenderer: ModifyTripDetailsDetailGridRendererComponent,
  modifyTripDetailsActivityTypeCellRenderer: ModifyTripDetailsActivityTypeCellRendererComponent,
  CustomerNameLinkCellRender: CustomerNameLinkCellRender,
  gridDetailNotesTriggerCellRender: GridDetailNotesTriggerComponent,
  gridDetailsNotesRenderComponent: GridDetailsNotesRenderComponent,
  optimizerUpdateShipmentAlertCellRenderComponent: OptimizerUpdateShipmentAlertCellRenderComponent,
  customAccordionGroupCellRendererComponent: CustomAccordionGroupCellRendererComponent,
  autocompleteEditor: AutocompleteEditorComponent,
  cellEditionError: CellEditionErrorCellRendererComponent,
  matTooltipCellRenderer: MatTooltipCellRendererComponent,
  zeroAsCellRenderer: ZeroAsCellRendererComponent,
  dockRoutesDetailGridRendererComponent: DockRoutesDetailGridRendererComponent,
  driverEditorComponent: DriverEditorComponent,
  xpoAgGridPaginatedSelectAllCheckbox: XpoAgGridPaginatedSelectAllCheckbox,
  xpoAgGridSelectAllCheckbox: XpoAgGridSelectAllCheckbox,
  timeSelectorCellRenderer: TimeSelectorCellRendererComponent,
  timeSelectorEditor: TimeSelectorEditorComponent,
  selectOptionEditor: SelectOptionEditorComponent,
};

/**
 * List of Angular components that can be used as CellRenderers and CellEditors.
 * used by AgGridModule.withComponents()
 */
export const pndFrameworkComponentsDeclarations = Object.values(pndFrameworkComponents);

// NOTE: For AOT builds to work, you must also add these components to
// the entryComponents in the module!
