import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pnd-grouped-count-cell-renderer',
  template: '<span class="pnd-grouped-count-cell-renderer">{{ params?.valueFormatted || params?.value }}</span>',
  styleUrls: ['./grouped-count-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedCountCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
