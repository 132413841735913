import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { Activity, ActivityShipment, DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { forEach as _forEach, map as _map, max as _max, min as _min } from 'lodash';
import moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class EtaWindowService {
  constructor(private timeService: XpoLtlTimeService) {}

  getEtaWindowFromActivities(sic: string, activities: Activity[]): string {
    if (!activities) {
      return '';
    }

    const destinationSicEtas = [];
    _forEach(activities, (activity: Activity) => {
      _forEach(activity?.activityShipments ?? [], (activityShipment: ActivityShipment) => {
        destinationSicEtas.push(activityShipment?.destSicEta);
      });
    });

    return this.getWindow(sic, destinationSicEtas);
  }

  getEtaWindowFromDeliveryShipments(sic: string, shipments: DeliveryShipmentSearchRecord[]): string {
    if (!shipments) {
      return '';
    }

    return this.getWindow(
      sic,
      _map(shipments, (shipment: DeliveryShipmentSearchRecord) => shipment.destSicEta)
    );
  }

  private getWindow(sic: string, destinationSicEtas: Date[]): string {
    let earliest: Date;
    let latest: Date;

    _forEach(destinationSicEtas, (destinationSicEta) => {
      if (!!destinationSicEta) {
        earliest = !earliest ? destinationSicEta : _min([earliest, destinationSicEta]);
        latest = !latest ? destinationSicEta : _max([latest, destinationSicEta]);
      }
    });

    if (!earliest || !latest) {
      return '';
    }

    // Disregard seconds in comparison
    if (moment(earliest).format('YYYY-MM-DD HH:mm') === moment(latest).format('YYYY-MM-DD HH:mm')) {
      return `${this.timeService.formatDate(earliest, 'MM/DD HH:mm', sic)}`;
    }
    return `${this.timeService.formatDate(earliest, 'MM/DD HH:mm', sic)} - ${this.timeService.formatDate(
      latest,
      'MM/DD HH:mm',
      sic
    )}`;
  }
}
