import { GlobalFilterMapCoordinate } from '../../inbound-planning/shared/models/global-filter-criteria.model';
import { SicZonesAndSatellites } from '../../inbound-planning/shared/models/sic-zones-and-satellites.model';

export const StateKey = 'globalFilterState';

export interface State {
  readonly sic: string;
  readonly sicLatLng: { latitude: number; longitude: number };
  readonly sicZonesAndSatellites: SicZonesAndSatellites;
  readonly planDate: Date;
  readonly geoFilterArea: GlobalFilterMapCoordinate[]; // area to use for filtering
}

export const initialState: State = {
  sic: '',
  sicLatLng: undefined,
  sicZonesAndSatellites: undefined,
  planDate: new Date(),
  geoFilterArea: [],
};
