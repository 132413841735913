import { MapToolbarSelectionItem } from 'app/inbound-planning/shared';

export enum DrawOptionTypes {
  Routes = 'Routes',
  Stops = 'Stops',
  UnassignedDeliveries = 'UNDLs',
  UnassignedPickups = 'UNPUs',
  UnPickupsHE = 'HE',
  UnPickupsSE = 'SE',
  UnPickupsHL = 'HL',
  PlanningRouteShipments = 'P.R. Shipments',
  Trips = 'Trips',
}

export interface DrawOption {
  name: DrawOptionTypes;
  checked: boolean;
  tooltip?: string;
  optionsList?: {
    autoOpen: boolean;
    options: DrawOption[];
  };
  mapToolbarItem?: MapToolbarSelectionItem;
}
