import { Injectable } from '@angular/core';
import { remove as _remove } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { LayoutPreferenceService } from 'shared/layout-manager';
import {
  DrawOption,
  DrawOptionTypes,
} from '../components/map-toolbar-widget/components/draw-options-panel/draw-options-panel.model';
import { WeatherLayer } from '../models/weather-layer.interface';

export interface MapDataStyleItem {
  strokeColor: string;
  strokeWeight: number;
  fillColor: string;
  fillOpacity: number;
  clickable?: boolean;
}

export enum MapToolbarSelectionItem {
  UNASSIGNED_DELIVERIES = 'Unassigned DLs',
  UNASSIGNED_PICKUPS = 'Unassigned Pickups',
  NORMAL_PICKUPS = 'Normal Pickups',
  LOAD_PICKUPS = 'Load Pickups',
  EMPTY_PICKUPS = 'Empty Pickups',
  SPOT_EMPTY_PICKUPS = 'Spot Empty Pickups',
  COMPLETED_STOPS = 'Completed Stops',
  DRIVERS_LOCATION = 'Drivers Location',
  TRAFFIC = 'Traffic',
  GEO_AREAS = 'Geoaraeas',
  GEO_AREAS_DRAW = 'Geoaraeas draw',
  PICKUP_CLUSTERS = 'Pickup Clusters',
  WEATHER_WIDGET = 'Weather Widget',
  GEO_FILTER_DRAW = 'Geofilter Draw',
  POLYGON_DRAW = 'Polygon Draw',
  INDICATOR = 'Indicator',
  FORECASTED_PICKUPS = 'Forecasted Pickups',
}

export const defaultPlannerDrawOptions: DrawOption[] = [
  {
    name: DrawOptionTypes.Routes,
    checked: false,
    tooltip: 'Routes',
  },
  {
    name: DrawOptionTypes.Stops,
    checked: false,
    tooltip: 'Route Stops',
  },
  {
    name: DrawOptionTypes.UnassignedDeliveries,
    checked: true,
    tooltip: 'Unassigned Deliveries',
  },
  {
    name: DrawOptionTypes.UnassignedPickups,
    checked: false,
    tooltip: 'Unassigned Pickups',
  },
  {
    name: DrawOptionTypes.PlanningRouteShipments,
    checked: true,
    tooltip: 'Planning Route Shipments',
  },
];

export const defaultDispatcherDrawOptions: DrawOption[] = [
  {
    name: DrawOptionTypes.Trips,
    checked: false,
    tooltip: 'Trips',
  },
  {
    name: DrawOptionTypes.UnassignedDeliveries,
    checked: false,
    tooltip: 'Unassigned Deliveries',
  },
  {
    name: DrawOptionTypes.UnassignedPickups,
    checked: true,
    tooltip: 'Unassigned Pickups',
    mapToolbarItem: MapToolbarSelectionItem.NORMAL_PICKUPS,
    optionsList: {
      autoOpen: true,
      options: [
        {
          name: DrawOptionTypes.UnPickupsHE,
          checked: false,
          tooltip: 'Hook Empties',
          mapToolbarItem: MapToolbarSelectionItem.EMPTY_PICKUPS,
        },
        {
          name: DrawOptionTypes.UnPickupsSE,
          checked: false,
          tooltip: 'Spot Empties',
          mapToolbarItem: MapToolbarSelectionItem.SPOT_EMPTY_PICKUPS,
        },
        {
          name: DrawOptionTypes.UnPickupsHL,
          checked: false,
          tooltip: 'Hook Loads',
          mapToolbarItem: MapToolbarSelectionItem.LOAD_PICKUPS,
        },
      ],
    },
  },
  {
    name: DrawOptionTypes.PlanningRouteShipments,
    checked: false,
    tooltip: 'Planning Route Shipments',
  },
];

@Injectable({ providedIn: 'root' })
export class MapToolbarService {
  private setMapInstanceSubject = new BehaviorSubject<google.maps.Map>(null);
  readonly mapInstance$ = this.setMapInstanceSubject.asObservable();

  private toggleDrawModeOffSubject = new BehaviorSubject<void>(null);
  readonly toggleDrawModeOff$ = this.toggleDrawModeOffSubject.asObservable();

  private setDrawModeStateSubject = new BehaviorSubject<boolean>(false);
  readonly setDrawModeState$ = this.setDrawModeStateSubject.asObservable();

  private setGeoAreaDrawModeStateSubject = new BehaviorSubject<boolean>(false);
  readonly setGeoAreaDrawModeState$ = this.setGeoAreaDrawModeStateSubject.asObservable();

  private clickOnEditGeoAreaButtonSubject = new BehaviorSubject<boolean>(false);
  readonly clickOnEditGeoAreaButton$ = this.clickOnEditGeoAreaButtonSubject.asObservable();

  private clearGeoAreaFilterSubject = new BehaviorSubject<void>(null);
  readonly clearGeoAreaFilter$ = this.clearGeoAreaFilterSubject.asObservable();

  private compassRotationAngleSubject = new BehaviorSubject<number>(0);
  readonly compassRotationAngle$ = this.compassRotationAngleSubject.asObservable();

  private mapInitializedSubject = new BehaviorSubject<boolean>(false);
  readonly mapInitialized$ = this.mapInitializedSubject.asObservable();

  private sicClickedSubject = new BehaviorSubject<void>(null);
  readonly sicClicked$ = this.sicClickedSubject.asObservable();

  private drawModeChangeSubject = new BehaviorSubject<boolean>(false);
  readonly drawModeChange$ = this.drawModeChangeSubject.asObservable();

  private showNormalDeliveriesSubject = new BehaviorSubject<boolean>(true);
  readonly showNormalDeliveries$ = this.showNormalDeliveriesSubject.asObservable();

  private showSpotLoadDeliveriesSubject = new BehaviorSubject<boolean>(true);
  readonly showSpotLoadDeliveries$ = this.showSpotLoadDeliveriesSubject.asObservable();

  private showNormalPickupsSubject = new BehaviorSubject<boolean>(true);
  readonly showNormalPickups$ = this.showNormalPickupsSubject.asObservable();

  private showForecastedPickupsSubject = new BehaviorSubject<boolean>(false);
  readonly showForecastedPickups$ = this.showForecastedPickupsSubject.asObservable();

  private isDeliveryHeatmapLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isDeliveryHeatmapLayerActive$ = this.isDeliveryHeatmapLayerActiveSubject.asObservable();

  private isPickupHeatmapLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isPickupHeatmapLayerActive$ = this.isPickupHeatmapLayerActiveSubject.asObservable();

  private showHookLoadPickupsSubject = new BehaviorSubject<boolean>(true);
  readonly showHookLoadPickups$ = this.showHookLoadPickupsSubject.asObservable();

  private showHookEmptyPickupsSubject = new BehaviorSubject<boolean>(true);
  readonly showHookEmptyPickups$ = this.showHookEmptyPickupsSubject.asObservable();

  private showSpotEmptyPickupsSubject = new BehaviorSubject<boolean>(true);
  readonly showSpotEmptyPickups$ = this.showSpotEmptyPickupsSubject.asObservable();

  private isUnassignedDeliveriesLayerActiveSubject = new BehaviorSubject<boolean>(true);
  readonly isUnassignedDeliveriesLayerActive$ = this.isUnassignedDeliveriesLayerActiveSubject.asObservable();

  private isUnassignedPickupsLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isUnassignedPickupsLayerActive$ = this.isUnassignedPickupsLayerActiveSubject.asObservable();

  private isDriverLocationLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isDriverLocationLayerActive$ = this.isDriverLocationLayerActiveSubject.asObservable();

  private isSpotsAndDropsLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isSpotsAndDropsLayerActive$ = this.isSpotsAndDropsLayerActiveSubject.asObservable();

  private isTrafficLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isTrafficLayerActive$ = this.isTrafficLayerActiveSubject.asObservable();

  private weatherLayerSubject = new BehaviorSubject<WeatherLayer>(null);
  readonly weatherLayer$ = this.weatherLayerSubject.asObservable();

  private isCompletedStopsLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isCompletedStopsLayerActive$ = this.isCompletedStopsLayerActiveSubject.asObservable();

  private isDispatchAreaLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isDispatchAreaLayerActive$ = this.isDispatchAreaLayerActiveSubject.asObservable();

  private isGeoAreasDrawerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isGeoAreasDrawerActive$ = this.isGeoAreasDrawerActiveSubject.asObservable();

  private unmappedPickupsCountSubject = new BehaviorSubject<number>(0);
  readonly unmappedPickupsCount$ = this.unmappedPickupsCountSubject.asObservable();

  private unmappedPickupShipmentsCountSubject = new BehaviorSubject<number>(0);
  readonly unmappedPickupShipmentsCount$ = this.unmappedPickupShipmentsCountSubject.asObservable();

  private unmappedDeliveriesCountSubject = new BehaviorSubject<number>(0);
  readonly unmappedDeliveriesCount$ = this.unmappedDeliveriesCountSubject.asObservable();

  private unmappedDeliveryShipmentsCountSubject = new BehaviorSubject<number>(0);
  readonly unmappedDeliveryShipmentsCount$ = this.unmappedDeliveryShipmentsCountSubject.asObservable();

  private reviewUnmappedPickupsSubject = new BehaviorSubject<void>(null);
  readonly reviewUnmappedPickups$ = this.reviewUnmappedPickupsSubject.asObservable();

  private reviewUnmappedDeliveriesSubject = new BehaviorSubject<void>(null);
  readonly reviewUnmappedDeliveries$ = this.reviewUnmappedDeliveriesSubject.asObservable();

  private showDrawOptionsPanelSubject = new BehaviorSubject<boolean>(false);
  readonly showDrawOptionsPanel$ = this.showDrawOptionsPanelSubject.asObservable();

  private drawOptionsChangeSubject = new BehaviorSubject<DrawOption[]>([]);
  readonly drawOptionsChange$ = this.drawOptionsChangeSubject.asObservable();

  private drawOptionsPanelLoadingSubject = new BehaviorSubject<boolean>(false);
  readonly drawOptionsPanelLoading$ = this.drawOptionsPanelLoadingSubject.asObservable();

  private isPickupClustersLayerActiveSubject = new BehaviorSubject<boolean>(false);
  readonly isPickupClustersLayerActive$ = this.isPickupClustersLayerActiveSubject.asObservable();

  mapToolbarSelectionItems: MapToolbarSelectionItem[] = [];
  mapToolbarSelectionItemsCache: MapToolbarSelectionItem[] = [];

  constructor(private layoutPreferenceService: LayoutPreferenceService) {
    this.subscribeToLayoutChanges();
  }

  private subscribeToLayoutChanges(): void {
    this.layoutPreferenceService.activeLayout$.subscribe((_) => {
      if (this.layoutPreferenceService.isDispatcherLayout()) {
        this.updateDrawOptions(defaultDispatcherDrawOptions);
      } else {
        this.updateDrawOptions(defaultPlannerDrawOptions);
      }
    });
  }

  updateDrawOptionsPanelLoading(isLoading: boolean): void {
    this.drawOptionsPanelLoadingSubject.next(isLoading);
  }

  updateDrawOptions(options: DrawOption[]): void {
    this.drawOptionsChangeSubject.next(options);
  }

  onReviewUnmappedDeliveries(): void {
    this.reviewUnmappedDeliveriesSubject.next();
  }

  onReviewUnmappedPickups(): void {
    this.reviewUnmappedPickupsSubject.next();
  }

  setUnmappedPickupsCount(n: number): void {
    this.unmappedPickupsCountSubject.next(n);
  }

  setUnmappedPickupShipmentsCount(n: number): void {
    this.unmappedPickupShipmentsCountSubject.next(n);
  }

  setUnmappedDeliveriesCount(n: number): void {
    this.unmappedDeliveriesCountSubject.next(n);
  }

  setUnmappedDeliveryShipmentsCount(n: number): void {
    this.unmappedDeliveryShipmentsCountSubject.next(n);
  }

  setMapInstance(map: google.maps.Map): void {
    this.setMapInstanceSubject.next(map);
  }

  togglePickupClustersLayer(isVisible: boolean): void {
    this.isPickupClustersLayerActiveSubject.next(isVisible);
  }

  toggleTrafficLayer(isVisible: boolean): void {
    this.isTrafficLayerActiveSubject.next(isVisible);
  }

  handleWeatherLayerChange(layer: WeatherLayer) {
    this.weatherLayerSubject.next(layer);
  }

  toggleCompletedStopsLayer(isVisible: boolean): void {
    this.isCompletedStopsLayerActiveSubject.next(isVisible);
  }

  toggleDispatchAreaLayer(isVisible: boolean): void {
    this.isDispatchAreaLayerActiveSubject.next(isVisible);
  }

  toggleGeoAreasDrawer(isVisible: boolean): void {
    this.isGeoAreasDrawerActiveSubject.next(isVisible);
  }

  toggleDriverLocationLayer(isVisible: boolean): void {
    this.isDriverLocationLayerActiveSubject.next(isVisible);
  }

  toggleSpotsAndDropsLayer(isVisible: boolean): void {
    this.isSpotsAndDropsLayerActiveSubject.next(isVisible);
  }

  toggleUnassignedPickupsLayer(isVisible: boolean): void {
    this.isUnassignedPickupsLayerActiveSubject.next(isVisible);
  }

  toggleUnassignedDeliveriesLayer(isVisible: boolean): void {
    this.isUnassignedDeliveriesLayerActiveSubject.next(isVisible);
  }

  handleDrawNormalDeliveries(shouldDraw: boolean) {
    this.showNormalDeliveriesSubject.next(shouldDraw);
  }

  handleDrawSpotLoadDeliveries(shouldDraw: boolean) {
    this.showSpotLoadDeliveriesSubject.next(shouldDraw);
  }

  handleDrawNormalPickups(shouldDraw: boolean) {
    this.showNormalPickupsSubject.next(shouldDraw);
  }

  handleDrawForecastedPickups(shouldDraw: boolean) {
    this.showForecastedPickupsSubject.next(shouldDraw);
  }

  handleDrawDeliveryHeatmap(shouldDraw: boolean) {
    this.isDeliveryHeatmapLayerActiveSubject.next(shouldDraw);
  }

  handleDrawPickupHeatmap(shouldDraw: boolean) {
    this.isPickupHeatmapLayerActiveSubject.next(shouldDraw);
  }

  handleDrawHookLoadPickups(shouldDraw: boolean) {
    this.showHookLoadPickupsSubject.next(shouldDraw);
  }

  handleDrawHookEmptyPickups(shouldDraw: boolean) {
    this.showHookEmptyPickupsSubject.next(shouldDraw);
  }

  handleDrawSpotEmptyPickups(shouldDraw: boolean) {
    this.showSpotEmptyPickupsSubject.next(shouldDraw);
  }

  handleDrawOptionsPanelChange(isVisible: boolean) {
    this.showDrawOptionsPanelSubject.next(isVisible);
  }

  handleDrawModeChange(inDrawMode: boolean): void {
    this.drawModeChangeSubject.next(inDrawMode);
  }

  handleSicClicked(): void {
    this.sicClickedSubject.next();
  }

  setMapInitialized(flag: boolean): void {
    this.mapInitializedSubject.next(flag);
  }

  setMarkerCompassRotationAngle(angle): void {
    this.compassRotationAngleSubject.next(angle);
  }

  toggleDrawModeOff(): void {
    this.toggleDrawModeOffSubject.next();
  }

  setDrawModeState(enabled: boolean): void {
    this.setDrawModeStateSubject.next(enabled);
  }

  setGeoAreaDrawModeState(enabled: boolean): void {
    this.setGeoAreaDrawModeStateSubject.next(enabled);
  }

  clearGeoFilter() {
    this.clearGeoAreaFilterSubject.next();
  }

  clickOnEditGeoAreaButton(isEditing: boolean) {
    this.clickOnEditGeoAreaButtonSubject.next(isEditing);
  }

  updatePolygonLayerStyle(map: google.maps.Map, styleObj: MapDataStyleItem, layerId: string | number) {
    if (map) {
      const feature = map.data.getFeatureById(layerId);
      if (feature) {
        map.data.setStyle(styleObj);
      }
    }
  }

  drawPolygonLayer(map: google.maps.Map, arrayofLatLng, styleObj: MapDataStyleItem, layerId: string | number) {
    map.data.add({ geometry: new google.maps.Data.Polygon([arrayofLatLng]), id: layerId });
    this.updatePolygonLayerStyle(map, styleObj, layerId);
  }

  clearPolygonLayer(map: google.maps.Map, layerId: string | number) {
    if (map) {
      const feature = map.data.getFeatureById(layerId);
      if (feature) {
        map.data.remove(feature);
      }
    }
  }

  updateMapToolbarSelectionItems(isSelected: boolean, item: MapToolbarSelectionItem): void {
    if (isSelected) {
      if (!this.mapToolbarSelectionItems.some((selection) => selection === item)) {
        this.mapToolbarSelectionItems.push(item);
      }
    } else if (!isSelected && this.mapToolbarSelectionItems.length > 0) {
      const index = this.mapToolbarSelectionItems.indexOf(item);
      if (index !== -1) {
        this.mapToolbarSelectionItems.splice(index, 1);
      }
    }
  }

  copyMatToolbarSelectionCache(): void {
    this.mapToolbarSelectionItemsCache = [...this.mapToolbarSelectionItems];
  }

  retrieveMatToolbarSelectionCache(): void {
    this.mapToolbarSelectionItems = [...this.mapToolbarSelectionItemsCache];
    this.mapToolbarSelectionItemsCache = [];
  }

  removeDrawSelection() {
    _remove(this.mapToolbarSelectionItems, (item: MapToolbarSelectionItem) => {
      return item === MapToolbarSelectionItem.POLYGON_DRAW || item === MapToolbarSelectionItem.GEO_FILTER_DRAW;
    });
  }
}
