import { Activity, DockStop, InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';

export const StateKey = 'dockRoutesState';

export interface DockRoutesActivityId {
  uniqueId: string;
  routeInstId: number;
  shipmentInstId: number;
  pickupInstId: number;
  proNbr: string;
  billCount: number;
  motorMovesNbr: number;
  weightLbs: number;
  customer: InterfaceAcct;
  specialServices: ShipmentSpecialServiceCd[];
  activities: Activity[];
}

export interface State {
  dockStops: DockStop[];
  incompleteDockStops: DockStop[];
  selectedDockActivities: DockRoutesActivityId[];
}

export const initialState: State = {
  dockStops: [],
  incompleteDockStops: [],
  selectedDockActivities: [],
};
