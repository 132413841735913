import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LicenseValidityCd } from '@xpo-ltl/sdk-common';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { AutoCompleteItem } from '../autocomplete/autocomplete.component';

@Component({
  selector: 'pnd-driver-editor',
  templateUrl: './driver-editor.component.html',
  styleUrls: ['./driver-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverEditorComponent implements ICellEditorAngularComp {
  params: ICellEditorParams;
  readonly driverFormControl = 'driverName';
  readonly LicenseValidityCd = LicenseValidityCd;

  focus$ = of(true);

  formGroup = new UntypedFormGroup({
    driverName: new UntypedFormControl(''),
  });

  driverNames: AutoCompleteItem[] = [];

  constructor() {}

  getValue() {
    return this.formGroup.get(this.driverFormControl).value;
  }

  isPopup?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    const drivers: AutoCompleteItem[] = _get(params, 'options');
    this.driverNames = drivers;
    this.formGroup.get(this.driverFormControl).setValue({
      id: params?.data?.dsrEmployeeId,
      value: params?.data?.tripDsrName,
    });
  }
}
