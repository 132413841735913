import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  GetUserPreferenceQuery,
  GetUserPreferenceResp,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile, switchMap } from 'rxjs/operators';
import {
  XpoLtlLayoutPreferencesStorage,
  XpoLtlLayoutPreferencesStorageData,
} from './layout-preferences-storage.interface';

export const LAYOUT_PREFERENCE_STORAGE_KEY = 'xpoLtlLayoutPreferences';

@Injectable({
  providedIn: 'root',
})
export class XpoLtlLayoutPreferencesStorageUserPreferencesService implements XpoLtlLayoutPreferencesStorage {
  constructor(private userPreferenceService: UserPreferenceApiService, private configManager: ConfigManagerService) {}

  /**
   * Get Layout data from user preferences.  Waits for the ConfigManager to be configured first
   */
  getData$(): Observable<XpoLtlLayoutPreferencesStorageData> {
    return this.configManager.configured$.pipe(
      skipWhile((value) => !value),
      switchMap(() => {
        const getPreferencesQuery = new GetUserPreferenceQuery();
        getPreferencesQuery.uiComponentName = LAYOUT_PREFERENCE_STORAGE_KEY;
        return this.userPreferenceService.getUserPreference(getPreferencesQuery, { loadingOverlayEnabled: false });
      }),
      map((result: GetUserPreferenceResp) => {
        const data = JSON.parse(result.preferences) as XpoLtlLayoutPreferencesStorageData;
        return data;
      }),
      catchError((error) => of(new XpoLtlLayoutPreferencesStorageData()))
    );
  }

  /**
   * Saves Layout data to user preferences.  Waits for the ConfigManager to be configured first
   */
  setData$(data: XpoLtlLayoutPreferencesStorageData): Observable<void> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = LAYOUT_PREFERENCE_STORAGE_KEY;
    upsertPreferencesRequest.preferences = JSON.stringify(data);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.configManager.configured$.pipe(
      skipWhile((value) => !value),
      switchMap(() => {
        return this.userPreferenceService.upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery);
      }),
      catchError((error) => of(undefined))
    );
  }
}
