import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmCancelAction } from './confirm-cancel-action.enum';
import { ConfirmCancelData } from './confirm-cancel-data';

@Component({
  selector: 'pnd-confirm-cancel',
  templateUrl: './confirm-cancel.component.html',
  styleUrls: ['./confirm-cancel.component.scss'],
  host: { class: 'pnd-ConfirmCancel' },
})
export class ConfirmCancelComponent {
  readonly ConfirmCancelAction = ConfirmCancelAction;

  title: string;
  content: SafeHtml;
  cancelButtonTitle: string;
  confirmButtonTitle: string;
  altActionButtonTitle: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmCancelData, private sanitizer: DomSanitizer) {
    this.title = data.heading;
    this.content = this.sanitizer.bypassSecurityTrustHtml(data.body);
    this.confirmButtonTitle = data.confirmButtonTitle;
    this.cancelButtonTitle = data.cancelButtonTitle;
    this.altActionButtonTitle = data.altActionButtonTitle;
  }
}
