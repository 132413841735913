import { Injectable } from '@angular/core';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { PastDueShipmentsComponentName } from 'app/inbound-planning/components/past-due-shipments/past-due-shipments-component-name';
import { SpotsAndDropsComponentName } from 'app/inbound-planning/components/spots-and-drops/models/spots-and-drops-component-name';
import { ModifyTripDetailsShipmentsComponentName } from 'app/inbound-planning/components/trips/modify-trip-details/modify-trip-details-shipments/modify-trip-details-shipments-component-name';
import { ModifyTripDetailsStopsComponentName } from 'app/inbound-planning/components/trips/modify-trip-details/modify-trip-details-stops/modify-trip-details-stops-component-name';
import { isEmpty as _isEmpty, isString as _isString } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMapTo, take } from 'rxjs/operators';

import { PndAppUtils } from '../../../../shared/app-utils';
import { LayoutPreferenceService } from '../../../../shared/layout-manager/services/layout-preference.service';
import { DispatcherTripsComponentName } from '../../components/dispatcher-trips/models/dispatcher-trips-component-name';
import { PlanningRouteShipmentsComponentName } from '../../components/planning-route-shipments/planning-route-shipments-component-name';
import { RoutePlanningComponentName } from '../../components/route-planning/route-planning-component-name';
import { RouteStopsComponentName } from '../../components/route-stops/route-stops-component-name';
import { TripPlanningComponentName } from '../../components/trip-planning/models/trip-planning-component-name';
import { ModifyTripDetailsComponentName } from '../../components/trips/modify-trip-details/modify-trip-details-component-name';
import { UnassignedDeliveriesComponentName } from '../../components/unassigned-deliveries/unassigned-deliveries-component-name';
import { UnassignedPickupsComponentName } from '../../components/unassigned-pickups/services/unassigned-pickups-component-name';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(
    private userPreferenceApiService: UserPreferenceApiService,
    private layoutPreferenceService: LayoutPreferenceService
  ) {}

  /**
   * Update the preferences for the named component
   */
  updatePreferencesFor<PREF_TYPE>(componentName: string, preferences: PREF_TYPE): Observable<PREF_TYPE> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = componentName;
    upsertPreferencesRequest.preferences = JSON.stringify(preferences);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceApiService
      .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery)
      .pipe(switchMapTo(of(preferences)));
  }

  /**
   * Get the user preferences stored for the named component
   * @param componentName name of the component to fetch stored preferences
   */
  getPreferencesFor<PREF_TYPE>(componentName: string): Observable<PREF_TYPE> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, { loadingOverlayEnabled: false }).pipe(
      PndAppUtils.retry(5, 200, 0),
      map((response) => {
        let rawPreferences;

        if (response?.preferences) {
          if (!_isString(response.preferences)) {
            rawPreferences = response.preferences;
          } else if (!_isEmpty(response.preferences)) {
            rawPreferences = response.preferences;
          }
        }

        try {
          const preference: PREF_TYPE = (_isString(rawPreferences)
            ? JSON.parse(rawPreferences)
            : rawPreferences) as PREF_TYPE;
          return preference;
        } catch (err) {
          return undefined;
        }
      }),
      catchError((error) => of(undefined))
    );
  }

  /**
   * Clear preferences for the named component by writing an empty object
   * @param componentName name of component to clear preferences for
   */
  clearPreferencesFor(componentName: string): Observable<void> {
    return this.updatePreferencesFor(componentName, []).pipe(switchMapTo(of(undefined)));
  }

  /**
   * Ask the user if they wish to delete all of there saved preferences, and
   * if so, deletes them and reloads the default layout
   */
  resetUserPreferences(): Observable<void> {
    return forkJoin([
      this.clearPreferencesFor(PlanningRouteShipmentsComponentName),
      this.clearPreferencesFor(PastDueShipmentsComponentName),
      this.clearPreferencesFor(RouteStopsComponentName),
      this.clearPreferencesFor(UnassignedDeliveriesComponentName),
      this.clearPreferencesFor(UnassignedPickupsComponentName),
      this.clearPreferencesFor(TripPlanningComponentName),
      this.clearPreferencesFor(RoutePlanningComponentName),
      this.clearPreferencesFor(ModifyTripDetailsStopsComponentName),
      this.clearPreferencesFor(ModifyTripDetailsShipmentsComponentName),
      this.clearPreferencesFor(ModifyTripDetailsComponentName),
      this.clearPreferencesFor(DispatcherTripsComponentName),
      this.clearPreferencesFor(SpotsAndDropsComponentName),
    ]).pipe(take(1), switchMapTo(this.layoutPreferenceService.deleteAllLayouts$()), take(1));
  }
}
