export enum PrintGridSources {
  TripPlanning = 'Trip Planning',
  PlanningRouteShipments = 'Planning Route Shipments',
  ModifyTripDetails = 'Modify Trip Details',
  UnassignedDeliveries = 'Unassigned Deliveries',
  RoutePlanning = 'Route Planning',
  DispatchTrip = 'Dispatch Trip',
  PastDueShipments = 'Past Due Shipments',
  UnassignedPickups = 'Unassigned Pickups',
  DockRoutes = 'Dock Routes',
}
