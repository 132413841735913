export enum UnassignedPickupsDetailGridFields {
  ROW_SELECTED = 'checkbox',
  EXTRA = 'extra',
  BOL = 'bolInstId',
  PRO_NBR = 'proNbr',
  ZIP_CODE = 'zip6',
  REMARKS = 'remarks',
  WEIGHT = 'weightLbs',
  PALLETS = 'palletsCount',
  PIECES = 'loosePiecesCount',
  CUBE = 'cubeNbr',
  MM = 'motorMovesNbr',
  DEST_SIC = 'destinationTerminalSicCd',
  SPECIAL_SERVICES = 'specialServiceSummary',
  PRO = 'proNbr',
  NOTES = 'notes',
  CALL_NBR = 'callNbr',
}
