import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { Unsubscriber, XpoLtlFeaturesService, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { interval, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../core/enums/config-manager-properties.enum';
import { UserRoleService } from '../core/services/user-role/user-role.service';
import { ComponentChangeUtils } from './inbound-planning/shared/classes/component-change-utils';
import { AutoRefreshBaseService } from './inbound-planning/shared/services/auto-refresh-base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'pnd-Root' },
})
export class AppComponent implements OnInit, OnDestroy {
  keyboardShortcuts: ShortcutInput[];

  private unsubscriber = new Unsubscriber();

  build: string;
  region: string = '';

  constructor(
    public loggedInUserService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private userRoleService: UserRoleService,
    private featureService: XpoLtlFeaturesService,
    private xpoAuthenticationService: XpoAuthenticationService,
    private xpoLtlAuthenticationService: XpoLtlAuthenticationService,
    private changeRef: ChangeDetectorRef
  ) {
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.region = `${_toLower(configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;

    this.xpoLtlAuthenticationService.initAuthSetup$(this.region).subscribe(() => {
      // Allow auto-refresh services to register after auth setup is complete
      AutoRefreshBaseService.beginAutoRefresh$.next();
      AutoRefreshBaseService.beginAutoRefresh$.complete();
      ComponentChangeUtils.detectChanges(this.changeRef);
    });

    // when user is logged in finish initialization
    this.loggedInUserFunc();
  }

  ngOnInit() {
    this.keyboardShortcuts = this.getApplicationKeyboardShortcuts().concat(
      this.getMapKeyboardShortcuts(),
      this.getTripNavigationKeyboardShortcuts()
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Process when the user is logged in
   */
  private loggedInUserFunc() {
    // NEED TO WAIT UNTIL AUTH SERVICE HAS COMPLETED SSO LOGIN BEFORE ANY ADDITIONAL CALLS ARE MADE
    this.xpoAuthenticationService
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        delay(100),
        switchMap((loggedIn: boolean) => {
          return !loggedIn
            ? of(undefined)
            : this.loggedInUserService
                .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
                .pipe(
                  catchError((error) => {
                    console.log('loggedInUserFunc ERROR:', error);
                    return of(undefined);
                  })
                );
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((user: User) => {
        this.userRoleService.setUser(user);

        this.setDynatraceUserIdentity(user);
        ComponentChangeUtils.detectChanges(this.changeRef);
      });
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user?.emailAddress) ? user?.emailAddress : !_isEmpty(user?.userId) ? user?.userId : 'PND_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  private getApplicationKeyboardShortcuts(): ShortcutInput[] {
    const label: string = 'Application';
    return [
      {
        key: ['alt + s'],
        description: 'Global Search',
        label,
        command: () => {}, //  bypass because global search handles shortcut listener
        preventDefault: true,
      },
      {
        key: ['esc'],
        description: 'Close Dialog',
        label,
        command: () => {}, //  bypass because global search handles shortcut listener
        preventDefault: false,
      },
    ];
  }

  private getTripNavigationKeyboardShortcuts(): ShortcutInput[] {
    const label: string = 'Trip Navigation';
    return [
      {
        key: ['ctrl + shift + j'],
        description: 'Previous Trip',
        label,
        command: () => {}, //  Set in trip-navigation.component
        preventDefault: true,
      },
      {
        key: ['ctrl + shift + k'],
        description: 'Next Trip',
        label,
        command: () => {}, //  Set in trip-navigation.component
        preventDefault: true,
      },
    ];
  }

  private getMapKeyboardShortcuts(): ShortcutInput[] {
    const label: string = 'Map';
    return [
      {
        key: ['D'],
        description: 'Activate the Draw tool',
        label,
        command: () => {},
        preventDefault: true,
      },
      {
        key: ['A'],
        description: 'Assign',
        label,
        command: () => {},
        preventDefault: true,
      },
      {
        key: ['C'],
        description: 'Create',
        label,
        command: () => {},
        preventDefault: true,
      },
    ];
  }
}
