import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PndStoreState } from '@pnd-store/.';
import { PndStore } from '@pnd-store/pnd-store';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { Unsubscriber, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import {
  DispatchGroup,
  ExistingRouteSummary,
  Note,
  Route,
  RouteDetail,
  SuggestedRouteName,
  Trailer,
  TrailerLoad,
  Trip,
  TripDriver,
  TripEquipment,
  TripNode,
} from '@xpo-ltl/sdk-cityoperations';
import { AvailableStatusCd, NoteTypeCd, RouteCategoryCd, TripNodeTypeCd, TripStatusCd } from '@xpo-ltl/sdk-common';
import { Equipment } from '@xpo-ltl/sdk-dockoperations';
import { dynamicValidator } from 'core/validators/dynamic-validator';
import { isEmpty as _isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AutoCompleteItem } from '../../shared/components/autocomplete/autocomplete.component';
import { TimeUtil } from '../../shared/services/time-format.util';
import { PndEquipmentTypeCd } from './create-trip/enums/equipment-type.enum';
import { FormModeEnum } from './create-trip/enums/form-mode.enum';
import { TripFormFields } from './create-trip/enums/trip-form-fields';
import { AutoCompleteEquipmentLists, CreateTripService, RouteNames } from './create-trip/services/create-trip.service';
import { ModifyTripFormErrors } from './modify-trip-details/enums/modify-trip-form-errors.enum';
import { TripManagerValidators } from './trip-manager-validators';

/**
 * Custom options for loading Equipment, Drivers, and Dispatch Areas
 */
export interface LoadEDDAOptions {
  emptyTrailersOnly?: boolean;
}

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class TripManagerBaseComponent implements OnInit {
  protected unsubscriber = new Unsubscriber();
  protected tripChangesUnsubscriber = new Unsubscriber();

  protected showSpinnerSubject = new BehaviorSubject<boolean>(false);
  readonly showSpinner$ = this.showSpinnerSubject.asObservable();

  readonly RUN_VALIDATOR_THRESHOLD = 50;

  @Input() shouldReload: boolean = true;

  formGroup: UntypedFormGroup;
  TripFormFields = TripFormFields;
  FormModeEnum = FormModeEnum;

  sics: AutoCompleteItem[] = [];
  driversNames: AutoCompleteItem[] = [];
  trailersNames: AutoCompleteItem[] = [];
  tractorsNames: AutoCompleteItem[] = [];
  dolliesNames: AutoCompleteItem[] = [];
  dispatchAreas: AutoCompleteItem[] = [];
  estimatedTimes: AutoCompleteItem[] = [];

  existingRouteNames: ExistingRouteSummary[];
  suggestedRouteNames: SuggestedRouteName[];
  canAddExistingTrip: boolean = true;
  routesAndEquipmentSectionTemplate: { [key: string]: any };

  lastTimeValidated: Date = new Date();
  timezone: string;

  protected constructor(
    protected pndStore$: PndStore<PndStoreState.State>,
    protected createTripService: CreateTripService,
    protected formBuilder: UntypedFormBuilder,
    protected validationService: FormatValidationService,
    protected timeService: XpoLtlTimeService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    this.formGroup = this.formBuilder.group(this.build());
    this.routesAndEquipmentSectionTemplate = this.buildRoutesAndEquipmentSection();
  }

  ngOnInit(): void {
    this.initWatchers();
  }

  initWatchers(): void {
    const routesAndEquipmentsFormArray = this.formGroup.get('routesAndEquipments') as UntypedFormArray;

    routesAndEquipmentsFormArray.controls.forEach((routesAndEquipmentsFormGroup: UntypedFormGroup) => {
      const suffixCtrl = routesAndEquipmentsFormGroup.get(TripFormFields.SUFFIX) as UntypedFormControl;

      suffixCtrl.valueChanges.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
        routesAndEquipmentsFormArray.controls.forEach((equipmentFormGroup: UntypedFormGroup) => {
          if (equipmentFormGroup !== routesAndEquipmentsFormGroup) {
            const suffixControlToUpdate: AbstractControl = equipmentFormGroup.get(TripFormFields.SUFFIX);
            suffixControlToUpdate.updateValueAndValidity({ emitEvent: false });
          }
        });
      });
    });
  }

  protected buildTripDateAndTimesSection(): { [key: string]: any } {
    const isAfter = (starts: string, ends: string) => {
      return Number(starts.replace(':', '')) > Number(ends.replace(':', ''));
    };

    const validateTimes = (firstControlName, secondControlName) => {
      let errors = null;
      if (this.formGroup) {
        const firstTimeControl = this.formGroup.get(firstControlName);
        const secondTimeControl = this.formGroup.get(secondControlName);
        const firstTimeValue = (<AutoCompleteItem>firstTimeControl?.value)?.value;
        const secondTimeValue = (<AutoCompleteItem>secondTimeControl?.value)?.value;

        if (firstTimeValue && secondTimeValue && isAfter(firstTimeValue, secondTimeValue)) {
          errors = { invalidValue: true };
        }
      }

      return errors;
    };

    return {
      [TripFormFields.TRIP_DATE]: [null],
      [TripFormFields.TRIP_STATUS]: [null],
      [TripFormFields.SIC]: [''],
      [TripFormFields.EST_START]: [
        undefined,
        [
          dynamicValidator(
            () => true,
            (control) => TimeUtil.timeFormatValidation(control, true)
          ),
          dynamicValidator(
            () => true,
            (control) => {
              if (this.formGroup) {
                const estClearTimeControl = this.formGroup.get(this.TripFormFields.EST_CLEAR);
                estClearTimeControl.updateValueAndValidity();
              }

              return null;
            }
          ),
          dynamicValidator(
            () => true,
            (control) => {
              const isToday = (d) => moment(d).isSame(moment(), 'day');

              let errors = null;

              if (this.formGroup) {
                const dateValue = this.formGroup.get(TripFormFields.TRIP_DATE)?.value;
                const estStartTimeValue: string = (<AutoCompleteItem>control?.value)?.value;

                const minHour = moment()
                  .seconds(0)
                  .milliseconds(0)
                  .tz(this.timezone)
                  .format('HH:mm');

                const minTime = isToday(dateValue) ? `${minHour.toString().padStart(2, '0')}` : '00:00';

                if (estStartTimeValue && isAfter(minTime, estStartTimeValue)) {
                  errors = { invalidValue: true };
                }
              }

              return errors;
            }
          ),
        ],
      ],
      [TripFormFields.EST_CLEAR]: [
        undefined,
        [
          dynamicValidator(
            () => true,
            (control) => TimeUtil.timeFormatValidation(control, true)
          ),
          dynamicValidator(
            () => true,
            (control) => {
              let errors = null;

              if (this.formGroup) {
                const estCompleteTimeControl = this.formGroup.get(this.TripFormFields.EST_COMPLETE);
                const tripStatus: TripStatusCd = this.formGroup.get(this.TripFormFields.TRIP_STATUS)
                  .value as TripStatusCd;

                if (tripStatus === TripStatusCd.DISPATCHED) {
                  errors = validateTimes(this.TripFormFields.ACTUAL_START, this.TripFormFields.EST_CLEAR);
                } else {
                  errors = validateTimes(this.TripFormFields.EST_START, this.TripFormFields.EST_CLEAR);
                }

                estCompleteTimeControl.updateValueAndValidity();
              }

              return errors;
            }
          ),
        ],
      ],
      [TripFormFields.EST_COMPLETE]: [
        undefined,
        [
          dynamicValidator(
            () => true,
            (control) => TimeUtil.timeFormatValidation(control, true)
          ),
          dynamicValidator(
            () => true,
            (control) => {
              let errors = null;

              if (this.formGroup) {
                errors = validateTimes(this.TripFormFields.EST_CLEAR, this.TripFormFields.EST_COMPLETE);

                if (!errors) {
                  errors = validateTimes(this.TripFormFields.EST_START, this.TripFormFields.EST_COMPLETE);
                }
              }

              return errors;
            }
          ),
        ],
      ],
      [TripFormFields.DISPATCH_AREA]: [undefined],
      [TripFormFields.ACTUAL_START]: [undefined],
      [TripFormFields.ACTUAL_COMPLETE]: [undefined],
    };
  }

  protected buildDriverAndTractorSection(): { [key: string]: any } {
    return {
      [TripFormFields.AUTO_DISPATCH]: [true],
      [TripFormFields.DRIVER]: [
        '',
        [
          dynamicValidator(
            () => true,
            (control) => this.validateDriver(control)
          ),
        ],
      ],
      [TripFormFields.TRACTOR]: [''],
      [TripFormFields.IS_STRAIGHT_TRUCK]: [false],
      [TripFormFields.REMARKS]: ['', [Validators.maxLength(30)]],
    };
  }

  buildRoutesAndEquipmentSection(
    isNewRoute: boolean = true,
    isInEditMode: boolean = false,
    routeName: AutoCompleteItem = { id: '', value: '' },
    prefix: AutoCompleteItem = { id: '', value: '' },
    suffix: string = '',
    trailer: AutoCompleteItem = { id: '', value: '' },
    dolly: AutoCompleteItem = { id: '', value: '' },
    loadDoor: string = '',
    unloadDoor: string = '',
    currentStatus: string = ''
  ): { [key: string]: any } {
    return {
      [TripFormFields.IS_NEW_ROUTE]: [isNewRoute],
      [TripFormFields.IS_IN_EDIT_MODE]: [isInEditMode],
      [TripFormFields.ROUTE_NAME]: [routeName],
      [TripFormFields.PREFIX]: [prefix],
      [TripFormFields.SUFFIX]: [suffix, this.validateSuffixUnique()],
      [TripFormFields.TRAILER]: [trailer],
      [TripFormFields.DOLLY]: [dolly],
      [TripFormFields.LOAD_DOOR]: [loadDoor],
      [TripFormFields.UNLOAD_DOOR]: [unloadDoor],
      [TripFormFields.CURRENT_STATUS]: [currentStatus],
    };
  }

  /**
   * Build the FormGroup containing the controls for defining a Trip
   */
  build(): { [key: string]: any } {
    return {
      ...this.buildTripDateAndTimesSection(),
      ...this.buildDriverAndTractorSection(),
      routesAndEquipments: new UntypedFormArray(
        [
          this.formBuilder.group(this.buildRoutesAndEquipmentSection()),
          this.formBuilder.group(this.buildRoutesAndEquipmentSection()),
          this.formBuilder.group(this.buildRoutesAndEquipmentSection()),
        ],
        dynamicValidator(
          () => true,
          () => this.validateRoutes()
        )
      ),
      [TripFormFields.TRIP_INST_ID]: [0, []],
      [TripFormFields.CARRIER_ID]: [undefined, []],
    };
  }

  protected loadSuggestedRouteNamesAndEstimatedTimeValues(tripDate: Date, sic: string): Observable<void> {
    return combineLatest([
      this.createTripService
        .listRouteNames$(sic, tripDate)
        .pipe(catchError(() => of(<RouteNames>{ suggestedRouteNames: [], existingRouteNames: [] }))),
      this.loadEstimatedTimeValues(tripDate, sic),
    ]).pipe(
      take(1),
      map(([routeNames, _]) => {
        this.suggestedRouteNames = routeNames.suggestedRouteNames;
        this.existingRouteNames = routeNames.existingRouteNames;
      })
    );
  }

  protected loadEquipmentDriversAndDispatchAreas(sic: string, options?: LoadEDDAOptions): Observable<void> {
    return combineLatest([
      this.createTripService.listEquipmentsBySic$(sic, this.shouldReload).pipe(
        catchError(() => of([{ trailers: [], tractors: [], dollies: [] }])),
        map((results: AutoCompleteEquipmentLists) => {
          // modify with additional options
          if (options?.emptyTrailersOnly) {
            return {
              trailers: results.trailers.filter((trailer) => trailer?.data?.isEmpty),
              tractors: results.tractors,
              dollies: results.dollies,
            };
          } else {
            return results;
          }
        })
      ),
      this.createTripService.listDrivers$(sic, this.shouldReload).pipe(
        catchError(() => of([])),
        map((drivers: AutoCompleteItem[]) => {
          drivers.sort((driverA: AutoCompleteItem, driverB: AutoCompleteItem) => {
            if (this.checkDriverStatus(driverA, driverB)) {
              if (driverA?.data?.employeeStatus === driverB?.data?.employeeStatus) {
                return driverA?.value.localeCompare(driverB?.value);
              } else {
                return driverA?.data?.employeeStatus.localeCompare(driverB?.data?.employeeStatus);
              }
            }
            if (driverA?.data?.employeeStatus === driverB?.data?.employeeStatus) {
              return driverA?.value.localeCompare(driverB?.value);
            } else {
              return this.compareDrivers(driverA, driverB);
            }
          });

          return drivers;
        })
      ),
      this.createTripService.listDispatchAreas$(sic, this.shouldReload).pipe(catchError(() => of([]))),
    ]).pipe(
      take(1),
      map(
        ([equipments, driversNames, dispatchAreas]: [
          { trailers: AutoCompleteItem[]; tractors: AutoCompleteItem[]; dollies: AutoCompleteItem[] },
          AutoCompleteItem[],
          AutoCompleteItem[]
        ]) => {
          this.trailersNames = equipments.trailers;
          this.tractorsNames = equipments.tractors;
          this.dolliesNames = equipments.dollies;
          this.driversNames = driversNames;
          this.dispatchAreas = dispatchAreas;

          timer(250)
            .pipe(take(1))
            .subscribe(() => {
              this.changeDetectorRef.detectChanges();
            });
        }
      )
    );
  }

  private checkDriverStatus(driverA: AutoCompleteItem, driverB: AutoCompleteItem): boolean {
    return (
      driverA?.data?.employeeStatus !== TripStatusCd.RETURNING &&
      driverA?.data?.employeeStatus !== AvailableStatusCd.AVAILABLE &&
      driverA?.data?.employeeStatus !== TripStatusCd.DISPATCHED &&
      driverA?.data?.employeeStatus !== AvailableStatusCd.UNAVAILABLE &&
      driverB?.data?.employeeStatus !== TripStatusCd.RETURNING &&
      driverB?.data?.employeeStatus !== AvailableStatusCd.AVAILABLE &&
      driverB?.data?.employeeStatus !== TripStatusCd.DISPATCHED &&
      driverB?.data?.employeeStatus !== AvailableStatusCd.UNAVAILABLE
    );
  }

  private compareDrivers(driverA: AutoCompleteItem, driverB: AutoCompleteItem): number {
    return this.getSortOrder(driverA) < this.getSortOrder(driverB) ? -1 : 1;
  }

  private getSortOrder(driver: AutoCompleteItem): number {
    if (driver?.data?.employeeStatus === TripStatusCd.RETURNING) {
      return 0;
    } else if (driver?.data?.employeeStatus === AvailableStatusCd.AVAILABLE) {
      return 1;
    } else if (driver?.data?.employeeStatus === TripStatusCd.DISPATCHED) {
      return 2;
    } else if (driver?.data?.employeeStatus === AvailableStatusCd.UNAVAILABLE) {
      return 3;
    } else {
      return 4;
    }
  }

  private loadEstimatedTimeValues(tripDate: Date, sic: string): Observable<string> {
    return this.timeService.timezoneForSicCd$(sic).pipe(
      take(1),
      tap((timezone) => {
        this.timezone = timezone;

        const nowAsString = moment()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .tz(timezone)
          .format('YYYY-MM-DD');

        const tripDateAsString = moment(tripDate)
          .hours(12)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .tz(timezone)
          .format('YYYY-MM-DD');

        const isInTheFuture: boolean = moment(nowAsString).isBefore(tripDateAsString);

        const currentHour = moment()
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .tz(timezone)
          .format('HH');

        const startHour: string = isInTheFuture || currentHour === '23' ? '00' : currentHour;

        const values: AutoCompleteItem[] = [];

        for (let i = Number(startHour) + 1; i < 24; i++) {
          const time = `${i.toString().padStart(2, '0')}:00`;
          values.push({
            id: time,
            value: time,
          });
        }

        this.estimatedTimes = values;
      })
    );
  }

  protected subscribeToValueChanges(): void {
    this.formGroup
      .get(TripFormFields.SIC)
      .valueChanges.pipe(
        skip(1),
        takeUntil(this.tripChangesUnsubscriber.done$),
        filter((sic: AutoCompleteItem) => !!sic && sic.hasOwnProperty('id')),
        distinctUntilChanged(),
        switchMap((sic: AutoCompleteItem) => {
          return this.loadEquipmentDriversAndDispatchAreas(sic.value);
        })
      )
      .subscribe((_) => {
        this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT).updateValueAndValidity();
      });

    combineLatest([
      this.formGroup.get(TripFormFields.TRIP_DATE).valueChanges,
      this.formGroup.get(TripFormFields.SIC).valueChanges,
    ])
      .pipe(
        skip(1),
        takeUntil(this.tripChangesUnsubscriber.done$),
        filter(([tripDate, sic]: [Date, AutoCompleteItem]) => !!tripDate && !!sic && sic.hasOwnProperty('id')),
        distinctUntilChanged(),
        switchMap(([tripDate, sic]: [Date, AutoCompleteItem]) => {
          return this.loadSuggestedRouteNamesAndEstimatedTimeValues(tripDate, sic.value);
        })
      )
      .subscribe((_) => {
        this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT).updateValueAndValidity();
      });
  }

  protected getTripData(timezone: string): Trip {
    const trip = new Trip();

    // TODO: Use LTL time service instead of moment.tz
    trip.tripInstId = Number(this.formGroup.get(TripFormFields.TRIP_INST_ID).value);

    const tripDateObj: Date = new Date(this.formGroup.get(TripFormFields.TRIP_DATE).value);
    tripDateObj?.setHours(12);
    trip.tripDate = moment.tz(tripDateObj, timezone).format('YYYY-MM-DD');
    trip.doNotAutoDisplayFlag = Number(!this.formGroup.get(TripFormFields.AUTO_DISPATCH).value);
    trip.dispatchGroupId = this.getDispatchAreaData()?.groupId;
    trip.terminalSicCd = (this.formGroup.get(TripFormFields.SIC).value as AutoCompleteItem)?.id;
    trip.estimatedDepartDateTime = this.formatDateTime(trip.tripDate, timezone, TripFormFields.EST_START);
    trip.estimatedEmptyDateTime = this.formatDateTime(trip.tripDate, timezone, TripFormFields.EST_CLEAR);
    trip.estimatedReturnDateTime = this.formatDateTime(trip.tripDate, timezone, TripFormFields.EST_COMPLETE);
    trip.cmsCarrierId = this.formGroup.get(TripFormFields.CARRIER_ID).value;

    return trip;
  }

  protected aggregateTripNodes(tripDate: string, timezone: string, nodes: TripNode[] = []): void {
    const originNode: TripNode = nodes.find((node) => node.tripNodeTypeCd === TripNodeTypeCd.ORIGIN);
    originNode.estimatedDepartDateTime = this.formatDateTime(tripDate, timezone, TripFormFields.EST_START);

    const destinationNode: TripNode = nodes.find((node) => node.tripNodeTypeCd === TripNodeTypeCd.DESTINATION);
    destinationNode.estimatedArriveDateTime = this.formatDateTime(tripDate, timezone, TripFormFields.EST_COMPLETE);
  }

  protected formatDateTime(tripDate: string, timezone: string, formField: TripFormFields): Date {
    const tripDateISO = moment
      .tz(tripDate, timezone)
      .toISOString()
      .substring(0, 10);

    const formValue: string | AutoCompleteItem = this.formGroup.get(formField).value;

    const value = formValue && formValue.hasOwnProperty('id') ? (<AutoCompleteItem>formValue).value : <string>formValue;

    return !_isEmpty(value) ? moment.tz(`${tripDateISO} ${value}`, 'YYYY-MM-DD HH:mm', timezone).toDate() : undefined;
  }

  protected getDispatchAreaData(): DispatchGroup {
    return this.createTripService.findDispatchAreaById(this.formGroup.get(TripFormFields.DISPATCH_AREA).value);
  }

  protected getDriverData(): TripDriver {
    return this.createTripService.findDriverById(this.formGroup.get(TripFormFields.DRIVER).value);
  }

  protected isStraightTruck(): boolean {
    const tractor = this.getTractorData();
    return tractor?.equipmentTypeCd === PndEquipmentTypeCd.STRAIGHT_TRUCK;
  }

  protected getTractorData(): Equipment {
    const tractorData: Equipment = this.createTripService.findTractorById(
      this.formGroup.get(TripFormFields.TRACTOR).value
    );

    if (tractorData?.trailerLoad) {
      tractorData.trailerLoad.liftgateInd = undefined;
    }

    return tractorData;
  }

  protected getNoteData(): Note {
    const noteText = this.formGroup.get(TripFormFields.REMARKS).value;
    if (_isEmpty(noteText)) {
      return undefined;
    }

    const note: Note = new Note();
    note.note = this.formGroup.get(TripFormFields.REMARKS).value;
    note.typeCd = NoteTypeCd.TRIP;

    return note;
  }

  protected getRouteDetailForStraightTruck(): RouteDetail[] {
    const routeDetails: RouteDetail[] = [];

    const routesAndEquipments = this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT) as UntypedFormArray;
    const group = routesAndEquipments.at(0);

    if (!group) {
      return [];
    }

    const route: Route = new Route();

    const isNewRoute: boolean = group.get(TripFormFields.IS_NEW_ROUTE).value;
    const isInEditMode: boolean = group.get(TripFormFields.IS_IN_EDIT_MODE).value;

    const routeName: AutoCompleteItem = group.get(this.TripFormFields.ROUTE_NAME).value;
    const routePrefix: string = (<AutoCompleteItem>group.get(this.TripFormFields.PREFIX).value)?.value;
    const routeSuffix: string = group.get(TripFormFields.SUFFIX).value;

    if (isNewRoute || isInEditMode) {
      route.routePrefix = routePrefix;
      route.routeSuffix = routeSuffix;

      if (isInEditMode) {
        route.routeInstId = Number(routeName.id);
      }
    } else {
      if (routeName && routeName.id && routeName.value) {
        const routeNameSplitted: string[] = routeName.value.split('-');

        route.routeInstId = Number(routeName.id);
        route.routePrefix = routeNameSplitted[0];
        route.routeSuffix = routeNameSplitted[1];
      }
    }

    route.routeInstId = Number((group.get(this.TripFormFields.ROUTE_NAME).value as AutoCompleteItem).id);
    route.categoryCd = RouteCategoryCd.DELIVERY;
    route.terminalSicCd = (this.formGroup.get(TripFormFields.SIC).value as AutoCompleteItem).value;
    route.routeDate = moment(this.formGroup.get(TripFormFields.TRIP_DATE).value).format('YYYY-MM-DD');

    const straightTruck = this.createTripService.findTractorById(
      this.formGroup.get(TripFormFields.TRACTOR).value as AutoCompleteItem
    );

    const trailer = new Trailer();
    if (straightTruck) {
      trailer.tripEquipment = {
        ...new TripEquipment(),
        equipmentInstId: straightTruck.equipmentId,
        equipmentIdPrefix: straightTruck.equipmentIdPrefix,
        equipmentIdSuffixNbr: straightTruck.equipmentIdSuffixNbr,
        tripEquipmentSequenceNbr: 0,
        equipmentTypeCd: 'Z',
      };
    }

    const modifyInd: boolean = !routesAndEquipments.pristine;

    if (route.routePrefix && route.routeSuffix) {
      routeDetails.push({
        ...new RouteDetail(),
        route,
        trailer,
        modifyInd,
      });
    }

    return routeDetails;
  }

  protected getRouteDetails(): RouteDetail[] {
    const routeDetails: RouteDetail[] = [];
    const routesAndEquipments = this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT) as UntypedFormArray;

    let tripEquipmentSequenceNbr: number = 0;
    routesAndEquipments.controls.forEach((group: UntypedFormGroup) => {
      const routeDetail: RouteDetail = new RouteDetail();
      const route: Route = new Route();

      const isNewRoute: boolean = group.get(TripFormFields.IS_NEW_ROUTE).value;
      const isInEditMode: boolean = group.get(TripFormFields.IS_IN_EDIT_MODE).value;

      const routeName: AutoCompleteItem = group.get(this.TripFormFields.ROUTE_NAME).value;
      const routePrefix: string = (<AutoCompleteItem>group.get(this.TripFormFields.PREFIX).value)?.value;
      const routeSuffix: string = group.get(TripFormFields.SUFFIX).value;

      routeDetail.modifyInd = !group.pristine;

      if (isNewRoute || isInEditMode) {
        route.routePrefix = routePrefix;
        route.routeSuffix = routeSuffix;

        if (isInEditMode) {
          route.routeInstId = Number(routeName.id);
        }
      } else {
        if (routeName && routeName.id && routeName.value) {
          const routeNameSplitted: string[] = routeName.value.split('-');

          route.routeInstId = Number(routeName.id);
          route.routePrefix = routeNameSplitted[0];
          route.routeSuffix = routeNameSplitted[1];
        }
      }

      route.categoryCd = RouteCategoryCd.DELIVERY;
      route.terminalSicCd = (this.formGroup.get(TripFormFields.SIC).value as AutoCompleteItem).value;
      route.routeDate = moment(this.formGroup.get(TripFormFields.TRIP_DATE).value).format('YYYY-MM-DD');
      route.plannedDoor = group.get(TripFormFields.LOAD_DOOR).value;
      const trailerEquipment: Equipment = this.createTripService.findTrailerById(
        group.get(this.TripFormFields.TRAILER)?.value
      );

      const trailer: Trailer = new Trailer();
      if (trailerEquipment) {
        trailer.tripEquipment = {
          ...new TripEquipment(),
          equipmentInstId: trailerEquipment.equipmentId,
          equipmentIdPrefix: trailerEquipment.equipmentIdPrefix,
          equipmentIdSuffixNbr: trailerEquipment.equipmentIdSuffixNbr,
          tripEquipmentSequenceNbr,
          equipmentTypeCd: 'T',
        };
      }

      trailer.trailerLoad = {
        ...new TrailerLoad(),
        evntDoor: group.get(TripFormFields.UNLOAD_DOOR).value,
        currentStatus: group.get(TripFormFields.CURRENT_STATUS).value,
      };

      if (trailer.trailerLoad.currentStatus === '') {
        trailer.trailerLoad.currentStatus = group.get(TripFormFields.TRAILER)?.value?.data?.statusCd;
      }

      if (route.routePrefix && route.routeSuffix) {
        routeDetail.route = route;
      }

      if (trailer.tripEquipment) {
        routeDetail.trailer = trailer;
      }

      if (routeDetail.route || routeDetail.trailer) {
        routeDetails.push(routeDetail);
      }

      tripEquipmentSequenceNbr += 1;
    });
    return routeDetails;
  }

  protected getDolliesData(): Equipment[] {
    const dollies: Equipment[] = [];
    const routesAndEquipments = this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT) as UntypedFormArray;

    routesAndEquipments.controls.forEach((group: UntypedFormGroup) => {
      const dolly = this.createTripService.findDollyById(group.get(this.TripFormFields.DOLLY).value);

      if (dolly) {
        dollies.push(dolly);
      }
    });

    return dollies;
  }

  /**
   * Returns error if any other route in form exists with same prefix and suffix
   */
  validateSuffixUnique(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.formGroup) {
        return null;
      }

      const routePrefix: AutoCompleteItem = control.parent?.get(TripFormFields.PREFIX)?.value;
      const routeSuffix: string = control.value;

      let result: ValidationErrors = null;

      if (!_isEmpty(routeSuffix)) {
        // only test for duplicates if user had entered a suffix
        const routesAndEquipments = this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT) as UntypedFormArray;
        for (const group of routesAndEquipments?.controls ?? []) {
          if (group !== control.parent) {
            const prefix: AutoCompleteItem = group.get(TripFormFields.PREFIX)?.value;
            if (prefix.id === routePrefix?.id) {
              const suffix: AbstractControl = group.get(TripFormFields.SUFFIX);
              if (suffix?.value === routeSuffix) {
                // duplicate route, so this is an error
                result = { [ModifyTripFormErrors.DUPLICATE_ROUTE]: { value: control.value } };
              }
            }
          }
        }
      }

      if (result !== null) {
        // flag all suffixes to revalidate
      }

      return result;
    };
  }

  validateRoutes(): ValidationErrors | null {
    if (!this.formGroup) {
      return null;
    }

    const removeRequiredError = (control: AbstractControl) => {
      let previousErrors: ValidationErrors = control.errors;

      if (previousErrors && previousErrors[Validators.required.name]) {
        delete previousErrors[Validators.required.name];

        if (_isEmpty(previousErrors)) {
          previousErrors = null;
        }

        control.setErrors(previousErrors);
      }
    };

    const setRequiredError = (control: AbstractControl) => {
      const previousErrors: ValidationErrors = control.errors || {};

      previousErrors[Validators.required.name] = true;

      control.setErrors(previousErrors);
    };

    const routesAndEquipments = <UntypedFormArray>this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT);

    if (routesAndEquipments.touched) {
      routesAndEquipments.controls.forEach((group) => {
        const prefixControl = group.get(TripFormFields.PREFIX);
        const suffixControl = group.get(TripFormFields.SUFFIX);

        // Xor: if both have values or both are empty, then both fields are not required
        const notRequired =
          TripManagerValidators.hasValueControl(prefixControl) === TripManagerValidators.hasValueControl(suffixControl);

        if (notRequired) {
          // Remove 'required' error
          removeRequiredError(prefixControl);
          removeRequiredError(suffixControl);
        } else {
          // Set 'required' error
          if (!TripManagerValidators.hasValueControl(prefixControl)) {
            setRequiredError(prefixControl);
          } else if (prefixControl.valid) {
            setRequiredError(suffixControl);
          }
        }
      });

      // this check prevents maximum stack size error (infinite check between driver and routes status)
      if (this.shouldRunValidations()) {
        this.lastTimeValidated = new Date();
        routesAndEquipments.markAllAsTouched();

        // driver control status needs to be updated
        const driverControl = this.formGroup.get(TripFormFields.DRIVER);
        driverControl.updateValueAndValidity();
      }
    }

    return null;
  }

  validateDriver(control): ValidationErrors | null {
    if (!this.formGroup) {
      return null;
    }

    const routesAndEquipments = <UntypedFormArray>this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT);
    const isDriverValid = TripManagerValidators.hasValueControl(control);

    if (isDriverValid) {
      // this check prevents maximum stack size error (infinite check between driver and routes status)
      if (this.shouldRunValidations()) {
        this.lastTimeValidated = new Date();
        routesAndEquipments.updateValueAndValidity();
      }

      return null;
    } else {
      // if driver is empty required status is depending on routes status
      let validRoutesCounter = 0;
      routesAndEquipments.controls.forEach((group) => {
        const prefixControl = group.get(TripFormFields.PREFIX);
        const suffixControl = group.get(TripFormFields.SUFFIX);
        const routeNameControl = group.get(TripFormFields.ROUTE_NAME);
        const isNewRouteControl = group.get(TripFormFields.IS_NEW_ROUTE);

        let validRoute: boolean;

        if (!isNewRouteControl?.value) {
          validRoute =
            routeNameControl?.value?.hasOwnProperty('id') && TripManagerValidators.hasValueControl(routeNameControl);
        } else {
          validRoute =
            prefixControl?.value?.hasOwnProperty('id') &&
            TripManagerValidators.hasValueControl(prefixControl) &&
            TripManagerValidators.hasValueControl(suffixControl);
        }

        if (validRoute) {
          validRoutesCounter += 1;
        }
      });

      return validRoutesCounter > 0 ? null : { required: true };
    }
  }

  /**
   * This function is created to break the infinite loop between validations.
   * ie: When the route is selected then the driver validation runs,
   * and driver validation asks per routes validations again
   */
  private shouldRunValidations(): boolean {
    const currentTime = new Date();

    return currentTime.getTime() - this.lastTimeValidated.getTime() > this.RUN_VALIDATOR_THRESHOLD;
  }
}
