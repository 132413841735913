<ng-container *ngIf="!isInvalid; else errorTemplate">
  <div class="xpo-GridCell-base">
    <span class="xpo-GridCell-base-text">{{ value }}</span>
    <mat-select xpoSelect *ngIf="showSelectIcon"></mat-select>
  </div>
</ng-container>
<ng-template #errorTemplate>
  <div [matTooltip]="error" class="xpo-GridCell-base xpo-GridCell-base--invalid">
    <mat-icon class="xpo-GridCell-base--invalid-icon">report_problem</mat-icon>
    <div class="xpo-GridCell-base--invalid-label">{{ value }}</div>
  </div>
</ng-template>
