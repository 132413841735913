import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store/index';
import { PndStore } from '../../../../store/pnd-store';
import { XpoColors } from './../../services/xpo-colors';

export interface OptimizerUpdateShipmentAlertCellRendererParams extends ICellRendererParams {
  showAlert: (params: OptimizerUpdateShipmentAlertCellRendererParams) => boolean;
}

@Component({
  selector: 'pnd-optimizer-update-shipment-alert-cell-render',
  templateUrl: './optimizer-update-shipment-alert-cell-render.component.html',
  styleUrls: ['./optimizer-update-shipment-alert-cell-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-OptimizerUpdateShipmentAlertCellRender' },
})
export class OptimizerUpdateShipmentAlertCellRenderComponent implements ICellRendererAngularComp {
  readonly XpoColors = XpoColors;
  currentSicTimeZone$: Observable<string>;
  params: OptimizerUpdateShipmentAlertCellRendererParams;
  data: any;

  showAlert: boolean;

  get address(): string {
    return `${this.data?.address}, ${this.data?.cityName}, ${this.data?.stateCd}, ${this.data?.zip6Cd}`;
  }

  constructor(private pndStore$: PndStore<PndStoreState.State>, private timeService: XpoLtlTimeService) {}

  agInit(params: OptimizerUpdateShipmentAlertCellRendererParams): void {
    this.params = params;
    this.data = params.data;
    this.setShowAlert(params);

    const globalFilter = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.selectGlobalFilterState);
    this.currentSicTimeZone$ = this.timeService.timezoneForSicCd$(globalFilter.sic).pipe(
      take(1),
      map((result) => {
        const date = moment()
          .tz(result)
          .format('DD MMMM YYYY, hh:mm a');
        return date;
      })
    );
  }

  private setShowAlert(params: OptimizerUpdateShipmentAlertCellRendererParams) {
    const originIncludeInd: boolean = params?.node?.['originIncludeInd'] ?? true;
    const includeInd: boolean = params?.node?.data?.includeInd ?? true;

    this.showAlert = !params.node.group && (!originIncludeInd || !includeInd);
  }

  refresh(params: OptimizerUpdateShipmentAlertCellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
