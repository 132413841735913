import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'pnd-time-selector-cell-renderer',
  templateUrl: './time-selector-cell-renderer.component.html',
  styleUrls: ['./time-selector-cell-renderer.component.scss'],
})
export class TimeSelectorCellRendererComponent implements ICellRendererAngularComp {
  cellValue: string;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params?.value;
  }
}
