import { isNil as _isNil } from 'lodash';

export class TimeInputModel {
  previousValue: string;
  nextValue: string;

  constructor(props?: any) {
    if (props) {
      this.previousValue = !_isNil(props.previousValue) ? props.previousValue : undefined;
      this.nextValue = !_isNil(props.nextValue) ? props.nextValue : undefined;
    }
  }
}
