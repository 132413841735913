import { Pipe, PipeTransform } from '@angular/core';
import { CarrierTenderGroupStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'carrierTenderGroupStatusCd' })
export class CarrierTenderGroupStatusCdPipe implements PipeTransform {
  private statusMap: { [key: string]: string } = {
    [CarrierTenderGroupStatusCd.ACCEPTED]: 'Accepted',
    [CarrierTenderGroupStatusCd.ACCEPTED_WITH_EXCEPTIONS]: 'Accepted With Exceptions',
    [CarrierTenderGroupStatusCd.PARTIALLY_ACCEPTED]: 'Partially Accepted',
    [CarrierTenderGroupStatusCd.PARTIALLY_ACCEPTED_WITH_EXCEPTIONS]: 'Partially Accepted With Exceptions',
    [CarrierTenderGroupStatusCd.PARTIALLY_ROUTED]: 'Partially Routed',
    [CarrierTenderGroupStatusCd.PARTIALLY_ROUTED_WITH_EXCEPTIONS]: 'Partially Routed With Exceptions',
    [CarrierTenderGroupStatusCd.ROUTED]: 'Routed',
    [CarrierTenderGroupStatusCd.ROUTED_WITH_EXCEPTIONS]: 'Routed With Exceptions',
    [CarrierTenderGroupStatusCd.TENDERED]: 'Tendered',
    [CarrierTenderGroupStatusCd.TENDERED_WITH_EXCEPTIONS]: 'Tendered With Exceptions',
    [CarrierTenderGroupStatusCd.WITHDRAWN]: 'Withdrawn',
    ['NotTendered']: 'Not Tendered',
  };

  transform(value: CarrierTenderGroupStatusCd | string): string {
    return this.statusMap[value] || value;
  }
}
