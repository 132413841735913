import { Injectable } from '@angular/core';
import { PnDDispatchGroupRegion } from '@xpo-ltl/sdk-cityoperations';
import { NotificationMessageStatus } from 'core';
import { NotificationMessageService } from 'core/services/notification-message.service';
import { forEach as _forEach } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapUtils } from '../../classes/map-utils';
import { DispatchAreaService } from './dispatch-area.service';

export type DispatchAreaPolygon = google.maps.Polygon & { pndDispatchGroupRegion: PnDDispatchGroupRegion };

@Injectable({
  providedIn: 'root',
})
export class DispatchAreaRenderingService {
  static COLOR_STROKE_DEFAULT: string = 'rgba(0,0,0,1)';
  static COLOR_FILL_DEFAULT: string = 'rgba(0,0,0,0.6)';
  static COLOR_STROKE_EDIT: string = 'rgba(69,145,219,1)';
  static COLOR_FILL_EDIT: string = 'rgba(69,145,219,0.6)';
  static STROKE_WEIGHT_DEFAULT = 1;
  static STROKE_WEIGHT_BOLD = 4;

  static DEFAULT_OPTIONS = {
    draggable: false,
    editable: false,
    strokeColor: DispatchAreaRenderingService.COLOR_STROKE_DEFAULT,
    fillColor: DispatchAreaRenderingService.COLOR_FILL_DEFAULT,
    strokeWeight: DispatchAreaRenderingService.STROKE_WEIGHT_DEFAULT,
  };

  static EDITABLE_OPTIONS = {
    draggable: true,
    editable: true,
    strokeColor: DispatchAreaRenderingService.COLOR_STROKE_EDIT,
    fillColor: DispatchAreaRenderingService.COLOR_FILL_EDIT,
    strokeWeight: DispatchAreaRenderingService.STROKE_WEIGHT_BOLD,
  };

  readonly VERTEX_NOT_DEFINED = -1;

  private googleMap: google.maps.Map;
  private drawingManager: google.maps.drawing.DrawingManager;
  private polygons: Map<number, DispatchAreaPolygon> = new Map<number, DispatchAreaPolygon>();

  private polygonCompletesBehaviorSubject = new BehaviorSubject<DispatchAreaPolygon>(undefined);
  readonly polygonComplete$ = this.polygonCompletesBehaviorSubject.asObservable();

  private inDrawModeBehaviorSubject = new BehaviorSubject<PnDDispatchGroupRegion>(undefined);
  readonly inDrawMode$ = this.inDrawModeBehaviorSubject.asObservable();

  private deleteDispatchGroupRegionBehaviorSubject = new Subject<PnDDispatchGroupRegion>();
  readonly deleteDispatchGroupRegion$ = this.deleteDispatchGroupRegionBehaviorSubject.asObservable();

  private focusedPolygon: DispatchAreaPolygon;
  private currentPolygon: DispatchAreaPolygon;
  private allowPolygonDeletion = false;
  private currentVertex: number;
  private unsavedChanges: boolean = false;

  constructor(
    private dispatchAreaService: DispatchAreaService,
    private notificationMessageService: NotificationMessageService
  ) {}

  getPolygon(groupId: number): DispatchAreaPolygon {
    return this.polygons.get(groupId);
  }

  setMap(thisMap: google.maps.Map) {
    this.googleMap = thisMap;

    const options = {
      drawingControl: false,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: DispatchAreaRenderingService.EDITABLE_OPTIONS,
      drawingMode: null,
    } as google.maps.drawing.DrawingManagerOptions;

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon: DispatchAreaPolygon) =>
      this.polygonComplete(polygon)
    );

    google.maps.event.addDomListener(document, 'keyup', (event: KeyboardEvent) => {
      // For Delete; we need to ensure we are within the polygon and not on say a form input field...
      if (event.key === 'Delete' && event?.srcElement['tagName'] === 'DIV') {
        this.deleteAction();
      }
      if (event.key === 'Escape') {
        this.enableDrawingMode(undefined);
      }
    });

    this.drawingManager.setMap(this.googleMap);
  }

  enableDrawingMode(pndDispatchGroupRegion: PnDDispatchGroupRegion) {
    if (this.googleMap) {
      this.currentVertex = this.VERTEX_NOT_DEFINED;
      this.unsavedChanges = false;

      // One polygon at a time
      if (this.currentPolygon) {
        if (
          this.currentPolygon.pndDispatchGroupRegion &&
          !this.currentPolygon.pndDispatchGroupRegion.dispatchGroupRegion
        ) {
          this.currentPolygon.setMap(null);
          this.polygonCompletesBehaviorSubject.next(null);
        } else {
          this.currentPolygon.setOptions(DispatchAreaRenderingService.DEFAULT_OPTIONS);
          // we need to restore the original shape of the polygon
          if (this.currentPolygon.pndDispatchGroupRegion && this.currentPolygon.pndDispatchGroupRegion) {
            const tmpPolygon = this.dispatchAreaService.createDispatchAreaPolygon(
              this.currentPolygon.pndDispatchGroupRegion
            );
            this.currentPolygon.setPath(tmpPolygon.getPath());
            this.addPathChangeEvent(this.currentPolygon);
          }
        }
        this.currentPolygon = undefined;
      }

      this.drawingManager.setOptions({
        drawingMode:
          pndDispatchGroupRegion && !pndDispatchGroupRegion.dispatchGroupRegion
            ? google.maps.drawing.OverlayType.POLYGON
            : null, // null equals hand mode
      });

      if (pndDispatchGroupRegion && pndDispatchGroupRegion.dispatchGroupRegion) {
        // edition mode
        this.currentPolygon = this.polygons.get(pndDispatchGroupRegion.dispatchGroup.groupId);
        if (this.currentPolygon) {
          this.currentPolygon.setOptions(DispatchAreaRenderingService.EDITABLE_OPTIONS);
        }
      }

      this.inDrawModeBehaviorSubject.next(pndDispatchGroupRegion);
    }
  }

  showPolygons(show: boolean, dispatchGroupRegions: PnDDispatchGroupRegion[] = []) {
    // clean current polygons
    this.polygons.forEach((polygon: DispatchAreaPolygon, groupId: number) => {
      polygon.setMap(null);
    });
    this.polygons = new Map<number, DispatchAreaPolygon>();

    if (show) {
      _forEach(dispatchGroupRegions, (dispatchGroupRegion: PnDDispatchGroupRegion) => {
        if (dispatchGroupRegion.dispatchGroupRegion) {
          const polygon = this.dispatchAreaService.createDispatchAreaPolygon(dispatchGroupRegion);
          polygon.setOptions({
            strokeWeight: DispatchAreaRenderingService.STROKE_WEIGHT_DEFAULT,
          });
          polygon.setMap(this.googleMap);
          polygon.pndDispatchGroupRegion = dispatchGroupRegion;

          this.addPolygonChangeEvent(polygon);
          this.polygons.set(dispatchGroupRegion.dispatchGroup.groupId, polygon);
        }
      });
    }
  }

  focusedDispatchGroupChange(pnDDispatchGroupRegion: PnDDispatchGroupRegion) {
    if (pnDDispatchGroupRegion) {
      this.setFocusedPolygon(this.polygons.get(pnDDispatchGroupRegion.dispatchGroup.groupId));
    } else {
      this.setFocusedPolygon(undefined);
    }
  }

  private polygonComplete(polygon: DispatchAreaPolygon) {
    // If there is an overlap, trim the overlap section for the dispatch area
    this.polygons.forEach((existingPolygon: DispatchAreaPolygon, _groupId: number) => {
      polygon.setPaths(MapUtils.difference(polygon, existingPolygon));
    });

    polygon.setPaths(MapUtils.reducePrecision(polygon, 8));

    polygon.pndDispatchGroupRegion = this.inDrawModeBehaviorSubject.value;
    this.enableDrawingMode(undefined);

    this.currentPolygon = polygon;
    this.polygonCompletesBehaviorSubject.next(this.currentPolygon);

    this.addPolygonChangeEvent(this.currentPolygon);
  }

  private addPolygonChangeEvent(polygon: DispatchAreaPolygon) {
    google.maps.event.addListener(polygon, 'click', (e: google.maps.PolyMouseEvent) =>
      this.clickEventListener(polygon, e)
    );
    google.maps.event.addListener(polygon, 'mouseover', (e: google.maps.PolyMouseEvent) =>
      this.mouseOverEventListener(polygon, e)
    );
    google.maps.event.addListener(polygon, 'mouseout', (e: google.maps.PolyMouseEvent) =>
      this.mouseOutEventListener(polygon, e)
    );

    this.addPathChangeEvent(polygon);
  }

  private addPathChangeEvent(polygon: DispatchAreaPolygon) {
    _forEach(polygon.getPaths().getArray(), (path: google.maps.MVCArray<google.maps.LatLng>) => {
      google.maps.event.addListener(path, 'insert_at', (at: number) => this.insertAtEventListener(at));
      google.maps.event.addListener(path, 'set_at', (at: number) => this.setAtEventListener(at));
    });
  }

  private clickEventListener(polygon: DispatchAreaPolygon, e: google.maps.PolyMouseEvent) {
    if (!this.unsavedChanges) {
      this.enableDrawingMode(polygon.pndDispatchGroupRegion);
    }
  }

  private mouseOverEventListener(polygon: DispatchAreaPolygon, e: google.maps.PolyMouseEvent) {
    if (polygon === this.currentPolygon) {
      if (e.vertex !== undefined) {
        this.currentVertex = e.vertex;
        this.allowPolygonDeletion = false;
      } else {
        this.currentVertex = this.VERTEX_NOT_DEFINED;
        this.allowPolygonDeletion = true;
      }
    } else {
      this.dispatchAreaService.setFocusedDispatchGroupRegion(polygon?.pndDispatchGroupRegion);
    }
  }

  private mouseOutEventListener(polygon: DispatchAreaPolygon, e: google.maps.PolyMouseEvent) {
    if (polygon === this.currentPolygon) {
      if (e.vertex !== undefined) {
        this.currentVertex = this.VERTEX_NOT_DEFINED;
        this.allowPolygonDeletion = false;
      }
    } else {
      this.dispatchAreaService.setFocusedDispatchGroupRegion(undefined);
    }
  }

  private insertAtEventListener(at: number) {
    this.currentVertex = at;
    this.unsavedChanges = true;
  }

  private setAtEventListener(at: number) {
    this.currentVertex = at;
    this.unsavedChanges = true;
  }

  private deleteAction() {
    if (this.currentPolygon) {
      const vertices = this.currentPolygon.getPaths().getArray()[0];
      if (this.currentVertex >= 0) {
        vertices.removeAt(this.currentVertex);
        this.currentVertex = this.VERTEX_NOT_DEFINED;
        this.unsavedChanges = true;

        if (vertices.getLength() <= 1) {
          this.unsavedChanges = false;
          this.deleteDispatchGroupRegionBehaviorSubject.next(this.currentPolygon.pndDispatchGroupRegion);
        } else {
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Success, 'Point removed sucessfully from the region')
            .subscribe();
        }
      } else if (this.allowPolygonDeletion) {
        this.unsavedChanges = false;
        this.deleteDispatchGroupRegionBehaviorSubject.next(this.currentPolygon.pndDispatchGroupRegion);
      }
    }
  }

  private setFocusedPolygon(polygon: DispatchAreaPolygon) {
    if (this.focusedPolygon && this.focusedPolygon !== this.currentPolygon) {
      this.focusedPolygon.setOptions({
        strokeWeight: DispatchAreaRenderingService.STROKE_WEIGHT_DEFAULT,
      });
      this.focusedPolygon = undefined;
    }

    if (polygon) {
      this.focusedPolygon = polygon;
      this.focusedPolygon.setOptions({
        strokeWeight: DispatchAreaRenderingService.STROKE_WEIGHT_BOLD,
      });
    }
  }
}
