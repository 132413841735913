<div>
  <span *ngIf="pastDuePickupsCount$ | async as pastDuePickupsCount">
    <span class="attention-chip">
      ATTENTION
    </span>
    <b *ngIf="!isPastDuePickup">{{ pastDuePickupsCount }} Pickup Requests past due. </b>
    <b *ngIf="isPastDuePickup"> Viewing past due pickups only. </b>

    <a
        *ngIf="!isPastDuePickup"
        class="pnd-past-due-action"
        (click)="onViewIntent(true)"
        data-test="pnd-past-due__refresh-button">View</a>
    <a
        *ngIf="isPastDuePickup"
        class="pnd-past-due-action"
        (click)="onViewIntent(false)"
        data-test="pnd-past-due__refresh-button">Return to default view</a>
  </span>
</div>
