import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { PndStoreState } from '@pnd-store/index';
import { PndStore } from '@pnd-store/pnd-store';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ag-grid';
import { ProFormatterPipe, Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchRecord,
  ShipmentMetric,
} from '@xpo-ltl/sdk-cityoperations';
import { MetricValueKeyCd } from '@xpo-ltl/sdk-common';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ValueGetterParams } from 'ag-grid-community';
import { SpecialServicesHelper } from 'app/inbound-planning/shared/helpers/special-services/special-services.helper';
import { ShipmentLocationService } from 'app/inbound-planning/shared/services/shipment-location.service';
import moment from 'moment';
import { skip, takeUntil } from 'rxjs/operators';
import { PndRouteUtils } from 'shared/route-utils';
import { pndFrameworkComponents } from '../../frameworkComponents';
import { MetricBarService } from '../metric-bar-service';

@Component({
  selector: 'metric-shipments',
  templateUrl: './metric-shipments.component.html',
  styleUrls: ['./metric-shipments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricShipmentsComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { valueKeyCd: MetricValueKeyCd; shipments: DeliveryShipmentSearchRecord[] },
    public dialogRef: MatDialogRef<MetricShipmentsComponent>,
    protected proFormatterPipe: ProFormatterPipe,
    private shipmentLocationService: ShipmentLocationService,
    private pndStore$: PndStore<PndStoreState.State>,
    private cityOperationsApi: CityOperationsApiService,
    private metricBarService: MetricBarService
  ) {}

  iconName: string;

  dummy: DeliveryShipmentSearchRecord = new DeliveryShipmentSearchRecord();

  columnDef: ColDef[] = [
    {
      headerName: 'PRO',
      field: 'proNbr',
      colId: 'proNbr',
      minWidth: 120,
      // cellRenderer: 'actionLinkCellRenderer',
      valueFormatter: (params) => this.proFormatterPipe.transform(params.data?.proNbr, 10),
    },
    {
      headerName: 'SVC Date',
      field: 'serviceDateTime',
      minWidth: 120,
      valueGetter: (params: ValueGetterParams) => {
        const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
        const estimatedDeliveryDate = shipment?.estimatedDeliveryDate;
        if (estimatedDeliveryDate) {
          return moment(estimatedDeliveryDate).format('MM/DD/YYYY');
        } else {
          return '';
        }
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const dateA = new Date(nodeA?.data?.estimatedDeliveryDate);
        const dateB = new Date(nodeB?.data?.estimatedDeliveryDate);
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      headerName: 'In Plan',
      field: 'onExcludedTrailerInd',
      minWidth: 80,
      valueGetter: (params) => {
        const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
        return shipment.onExcludedTrailerInd ? 'N' : 'Y';
      },
      comparator: (valueA, valueB) => {
        if (!valueB) {
          return -1;
        }
        if (!valueA) {
          return 1;
        }
        return (valueA || '').localeCompare(valueB || '');
      },
    },
    {
      headerName: 'Route',
      field: 'routeName',
      minWidth: 80,
      valueFormatter: (params) => {
        const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
        return PndRouteUtils.getRouteId(shipment);
      },
      comparator: (valueA, valueB) => {
        if (!valueB) {
          return -1;
        }
        if (!valueA) {
          return 1;
        }
        return (valueA || '').localeCompare(valueB || '');
      },
    },
    {
      headerName: 'Current Location',
      field: 'currentLocation',
      minWidth: 200,
      valueGetter: (params) => {
        const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
        if (shipment) {
          return this.shipmentLocationService.getShipmentCurrentLocation(params.data.destinationSicCd, shipment);
        }
      },
      comparator: (valueA, valueB) => {
        if (!valueB) {
          return -1;
        }
        if (!valueA) {
          return 1;
        }
        return (valueA || '').localeCompare(valueB || '');
      },
    },
    {
      headerName: 'Consignee',
      field: 'consigneeName',
      colId: 'consigneeName',
      minWidth: 275,
      valueGetter: (params) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        return `${shipment?.consignee?.name1 ?? ''} ${shipment?.consignee?.name2 ?? ''}`;
      },
      comparator: (valueA, valueB) => {
        if (!valueB) {
          return -1;
        }
        if (!valueA) {
          return 1;
        }
        return (valueA || '').localeCompare(valueB || '');
      },
    },
    {
      headerName: 'Address',
      field: 'consigneeAddress',
      colId: 'consigneeAddress',
      minWidth: 200,
      valueGetter: (params) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        return shipment?.consignee?.addressLine1 ?? '';
      },
      comparator: (valueA, valueB) => {
        if (!valueB) {
          return -1;
        }
        if (!valueA) {
          return 1;
        }
        return (valueA || '').localeCompare(valueB || '');
      },
    },
    {
      headerName: 'City',
      field: 'city',
      minWidth: 100,
      valueGetter: (params) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        return shipment?.consignee?.cityName ?? '';
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const nodeASel: string = nodeA.data?.consignee?.cityName ?? '';
        const nodeBSel: string = nodeB.data?.consignee?.cityName ?? '';
        return nodeASel.localeCompare(nodeBSel);
      },
    },
    {
      headerName: 'Zip',
      field: 'zip',
      minWidth: 100,
      valueGetter: (params: ValueGetterParams) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        return shipment.consignee?.postalCd || '';
      },
      comparator: (valueA, valueB) => {
        if (!valueB) {
          return -1;
        }
        if (!valueA) {
          return 1;
        }
        return (valueA || '').localeCompare(valueB || '');
      },
    },
    {
      headerName: 'Special Services',
      field: 'specialServices',
      minWidth: 150,
      cellStyle: { 'justify-content': 'flex-start' },
      cellRenderer: 'specialServicesCellRenderer',

      valueGetter: (params) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        const specialServices = SpecialServicesHelper.getSpecialServicesForSummary(shipment?.specialServiceSummary);
        return specialServices;
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const countA = nodeA?.data?.specialServiceSummary?.length ?? 0;
        const countB = nodeB?.data?.specialServiceSummary?.length ?? 0;
        return countA - countB;
      },
    },
    {
      headerName: 'MM',
      field: 'motorMoves',
      minWidth: 50,
      type: 'numericColumn',
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        return shipment?.motorizedPiecesCount;
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const valA = nodeA.data.motorizedPiecesCount;
        const valB = nodeB.data.motorizedPiecesCount;
        return valA - valB;
      },
      cellStyle: { justifyContent: 'flex-end' },
    },
    {
      headerName: 'Weight',
      field: 'totalWeightLbs',
      minWidth: 50,
      type: 'numericColumn',
      valueFormatter: XpoAgGridFormatters.formatNumber,
      valueGetter: (params: ValueGetterParams) => {
        const shipment = params.data as DeliveryShipmentSearchRecord;
        return shipment?.totalWeightLbs;
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        const valA = nodeA.data.totalWeightLbs;
        const valB = nodeB.data.totalWeightLbs;
        return valA - valB;
      },
    },
  ];

  gridApi: GridApi;

  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    frameworkComponents: pndFrameworkComponents,
    enableBrowserTooltips: true,
    tooltipShowDelay: 0,
    rowData: [],
    columnDefs: this.columnDef,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      sortable: true,
    },
    overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No data found...</span>',
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading data...</span>',
  };

  ngOnInit() {
    this.iconName = this.getSpecialServiceIcon(this.data?.valueKeyCd);
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  getData(): void {
    this.metricBarService.shipmentMetrics$
      .pipe(takeUntil(this.unsubscriber.done$), skip(1))
      .subscribe((shipmentMetrics: ShipmentMetric[]) => {
        const { deliveryShipments } = shipmentMetrics?.filter(
          (shipmentMetric: ShipmentMetric) => shipmentMetric?.valueKeyCd === this.data?.valueKeyCd
        )[0];

        this.gridApi.setRowData(deliveryShipments);
        this.gridApi.hideOverlay();
        this.gridApi.sizeColumnsToFit();
      });
  }

  getSpecialServiceIcon(key: MetricValueKeyCd): string {
    if (key) {
      switch (key) {
        case MetricValueKeyCd.SHOW_SHIPMENTS_FOR_PLANNING:
          return 'ss_show';
        case MetricValueKeyCd.PREM_SHIPMENTS_FOR_PLANNING:
          return 'ss_premium';
        case MetricValueKeyCd.G_G_12_SHIPMENTS_FOR_PLANNING:
          return 'ss_guaranteed';
        default:
          return;
      }
    }
  }

  onGridReady(gridEvent: GridReadyEvent) {
    this.gridApi = gridEvent.api;
    this.gridApi.setRowData([]);
    this.gridApi.showLoadingOverlay();
    this.getData();
  }

  cancel() {
    this.dialogRef.close();
  }
}
