import { Component, ViewEncapsulation } from '@angular/core';
import { XpoGridCellEditorParams } from '../../models/inline-editing-error.model';
import { XpoGridBaseCellEditor } from '../base-editor.class';

export interface XpoGridAlphanumericCellEditorParams extends XpoGridCellEditorParams {
  maxLength: number;
}

@Component({
  selector: 'xpo-alphanumeric-cell-editor',
  templateUrl: './alphanumeric-editor.component.html',
  styleUrls: ['./alphanumeric-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-AgGrid-cellEditor--alphanumeric',
  },
})
export class XpoGridAlphanumericCellEditorComponent extends XpoGridBaseCellEditor {
  maxLength: number;
  declare value: string;
  agInit(params: XpoGridAlphanumericCellEditorParams): void {
    super.agInit(params);
    this.maxLength = params.maxLength;
  }

  evaluateKey(event): boolean {
    if (this.isTextSelected()) {
      return true;
    }
    if (!this.isValidKey(event.key) && this.maxLength && event.target.value.length > this.maxLength - 1) {
      return false;
    }

    return true;
  }
}
