import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchFilter,
  InterfaceAcct,
  ListPnDUnassignedStopsRqst,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { LocationOperationTypeCd, XrtSearchQueryHeader } from '@xpo-ltl/sdk-common';
import { sortBy as _sortBy } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap } from 'rxjs/operators';
import { UNBILLED_SHIPMENT_CONSIGNEE_ACCTINSTID } from 'shared/app.constants';
import { UnassignedDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/unassigned-deliveries-search-criteria.interface';
import { UnassignedDeliveriesViewId } from '../../components/unassigned-deliveries/unassigned-deliveries-view-data-store.service';
import { SicZonesAndSatellites } from '../models/sic-zones-and-satellites.model';
import { UnassignedDeliveriesCriteriaService } from './unassigned-deliveries-criteria.service';

export class AuditInfo {
  createdById = '';
  createdTimestamp = new Date();
  updateById = '';
  updatedTimestamp = new Date();
  createByPgmId = '';
  updateByPgmId = '';
  correlationId = '';
  createdApplicationId = '';
  lastUpdateApplicationId = '';
}

export class Consignee implements InterfaceAcct {
  acctInstId = UNBILLED_SHIPMENT_CONSIGNEE_ACCTINSTID;
  acctMadCd = '';
  creditStatusCode = '';
  locationTypeCode = '';
  parentAcctInstId = 0;
  nationalAcctInd: false;
  name1 = '';
  name2 = '';
  addressLine1 = '';
  addressLine2 = '';
  cityName = '';
  stateCd = '';
  postalCd = '';
  countryCd = '';
  deletedInd: false;
  lastCreatedBy = '';
  lastCreatedDateTime = new Date();
  latitudeNbr = 0;
  longitudeNbr = 0;
  zip4RestUs = '';
  auditInfo = new AuditInfo();
  geoCoordinates = { latitude: -1, longitude: -1 };
  geoCoordinatesGeo: { lat: 0; lon: 0 };
  customerProfileLocationTypeCd: LocationOperationTypeCd.BUSINESS_COMMERCIAL;
  phoneNbr = '';
  geoAreaInstId: number;
}

// NOTE: DO NOT include the Store here as it will cause a circular dependency error
@Injectable({
  providedIn: 'root',
})
export class UnassignedDeliveriesCacheService {
  constructor(
    private cityOperationsService: CityOperationsApiService,
    private unassignedDeliveriesCriteriaService: UnassignedDeliveriesCriteriaService
  ) {}

  private globalSearchDeliveryShipmentSubject: BehaviorSubject<Params> = new BehaviorSubject(undefined);
  readonly globalSearchMapShipment$: Observable<Params> = this.globalSearchDeliveryShipmentSubject.asObservable().pipe(
    filter((params: Params) => !!params),
    delay(500)
  );

  // cache of all unassigned deliveries from the last search
  private unassignedDeliveriesSubject = new BehaviorSubject<UnassignedStop[]>([]);
  readonly unassignedDeliveries$ = this.unassignedDeliveriesSubject.asObservable();
  get unassignedDeliveries() {
    return this.unassignedDeliveriesSubject.value;
  }

  // cache of all unmapped deliveries from the last search
  private unmappedDeliveriesSubject = new BehaviorSubject<UnassignedStop[]>([]);
  readonly unmappedDeliveries$ = this.unmappedDeliveriesSubject.asObservable();
  get unmappedDeliveries() {
    return this.unmappedDeliveriesSubject.value;
  }

  // cache of all unbilled deliveries from last search
  private unBilledDeliveriesSubject = new BehaviorSubject<UnassignedStop[]>([]);
  readonly unBilledDeliveries$ = this.unBilledDeliveriesSubject.asObservable();

  get unBilledDeliveries() {
    return this.unBilledDeliveriesSubject.value;
  }

  // track loading state of unassigned deliveries
  private loadingSubject = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loadingSubject.asObservable();
  get loading() {
    return this.loadingSubject.value;
  }
  set loading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  updateUnassignedDeliveries(value: UnassignedStop[]) {
    this.unassignedDeliveriesSubject.next(value);
  }

  updateUnmappedDeliveries(unassignedStops: UnassignedStop[]) {
    this.unmappedDeliveriesSubject.next(unassignedStops);
  }

  updateUnBilledDeliveries(unassignedStops: UnassignedStop[]) {
    this.unBilledDeliveriesSubject.next(unassignedStops);
  }

  /**
   * Get from server all unassigned and unmapped deliveries
   */
  searchUnassignedDeliveries(
    criteria: UnassignedDeliveriesSearchCriteria,
    planDate: Date,
    sicZonesAndSatellites: SicZonesAndSatellites,
    activeView: string
  ): Observable<UnassignedStop[]> {
    return this.unassignedDeliveriesCriteriaService.filterFromCriteria(criteria, planDate, sicZonesAndSatellites).pipe(
      switchMap((deliveryShipmentSearchFilter: DeliveryShipmentSearchFilter) => {
        return forkJoin([
          this.fetchUnassignedDeliveriesWithoutUnbilledUnMapped(deliveryShipmentSearchFilter, activeView),
          this.fetchUnmappedDeliveries(deliveryShipmentSearchFilter, activeView),
          this.fetchUnBilledDeliveries(deliveryShipmentSearchFilter, activeView),
        ]).pipe(
          // we only want to return the mapped deliveries here
          map((results) => results[0])
        );
      })
    );
  }

  /// Return all unassigned deliveries that match search criteria, except unmapped and unbilled
  private fetchUnassignedDeliveriesWithoutUnbilledUnMapped(
    deliveryShipmentSearchFilter: DeliveryShipmentSearchFilter,
    activeView?: string
  ): Observable<UnassignedStop[]> {
    const header: XrtSearchQueryHeader = {
      fields: null,
      pageNumber: 1,
      pageSize: 10000,
      sortExpressions: [],
      excludeFields: [],
    };

    const request: ListPnDUnassignedStopsRqst = {
      planByTrailerInd: activeView === UnassignedDeliveriesViewId.ByTrailer,
      header,
      filter: deliveryShipmentSearchFilter,
      unmappedInd: false,
      pastDueShipmentsInd: false,
      unbilledInd: false,
    };

    return this.cityOperationsService.listPnDUnassignedStops(request).pipe(
      catchError(() => {
        this.updateUnassignedDeliveries([]);
        return of([]);
      }),
      map((response) => {
        // store all unassigned deliveries
        let unassignedStops = 'unassignedStops' in response ? response?.unassignedStops : [];
        // filter anything without geoCoordinates as these are unmapped.
        unassignedStops = unassignedStops.filter(
          (stop) =>
            !stop.consignee.geoCoordinatesGeo ||
            stop.consignee.geoCoordinatesGeo?.lat !== 0 ||
            stop.consignee.geoCoordinatesGeo?.lon !== 0
        );
        unassignedStops = _sortBy(unassignedStops, (x) => x.consignee.acctInstId);
        this.updateUnassignedDeliveries(unassignedStops);
        return unassignedStops;
      })
    );
  }

  /// Unmapped Deliveries:  Return all deliveries that match criteria and that do not have valid lat/lng coordinates
  private fetchUnmappedDeliveries(
    deliveryShipmentSearchFilter: DeliveryShipmentSearchFilter,
    activeView?: string
  ): Observable<UnassignedStop[]> {
    if (deliveryShipmentSearchFilter?.consignee_geoCoordinatesGeo?.points) {
      return of(undefined);
    } else {
      const header: XrtSearchQueryHeader = {
        fields: null,
        pageNumber: 1,
        pageSize: 10000,
        sortExpressions: [],
        excludeFields: [],
      };

      const request: ListPnDUnassignedStopsRqst = {
        planByTrailerInd: activeView === UnassignedDeliveriesViewId.ByTrailer,
        header,
        filter: {
          ...deliveryShipmentSearchFilter,
          consignee_geoCoordinatesGeo: undefined,
          dispatchGroupId: undefined,
        },
        unmappedInd: true,
        pastDueShipmentsInd: false,
        unbilledInd: false,
      };

      return this.cityOperationsService.listPnDUnassignedStops(request).pipe(
        catchError(() => {
          this.updateUnmappedDeliveries([]);
          return of([]);
        }),
        map((response) => {
          // store all unmapped deliveries
          const unassignedStops = 'unassignedStops' in response ? response?.unassignedStops : [];
          this.updateUnmappedDeliveries(unassignedStops);
          return unassignedStops;
        })
      );
    }
  }
  /// UnBilled Deliveries:  Return all deliveries that  have an undefined consignee
  private fetchUnBilledDeliveries(
    deliveryShipmentSearchFilter: DeliveryShipmentSearchFilter,
    activeView?: string
  ): Observable<UnassignedStop[]> {
    if (deliveryShipmentSearchFilter?.consignee_geoCoordinatesGeo?.points) {
      return of(undefined);
    } else {
      const header: XrtSearchQueryHeader = {
        fields: null,
        pageNumber: 1,
        pageSize: 10000,
        sortExpressions: [],
        excludeFields: [],
      };

      const request: ListPnDUnassignedStopsRqst = {
        planByTrailerInd: activeView === UnassignedDeliveriesViewId.ByTrailer,
        header,
        filter: {
          ...deliveryShipmentSearchFilter,
          consignee_geoCoordinatesGeo: undefined,
          dispatchGroupId: undefined,
        },
        unmappedInd: false,
        pastDueShipmentsInd: false,
        unbilledInd: true,
      };

      return this.cityOperationsService.listPnDUnassignedStops(request).pipe(
        catchError(() => {
          return of([]);
        }),
        map((response) => {
          // response returns all stops with unbilled shipments
          const unassignedStops = 'unassignedStops' in response ? response?.unassignedStops : [];
          // API returns single stop containing all unbilled shipments.
          const stop = unassignedStops[0];

          const updatedShipments = stop?.deliveryShipments?.map((shipment) => {
            return { ...shipment, consignee: new Consignee() };
          });
          const unbilledShipmentContainerStop = {
            ...stop,
            stopId: 0,
            consignee: new Consignee(),
            deliveryShipments: updatedShipments,
          };

          this.updateUnBilledDeliveries([unbilledShipmentContainerStop]);

          return [unbilledShipmentContainerStop];
        })
      );
    }
  }

  setGlobalSearchDeliveryShipment(param: Params) {
    this.globalSearchDeliveryShipmentSubject.next(param);
  }
}
