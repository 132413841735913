import { Component, ViewEncapsulation } from '@angular/core';
import { GlobalFilterStoreSelectors, PndStoreState } from '@pnd-store/.';
import { PndStore } from '@pnd-store/pnd-store';
import { GlobalSearchDialogService } from '@xpo-ltl/ngx-ltl-global-search';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'handling-units-cell-renderer',
  templateUrl: './handling-units-cell-renderer.html',
  styleUrls: ['./handling-units-cell-renderer.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-ActionLinkCellRenderer' },
})
export class HandlingUnitsCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  count: number;
  isPartial: boolean = false;
  isSplit: boolean = false;
  constructor(
    private globalSearchDialogService: GlobalSearchDialogService,
    private pndStore$: PndStore<PndStoreState.State>
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.count = params?.data?.totalHandlingUnitCount;
    this.isPartial = params?.data?.handlingUnitPartialInd;
    this.isSplit = params?.data?.handlingUnitSplitInd;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onClickFunction(clickEvent) {
    const currentSic = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    if (this.params?.data?.proNbr && this.params?.data?.totalHandlingUnitCount > 0) {
      this.globalSearchDialogService.showHUDialog({
        proNbr: this.params?.data?.proNbr,
        shipmentLocationSicCd: currentSic,
      } as any);
    }

    clickEvent.stopImmediatePropagation();
  }
}
