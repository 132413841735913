import * as mapTypes from '@agm/core/services/google-maps-types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalFilterMapCoordinate } from '../../../..';
import { LayoutPreferenceService } from '../../../../../../../shared/layout-manager/services/layout-preference.service';
import { GlobalFilterStoreActions, PndStoreState } from '../../../../../../store';
import { EventItem, UnassignedDeliveryIdentifier } from '../../../../interfaces/event-item.interface';
import { MapDataStyleItem, MapToolbarService } from '../../../../services';

const LIGHT_STROKE_COLOR = '#304FFE';

@Component({
  selector: 'pnd-geo-filter-polygon-selection',
  templateUrl: './geo-filter-polygon-selection.component.html',
  styleUrls: ['./geo-filter-polygon-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoFilterPolygonSelectionComponent implements OnDestroy, AfterViewInit {
  @Output()
  toggleDrawMode = new EventEmitter<boolean>();

  markers: EventItem<UnassignedDeliveryIdentifier>[] = [];
  inDrawMode = false;

  private disabledSubject = new BehaviorSubject<boolean>(false);
  readonly disabled$ = this.disabledSubject.asObservable();

  private googleMap: google.maps.Map;
  private newShape: google.maps.Polyline;
  private mouseMoveDrawer: mapTypes.MapsEventListener;
  private mouseUpDrawer: mapTypes.MapsEventListener;
  private mouseDownDrawer: mapTypes.MapsEventListener;
  private unsubscriber = new Unsubscriber();
  private drawing: boolean;
  private strokeColor: string = LIGHT_STROKE_COLOR;
  private styleObj: MapDataStyleItem = {
    strokeColor: this.strokeColor,
    strokeWeight: 3,
    fillColor: this.strokeColor,
    fillOpacity: 0.1,
    clickable: false,
  };

  constructor(
    private mapToolbarService: MapToolbarService,
    private pndStore$: PndStore<PndStoreState.State>,
    private layoutPreferences: LayoutPreferenceService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'draw-geo-filter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/xpo-icons/draw-geo-filter.svg')
    );
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.inDrawMode) {
      this.togglePolygonDraw();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    if (this.googleMap) {
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'mousedown');
    }
  }

  ngAfterViewInit(): void {
    this.subscribeToMapInstance();

    this.subscribeToDrawModeState();

    this.subscribeToDrawModeOff();

    this.subscribeToActiveLayout();

    this.mapToolbarService.updatePolygonLayerStyle(this.googleMap, this.styleObj, 'geoFilterLayer');
  }

  private subscribeToActiveLayout() {
    this.layoutPreferences.activeLayout$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((layout) => {
      this.inDrawMode = false;
      this.disabledSubject.next(false);
    });
  }

  private subscribeToDrawModeOff() {
    this.mapToolbarService.toggleDrawModeOff$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.inDrawMode) {
        this.togglePolygonDraw();
      }
    });
  }

  private subscribeToDrawModeState() {
    this.mapToolbarService.setDrawModeState$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((enabled) => {
      if (this.inDrawMode && !enabled) {
        this.togglePolygonDraw();
      }
      this.disabledSubject.next(this.inDrawMode ? false : enabled);
    });
  }

  private subscribeToMapInstance() {
    this.mapToolbarService.mapInstance$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((map: google.maps.Map) => {
      this.googleMap = map;
    });
  }

  togglePolygonDraw(): void {
    this.inDrawMode = !this.inDrawMode;
    this.mapToolbarService.setDrawModeState(this.inDrawMode);
    this.toggleDrawMode.emit(this.inDrawMode);

    if (this.inDrawMode) {
      // this.mapToolbarService.clearPolygonLayer(this.googleMap, 'geoFilterLayer');
      this.mouseDownDrawer = google.maps.event.addListener(this.googleMap, 'mousedown', () => {
        if (this.inDrawMode && !this.drawing) {
          this.disableMap();
          this.initPolygonSelection();
        }
      });
    } else {
      google.maps.event.removeListener(this.mouseDownDrawer);
    }
  }

  private initPolygonSelection(): void {
    const polylineOptions: google.maps.PolylineOptions = {
      map: this.googleMap,
      strokeColor: this.strokeColor,
      strokeWeight: 3,
      clickable: false,
      zIndex: 1,
      editable: false,
    };

    this.newShape = new google.maps.Polyline(polylineOptions);
    this.mouseMoveDrawer = google.maps.event.addListener(this.googleMap, 'mousemove', ($event) => {
      this.newShape.getPath().push($event.latLng);
    });
    this.mouseUpDrawer = google.maps.event.addListener(this.googleMap, 'mouseup', () => {
      google.maps.event.removeListener(this.mouseMoveDrawer);

      const path = this.newShape.getPath();

      this.newShape.setMap(null);
      const arrayofLatLng = path.getArray();
      const arrayForPolygontoUse = this.reducerDP(arrayofLatLng, 300);

      const reducedLatLonArray = arrayForPolygontoUse.map((point) => {
        return { lat: point.lat(), lon: point.lng() };
      });

      this.mapToolbarService.drawPolygonLayer(this.googleMap, arrayofLatLng, this.styleObj, 'geoFilterLayer');

      this.onPolygonDrawn(reducedLatLonArray);
    });
  }

  private disableMap() {
    this.drawing = true;
    this.googleMap.setOptions({
      draggable: false,
    });
  }

  private enableMap() {
    this.drawing = false;
    this.googleMap.setOptions({
      draggable: true,
    });
  }

  private onPolygonDrawn(latLng: GlobalFilterMapCoordinate[]): void {
    this.pndStore$.dispatch(new GlobalFilterStoreActions.SetGeoFilterArea({ geoFilterArea: latLng }));
    if (this.inDrawMode) {
      this.togglePolygonDraw();
    }
    google.maps.event.removeListener(this.mouseUpDrawer);
    this.enableMap();
  }

  /* Stack-based Douglas Peucker line simplification routine
  https://en.wikipedia.org/wiki/Ramer–Douglas–Peucker_algorithm
   returned is a reduced GLatLng array
  */

  private reducerDP(source: google.maps.LatLng[], kink: number): google.maps.LatLng[] {
    /* source Input coordinates in GLatLngs 	*/
    /* kink	in metres, kinks above this depth kept  */
    /* kink depth is the height of the triangle abc where a-b and b-c are two consecutive line segments */

    let nSource: number,
      nStack: number,
      nDest: number,
      start: number,
      end: number,
      i: number,
      sig: number,
      devSqr: number,
      maxDevSqr: number,
      bandSqr: number,
      kain12: number,
      y12: number,
      d12: number,
      x13: number,
      y13: number,
      d13: number,
      x23: number,
      y23: number,
      d23: number;
    const F = (Math.PI / 180.0) * 0.5;
    const index = new Array();
    const sigStart = new Array();
    const sigEnd = new Array();

    if (source.length < 3) {
      return source;
    }

    nSource = source.length;
    bandSqr = (kink * 360.0) / (2.0 * Math.PI * 6378137.0); /* Now in degrees */
    bandSqr *= bandSqr;
    nDest = 0;
    sigStart[0] = 0;
    sigEnd[0] = nSource - 1;
    nStack = 1;

    /* while the stack is not empty  ... */
    while (nStack > 0) {
      start = sigStart[nStack - 1];
      end = sigEnd[nStack - 1];
      nStack--;

      if (end - start > 1) {
        /* any intermediate points ? */
        /* ... yes, so find most deviant intermediate point to
          either side of line joining start & end points */

        kain12 = source[end].lng() - source[start].lng();
        y12 = source[end].lat() - source[start].lat();

        if (Math.abs(kain12) > 180.0) {
          kain12 = 360.0 - Math.abs(kain12);
        }

        kain12 *= Math.cos(F * (source[end].lat() + source[start].lat()));
        /* use avg lat to reduce lng */
        d12 = kain12 * kain12 + y12 * y12;

        for (i = start + 1, sig = start, maxDevSqr = -1.0; i < end; i++) {
          x13 = source[i].lng() - source[start].lng();
          y13 = source[i].lat() - source[start].lat();
          if (Math.abs(x13) > 180.0) {
            x13 = 360.0 - Math.abs(x13);
          }
          x13 *= Math.cos(F * (source[i].lat() + source[start].lat()));
          d13 = x13 * x13 + y13 * y13;

          x23 = source[i].lng() - source[end].lng();
          y23 = source[i].lat() - source[end].lat();
          if (Math.abs(x23) > 180.0) {
            x23 = 360.0 - Math.abs(x23);
          }

          x23 *= Math.cos(F * (source[i].lat() + source[end].lat()));
          d23 = x23 * x23 + y23 * y23;

          if (d13 >= d12 + d23) {
            devSqr = d23;
          } else if (d23 >= d12 + d13) {
            devSqr = d13;
          } else {
            devSqr = ((x13 * y12 - y13 * kain12) * (x13 * y12 - y13 * kain12)) / d12; // solve triangle
          }

          if (devSqr > maxDevSqr) {
            sig = i;
            maxDevSqr = devSqr;
          }
        }

        if (maxDevSqr < bandSqr) {
          /* is there a sig. intermediate point ? */
          /* ... no, so transfer current start point */
          index[nDest] = start;
          nDest++;
        } else {
          /* ... yes, so push two sub-sections on stack for further processing */
          nStack++;
          sigStart[nStack - 1] = sig;
          sigEnd[nStack - 1] = end;
          nStack++;
          sigStart[nStack - 1] = start;
          sigEnd[nStack - 1] = sig;
        }
      } else {
        /* ... no intermediate points, so transfer current start point */
        index[nDest] = start;
        nDest++;
      }
    }

    /* transfer last point */
    index[nDest] = nSource - 1;
    nDest++;

    /* make return array */
    const r = new Array();
    for (let ix = 0; ix < nDest; ix++) {
      r.push(source[index[ix]]);
    }
    return r;
  }
}
