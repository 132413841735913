import { Injectable } from '@angular/core';
import { XpoLtlBaseCacheService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  ListPnDStopsPath,
  ListPnDStopsQuery,
  ListPnDStopsResp,
  ListPnDTripStopsResp,
  RouteSummary,
  Stop,
} from '@xpo-ltl/sdk-cityoperations';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface StopsCacheParams {
  routeInstId: number;
  tripInstId?: number;
}

@Injectable({ providedIn: 'root' })
export class StopsCacheService extends XpoLtlBaseCacheService<StopsCacheParams, Stop[]> {
  constructor(private cityOperationsApiService: CityOperationsApiService) {
    super(XpoLtlBaseCacheService.MAX_AGE, XpoLtlBaseCacheService.RETRY_DELAY, 5 * 60000); // reget stops if more than 5 minutes old
  }

  protected getKeyFromParams(params: StopsCacheParams): string {
    return params?.routeInstId?.toString(10) ?? '';
  }

  protected requestData(params: StopsCacheParams): Observable<Stop[]> {
    const pathParams: ListPnDStopsPath = { ...new ListPnDStopsPath(), routeInstId: `${params.routeInstId}` };
    const queryParams: ListPnDStopsQuery = { ...new ListPnDStopsQuery() };

    if (params.tripInstId) {
      return this.cityOperationsApiService.listPnDTripStops({ tripInstId: params.tripInstId }).pipe(
        catchError((err) => {
          return of({
            tripSummary: {
              routeSummary: [],
              tripDriver: undefined,
            },
            tripStops: [],
          } as ListPnDTripStopsResp);
        }),
        map((response: ListPnDTripStopsResp) => {
          const routes: RouteSummary[] = response?.tripSummary?.routeSummary?.filter((r) => !!r?.route) || [];
          const pickupsOriginDestination: Stop[] =
            response?.tripSummary?.routeSummary?.find((r) => !r?.route)?.stops || [];

          const route: RouteSummary = routes.find((r) => r?.route?.routeInstId === params.routeInstId);
          const stops: Stop[] = route?.stops || [];

          // push pickups, and also origin and destination for route balancing
          stops.push(...pickupsOriginDestination);

          return stops;
        })
      );
    } else {
      return this.cityOperationsApiService.listPnDStops(pathParams, queryParams).pipe(
        catchError((err) => {
          return of({
            route: undefined,
            stops: [],
          } as ListPnDStopsResp);
        }),
        map((response: ListPnDStopsResp) => {
          return response.stops;
        })
      );
    }
  }
}
