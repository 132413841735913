<div class="confirm-split">
  <h1 mat-dialog-title data-test="confirm-split-title" class="dialog-header">
    <p class="error-message"><xpo-icon iconName="error" class="error-icon"></xpo-icon>Select a location for the Stop</p>
  </h1>

  <div mat-dialog-content class="content-padding">
    <h1>
      <p class="message">
        You are splitting activities with different customer names and/or<br />addresses. Select one to be the location
        of the stop. This will not <br />change the customer information of the separate activities
        <br />
      </p>
    </h1>
    <mat-radio-group class="radio-group" [(ngModel)]="selectedCustomerItem">
      <mat-radio-button
        class="radio-button"
        *ngFor="let stop of uniqueCustomerItems; trackBy: trackStopBy"
        [value]="stop"
      >
        <span class="span-class">{{ stop.customer_address }}, {{ stop.customer_state }}</span>
        <span class="span-class--span-flex">{{ stop.customer_name }}</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div mat-dialog-actions>
    <div class="pnd-ConfirmCancel-actionButtons--left">
      <ng-container>
        <button
          data-test="confirm-split-confirmBtn"
          class="button-confirm"
          type="button"
          mat-flat-button
          (click)="confirmAction()"
        >
          CONTINUE
        </button>
      </ng-container>

      <ng-container>
        <button
          data-test="confirm-split-cancelBtn"
          class="button-cancel"
          type="button"
          mat-stroked-button
          (click)="cancelAction()"
        >
          CANCEL
        </button>
      </ng-container>
    </div>
  </div>
</div>
