export enum StoreSourcesEnum {
  PLANNING_MAP = 'planning-map',
  TRIP_PLANNING_GRID = 'trip-planning-grid',
  UNASSIGNED_DELIVERY_GRID = 'unassigned-delivery-grid',
  UNASSIGNED_PICKUP_GRID = 'unassigned-pickup-grid',
  ASSIGNED_STOP_GRID = 'assigned-stop-grid',
  ROUTE_STOPS_GRID = 'route-stops-grid',
  PLANNING_ROUTE_SHIPMENTS_GRID = 'planning-route-shipments-grid',
  PAST_DUE_SHIPMENTS_GRID = 'past-due-shipments-grid',
  UPDATE_TRIP_GRID = 'update-trip-grid',
  ROUTE_BALANCING_BOARD = 'route-balancing-board',
  ASSIGN_TO_ROUTE_DIALOG = 'assign-to-route-dialog',
  POLYGON_SELECTION = 'polygon-selection',
  GLOBAL_SEARCH = 'global-search',
  REDUX_STORE = 'redux-store',
  RESULTS_RETURNED = 'results-returned', // modified by an effect in the Store
  REASSIGN_ACTION = 'reassign-pickup-action',
  UnmappedPickupsPanel = 'unmapped-pickups-panel',
  DRIVER_LOCATION_LAYER = 'driver-location-layer',
  DISPATCH_TRIPS_BOARD = 'dispatch-trips-board',
  SPOTS_AND_DROPS_GRID = 'spots-and-drops-grid',
}
