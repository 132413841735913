import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';

export interface DetailGridToggleCellRendererParams extends ICellRendererParams {
  smallIconsStyle?: boolean;
}

export interface CustomExpandContractRowNode extends RowNode {
  showExpandIcon: boolean;
}
@Component({
  selector: 'pnd-detail-grid-toggle-cell-renderer',
  templateUrl: './detail-grid-toggle-cell-renderer.component.html',
  styleUrls: ['./detail-grid-toggle-cell-renderer.component.scss'],
})
export class DetailGridToggleCellRendererComponent implements ICellRendererAngularComp {
  params: DetailGridToggleCellRendererParams;
  smallIconsStyle: boolean = false;
  rowNode: CustomExpandContractRowNode;

  agInit(params: DetailGridToggleCellRendererParams): void {
    this.params = params;
    this.rowNode = params.node as CustomExpandContractRowNode;
    this.smallIconsStyle = params.smallIconsStyle;
  }

  refresh(params: DetailGridToggleCellRendererParams): boolean {
    return false;
  }
}
