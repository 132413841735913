import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionTimestampInterceptor implements HttpInterceptor {
  constructor() {}

  static useTransactionTimestampHeader = {
    headers: {
      'Transaction-Timestamp': 'true',
    },
  };

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('Transaction-Timestamp') === 'true') {
      request = request.clone({
        setHeaders: {
          'Transaction-Timestamp': `${new Date().getTime()}`,
        },
      });
    }
    return next.handle(request);
  }
}
