import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { DispatchGroup, PnDDispatchGroupRegion } from '@xpo-ltl/sdk-cityoperations';
import { ComponentChangeUtils } from 'app/inbound-planning/shared/classes/component-change-utils';
import { FormUtils } from 'app/inbound-planning/shared/classes/form-utils.class';
import { MapUtils } from 'app/inbound-planning/shared/classes/map-utils';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import { NotificationMessageService } from 'core/services/notification-message.service';
import { isEmpty as _isEmpty, toUpper as _toUpper } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../../store';
import { PndStore } from '../../../../../store/pnd-store';
import {
  DispatchAreaPolygon,
  DispatchAreaRenderingService,
} from '../../../services/dispatch-area/dispatch-area-rendering.service';
import { DispatchAreaService } from '../../../services/dispatch-area/dispatch-area.service';

// Form control names
enum DispatchAreaFormFields {
  Name = 'groupName',
  Description = 'groupDescription',
}

@Component({
  selector: 'app-map-dispatch-area-card',
  templateUrl: './map-dispatch-area-card.component.html',
  styleUrls: ['./map-dispatch-area-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapDispatchAreaCardComponent implements OnInit, OnDestroy {
  @Input() pndDispatchGroupRegion: PnDDispatchGroupRegion;
  @Output() scrollTo = new EventEmitter<ElementRef>();

  @ViewChild('groupName', { static: true }) groupNameField: ElementRef;
  @ViewChild('container', { static: true }) container: ElementRef;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  private defaultValues: {
    [key: string]: string;
  };

  private polygonCompletesBehaviorSubject = new BehaviorSubject<DispatchAreaPolygon>(undefined);
  readonly polygonComplete$ = this.polygonCompletesBehaviorSubject.asObservable();

  private focusedBehaviorSubject = new BehaviorSubject<boolean>(false);
  readonly focused$ = this.focusedBehaviorSubject.asObservable();

  private isProcessingSubject = new BehaviorSubject<boolean>(false);
  readonly isProcessing$ = this.isProcessingSubject.asObservable();

  readonly DispatchAreaFormFields = DispatchAreaFormFields;

  editMode: boolean;
  disabled$: Observable<PnDDispatchGroupRegion>;

  dispatchAreaForm = new UntypedFormGroup({
    [DispatchAreaFormFields.Name]: new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
    [DispatchAreaFormFields.Description]: new UntypedFormControl('', Validators.maxLength(30)),
  });

  constructor(
    private dispatchAreaRenderingService: DispatchAreaRenderingService,
    private dispatchAreaService: DispatchAreaService,
    private pndStore$: PndStore<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.patchRegionValue();

    this.disabled$ = this.dispatchAreaRenderingService.inDrawMode$;

    this.dispatchAreaRenderingService.inDrawMode$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((pnDDispatchGroupRegion: PnDDispatchGroupRegion) => {
        this.editMode =
          pnDDispatchGroupRegion &&
          pnDDispatchGroupRegion.dispatchGroup.groupId === this.pndDispatchGroupRegion.dispatchGroup.groupId;

        if (this.editMode) {
          this.scrollTo.next(this.container);
        }
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });

    this.dispatchAreaService.focusedDispatchGroupRegion$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((pnDDispatchGroupRegion: PnDDispatchGroupRegion) => {
        this.focusedBehaviorSubject.next(
          pnDDispatchGroupRegion &&
            pnDDispatchGroupRegion.dispatchGroup.groupId === this.pndDispatchGroupRegion.dispatchGroup.groupId
        );
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });

    this.dispatchAreaRenderingService.polygonComplete$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((polygon: DispatchAreaPolygon) => {
        if (polygon && !MapUtils.isValidPolygon(polygon)) {
          this.notificationMessageService
            .openNotificationMessage(
              NotificationMessageStatus.Error,
              'Invalid area.  A minimum of three points are required.'
            )
            .subscribe();
        }

        if (
          polygon &&
          polygon.pndDispatchGroupRegion.dispatchGroup.groupId === this.pndDispatchGroupRegion.dispatchGroup.groupId
        ) {
          this.polygonCompletesBehaviorSubject.next(polygon);
          this.editMode = true;

          setTimeout(() => {
            this.groupNameField.nativeElement.focus();
          }, 100);
        } else {
          this.editMode = false;
        }
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  defineArea(): void {
    this.dispatchAreaRenderingService.enableDrawingMode(this.pndDispatchGroupRegion);

    this.dispatchAreaService.openUsageNotification();
  }

  deleteArea(): void {
    this.isProcessingSubject.next(true);
    if (this.pndDispatchGroupRegion.dispatchGroup.groupId) {
      this.dispatchAreaService
        .deleteDispatchGroup$(this.pndDispatchGroupRegion)
        .pipe(take(1))
        .pipe(
          take(1),
          finalize(() => {
            this.isProcessingSubject.next(false);
          })
        )
        .subscribe(() => {
          this.dispatchAreaRenderingService.enableDrawingMode(undefined);
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        });
    } else {
      this.dispatchAreaService
        .loadDispatchGroups$()
        .pipe(
          take(1),
          finalize(() => {
            this.isProcessingSubject.next(false);
          })
        )
        .subscribe(() => {
          this.dispatchAreaRenderingService.enableDrawingMode(undefined);
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        });
    }
  }

  onSubmit(): void {
    this.isProcessingSubject.next(true);
    this.pndDispatchGroupRegion.dispatchGroup.groupName = _toUpper(this.dispatchAreaForm.value.groupName);
    this.pndDispatchGroupRegion.dispatchGroup.groupDescription = this.dispatchAreaForm.value.groupDescription;

    if (this.isNew()) {
      this.pndDispatchGroupRegion.dispatchGroup.sicCd = this.pndStore$.selectSnapshot(
        GlobalFilterStoreSelectors.globalFilterSic
      );

      this.dispatchAreaService
        .createDispatchGroup$(this.pndDispatchGroupRegion.dispatchGroup, this.polygonCompletesBehaviorSubject.value)
        .pipe(
          takeUntil(this.unsubscriber.done$),
          finalize(() => {
            this.isProcessingSubject.next(false);
          })
        )
        .subscribe((created: boolean) => {
          this.refresh(created);
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        });
    } else {
      let polygon: DispatchAreaPolygon = this.polygonCompletesBehaviorSubject.value;
      if (!polygon) {
        polygon = this.dispatchAreaRenderingService.getPolygon(this.pndDispatchGroupRegion.dispatchGroup.groupId);
      }
      this.dispatchAreaService
        .updateDispatchGroup$(this.pndDispatchGroupRegion, polygon)
        .pipe(
          takeUntil(this.unsubscriber.done$),
          finalize(() => {
            this.isProcessingSubject.next(false);
          })
        )
        .subscribe((updated: boolean) => {
          this.refresh(updated);
          ComponentChangeUtils.detectChanges(this.changeDetectorRef);
        });
    }
  }

  cancel(): void {
    this.dispatchAreaForm.reset(this.defaultValues);

    this.dispatchAreaRenderingService.enableDrawingMode(undefined);
    this.polygonCompletesBehaviorSubject.next(undefined);
  }

  setEditModeEnable(): void {
    if (!this.editMode) {
      this.dispatchAreaRenderingService.enableDrawingMode(this.pndDispatchGroupRegion);
    }
  }

  isNew(): boolean {
    return this.pndDispatchGroupRegion.dispatchGroup.groupId === undefined;
  }

  onMouseEnter(): void {
    if (!this.isNew()) {
      this.dispatchAreaService.setFocusedDispatchGroupRegion(this.pndDispatchGroupRegion);
      this.focusedBehaviorSubject.next(true);
    }
  }

  onMouseLeave(): void {
    this.dispatchAreaService.setFocusedDispatchGroupRegion(undefined);
    this.focusedBehaviorSubject.next(false);
  }

  hasErrors(formField: DispatchAreaFormFields): boolean {
    if (!this.dispatchAreaForm) {
      return false;
    } else {
      return !_isEmpty(this.dispatchAreaForm.get(formField).errors);
    }
  }

  hasError(formField: DispatchAreaFormFields, errorKey: string): boolean {
    return FormUtils.hasError(this.dispatchAreaForm, formField, errorKey);
  }

  private refresh(refresh: boolean) {
    if (refresh) {
      this.dispatchAreaService
        .loadDispatchGroups$()
        .pipe(take(1))
        .subscribe();
      this.cancel();
    }
  }

  private patchRegionValue() {
    if (!this.pndDispatchGroupRegion) {
      this.pndDispatchGroupRegion = new PnDDispatchGroupRegion();
      this.pndDispatchGroupRegion.dispatchGroup = new DispatchGroup();
      this.pndDispatchGroupRegion.dispatchGroup.groupName = '';
      this.pndDispatchGroupRegion.dispatchGroup.groupDescription = '';
    }

    this.defaultValues = {
      [DispatchAreaFormFields.Name]: this.pndDispatchGroupRegion.dispatchGroup.groupName,
      [DispatchAreaFormFields.Description]: this.pndDispatchGroupRegion.dispatchGroup.groupDescription,
    };
    this.dispatchAreaForm.patchValue(this.defaultValues);
  }
}
