import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PastDueShipmentsStoreEffects } from './past-due-shipments-store.effects';
import { routesReducer } from './past-due-shipments-store.reducers';
import { StateKey } from './past-due-shipments-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, routesReducer),
    EffectsModule.forFeature([PastDueShipmentsStoreEffects]),
  ],
})
export class PastDueShipmentsStoreModule {}
