<div class="loading-spinner" *ngIf="showSpinner$ | async">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<xpo-ltl-dialog-title>{{
  showOnlyPremiumWarnings ? 'Premium Shipment Warnings' : 'Override Assign Shipment Warnings'
}}</xpo-ltl-dialog-title>
<div
  mat-dialog-content
  [ngClass]="{ showOnlyPremiumWarnings: showOnlyPremiumWarnings, showAllWarnings: !showOnlyPremiumWarnings }"
>
  <h3 *ngIf="showOnlyPremiumWarnings">
    Attention: You are trying to assign the following premium shipments to incorrect route. Are you sure you want to
    proceed?
  </h3>
  <h3 *ngIf="!showOnlyPremiumWarnings">Shipments will not be assigned unless selected below</h3>
  <table mat-table matSort [dataSource]="dataSource" matSortActive="retailWarnings" matSortDirection="asc">
    <ng-container *ngFor="let column of visibleColumns; trackBy: trackColumnBy" [matColumnDef]="column.field">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column.name }}
      </th>

      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="column.field">
          <ng-container *ngSwitchCase="specialServicesField">
            <pnd-special-services
              [value]="element[column.field]"
              [specialServiceMarks]="element['specialServiceMarks']"
            ></pnd-special-services>
          </ng-container>
          <ng-container *ngSwitchCase="consigneeField">
            <a (click)="onConsigneeClick(element[column.field][1])">
              {{ element[column.field][0] }}
            </a>
          </ng-container>
          <ng-container *ngSwitchCase="warningMessagesField">
            <ng-container *ngFor="let warningMessage of element[column.field]; trackBy: trackColumnBy">
              <ng-template
                [ngTemplateOutlet]="warningMessageDisplay"
                [ngTemplateOutletContext]="{ message: warningMessage }"
              ></ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ element[column.field] }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleAllOnOff($event)" [checked]="isToggleAllOn"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-checkbox
          #checkboxes
          (change)="addSelectionToOverride($event, element)"
          [checked]="element.warningMessages.length === 0 || (element.hasPremiumWarning && overridePremiumChecked)"
          [disabled]="element.disabled || element.hasPremiumWarning"
        ></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnFields; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnFields"
      [ngClass]="{
        'tdc-error': row.disabled,
        'premium-warning': row.hasPremiumWarning,
        'normal-warning': !row.hasPremiumWarning
      }"
    ></tr>
  </table>
</div>
<div class="override-confirmation" *ngIf="showOnlyPremiumWarnings">
  <div>
    <mat-checkbox [checked]="overridePremiumChecked" (change)="onOverridePremiumChange($event)"
      >Override Warning</mat-checkbox
    >
  </div>
  <div>
    By checking this box, I <strong>{{ user?.displayName }}</strong> am overriding the Premium Services warning and
    routing the selected shipments to the indicated route. This action will be logged for reporting.
  </div>
</div>
<div mat-dialog-actions>
  <button
    [disabled]="!overridePremiumChecked"
    *ngIf="showOnlyPremiumWarnings"
    mat-flat-button
    (click)="confirmOverride()"
  >
    <span *ngIf="hasMixedWarnings">CONTINUE To ASSIGN</span>
    <span *ngIf="hasOnlyPremiumWarnings">OVERRIDE ASSIGN</span>
  </button>
  <button
    *ngIf="!showOnlyPremiumWarnings"
    [disabled]="selectedsToOverride.length === 0"
    mat-flat-button
    [mat-dialog-close]="selectedsToOverride"
  >
    OVERRIDE ASSIGN
  </button>
  <button mat-stroked-button (click)="cancel()">
    CANCEL
  </button>
</div>

<ng-template #warningMessageDisplay let-message="message">
  <div class="tdc-message">
    <div
      [ngClass]="{
        'error-circle': message.isError,
        'tdc-circle': !message.isError,
        'premium-warning-circle': message.isPremium
      }"
    ></div>
    <span>{{ message.message }}</span>
  </div>
</ng-template>
