import { Injectable } from '@angular/core';
import { PickupLineItemSearchFilter } from '@xpo-ltl/sdk-common';
import { isEmpty as _isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { PndXrtService } from '../../../../core/services';
import { UnassignedPickupsSearchCriteria } from '../../../store/unassigned-pickups-store/unassigned-pickups-search-criteria.interface';

@Injectable({
  providedIn: 'root',
})
export class UnassignedPickupsCriteriaService {
  constructor(private pndXrtService: PndXrtService) {}

  /**
   * Return a PickupLineItemSearchFilter based on the passed filter criteria
   */
  filterFromCriteria(criteria: UnassignedPickupsSearchCriteria): Observable<PickupLineItemSearchFilter> {
    if (!criteria) {
      return of(new PickupLineItemSearchFilter());
    } else {
      const filter: PickupLineItemSearchFilter = {
        ...new PickupLineItemSearchFilter(),
        q: criteria?.Q,
        header_pickupTerminalSicCd: this.pndXrtService.toXrtFilterEquals(criteria.pickupTerminalSicCd),
        header_pickupDate: !_isEmpty(criteria.pickupDate)
          ? this.pndXrtService.toXrtFilterEqualsDateRange(criteria?.pickupDate?.min, criteria?.pickupDate?.max)
          : undefined,
        header_pickupTypeCd: this.pndXrtService.toXrtFilterValues(criteria.pickupTypeCd),
        header_readyTime: this.pndXrtService.toXrtFilterEqualsDate(criteria.readyTime),
        header_closeTime: this.pndXrtService.toXrtFilterEqualsDate(criteria.closeTime),
        header_cadCallNbr: this.pndXrtService.toXrtFilterValues(criteria.cadCallNbr),
        // TODO - Ind don't seem to be added correctly!
        guaranteedInd: this.pndXrtService.toXrtFilterEquals(criteria.guaranteedInd),
        hazardousMtInd: this.pndXrtService.toXrtFilterEquals(criteria.hazardousMtInd),
        freezableInd: this.pndXrtService.toXrtFilterEquals(criteria.freezableInd),
        hostOriginTerminalSicCd: this.pndXrtService.toXrtFilterValues(criteria.hostOriginTerminalSicCd),
        proNbr: this.pndXrtService.toXrtFilterValues(criteria.proNbr),
        shipper_geoCoordinatesGeo: this.pndXrtService.toXrtFilterPoints(criteria.shipperGeoCoordinatesGeo),
        dispatchGroupId: this.pndXrtService.toXrtFilterValues(criteria.dispatchGroupIds),
      };
      return of(filter);
    }
  }
}
