<div class="pnd-GridToolbarSearch">
  <ng-container *ngIf="!(searchActive$ | async); else search">
    <div data-test="pnd-grid-toolbar-activate-search" class="pnd-GridToolbarSearch__btn-container">
      <button
          mat-icon-button
          class="pnd-GridToolbarSearch__btn"
          [matTooltip]="searchDisplayText"
          matTooltipPosition="above"
          (click)="toggleSearchActive(true)">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #search>
  <div class="pnd-GridToolbarSearch__input">
    <mat-form-field class="ngx-FormField--inline ngx-FormField--borderless" appearance="standard">
      <input
          #searchInput
          matInput
          (focus)="onInputFocus()" 
          (focusout)="onInputFocusOut()"
          [formControl]="searchCtrl"
          data-test="pnd-grid-toolbar-search-input"
          [placeholder]="searchDisplayText" />
      <ng-container *ngIf="!searchCtrl?.value; else clearSearch">
        <button
            (click)="toggleSearchActive(false)"
            data-test="pnd-grid-toolbar-search-btn"
            mat-icon-button
            matSuffix
            matTooltip="Close"
            matTooltipPosition="above">
          <mat-icon>search</mat-icon>
        </button>
      </ng-container>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #clearSearch>
  <button
      (click)="handleClearSearchFilter()"
      data-test="pnd-grid-toolbar-clear-btn"
      mat-icon-button
      matSuffix
      matTooltip="Clear"
      matTooltipPosition="above">
    <mat-icon>clear</mat-icon>
  </button>
</ng-template>
