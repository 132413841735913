import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ModifyTripActivityId } from '@pnd-store/modify-trip-details-store/modify-trip-details.state';
import { TripNode } from '@xpo-ltl/sdk-cityoperations';
import { NodeTypeCd } from '@xpo-ltl/sdk-common';
import { ConfirmMergeDialogData } from '../confirm-merge/confirm-merge.component';

export class ConfirmSplitDialogData {
  activities: ModifyTripActivityId[];

  constructor(activities) {
    this.activities = activities;
  }
}

@Component({
  selector: 'pnd-confirm-split',
  templateUrl: './confirm-split.component.html',
  styleUrls: ['./confirm-split.component.scss'],
})
export class ConfirmSplitComponent implements OnInit {
  uniqueCustomerItems: ModifyTripActivityId[];
  selectedCustomerItem: ModifyTripActivityId;

  constructor(
    public dialogRef: MatDialogRef<ConfirmMergeDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmSplitDialogData
  ) {}

  ngOnInit(): void {
    this.uniqueCustomerItems = this.data?.activities.filter((item, i, uniqueList) => {
      return uniqueList.indexOf(uniqueList.find((t) => t?.customer_acct_instId === item?.customer_acct_instId)) === i;
    });
    this.selectedCustomerItem = this.data?.activities?.[0];
  }

  changeSelection(value): void {
    this.selectedCustomerItem = value;
  }

  trackStopBy(index, stop: ModifyTripActivityId): number | null {
    if (!stop) {
      return null;
    }
    return stop?.tripInstId + index;
  }

  confirmAction(): void {
    const targetStop = {
      nodeInstId: this.selectedCustomerItem.customer_acct_instId,
      nodeTypeCd: NodeTypeCd.CUSTOMER_LOCATION,
    } as TripNode;
    this.dialogRef.close({ value: targetStop });
  }

  cancelAction(): void {
    this.dialogRef.close({ value: undefined });
  }
}
