import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { InjectionComponentConfig } from '../../models/injection-component-config.model';

@Injectable({
  providedIn: 'root',
})
export class SplitPanelService {
  private readonly rightPanelComponentConfigSubject = new BehaviorSubject<InjectionComponentConfig>(undefined);
  readonly rightPanelComponentConfig$ = this.rightPanelComponentConfigSubject.asObservable();
  get rightPanelComponentConfig(): InjectionComponentConfig {
    return this.rightPanelComponentConfigSubject.value;
  }

  private readonly leftPanelComponentConfigSubject = new BehaviorSubject<InjectionComponentConfig>(undefined);
  readonly leftPanelComponentConfig$ = this.leftPanelComponentConfigSubject.asObservable();
  get leftPanelComponentConfig(): InjectionComponentConfig {
    return this.leftPanelComponentConfigSubject.value;
  }

  readonly showRightPanel$ = this.rightPanelComponentConfig$.pipe(map((config) => !!config));
  readonly showLeftPanel$ = this.leftPanelComponentConfig$.pipe(map((config) => !!config));

  constructor() {}

  openRightPanel(componentConfig: InjectionComponentConfig) {
    this.rightPanelComponentConfigSubject.next(componentConfig);
  }

  openLeftPanel(componentConfig: InjectionComponentConfig) {
    this.leftPanelComponentConfigSubject.next(componentConfig);
  }

  updateRightPanel(partialComponentConfig: Partial<InjectionComponentConfig> | InjectionComponentConfig = {}) {
    this.rightPanelComponentConfigSubject.next({ ...this.rightPanelComponentConfig, ...partialComponentConfig });
  }

  updateLeftPanel(partialComponentConfig: Partial<InjectionComponentConfig> | InjectionComponentConfig = {}) {
    this.leftPanelComponentConfigSubject.next({ ...this.leftPanelComponentConfig, ...partialComponentConfig });
  }

  closeRightPanel() {
    this.rightPanelComponentConfigSubject.next(undefined);
  }

  closeLeftPanel() {
    this.leftPanelComponentConfigSubject.next(undefined);
  }

  reset() {
    this.closeRightPanel();
    this.closeLeftPanel();
  }
}
