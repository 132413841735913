import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateKey, State } from '@pnd-store/dock-routes-store/dock-routes.state';

export const selectDockStopsState = createFeatureSelector<State>(StateKey);
export const selectDockRoutesState = createFeatureSelector<State>(StateKey);
export const selectIncompleteDockStopsState = createFeatureSelector<State>(StateKey);

export const dockStops = createSelector(selectDockStopsState, (state: State) => state.dockStops);

export const selectedDockActivities = createSelector(
  selectDockRoutesState,
  (state: State) => state.selectedDockActivities
);

export const incompleteDockStops = createSelector(
  selectIncompleteDockStopsState,
  (state: State) => state.incompleteDockStops
);
