import { Pipe, PipeTransform } from '@angular/core';
import { PickupTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'pickupTypeCd' })
export class PickupTypeCdPipe implements PipeTransform {
  transform(value: PickupTypeCd): string {
    switch (value || '') {
      case PickupTypeCd.HE:
        return 'Hook Empty';
      case PickupTypeCd.HL:
        return 'Hook Loaded';
      case PickupTypeCd.PU:
        return 'Pickup';
      case PickupTypeCd.SE:
        return 'Spot Empty';
    }

    return value;
  }
}
