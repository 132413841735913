import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import * as GoldenLayout from 'golden-layout';
import { ContentItem } from 'golden-layout';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  isEqual as _isEqual,
  isString as _isString,
  map as _map,
  some as _some,
  uniq as _uniq,
} from 'lodash';
import { GoldenLayoutComponent, IExtendedGoldenLayoutConfig } from 'ngx-golden-layout';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { AppNavPageLink } from 'shared/enums/app-nav-page-link.enum';
import { LayoutComponentName } from '../../enums/layout-component-name.enum';
import { LayoutConfig } from '../layout-config.interface';

export type NamedContentItem = GoldenLayout.ContentItem & { componentName: string; container: GoldenLayout.Container };

/**
 * Define an activation binding between Component A & B
 */
export interface ComponentBinding {
  componentA: LayoutComponentName;
  componentB: LayoutComponentName;
}

const DRAGEND_FIRE_TIMER = 1500;

@Injectable({
  providedIn: 'root',
})
export class LayoutManagerService implements OnDestroy {
  private unsubscriber = new Unsubscriber();

  private initializedSubject = new ReplaySubject<boolean>(1);
  readonly initialized$ = this.initializedSubject.asObservable();

  private goldenLayoutComponent: GoldenLayoutComponent;
  private componentBindings: ComponentBinding[] = [];

  private navPageChangedSubject: BehaviorSubject<AppNavPageLink> = new BehaviorSubject(undefined);
  readonly navPageChanged$ = this.navPageChangedSubject.asObservable();

  /**
   * Return the GoldenLayout instance, or throw an error if container component has not been set
   */
  private get goldenLayout(): GoldenLayout {
    if (this.goldenLayoutComponent) {
      const gl = this.goldenLayoutComponent.getGoldenLayoutInstance();
      if (gl) {
        return gl;
      } else {
        throw new Error('GoldenLayout not initialized');
      }
    } else {
      throw new Error('GoldenLayoutComponent not initialized');
    }
  }

  private panelActivatedSubject = new ReplaySubject<NamedContentItem>(1);
  readonly panelActivated$ = this.panelActivatedSubject.asObservable();

  private layoutActivatedSubject = new ReplaySubject<IExtendedGoldenLayoutConfig>(1);
  readonly layoutActivated$ = this.layoutActivatedSubject.asObservable();

  private stateChangedSubject = new Subject<void>();
  readonly stateChanged$ = this.stateChangedSubject.asObservable();

  constructor() {}

  /**
   * Initialize the LayoutManager with reference to the host component and container
   */
  initialize(component: GoldenLayoutComponent) {
    this.goldenLayoutComponent = component;

    this.goldenLayoutComponent.stateChanged.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.stateChangedSubject.next(undefined);
    });

    this.goldenLayoutComponent.tabActivated
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((item: NamedContentItem) => {
        this.panelActivatedSubject.next(item);
        this.processBoundComponents(item);
      });

    this.goldenLayoutComponent.layout
      .pipe(
        skip(1), // skips initial empty layout
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((layout: IExtendedGoldenLayoutConfig) => {
        this.layoutActivatedSubject.next(layout);
      });

    this.initializedSubject.next(true);
  }

  /**
   * Sever connection to the GoldenLayout component and mark LayoutManager as un-initialized
   */
  deinitialize() {
    this.initializedSubject.next(false);
    this.goldenLayoutComponent = undefined;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  // #region Layout Management

  // for the passed component, ensure any bound components to it is also activated
  private processBoundComponents(component: NamedContentItem) {
    const selectedComponentName = component?.componentName;
    const selectedComponentStackName = component?.parent?.config?.title;

    _forEach(this.componentBindings, ({ componentA, componentB }) => {
      const isComponentAActive = this.isPanelActive(componentA);
      const isComponentBActive = this.isPanelActive(componentB);

      if (
        selectedComponentName === componentA &&
        !this.isPanelInStack(componentB, selectedComponentStackName) &&
        !isComponentBActive
      ) {
        this.activatePanel(componentB);
      }

      if (
        selectedComponentName === componentB &&
        !this.isPanelInStack(componentA, selectedComponentStackName) &&
        !isComponentAActive
      ) {
        this.activatePanel(componentA);
      }
    });
  }

  /**
   * Setup binding panels so that when componentA is active, B is active, and visa versa.
   */
  bindPanels(value: ComponentBinding[]) {
    this.componentBindings = value;
  }

  /**
   * Return LayoutConfig describing the current Layout
   */
  getLayoutConfig(): LayoutConfig {
    return this.goldenLayout.toConfig();
  }

  /**
   * Set the specified component as active in its stack
   */
  activatePanel(componentName: string) {
    try {
      const stacks = this.getRoot().getItemsByType('stack');
      if (stacks && stacks.length > 0) {
        stacks.forEach((stack) => {
          stack.contentItems.forEach((item) => {
            if ((item as NamedContentItem).componentName === componentName) {
              stack.setActiveContentItem(item as NamedContentItem);
            }
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Returns true if the named component is currently in the Layout (NOTE: may be hidden in a stack!)
   * @param componentName name of component to find
   */
  isPanelOpen(componentName: string) {
    const openedComponents = this.getOpenedPanels();
    return _some(openedComponents, (c: NamedContentItem) => {
      return _isEqual(c.componentName, componentName);
    });
  }

  /**
   * Return the component as a NamedContentItem. If passed a string, looks for an open panel
   * with that type and returns it.
   */
  private asNamedContentItem(component: string | NamedContentItem): NamedContentItem {
    if (_isString(component)) {
      return this.getPanel(component);
    } else {
      return component;
    }
  }

  /**
   * Returns true if the named component Panel is in the named Stack of panels
   */
  isPanelInStack(panel: string | NamedContentItem, stack: string): boolean {
    const component = this.asNamedContentItem(panel);
    const stackName = component?.parent?.config?.title;
    return stackName === stack;
  }

  /**
   * Returns true if the specified component Panel is the active component in a tab
   */
  isPanelActive(panel: string | NamedContentItem): boolean {
    const component = this.asNamedContentItem(panel);
    return !!component?.container?.tab?.isActive;
  }

  /**
   * Returns the named component Panel if it existsin the layout, or undefined if it is not
   */
  getPanel(componentName: string): NamedContentItem {
    const component = this.getOpenedPanels().find((c: NamedContentItem) => c.componentName === componentName);
    return component;
  }

  /**
   * Return list of componentNames for all instantiated Panels
   */
  getOpenPanelNames(): string[] {
    const openedPanels = this.getOpenedPanels();
    return _map(openedPanels, (item) => {
      return item?.componentName ?? '';
    });
  }

  /**
   * Return list of all open Component Panels
   */
  getOpenedPanels(): NamedContentItem[] {
    try {
      return this.getRoot().getItemsByType('component') as NamedContentItem[];
    } catch (error) {
      return [];
    }
  }

  /**
   * Return the root of the layout
   */
  getRoot(): GoldenLayout.ContentItem {
    return this.goldenLayout.root;
  }

  /**
   * update the size of the layout and panels
   */
  updateSize() {
    this.goldenLayout.updateSize();
  }

  /**
   * Go through all open stacks and if it is maximized, toggle to be non-maximized
   */
  deMaximizeAll() {
    // get all open stacks
    const stacks = _uniq(
      _map(
        _filter(this.getOpenedPanels(), (pane) => !!pane),
        (pane) => pane.parent
      )
    );

    _forEach(stacks, (stack) => {
      if (stack.isMaximised) {
        stack.toggleMaximise();
      }
    });
  }

  /**
   * Go through all open stacks and return the maximized stack
   */
  findMaximisedStack(): ContentItem {
    let maximizedStack: ContentItem = null;
    // get all open stacks
    const stacks = _uniq(
      _map(
        _filter(this.getOpenedPanels(), (pane) => !!pane),
        (pane) => pane.parent
      )
    );

    _forEach(stacks, (stack) => {
      if (stack.isMaximised) {
        maximizedStack = stack;
      }
    });
    return maximizedStack;
  }

  /**
   * Toggle maximize state of the passed panel.
   */
  toggleMaximize(panel: NamedContentItem) {
    if (panel) {
      if (panel.parent) {
        if (panel.parent.isMaximised && panel === panel.parent.getActiveContentItem()) {
          // active pane is being shrunken
          panel.parent.toggleMaximise();
        } else {
          this.deMaximizeAll();
          panel.parent.toggleMaximise();
          panel.parent.setActiveContentItem(panel);
        }
      } else {
        // this pane isn't part of a stack, so directly toggle size
        panel.toggleMaximise();
      }
    }
  }

  /**
   * Adds a component with specified title to the root.
   * Does nothing if the component Panel is already open
   * @param componentName component to add to the layout
   * @param title text to display in the panel tab
   * @param addToMainStack add component to the main stack
   */
  addPanel(componentName: string, title: string, addToMainStack: boolean = false) {
    if (!this.isPanelOpen(componentName)) {
      // Add stack to the root element when there is no content items.
      if (this.getRoot().contentItems && this.getRoot().contentItems.length === 0) {
        // ensure that there is a stack to add the panel to
        // This is a hack to solve a bug in the library 'ng6-golden-layout' when there are no contentItems at the root
        this.getRoot().addChild(this.goldenLayout.createContentItem({ type: 'stack', title: title }) as any);
      }
      // add the new component as a child to the root
      const mainStack = this.getOpenedPanels()[0]?.parent;
      if (addToMainStack && mainStack) {
        mainStack.addChild({
          type: 'component',
          componentName,
          title,
        });
      } else {
        this.getRoot().contentItems[0].addChild({
          type: 'component',
          componentName,
          title,
        });
      }
    }
  }

  /**
   * Remove the specified panel from the layout
   */
  removePanel(componentName: string) {
    if (this.goldenLayout?.root) {
      const components = this.getOpenedPanels();
      const componentInstance = _find(components, (c) => {
        return _isEqual(c.componentName, componentName);
      });

      if (componentInstance) {
        componentInstance.remove();
      }
    }
  }

  /**
   * Change Navigation page
   * @param navPage Operations or Customers
   */
  changeNavPage(navPage: AppNavPageLink): void {
    this.navPageChangedSubject.next(navPage);
  }

  getActivePanel(): string {
    const openedPanels = this.getOpenedPanels();
    const returnValue: string[] = [];
    openedPanels.forEach((item) => {
      if (item?.container?.tab?.isActive && item.componentName !== LayoutComponentName.PLANNING_MAP) {
        returnValue.push(item.componentName);
      }
    });
    return returnValue.length > 1 || returnValue.length === 0 ? undefined : returnValue[0];
  }

  /**
   * Hide the close button for the tab component
   * Does nothing if the component Panel is already closed
   * @param componentName component to add to the layout
   */
  hideCloseBtn(componentName: string) {
    if (this.isPanelOpen(componentName)) {
      const componentPanel = this.getPanel(componentName);
      componentPanel.container.tab.closeElement.hide();
    }
  }

  /**
   * Show the close button for the tab component
   * Does nothing if the component Panel is already closed
   * @param componentName component to add to the layout
   */
  showCloseBtn(componentName: string) {
    if (this.isPanelOpen(componentName)) {
      const componentPanel = this.getPanel(componentName);
      componentPanel.container.tab.closeElement.show();
    }
  }

  /**
   * Get text from tab title
   * Does nothing if the component Panel is already closed
   * @param componentName component to add to the layout
   */
  getTabTitle(componentName: string) {
    if (this.isPanelOpen(componentName)) {
      const componentPanel = this.getPanel(componentName);
      return componentPanel.container.tab.titleElement.text();
    }
  }

  /**
   * set tab title
   * Does nothing if the component Panel is already closed
   * @param componentName component to add to the layout
   * @param title text to append
   */
  setTabTitle(componentName: string, title: string) {
    if (this.isPanelOpen(componentName)) {
      const componentPanel = this.getPanel(componentName);
      componentPanel.container.tab.setTitle(title);
      componentPanel.container.layoutManager.on('itemDragged', (event: NamedContentItem) => {
        if (event && event.container.tab.titleElement.text()?.includes(title)) {
          setTimeout(() => {
            componentPanel.container.tab.setTitle(title);
            const countNumber = title.match(/\d+/)[0];
            if (parseInt(countNumber, 10) > 0) {
              this.hideCloseBtn(componentName);
            }
          }, DRAGEND_FIRE_TIMER);
        }
      });
    }
  }

  // #endregion
}
