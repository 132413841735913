import { EventItem, UnassignedPickupIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedPickupsSearchCriteria } from './unassigned-pickups-search-criteria.interface';

export const StateKey = 'unassignedPickupsState';

export interface State {
  readonly criteria: UnassignedPickupsSearchCriteria;
  readonly lastUpdate: Date;
  readonly selectedPickups: EventItem<UnassignedPickupIdentifier>[];
  readonly filteredUnassignedPickupIds: { [key: number]: boolean };
  readonly focusedPickup: EventItem<UnassignedPickupIdentifier>;
  readonly pickupDate: { min: Date; max: Date };
}

export const initialState: State = {
  criteria: null,
  lastUpdate: new Date(0),
  selectedPickups: [],
  filteredUnassignedPickupIds: {},
  focusedPickup: {
    id: undefined,
    source: undefined,
  },
  pickupDate: {
    min: undefined,
    max: undefined,
  },
};
