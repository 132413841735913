import { Injectable } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  DispatchRoute,
  DispatchTrip,
  DsrLocation,
  GetPnDTripDsrLocationsResp,
  InterfaceAcct,
  Route,
  Stop,
} from '@xpo-ltl/sdk-cityoperations';
import { NodeTypeCd } from '@xpo-ltl/sdk-common';
import { TripNode } from '@xpo-ltl/sdk-shipmentods';
import {
  filter as _filter,
  find as _find,
  flatten as _flatten,
  forEach as _forEach,
  forOwn as _forOwn,
  map as _map,
  size as _size,
  sortBy as _sortBy,
} from 'lodash';
import { BehaviorSubject, Observable, of, Subscriber, zip } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import shader from 'shader';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../../../../core';
import { PndAppUtils } from '../../../../../../../shared/app-utils';
import { LayoutPreferenceService } from '../../../../../../../shared/layout-manager';
import { PndRouteUtils } from '../../../../../../../shared/route-utils';
import { GlobalFilterStoreSelectors, PndStoreState, TripsStoreSelectors } from '../../../../../../store';
import { NumberToValueMap } from '../../../../../../store/number-to-value-map';
import { PndStore } from '../../../../../../store/pnd-store';
import { MapUtils } from '../../../../../shared/classes/map-utils';
import { DispatcherTripsService } from '../../../../../shared/services/dispatcher-trips.service';
import { RouteColorService } from '../../../../../shared/services/route-color.service';
import { LegRenderType } from '../enums/leg-render-type.enum';
import { RenderType } from '../enums/render-type.enum';
import { GoogleLegInfo } from '../interfaces/google-leg-info.interface';
import { Leg } from '../interfaces/leg.interface';
import { RouteRenderInfo } from '../interfaces/route-render-info';
import { RoutesInfoWindowData } from '../interfaces/routes-info-window-data.interface';
import { TripRenderInfo } from '../interfaces/trip-render-info';
import { GoogleRenderingService } from './google-rendering.service';
import { RenderStop, TripRenderingHelper } from './helpers/trip-rendering.helper';
import { COMPLETED_BLUR_OPACITY, INCOMPLETED_BLUR_OPACITY, PolylinesService } from './polylines.service';
import { TripBreadcrumbsService } from './trip-breadcrumbs.service';
import { TripDriverLocationsService } from './trip-driver-locations.service';

@Injectable({
  providedIn: 'root',
})
export class TripRenderingService {
  static readonly maxCachingThreshold: number = 60; // how long to cache values
  private currentZoomLevel: number = -1;
  private routesWithoutDriverDirections: Route[] = [];
  private updateRoutesUnsubscriber: Unsubscriber;
  private showBreadcrumbsUnsubscriber: Unsubscriber;

  map: google.maps.Map;
  driverDirectionsNotifier: BehaviorSubject<Route[]> = new BehaviorSubject(undefined);
  cachedTripRenderInfos: NumberToValueMap<TripRenderInfo> = {};

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private routeColorService: RouteColorService,
    private tripDriverLocationsService: TripDriverLocationsService,
    private googleRenderingService: GoogleRenderingService,
    private tripBreadcrumbsService: TripBreadcrumbsService,
    private tripRenderingHelper: TripRenderingHelper,
    private polylinesService: PolylinesService,
    private layoutPreferenceService: LayoutPreferenceService,
    private notificationMessageService: NotificationMessageService,
    private dispatcherTripsService: DispatcherTripsService
  ) {
    this.subscribeToColorChange();
  }

  /**
   * Set the color of the legs to the passed color
   */
  private updateColorForLegs(legs: Leg[], color: string): void {
    _forEach(legs, (leg) => {
      leg.color = color;
      leg.originalPolylineOptions.strokeColor = color;

      _forEach(leg.originalPolylineOptions.icons, (icon) => {
        if (leg.isBreadcrumb) {
          icon.icon.fillColor = color;
          icon.icon.strokeColor = shader(color, -0.5);
        } else {
          icon.icon.strokeColor = color;
        }
      });

      if (leg.polyline) {
        leg.polyline.setOptions(leg.originalPolylineOptions);
      }
    });
  }

  /**
   * Begin listening for changes to colors and updating when a color is changed
   */
  private subscribeToColorChange(): void {
    this.routeColorService.colorChanged$.pipe(filter((value) => !!value)).subscribe((value) => {
      if (this.layoutPreferenceService.isDispatcherLayout()) {
        const tripRenderInfo = this.cachedTripRenderInfos[value.id];

        if (tripRenderInfo) {
          _forEach(tripRenderInfo.routesRenderInfos, (routeRenderInfo) => (routeRenderInfo.color = value.color));

          this.updateColorForLegs(tripRenderInfo.projectedLegs, value.color);
          this.updateColorForLegs(tripRenderInfo.breadcrumbsLegs, value.color);
        }
      } else {
        _forOwn(this.cachedTripRenderInfos, (tripRenderInfo) => {
          const routeRenderInfo = _find(tripRenderInfo.routesRenderInfos, (renderInfo) => {
            return renderInfo.route.routeInstId === value.id;
          });

          if (routeRenderInfo) {
            routeRenderInfo.color = value.color;

            const projectedLegs: Leg[] = _filter(
              tripRenderInfo.projectedLegs,
              (leg) => !leg.isConnector && leg.route.routeInstId === routeRenderInfo.route.routeInstId
            );
            this.updateColorForLegs(projectedLegs, routeRenderInfo.color);

            const breadcrumbsLegs: Leg[] = _filter(
              tripRenderInfo.breadcrumbsLegs,
              (leg) => !leg.isConnector && leg.route.routeInstId === routeRenderInfo.route.routeInstId
            );
            this.updateColorForLegs(breadcrumbsLegs, routeRenderInfo.color);
          }
        });
      }
    });
  }

  /**
   * Process the legs to the given tripRenderInfo.
   * @param tripInstId
   * @param tripRenderInfo
   * @param projected If true generate projected legs with google directions, otherwise it'll generate with breadcrumbs
   */
  private processLegs(tripInstId: number, tripRenderInfo: TripRenderInfo, projected: boolean = true): Observable<void> {
    return new Observable((observer: Subscriber<void>) => {
      const directionsRequest: google.maps.DirectionsRequest = {
        origin: undefined,
        destination: undefined,
        waypoints: [],
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING,
      };

      const generatedLegs: GoogleLegInfo = projected
        ? this.tripRenderingHelper.generateProjectedLegs(+tripInstId, tripRenderInfo)
        : this.tripRenderingHelper.generateBreadcrumbsLegs(+tripInstId, tripRenderInfo);

      if (generatedLegs.calculateLegsWithGoogle) {
        directionsRequest.origin = generatedLegs.waypointsCandidates.shift();
        directionsRequest.destination = generatedLegs.waypointsCandidates.pop();
        directionsRequest.waypoints = _map(generatedLegs.waypointsCandidates, (wps) => {
          return {
            location: wps,
            stopover: true,
          } as google.maps.DirectionsWaypoint;
        });

        const optimizedDirectionRequests: google.maps.DirectionsRequest[] = this.googleRenderingService.optimizeDirectionRequests(
          directionsRequest
        );

        const chunkedLegsObservers: Observable<{
          chunkOrder: number;
          googleLegs: Leg[];
        }>[] = [];

        _forEach(
          optimizedDirectionRequests,
          (optimizedDirectionRequest: google.maps.DirectionsRequest, chunkOrder: number) => {
            chunkedLegsObservers.push(
              this.googleRenderingService.calculateDirectionLegs(optimizedDirectionRequest).pipe(
                PndAppUtils.retry(2, 500, 500),
                take(1),
                catchError((error) => {
                  const routes: Route[] = _map(
                    tripRenderInfo.routesRenderInfos,
                    (routeRenderInfo) => routeRenderInfo.route
                  );
                  _forEach(routes, (route) => {
                    if (!_find(this.routesWithoutDriverDirections, (rt) => rt.routeInstId === route.routeInstId)) {
                      this.routesWithoutDriverDirections.push(route);
                    }
                  });

                  return of([]);
                }),
                map((googleLegs: Leg[]) => {
                  return {
                    chunkOrder,
                    googleLegs,
                  };
                })
              )
            );
          }
        );

        zip(...chunkedLegsObservers)
          .pipe(take(1))
          .subscribe((chunkedLegs) => {
            chunkedLegs.sort((a, b) => a.chunkOrder - b.chunkOrder);

            const googleLegs: Leg[] = _flatten(_map(chunkedLegs, (chunk) => chunk.googleLegs));
            const tripLegs: Leg[] = projected ? tripRenderInfo.projectedLegs : tripRenderInfo.breadcrumbsLegs;

            let i: number = _size(googleLegs) - 1;
            let j: number = _size(tripLegs) - 1;

            const fullBreadcrumbs = !_find(tripLegs, (leg) => !leg.isBreadcrumb);

            while (i >= 0 && j >= 0) {
              const googleLeg: Leg = googleLegs[i];
              const leg: Leg = tripLegs[j];

              if (fullBreadcrumbs) {
                if (leg.connectionLost) {
                  leg.points = googleLeg.points;
                  i--;
                }

                j--;
              } else {
                // Mix case or full projected
                if (!leg.isBreadcrumb || leg.connectionLost) {
                  leg.points = googleLeg.points;
                }

                i--;
                j--;
              }
            }

            observer.next();
            observer.complete();
          });
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  /**
   * Shows/hides the route lines, and optionally the completed legs.
   * @param tripInstId
   * @param tripRenderInfo
   * @param showCompletedLegs
   * @param zoomLevel
   * @param googleMap
   */
  updateVisibility(tripInstId: number, tripRenderInfo: TripRenderInfo, showCompletedLegs: boolean, zoomLevel: number) {
    const zoomHasChanged = (lastZoom: number, current: number): boolean => {
      return (
        lastZoom !== current &&
        ((lastZoom <= MapUtils.EAGLE_VIEW_LEVEL && current >= MapUtils.EAGLE_VIEW_LEVEL) ||
          (lastZoom >= MapUtils.EAGLE_VIEW_LEVEL && current <= MapUtils.EAGLE_VIEW_LEVEL))
      );
    };

    const legs: Leg[] = tripRenderInfo.isShowingBreadcrumbs
      ? tripRenderInfo.breadcrumbsLegs
      : tripRenderInfo.projectedLegs;

    if (_find(legs, (leg) => !leg.polyline) || zoomHasChanged(this.currentZoomLevel, zoomLevel)) {
      // Update polylines based on the new zoom level
      this.polylinesService.updatePolylines(legs, zoomLevel);

      // Re-attach polyline event listeners
      this.polylinesService.addEventListeners(tripInstId, legs);
    }

    this.currentZoomLevel = zoomLevel;

    for (const leg of legs) {
      let isVisible = !leg.hasCompletedStops || (leg.hasCompletedStops && showCompletedLegs);

      if (leg.isConnector) {
        const isDispatcherLayout = this.layoutPreferenceService.isDispatcherLayout();
        isVisible = isVisible && isDispatcherLayout;

        if (leg.isSicConnector) {
          isVisible = isVisible && tripRenderInfo.isShowingBreadcrumbs;
        }

        if (leg.isPickupClusterConnector) {
          isVisible = true;
        }
      }

      leg.polyline.setMap(isVisible ? this.map : null);
    }
  }

  /**
   * Update visibility for each RouteRenderInfo
   * @param showCompletedLegs
   * @param zoomLevel
   * @param googleMap
   */
  updateAllVisibility(showCompletedLegs: boolean, zoomLevel: number) {
    _forOwn(this.cachedTripRenderInfos, (tripRenderInfo, tripInstId) => {
      this.updateVisibility(+tripInstId, tripRenderInfo, showCompletedLegs, zoomLevel);
    });
  }

  private tripRenderInfosForStops(stopsForTrips: NumberToValueMap<Stop[]>): NumberToValueMap<TripRenderInfo> {
    const tripRenderInfos: { [key: number]: TripRenderInfo } = {};

    _forOwn(stopsForTrips, (stops: Stop[], tripInstId: string) => {
      const stopsAbleToBeRendered: Stop[] = _filter(stops, (stop) => this.tripRenderingHelper.isAbleToRender(stop));

      if (_size(stopsAbleToBeRendered) > 0) {
        tripRenderInfos[+tripInstId] = {
          routesRenderInfos: [],
          projectedLegs: [],
          breadcrumbsLegs: [],
          isShowingBreadcrumbs: false,
          driverLocationBreadcrumbs: [],
          driverCurrentLocation: undefined,
          driverName: '',
          lastUpdated: new Date(),
          stops: [],
        };
      }
    });

    return tripRenderInfos;
  }

  /**
   * Builds a map structure of TripRenderInfos based on the given information.
   * @param routes
   * @param stopsForRoutes
   * @param stopsForTrips
   */
  updateRoutes(
    routes: Route[],
    stopsForRoutes: NumberToValueMap<Stop[]>,
    stopsForTrips: NumberToValueMap<Stop[]> = {}
  ): Observable<{ [key: number]: TripRenderInfo }> {
    return new Observable((observer) => {
      this.routesWithoutDriverDirections = []; // reset any errors
      if (this.updateRoutesUnsubscriber) {
        this.updateRoutesUnsubscriber.complete();
      }

      this.updateRoutesUnsubscriber = new Unsubscriber();

      const sicLocation = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSicLatLng);
      this.driverDirectionsNotifier.next(undefined);

      // if the Trip has stops, then add them too the list of stops too render
      const tripRenderInfos = this.tripRenderInfosForStops(stopsForTrips);
      let hasRenderableStops = _size(tripRenderInfos) > 0;

      _forOwn(stopsForRoutes, (stops, routeInstId) => {
        // get the route for this set of stops
        const route = _find(routes, (r) => r.routeInstId === +routeInstId);
        if (route) {
          // filter out stops that are invalid (no customer or no lat/lng), then
          // sort the stops by their sequenceNbr.
          const stopsAbleToBeRendered: Stop[] = _filter(stops, (stop) => this.tripRenderingHelper.isAbleToRender(stop));

          // create initial Route rendering info with valid stops
          if (_size(stopsAbleToBeRendered) > 0) {
            const stop: Stop = _find(stopsAbleToBeRendered, (renderableStop) => !!renderableStop?.tripNode?.tripInstId);

            if (stop) {
              hasRenderableStops = true;
              const tripInstId = stop.tripNode.tripInstId;

              // Group by trip
              if (!tripRenderInfos[tripInstId]) {
                tripRenderInfos[tripInstId] = {
                  routesRenderInfos: [],
                  projectedLegs: [],
                  breadcrumbsLegs: [],
                  isShowingBreadcrumbs: false,
                  driverLocationBreadcrumbs: [],
                  driverCurrentLocation: undefined,
                  driverName: '',
                  lastUpdated: new Date(),
                  stops: [],
                };
              }

              const routeColor = this.layoutPreferenceService.isDispatcherLayout()
                ? this.routeColorService.getColorForTrip(tripInstId)
                : this.routeColorService.getColorForRoute(route.routeInstId);

              const routeRenderInfo: RouteRenderInfo = {
                route: route,
                routeName: PndRouteUtils.getRouteId(route),
                color: routeColor,
                stops: stopsAbleToBeRendered,
                midpoint: undefined,
                lastUpdated: new Date(),
              };

              tripRenderInfos[tripInstId].routesRenderInfos.push(routeRenderInfo);
            }
          }
        }
      });

      _forOwn(tripRenderInfos, (tripRenderInfo, tripInstId) => {
        // for Dispatcher layout, we want to also render Pickups that are not part of Routes
        const tripStops: Stop[] = this.layoutPreferenceService.isDispatcherLayout()
          ? stopsForTrips[tripInstId] || []
          : [];

        // extract all stops into a flattened list
        let routeStops: RenderStop[] = _flatten(
          _map(tripRenderInfo.routesRenderInfos, (routeRenderInfo) => {
            return _map(routeRenderInfo.stops, (stop) => {
              return {
                stop: stop,
                route: routeRenderInfo.route,
              };
            });
          })
        );

        // add tripStops to the list of routeStops
        routeStops.push(
          ..._map(tripStops, (stop) => {
            return {
              stop: stop,
              route: undefined,
            };
          })
        );

        // remove stops that can not be rendered (no location or not sequenced)
        routeStops = _filter(routeStops, (routeStop) => this.tripRenderingHelper.isAbleToRender(routeStop.stop));

        // sort the route stops, keeping stops for the same route together.  Otherwise the legs won't render correctly
        routeStops.sort((a, b) =>
          a?.route?.routeInstId === b?.route?.routeInstId
            ? (a?.stop?.tripNode?.stopSequenceNbr ?? 0) - (b?.stop?.tripNode?.stopSequenceNbr ?? 0)
            : 0
        );

        // add service center as the first and last stop
        const sic: Stop = {
          ...new Stop(),
          tripNode: {
            ...new TripNode(),
            nodeTypeCd: NodeTypeCd.SERVICE_CENTER,
            cmsEstimatedChargeAmount: 0,
            cmsInvoicedChargeAmount: 0,
          },
          customer: {
            ...new InterfaceAcct(),
            latitudeNbr: sicLocation.latitude,
            longitudeNbr: sicLocation.longitude,
          },
        };

        routeStops.unshift({ stop: sic, route: undefined });
        routeStops.push({ stop: sic, route: undefined });

        let driverName: string;

        if (this.layoutPreferenceService.isDispatcherLayout()) {
          const dispatcherTrips: DispatchTrip[] = this.dispatcherTripsService.trips;
          const targetTrip = _find(dispatcherTrips, (trip) => trip.tripInstId === +tripInstId);
          driverName = targetTrip?.dispatchDriver?.dsrName || '';
        } else {
          const selectedTrips = this.pndStore$.selectSnapshot(TripsStoreSelectors.selectedTrips);
          const targetTrip = _find(selectedTrips, (trip) => trip.route.tripInstId === +tripInstId);
          driverName = targetTrip?.tripDsrName || '';
        }

        // Take from cache when possible
        const cachedTripRenderInfo: TripRenderInfo = this.cachedTripRenderInfos[+tripInstId];
        if (
          cachedTripRenderInfo &&
          !this.tripRenderingHelper.isOverdue(
            cachedTripRenderInfo.lastUpdated,
            TripRenderingService.maxCachingThreshold
          ) &&
          this.tripRenderingHelper.areStopsEqual(cachedTripRenderInfo.stops, routeStops)
        ) {
          tripRenderInfo.projectedLegs = cachedTripRenderInfo.projectedLegs;
          tripRenderInfo.breadcrumbsLegs = cachedTripRenderInfo.breadcrumbsLegs;
          tripRenderInfo.isShowingBreadcrumbs = cachedTripRenderInfo.isShowingBreadcrumbs;
        }

        tripRenderInfo.stops = routeStops;
        tripRenderInfo.driverName = driverName;
      });

      // Generate the polylines connecting the stops
      if (hasRenderableStops) {
        const processLegsObservers: Observable<void>[] = [];

        _forOwn(tripRenderInfos, (tripRenderInfo, tripInstId) => {
          processLegsObservers.push(
            this.processLegs(+tripInstId, tripRenderInfo).pipe(take(1), takeUntil(this.updateRoutesUnsubscriber.done$))
          );
        });

        zip(...processLegsObservers)
          .pipe(take(1), takeUntil(this.updateRoutesUnsubscriber.done$))
          .subscribe(() => {
            _forOwn(this.cachedTripRenderInfos, (tripRenderInfo) => {
              this.polylinesService.clearTripPolylines(tripRenderInfo);
            });

            this.cachedTripRenderInfos = { ...tripRenderInfos };
            this.driverDirectionsNotifier.next([...this.routesWithoutDriverDirections]);

            this.updateRoutesUnsubscriber.complete();

            observer.next(this.cachedTripRenderInfos);
            observer.complete();
          });
      } else {
        _forOwn(this.cachedTripRenderInfos, (tripRenderInfo) => {
          this.polylinesService.clearTripPolylines(tripRenderInfo);
        });

        this.cachedTripRenderInfos = {};
        this.driverDirectionsNotifier.next([]);

        this.updateRoutesUnsubscriber.complete();
        observer.next({});
        observer.complete();
      }
    });
  }

  //#region Breadcrumbs visibility

  isShowingBreadcrumbs(tripInstId: number): boolean {
    let isShowingBreadcrumbs = false;
    const tripRenderInfo = this.cachedTripRenderInfos[tripInstId];

    if (tripRenderInfo) {
      isShowingBreadcrumbs = tripRenderInfo.isShowingBreadcrumbs;
    }

    return isShowingBreadcrumbs;
  }

  showBreadcrumbs(tripInstId: number): void {
    this.driverDirectionsNotifier.next(undefined);

    const tripRenderInfo = this.cachedTripRenderInfos[tripInstId];

    if (tripRenderInfo) {
      if (this.showBreadcrumbsUnsubscriber) {
        this.showBreadcrumbsUnsubscriber.complete();
      }

      this.showBreadcrumbsUnsubscriber = new Unsubscriber();

      if (
        _size(tripRenderInfo.breadcrumbsLegs) === 0 ||
        this.tripRenderingHelper.isOverdue(tripRenderInfo.lastUpdated, TripRenderingService.maxCachingThreshold)
      ) {
        this.tripDriverLocationsService
          .getDriverBreadcrumbs$(+tripInstId)
          .pipe(
            take(1),
            takeUntil(this.showBreadcrumbsUnsubscriber.done$),
            catchError(() => {
              return of(undefined);
            }),
            switchMap((response: GetPnDTripDsrLocationsResp) => {
              const dsrLocations: DsrLocation[] = response?.dsrLocations || [];
              if (_size(dsrLocations) === 0) {
                this.notificationMessageService
                  .openNotificationMessage(
                    NotificationMessageStatus.Info,
                    'No driver breadcrumb data available for the trip.'
                  )
                  .subscribe(() => {});
              }

              return this.tripBreadcrumbsService.processBreadcrumbs(response, tripRenderInfo);
            }),
            filter((processSucceeded) => processSucceeded)
          )
          .subscribe(() => {
            this.processLegs(tripInstId, tripRenderInfo, false)
              .pipe(take(1), takeUntil(this.showBreadcrumbsUnsubscriber.done$))
              .subscribe(() => {
                this.polylinesService.clearTripPolylines(tripRenderInfo);

                this.showBreadcrumbsUnsubscriber.complete();
                tripRenderInfo.isShowingBreadcrumbs = true;

                const showCompletedStops = this.pndStore$.selectSnapshot(TripsStoreSelectors.showCompletedStops);
                this.updateAllVisibility(showCompletedStops, this.currentZoomLevel);

                this.driverDirectionsNotifier.next([...this.routesWithoutDriverDirections]);
              });
          });
      } else {
        this.polylinesService.clearTripPolylines(tripRenderInfo);

        this.showBreadcrumbsUnsubscriber.complete();
        tripRenderInfo.isShowingBreadcrumbs = true;

        const showCompletedStops = this.pndStore$.selectSnapshot(TripsStoreSelectors.showCompletedStops);
        this.updateAllVisibility(showCompletedStops, this.currentZoomLevel);

        this.driverDirectionsNotifier.next([...this.routesWithoutDriverDirections]);
      }
    }
  }

  hideBreadcrumbs(tripInstId: number): void {
    const tripRenderInfo = this.cachedTripRenderInfos[tripInstId];

    if (tripRenderInfo) {
      this.polylinesService.clearTripPolylines(tripRenderInfo);
      tripRenderInfo.isShowingBreadcrumbs = false;

      const showCompletedStops = this.pndStore$.selectSnapshot(TripsStoreSelectors.showCompletedStops);
      this.updateVisibility(tripInstId, tripRenderInfo, showCompletedStops, this.currentZoomLevel);
    }
  }

  //#endregion

  //#region Events

  /**
   * Set the blur state of a trip/route. A blurred polylines is 50% transparent.
   * @param renderType
   * @param id tripInstId or routeInstId
   * @private
   */
  private blur(renderType: RenderType, id: number | undefined): RoutesInfoWindowData {
    let info: RoutesInfoWindowData;

    if (id) {
      let tripInfo: TripRenderInfo;
      let routeInfos: RouteRenderInfo[] = [];
      let routeNames: string[] = [];
      let routeColor: string;

      if (renderType === RenderType.trip) {
        tripInfo = this.cachedTripRenderInfos[id];
        routeInfos = _sortBy(
          tripInfo?.routesRenderInfos,
          (routeInfo: RouteRenderInfo) => routeInfo?.stops?.[0]?.tripNode?.stopSequenceNbr
        );

        routeColor = this.routeColorService.getColorForTrip(id);
        routeNames = routeInfos.map((routeInfo) => routeInfo.routeName);

        const dispatcherTrips: DispatchTrip[] = this.dispatcherTripsService.trips;
        const dispatchTrip: DispatchTrip = dispatcherTrips.find((trip: DispatchTrip) => trip.tripInstId === id);

        if (dispatchTrip) {
          const dispatchTripRouteNames: string[] = dispatchTrip.dispatchRoutes.map((dispatchRoute: DispatchRoute) =>
            PndRouteUtils.getRouteId({
              routePrefix: dispatchRoute.routePrefix,
              routeSuffix: dispatchRoute.routeSuffix,
            })
          );

          // Tooltip order: rendered routes ordered by seq nbr, empty routes ordered alphabetically
          routeNames.push(...dispatchTripRouteNames.filter((name) => !routeNames.includes(name)).sort());
        }
      } else {
        _forOwn(this.cachedTripRenderInfos, (tripRenderInfo: TripRenderInfo) => {
          for (let i = 0; i < _size(tripRenderInfo.routesRenderInfos); i++) {
            const routeRenderInfo: RouteRenderInfo = tripRenderInfo.routesRenderInfos[i];

            if (routeRenderInfo.route.routeInstId === id) {
              tripInfo = tripRenderInfo;
              routeInfos = [routeRenderInfo];

              break;
            }
          }
        });

        routeNames = routeInfos.map((routeInfo) => routeInfo.routeName);
        routeColor = this.routeColorService.getColorForRoute(id);
      }

      if (_size(routeNames) > 0 && tripInfo) {
        let legs: Leg[];

        if (renderType === RenderType.trip) {
          legs = tripInfo.isShowingBreadcrumbs ? tripInfo.breadcrumbsLegs : tripInfo.projectedLegs;
        } else {
          legs = _filter(
            tripInfo.isShowingBreadcrumbs ? tripInfo.breadcrumbsLegs : tripInfo.projectedLegs,
            (leg: Leg) => !leg.isConnector && leg.route.routeInstId === id
          );
        }

        info = {
          routeNames: routeNames,
          color: routeColor,
          midpoint: this.tripRenderingHelper.calculateRouteMidpoint(legs),
        };

        _forEach(legs, (leg: Leg) => {
          const legRenderType: LegRenderType = leg.isSicConnector ? LegRenderType.dashed : LegRenderType.solid;
          const opacity: number = leg.hasCompletedStops ? COMPLETED_BLUR_OPACITY : INCOMPLETED_BLUR_OPACITY;

          const options: google.maps.PolygonOptions = this.polylinesService.polylineOptions(
            leg.color,
            legRenderType,
            opacity,
            this.currentZoomLevel,
            !leg.hasCompletedStops,
            leg.isBreadcrumb,
            leg.connectionLost
          );

          leg.polyline.setOptions(options);
        });
      }
    } else {
      // For each leg: set back to its original polyline options
      _forOwn(this.cachedTripRenderInfos, (tripRenderInfo: TripRenderInfo) => {
        _forEach(tripRenderInfo.projectedLegs, (leg: Leg) => {
          if (leg.polyline) {
            leg.polyline.setOptions(leg.originalPolylineOptions);
          }
        });

        _forEach(tripRenderInfo.breadcrumbsLegs, (leg: Leg) => {
          if (leg.polyline) {
            leg.polyline.setOptions(leg.originalPolylineOptions);
          }
        });
      });
    }

    return info;
  }

  /**
   * Set the blur state of the trip polylines.
   * @param tripInstId
   */
  blurTrip(tripInstId: number | undefined): RoutesInfoWindowData {
    return this.blur(RenderType.trip, tripInstId);
  }

  /**
   * Set the blur state of the route polylines.
   * @param routeInstId
   */
  blurRoute(routeInstId: number | undefined): RoutesInfoWindowData {
    return this.blur(RenderType.route, routeInstId);
  }

  //#endregion
}
