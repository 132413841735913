import { GeocoderLocationType } from '@agm/core';
import { Injectable } from '@angular/core';
import { PndStoreState, RoutesStoreSelectors, RoutesStoreActions } from '@pnd-store/index';
import {
  CityOperationsApiService,
  Stop,
  UnassignedStop,
  UpdateCustomerGeoCoordinatesPath,
  UpdateCustomerGeoCoordinatesRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { LatLong, GeoCoordinates } from '@xpo-ltl/sdk-common';
import { find as _find, first as _first, forEach as _forEach, startCase as _startCase } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { NotificationMessageStatus } from '../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../core/services/notification-message.service';
import { NumberToValueMap } from './../../../store/number-to-value-map';
import { PndStore } from './../../../store/pnd-store';
import { UnassignedPickupsSummary } from './../../components/unassigned-pickups/models/unassigned-pickup-summary';
import { UnassignedDeliveriesCacheService } from './unassigned-deliveries-cache.service';
import { UnassignedPickupsService } from './unassigned-pickups.service';

export enum GeocodeCustomLocationTypes {
  NON_GEOCODED = 'NON_GEOCODED',
}

export type GeocodeResultLocationType = google.maps.GeocoderLocationType | GeocodeCustomLocationTypes;

export interface GeocodeResult {
  location: google.maps.LatLng;
  locationType: GeocodeResultLocationType;
  address: string;
}

export interface AddressUpdated {
  customerId: number;
  geoCoordinate: LatLong;
}

export type GeocodeRequest = google.maps.GeocoderRequest;

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  private addressUpdatedSubject = new BehaviorSubject<AddressUpdated>(undefined);
  readonly addressUpdated$ = this.addressUpdatedSubject.asObservable();

  private cachedResolvedCoordinatesSubject = new BehaviorSubject<NumberToValueMap<GeoCoordinates>>({});
  readonly cachedResolvedCoordinates = this.cachedResolvedCoordinatesSubject.asObservable();

  get address() {
    return this.addressUpdatedSubject.value;
  }

  get cachedResolvedCoordinatesValue() {
    return this.cachedResolvedCoordinatesSubject.value;
  }

  private _geocoder: google.maps.Geocoder;
  get geocoder() {
    if (!this._geocoder) {
      this._geocoder = new google.maps.Geocoder();
    }
    return this._geocoder;
  }

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private notificationMessageService: NotificationMessageService,
    private unassignedDeliveriesCacheService: UnassignedDeliveriesCacheService,
    private unassignedPickupsService: UnassignedPickupsService,
    private pndStore$: PndStore<PndStoreState.State>
  ) {}

  firstOfLocationType(results: GeocodeResult[], locType: GeocoderLocationType): GeocodeResult {
    return _find(results, (result) => result && result.locationType === locType);
  }

  /**
   * Attempt to geocode the passed address data, returning the location if successful
   */
  geocodeAddress(request: GeocodeRequest): Observable<GeocodeResult> {
    return new Observable((observer) => {
      this.geocoder.geocode(request, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          // geocoding found an address
          const res = _first(results);

          const geoResult = {
            location: res.geometry.location,
            locationType: res.geometry.location_type,
            address: res?.formatted_address ?? '',
          };

          observer.next(geoResult);
          observer.complete();
        } else {
          // failed to find address
          observer.error(_startCase(status));
          observer.complete();
        }
      });
    });
  }

  geocodeAddresses(requests: GeocodeRequest[]): Observable<GeocodeResult[]> {
    const observers: Observable<GeocodeResult>[] = [];
    _forEach(requests, (request) => {
      observers.push(
        this.geocodeAddress(request).pipe(
          take(1),
          catchError((error) => of(null))
        )
      );
    });
    return forkJoin(observers);
  }

  /**
   * Update the specified customer's GeoLocation
   * Returns observable that provides the acctInstId when update successful
   */
  updateCustomerGeoLocation(customerId: number, geoCoordinate: LatLong): Observable<number> {
    const request: UpdateCustomerGeoCoordinatesRqst = {
      ...new UpdateCustomerGeoCoordinatesRqst(),
      geoCoordinate,
    };
    const path: UpdateCustomerGeoCoordinatesPath = {
      ...new UpdateCustomerGeoCoordinatesPath(),
      customerAcctId: customerId,
    };

    return this.cityOperationsService.updateCustomerGeoCoordinates(request, path).pipe(
      take(1),
      switchMap(() => {
        // remove the customer from the list of unmapped deliveries
        this.unassignedDeliveriesCacheService.updateUnmappedDeliveries(
          this.unassignedDeliveriesCacheService.unmappedDeliveries.filter(
            (stop) => stop?.consignee?.acctInstId !== customerId
          )
        );

        // remove the customer from the list of unmapped pickups
        this.unassignedPickupsService.updateUnmappedPickups(
          this.unassignedPickupsService.unmappedPickups.filter(
            (stop) => stop?.pickupHeader?.shipper?.acctInstId !== customerId
          )
        );

        // update the Unassigned DL witn new geo location
        this.unassignedDeliveriesCacheService.updateUnassignedDeliveries(
          this.getUpdatedUnassignedDL(
            this.unassignedDeliveriesCacheService.unassignedDeliveries,
            customerId,
            geoCoordinate
          )
        );

        // update the Unassigned PU with new geo location
        this.unassignedPickupsService.updateUnassignedPickups(
          this.getUpdatedUnassignedPU(this.unassignedPickupsService.unassignedPickups, customerId, geoCoordinate)
        );

        // update selected planning routes
        this.updateSelectedPlanningRoute(customerId, geoCoordinate);

        return of(customerId);
      }),
      catchError((error) => {
        // display error message to user
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});

        return throwError(error);
      })
    );
  }

  private updateSelectedPlanningRoute(customerId: number, geoCoordinate: LatLong) {
    const selectedStops = this.pndStore$.selectSnapshot(RoutesStoreSelectors.stopsForSelectedPlanningRoutes);
    selectedStops?.forEach((obj) => {
      obj?.stops?.forEach((unassignedStop) => {
        if (unassignedStop?.consignee?.acctInstId === customerId) {
          unassignedStop.consignee.geoCoordinatesGeo = {
            lat: geoCoordinate?.latitude,
            lon: geoCoordinate?.longitude,
          };
        }
      });
    });

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetStopsForSelectedPlanningRoutes({
        stopsForSelectedPlanningRoutes: selectedStops,
      })
    );
  }

  getUpdatedUnassignedPU(
    unassignedPickups: UnassignedPickupsSummary[],
    customerId: number,
    geoCoordinate: LatLong
  ): UnassignedPickupsSummary[] {
    unassignedPickups.forEach((summary) => {
      if (summary?.pickupHeader?.shipper?.acctInstId === customerId) {
        summary.pickupHeader.shipper.geoCoordinatesGeo = {
          lat: geoCoordinate?.latitude,
          lon: geoCoordinate?.longitude,
        };
      }
    });
    return unassignedPickups;
  }

  getUpdatedUnassignedDL(
    unassignedDeliveries: UnassignedStop[],
    customerId: number,
    geoCoordinate: LatLong
  ): UnassignedStop[] {
    unassignedDeliveries?.forEach((stop) => {
      if (customerId === stop?.consignee?.acctInstId) {
        stop.consignee.geoCoordinatesGeo = {
          lat: geoCoordinate?.latitude,
          lon: geoCoordinate?.longitude,
        };
      }
    });

    return unassignedDeliveries;
  }

  setAddressUpdatedSubject(customerId: number, geoCoordinate: { latitude: number; longitude: number }): void {
    if (customerId) {
      this.addressUpdatedSubject.next({
        customerId,
        geoCoordinate,
      });
    } else {
      this.addressUpdatedSubject.next(undefined);
    }
  }

  getMapForSelectedDLorPU(
    address: AddressUpdated,
    selectedDLStops: NumberToValueMap<Stop[]>
  ): NumberToValueMap<Stop[]> {
    const selectedRouteInstIds: string[] = Object.keys(selectedDLStops);
    selectedRouteInstIds?.forEach((routeInstId) => {
      const stopsForRoute: Stop[] = selectedDLStops[routeInstId];
      stopsForRoute?.forEach((stop) => {
        if (address?.customerId === stop?.customer?.acctInstId) {
          stop.customer.geoCoordinatesGeo = {
            lat: address?.geoCoordinate?.latitude,
            lon: address?.geoCoordinate?.longitude,
          };
        }
      });
      selectedDLStops[routeInstId] = stopsForRoute;
    });

    return selectedDLStops;
  }

  updateResolvedCoordsCache(coords: NumberToValueMap<GeoCoordinates>): void {
    this.cachedResolvedCoordinatesSubject.next(coords);
  }
}
