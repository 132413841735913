import { Injectable, OnDestroy } from '@angular/core';
import { TripsSearchCriteria } from '@pnd-store/trips-store/trips-search-criteria.interface';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoLtlDriverContact, XpoLtlMessagingService } from '@xpo-ltl/ngx-ltl-messaging';
import {
  CityOperationsApiService,
  ListTripDriversPath,
  ListTripDriversQuery,
  ListTripDriversResp,
} from '@xpo-ltl/sdk-cityoperations';
import { TripStatusCd, ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, map, shareReplay, skip, switchMap, takeUntil } from 'rxjs/operators';

/**
 * Manage list of Driver contacts for this SIC messaging
 */
@Injectable({
  providedIn: 'root',
})
export class DriverContactsService implements OnDestroy {
  private unsubscriber = new Unsubscriber();

  private readonly driverContactsSubject = new BehaviorSubject<XpoLtlDriverContact[]>([]);
  readonly driverContacts$ = this.driverContactsSubject.asObservable();

  private readonly period = 60000;

  private readonly loadDriversSubject = new BehaviorSubject<TripsSearchCriteria>({});
  readonly loadDrivers$ = this.loadDriversSubject.asObservable().pipe(skip(1)); // skip the first value of the BehaviorSubject

  constructor(
    private cityOperationsApiService: CityOperationsApiService,
    private messagingService: XpoLtlMessagingService
  ) {
    const driverContactPolling$ = this.createDriverContactsPolling(
      this.loadDrivers$,
      this.createListDriversObs$,
      this.period
    );

    driverContactPolling$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((contacts: XpoLtlDriverContact[]) => {
      this.messagingService.updateContactsList(contacts);
      this.driverContactsSubject.next(contacts); // we have to live with this BehaviorSubject because the getContact function is using it in an imperative way. :(
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private createDriverContactsPolling(
    outer$: Observable<TripsSearchCriteria>,
    inner$: (a: TripsSearchCriteria) => Observable<XpoLtlDriverContact[]>,
    period: number
  ): Observable<XpoLtlDriverContact[]> {
    return outer$.pipe(
      switchMap((criteria: TripsSearchCriteria) => {
        return timer(0, period).pipe(
          switchMap((_) => {
            return inner$(criteria);
          })
        );
      }),
      shareReplay(1)
    );
  }

  private createListDriversObs$ = (criteria: TripsSearchCriteria): Observable<XpoLtlDriverContact[]> => {
    const tripDriversPath = { sicCd: criteria.sicCd } as ListTripDriversPath;
    const tripDriversQuery = {
      tripDate: criteria.tripDate,
      zoneIndicatorCd: ZoneIndicatorCd.INCLUDE_ZONES,
    } as ListTripDriversQuery;
    return this.cityOperationsApiService.listTripDrivers(tripDriversPath, tripDriversQuery).pipe(
      map((drivers: ListTripDriversResp) => this.fromDriversToContacts(drivers, criteria.sicCd)),
      catchError((err) => of([]))
    );
  };

  private fromDriversToContacts(drivers: ListTripDriversResp, sicCd: string): XpoLtlDriverContact[] {
    // TODO: Expose this method from the messaging service and use that instead. (Hotfix for PCT-15144)
    const isConversationActive = (tripStatusCd: TripStatusCd): boolean => {
      return (
        // only allow new messages for Dispatched or Returning trips
        tripStatusCd === TripStatusCd.DISPATCHED || tripStatusCd === TripStatusCd.RETURNING
      );
    };
    const allContacts: XpoLtlDriverContact[] =
      drivers?.driverDetails?.map((dd) => {
        return {
          dsrEmplId: dd.dsrEmployeeId,
          driverName: dd.dsrName,
          deviceId: dd.deviceId,
          tripInstId: dd.tripInstId,
          activeTripStatusCd: dd.activeTripStatusCd,
          trmnlSicCd: sicCd,
          conversationId: dd.beaconConversation?.conversationId,
          unreadMessages: dd.beaconConversation?.unreadMessages,
          lastMessageTxt: dd.beaconConversation?.lastMessageTxt,
        };
      }) ?? [];

    // Send only active conversations to the messaging service so that the filter can be populated
    // by active recipients (PCT-15144)
    const uniqContacts = new Map<string, XpoLtlDriverContact>();
    for (const contact of allContacts) {
      if (!uniqContacts.has(contact.dsrEmplId) || isConversationActive(contact.activeTripStatusCd)) {
        uniqContacts.set(contact.dsrEmplId, contact);
      }
    }

    const availableContacts: XpoLtlDriverContact[] = Array.from(uniqContacts.values());
    return availableContacts;
  }

  /**
   * Refresh the known contacts in the MessagingService with the list of Trip
   * drivers at the SIC
   */
  refreshContacts(criteria: TripsSearchCriteria) {
    this.loadDriversSubject.next(criteria);
  }

  /**
   * Returns the contact info for the driver with the passed employeeId, or undefined
   * if not found.
   * @param dsrEmplId of the Driver to find
   * @param tripInstId (optional) the Driver is associated with
   * @deprecated use the driverContacts$ observable instead!
   */
  getContact(dsrEmplId: string, tripInstId?: number): XpoLtlDriverContact {
    return this.driverContactsSubject.value.find((driver) => {
      const isMatchingTripInstId: boolean = tripInstId ? tripInstId === driver.tripInstId : true;
      return driver.dsrEmplId === dsrEmplId && isMatchingTripInstId;
    });
  }
}
