import { Action } from '@ngrx/store';
import { DispatcherTripsSearchCriteria } from './dispatcher-trips-search-criteria.interface';
import { WatchedDriver } from './dispatcher-trips-store.state';

export enum ActionTypes {
  setSearchCriteria = 'dispatcherTrips.setSearchCriteria',
  setLastUpdate = 'dispatcherTrips.setLastUpdate',
  setSelected = 'dispatcherTrips.setSelected',
  refresh = 'dispatcherTrips.refresh',
  setWatched = 'dispatcherTrips.setWatched',

  setGridScrollPosition = 'dispatcherTrips.setGridScrollPosition',
}

export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: DispatcherTripsSearchCriteria }) {}
}

export class SetSelected implements Action {
  readonly type = ActionTypes.setSelected;
  constructor(public payload: { selectedDispatcherTripsIds: number[] }) {}
}

export class SetWatched implements Action {
  readonly type = ActionTypes.setWatched;
  constructor(public payload: { watchedDrivers: WatchedDriver[] }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { dispatcherTripsLastUpdate: Date }) {}
}

export class SetGridScrollPosition implements Action {
  readonly type = ActionTypes.setGridScrollPosition;
  constructor(public payload: { position: number }) {}
}

export type Actions = SetSearchCriteria | SetLastUpdate | SetSelected | Refresh | SetGridScrollPosition | SetWatched;
