import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { GoogleMapsConfig } from './services';

@NgModule({
  imports: [ConfigManagerModule, AgmCoreModule.forRoot(), AgmSnazzyInfoWindowModule],
  providers: [
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json' } },
    { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}
