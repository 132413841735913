import { Action } from '@ngrx/store';
import { GetGeoAreasForLocationResp } from '@xpo-ltl-2.0/sdk-location';

export enum ActionTypes {
  setGeoAreas = 'geoArea.setGeoAreas',
  setSicBoundaries = 'geoArea.setSicBoundaries',
}

export class SetGeoAreaGeoAreasAction implements Action {
  readonly type = ActionTypes.setGeoAreas;
  constructor(public payload: { geoAreas: GetGeoAreasForLocationResp }) {}
}

export class SetGeoAreaSicBoundariesAction implements Action {
  readonly type = ActionTypes.setSicBoundaries;
  constructor(public payload: { sicBoundaries: GetGeoAreasForLocationResp }) {}
}

export type Actions = SetGeoAreaGeoAreasAction | SetGeoAreaSicBoundariesAction;
