<div
    (click)="onClickFunction($event)"
    [ngClass]="{
    'pnd-PickupCallNbrCellRenderer-link': isLink,
    'pnd-PickupCallNbrCellRenderer': !isLink
  }"
    *ngIf="callNbr$ | async as callNbr">
  <span>{{ callNbr }}</span>
  <xpo-status-indicator
      *ngIf="isNew$ | async"
      statusText="NEW"
      statusColor="green"
      isLabel="true"></xpo-status-indicator>
</div>
