<button
    mat-stroked-button
    [disabled]="disabled$ | async"
    class="pnd-PolygonSelection"
    (click)="togglePolygonDraw()"
    [ngClass]="{ 'pnd-PolygonSelection--checked': inDrawMode }">
  <div class="icon">
    <mat-icon svgIcon="draw-geo-filter" class="xpo-Icon"></mat-icon>
  </div>
  <div class="desc">
    Geo Filter
  </div>
</button>
