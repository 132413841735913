export enum QualifierCodes {
  FINAL = 'Z',
  FINAL_WITH_EXCEPTION = 'ZE',
  PARTIAL_SHORT = 'K',
  REFUSED = 'L',
  DAMAGED = 'M',
  RETURNED = 'R',
  ATTEMPTED = 'H',
  ALL_SHORT = 'J',
  CRTG = 'CRTG',
  UNDL = 'UNDL',
}

export enum FinalWithExceptionSubQualifierCodes {
  ACCEPTED = 'A',
  ACCEPTED_DAMAGED = 'AD',
  REFUSED = 'REF',
  REFUSED_DAMAGED = 'RD',
  SHORT = 'S',
  RETURN = 'RET',
  ATTEMPTED = 'ATT',
  FINAL = 'Z',
  UNDL = 'UNDL',
}
