export enum ModifyTripDetailsShipmentsGridFields {
  // Shared fields are defined in ModifyTripDetailsSharedGridFields

  PRO = 'proNbr',
  ORIGIN_SIC = 'originSic',
  SERVICE_DATE = 'serviceDate',
  LOADED = 'loadedInd',
  DESTINATION_ETA = 'destSicEta',
  CURRENT_LOCATION = 'currentLocation',
  HANDLING_UNITS = 'handlingUnits',
}
