import { ProFormatterPipe } from '@xpo-ltl/ngx-ltl';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { SpecialServicesService, TotalTextCellRendererParams } from '../../../../../shared';
import { DockRoutesGridFields } from '../../enums/dock-routes-grid-fields.enum';
import { DockRoutesColumnDefinitions } from '../../models/dock-routes-column-definitions';
import { DockRoutesActivityGridItem } from '../../models/dock-routes-grid-item.model';

export class DockRoutesDetailGirdColumnDefinition {
  private static emptySpaceExpandColDef: ColDef = {
    colId: 'emptySpaceExpandedCol',
    headerName: '',
    headerValueGetter: () => '',
    valueGetter: (params) => '',
    width: 50,
    headerClass: 'pnd-DockRoutesGridRenderer__empty-space-header pnd-first-col',
    cellClass: 'pnd-DockRoutesGridRenderer__empty-space-cell pnd-first-col',
    sortable: false,
    resizable: false,
    lockPosition: true,
    pinned: 'left',
  };

  static pickupLineItemsColDef = (
    specialServicesService: SpecialServicesService,
    proFormatterPipe: ProFormatterPipe,
    onProNbrClicked: (gridItem: DockRoutesActivityGridItem) => void,
    onCallNbrClicked: (gridItem: DockRoutesActivityGridItem) => void
  ): ColDef[] => {
    return [
      DockRoutesDetailGirdColumnDefinition.emptySpaceExpandColDef,
      {
        ...DockRoutesColumnDefinitions[DockRoutesGridFields.ROW_SELECTED],
        headerCheckboxSelection: false,
        width: 50,
        cellRendererSelector: (params: ICellRendererParams) =>
          DockRoutesColumnDefinitions[DockRoutesGridFields.ROW_SELECTED].cellRendererSelector({
            ...params,
            suppressUppercaseTotalsText: true,
          } as TotalTextCellRendererParams),
      },
      DockRoutesColumnDefinitions[DockRoutesGridFields.CALL_NBR](onCallNbrClicked),
      DockRoutesColumnDefinitions[DockRoutesGridFields.PRO](proFormatterPipe, onProNbrClicked),
      DockRoutesColumnDefinitions[DockRoutesGridFields.DEST_SIC],
      DockRoutesColumnDefinitions[DockRoutesGridFields.DEST_ZIP],
      DockRoutesColumnDefinitions[DockRoutesGridFields.PALLET_COUNT],
      DockRoutesColumnDefinitions[DockRoutesGridFields.PIECES_COUNT],
      DockRoutesColumnDefinitions[DockRoutesGridFields.WEIGHT_LBS],
      DockRoutesColumnDefinitions[DockRoutesGridFields.MOTOR_MOVE_NBR],
      DockRoutesColumnDefinitions[DockRoutesGridFields.CUBE_NBR],
      DockRoutesColumnDefinitions[DockRoutesGridFields.SPECIAL_SERVICES](specialServicesService),
    ];
  };

  static deliveryLineItemsColDef = (
    specialServicesService: SpecialServicesService,
    proFormatterPipe: ProFormatterPipe,
    onProNbrClicked: (gridItem: DockRoutesActivityGridItem) => void
  ): ColDef[] => {
    return [
      DockRoutesDetailGirdColumnDefinition.emptySpaceExpandColDef,
      {
        ...DockRoutesColumnDefinitions[DockRoutesGridFields.ROW_SELECTED],
        headerCheckboxSelection: false,
        width: 50,
        cellRendererSelector: (params: ICellRendererParams) =>
          DockRoutesColumnDefinitions[DockRoutesGridFields.ROW_SELECTED].cellRendererSelector({
            ...params,
            suppressUppercaseTotalsText: true,
          } as TotalTextCellRendererParams),
      },
      DockRoutesColumnDefinitions[DockRoutesGridFields.PRO](proFormatterPipe, onProNbrClicked),
      ,
      DockRoutesColumnDefinitions[DockRoutesGridFields.ORIGIN_SIC],
      DockRoutesColumnDefinitions[DockRoutesGridFields.PALLET_COUNT],
      DockRoutesColumnDefinitions[DockRoutesGridFields.PIECES_COUNT],
      DockRoutesColumnDefinitions[DockRoutesGridFields.WEIGHT_LBS],
      DockRoutesColumnDefinitions[DockRoutesGridFields.MOTOR_MOVE_NBR],
      DockRoutesColumnDefinitions[DockRoutesGridFields.CUBE_NBR],
      DockRoutesColumnDefinitions[DockRoutesGridFields.SPECIAL_SERVICES](specialServicesService),
    ];
  };
}
