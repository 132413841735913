<div class="xpo-LtlLayoutPreferencesSelector">
  <mat-form-field class="ngx-FormField--inline">
    <mat-select
      #selector
      xpoSelect
      panelClass="xpo-LtlLayoutPreferencesSelector__panel"
      [placeholder]="placeholder"
      [value]="activeLayout$ | async"
      (selectionChange)="handleSelectionChange($event)"
      [compareWith]="compareLayoutFn"
      [disabled]="!(activeLayout$ | async) || disabled"
      data-test="xpo-LtlLayoutPreferencesSelector"
    >
      <mat-select-trigger>
        {{ (activeLayout$ | async)?.name }}
      </mat-select-trigger>

      <mat-option
        *ngFor="let item of availableLayouts$ | async; trackBy: trackItemBy; let idx = index"
        [value]="item"
        attr.data-test="xpo-LtlLayoutPreferencesSelector-option-{{ idx }}"
      >
        <div class="xpo-LtlLayoutPreferencesSelector__option">
          <span class="xpo-LtlLayoutPreferencesSelector__layout-name">{{ item.name }}</span>
          <button
            class="xpo-LtlLayoutPreferencesSelector__delete-button"
            mat-icon-button
            [disabled]="item.default"
            (click)="deleteLayout(item.name)"
            matTooltip="Delete"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-option>

      <span class="xpo-LtlLayoutPreferencesSelector__option">
        <button
          mat-flat-button
          xpoSmallButton
          class="xpo-LtlLayoutPreferencesSelector__save-button"
          (click)="saveLayout()"
        >
          Save Layout...
        </button>
      </span>
    </mat-select>
  </mat-form-field>
</div>
