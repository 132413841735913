<div
    *ngIf="tooltip"
    [matTooltip]="tooltip"
    matTooltipPosition="above"
    class="pickup-close-time-cell"
    style="display: flex">
  {{ content }}
</div>

<div *ngIf="!tooltip" class="pickup-close-time-cell" style="display: flex">
  {{ content }}
</div>
