import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeLeadingZeros' })
export class RemoveLeadingZerosPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || isNaN(+value)) {
      return '';
    }

    return (+value).toString();
  }
}
