import { Injectable } from '@angular/core';
import { PANEL_MAP_COLORS } from 'app/inbound-planning/shared/services/panel-map-colors';
import { BehaviorSubject } from 'rxjs';

export interface GeoAreaColorChangeEvent {
  id: number;
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class GeoAreaColorService {
  private assignedColors: Map<number, string>;
  private readonly colorChangedSubject = new BehaviorSubject<GeoAreaColorChangeEvent>(undefined);
  readonly colorChanged$ = this.colorChangedSubject.asObservable();

  constructor() {
    this.clear();
  }

  private getLessRepeatedColor(): string {
    const repetitionCounter: { color: string; times: number }[] = [];

    for (let i = 0; i < PANEL_MAP_COLORS.length; i++) {
      const targetColor = PANEL_MAP_COLORS[i];
      let count = 0;

      this.assignedColors.forEach((assignedColor: string) => {
        if (targetColor === assignedColor) {
          count++;
        }
      });

      repetitionCounter.push({ color: targetColor, times: count });
    }

    const lessRepeatedColor = repetitionCounter.sort((a, b) => a.times - b.times)[0] || { color: '#FFFFFF', times: 0 };
    return lessRepeatedColor.color;
  }

  private setColor(id: number): string {
    if (!this.assignedColors.has(id)) {
      const color = this.getUnassignedColors()[0] || this.getLessRepeatedColor();
      this.assignedColors.set(id, color);
      return color;
    } else {
      return this.assignedColors.get(id);
    }
  }

  private clearColor(id: number): void {
    const color = this.assignedColors.get(id);
    if (color) {
      this.assignedColors.delete(id);
    }
    this.colorChangedSubject.next(undefined);
  }

  private changeColor(id: number, color: string): void {
    this.assignedColors.set(id, color);
    this.colorChangedSubject.next({ id, color: color });
  }

  /**
   * Clear all assigned colors
   */
  clear(): void {
    this.assignedColors = new Map<number, string>();
  }

  getUnassignedColors(): string[] {
    let allColors = [...PANEL_MAP_COLORS];
    this.assignedColors.forEach((assignedColor: string) => {
      allColors = allColors.filter((color) => color !== assignedColor);
    });
    return allColors;
  }

  //#region GeoAreas

  /**
   * Returns color assigned to this geoArea.
   */
  getColorForGeoArea(geoAreaInstId: number): string {
    return this.setGeoAreaColor(geoAreaInstId);
  }

  /**
   * Returns color assigned to this geoArea. Assigns a new color if one has not already been assigned.
   */
  setGeoAreaColor(geoAreaInstId: number): string {
    return this.setColor(geoAreaInstId);
  }

  /**
   * Removes the association between a geoArea and a specific color
   */
  clearGeoAreaColor(geoAreaInstId: number): void {
    this.clearColor(geoAreaInstId);
  }

  changeGeoAreaColor(geoAreaInstId: number, color: string): void {
    this.changeColor(geoAreaInstId, color);
  }

  //#endregion
}
