import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface MatTooltipCellRendererParams extends ICellRendererParams {
  content: string;
  tooltip?: string | number;
}
@Component({
  selector: 'pnd-mat-tooltip-cell-renderer',
  templateUrl: './mat-tooltip-cell-renderer.component.html',
  styleUrls: ['./mat-tooltip-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatTooltipCellRendererComponent implements ICellRendererAngularComp {
  content: string;
  tooltip: string;

  constructor() {}

  refresh(params: any): boolean {
    this.content = params?.content;
    this.tooltip = params?.tooltip;
    return true;
  }

  agInit(params: any): void {
    this.refresh(params);
  }
}
