import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PndStore } from '@pnd-store/pnd-store';
import { RouteBalancingSelectors } from '@pnd-store/route-balancing-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoLtlShipmentDescriptor } from '@xpo-ltl/ngx-ltl-shipment-details';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { ComponentChangeUtils } from 'app/inbound-planning/shared/classes/component-change-utils';
import { clone as _clone, pick as _pick } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../../core/dialogs/pnd-dialog.service';
import { PndStoreState, UnassignedDeliveriesStoreActions } from '../../../../../../store';
import { GeoLocationStoreActions, GeoLocationStoreSelectors } from '../../../../../../store/geo-location-store';
import { AssignShipmentsSelectionSource } from '../../../../../components/assign-shipments/enums/assign-shipments-selection-source.enum';
import { AssignType } from '../../../../../components/assign-shipments/enums/assign-type.enum';
import { AssignShipmentsService } from '../../../../../components/assign-shipments/services/assign-shipments.service';
import { StoreSourcesEnum } from '../../../../enums/store-sources.enum';
import { SpecialServicesHelper } from '../../../../helpers/special-services/special-services.helper';
import { EventItem, UnassignedDeliveryIdentifier, UnassignedPickupIdentifier } from '../../../../interfaces';
import { TripNodeActivityExtendedCd } from '../../../../models/stop-type.model';
import { ActivityCdPipe } from '../../../../pipes/activity-cd.pipe';
import { AssignPickupsService } from '../../../../services/assign-pickups.service';
import { MapMarkersService } from '../../../../services/map-markers.service';
import { DELIVERY_BORDER, DELIVERY_COLOR, PICKUP_BORDER, PICKUP_COLOR } from '../../../../services/stop-colors';
import { UnmappedStopStatusCode } from '../../classes/unmapped-stop-status-code.enum';
import { UnmappedStopDetail } from './unmapped-stop-detail.model';

@Component({
  selector: 'pnd-unmapped-stop-detail',
  templateUrl: './unmapped-stop-detail.component.html',
  styleUrls: ['./unmapped-stop-detail.component.scss'],
  host: { class: 'pnd-UnmappedStopDetail' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnmappedStopDetailComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  @Input() stopDetail: UnmappedStopDetail;
  @Output() reassignedToRoute: EventEmitter<UnmappedStopDetail> = new EventEmitter(); // emitted when stop is assigned to a route

  readonly active$: Observable<boolean> = this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(
    map((stop) => {
      return !!stop && stop.acctInstId === this.stopDetail.acctInstId;
    }),
    takeUntil(this.unsubscriber.done$)
  );

  readonly disabled$: Observable<boolean> = this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(
    map((stop) => {
      return !!stop && stop.acctInstId !== this.stopDetail.acctInstId;
    }),
    takeUntil(this.unsubscriber.done$)
  );

  readonly status$ = this.pndStore$.select(GeoLocationStoreSelectors.status).pipe(takeUntil(this.unsubscriber.done$));

  readonly hasErrors$: Observable<boolean> = this.status$.pipe(
    withLatestFrom(this.active$),
    map(([status, active]) => {
      return (
        active &&
        status &&
        (status.code === UnmappedStopStatusCode.SEARCHING_ERROR || status.code === UnmappedStopStatusCode.SAVING_ERROR)
      );
    })
  );

  readonly isSearching$: Observable<boolean> = this.status$.pipe(
    withLatestFrom(this.active$),
    map(([status, active]) => {
      return active && status && status.code === UnmappedStopStatusCode.SEARCHING;
    })
  );

  readonly isSaving$: Observable<boolean> = this.status$.pipe(
    withLatestFrom(this.active$),
    map(([status, active]) => {
      return active && status && status.code === UnmappedStopStatusCode.SAVING;
    })
  );

  readonly TripNodeActivityCd = TripNodeActivityCd;

  readonly isRouteBalancingActive$: Observable<boolean>;

  stopSvg: SafeHtml;
  originalAddress: string;
  tooltip: string;

  constructor(
    private sanitizer: DomSanitizer,
    private activityCdPipe: ActivityCdPipe,
    private pndDialogService: PndDialogService,
    private pndStore$: PndStore<PndStoreState.State>,
    private mapMarkersService: MapMarkersService,
    private assignShipmentsService: AssignShipmentsService,
    private assignPickupsService: AssignPickupsService,
    private changeRef: ChangeDetectorRef
  ) {
    this.isRouteBalancingActive$ = this.pndStore$.select(RouteBalancingSelectors.openRouteBalancingPanel);
  }

  ngOnInit() {
    if (this.stopDetail) {
      this.originalAddress = _clone(this.stopDetail.address);
      this.stopSvg = this.buildMarkerHtml(this.stopDetail.stopTypeCd);
    }

    this.assignShipmentsService.assignCompleted$
      .pipe(
        filter((source) => source === AssignShipmentsSelectionSource.UnmappedDeliveries),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(() => {
        this.reassignedToRoute.emit(this.stopDetail);

        // After successful assign, the Assign Shipments panel should no longer hold this shipment for assignment
        this.assignShipmentsService.unmappedDeliveriesShipments = [];
        ComponentChangeUtils.detectChanges(this.changeRef);
      });

    this.assignPickupsService.assignCompleted$
      .pipe(
        takeUntil(this.unsubscriber.done$),
        filter((completed) => completed)
      )
      .subscribe(() => {
        this.assignPickupsService.setAssignCompletedFalse(); // prevents assign completed being stuck in true and causing this component to loop
        this.reassignedToRoute.emit(this.stopDetail);
        ComponentChangeUtils.detectChanges(this.changeRef);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();

    // After closing the unmapped split panel, the Assign Shipments panel should no longer hold this shipment for assignment
    this.assignShipmentsService.unmappedDeliveriesShipments = [];
  }

  toEventItem(sourceId: UnassignedDeliveryIdentifier): EventItem<UnassignedDeliveryIdentifier> {
    if (!sourceId) {
      return undefined;
    } else {
      return {
        id: {
          consignee: _pick(sourceId.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          shipmentInstId: sourceId.shipmentInstId,
        },
        source: StoreSourcesEnum.UNASSIGNED_DELIVERY_GRID,
      } as EventItem<UnassignedDeliveryIdentifier>;
    }
  }
  /**
   * Begin editing this stop location
   */
  beginEdit(forceGeocode: boolean = false) {
    // Need to trigger looking up the address again since the user may have edited it
    // get the address the user typed in
    const eventItems = this.stopDetail.shipmentDetails.map((detail) => {
      const sourceId = {
        consignee: detail.consignee,
        shipmentInstId: detail.shipmentInstId,
      };
      return this.toEventItem(sourceId);
    });

    this.pndStore$.dispatch(
      new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
        selectedDeliveries: [...eventItems],
      })
    );

    this.pndStore$.dispatch(
      new GeoLocationStoreActions.SetStopToEdit({
        ...this.stopDetail,
        // if forceGeocode, then clear existing location to force geocoding the address
        location: forceGeocode ? undefined : this.stopDetail.location,
      })
    );
  }

  /**
   * Show the shipment details dialog for this stop
   */
  showShipmentDetails(): void {
    const shipmentsDescriptor: XpoLtlShipmentDescriptor[] = [];
    this.stopDetail?.shipmentDetails?.forEach((shipmentDetail: DeliveryShipmentSearchRecord) => {
      shipmentsDescriptor.push({ proNbr: shipmentDetail.proNbr, shipmentInstId: shipmentDetail.shipmentInstId });
    });
    this.pndDialogService.showShipmentDetailsDialog(shipmentsDescriptor).subscribe();
  }

  /**
   * show Assign to Route split panel for this stop
   */
  assignDelivery(): void {
    this.assignShipmentsService.unmappedDeliveriesShipments = [...this.stopDetail.shipmentDetails];
    this.pndDialogService.openAssignShipmentsPanel({
      assignType: AssignType.Existing,
      fromUnmappedDeliveries: true,
      source: AssignShipmentsSelectionSource.UnmappedDeliveries,
    });
  }

  assignPickup(): void {
    this.assignPickupsService.unmappedPickupToBeAssignedSubject.next({
      id: {
        pickupInstId: this.stopDetail?.pickupDetails?.pickupRequestInstId,
        loosePiecesCount: this.stopDetail?.pickupDetails?.loosePiecesCount,
        motorMovesNbr: this.stopDetail?.pickupDetails?.motorMovesNbr,
        palletsCount: this.stopDetail?.pickupDetails?.palletsCount,
        weightLbs: this.stopDetail?.pickupDetails?.weightLbs,
        tripInstId: this.stopDetail.pickupDetails?.tripInstId,
        shipper: this.stopDetail?.pickupDetails?.shipper,
        specialServiceSummary: SpecialServicesHelper.getSpecialServicesForSummary(
          this.stopDetail?.pickupDetails?.specialServiceSummary
        ),
        pickupTypeCd: this.stopDetail?.pickupDetails?.pickupTypeCd,
      },
      source: StoreSourcesEnum.UnmappedPickupsPanel,
    } as EventItem<UnassignedPickupIdentifier>);

    this.assignPickupsService.openAssignPickupsDialog();
  }

  getTooltip(): string {
    const element = document.getElementById('assign_button');
    if (element) {
      element['disabled'] ? (this.tooltip = 'Cannot assign while Route Balancing is open') : (this.tooltip = undefined);
    }
    return this.tooltip;
  }

  /// Create the SVG representing this stop
  private buildMarkerHtml(stopTypeCd: TripNodeActivityExtendedCd): SafeHtml {
    // TODO - do we want the color to match any route the stop is a part of?
    const color = stopTypeCd === TripNodeActivityCd.PICKUP_SHIPMENTS ? PICKUP_COLOR : DELIVERY_COLOR;
    const border = stopTypeCd === TripNodeActivityCd.PICKUP_SHIPMENTS ? PICKUP_BORDER : DELIVERY_BORDER;

    const stopType = this.activityCdPipe.transform(stopTypeCd);
    const icon = this.mapMarkersService.getMarkerIconUnassignedSvg(stopType, true, false, color, border);

    // NOTE: Need to fix the url for filter or it won't render correctly
    const svg = icon.svg.replace('url(%23shadowUn3)', `url('#shadowUn3')`);

    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
