<div class="apply-changes-modal__container">
  <div class="apply-changes-modal__container__content">
    <div class="apply-changes-modal__container__content__icon">
      <mat-icon>warning</mat-icon>
    </div>
    <div class="apply-changes-modal__container__content__inner-content">
      <div class="apply-changes-modal__container__content__inner-content__title" data-test="apply-changes-modal-title">
        Close without {{ data?.mode }}?
      </div>
      <div
          class="apply-changes-modal__container__content__inner-content__message"
          data-test="apply-changes-modal-content">
        {{ content }}
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="apply-changes-modal__container__actions">
    <div>
      <button mat-raised-button (click)="doNotSave()" data-test="apply-changes-modal-close-button">
        Close without {{ data?.mode }}
      </button>
    </div>
    <div>
      <button mat-stroked-button (click)="cancel()" data-test="apply-changes-modal-cancel-button">Cancel</button>
    </div>
  </div>
</div>
