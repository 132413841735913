import { Injectable, Injector } from '@angular/core';
import { PndStore } from '@pnd-store/pnd-store';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchFilter,
  DeliveryShipmentSearchFilterAnd,
  DeliveryShipmentSearchFilterOr,
  GetPnDInboundSelectionProfilePath,
  ProfileSic,
  SelectionProfile,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  chain as _chain,
  filter as _filter,
  find as _find,
  forEach as _forEach,
  isArray as _isArray,
  size as _size,
  some as _some,
  toString as _toString,
} from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PndXrtService } from '../../../../core/services/pnd-xrt.service';
import { PndZoneUtils } from '../../../../shared/zone-utils';
import * as PndStoreState from '../../../store/pnd-store.state';
import { UnassignedDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/unassigned-deliveries-search-criteria.interface';
import * as UnassignedDeliveriesStoreSelectors from '../../../store/unassigned-deliveries-store/unassigned-deliveries-store.selectors';
import { UnassignedDeliveriesViewId } from '../../components/unassigned-deliveries/unassigned-deliveries-view-data-store.service';
import { SicZoneSelectionType } from '../enums/sic-zone-selection-type';
import { SicZonesAndSatellites } from '../models/sic-zones-and-satellites.model';
import { SicZonesAndSatellitesService } from './sic-zones-and-satellites.service';
import { TimeOffset } from './time-offset.util';

// Profile type codes
enum SIC_TYPE_CD {
  CURRENT = 'C', // shipment at current SIC
  ARRIVAL = 'A', // shipment in-route to SIC
}

@Injectable({
  providedIn: 'root',
})
export class UnassignedDeliveriesCriteriaService {
  private profileAndFilterSubject = new BehaviorSubject<DeliveryShipmentSearchFilterAnd[]>([]);
  readonly profileAndFilter$ = this.profileAndFilterSubject.asObservable();

  constructor(
    private pndXrtService: PndXrtService,
    private cityOperationsService: CityOperationsApiService,
    private injector: Injector,
    private sicZonesAndSatellitesService: SicZonesAndSatellitesService,
    private pndStore$: PndStore<PndStoreState.State>
  ) {}

  /**
   * Return a DeliveryShipmentSearchFilter based on the passed filter criteria
   * @param unmapped - true to ignore the consigneeGeoCoordinates
   */
  filterFromCriteria(
    criteria: UnassignedDeliveriesSearchCriteria,
    planDate: Date,
    sicZonesAndSatellites: SicZonesAndSatellites
  ): Observable<DeliveryShipmentSearchFilter> {
    if (!criteria) {
      return of({ ...new DeliveryShipmentSearchFilter() });
    } else {
      const hostDestSicCds = PndZoneUtils.getCurrentSelectionSics(sicZonesAndSatellites, false);

      const baseFilter = {
        ...new DeliveryShipmentSearchFilter(),
        q: criteria?.Q,

        hostDestSicCd:
          _size(hostDestSicCds) === 1
            ? this.pndXrtService.toXrtFilterEquals(hostDestSicCds[0])
            : this.pndXrtService.toXrtFilterValues(hostDestSicCds),
        destinationSicCd: this.pndXrtService.toXrtFilterValues(criteria.destinationSicCd),
        destSicEta: criteria.destinationSicEta
          ? {
              ...new XrtAttributeFilter(),
              min: '2000-01-01T00:00:00.000',
              max: criteria.destinationSicEta,
              convert: true,
            }
          : undefined,
        estimatedDeliveryDate: this.pndXrtService.toXrtFilterEqualsDateRange(
          criteria?.estimatedDeliveryDate?.min,
          criteria?.estimatedDeliveryDate?.max
        ),
        specialServiceSummary_specialService: this.pndXrtService.toXrtFilterValues(criteria.specialServices),
        consignee_geoCoordinatesGeo: this.pndXrtService.toXrtFilterPoints(criteria.consigneeGeoCoordinatesGeo),

        deliveryQualifierCd: this.pndXrtService.toXrtFilterValues(criteria.deliveryQualifiers),
        billClassCd: this.pndXrtService.toXrtFilterValues(criteria.billClass),
        currentTrailer: this.pndXrtService.toXrtFilterValues(criteria.currentTrailer),
        onExcludedTrailerInd: !criteria.onExcludedTrailerInd
          ? this.pndXrtService.toXrtFilterEquals(_toString(criteria.onExcludedTrailerInd))
          : undefined,

        dispatchGroupId: this.pndXrtService.toXrtFilterValues(criteria.dispatchGroupIds),
      };

      if (criteria.profileId) {
        // adjust filter with planningProfile data
        return this.updateFilterWithPlanningProfile(
          `${criteria.profileId}`,
          planDate,
          sicZonesAndSatellites,
          baseFilter
        );
      } else {
        // no planning profile

        // TODO - Refactor this so we are not relying on knowledge of a grid view, but use a search criteria flag instead
        // TODO - figure out how to make adding the new criteria into a function so we don't repeat what we do with profiles

        // if user is looking at the Arrival view, then we need to add all satellite Sics to the shipmentLocationSicCd list
        return this.pndStore$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesActiveBoardView).pipe(
          take(1),
          map((viewId: string) => {
            if (viewId === UnassignedDeliveriesViewId.Arrived) {
              // in Arrived view, so add satellite Sics to search
              return this.addSatelliteSicCdsToFilter(baseFilter, sicZonesAndSatellites);
            } else {
              // includes shipments with destSicEta < input ETA OR shipmentLocationSic = one of the appropriate destination SICs
              let baseFilterUpdated: DeliveryShipmentSearchFilter;
              if (criteria.destinationSicEta) {
                baseFilterUpdated = this.addSatelliteSicCdsToFilter(baseFilter, sicZonesAndSatellites);
                baseFilterUpdated.and.map((searchFilter) =>
                  searchFilter.or.push({
                    ...new DeliveryShipmentSearchFilterOr(),
                    destSicEta: baseFilter.destSicEta,
                  })
                );
                baseFilterUpdated.destSicEta = undefined;
              }

              // not in Arrived view, so return filter
              const shipmentLocationSicCds = criteria.currentSicCd ? [...criteria.currentSicCd] : [];

              // filter to keep only the host or zone sics (selected in Current SIC filter)
              // and then add the satellites of those
              const hostOrZoneSics = shipmentLocationSicCds.filter(
                (sic) => sic === sicZonesAndSatellites.host || sicZonesAndSatellites.zones.includes(sic)
              );
              _forEach(hostOrZoneSics, (sic) => {
                const satellites = this.sicZonesAndSatellitesService.getSatellitesForSicFromCache(sic);
                shipmentLocationSicCds.push(...satellites);
              });

              return {
                ...(baseFilterUpdated ?? baseFilter),
                shipmentLocationSicCd: this.pndXrtService.toXrtFilterValues(shipmentLocationSicCds),
              };
            }
          })
        );
      }
    }
  }

  // adjust the filter with planningProfile data
  private updateFilterWithPlanningProfile(
    selectionProfileId: string,
    planDate: Date,
    sicZonesAndSatellites: SicZonesAndSatellites,
    originalFilter: DeliveryShipmentSearchFilter
  ): Observable<DeliveryShipmentSearchFilter> {
    const pathParams = { ...new GetPnDInboundSelectionProfilePath(), selectionProfileId };
    return this.cityOperationsService.getPnDInboundSelectionProfile(pathParams).pipe(
      map((response) => {
        const selectionProfile: SelectionProfile = response?.selectionProfile;

        // need to get fromt the Injector here since XpoLtlTimeService tries to fetch data and if
        // injected in the constructor it does it BEFORE the ngx data libraries are initialized, causing
        // an error.
        const timeService: XpoLtlTimeService = this.injector.get(XpoLtlTimeService);

        // the updatedFilter
        let updatedFilter: DeliveryShipmentSearchFilter = {
          ...originalFilter,
          and: originalFilter?.and ?? [], // make sure we have an array of 'and' filters
        };

        const allSicProfiles: ProfileSic[] = selectionProfile?.profileSic ?? [];

        // Any time value within this window we will assume plan date for yesterday
        const isTimeWithinCutoff = (timeHHMMSS: string): boolean => {
          const result = moment(timeHHMMSS, 'HH:mm:ss').isBetween(
            moment('19:00:00', 'HH:mm:ss'),
            moment('23:59:59', 'HH:mm:ss')
          );
          return result;
        };

        // ALL existing criteria for filtering will be and'd with a set of OR/AND criteria
        // to support profile filter construction.
        const orCriterias: DeliveryShipmentSearchFilterOr[] = [];

        // First, handle profiles for Arrival
        const currentPlanDate = moment(planDate).format('YYYY-MM-DD');
        const yesterdayPlanDate = moment(planDate)
          .add('-1', 'days')
          .format('YYYY-MM-DD');

        // This is common regardless of if there is an owning profile SIC record.
        _forEach(
          _filter(allSicProfiles, (profile) => profile.sicTypeCd === SIC_TYPE_CD.ARRIVAL),
          (profile) => {
            const arrivalCutoffTime = profile?.arrivalCutoffTime;
            const arrivalCutoffDate = isTimeWithinCutoff(arrivalCutoffTime) ? yesterdayPlanDate : currentPlanDate;
            const offset = TimeOffset.getOffsetFromTimezone(timeService.timezoneForSicCd(profile.sicCd));
            const endTime = `${arrivalCutoffDate}T${arrivalCutoffTime}.999${offset}`;

            orCriterias.push({
              ...new DeliveryShipmentSearchFilterOr(),
              and: [
                {
                  ...new DeliveryShipmentSearchFilterAnd(),
                  scheduleDestinationSicCd: this.pndXrtService.toXrtFilterEquals(profile.sicCd),
                },
                {
                  ...new DeliveryShipmentSearchFilterAnd(),
                  scheduleETA: this.pndXrtService.toXrtFilterMinMaxRange(undefined, endTime),
                },
              ],
            });
          }
        );

        // is there a profileSic that matches the owningSic
        const hasProfileForOwningSic = _some(
          allSicProfiles,
          (profile) => profile.sicTypeCd === SIC_TYPE_CD.CURRENT && profile.sicCd === selectionProfile.owningSicCd
        );

        if (!hasProfileForOwningSic) {
          // In this case there is no profile record for the SIC so we have to use
          // the header enroute Start/End times for the owning SIC.
          const enrouteStartTime = selectionProfile.enrouteStartTime;
          const enrouteEndTime = selectionProfile.enrouteEndTime;
          const enrouteStartDate =
            isTimeWithinCutoff(enrouteEndTime) || isTimeWithinCutoff(enrouteStartTime)
              ? yesterdayPlanDate
              : currentPlanDate;
          const enrouteEndDate = isTimeWithinCutoff(enrouteEndTime) ? yesterdayPlanDate : currentPlanDate;
          const offset = TimeOffset.getOffsetFromTimezone(timeService.timezoneForSicCd(selectionProfile.owningSicCd));
          const startTime = `${enrouteStartDate}T${enrouteStartTime}.000${offset}`;
          const endTime = `${enrouteEndDate}T${enrouteEndTime}.999${offset}`;

          orCriterias.push({
            ...new DeliveryShipmentSearchFilterOr(),
            scheduleDestinationSicCd: this.pndXrtService.toXrtFilterEquals(selectionProfile.owningSicCd),
            scheduleETA: this.pndXrtService.toXrtFilterMinMaxRange(startTime, endTime),
          });
        }

        // always find Sics with sicTypeCd C - current
        const profileSicCds = _chain(allSicProfiles)
          .filter((profile) => profile.sicTypeCd === SIC_TYPE_CD.CURRENT)
          .map((profile) => profile.sicCd)
          .sortBy()
          .sortedUniq()
          .without('')
          .value();

        if (_size(profileSicCds) > 0) {
          orCriterias.push({
            ...new DeliveryShipmentSearchFilterOr(),
            shipmentLocationSicCd: this.pndXrtService.toXrtFilterValues(profileSicCds),
          });
        }

        // add the new 'or' criteria to the filter
        updatedFilter.and.push({
          ...new DeliveryShipmentSearchFilterAnd(),
          or: orCriterias,
        });

        // Update the shipmentLocationSicCd with satellite SICs, if needed
        if (selectionProfile.inboundDestinationSicInd || hasProfileForOwningSic) {
          updatedFilter = this.addSatelliteSicCdsToFilter(updatedFilter, sicZonesAndSatellites);
          this.profileAndFilterSubject.next(updatedFilter.and);
          return updatedFilter;
        } else {
          this.profileAndFilterSubject.next(updatedFilter.and);
          return updatedFilter;
        }
      })
    );
  }

  /**
   * Return filter with all satellite sics added to shipmentLocationSicCd
   */
  private addSatelliteSicCdsToFilter(
    originalFilter: DeliveryShipmentSearchFilter,
    sicZonesAndSatellites: SicZonesAndSatellites
  ): DeliveryShipmentSearchFilter {
    const sicsToAdd: string[] =
      sicZonesAndSatellites.currentSelection === SicZoneSelectionType.HOST_ONLY
        ? [sicZonesAndSatellites.host, ...sicZonesAndSatellites.hostSatellites]
        : [
            sicZonesAndSatellites.host,
            ...sicZonesAndSatellites.hostSatellites,
            ...sicZonesAndSatellites.zones,
            ...sicZonesAndSatellites.zoneSatellites,
          ];
    return this.addSicCdsToFilter(sicsToAdd, originalFilter);
  }

  /**
   * Return filter with all the passed sics added to shipmentLocationSicCd
   */
  private addSicCdsToFilter(
    sicsToAdd: string[],
    originalFilter: DeliveryShipmentSearchFilter
  ): DeliveryShipmentSearchFilter {
    // create copy of filter we can update
    const updatedFilter: DeliveryShipmentSearchFilter = {
      ...originalFilter,
      and: originalFilter?.and ?? [
        {
          ...new DeliveryShipmentSearchFilterAnd(),
          or: [],
        },
      ], // make sure we have an array of 'and' filters
    };

    const shipmentLocationSicCd = _chain(sicsToAdd)
      .sortBy()
      .sortedUniq()
      .without('')
      .value();

    const orShipmentLocation = {
      ...new DeliveryShipmentSearchFilterOr(),
      shipmentLocationSicCd: this.pndXrtService.toXrtFilterValues(shipmentLocationSicCd),
    };

    // find first 'and' that contains a list of 'or' clauses
    let andWithOrClause = _find(updatedFilter.and, (clause) => _isArray(clause.or));
    if (!andWithOrClause) {
      // there was no suitable 'or' to add to, so create one
      andWithOrClause = {
        ...new DeliveryShipmentSearchFilterAnd(),
        or: [],
      };
      updatedFilter.and.push(andWithOrClause);
    }

    andWithOrClause.or.push(orShipmentLocation);

    return updatedFilter;
  }
}
