import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  XpoLtlLayoutPreferencesStorage,
  XpoLtlLayoutPreferencesStorageData,
} from './layout-preferences-storage.interface';

// create injectable version of localStorage
const LOCAL_STORAGE = new InjectionToken('xpo-LtlLocalStorage', {
  providedIn: 'root',
  factory: () => {
    return localStorage;
  },
});
const LAYOUT_PREFERENCE_LOCAL_STORAGE_KEY = 'xpo-LtlLocalStorage';

@Injectable({
  providedIn: 'root',
})
export class XpoLtlLayoutPreferencesStorageLocalService implements XpoLtlLayoutPreferencesStorage {
  constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {}

  getData$(): Observable<XpoLtlLayoutPreferencesStorageData> {
    return new Observable<XpoLtlLayoutPreferencesStorageData>((subscriber) => {
      let data = new XpoLtlLayoutPreferencesStorageData();
      const fromJSON = this.storage.getItem(LAYOUT_PREFERENCE_LOCAL_STORAGE_KEY);
      if (fromJSON) {
        try {
          data = JSON.parse(fromJSON);
        } catch {}
      }
      subscriber.next(data);
      subscriber.complete();
    });
  }
  setData$(data: XpoLtlLayoutPreferencesStorageData): Observable<void> {
    return new Observable<void>((subscriber) => {
      const asJSON = JSON.stringify(data);
      this.storage.setItem(LAYOUT_PREFERENCE_LOCAL_STORAGE_KEY, asJSON);
      subscriber.next();
      subscriber.complete();
    });
  }
}
