import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  matLegacyDialogAnimations as matDialogAnimations,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import { isNumber as _isNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface StopMapData {
  name: string;
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'pnd-stop-map',
  templateUrl: './stop-map.component.html',
  styleUrls: ['./stop-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [matDialogAnimations.dialogContainer],
  host: {
    class: 'pnd-StopMap',
  },
})
export class StopMapComponent implements OnInit {
  private locationSubject = new BehaviorSubject<{ lat: number; lng: number }>(undefined);
  location$ = this.locationSubject.asObservable();

  private nameSubject = new BehaviorSubject<string>(undefined);
  name$ = this.nameSubject?.asObservable().pipe(filter((val) => val !== undefined));

  readonly defaultZoom: number = 19;
  private zoomSubject = new BehaviorSubject<number>(null);
  zoom$ = this.zoomSubject?.asObservable().pipe(filter((val) => val !== null));
  map: google.maps.Map;
  shouldShowMarkers: boolean = false;

  constructor(public dialogRef: MatDialogRef<StopMapComponent>, @Inject(MAT_DIALOG_DATA) public data: StopMapData) {}

  ngOnInit() {
    this.shouldShowMarkers = false;
    // on animation done
    this.dialogRef.afterOpened().subscribe(() => {
      this.nameSubject.next(this?.data?.name ?? '');
      this.initMap();
    });
  }

  /**
   * Initializes/broadcast map data
   */
  initMap(): void {
    const isLatNumber = _isNumber(this.data.latitude);
    const isLngNumber = _isNumber(this.data.longitude);
    if (!isLatNumber || !isLngNumber) {
    } else {
      this.zoomSubject.next(this.defaultZoom);
      this.locationSubject.next({
        lat: this.data.latitude,
        lng: this.data.longitude,
      });
    }
  }

  /**
   * Callback when map initialization on gmap api is done
   * @param map
   */
  // tslint:disable-next-line: no-shadowed-variable
  mapReady(map: google.maps.Map): void {
    this.map = map;
    this.shouldShowMarkers = true;
    this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
  }
}
