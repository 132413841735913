import { Injectable } from '@angular/core';
import {
  LocationApiService,
  LocGeoArea,
  UpsertGeoAreasPath,
  UpsertGeoAreasResp,
  UpsertGeoAreasRqst,
} from '@xpo-ltl-2.0/sdk-location';
import { PolygonTypeCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeoAreaDraw } from '../classes/GeoAreaDraw';

@Injectable({
  providedIn: 'root',
})
export class GeoAreaDrawerService {
  private geoAreasSubject = new BehaviorSubject<GeoAreaDraw[]>([]);
  readonly geoAreas$ = this.geoAreasSubject.asObservable();

  constructor(private locationApiService: LocationApiService) {}

  saveGeoAreaChanges(geoAreas: GeoAreaDraw[], sicCd: string): Observable<UpsertGeoAreasResp> {
    const request: UpsertGeoAreasRqst = { geoAreas: [] };
    const path: UpsertGeoAreasPath = { sicCd: sicCd };
    for (const geoArea of geoAreas) {
      const locGeoArea: LocGeoArea = new LocGeoArea();
      if (geoArea.areaInstId) {
        locGeoArea.areaId = +geoArea.areaInstId;
      }
      locGeoArea.areaName = geoArea.geoAreaName;
      locGeoArea.areaDescription = geoArea.geoAreaDesc;
      locGeoArea.areaGeojson = geoArea.geoAreaJson;
      locGeoArea.typeCd = geoArea.polygonTypeCd ? geoArea.polygonTypeCd : PolygonTypeCd.AREA;
      locGeoArea.parentRefSicCd = geoArea.sicCd;
      locGeoArea.reconciledDateTime = new Date();
      request.geoAreas.push(locGeoArea);
    }

    return this.locationApiService.upsertGeoAreas(request, path);
  }

  getPolygonCenter(coordinates: { lat: number; lng: number }[]) {
    const bounds = new google.maps.LatLngBounds();
    coordinates?.forEach((coords) => bounds.extend(coords));
    return new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng());
  }

  reducerDP(source: google.maps.LatLng[], kink: number): google.maps.LatLng[] {
    /* source Input coordinates in GLatLngs 	*/
    /* kink	in metres, kinks above this depth kept  */
    /* kink depth is the height of the triangle abc where a-b and b-c are two consecutive line segments */

    let nSource: number,
      nStack: number,
      nDest: number,
      start: number,
      end: number,
      i: number,
      sig: number,
      devSqr: number,
      maxDevSqr: number,
      bandSqr: number,
      kain12: number,
      y12: number,
      d12: number,
      x13: number,
      y13: number,
      d13: number,
      x23: number,
      y23: number,
      d23: number;
    const F = (Math.PI / 180.0) * 0.5;
    const index = new Array();
    const sigStart = new Array();
    const sigEnd = new Array();

    if (source.length < 3) {
      return source;
    }

    nSource = source.length;
    bandSqr = (kink * 360.0) / (2.0 * Math.PI * 6378137.0); /* Now in degrees */
    bandSqr *= bandSqr;
    nDest = 0;
    sigStart[0] = 0;
    sigEnd[0] = nSource - 1;
    nStack = 1;

    /* while the stack is not empty  ... */
    while (nStack > 0) {
      start = sigStart[nStack - 1];
      end = sigEnd[nStack - 1];
      nStack--;

      if (end - start > 1) {
        /* any intermediate points ? */
        /* ... yes, so find most deviant intermediate point to
          either side of line joining start & end points */

        kain12 = source[end].lng() - source[start].lng();
        y12 = source[end].lat() - source[start].lat();

        if (Math.abs(kain12) > 180.0) {
          kain12 = 360.0 - Math.abs(kain12);
        }

        kain12 *= Math.cos(F * (source[end].lat() + source[start].lat()));
        /* use avg lat to reduce lng */
        d12 = kain12 * kain12 + y12 * y12;

        for (i = start + 1, sig = start, maxDevSqr = -1.0; i < end; i++) {
          x13 = source[i].lng() - source[start].lng();
          y13 = source[i].lat() - source[start].lat();
          if (Math.abs(x13) > 180.0) {
            x13 = 360.0 - Math.abs(x13);
          }
          x13 *= Math.cos(F * (source[i].lat() + source[start].lat()));
          d13 = x13 * x13 + y13 * y13;

          x23 = source[i].lng() - source[end].lng();
          y23 = source[i].lat() - source[end].lat();
          if (Math.abs(x23) > 180.0) {
            x23 = 360.0 - Math.abs(x23);
          }

          x23 *= Math.cos(F * (source[i].lat() + source[end].lat()));
          d23 = x23 * x23 + y23 * y23;

          if (d13 >= d12 + d23) {
            devSqr = d23;
          } else if (d23 >= d12 + d13) {
            devSqr = d13;
          } else {
            devSqr = ((x13 * y12 - y13 * kain12) * (x13 * y12 - y13 * kain12)) / d12; // solve triangle
          }

          if (devSqr > maxDevSqr) {
            sig = i;
            maxDevSqr = devSqr;
          }
        }

        if (maxDevSqr < bandSqr) {
          /* is there a sig. intermediate point ? */
          /* ... no, so transfer current start point */
          index[nDest] = start;
          nDest++;
        } else {
          /* ... yes, so push two sub-sections on stack for further processing */
          nStack++;
          sigStart[nStack - 1] = sig;
          sigEnd[nStack - 1] = end;
          nStack++;
          sigStart[nStack - 1] = start;
          sigEnd[nStack - 1] = sig;
        }
      } else {
        /* ... no intermediate points, so transfer current start point */
        index[nDest] = start;
        nDest++;
      }
    }

    /* transfer last point */
    index[nDest] = nSource - 1;
    nDest++;

    /* make return array */
    const r = new Array();
    for (let ix = 0; ix < nDest; ix++) {
      r.push(source[index[ix]]);
    }
    return r;
  }

  updateTheCache(geoAreaList: GeoAreaDraw[]) {
    this.geoAreasSubject.next(geoAreaList);
  }
}
