import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import {
  find as _find,
  keyBy as _keyBy,
  toUpper as _toUpper,
  cloneDeep as _cloneDeep,
  isEqual as _isEqual,
  reduce as _reduce,
  map as _map,
  size as _size,
  pick as _pick,
  forEach as _forEach,
  every as _every,
} from 'lodash';
import { combineLatest, Observable, of, forkJoin } from 'rxjs';
import { catchError, concatMapTo, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { StoreSourcesEnum } from '../../inbound-planning/shared/enums/store-sources.enum';
import {
  consigneeToId,
  EventItem,
  UnassignedDeliveryIdentifier,
  consigneeShipmentToId,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedDeliveriesCacheService } from '../../inbound-planning/shared/services/unassigned-deliveries-cache.service';
import * as GlobalFilterStoreSelectors from '../global-filters-store/global-filters-store.selectors';
import * as PlanningProfilesStoreSelectors from '../planning-profiles-store/planning-profiles-store.selectors';
import * as PndStoreState from '../pnd-store.state';
import { UnassignedDeliveriesSearchCriteria } from './unassigned-deliveries-search-criteria.interface';
import {
  ActionTypes,
  Refresh,
  SetLastUpdate,
  SetSearchCriteria,
  SetSelectedDeliveries,
} from './unassigned-deliveries-store.actions';
import * as UnassignedDeliveriesStoreSelectors from './unassigned-deliveries-store.selectors';

@Injectable()
export class UnassignedDeliveriesStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: PndStore<PndStoreState.State>,
    private unassignedDeliveriesService: UnassignedDeliveriesCacheService
  ) {}

  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new Refresh()])
  );

  // refresh deliveries based on current searchCriteria
  @Effect()
  refresh$: Observable<SetLastUpdate | SetSelectedDeliveries> = this.actions$.pipe(
    ofType<Refresh>(ActionTypes.refresh),
    switchMap(() =>
      combineLatest([
        this.store$.select(UnassignedDeliveriesStoreSelectors.searchCriteria),
        this.store$.select(PlanningProfilesStoreSelectors.planningProfiles),
        this.store$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
        this.store$.select(GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites),
        this.store$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesActiveBoardView),
        this.unassignedDeliveriesService.unassignedDeliveries$,
      ]).pipe(take(1))
    ),
    switchMap(
      ([criteria, planningProfiles, planDate, sicZonesAndSatellites, activeBoardView, currentUnassignedStops]) => {
        const planningProfile = _find(planningProfiles, (profile) => profile.profileId === criteria.profileId);
        const searchCriteria: UnassignedDeliveriesSearchCriteria = _cloneDeep(criteria);
        searchCriteria.profileName = planningProfile ? _toUpper(planningProfile.profileName) : undefined;
        return forkJoin([
          of(currentUnassignedStops), // unassigned stop state prior to fetch
          this.unassignedDeliveriesService.searchUnassignedDeliveries(
            searchCriteria,
            planDate,
            sicZonesAndSatellites,
            activeBoardView
          ), // unassigned stop state after fetch
        ]).pipe(take(1));
      }
    ),
    withLatestFrom(this.store$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)),
    switchMap(([[previousUnassignedStopsState, nextUnassignedStopsState], previousSelectionState]) => {
      let newSelectedDeliveries: EventItem<UnassignedDeliveryIdentifier>[] = [];

      if (_size(previousSelectionState)) {
        // create map of all previous unassigned stops so we don't have to keep iterating through the list
        const prevUnassignedStopMap: { [key: string]: UnassignedStop } = _keyBy(
          previousUnassignedStopsState,
          (stop: UnassignedStop) => consigneeToId(stop)
        );
        // create map of all previous selected stops/shipments. If stop, value will be array of shipmentInstIds
        // if shipment, value will be consignee identifier
        const previousShipmentSelectionMap: { [key: string]: UnassignedDeliveryIdentifier | string[] } = _reduce(
          previousSelectionState,
          (selectionMap, currentIdentifier: EventItem<UnassignedDeliveryIdentifier>) => {
            // if selection is a shipment identifier, add to map with consigneeShipmentToId as key
            if (currentIdentifier?.id?.shipmentInstId) {
              selectionMap[consigneeShipmentToId(currentIdentifier.id)] = currentIdentifier.id;
            } else {
              // selection is a stop, grab all shipments from stop and add all to consigneeShipment Id's as array with stop id as key
              const prevUnassignedStop: UnassignedStop = prevUnassignedStopMap[consigneeToId(currentIdentifier.id)];
              if (prevUnassignedStop) {
                const selectedShipments = _map(
                  prevUnassignedStop.deliveryShipments,
                  (deliveryShipment: DeliveryShipmentSearchRecord) => {
                    return consigneeShipmentToId(deliveryShipment);
                  }
                );

                selectionMap[consigneeToId(prevUnassignedStop)] = selectedShipments;
              }
            }
            return selectionMap;
          },
          {}
        );

        newSelectedDeliveries = _reduce(
          nextUnassignedStopsState,
          (stopIdentifiers: EventItem<UnassignedDeliveryIdentifier>[], unassignedStop: UnassignedStop) => {
            // if entire stop was selected, check stop ref with shipment id arrays to see if all shipments in stop are still selected
            const previouslySelectedStopRef: string[] = previousShipmentSelectionMap[
              consigneeToId(unassignedStop)
            ] as string[];

            const isMatchingSelectedShipmentRef = (deliveryShipment: DeliveryShipmentSearchRecord): boolean => {
              return !!_find(previouslySelectedStopRef, (id: string) => id === consigneeShipmentToId(deliveryShipment));
            };

            if (previouslySelectedStopRef) {
              // check if all shipment ids on delivery shipments are in selectedStopRef array of shipment ids
              const areAllShipmentsSelected =
                _isEqual(_size(previouslySelectedStopRef), _size(unassignedStop.deliveryShipments)) &&
                _every(unassignedStop.deliveryShipments, (deliveryShipment: DeliveryShipmentSearchRecord) => {
                  return isMatchingSelectedShipmentRef(deliveryShipment);
                });

              // if all are still selected, push the unassigned stop
              if (areAllShipmentsSelected) {
                stopIdentifiers.push(this.transformToUnnasignedDeliveryEventItem(unassignedStop));
              } else {
                // check each delivery shipment to see if individual shipment should be selected
                _forEach(unassignedStop.deliveryShipments, (deliveryShipment: DeliveryShipmentSearchRecord) => {
                  if (isMatchingSelectedShipmentRef(deliveryShipment)) {
                    stopIdentifiers.push(this.transformToUnnasignedDeliveryEventItem(deliveryShipment));
                  }
                });
              }
            } else {
              // check each delivery shipment to see if individual shipment should be selected
              _forEach(unassignedStop.deliveryShipments, (deliveryShipment: DeliveryShipmentSearchRecord) => {
                if (previousShipmentSelectionMap[consigneeShipmentToId(deliveryShipment)]) {
                  stopIdentifiers.push(this.transformToUnnasignedDeliveryEventItem(deliveryShipment));
                }
              });
            }

            return stopIdentifiers;
          },
          []
        );
      }

      this.unassignedDeliveriesService.loading = false;
      return [
        new SetLastUpdate({ lastUpdate: new Date() }),
        new SetSelectedDeliveries({ selectedDeliveries: newSelectedDeliveries }),
      ];
    }),
    catchError(() => {
      this.unassignedDeliveriesService.loading = false;
      return [new SetLastUpdate({ lastUpdate: new Date() }), new SetSelectedDeliveries({ selectedDeliveries: [] })];
    })
  );

  private transformToUnnasignedDeliveryEventItem(
    identifier: UnassignedDeliveryIdentifier
  ): EventItem<UnassignedDeliveryIdentifier> {
    return {
      id: {
        consignee: _pick(identifier.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
        shipmentInstId: identifier.shipmentInstId,
      },
      source: StoreSourcesEnum.REDUX_STORE,
    } as EventItem<UnassignedDeliveryIdentifier>;
  }
}
