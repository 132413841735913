import { ConditioningService } from '@xpo-ltl/common-services';
import { Activity, Stop } from '@xpo-ltl/sdk-cityoperations';
import { GeoCoordinates, PickupTypeCd, ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { TripActivityExtendedCd } from 'app/inbound-planning/shared/models/stop-type.model';
import { filter as _filter, has as _has, map as _map, set as _set } from 'lodash';
import { PndTripUtils } from 'shared/trip-utils';
import { MapUtils } from '../../classes/map-utils';
import { SpecialServicesHelper } from '../../helpers/special-services/special-services.helper';
import { MarkerRouteInfo } from '../../interfaces/marker-route-info.interface';
import { DeliveryWindowService } from '../../services/delivery-window.service';
import { EtaWindowService } from '../../services/eta-window.service';
import { MapMarkersService } from '../../services/map-markers.service';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo, MapMarkerInfoData } from '../map-marker-info.model';
import { ClusterableMarker } from './clusterable-marker';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class ForecastedPickupMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> implements ClusterableMarker {
  readonly forecastedPickup: Stop;
  infoWindowTopOffset: number = 0;
  infoWindowLeftOffset: number = 0;
  infoWindowSpaceBetweenMarker: number = 0;

  get mappable(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  constructor(
    forecastedPickup: Stop,
    routeInfo: MarkerRouteInfo,
    mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private etaWindowService: EtaWindowService,
    private deliveryWindowService: DeliveryWindowService,
    private conditioningService: ConditioningService
  ) {
    super();

    this.forecastedPickup = forecastedPickup;

    let totalWeight = 0;
    let totalMM = 0;
    let totalPieces = 0;
    let totalPallets = 0;
    let totalLoosePieces = 0;
    let totalCube = 0;
    _filter(forecastedPickup.activities, (activity: Activity) => _has(activity, 'tripNodeActivity')).forEach(
      (activity) => {
        totalWeight += activity?.tripNodeActivity?.totalWeightCount || 0;
        totalMM += activity?.tripNodeActivity?.totalMmCount || 0;
        totalPieces += activity?.tripNodeActivity?.totalBillCount || 0;
        totalPallets += activity?.tripNodeActivity?.totalPalletCount || 0;
        activity?.activityShipments?.forEach((activityShipment) => {
          totalLoosePieces += activityShipment?.loosePiecesCount;
        });
        totalCube += activity?.tripNodeActivity?.totalCubePercentage || 0;
      }
    );

    const stopType = PndTripUtils.aggregateTripNodeActivityCd(forecastedPickup.activities);

    this.specialServices = _map(
      forecastedPickup.specialServicesSummary,
      (summary: ShipmentSpecialServiceSummary) => summary.specialService
    );

    this.icon = mapMarkerService.getMarkerIconAssigned(
      stopType || PickupTypeCd.PU,
      10,
      this.isSelected || this.isFocused,
      routeInfo.color,
      undefined,
      undefined,
      undefined,
      this.specialServices
    );

    const geoCoordinates: GeoCoordinates = MapUtils.getCoordinates(forecastedPickup.customer);
    this.latitude = geoCoordinates.lat;
    this.longitude = geoCoordinates.lon;

    const data: MapMarkerInfoData = {
      totalWeightLbs: totalWeight,
      motorizedPiecesCount: totalMM,
      totalShipmentsCount: totalPieces,
      totalPalletCount: totalPallets,
      totalLoosePieces: totalLoosePieces,
      totalCube: totalCube,
      stopType,
      color: routeInfo.color,
      stopWindow: this.forecastedPickup?.stopWindowSummary,
      activities: this.forecastedPickup?.activities,
      etaSicCd: this.forecastedPickup?.tripNode?.nodeSicCd ?? '',
    };

    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      this.etaWindowService,
      this.deliveryWindowService,
      this.conditioningService,
      data,
      undefined,
      undefined,
      `${routeInfo.routeInstId}`,
      routeInfo.routeName
    );

    // This is a hack!  Required to get icon to re-render its type when zooming and theming.
    _set(this.markerInfo, 'stopType', stopType || TripActivityExtendedCd.ForecastedPickup);

    this.markerInfo.specialServices = SpecialServicesHelper.getSpecialServicesForSummary(
      forecastedPickup.specialServicesSummary
    );
  }
}
