<div [id]="'areaCard-' + geoAreaDraw.areaInstId">
  <div
    class="pnd-geo-area-drawer-detail__details-container"
    [ngStyle]="{ 'border-left': '5px solid ' + geoAreaDraw?.areaColor }"
  >
    <div class="pnd-geo-area-drawer-detail__header">
      <div class="pnd-geo-area-drawer-detail__header-container">
        <h1 (click)="editGeoArea()" *ngIf="!geoAreaDraw?.mode || geoAreaDraw?.mode === GeoAreaDrawMode.VIEW">
          {{ geoAreaDraw?.geoAreaName }} - {{ geoAreaDraw.sicCd }}
        </h1>
        <div
          class="pnd-geo-area-drawer-detail__header-container-input"
          *ngIf="
            geoAreaDraw?.mode === GeoAreaDrawMode.EDIT ||
            geoAreaDraw?.mode === GeoAreaDrawMode.CREATE ||
            (editModeState$ | async)
          "
        >
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              required
              minLength="1"
              maxLength="5"
              matInput
              type="text"
              [(ngModel)]="geoAreaDraw.geoAreaName"
              [formControl]="geoAreaName"
            />
            <mat-error *ngIf="geoAreaName?.hasError('pattern')">
              Geo Area name cannot contain special characters
            </mat-error>
            <mat-error>
              <ng-container *ngIf="isNullOrEmpty(geoAreaDraw.geoAreaName)">
                Geo Area name should be at least 1 character long.
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>SIC *</mat-label>
            <mat-select
              [formControl]="sicControl"
              [value]="geoAreaDraw?.sicCd"
              xpoSelect
              (selectionChange)="setSicZone($event.value)"
              [disabled]="geoAreaDraw?.mode === GeoAreaDrawMode.EDIT"
            >
              <mat-option *ngFor="let val of sicZoneSatellite; trackBy: trackValBy" [value]="val">
                {{ val }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="isNullOrEmpty(geoAreaDraw?.sicCd)">
              Geo Area Sic Code is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="pnd-geo-area-drawer-detail__header-container-button">
          <button
            matTooltip="Edit Geo Area"
            matTooltipPosition="above"
            *ngIf="!geoAreaDraw?.mode || geoAreaDraw?.mode === GeoAreaDrawMode.VIEW"
            mat-icon-button
            (click)="editGeoArea()"
          >
            <mat-icon>create</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="pnd-geo-area-drawer-detail__content">
      <p
        *ngIf="!geoAreaDraw?.mode || geoAreaDraw?.mode === GeoAreaDrawMode.VIEW"
        (click)="editGeoArea(geoAreaDraw.areaInstId)"
      >
        {{ geoAreaDraw?.geoAreaDesc }}
      </p>
      <div
        class="pnd-geo-area-drawer-detail__content-input"
        *ngIf="
          geoAreaDraw?.mode === GeoAreaDrawMode.EDIT ||
          geoAreaDraw?.mode === GeoAreaDrawMode.CREATE ||
          (editModeState$ | async)
        "
      >
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea
            required
            matInput
            [(ngModel)]="geoAreaDraw.geoAreaDesc"
            minLength="1"
            maxLength="50"
            rows="5"
          ></textarea>
          <mat-error *ngIf="isNullOrEmpty(geoAreaDraw.geoAreaDesc)">
            Geo Area description should be at least 1 characters long.
          </mat-error>
          <mat-hint>
            <p>{{ geoAreaDraw.geoAreaDesc ? geoAreaDraw.geoAreaDesc?.length : 0 }} / 50</p>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="pnd-geo-area-drawer-detail__content-buttons">
        <div class="pnd-geo-area-drawer-detail__content-buttons-edit">
          <button
            [disabled]="
              geoAreaName?.hasError('pattern') ||
              isNullOrEmpty(geoAreaDraw.geoAreaDesc) ||
              isNullOrEmpty(geoAreaDraw.geoAreaName) ||
              !geoAreaDraw.geoAreaJson
            "
            matTooltip="Save Changes"
            matTooltipPosition="above"
            *ngIf="geoAreaDraw?.mode === GeoAreaDrawMode.EDIT || geoAreaDraw?.mode === GeoAreaDrawMode.CREATE"
            class="pnd-geo-area-drawer-detail__content-buttons-save"
            mat-flat-button
            xpoSmallButton
            color="primary"
            (click)="saveEdit()"
          >
            Save
          </button>
          <button
            matTooltip="Cancel Changes"
            matTooltipPosition="above"
            *ngIf="geoAreaDraw?.mode === GeoAreaDrawMode.EDIT || geoAreaDraw?.mode === GeoAreaDrawMode.CREATE"
            class="pnd-geo-area-drawer-detail__content-buttons-cancel"
            mat-stroked-button
            xpoSmallButton
            (click)="cancelEdit()"
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            matTooltip="Delete Geo Area"
            matTooltipPosition="above"
            mat-stroked-button
            xpoSmallButton
            (click)="deleteGeoArea(geoAreaDraw)"
            *ngIf="geoAreaDraw?.mode === GeoAreaDrawMode.EDIT"
            class="pnd-geo-area-drawer-detail__content-buttons-delete"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
