import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CarrierMaster } from '@xpo-ltl/sdk-carriermanagement';
import { CmsTripTypeCd } from '@xpo-ltl/sdk-common';
import { NotificationMessageService, NotificationMessageStatus } from 'core';
import { sortBy as _sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { CartageService } from './../../services/cartage.service';

@Component({
  selector: 'pnd-carriers-selector-dialog',
  templateUrl: './carriers-selector-dialog.component.html',
  styleUrls: ['./carriers-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarriersSelectorDialogComponent implements AfterViewInit {
  @ViewChild('filterInput') filterInput: ElementRef;

  selectedCarrierId: number = undefined;
  isCarrierSelected: boolean = false;
  displayedColumns: string[] = ['scacCd', 'carrierName', 'selected'];
  showSpinner: boolean = false;
  dataSource = new MatTableDataSource(_sortBy(this.data.carriers, (c) => c?.carrierName));
  dispatchType: CmsTripTypeCd;
  componentName = 'pnd-carriers-selector-dialog';
  dispatchTypeMapPreferences: { [key: number]: CmsTripTypeCd } = {};

  constructor(
    private notificationMessageService: NotificationMessageService,
    private userPreferencesService: UserPreferencesService,
    public dialogRef: MatDialogRef<CarriersSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentCarrier: CarrierMaster;
      carriers: CarrierMaster[];
      convertRouteCallback$: (carrierId: number, dispatchType: string) => Observable<boolean>;
    },
    private cartageService: CartageService
  ) {
    this.userPreferencesService
      .getPreferencesFor<{ [key: number]: CmsTripTypeCd }>(this.componentName)
      .pipe(
        catchError(() => {
          return of({});
        }),
        take(1)
      )
      .subscribe((preferences: { [key: number]: CmsTripTypeCd }) => {
        this.dispatchTypeMapPreferences = preferences || {};

        this.selectedCarrierId = data?.currentCarrier?.carrierId;
        if (this.selectedCarrierId) {
          this.dispatchType = this.dispatchTypeMapPreferences[this.selectedCarrierId];
        }
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.filterInput?.nativeElement?.focus();
    }, 500);
  }

  /**
   * Set the carrierId of the carrier selected by the user in the UI. Do not use this function to select the carrier by code
   */
  selectCarrier(carrierId: number) {
    this.selectedCarrierId = carrierId;

    this.dispatchType = this.dispatchTypeMapPreferences[this.selectedCarrierId];

    this.isCarrierSelected = true;
  }

  selectDispatchType(selection: MatSelect): void {
    this.dispatchType = <CmsTripTypeCd>selection.value;
    this.dispatchTypeMapPreferences[this.selectedCarrierId] = this.dispatchType;
  }

  convert() {
    this.showSpinner = true;

    this.data
      .convertRouteCallback$(this.selectedCarrierId, this.dispatchType)
      .pipe(
        take(1),
        finalize(() => (this.showSpinner = false))
      )
      .subscribe(
        () => {
          this.userPreferencesService
            .updatePreferencesFor(this.componentName, this.dispatchTypeMapPreferences)
            .subscribe();

          this.dialogRef.close(this.selectedCarrierId);

          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Success, 'Route converted')
            .subscribe();
          this.cartageService.setConvertToCartageSuccess(true);
        },
        (error) => {
          this.cartageService.setConvertToCartageSuccess(false);
          this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe();
        }
      );
  }

  cancel() {
    this.dialogRef.close();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
