import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Route } from '@xpo-ltl/sdk-cityoperations';
import { RouteStatusCd, RouteStatusCdHelper } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pnd-release-route-toggle-cell-renderer',
  templateUrl: './release-route-toggle-cell-renderer.html',
  styleUrls: ['./release-route-toggle-cell-renderer.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-ReleaseRouteToggleCellRenderer' },
})
export class ReleaseRouteToggleCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  isVisible = false;
  isChecked = false;

  agInit(params: any): void {
    this.params = params;
    const route = params.data?.route as Route;
    if (route) {
      const statusCd = RouteStatusCdHelper.toEnum(route.statusCd);
      this.isVisible =
        statusCd === RouteStatusCd.UNRELEASED ||
        statusCd === RouteStatusCd.RELEASED ||
        statusCd === RouteStatusCd.LOADING;
      this.isChecked = !!route.xdockReleaseInd;
    } else {
      // No route, so can't tell if it is releasable or not
      this.isVisible = false;
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    return false;
  }

  onChange(event: MatSlideToggleChange) {
    if (this.isVisible && this.params.onClick) {
      this.params.newValue = event.checked;
      this.params.onClick(this.params);
    }
  }
}
