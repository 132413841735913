import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pnd-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintButtonComponent {
  @Input() disabled: boolean = true;
  @Output() print = new EventEmitter<void>();

  constructor() {}

  onClick() {
    this.print.emit();
  }
}
