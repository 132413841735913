import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DispatcherTripsAlertType } from '../../../components/dispatcher-trips/enums/dispatcher-trips-alert-type.enum';
import { DispatcherTripsAlert } from '../../../components/dispatcher-trips/models/dispatcher-trips-alert';
import { DispatcherTripsGridItem } from '../../../components/dispatcher-trips/models/dispatcher-trips-grid-item.model';

export interface DispatchExceptionTooltipCellRendererParams extends ICellRendererParams {
  alertType: DispatcherTripsAlertType;
  getAlertsFn: (item: DispatcherTripsGridItem) => DispatcherTripsAlert[];
  rightAlign?: boolean;
  isStatusIndicator?: boolean;
}

export interface DispatchCellRendererStatusIndicatorValue {
  statusText: string;
  statusColor: string;
}

@Component({
  selector: 'pnd-dispatch-exception-tooltip-cell-renderer',
  styleUrls: ['./dispatch-exception-tooltip-cell-renderer.component.scss'],
  templateUrl: './dispatch-exception-tooltip-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispatchExceptionTooltipCellRendererComponent implements ICellRendererAngularComp {
  alert: DispatcherTripsAlert;
  isStatusIndicator: boolean;
  stringValue: string;
  statusIndicatorValue: DispatchCellRendererStatusIndicatorValue;
  rightAlign: boolean = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  agInit(params: DispatchExceptionTooltipCellRendererParams): void {
    this.alert = params
      .getAlertsFn(params.data as DispatcherTripsGridItem)
      .find((exception) => exception.type === params.alertType);
    this.isStatusIndicator = params.isStatusIndicator;
    this.rightAlign = params.rightAlign;
    if (params.isStatusIndicator) {
      this.statusIndicatorValue = params.value as DispatchCellRendererStatusIndicatorValue;
    } else {
      this.stringValue = (params.valueFormatted || params.value) as string;
    }
    this.changeDetector.markForCheck();
  }

  refresh(params: DispatchExceptionTooltipCellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
}
