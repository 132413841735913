import { Injectable } from '@angular/core';
import { PluralPipe } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  CompleteTripPath,
  CompleteTripRqst,
  DeletePnDTripPath,
  DispatchPnDTripPath,
  DispatchPnDTripResp,
  DispatchPnDTripRqst,
  DispatchTrip,
  ListPnDDispatchTripsPath,
  ListPnDDispatchTripsQuery,
  ListPnDDispatchTripsResp,
  ListPnDRoutesByGeofenceResp,
  ListPnDRoutesByGeofenceRqst,
  Route,
  UpdateTripStatusPath,
  UpdateTripStatusRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { LatLong, TripStatusCd } from '@xpo-ltl/sdk-common';
import { DispatcherTripsGridItem } from 'app/inbound-planning/components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { PndDialogService } from 'core/dialogs/pnd-dialog.service';
import { NotificationMessageStatus } from 'core/enums/notification-message-status.enum';
import { NotificationMessageService } from 'core/services/notification-message.service';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { DispatcherTripsSearchCriteria } from '../../../store/dispatcher-trips-store/dispatcher-trips-search-criteria.interface';
import { DispatchTripsGridItemConverterService } from './dispatch-trips-grid-item-converter.service/dispatch-trips-grid-item-converter.service';
import { DriverContactsService } from './driver-contacts.service';

@Injectable({
  providedIn: 'root',
})
export class DispatcherTripsService {
  // cache of all TripDetails from the last search
  private readonly tripsSubject = new BehaviorSubject<DispatchTrip[]>([]);
  readonly trips$ = this.tripsSubject.asObservable();

  // cache of all TripDetails from the last search
  private readonly searchResultsSubject = new BehaviorSubject<(DispatchTrip | Route)[]>([]);
  readonly searchResults$ = this.searchResultsSubject.asObservable();

  // cache of all DockRoutes from the last search
  private readonly dockRoutesSubject = new BehaviorSubject<Route[]>([]);
  readonly dockRoutes$ = this.dockRoutesSubject.asObservable();

  // return the current list of Trips
  get trips(): DispatchTrip[] {
    return this.tripsSubject.value;
  }

  get dockRoutes(): Route[] {
    return this.dockRoutesSubject.value;
  }

  // emits true when loading Trips, false when done loading
  private loadingStopsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingStops$ = this.loadingStopsSubject.asObservable();

  private readonly dispatchCompletedSubject = new BehaviorSubject<boolean>(false);
  readonly dispatchComplete$ = this.dispatchCompletedSubject.asObservable();

  constructor(
    private cityOperationsApiService: CityOperationsApiService,
    private notificationMessageService: NotificationMessageService,
    private dialogService: PndDialogService,
    private dispatchTripsGridItemConverterService: DispatchTripsGridItemConverterService,
    private driverContactsService: DriverContactsService,
    private pluralPipe: PluralPipe
  ) {}

  /**
   * Fetch the Dispatch Trips that match the specified Criteria
   */
  fetchTrips$(criteria: DispatcherTripsSearchCriteria): Observable<(DispatchTrip | Route)[]> {
    if (!criteria.sicCd) {
      // no Sic set, so can't return any trips
      return of([]);
    } else {
      // Refresh the avaiable driver contacts for messaging
      this.driverContactsService.refreshContacts(criteria);

      const path: ListPnDDispatchTripsPath = {
        sicCd: criteria.sicCd,
      };
      const query: ListPnDDispatchTripsQuery = {
        ...new ListPnDDispatchTripsQuery(),

        zoneIndicatorCd: criteria.zoneIndicatorCd,
        tripStatusCds: [...(criteria.tripStatusCd || [])],
        tripDate: criteria.tripDate,
        groupIds: criteria.groupIds,
      };
      this.loadingStopsSubject.next(true);
      return this.cityOperationsApiService.listPnDDispatchTrips(path, query).pipe(
        catchError((error) => {
          console.error('ERROR: DispatchTripsService.fetchTrips: ', error);
          return of(undefined);
        }),
        tap((response: ListPnDDispatchTripsResp) => {
          this.tripsSubject.next(response?.dispatchTrips ?? []);
          this.dockRoutesSubject.next(response?.dockRoutes ?? []);
        }),
        map((response: ListPnDDispatchTripsResp) => {
          const searchResults: (DispatchTrip | Route)[] = [];

          if (response?.dispatchTrips) {
            searchResults.push(...response.dispatchTrips);
          }
          if (response?.dockRoutes) {
            searchResults.push(...response.dockRoutes);
          }

          this.searchResultsSubject.next(searchResults);
          this.tripsSubject.next(response?.dispatchTrips ?? []);

          return searchResults;
        }),
        finalize(() => {
          this.loadingStopsSubject.next(false);
        })
      );
    }
  }

  /**
   * Delete the specified Trip
   */
  deleteTrip$(tripInstId: number): Observable<boolean> {
    const pathParams: DeletePnDTripPath = { ...new DeletePnDTripPath(), tripInstId: tripInstId };
    return this.cityOperationsApiService.deletePnDTrip(pathParams).pipe(
      switchMap(() => {
        return this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Success, 'Trip deleted.')
          .pipe(mapTo(true));
      }),
      catchError((error) => {
        return this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .pipe(mapTo(false));
      })
    );
  }

  /**
   * Set trip to complete status (only valid for returning trips)
   */
  completeTrip$(tripInstId: number, completedOnDateTime: Date): Observable<boolean> {
    const request = {
      ...new CompleteTripRqst(),
      invokeFromHandheldInd: false,
      tripCompleteDateTime: completedOnDateTime,
    };
    const path = { ...new CompleteTripPath(), tripInstId: tripInstId };
    return this.cityOperationsApiService.completeTrip(request, path).pipe(
      switchMap(() => {
        return this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Success, 'Trip status set to Completed.')
          .pipe(mapTo(true));
      }),
      catchError((error) => {
        this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe();
        return of(false);
      })
    );
  }

  /**
   * Set trip to returning status (only valid for dispatched trips)
   */
  returnTrip$(tripInstId: number, estimatedClearTime: Date): Observable<boolean> {
    const request = {
      ...new UpdateTripStatusRqst(),
      tripStatusCd: TripStatusCd.RETURNING,
      estimatedArriveDateTime: estimatedClearTime,
    };
    const path = { ...new UpdateTripStatusPath(), tripInstId: tripInstId };
    return this.cityOperationsApiService.updateTripStatus(request, path).pipe(
      switchMap(() => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Success, 'Trip status set to Returning.')
          .subscribe();
        return of(true);
      }),
      catchError((error) => {
        this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe();
        return of(false);
      })
    );
  }

  /**
   * Dispatch a trip currently in New status
   */
  dispatchTrip$(tripInstId: number, estimatedStartTime, overrideDsrLicenseInd: boolean = false): Observable<boolean> {
    const request = {
      ...new DispatchPnDTripRqst(),
      actualActivityDateTime: estimatedStartTime,
      equipmentCheckInd: true,
      overrideDsrLicenseInd: overrideDsrLicenseInd,
    };
    const path = { ...new DispatchPnDTripPath() };
    path.tripInstId = tripInstId;

    return this.cityOperationsApiService.dispatchPnDTrip(request, path).pipe(
      switchMap((resp: DispatchPnDTripResp) => {
        if (resp && resp.warnings && resp.warnings[0]) {
          const msg = resp.warnings[0]?.message ?? '';
          return this.dialogService.showConfirmCancelDialog('', msg, 'no', 'yes').pipe(
            switchMap((result) => {
              if (result) {
                return this.dispatchTrip$(tripInstId, estimatedStartTime, true);
              } else {
                return of(false);
              }
            })
          );
        } else {
          this.dispatchCompletedSubject.next(true);
          return this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Success, 'Trip dispatched.')
            .pipe(mapTo(true));
        }
      }),
      catchError((error) => {
        this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe();
        return of(false);
      })
    );
  }

  /**
   * Before Dispatch, check whether the trip is today dated or not
   */
  isTodaysDateTrip$ = (
    tripDate: string,
    timezone$: Observable<string>
  ): Observable<{ isTodaysDayTrip: boolean; days: number }> => {
    let isTodaysDayTrip: boolean = false;
    let days: number = 0;

    return timezone$.pipe(
      map((timezone) => {
        const nowAsString = moment()
          .tz(timezone)
          .format('YYYY-MM-DD');

        if (moment(nowAsString).isSame(tripDate)) {
          isTodaysDayTrip = true;
          days = 0;
        } else {
          if (moment(tripDate).isAfter(nowAsString)) {
            days = moment(tripDate).diff(nowAsString, 'days');
          }
        }

        return { isTodaysDayTrip, days };
      })
    );
  };

  confirmIsFutureOrPastTripDispatch$ = (tripDate: string, futureDaysCount: number): Observable<boolean> => {
    let warningMsg: string;
    const formattedDate = moment(tripDate).format('MM/DD/YYYY');
    const pluralResult = this.pluralPipe.transform(futureDaysCount, 'day', 'days');

    if (futureDaysCount > 0) {
      warningMsg = `This trip is planned for ${formattedDate} in ${futureDaysCount} ${pluralResult}. <br> If you dispatch, this trip will dispatch for today’s date.`;
    } else {
      warningMsg = `This trip was planned for ${formattedDate}. <br> If you dispatch, this trip will dispatch for today’s date.`;
    }

    return this.dialogService
      .showConfirmCancelDialog('Do you want to dispatch this trip for Today?', warningMsg, 'Cancel', 'Dispatch')
      .pipe(
        map((result) => {
          return result;
        })
      );
  };

  /**
   * Request trips located withing the specified polygon
   */
  fetchDispatcherTripGridItemsByGeofence(
    latLngArray: LatLong[],
    hostDestinationSicCds: string[],
    planDate: Date,
    sicCd: string
  ): Observable<DispatcherTripsGridItem[]> {
    if (latLngArray.length <= 0) {
      return of([]);
    } else {
      const request: ListPnDRoutesByGeofenceRqst = {
        geofences: latLngArray,
        hostDestinationSicCds: hostDestinationSicCds,
        planDate: moment(planDate).format('YYYY-MM-DD'),
      };

      return this.cityOperationsApiService.listPnDRoutesByGeofence(request).pipe(
        catchError(() => of([])),
        map((response: ListPnDRoutesByGeofenceResp) => {
          if (response?.routeInstIds.length > 0 || response?.tripInstIds.length > 0) {
            const trips = this.trips;
            const dispatcherTrips: DispatcherTripsGridItem[] = this.dispatchTripsGridItemConverterService.getDispatchTripsGridItems(
              sicCd,
              trips
            );

            const dispatchtripsToSelect: DispatcherTripsGridItem[] = dispatcherTrips.filter(
              (tripItem: DispatcherTripsGridItem) =>
                response.tripInstIds.includes(tripItem.tripInstId) && tripItem.tripInstId !== 0
            );

            const dispatchRoutesToSelect: DispatcherTripsGridItem[] = this.fetchDispatcherTripsFromGivenRouteInstIds(
              dispatcherTrips,
              response.routeInstIds
            );

            const selectedTrips = [...dispatchtripsToSelect, ...dispatchRoutesToSelect];

            return selectedTrips;
          } else {
            return [];
          }
        })
      );
    }
  }

  /**
   * Fetch the dispatcher trips according to the routeInstIds
   * @param dispatcherTrips DispatcherTripsGridItem
   * @param routeInstIds number
   * @returns match trips with routeInstIds
   */
  private fetchDispatcherTripsFromGivenRouteInstIds(
    dispatcherTrips: DispatcherTripsGridItem[],
    routeInstIds: number[]
  ): DispatcherTripsGridItem[] {
    const dispatcheroutesToSelect: DispatcherTripsGridItem[] = [];

    dispatcherTrips?.forEach((item: DispatcherTripsGridItem) => {
      item?.dispatchTrip?.dispatchRoutes?.forEach((dispatchRoute) => {
        routeInstIds?.forEach((routeInstId) => {
          if (dispatchRoute.routeInstId === routeInstId) {
            dispatcheroutesToSelect.push(item);
          }
        });
      });
    });
    return dispatcheroutesToSelect;
  }
}
