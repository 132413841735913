import { Stop } from '@xpo-ltl/sdk-cityoperations';
import { RouteStopsGridItem } from '../../inbound-planning/components/route-stops';
import { TripPlanningGridItem } from '../../inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import {
  AssignedStopIdentifier,
  EventItem,
  RouteItemIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { NumberToValueMap } from '../number-to-value-map';
import { TripsSearchCriteria } from './trips-search-criteria.interface';

export const StateKey = 'tripsState';

export interface State {
  readonly criteria: TripsSearchCriteria;
  readonly lastUpdate: Date;
  readonly selectedTrips: TripPlanningGridItem[];

  readonly gridScrollPosition: number;

  readonly selectedRoutes: RouteItemIdentifier[];
  readonly clickedRouteStop: RouteStopsGridItem;

  readonly focusedRoute: EventItem<RouteItemIdentifier>;
  readonly focusedStopForSelectedRoute: EventItem<AssignedStopIdentifier>;

  readonly stopsForSelectedRoutes: NumberToValueMap<Stop[]>;
  readonly selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[];

  readonly showCompletedStops: boolean;
}

export const initialState: State = {
  criteria: {},
  lastUpdate: new Date(0),
  selectedTrips: [],

  gridScrollPosition: 0,

  selectedRoutes: [],
  clickedRouteStop: undefined,
  stopsForSelectedRoutes: {},
  selectedStopsForSelectedRoutes: [],

  focusedRoute: { id: undefined, source: undefined },
  focusedStopForSelectedRoute: { id: undefined, source: undefined },

  showCompletedStops: true,
};
