import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ComponentChangeUtils } from '../../classes/component-change-utils';
import { Note } from './../../models/note.model';

@Component({
  selector: 'pnd-notes-cell-renderer',
  templateUrl: './notes-cell-renderer.component.html',
  styleUrls: ['./notes-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pnd_NotesCellRenderer',
  },
})
export class NotesCellRendererComponent implements ICellRendererAngularComp {
  note: Note;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: ICellRendererParams): void {
    this.note = params?.value;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
  }

  refresh(params: ICellRendererParams): boolean {
    this.note = params?.value;
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    return true;
  }
}
