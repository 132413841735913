import { Injectable } from '@angular/core';
import { TripSummary, TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StagedTripsService {
  constructor() {}

  stagedTripsMap: Map<number, { tripSummary: TripSummary; tripDetail: TripDetail }> = new Map<
    number,
    { tripSummary: TripSummary; tripDetail: TripDetail }
  >();

  get stagedTripsMap$(): Observable<Map<number, { tripSummary: TripSummary; tripDetail: TripDetail }>> {
    return this.stagedTripsMapSubject.asObservable();
  }

  stagedTripsMapSubject = new BehaviorSubject<Map<number, { tripSummary: TripSummary; tripDetail: TripDetail }>>(
    this.stagedTripsMap
  );
}
