export const COLUMN_FILTER_MENU_WIDTH: number = 35;

export const TOTALS_COLUMN_WIDTH: number = 65;
export const CUSTOMERS_AT_DOCK = 'Customers at Dock';
export const DOCK_DROP = 'DROP';
export const DOCK_PICKUP = 'PKUP';
export const MIXED_STOP = 'MX';

/// Placeholder Id given for all unbilled shipments in Unassigned Deliveries
export const UNBILLED_SHIPMENT_CONSIGNEE_ACCTINSTID = 2915;
