export enum TripFormFields {
  TRIP_INST_ID = 'tripInstId',
  CARRIER_ID = 'cmsCarrierId',

  // Trip Date & Time Section
  TRIP_DATE = 'tripDate',
  TRIP_STATUS = 'tripStatus',
  SIC = 'sic',
  EST_START = 'estStart',
  ACTUAL_START = 'actualStart',
  EST_CLEAR = 'estClear',
  EST_COMPLETE = 'estComplete',
  ACTUAL_COMPLETE = 'actualComplete',
  DISPATCH_AREA = 'dispatchArea',

  // Route & Equipment Section
  IS_NEW_ROUTE = 'isNewRoute',
  IS_IN_EDIT_MODE = 'isInEditMode',
  ROUTE_NAME = 'routeName',
  PREFIX = 'prefix',
  SUFFIX = 'suffix',
  TRAILER = 'trailer',
  DOLLY = 'dolly',
  LOAD_DOOR = 'loadDoor',
  UNLOAD_DOOR = 'unloadDoor',
  // Driver & Tractor Section
  AUTO_DISPATCH = 'autoDispatch',
  DRIVER = 'driverName',
  TRACTOR = 'tractor',
  IS_STRAIGHT_TRUCK = 'isStraightTruck',
  REMARKS = 'remarks',

  // Routes & Equipment Array
  ROUTES_AND_EQUIPMENT = 'routesAndEquipments',

  // trailer status not displayed on the form
  CURRENT_STATUS = 'currentStatus',
}
