import { Action } from '@ngrx/store';
import { ModifyTripActivityId } from './modify-trip-details.state';

export enum ActionTypes {
  setLastUpdate = 'modifyTripDetails.setLastUpdate',
  setSelectedActivities = 'modifyTripDetails.setSelectedActivities',
  refresh = 'modifyTripDetails.refresh',
}

export class SetSelectedActivities implements Action {
  readonly type = ActionTypes.setSelectedActivities;
  constructor(public payload: { selectedActivities: ModifyTripActivityId[] }) {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { lastUpdate: Date }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions = SetSelectedActivities | SetLastUpdate | Refresh;
