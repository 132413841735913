import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { PlanningProfilesStoreActions } from '../planning-profiles-store';
import { ActionTypes, SetGeoFilterArea, SetSic } from './global-filters-store.actions';

@Injectable()
export class GlobalFiltersStoreEffects {
  constructor(private actions$: Actions) {}

  // Clear bounds and selections when SIC changes
  @Effect()
  setSic$: Observable<Action> = this.actions$.pipe(
    ofType<SetSic>(ActionTypes.setSic),
    concatMap(() => {
      return [
        new SetGeoFilterArea({ geoFilterArea: [] }),
        new PlanningProfilesStoreActions.SetPlanningProfilesStoreAction({ planningProfiles: [] }),
        new PlanningProfilesStoreActions.LoadPlanningProfilesAction(),
      ];
    })
  );
}
