import { Action } from '@ngrx/store';
import { EventItem, PinnedStopIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { ResequencingRouteDataMap } from './route-balancing-types';

export enum ActionTypes {
  SetOpenRouteBalancingPanel = 'routeBalancing.setOpenRouteBalancingPanel',
  SetOpenMergeModePanel = 'routeBalancing.setOpenMergeModePanel',
  SetManualSequencingRoutes = 'routeBalancing.setManualSequencingRoutes',
  SetPinFirst = 'routeBalancing.setPinFirst',
  SetPinLast = 'routeBalancing.setPinLast',
  SetCanOpenRouteBalancing = 'routeBalancing.setCanOpenRouteBalancing',
  setResequencedRouteData = 'routeBalancing.setResequencedRouteData',
}

export class SetOpenRouteBalancingPanel implements Action {
  readonly type = ActionTypes.SetOpenRouteBalancingPanel;
  constructor(public payload: { openRouteBalancingPanel: boolean }) {}
}

export class SetOpenMergeModePanel implements Action {
  readonly type = ActionTypes.SetOpenMergeModePanel;
  constructor(public payload: { openMergeModePanel: boolean }) {}
}

export class SetManualSequencingRoutes implements Action {
  readonly type = ActionTypes.SetManualSequencingRoutes;
  constructor(public payload: { routes: number[] }) {}
}

export class SetPinFirst implements Action {
  readonly type = ActionTypes.SetPinFirst;
  constructor(public payload: { pinFirst: EventItem<PinnedStopIdentifier> }) {}
}

export class SetPinLast implements Action {
  readonly type = ActionTypes.SetPinLast;
  constructor(public payload: { pinLast: EventItem<PinnedStopIdentifier> }) {}
}

export class SetCanOpenRouteBalancing implements Action {
  readonly type = ActionTypes.SetCanOpenRouteBalancing;
  constructor(public payload: { canOpenRouteBalancing: boolean }) {}
}

export class SetResequencedRouteData implements Action {
  readonly type = ActionTypes.setResequencedRouteData;
  constructor(public payload: { resequenceData: ResequencingRouteDataMap }) {}
}

export type Actions =
  | SetOpenRouteBalancingPanel
  | SetOpenMergeModePanel
  | SetManualSequencingRoutes
  | SetPinFirst
  | SetPinLast
  | SetCanOpenRouteBalancing
  | SetResequencedRouteData;
