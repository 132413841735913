import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationMessageService } from '../../../../../core/services/notification-message.service';

export interface PdfViewerData {
  title?: string;
  retrieveContent$: Observable<Uint8Array[]>;
}

@Component({
  selector: 'pnd-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  host: { class: 'pnd-PdfViewer' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PdfViewerData,
    private dialogRef: MatDialogRef<PdfViewerComponent>,
    private notificationMessageService: NotificationMessageService
  ) {}

  readonly LoadingMessage: string = 'Please wait, loading document...';
  private displayMessageSubject = new BehaviorSubject<string>(null);
  readonly displayMessage$ = this.displayMessageSubject.asObservable();

  private pdfContentSubject = new BehaviorSubject<Uint8Array[]>([]);
  readonly pdfContent$ = this.pdfContentSubject.asObservable();

  private spinnerSubject = new BehaviorSubject<boolean>(false);
  readonly spinner$ = this.spinnerSubject.asObservable();
  trackContentBy(index, content: Uint8Array): number {
    return index;
  }
  ngAfterViewInit() {
    this.spinnerSubject.next(true);
    this.data.retrieveContent$.pipe(take(1)).subscribe(
      (content: Uint8Array[]) => {
        this.pdfContentSubject.next(content);
        this.spinnerSubject.next(false);
      },
      (error) => {
        this.spinnerSubject.next(false);
        this.displayMessageSubject.next(
          `Failed to load document; ${this.notificationMessageService.parseErrorMessage(error)}.`
        );
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
