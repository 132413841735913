import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { size as _size } from 'lodash';
import moment from 'moment';
import { timer } from 'rxjs';
import { EditorKeys } from '../../classes/editor-keys';
import { TimeUtil } from '../../services/time-format.util';

@Component({
  selector: 'pnd-time-editor',
  templateUrl: './time-editor.component.html',
  styleUrls: ['./time-editor.component.scss'],
})
export class TimeEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params;
  value: string;
  maxLength: number;

  @ViewChild('input') input: ElementRef;

  ngAfterViewInit() {
    timer().subscribe(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, _size(this.value));
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxLength = this.params.maxLength;
  }

  getValue(): any {
    return this.sanitizeValueSetter(this.value);
  }

  onKeyDown(event): void {
    if (!this.isEventKey(event.key) && !this.isKeyValidNumeric(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  onFocusOut(e): void {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  private sanitizeValueSetter(value): string {
    const date = moment(TimeUtil.formatStringToTimeStringByFillingWithZeros(value), 'HH:mm');
    return date.isValid() ? date.format('HH:mm') : value;
  }

  private isKeyValidNumeric(value): boolean {
    return /\d|:/.test(value);
  }

  private isEventKey(key): boolean {
    return key === EditorKeys.BACKSPACE || key === EditorKeys.DELETE || key === EditorKeys.TAB;
  }
}
