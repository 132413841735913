import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ShipmentReferenceService, Unsubscriber, XpoLtlWindowService } from '@xpo-ltl/ngx-ltl';
import { DocumentSearch } from '@xpo-ltl/sdk-documentmanagement';
import { forEach as _forEach } from 'lodash';

@Component({
  selector: 'document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentViewerComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  proNbr: string;
  shipment$ = this.shipmentReferenceService.shipment$;

  constructor(private shipmentReferenceService: ShipmentReferenceService, private windowService: XpoLtlWindowService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  displayDocuments(documents: DocumentSearch[]) {
    _forEach(documents, (doc: DocumentSearch) => {
      const url = `docview/${doc.cdt.docClass}/${doc.cdt.timestamp}`;
      this.windowService.openWindow(url);
    });
  }
}
