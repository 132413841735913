// Define various Board sorces for state changes
export enum BoardStatesEnum {
  REDUX_STORE = 'REDUX-STORE-CHANGE', // not a board state, but useful as a source
  EXTERNAL_DATA_REFRESH = 'EXTERNAL-DATA-REFRESH', // not a board state, but useful as a source
  SHOW_GLOBAL_FILTERS = 'SHOW-GLOBAL-FILTERS', // not a board state, but needed to display older style global filters
  SORT_CHANGE = 'SORT-CHANGE',
  FILTER_CHANGE = 'FILTER-CHANGE',
  BOARD_DATA_FETCHER = 'BOARD DATA FETCHER',
  ACTIVE_VIEW_CHANGE = 'ACTIVE-VIEW-CHANGE',
  BOARD_ACTIVATING_VIEW = 'BOARD-ACTIVATING-VIEW',
  ADD_NEW_VIEW = 'ADD-NEW-VIEW',
  SELECTION_CHANGE = 'SELECTION-CHANGE',
  SAVE_VIEW = 'SAVE-VIEW',
  SAVE_VIEW_AS = 'SAVE-VIEW-AS',
  DATA_SOURCE_REFRESH = 'DATA SOURCE REFRESH',
  SET_VISIBLE_COLUMNS = 'SET-VISIBLE-COLUMNS',
  INITIALIZE_VIEW = 'INITIALIZE-VIEW',
  CLEAR_SELECTION = 'CLEAR-SELECTION',
  GLOBAL_SEARCH = 'GLOBAL-SEARCH',
  SET_SELECTION = 'SET-SELECTION',
  AVAILABLE_VIEWS_DELETE_VIEW = 'AVAILABLE-VIEWS-DELETE-VIEW',
  AVAILABLE_VIEWS_SAVE_VIEW = 'AVAILABLE-VIEWS-SAVE-VIEW',
  GRID_SETTINGS_UPDATE = 'GRID-SETTINGS-UPDATE',
  UPDATE_ROW_COUNT = 'UPDATE-ROW-COUNT',
  COLUMN_FILTER_CHANGE = 'COLUMN-FILTER-CHANGE',
}
