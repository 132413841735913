import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, RowNode } from 'ag-grid-community';
import { ActionsCellRendererParams } from './interfaces/actions-cell-renderer-params.interface';
import { CellRendererActionButton } from './interfaces/cell-renderer-action-button.interface';
import { CellRendererActionMenuOption } from './interfaces/cell-renderer-action-menu-option.interface';
import { CellRendererActionMenu } from './interfaces/cell-renderer-action-menu.interface';

@Component({
  selector: 'pnd-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.html',
  styleUrls: ['./actions-cell-renderer.scss'],
  host: { class: 'pnd-actionsCellRenderer' },
  encapsulation: ViewEncapsulation.None,
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
  menus: CellRendererActionMenu[];
  actions: CellRendererActionButton[];

  shouldHide: boolean;
  shouldDisable = false;

  gridApi: GridApi;
  rowIndex: number;
  rowNode: RowNode;

  data: any;

  stopExceptionInd: boolean = false;

  agInit(params: ActionsCellRendererParams): void {
    this.stopExceptionInd = params?.stopExceptionsInd(params);

    this.menus = params.menus;
    this.actions = params.actions;

    this.shouldHide = params.shouldHide(params);
    if (this.actions[0].shouldDisable) {
      this.actions[0].shouldDisable(params).subscribe((shouldDisable) => {
        this.shouldDisable = shouldDisable;
      });
    }

    this.gridApi = params.api;
    this.rowIndex = params.rowIndex;
    this.rowNode = params.node;

    this.data = params.data;
  }

  refresh(params: ActionsCellRendererParams): boolean {
    this.agInit(params);
    return true;
  }
  trackOptionsBy(index, option: CellRendererActionMenuOption): string | null {
    if (!option) {
      return null;
    }
    return option?.text + index;
  }
  trackActionsBy(index, action: CellRendererActionMenu): number {
    return index;
  }
  trackMenuBy(index, menu: CellRendererActionMenu): number {
    return index;
  }
  stopEventPropagation(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }
}
