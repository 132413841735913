import { ConditioningService } from '@xpo-ltl/common-services';
import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { PickupStatusCd, TripNodeActivityCd, GeoCoordinates } from '@xpo-ltl/sdk-common';
import { MapUtils } from '../../classes/map-utils';
import { DeliveryWindowService } from '../../services/delivery-window.service';
import { EtaWindowService } from '../../services/eta-window.service';
import { MapMarkersService } from '../../services/map-markers.service';
import { PICKUP_BORDER, PICKUP_COLOR } from '../../services/stop-colors';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo } from '../map-marker-info.model';
import { ClusterableMarker } from './clusterable-marker';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class UnassignedPickupMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> implements ClusterableMarker {
  readonly pickup: UnassignedPickup;
  infoWindowTopOffset: number = 0;
  infoWindowLeftOffset: number = 0;
  infoWindowSpaceBetweenMarker: number = 0;

  get mappable(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  constructor(
    pickup: UnassignedPickup,
    private mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private etaWindowService: EtaWindowService,
    private deliveryWindowService: DeliveryWindowService,
    private conditioningService: ConditioningService
  ) {
    super();

    this.specialServices = pickup.specialServiceSummary;
    this.pickup = pickup;
    this.isPreAssigned = this.pickup?.pickupHeader?.header?.statusCd === PickupStatusCd.PRE_ASSIGNED;

    if (pickup?.pickupHeader?.shipper) {
      const geoCoordinates: GeoCoordinates = MapUtils.getCoordinates(pickup.pickupHeader.shipper);

      this.latitude = geoCoordinates.lat;
      this.longitude = geoCoordinates.lon;
    }

    // TODO - MapMarkerInfo uses the stopType to recreate the icon for the marker.  This is BAD since it ignores
    // any color (and other data) set on the initial marker creation.
    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      this.etaWindowService,
      this.deliveryWindowService,
      this.conditioningService,
      {
        ...pickup,
        stopType: TripNodeActivityCd.PICKUP_SHIPMENTS,
        pickupTypeCd: pickup.pickupHeader.pickupTypeCd,
        color: PICKUP_COLOR,
        textAndBorderColor: PICKUP_BORDER,
      },
      undefined,
      undefined
    );

    // for pickups, specialServiceSummary is already ShipmentSpecialServiceCd[]
    this.markerInfo.specialServices = pickup.specialServiceSummary;
  }

  async createIcon(): Promise<UnassignedPickupMapMarker> {
    this.icon = await this.mapMarkerService.getMarkerIconUnassigned(
      this.pickup.pickupHeader.header.pickupTypeCd,
      10,
      false,
      PICKUP_COLOR,
      PICKUP_BORDER,
      undefined,
      this.specialServices,
      this.markerInfo?.specialServiceMarks,
      this.isPreAssigned
    );

    return this;
  }
}
