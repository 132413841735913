export enum UnassignedPickupsDetailGridHeaders {
  ROW_SELECTED = '',
  EXTRA = '',
  BOL = 'BOL',
  PRO_NBR = 'PRO',
  ZIP_CODE = 'Zip',
  REMARKS = 'Remarks',
  WEIGHT = 'Weight',
  PALLETS = 'Pallets',
  PIECES = 'Pieces',
  CUBE = 'Cube',
  MM = 'MM',
  DEST_SIC = 'Dest. SIC',
  SPECIAL_SERVICES = 'Special Services',
  NOTES = 'NOTES AND COMMENTS',
}
