import { Injectable } from '@angular/core';
import { PositioningPoint, Route } from '@xpo-ltl/sdk-cityoperations';
import { unionBy as _unionBy, filter as _filter, find as _find, maxBy as _maxBy, minBy as _minBy } from 'lodash';

import { BehaviorSubject } from 'rxjs';
import { PickupClusterPoint } from '../../../components/planning-map/layers/pickup-positioning-points-layer/pickup-positioning-points-layer.component';
import { MapMarkersService } from '../map-markers.service';

@Injectable({
  providedIn: 'root',
})
export class PickupClusterPointsService {
  private readonly pickupClustersRoutesMapSubject = new BehaviorSubject<Map<number, Route[]>>(new Map());
  readonly pickupClustersRoutesMap$ = this.pickupClustersRoutesMapSubject.asObservable();

  private readonly positioningPointsSubject = new BehaviorSubject<PositioningPoint[]>([]);
  readonly positioningPoints$ = this.positioningPointsSubject.asObservable();

  private readonly focusedPositioningPointSubject = new BehaviorSubject<PickupClusterPoint>(undefined);
  readonly focusedPositioningPoint$ = this.focusedPositioningPointSubject.asObservable();

  constructor(private markersService: MapMarkersService) {}

  get pickupClustersRoutesMap(): Map<number, Route[]> {
    return this.pickupClustersRoutesMapSubject.value;
  }

  resetPickupClustersRoutesMap(): void {
    this.pickupClustersRoutesMapSubject.next(new Map<number, Route[]>());
  }

  pinPickupClusterLastToRoute(point: PositioningPoint, routeToAssign: Route): void {
    const routeAlreadyAssignedToPoint: PositioningPoint = this.getPositionPointforRouteInstId(
      routeToAssign.routeInstId
    );
    if (routeAlreadyAssignedToPoint) {
      this.removePickupClusterFromRoute(routeAlreadyAssignedToPoint, routeToAssign);
    }

    const pickupClustersRoutesMap = this.pickupClustersRoutesMap;
    const existingRoutes = pickupClustersRoutesMap.get(point.positioningPointId);
    const routesArray: Route[] = _unionBy(existingRoutes, [routeToAssign], 'routeInstId');
    pickupClustersRoutesMap.set(point.positioningPointId, routesArray);
    this.pickupClustersRoutesMapSubject.next(pickupClustersRoutesMap);
  }

  removePickupClusterFromRoute(point: PositioningPoint, routeToRemove: Route): void {
    const pickupClustersRoutesMap = this.pickupClustersRoutesMap;
    const existingRoutes = pickupClustersRoutesMap.get(point.positioningPointId);
    const routesArray: Route[] = _filter(existingRoutes, (route) => {
      return route.routeInstId !== routeToRemove.routeInstId;
    });
    pickupClustersRoutesMap.set(point.positioningPointId, routesArray);
    this.pickupClustersRoutesMapSubject.next(pickupClustersRoutesMap);
  }

  transformToPickupClusterPoints(positioningPoints: PositioningPoint[]): PickupClusterPoint[] {
    return positioningPoints.map((point: PositioningPoint) => {
      const infoWindowPosLatLng = google.maps.geometry.spherical.computeOffset(
        new google.maps.LatLng(point.latitudeNbr, point.longitudeNbr),
        this.calculateRadius(point, positioningPoints),
        0
      );

      return {
        pointData: point,
        radius: this.calculateRadius(point, positioningPoints),
        fillColor: '#333',
        strokeColor: '#333',
        strokeWeight: 1,
        opacity: 0.2,
        openInfoWindow: false,

        assignedRoutes: [],

        infoWindowPos: { lat: infoWindowPosLatLng.lat(), lng: infoWindowPosLatLng.lng() },
      } as PickupClusterPoint;
    });
  }

  private calculateRadius(point: PositioningPoint, positioningPoints: PositioningPoint[]): number {
    const minPoint = _minBy(positioningPoints, (positionPoint) => positionPoint.motorizedPiecesCount);
    const maxPoint = _maxBy(positioningPoints, (positionPoint) => positionPoint.motorizedPiecesCount);
    const current = point.motorizedPiecesCount;
    const radius = this.convertRange(
      current,
      [minPoint.motorizedPiecesCount, maxPoint.motorizedPiecesCount],
      [minPoint.motorizedPiecesCount * 100, 4000]
    );
    return radius; // in metres
  }

  private convertRange(value: number, r1: number[], r2: number[]): number {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
  }

  updatePositioningPoints(points: PositioningPoint[]): void {
    this.positioningPointsSubject.next(points);
  }

  getPositionPointforRouteInstId(routeInstId): PositioningPoint {
    let pointId: number;
    this.pickupClustersRoutesMap.forEach((routes: Route[], positionPointId: number) => {
      if (_find(routes, (route) => route.routeInstId === routeInstId)) {
        pointId = positionPointId;
      }
    });
    return this.getPositionPointById(pointId);
  }

  getPositionPointById(pointId): PositioningPoint {
    const points = this.positioningPointsSubject.value;
    let result: PositioningPoint;
    points.forEach((point) => {
      if (point.positioningPointId === pointId) {
        result = point;
      }
    });
    return result;
  }

  setFocusedPositionPoint(point: PickupClusterPoint) {
    this.focusedPositioningPointSubject.next(point);
  }
}
