import { Action } from '@ngrx/store';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { EventItem, UnassignedDeliveryIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedDeliveriesSearchCriteria } from './unassigned-deliveries-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'unassignedDeliveries.setSearchCriteria',
  setLastUpdate = 'unassignedDeliveries.setLastUpdate',
  setSelectedDeliveries = 'unassignedDeliveries.setSelectedDeliveries',
  setFocusedDelivery = 'unassignedDeliveries.setFocusedDelivery',
  setClickedDelivery = 'unassignedDeliveries.setClickedDelivery',
  setActiveBoardView = 'unassignedDeliveries.setActiveBoardView',
  setFilteredUnassignedDeliveryIds = 'unassignedDeliveries.setFilteredUnassignedDeliveryIds',
  refresh = 'unassignedDeliveries.refresh',
}

export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: UnassignedDeliveriesSearchCriteria }) {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { lastUpdate: Date }) {}
}

export class SetActiveBoardView implements Action {
  readonly type = ActionTypes.setActiveBoardView;
  constructor(public payload: { viewId: string }) {}
}

export class SetSelectedDeliveries implements Action {
  readonly type = ActionTypes.setSelectedDeliveries;
  constructor(public payload: { selectedDeliveries: EventItem<UnassignedDeliveryIdentifier>[] }) {}
}

export class SetFocusedDelivery implements Action {
  readonly type = ActionTypes.setFocusedDelivery;
  constructor(public payload: { focusedDelivery: EventItem<UnassignedDeliveryIdentifier> }) {}
}

export class SetFilteredUnassignedDeliveryIds implements Action {
  readonly type = ActionTypes.setFilteredUnassignedDeliveryIds;
  constructor(public payload: { filteredUnassignedDeliveryIds: { [key: number]: boolean } }) {}
}

export class SetClickedDelivery implements Action {
  readonly type = ActionTypes.setClickedDelivery;
  constructor(public payload: { clickedDelivery: DeliveryShipmentSearchRecord }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions =
  | SetSearchCriteria
  | SetLastUpdate
  | SetSelectedDeliveries
  | SetFocusedDelivery
  | SetFilteredUnassignedDeliveryIds
  | SetClickedDelivery
  | SetActiveBoardView
  | Refresh;
