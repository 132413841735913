import { Pipe, PipeTransform } from '@angular/core';
import { StopWindowNoteTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'stopWindowNote',
})
export class StopWindowNotePipe implements PipeTransform {
  transform(value: StopWindowNoteTypeCd): string {
    switch (value) {
      case StopWindowNoteTypeCd.FBDS:
        return 'Appointment DSR Note';
      case StopWindowNoteTypeCd.INTERNAL:
        return 'Appointment Internal Note';
      case StopWindowNoteTypeCd.TIME_DATE_CRITICAL:
        return 'TDC Note';
      default:
        return value;
    }
  }
}
