import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'pdoEquipmentStatus',
})
export class PdoEquipmentStatusPipe implements PipeTransform {
  transform(value: any, abbreviated: boolean = false): any {
    switch (value) {
      case EquipmentStatusCd.APPOINTMENT:
      case 'APPT':
        return abbreviated ? 'APPT' : 'Appointment';
      case EquipmentStatusCd.ARRIVAL:
      case 'ARIV':
        return abbreviated ? 'ARIV' : 'Arrive';
      case EquipmentStatusCd.AVAILABLE:
      case 'AVAL':
        return abbreviated ? 'AVAL' : 'Available';
      case EquipmentStatusCd.CITY_ARRIVAL:
      case 'CARV':
        return abbreviated ? 'CARV' : 'City Arrive';
      case EquipmentStatusCd.CITY:
      case 'CITY':
        return abbreviated ? 'CITY' : 'CITY';
      case EquipmentStatusCd.CITY_LOADING_AT_DOCK:
      case 'CLDK':
        return abbreviated ? 'CLDK' : 'City Loading at Dock';
      case EquipmentStatusCd.CLOSED_FOR_DELIVERY:
      case 'CLDV':
        return abbreviated ? 'CLDV' : 'Closed for Delivery';
      case EquipmentStatusCd.CLOSE:
      case 'CLOS':
        return abbreviated ? 'CLOS' : 'Close';
      case EquipmentStatusCd.DELAYED:
      case 'DLYD':
        return abbreviated ? 'DLYD' : 'Delayed';
      case EquipmentStatusCd.DROP:
      case 'DROP':
        return abbreviated ? 'DROP' : 'DROP';
      case EquipmentStatusCd.EMPTY:
      case 'EMTY':
        return abbreviated ? 'EMTY' : 'Empty';
      case EquipmentStatusCd.ENROUTE:
      case 'ENRT':
        return abbreviated ? 'ENRT' : 'Enroute';
      case EquipmentStatusCd.HELD:
      case 'HELD':
        return abbreviated ? 'HELD' : 'Held';
      case EquipmentStatusCd.LOADING_AT_CUSTOMER:
      case 'LDCU':
        return abbreviated ? 'LDCU' : 'Loading at Customer';
      case EquipmentStatusCd.LOADING_AT_DOCK:
      case 'LDDK':
        return abbreviated ? 'LDDK' : 'Loading at Dock';
      case EquipmentStatusCd.OUT_OF_SERVICE:
      case 'OSVC':
        return abbreviated ? 'OSVC' : 'Out Of Service';
      case EquipmentStatusCd.OVERHEAD:
      case 'OVHD':
        return abbreviated ? 'OVHD' : 'Overhead';
      case EquipmentStatusCd.PICKUP_AND_DELIVERY_ROUTE:
      case 'PDRT':
        return abbreviated ? 'PDRT' : 'Pickup and Delivery';
      case EquipmentStatusCd.TRAP:
      case 'TRAP':
        return abbreviated ? 'TRAP' : 'TRAP';
      case EquipmentStatusCd.UNLOADING_AT_CUSTOMER:
      case 'UNLC':
        return abbreviated ? 'UNLC' : 'Unloading at Customer';
      case EquipmentStatusCd.UNLOADING_AT_DOCK:
      case 'UNLD':
        return abbreviated ? 'UNLD' : 'Unloading at Dock';
      default:
        return '';
    }
  }
}
