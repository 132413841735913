import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'pnd-visibility-cell-renderer',
  template: `
    <div style="display: flex; cursor: pointer" *ngIf="!!params?.value">
      <mat-icon [matTooltip]="params?.tooltip || ''" [matTooltipPosition]="params?.tooltipPosition || 'below'"
        >visibility</mat-icon
      >
    </div>
  `,
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisibilityCellRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
