<div class="skeleton">
  <ngx-skeleton-loader
      count="1"
      [theme]="{
      'background-color': '#F1F1F1',
      'margin-bottom': '5px',
      height: '24px',
      width: '50%'
    }"></ngx-skeleton-loader>

  <ngx-skeleton-loader
      count="2"
      [theme]="{
      'background-color': '#F1F1F1',
      'margin-bottom': '0',
      height: '15px'
    }"></ngx-skeleton-loader>
</div>
