import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ModifyTripsDetailsStoreEffects } from './modify-trip-details.effects';
import { modifyTripDetailsReducer } from './modify-trip-details.reducer';
import { StateKey } from './modify-trip-details.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, modifyTripDetailsReducer),
    EffectsModule.forFeature([ModifyTripsDetailsStoreEffects]),
  ],
})
export class ModifyTripDetailsStoreModule {}
