import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatLegacySelect as MatSelect,
  MatLegacySelectChange as MatSelectChange,
} from '@angular/material/legacy-select';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoConfirmDialog } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { GeoLocationService } from 'app/inbound-planning/shared/services/geo-location.service';
import { size as _size } from 'lodash';
import { take, takeUntil } from 'rxjs/operators';

import { LayoutConfig } from '../layout-config.interface';
import { LayoutPreferenceService } from '../services/layout-preference.service';
import { XpoLtlSaveLayoutDialog } from './save-layout-dialog.component';

@Component({
  selector: 'layout-preference-selector',
  templateUrl: './layout-preference-selector.component.html',
  styleUrls: ['./layout-preference-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutPreferenceSelectorComponent implements OnInit, OnDestroy {
  @Input() label: string = 'Layout:';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @ViewChild('selector', { static: true }) matSelector: MatSelect;

  private unsubscriber = new Unsubscriber();

  readonly activeLayout$ = this.layoutPreferenceService.activeLayout$;
  readonly availableLayouts$ = this.layoutPreferenceService.availableLayouts$;

  private isDeleting: boolean = false;

  constructor(
    private layoutPreferenceService: LayoutPreferenceService,
    private dialog: MatDialog,
    private confirmDialog: XpoConfirmDialog,
    private geoLocationService: GeoLocationService
  ) {}

  ngOnInit() {
    this.layoutPreferenceService
      .onViewChange()
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((view) => {
        this.geoLocationService.setAddressUpdatedSubject(undefined, undefined);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  handleSelectionChange(selection: MatSelectChange) {
    if (!this.isDeleting) {
      this.layoutPreferenceService.setActiveLayout(selection.value);
    }
  }

  compareLayoutFn(l1: LayoutConfig, l2: LayoutConfig): boolean {
    return l1 && l2 ? l1.name === l2.name : l1 === l2;
  }

  saveLayout() {
    this.matSelector.close();

    const dialogRef = this.dialog.open(XpoLtlSaveLayoutDialog, {
      width: '500px',
      data: this.layoutPreferenceService.activeLayoutMode,
    });

    dialogRef.afterClosed().subscribe((layoutName: string) => {
      if (_size(layoutName)) {
        this.layoutPreferenceService.saveLayoutAs$(layoutName).subscribe();
      }
    });
  }
  trackItemBy(index, item: LayoutConfig): null | string {
    if (!item) {
      return null;
    }
    return item?.name + index;
  }
  deleteLayout(layoutName: string) {
    this.matSelector.close();
    this.isDeleting = true;

    this.confirmDialog
      .confirm(`Are you sure you want to delete layout '${layoutName}'?`)
      .pipe(take(1))
      .subscribe((result) => {
        this.isDeleting = false;
        if (result) {
          this.layoutPreferenceService.deleteLayout$(layoutName).subscribe(() => {
            // make sure that the activeLayout is selected and displayed
            this.matSelector.value = this.layoutPreferenceService.activeLayout;
          });
        }
      });
  }
}
