import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PriorityCd } from '../../../../../shared/enums/priority.enum';

@Component({
  selector: 'pnd-pickup-date-cell-renderer',
  templateUrl: './pickup-date-cell-renderer.component.html',
  styleUrls: ['./pickup-date-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupDateCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  tooltip: string;

  constructor() {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.setTooltip(params);
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    this.setTooltip(params);
    return true;
  }

  private setTooltip(params: ICellRendererParams) {
    this.tooltip = params?.value?.priority === PriorityCd.PASTDUE ? 'Past Due Pickup' : '';
  }
}
