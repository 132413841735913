export class ModifyTripDetailsRowHeightConfig {
  static readonly INITIAL_ROW_HEIGHT = 30;
  static readonly GROUP_ROW_HEIGHT = 40;
  static readonly HEADER_HEIGHT = 40;

  static readonly DETAIL_MARGIN_TOP = 15;
  static readonly DETAIL_MARGIN_BOTTOM = 15;
  static readonly TOTALS_ROW_HEIGHT = 30;

  static readonly DETAIL_HEADER_OFFSET = 2;
  static readonly DETAIL_ROW_HEIGHT_NOTES_TITLE = 34;
  static readonly DETAIL_ROW_HEIGHT_NOTE_SUBTITLE = 50;
  static readonly DETAIL_ROW_HEIGHT_NOTE_DESCRIPTION = 20;
}
