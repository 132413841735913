import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { RouteBalancingReducer } from './route-balancing.reducer';
import { StateKey } from './route-balancing.state';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(StateKey, RouteBalancingReducer)],
})
export class RouteBalancingStoreModule {}
