import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '@pnd-store/dispatcher-trips-store';
import { WatchedDriver } from '@pnd-store/dispatcher-trips-store/dispatcher-trips-store.state';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { TripDriverLocationsService } from 'app/inbound-planning/components/planning-map/layers/routes-layer/services/trip-driver-locations.service';
import { filter as _filter } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { PndStoreState } from '../../../../store';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

@Component({
  selector: 'app-map-watched-driver',
  templateUrl: './map-watched-driver.component.html',
  styleUrls: ['./map-watched-driver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapWatchedDriverComponent implements OnInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  watchedDrivers: WatchedDriver[] = [];

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private tripDriverLocationsService: TripDriverLocationsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.pndStore$
      .select(DispatcherTripsStoreSelectors.watchedDrivers)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((newWatchedDrivers: WatchedDriver[]) => {
        // Remove expired drivers
        this.watchedDrivers = this.watchedDrivers.filter((d) =>
          newWatchedDrivers.find((n) => n.driverId === d.driverId)
        );

        newWatchedDrivers.forEach((watchedDriver) => {
          const existingDriver = this.watchedDrivers.find((d) => d.driverId === watchedDriver.driverId);

          if (existingDriver) {
            // Update the data of existing objects to prevent the creation of new objects in the DOM
            existingDriver.timerCounter = watchedDriver.timerCounter;
            existingDriver.color = watchedDriver.color;
          } else {
            this.watchedDrivers.push(watchedDriver);
          }
        });
        ComponentChangeUtils.detectChanges(this.changeDetectorRef);
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  addMoreTime(watchedDriver: WatchedDriver) {
    if (this.addMoreTimeEnabled(watchedDriver)) {
      watchedDriver.timerCounter += this.tripDriverLocationsService.onDemandDuration;
      watchedDriver.numberOfAdditions++;

      const newWatchedDrivers = _filter(
        this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers),
        (watchedDrivers) => {
          return watchedDrivers.driverId !== watchedDriver.driverId;
        }
      ).concat(watchedDriver);

      this.pndStore$.dispatch(
        new DispatcherTripsStoreActions.SetWatched({
          watchedDrivers: newWatchedDrivers,
        })
      );
    }
  }

  addMoreTimeEnabled(watchedDriver: WatchedDriver): boolean {
    if (watchedDriver) {
      return watchedDriver.numberOfAdditions < 1;
    }
    return false;
  }
  trackDriverBy(index, driver: WatchedDriver): null | string {
    if (!driver) {
      return null;
    }
    return driver?.driverId + index;
  }
  stopWatch(watchedDriver: WatchedDriver) {
    if (watchedDriver) {
      this.tripDriverLocationsService.stopWatching(watchedDriver.driverId);
    }
  }
}
