import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GeoLocationStoreEffects } from './geo-location-store.effects';
import { geoLocationReducer } from './geo-location-store.reducer';
import { StateKey } from './geo-location-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, geoLocationReducer),
    EffectsModule.forFeature([GeoLocationStoreEffects]),
  ],
})
export class GeoLocationStoreModule {}
