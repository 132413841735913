import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { toNumber as _toNumber } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActionLinkCellRendererParams } from '..';
import { FeatureTypes } from '../../../../../core/services/features/feature-types.enum';
import { CallNbrService } from './../../services/callnbr-service/callnbr.service';

const POLLING_INTERVAL = 60000;

// default time for show pickup new tag, in case didn't set in the SIC
const DEFAULT_NEW_PICKUP_ALERT_MINS = '10';

@Component({
  selector: 'pnd-pickup-call-nbr-cell-renderer',
  templateUrl: './pickup-call-nbr-cell-renderer.component.html',
  styleUrls: ['./pickup-call-nbr-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupCallNbrCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: ActionLinkCellRendererParams;
  onClick?: (data: any) => void;
  pickupCreatedTmst: moment.Moment;
  isNew$: Observable<boolean>;
  isLink: boolean = true;
  private callNbrSubject = new BehaviorSubject<string>(undefined);
  readonly callNbr$ = this.callNbrSubject.asObservable();

  private readonly _stopPolling = new Subject<void>();

  constructor(private featuresService: XpoLtlFeaturesService, private callNbrService: CallNbrService) {}

  agInit(params: ICellRendererParams) {
    this.params = params;
    const callNbr = this.params.data?.callNbr as string;
    if (callNbr) {
      this.callNbrSubject.next(this.callNbrService.getDDNNNFormat(callNbr));
    }
    this.pickupCreatedTmst = moment(params.data?.pickupHeader?.header?.auditInfo?.createdTimestamp ?? 0);
    const newPickupAlert = _toNumber(
      this.featuresService.getFeatureValue(
        params.data?.pickupHeader?.header?.pickupTerminalSicCd ?? '',
        FeatureTypes.NewPickupAlert,
        DEFAULT_NEW_PICKUP_ALERT_MINS
      )
    );
    this.isNew$ = this.isPickupNew(newPickupAlert);
    this.isLink = !!this.params?.onClick;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  ngOnDestroy() {
    this._stopPolling.next();
  }

  onClickFunction(clickEvent: MouseEvent): void {
    if (this.params.onClick) {
      this.params.onClick(this.params.data);
      clickEvent.stopImmediatePropagation();
    }
  }

  private isPickupNew(pickupAsNew: number) {
    return timer(0, POLLING_INTERVAL).pipe(
      map((_) => {
        const isNew = moment().diff(this.pickupCreatedTmst, 'minutes') <= pickupAsNew;
        if (!isNew) {
          this._stopPolling.next();
        }
        return isNew;
      }),
      takeUntil(this._stopPolling)
    );
  }
}
