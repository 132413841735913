export enum DmanFormFields {
  DMAN = 'dman',
  ROUTEINSTID = 'routInstId',
  SEALNO = 'sealNo',
  IS_ALREADY_PRINTED = 'isAlreadyPrinted',
  TRAILER = 'trailer',
  IS_CHECKED = 'isChecked',

  CONSIGNEE = 'consignees',
  CONSIGNEE_NAME = 'consigneeName',
  DMAN_ID = 'dmanId',

  SHIPMENTS = 'shipments',
  PRO = 'pro',
  SHIPPER = 'shipper',
  BILL_NUMBER = 'billNumber',
  PO_PIECES = 'poPieces',
  PO_WEIGHT = 'poWeight',
  PO_NUMBER = 'poNumber',
  BILL_WEIGHT = 'billWeight',
  ROUTE_NAME = 'routeName',
}
