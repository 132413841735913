import { tassign } from 'tassign';
import { Actions, ActionTypes } from './modify-trip-details.actions';
import { initialState, State } from './modify-trip-details.state';

export function modifyTripDetailsReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSelectedActivities:
      return tassign(state, {
        selectedActivities: action.payload.selectedActivities,
      });
    case ActionTypes.setLastUpdate: {
      return tassign(state, {
        lastUpdate: action.payload.lastUpdate,
      });
    }
    default:
      return state;
  }
}
