import { tassign } from 'tassign';
import { Actions, ActionTypes } from './dispatcher-trips-store.actions';
import { initialState, State } from './dispatcher-trips-store.state';

export function dispatcherTripsReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        criteria: action.payload.criteria,
      });
    }
    case ActionTypes.setSelected: {
      return tassign(state, {
        selectedTrips: action.payload.selectedDispatcherTripsIds,
      });
    }
    case ActionTypes.setWatched: {
      return tassign(state, {
        watchedDrivers: action.payload.watchedDrivers,
      });
    }
    case ActionTypes.setLastUpdate: {
      return tassign(state, {
        lastUpdate: action.payload.dispatcherTripsLastUpdate,
      });
    }
    case ActionTypes.setGridScrollPosition: {
      return tassign(state, {
        gridScrollPosition: action.payload.position,
      });
    }
    default: {
      return state;
    }
  }
}
