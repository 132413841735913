import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, UntypedFormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  InfrastructureApiService,
  ListPrintersQuery,
  ListPrintersResp,
  Printer,
} from '@xpo-ltl-2.0/sdk-infrastructure';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  GetServiceCenterPrintPreferencesPath,
  PrintOption,
  GetServiceCenterPrintPreferencesResp,
  UpsertServiceCenterPrintPreferencesRqst,
  UpsertServiceCenterPrintPreferencesPath,
  UpsertServiceCenterPrintPreferencesResp,
} from '@xpo-ltl/sdk-cityoperations';
import { FbdsVersionCd, PnDAutoPrintOptionCd } from '@xpo-ltl/sdk-common';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, take, tap, mapTo, startWith, takeUntil, catchError } from 'rxjs/operators';
import { LanguageTypeEnum } from 'shared/enums/language.enum';
import { AutoCompleteItem } from '../../../app/inbound-planning/shared/components/autocomplete/autocomplete.component';
import { PrintTypeCdPipe } from '../../../app/inbound-planning/shared/pipes/print-type-cd.pipe';
import { NotificationMessageStatus } from '../../enums';
import { NotificationMessageService } from '../../services';
import { PrintOccurrenceEnum } from './print-occurrence.enum';
import { PrintPreferenceFormFields } from './print-preference-form.fields';

export interface PrintPreferenceDialogData {
  sicCd: string;
}

@Component({
  selector: 'pnd-print-preference-dialog',
  templateUrl: './print-preference-dialog.component.html',
  styleUrls: ['./print-preference-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintPreferenceDialogComponent implements OnInit, OnDestroy {
  protected unsubscribe = new Unsubscriber();
  printOption: PrintOption;
  formGroup: UntypedFormGroup;
  printersListFromAPI: string[] = [];
  readonly printerTrayMap = new Map<string, string[]>();
  readonly printPreferenceFormFields = PrintPreferenceFormFields;
  readonly LanguageTypeEnum = LanguageTypeEnum;
  readonly FbdsVersionCd = FbdsVersionCd;
  triggerCdList: AutoCompleteItem[] = [];
  pndFbdsTriggerCdList: AutoCompleteItem[] = [];
  dsrOutboundTriggerCdList: AutoCompleteItem[] = [];

  private readonly activePrintersListSubject = new BehaviorSubject<AutoCompleteItem[]>([]);
  readonly activePrintersList$ = this.activePrintersListSubject.asObservable();

  private readonly isSaveDisabledSubject = new BehaviorSubject<boolean>(true);
  readonly isSaveDisabled$ = this.isSaveDisabledSubject.asObservable();

  activePndTrays$: Observable<AutoCompleteItem[]>;
  activeFbdsTrays$: Observable<AutoCompleteItem[]>;
  activeOutboundTrays$: Observable<AutoCompleteItem[]>;
  activeDsrTrays$: Observable<AutoCompleteItem[]>;

  private readonly isLoadingSubject = new BehaviorSubject(false);
  readonly isLoading$ = this.isLoadingSubject.asObservable();
  readonly sortOrder: boolean = false;
  readonly strictSelection: boolean = false;
  printerConflictList: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PrintPreferenceDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private cityOperationService: CityOperationsApiService,
    private infrastructureApi: InfrastructureApiService,
    private notificationMessageService: NotificationMessageService,
    protected printTypePipe: PrintTypeCdPipe,
    @Inject(MAT_DIALOG_DATA) public data: PrintPreferenceDialogData
  ) {}

  get pndPrinterTriggerCd() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.PndManifestTriggerCd)?.value;
    return res?.id ?? '';
  }

  set pndPrinterTriggerCd(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.PndManifestTriggerCd)
      .setValue(<AutoCompleteItem>{ id: value, value: this.printTypePipe.transform(PrintOccurrenceEnum[value]) });
  }

  get pndPrinter() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.PndManifestPrinter)?.value;
    return res.value ?? res ?? '';
  }

  set pndPrinter(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.PndManifestPrinter)
      .setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get pndPrinterTray() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.PndManifestTray)?.value;
    return res.value ?? res ?? '';
  }

  set pndPrinterTray(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.PndManifestTray)
      .setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get fbdsPrinterTriggerCd() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.FbdsTriggerCd)?.value;
    return res?.id ?? '';
  }

  set fbdsPrinterTriggerCd(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.FbdsTriggerCd)
      .setValue(<AutoCompleteItem>{ id: value, value: this.printTypePipe.transform(PrintOccurrenceEnum[value]) });
  }

  get fbdsLanguage() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.FbdsLanguage)?.value;
    return res ? res : null;
  }

  set fbdsLanguage(value: FbdsVersionCd) {
    this.formGroup.get(PrintPreferenceFormFields.FbdsLanguage).setValue(<string>value);
  }

  get fbdsPrinter() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.FbdsPrinter)?.value;
    return res.value ?? res ?? '';
  }

  set fbdsPrinter(value) {
    this.formGroup.get(PrintPreferenceFormFields.FbdsPrinter).setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get fbdsPrinterTray() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.FbdsTray)?.value;
    return res.value ?? res ?? '';
  }

  set fbdsPrinterTray(value) {
    this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get outboundPrinterTriggerCd() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.ObBreakoutTriggerCd)?.value;
    return res?.id ?? '';
  }

  set outboundPrinterTriggerCd(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.ObBreakoutTriggerCd)
      .setValue(<AutoCompleteItem>{ id: value, value: this.printTypePipe.transform(PrintOccurrenceEnum[value]) });
  }

  get outboundPrinter() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.ObBreakoutPrinter)?.value;
    return res.value ?? res ?? '';
  }

  set outboundPrinter(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.ObBreakoutPrinter)
      .setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get outboundPrinterTray() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.ObBreakoutTray)?.value;
    return res.value ?? res ?? '';
  }

  set outboundPrinterTray(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.ObBreakoutTray)
      .setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get dsrPrinterTriggerCd() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.DsrCheckinTriggerCd)?.value;
    return res?.id ?? '';
  }

  set dsrPrinterTriggerCd(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.DsrCheckinTriggerCd)
      .setValue(<AutoCompleteItem>{ id: value, value: this.printTypePipe.transform(PrintOccurrenceEnum[value]) });
  }

  get dsrPrinter() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.DsrCheckinPrinter)?.value;
    return res.value ?? res ?? '';
  }

  set dsrPrinter(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.DsrCheckinPrinter)
      .setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get dsrPrinterTray() {
    const res = this.formGroup?.get(PrintPreferenceFormFields.DsrCheckinTray)?.value;
    return res.value ?? res ?? '';
  }

  set dsrPrinterTray(value) {
    this.formGroup
      .get(PrintPreferenceFormFields.DsrCheckinTray)
      .setValue(<AutoCompleteItem>{ id: value, value: value });
  }

  get fbdsReprintIndicator(): boolean {
    return this.formGroup?.get(PrintPreferenceFormFields.FbdsReprintInd)?.value;
  }

  set fbdsReprintIndicator(checked: boolean) {
    this.formGroup?.get(PrintPreferenceFormFields.FbdsReprintInd)?.setValue(checked);
  }

  get obBreakoutSlcInd(): boolean {
    return this.formGroup?.get(PrintPreferenceFormFields.ObBreakoutSlcInd)?.value;
  }

  set obBreakoutSlcInd(checked: boolean) {
    this.formGroup?.get(PrintPreferenceFormFields.ObBreakoutSlcInd)?.setValue(checked);
  }

  ngOnInit(): void {
    this.setTriggerCdLists();
    this.createForm();
    this.isLoadingSubject.next(true);
    this.loadData$()
      .pipe(take(1))
      .subscribe(
        () => {
          this.isLoadingSubject.next(false);
          this.isSaveDisabledSubject.next(true);
        },
        (error) => {
          this.isLoadingSubject.next(false);
        }
      );
  }

  private loadData$(): Observable<void> {
    return forkJoin([
      this.fetchPrintersListFromAPI$(),
      this.getPrintPeferences$().pipe(catchError((error) => of(error))),
    ]).pipe(
      tap(() => {
        this.updatePrintersList();
        this.setServiceCenterPreferences();
      }),
      mapTo(undefined)
    );
  }

  private setTriggerCdLists(): void {
    this.pndFbdsTriggerCdList.push({
      id: PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT,
      value: this.printTypePipe.transform(PrintOccurrenceEnum[PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT]),
    });
    this.pndFbdsTriggerCdList.push({
      id: PnDAutoPrintOptionCd.TRAILER_CLOSE,
      value: this.printTypePipe.transform(PrintOccurrenceEnum[PnDAutoPrintOptionCd.TRAILER_CLOSE]),
    });
    this.pndFbdsTriggerCdList.push({
      id: PnDAutoPrintOptionCd.DISPATCH,
      value: this.printTypePipe.transform(PrintOccurrenceEnum[PnDAutoPrintOptionCd.DISPATCH]),
    });

    this.dsrOutboundTriggerCdList.push({
      id: PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT,
      value: this.printTypePipe.transform(PrintOccurrenceEnum[PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT]),
    });
    this.dsrOutboundTriggerCdList.push({
      id: PnDAutoPrintOptionCd.TRIP_COMPLETE,
      value: this.printTypePipe.transform(PrintOccurrenceEnum[PnDAutoPrintOptionCd.TRIP_COMPLETE]),
    });
    this.dsrOutboundTriggerCdList.push({
      id: PnDAutoPrintOptionCd.TRIP_RETURN,
      value: this.printTypePipe.transform(PrintOccurrenceEnum[PnDAutoPrintOptionCd.TRIP_RETURN]),
    });
  }

  private getPrintPeferences$(): Observable<void> {
    const request = new GetServiceCenterPrintPreferencesPath();
    request.sicCd = this.data.sicCd;

    return this.cityOperationService.getServiceCenterPrintPreferences(request).pipe(
      map(
        (resp: GetServiceCenterPrintPreferencesResp) => {
          this.printOption = resp.printPreferences;
        },
        (errorResp) => {
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, errorResp?.error?.message)
            .subscribe(() => {});
        }
      )
    );
  }

  private setServiceCenterPreferences(): void {
    this.fbdsPrinterTriggerCd = this.printOption?.fbdsTriggerCd ?? PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    this.fbdsLanguage = this.printOption?.fbdsVersionCd ?? FbdsVersionCd.DEFAULT;
    this.fbdsPrinter = this.printOption?.fbdsPrinter ?? '';
    this.fbdsPrinterTray = this.printOption?.fbdsTray ?? '';
    this.fbdsReprintIndicator = this.printOption?.fbdsReprintInd ?? false;

    this.pndPrinterTriggerCd = this.printOption?.pndManifestTriggerCd ?? PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    this.pndPrinter = this.printOption?.pndManifestPrinter ?? '';
    this.pndPrinterTray = this.printOption?.pndManifestTray ?? '';

    this.outboundPrinterTriggerCd = this.printOption?.obBreakoutTriggerCd ?? PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    this.outboundPrinter = this.printOption?.obBreakoutPrinter ?? '';
    this.outboundPrinterTray = this.printOption?.obBreakoutTray ?? '';
    this.obBreakoutSlcInd = this.printOption?.obBreakoutSlcInd ?? false;

    this.dsrPrinterTriggerCd = this.printOption?.dsrCheckinTriggerCd ?? PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    this.dsrPrinter = this.printOption?.dsrCheckinPrinter ?? '';
    this.dsrPrinterTray = this.printOption?.dsrCheckinTray ?? '';
  }

  private updatePrintersList(): void {
    const result: string[] = this.printersListFromAPI;
    this.activePrintersListSubject.next(
      result.map((val: string) => {
        return <AutoCompleteItem>{
          id: val,
          value: val,
        };
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.complete();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      [PrintPreferenceFormFields.FbdsTriggerCd]: ['', Validators.required],
      [PrintPreferenceFormFields.FbdsLanguage]: [null, Validators.required],
      [PrintPreferenceFormFields.FbdsPrinter]: '',
      [PrintPreferenceFormFields.FbdsTray]: '',
      [PrintPreferenceFormFields.FbdsReprintInd]: false,
      [PrintPreferenceFormFields.PndManifestTriggerCd]: ['', Validators.required],
      [PrintPreferenceFormFields.PndManifestPrinter]: '',
      [PrintPreferenceFormFields.PndManifestTray]: [
        '',
        this.validateTraySelection(
          PrintPreferenceFormFields.PndManifestPrinter,
          PrintPreferenceFormFields.PndManifestTray
        ),
      ],
      [PrintPreferenceFormFields.ObBreakoutTriggerCd]: ['', Validators.required],
      [PrintPreferenceFormFields.ObBreakoutPrinter]: '',
      [PrintPreferenceFormFields.ObBreakoutTray]: [
        '',
        this.validateTraySelection(
          PrintPreferenceFormFields.ObBreakoutPrinter,
          PrintPreferenceFormFields.ObBreakoutTray
        ),
      ],
      [PrintPreferenceFormFields.ObBreakoutSlcInd]: false,
      [PrintPreferenceFormFields.DsrCheckinTriggerCd]: ['', Validators.required],
      [PrintPreferenceFormFields.DsrCheckinPrinter]: '',
      [PrintPreferenceFormFields.DsrCheckinTray]: [
        '',
        this.validateTraySelection(
          PrintPreferenceFormFields.DsrCheckinPrinter,
          PrintPreferenceFormFields.DsrCheckinTray
        ),
      ],
    });
    this.subscribeToPndPrinterChange();
    this.subscribeToFbdsPrinterChange();
    this.subscribeToOutboundPrinterChange();
    this.subscribeToDsrPrinterChange();
    this.subscribeToFbdsTrayChanges();
    this.subscribeFormValueChanges();
  }

  private subscribeToPndPrinterChange() {
    this.activePndTrays$ = this.formGroup.get(PrintPreferenceFormFields.PndManifestPrinter).valueChanges.pipe(
      startWith(''),
      map((selectedPrinter) => {
        const printerName: string = selectedPrinter?.value ? selectedPrinter.value : selectedPrinter;
        let selectedTray = [];
        if (this.printerTrayMap.has(printerName)) {
          selectedTray = this.printerTrayMap
            .get(printerName)
            .filter((option) => option.toLowerCase().indexOf('Tray'.toLowerCase()) === 0);
        } else {
          selectedTray = [];
        }
        if (printerName === '') {
          this.pndPrinterTray = '';
        }
        this.isPrinterTrayConflict() === false
          ? this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors(null)
          : this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors({ match: true });
        this.printerChangeHandle();
        return selectedTray.map((val: string) => {
          return <AutoCompleteItem>{
            id: val,
            value: val,
          };
        });
      })
    );
  }

  private subscribeToFbdsPrinterChange() {
    this.activeFbdsTrays$ = this.formGroup.get(PrintPreferenceFormFields.FbdsPrinter).valueChanges.pipe(
      startWith(''),
      map((selectedPrinter) => {
        const printerName: string = selectedPrinter?.value ? selectedPrinter.value : selectedPrinter;
        let selectedTray = [];
        if (this.printerTrayMap.has(printerName)) {
          selectedTray = this.printerTrayMap
            .get(printerName)
            .filter((option) => option.toLowerCase().indexOf('Tray'.toLowerCase()) === 0);
        } else {
          selectedTray = [];
        }
        if (printerName === '') {
          this.fbdsPrinterTray = '';
        }
        this.printerChangeHandle();
        return selectedTray.map((val: string) => {
          return <AutoCompleteItem>{
            id: val,
            value: val,
          };
        });
      })
    );
  }

  private subscribeToOutboundPrinterChange() {
    this.activeOutboundTrays$ = this.formGroup.get(PrintPreferenceFormFields.ObBreakoutPrinter).valueChanges.pipe(
      startWith(''),
      map((selectedPrinter) => {
        const printerName: string = selectedPrinter?.value ? selectedPrinter.value : selectedPrinter;
        let selectedTray = [];
        if (this.printerTrayMap.has(printerName)) {
          selectedTray = this.printerTrayMap
            .get(printerName)
            .filter((option) => option.toLowerCase().indexOf('Tray'.toLowerCase()) === 0);
        } else {
          selectedTray = [];
        }
        if (printerName === '') {
          this.outboundPrinterTray = '';
        }
        this.isPrinterTrayConflict() === false
          ? this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors(null)
          : this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors({ match: true });
        this.printerChangeHandle();
        return selectedTray.map((val: string) => {
          return <AutoCompleteItem>{
            id: val,
            value: val,
          };
        });
      })
    );
  }

  private subscribeToDsrPrinterChange() {
    this.activeDsrTrays$ = this.formGroup.get(PrintPreferenceFormFields.DsrCheckinPrinter).valueChanges.pipe(
      startWith(''),
      map((selectedPrinter) => {
        const printerName: string = selectedPrinter?.value ? selectedPrinter.value : selectedPrinter;
        let selectedTray = [];
        if (this.printerTrayMap.has(printerName)) {
          selectedTray = this.printerTrayMap
            .get(printerName)
            .filter((option) => option.toLowerCase().indexOf('Tray'.toLowerCase()) === 0);
        } else {
          selectedTray = [];
        }
        if (printerName === '') {
          this.dsrPrinterTray = '';
        }
        this.isPrinterTrayConflict() === false
          ? this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors(null)
          : this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors({ match: true });
        this.printerChangeHandle();
        return selectedTray.map((val: string) => {
          return <AutoCompleteItem>{
            id: val,
            value: val,
          };
        });
      })
    );
  }

  private subscribeToFbdsTrayChanges() {
    this.formGroup
      .get(PrintPreferenceFormFields.FbdsTray)
      .valueChanges.pipe(takeUntil(this.unsubscribe.done$))
      .subscribe((tray) => {
        const value = tray.value ?? tray;
        if (value !== '' && this.isPrinterTrayConflict()) {
          this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors({ match: true });
        } else {
          this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors(null);
        }
        this.formGroup.markAllAsTouched();
        this.formGroup.get(PrintPreferenceFormFields.PndManifestTray).updateValueAndValidity();
        this.formGroup.get(PrintPreferenceFormFields.ObBreakoutTray).updateValueAndValidity();
        this.formGroup.get(PrintPreferenceFormFields.DsrCheckinTray).updateValueAndValidity();
      });
  }

  private subscribeFormValueChanges() {
    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe.done$)).subscribe(() => {
      this.validate();
    });
  }

  private fetchPrintersListFromAPI$(): Observable<void> {
    const request: ListPrintersQuery = {
      sicCds: [this.data?.sicCd],
    };

    return this.infrastructureApi.listPrinters(request).pipe(
      take(1),
      map(
        (resp: ListPrintersResp) => {
          this.printersListFromAPI = resp.printers.map((printer) => printer.name);

          resp?.printers.forEach((printer: Printer) => {
            this.printerTrayMap.set(printer.name, printer.paperSources);
          });
        },
        (errorResp) => {
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, errorResp?.error?.message)
            .subscribe(() => {});
        }
      )
    );
  }

  save() {
    if (this.printOption === undefined) {
      this.printOption = new PrintOption();
      this.printOption.sicCd = this.data.sicCd;
    }
    this.printOption.fbdsPrinter = this.fbdsPrinter;
    this.printOption.fbdsTray = this.fbdsPrinterTray;
    this.printOption.fbdsTriggerCd = this.fbdsPrinterTriggerCd;
    this.printOption.fbdsReprintInd = this.fbdsReprintIndicator;
    this.printOption.fbdsVersionCd = this.fbdsLanguage;

    this.printOption.dsrCheckinPrinter = this.dsrPrinter;
    this.printOption.dsrCheckinTray = this.dsrPrinterTray;
    this.printOption.dsrCheckinTriggerCd = this.dsrPrinterTriggerCd;

    this.printOption.obBreakoutPrinter = this.outboundPrinter;
    this.printOption.obBreakoutTray = this.outboundPrinterTray;
    this.printOption.obBreakoutTriggerCd = this.outboundPrinterTriggerCd;
    this.printOption.obBreakoutSlcInd = this.obBreakoutSlcInd;

    this.printOption.pndManifestPrinter = this.pndPrinter;
    this.printOption.pndManifestTray = this.pndPrinterTray;
    this.printOption.pndManifestTriggerCd = this.pndPrinterTriggerCd;

    const request = new UpsertServiceCenterPrintPreferencesRqst();
    request.printPreferences = this.printOption;
    const pathParams = new UpsertServiceCenterPrintPreferencesPath();
    pathParams.sicCd = this.data.sicCd;
    this.isLoadingSubject.next(true);
    this.cityOperationService
      .upsertServiceCenterPrintPreferences(request, pathParams)
      .pipe(take(1))
      .subscribe(
        (resp: UpsertServiceCenterPrintPreferencesResp) => {
          this.printOption = resp.printPreferences;
          this.isLoadingSubject.next(false);
          this.dialogRef.close(true);
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Success, 'Print preference saved successfully')
            .subscribe(() => {});
        },
        (errorResp) => {
          this.isLoadingSubject.next(false);
          this.dialogRef.close(false);
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, errorResp?.error?.message)
            .subscribe(() => {});
        }
      );
  }

  cancel() {
    this.dialogRef.close();
  }

  validateTraySelection(
    printerSelection: PrintPreferenceFormFields,
    traySelection: PrintPreferenceFormFields
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      const selectedTray = control.parent.get(traySelection)?.value?.value ?? control.parent.get(traySelection)?.value;
      const selectedPrinter =
        control.parent.get(printerSelection)?.value?.value ?? control.parent.get(printerSelection)?.value;
      if (selectedTray === undefined || selectedTray === '') {
        return null;
      }
      this.isPrinterTrayConflict() === false
        ? this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors(null)
        : this.formGroup.get(PrintPreferenceFormFields.FbdsTray).setErrors({ match: true });

      this.formGroup.markAllAsTouched();
      if (this.fbdsPrinter === selectedPrinter && this.fbdsPrinterTray === selectedTray) {
        this.notificationMessageService
          .openNotificationMessage(
            NotificationMessageStatus.Error,
            'Please use a different tray number for ' +
              this.printerConflictList.toString() +
              ' as the tray specified is also used for FBDS printing, which uses perforated paper.'
          )
          .subscribe(() => {});
        return { match: true };
      } else {
        return null;
      }
    };
  }

  isPrinterTrayConflict(): boolean {
    this.printerConflictList = [];
    if (
      this.pndPrinter === this.fbdsPrinter &&
      this.pndPrinterTray === this.fbdsPrinterTray &&
      this.fbdsPrinterTray !== ''
    ) {
      this.printerConflictList.push('P&D Manifests');
    }
    if (
      this.outboundPrinter === this.fbdsPrinter &&
      this.outboundPrinterTray === this.fbdsPrinterTray &&
      this.fbdsPrinterTray !== ''
    ) {
      this.printerConflictList.push('Outbound Manifests');
    }
    if (
      this.dsrPrinter === this.fbdsPrinter &&
      this.dsrPrinterTray === this.fbdsPrinterTray &&
      this.fbdsPrinterTray !== ''
    ) {
      this.printerConflictList.push('DSR Check-In Report');
    }
    return this.printerConflictList.length > 0;
  }

  validate() {
    let isPndValid = this.pndPrinterTriggerCd === PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    let isFbdsValid = this.fbdsPrinterTriggerCd === PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    let isDsrValid = this.dsrPrinterTriggerCd === PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;
    let isOutBoundValid = this.outboundPrinterTriggerCd === PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT;

    if (this.pndPrinterTriggerCd !== '' && this.pndPrinterTriggerCd !== PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT) {
      isPndValid = this.pndPrinter !== '' && this.pndPrinterTray !== '';
    }
    if (this.fbdsPrinterTriggerCd !== '' && this.fbdsPrinterTriggerCd !== PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT) {
      isFbdsValid = this.fbdsPrinter !== '' && this.fbdsPrinterTray !== '' && this.fbdsLanguage !== null;
    }
    if (
      this.outboundPrinterTriggerCd !== '' &&
      this.outboundPrinterTriggerCd !== PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT
    ) {
      isOutBoundValid = this.outboundPrinter !== '' && this.outboundPrinterTray !== '';
    }
    if (this.dsrPrinterTriggerCd !== '' && this.dsrPrinterTriggerCd !== PnDAutoPrintOptionCd.DO_NOT_AUTO_PRINT) {
      isDsrValid = this.dsrPrinter !== '' && this.dsrPrinterTray !== '';
    }

    const isTraySelectionInvalid =
      this.formGroup.get(PrintPreferenceFormFields.FbdsTray).invalid ||
      this.formGroup.get(PrintPreferenceFormFields.PndManifestTray).invalid ||
      this.formGroup.get(PrintPreferenceFormFields.DsrCheckinTray).invalid ||
      this.formGroup.get(PrintPreferenceFormFields.ObBreakoutTray).invalid;

    this.isSaveDisabledSubject.next(
      !isPndValid || !isFbdsValid || !isDsrValid || !isOutBoundValid || isTraySelectionInvalid
    );
  }

  printerChangeHandle(): void {
    this.formGroup.get(PrintPreferenceFormFields.PndManifestTray).updateValueAndValidity();
    this.formGroup.get(PrintPreferenceFormFields.ObBreakoutTray).updateValueAndValidity();
    this.formGroup.get(PrintPreferenceFormFields.DsrCheckinTray).updateValueAndValidity();
    this.formGroup.get(PrintPreferenceFormFields.FbdsTray).updateValueAndValidity();
  }
}
