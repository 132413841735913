import { Injectable, OnDestroy } from '@angular/core';
import { DockRoutesActivityId } from '@pnd-store/dock-routes-store/dock-routes.state';
import { RouteBalancingSelectors } from '@pnd-store/route-balancing-store';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  Activity,
  ActivityShipment,
  DeliveryShipmentSearchRecord,
  InterfaceAcct,
  Route,
  RouteShipment,
  Stop,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { NodeTypeCd, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  includes as _includes,
  map as _map,
  set as _set,
  size as _size,
  uniq as _uniq,
  sortBy as _sortBy,
} from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  PndStoreState,
  RoutesStoreSelectors,
  TripsStoreActions,
  TripsStoreSelectors,
  UnassignedDeliveriesStoreSelectors,
  UnassignedPickupsStoreSelectors,
  DockRoutesStoreSelectors,
  DockRoutesStoreActions,
  PastDueShipmentsStoreSelectors,
  PastDueShipmentsStoreActions,
} from '../../../../store';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '../../../../store/dispatcher-trips-store';
import { ModifyTripDetailsActions, ModifyTripDetailsSelectors } from '../../../../store/modify-trip-details-store';
import { ModifyTripActivityId } from '../../../../store/modify-trip-details-store/modify-trip-details.state';
import { PndStore } from '../../../../store/pnd-store';
import { PluralMaps } from '../../../shared/classes/plural-maps';
import { SelectionSummaryData } from '../../../shared/components/selection-summary/selection-summary-data.class';
import { SpecialServicesHelper } from '../../../shared/helpers/special-services/special-services.helper';
import {
  AssignedStopIdentifier,
  consigneeToId,
  EventItem,
  PastDueShipmentIdentifier,
  PlanningRouteShipmentIdentifier,
  routeStopToId,
  UnassignedDeliveryIdentifier,
  UnassignedPickupIdentifier,
} from '../../../shared/interfaces/event-item.interface';
import { SpecialServicesService, UnassignedDeliveriesCacheService } from '../../../shared/services';
import { PastDueShipmentsCacheService } from '../../../shared/services/past-due-cache.service';
import { PlanningRoutesCacheService } from '../../../shared/services/planning-routes-cache.service';
import { DispatcherTripsRouteRenderingService } from '../../dispatcher-trips/services/dispatcher-trips-route-rendering.service';
import { AssignShipmentsSelectionSource } from '../enums/assign-shipments-selection-source.enum';
import { LayoutPreferenceService } from './../../../../../shared/layout-manager/services/layout-preference.service';
import { TripPlanningGridItem } from './../../trip-planning/models/trip-planning-grid-item.model';
@Injectable({
  providedIn: 'root',
})
export class AssignShipmentsService implements OnDestroy {
  // ------------------ getters for all kind of grid selection snapshot data -----------

  get selectedUnassignedDeliveriesShipments(): DeliveryShipmentSearchRecord[] {
    return this.getSelectedUnassignedDeliveriesShipments();
  }

  get selectedUnassignedPickupsShipments(): EventItem<UnassignedPickupIdentifier>[] {
    return this.getSelectedUnassignedPickupsShipments();
  }

  get selectedPlanningRouteShipments(): DeliveryShipmentSearchRecord[] {
    return this.getSelectedPlanningRouteShipments();
  }

  get selectedPastDueShipments(): DeliveryShipmentSearchRecord[] {
    return this.getSelectedPastDueShipments();
  }

  get selectedStops(): Stop[] {
    return this.getSelectedRouteStops()[0];
  }
  get selectedRouteStopShipments(): Activity[] {
    return this.getSelectedRouteStops()[1];
  }

  get selectedTripDetailsShipments(): Activity[] {
    return this.getTripDetailsShipments();
  }

  get selectedDockStops() {
    return this.getDockRouteStops();
  }
  set selectedShipmentIds(selectedShipmentIds: number[]) {
    this._selectedShipmentIds = selectedShipmentIds;
    this.updateSummary();
  }
  get selectedShipmentIds() {
    return this._selectedShipmentIds;
  }
  set unmappedDeliveriesShipments(unmappedDeliveriesShipments: DeliveryShipmentSearchRecord[]) {
    this._unmappedDeliveriesShipments = unmappedDeliveriesShipments;
  }
  get unmappedDeliveriesShipments() {
    return this._unmappedDeliveriesShipments;
  }
  set selectedRouteAdditions(selectedRouteAdditions: DeliveryShipmentSearchRecord[]) {
    this._selectedRouteAdditions = selectedRouteAdditions;
    this.updateSummary();
  }
  get selectedRouteAdditions() {
    return this._selectedRouteAdditions;
  }
  get isUnassignedDeliveriesGridRefreshDisabled() {
    return this.isUnassignedDeliveriesGridRefreshDisabledSubject.value;
  }

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private unassignedDeliveriesCacheService: UnassignedDeliveriesCacheService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private dispatcherTripsRouteRenderingService: DispatcherTripsRouteRenderingService,
    private specialServicesService: SpecialServicesService,
    private layoutPreferenceService: LayoutPreferenceService,
    private pastDueCacheService: PastDueShipmentsCacheService
  ) {
    this.isRouteBalancingActive$ = this.pndStore$.select(RouteBalancingSelectors.openRouteBalancingPanel);
  }

  get selectedTrips(): number[] {
    const selectedTripSelector = this.layoutPreferenceService.isDispatcherLayout()
      ? DispatcherTripsStoreSelectors.selectedTrips
      : TripsStoreSelectors.selectedTrips;

    const dispatchMapFun = (selectedTripsIds) => {
      const ids = [...selectedTripsIds].map((id) => +id);
      return [...ids];
    };

    const plannerMapFun = (tripPlanningItems) => {
      return tripPlanningItems?.map((item) => item?.tripInstId);
    };

    const value = this.pndStore$.selectSnapshot<TripPlanningGridItem[] | number[]>(selectedTripSelector);

    return this.layoutPreferenceService.isDispatcherLayout() ? dispatchMapFun(value) : plannerMapFun(value);
  }
  private readonly assignCompletedSubject = new Subject<AssignShipmentsSelectionSource>();
  readonly assignCompleted$ = this.assignCompletedSubject.asObservable();

  private readonly selectionChangedSubject = new Subject();
  readonly selectionChanged$ = this.selectionChangedSubject.asObservable();

  private readonly summarySubject = new BehaviorSubject<SelectionSummaryData>(new SelectionSummaryData());
  readonly summary$ = this.summarySubject.asObservable();

  readonly isRouteBalancingActive$: Observable<boolean>;
  private selectedBoardSubject = new BehaviorSubject<string>(undefined);
  readonly selectedBoard$ = this.selectedBoardSubject.asObservable();

  currentSelectionSources: AssignShipmentsSelectionSource[] = [];

  // ------------------

  private _selectedShipmentIds: number[] = [];

  private _unmappedDeliveriesShipments: DeliveryShipmentSearchRecord[] = [];

  private _selectedRouteAdditions: DeliveryShipmentSearchRecord[] = [];

  private isUnassignedDeliveriesGridRefreshDisabledSubject = new BehaviorSubject<boolean>(false);
  readonly isUnassignedDeliveriesGridRefreshDisabled$ = this.isUnassignedDeliveriesGridRefreshDisabledSubject.asObservable();

  selectedTripDetailsShipmentsActivityId: ModifyTripActivityId[] = [];
  selectedCustomers: InterfaceAcct[] = [];

  private fromTripDetailsRouteIds: number[] = [];
  private fromPlanningRoutesRouteIds: number[] = [];
  private fromPastDueShipmentsIds: number[] = [];
  private fromRouteStopsRouteIds: number[] = [];
  private unsubscriber = new Unsubscriber();
  setSelectedBoard(selectedBoard: string): void {
    this.selectedBoardSubject.next(selectedBoard);
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Notifies assignCompleted$ subscribers that a new assign/reassign has been completed,
   * so they can act on accordingly.
   *
   * @param source
   */
  onAssignCompleted(source: AssignShipmentsSelectionSource) {
    this.assignCompletedSubject.next(source);

    // refresh data that may have been changed
    this.pndStore$.dispatch(new TripsStoreActions.Refresh());
    this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
    this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
    this.pndStore$.dispatch(new DockRoutesStoreActions.Refresh());
    this.pndStore$.dispatch(new PastDueShipmentsStoreActions.Refresh());
  }

  /**
   * Updates the currentSelectionSources value, used to disable the form when there are
   * more than 1 selection sources, or no selections at all.
   */
  onSelectionUpdated(source: AssignShipmentsSelectionSource) {
    this.currentSelectionSources = [source];
    this.selectionChangedSubject.next();
  }

  getSelectedUnassignedDeliveriesShipments(): DeliveryShipmentSearchRecord[] {
    let result: DeliveryShipmentSearchRecord[] = [];
    const selectedDeliveries = this.pndStore$.selectSnapshot(
      UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected
    );
    const unassignedDeliveries = this.unassignedDeliveriesCacheService.unassignedDeliveries;
    const unmappedDeliveries = this.unassignedDeliveriesCacheService.unmappedDeliveries;
    const unBilledDeliveries = this.unassignedDeliveriesCacheService.unBilledDeliveries;
    const selectedIds = _map(selectedDeliveries, (item) => item.id);
    const arrayOfUnmappedAndUnassignedDL: UnassignedStop[] = [
      ...unassignedDeliveries,
      ...unmappedDeliveries,
      ...unBilledDeliveries,
    ];
    result = this.deliveriesFromSelected(selectedIds, arrayOfUnmappedAndUnassignedDL);
    return result;
  }

  getSelectedUnassignedPickupsShipments(): EventItem<UnassignedPickupIdentifier>[] {
    const pickupsSelected: EventItem<UnassignedPickupIdentifier>[] = this.pndStore$.selectSnapshot(
      UnassignedPickupsStoreSelectors.unassignedPickupsSelected
    );
    return pickupsSelected;
  }

  getSelectedPlanningRouteShipments() {
    const selectedShipments = this.pndStore$.selectSnapshot(RoutesStoreSelectors.selectedPlanningRoutesShipments);

    // build map of all DeliveryShipmentSearchRecords for selected shipmetns
    const idToIdentity: { [key: string]: PlanningRouteShipmentIdentifier } = {};
    const idToShipments: { [key: string]: DeliveryShipmentSearchRecord[] } = {};

    _forEach(selectedShipments, (shipmentId: EventItem<PlanningRouteShipmentIdentifier>) => {
      const sid = routeStopToId(shipmentId.id);
      _set(idToIdentity, sid, shipmentId);

      const deliveryShipment = this.planningRoutesCacheService.getDeliveryShipmentSearchRecord(shipmentId.id);
      if (deliveryShipment) {
        const deliveryShipments = idToShipments?.[sid] ?? [];
        _set(idToShipments, sid, deliveryShipments.concat(deliveryShipment));
      }
    });

    const selectedPlanningShipments: DeliveryShipmentSearchRecord[] = [];
    _forOwn(idToIdentity, (identity, sid) => {
      const shipments = idToShipments?.[sid] ?? [];
      selectedPlanningShipments.push(...shipments);
    });

    // used to disable assigning (only shipments from one route at a time are allowed)
    this.fromPlanningRoutesRouteIds = _uniq(
      selectedPlanningShipments.map((shipment) => shipment.routeInstId).filter((routeName) => !!routeName)
    );
    return selectedPlanningShipments;
  }

  getSelectedPastDueShipments(): DeliveryShipmentSearchRecord[] {
    const selectedShipments = this.pndStore$.selectSnapshot(PastDueShipmentsStoreSelectors.selectedPastDueShipments);

    // build map of all DeliveryShipmentSearchRecords for selected shipmetns
    const idToIdentity: { [key: string]: PastDueShipmentIdentifier } = {};
    const idToShipments: { [key: string]: DeliveryShipmentSearchRecord[] } = {};

    _forEach(selectedShipments, (shipmentId: EventItem<PastDueShipmentIdentifier>) => {
      const sid = routeStopToId(shipmentId.id);
      _set(idToIdentity, sid, shipmentId);

      const deliveryShipment = this.pastDueCacheService.getPastDueShipmentSearchRecord(shipmentId.id.shipmentInstId);
      if (deliveryShipment) {
        const deliveryShipments = idToShipments?.[sid] ?? [];
        _set(idToShipments, sid, deliveryShipments.concat(deliveryShipment));
      }
    });

    const selectedPastDueShipments: DeliveryShipmentSearchRecord[] = [];
    _forOwn(idToIdentity, (identity, sid) => {
      const shipments = idToShipments?.[sid] ?? [];
      selectedPastDueShipments.push(...shipments);
    });

    // used to disable assigning (only shipments from one route at a time are allowed)
    this.fromPastDueShipmentsIds = _uniq(
      selectedPastDueShipments.map((shipment) => shipment.routeInstId).filter((routeName) => !!routeName)
    );
    return selectedPastDueShipments;
  }

  getSelectedRouteStops(): [Stop[], Activity[]] {
    const stopsForSelectedRoutes = this.pndStore$.selectSnapshot(TripsStoreSelectors.stopsForSelectedRoutes);
    const stopsForSelectedRoutesService = this.dispatcherTripsRouteRenderingService.selectedStops;
    const selectedStopsForSelectedRoutes = this.pndStore$.selectSnapshot(
      TripsStoreSelectors.selectedStopsForSelectedRoutes
    );

    const selectedStopsResult = [];
    const selectedRouteStopShipmentsResult = [];

    selectedStopsForSelectedRoutes.forEach((identifier: EventItem<AssignedStopIdentifier>) => {
      let allStops: Stop[] = stopsForSelectedRoutes?.[identifier.id.routeInstId] ?? [];

      if (allStops.length === 0) {
        allStops = stopsForSelectedRoutesService?.[identifier.id.routeInstId] ?? [];
      }

      const selectedStop: Stop = allStops.find((stop) => stop?.tripNode?.stopSequenceNbr === identifier.id.origSeqNo);

      if (selectedStop) {
        _forEach(selectedStop.activities, (activity: Activity) => {
          if (activity.routeShipment === undefined) {
            activity.routeShipment = { ...new RouteShipment(), routeInstId: identifier.id.routeInstId };
          }
          selectedRouteStopShipmentsResult.push(activity);
        });
        selectedStopsResult.push(selectedStop);
      }
    });

    // used to disable assigning (only shipments from one route at a time are allowed)
    this.fromRouteStopsRouteIds = _uniq(
      selectedStopsForSelectedRoutes.map((eventItem) => eventItem?.id?.routeInstId).filter((routeId) => !!routeId)
    );

    return [selectedStopsResult, selectedRouteStopShipmentsResult];
  }

  getTripDetailsShipments(): Activity[] {
    let selectedTripDetailsShipmentsActivityId: ModifyTripActivityId[] = [];

    const activities: ModifyTripActivityId[] = this.pndStore$.selectSnapshot(
      ModifyTripDetailsSelectors.selectedActivities
    );
    const selectedTrips: number[] = this.selectedTrips;

    const selectedRoutes =
      _size(this.dispatcherTripsRouteRenderingService.selectedRoutes) > 0
        ? this.dispatcherTripsRouteRenderingService.selectedRoutes
        : this.pndStore$.selectSnapshot(TripsStoreSelectors.selectedRoutes);

    const stopsForSelectedRoutes =
      _size(this.dispatcherTripsRouteRenderingService.selectedStops) > 0
        ? this.dispatcherTripsRouteRenderingService.selectedStops
        : this.pndStore$.selectSnapshot(TripsStoreSelectors.stopsForSelectedRoutes);

    let tripInstIds = [];
    const selectedShipmentIds: number[] = [];
    _forEach(activities, (activity: ModifyTripActivityId) => {
      selectedTripDetailsShipmentsActivityId.push(activity);
      tripInstIds.push(activity.tripInstId);
      selectedShipmentIds.push(activity.shipmentInstId);
    });
    if (activities?.length > 0) {
      tripInstIds = [...tripInstIds, ...selectedTrips];
    }
    tripInstIds = _uniq(tripInstIds);
    if (tripInstIds.length > 0) {
      this.dispatcherTripsRouteRenderingService.getStops(tripInstIds);
    }
    if (activities.length === 0) {
      selectedTripDetailsShipmentsActivityId = [];
    }
    this.selectedTripDetailsShipmentsActivityId = selectedTripDetailsShipmentsActivityId;

    const selectedShipmentsInfo: Activity[] = [];
    this.selectedCustomers = [];
    const tripDetailsSelectedStop: Map<number, Stop[]> = new Map<number, Stop[]>();

    _forEach(selectedRoutes, (selectedRoute: Route) => {
      const stopsForRoute = stopsForSelectedRoutes[selectedRoute?.routeInstId];

      _forEach(selectedShipmentIds, (shipmentId) => {
        _forEach(stopsForRoute, (stop: Stop) => {
          const activitiesInStop: Activity[] = stop?.activities ?? [];
          const customer = stop?.customer;
          const activityInStop: Activity = activitiesInStop.find(
            (activity: Activity) => activity?.tripNodeActivity?.shipmentInstId === shipmentId
          );
          if (activityInStop) {
            tripDetailsSelectedStop.set(selectedRoute?.routeInstId, stopsForRoute);
            selectedShipmentsInfo.push(activityInStop);
            this.selectedCustomers.push(customer);
            return false;
          }
        });
      });
    });
    this.fromTripDetailsRouteIds = [...tripDetailsSelectedStop.keys()];
    this.selectedCustomers = _uniq(this.selectedCustomers);

    return selectedShipmentsInfo;
  }

  private getDockRouteStops(): DockRoutesActivityId[] {
    const dockStops: DockRoutesActivityId[] = this.pndStore$.selectSnapshot(
      DockRoutesStoreSelectors.selectedDockActivities
    );

    return dockStops;
  }

  /**
   * Get selected deliveries from Unassigned Deliveries grid
   */
  private deliveriesFromSelected(
    selectedIds: UnassignedDeliveryIdentifier[],
    unassignedStops: UnassignedStop[]
  ): DeliveryShipmentSearchRecord[] {
    const selectedDeliveries: DeliveryShipmentSearchRecord[] = [];

    // gather all shipments in selected stops
    const selectedConsignees = _filter(selectedIds, (item: UnassignedDeliveryIdentifier) => {
      return !item.shipmentInstId;
    });

    _forEach(selectedConsignees, (id: UnassignedDeliveryIdentifier) => {
      // find stop for this selected shipment
      const stop = _find(unassignedStops, (us: UnassignedStop) => {
        return consigneeToId(us) === consigneeToId(id);
      });

      const deliveryShipments = stop?.deliveryShipments ?? [];
      selectedDeliveries.push(
        ...deliveryShipments.map((deliveryShipmentRecord: DeliveryShipmentSearchRecord) => ({
          ...deliveryShipmentRecord,
          appointmentStatusCd: stop?.appointmentStatusCd,
        }))
      );
    });

    // gather all selected shipments from Items
    const selectedShipments = _filter(selectedIds, (item: UnassignedDeliveryIdentifier) => {
      return !!item.shipmentInstId;
    });
    _forEach(selectedShipments, (id: UnassignedDeliveryIdentifier) => {
      // find stop for this selected shipment
      const stop = _find(unassignedStops, (us: UnassignedStop) => {
        return consigneeToId(us) === consigneeToId(id);
      });

      const shipment = stop?.deliveryShipments?.find(
        (ds: DeliveryShipmentSearchRecord) => ds.shipmentInstId === id.shipmentInstId
      );

      if (shipment) {
        selectedDeliveries.push(shipment);
      }
    });

    return selectedDeliveries;
  }

  /**
   * Returns the route ids from which we are reassigning.
   * Only applicable for RouteStops, PlanningRouteShipments & TripDetails
   */
  getFromRouteIds(): number[] {
    if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.RouteStops) {
      return this.fromRouteStopsRouteIds;
    } else if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PlanningRouteShipments) {
      return this.fromPlanningRoutesRouteIds;
    } else if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PastDueShipments) {
      return this.fromPastDueShipmentsIds;
    } else if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.TripDetails) {
      return this.fromTripDetailsRouteIds;
    } else {
      return [];
    }
  }

  /**
   * Returns an array of the shipments selected for assign/reassign.
   * The source can be:
   *   - Unmapped Deliveries
   *   - Unassigned Deliveries
   *   - TripDetailss
   *   - Planning Route Shipments
   */
  getSelectedShipments(): (DeliveryShipmentSearchRecord | Activity)[] {
    let shipments = [];
    const source: AssignShipmentsSelectionSource = this.currentSelectionSources[0];
    switch (source) {
      case AssignShipmentsSelectionSource.UnassignedDeliveries:
        shipments = this.selectedUnassignedDeliveriesShipments;
        break;
      case AssignShipmentsSelectionSource.RouteStops:
        shipments = this.selectedRouteStopShipments;
        break;
      case AssignShipmentsSelectionSource.PlanningRouteShipments:
        shipments = this.selectedPlanningRouteShipments;
        break;
      case AssignShipmentsSelectionSource.PastDueShipments:
        shipments = this.selectedPastDueShipments;
        break;
      case AssignShipmentsSelectionSource.TripDetails:
        shipments = this.selectedTripDetailsShipments;
        break;
      case AssignShipmentsSelectionSource.DockRoutes:
        shipments = this.selectedDockStops;
        break;
      case AssignShipmentsSelectionSource.UnmappedDeliveries:
        shipments = this.unmappedDeliveriesShipments;
    }
    return shipments;
  }

  private clearSummary() {
    this.summarySubject.next(undefined);
  }

  updateSummary() {
    this.clearSummary();

    const selectedShipments = [...this.getSelectedShipments()];
    const additions = [];
    if (
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PlanningRouteShipments ||
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.UnassignedDeliveries ||
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.UnassignedPickups ||
      this.currentSelectionSources[0] === AssignShipmentsSelectionSource.PastDueShipments
    ) {
      // add selected recommendations
      additions.push(...this.selectedRouteAdditions);
    }
    if (
      _size(selectedShipments) > 0 &&
      _includes(this.currentSelectionSources, AssignShipmentsSelectionSource.TripDetails)
    ) {
      this.summarySubject.next(this.buildSummaryForTripDetailsShipments(<Activity[]>selectedShipments));
    } else if (
      _size(selectedShipments) > 0 &&
      _includes(this.currentSelectionSources, AssignShipmentsSelectionSource.DockRoutes)
    ) {
      this.summarySubject.next(this.buildSummaryForSelectedDockStops(this.selectedDockStops));
    } else if (_size(selectedShipments) > 0) {
      this.summarySubject.next(
        this.buildSummaryForSelectedShipments(<DeliveryShipmentSearchRecord[]>selectedShipments, additions)
      );
    } else if (_size(this.selectedStops) > 0) {
      this.summarySubject.next(this.buildSummaryForSelectedStops(this.selectedStops));
    }
  }

  buildSummaryForTripDetailsShipments(activities: Activity[]) {
    const newSummary = new SelectionSummaryData();
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];

    newSummary.shipments = _size(activities);

    _forEach(activities, (activity: Activity) => {
      const shipment = activity?.tripNodeActivity;
      newSummary.motorMoves += shipment?.totalMmCount ?? 0;
      newSummary.weight += shipment?.totalWeightCount ?? 0;
      servicesAccumulator = servicesAccumulator.concat(
        SpecialServicesHelper.getSpecialServicesForSummary(activity.shipmentSpecialServices)
      );

      activity?.activityShipments?.forEach((activityShipment: ActivityShipment) => {
        this.specialServicesService.getSpecialServiceAppointmentSummaryMark(
          activityShipment,
          newSummary,
          'appointmentStatusCd'
        );
      });
    });
    newSummary.stops = _size(this.selectedCustomers);
    newSummary.specialServices = _uniq(servicesAccumulator);

    this.specialServicesService.addPendingMarksToSpecialServices(
      newSummary.specialServices,
      newSummary.specialServicesMarks
    );

    if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.TripDetails) {
      newSummary.selectedDisplayName = PluralMaps.Customers;
    }
    return newSummary;
  }

  private buildSummaryForSelectedShipments(
    selectedShipments: DeliveryShipmentSearchRecord[],
    additions: DeliveryShipmentSearchRecord[]
  ) {
    const consigneeIds: number[] = [];
    // gather all the stats for selected shipments
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];
    const newSummary = new SelectionSummaryData();
    const allShipments = [...selectedShipments, ...additions];

    newSummary.shipments = _size(allShipments);
    _forEach(allShipments, (shipment) => {
      consigneeIds.push(shipment?.consignee?.acctInstId);

      newSummary.motorMoves += shipment.motorizedPiecesCount;
      newSummary.weight += shipment.totalWeightLbs;

      this.specialServicesService.getSpecialServiceAppointmentSummaryMark(shipment, newSummary, 'appointmentStatusCd');

      servicesAccumulator = servicesAccumulator.concat(
        SpecialServicesHelper.getSpecialServicesForSummary(shipment.specialServiceSummary)
      );
    });

    newSummary.stops = _size(_uniq(consigneeIds));
    newSummary.specialServices = _uniq(servicesAccumulator);
    this.specialServicesService.addPendingMarksToSpecialServices(
      newSummary.specialServices,
      newSummary.specialServicesMarks
    );

    // Set label name (Stops or Customers for example)
    if (this.currentSelectionSources[0] === AssignShipmentsSelectionSource.UnassignedDeliveries) {
      newSummary.selectedDisplayName = PluralMaps.Customers;
    }
    return newSummary;
  }

  private buildSummaryForSelectedStops(selectedStops: Stop[]) {
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];
    const newSummary = new SelectionSummaryData();

    newSummary.shipments = 0;
    newSummary.motorMoves = 0;
    newSummary.weight = 0;

    newSummary.stops = 0;
    newSummary.specialServices = [];

    selectedStops
      .filter((s) => s?.tripNode?.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER)
      .forEach((stop) => {
        if (stop.activities && stop.activities.length) {
          newSummary.stops++;
          stop.activities.forEach((act: Activity) => {
            if (act.tripNodeActivity) {
              newSummary.shipments += act.tripNodeActivity.totalBillCount;
              newSummary.motorMoves += act.tripNodeActivity.totalMmCount;
              newSummary.weight += act.tripNodeActivity.totalWeightCount;
            }
          });
        }
        servicesAccumulator = servicesAccumulator.concat(SpecialServicesHelper.getSpecialServicesForStop(stop));
      });

    newSummary.specialServices = _uniq(servicesAccumulator);
    return newSummary;
  }

  arePickupsInTripDetails(): boolean {
    const tripActivity = this.pndStore$.selectSnapshot(ModifyTripDetailsSelectors.selectedActivities);
    return !!_find(tripActivity, (activity) => activity.pickupRequestInstId > 0);
  }

  // TODO - NJ: Ability to show pickups and deliveries together?

  //   const arePickupsInTripDetails = this.arePickupsInTripDetails();
  //   if (arePickupsInTripDetails) {
  //     // TODO - NJ -
  //     // this.toggleAssignPickupsInMapSplitPanel(true, expanded, true);
  //   }

  private isSameRouteTripSelections(): boolean {
    const routeStopShipments = _sortBy(
      _map(this.selectedRouteStopShipments, 'tripNodeActivity'),
      'tripNodeActivitySequenceNbr'
    );
    const tripDetailsShipments = _sortBy(
      _map(this.selectedTripDetailsShipments, 'tripNodeActivity'),
      'tripNodeActivitySequenceNbr'
    );
    return routeStopShipments?.length > 0 && tripDetailsShipments.length > 0;
  }

  private buildSummaryForSelectedDockStops(selectedDockStops: DockRoutesActivityId[]) {
    const servicesAccumulator: ShipmentSpecialServiceCd[] = [];
    const newSummary = new SelectionSummaryData();
    newSummary.shipments = 0;
    newSummary.motorMoves = 0;
    newSummary.weight = 0;

    newSummary.stops = 0;
    newSummary.specialServices = [];
    _forEach(selectedDockStops, (stop) => {
      newSummary.stops++;
      newSummary.shipments += stop?.billCount;
      newSummary.weight += stop?.weightLbs;
      newSummary.motorMoves += stop?.motorMovesNbr;
      _forEach(stop?.specialServices, (service) => {
        servicesAccumulator.push(service);
      });
    });
    newSummary.specialServices = _uniq(servicesAccumulator);
    return newSummary;
  }
}
