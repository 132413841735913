import { Injectable } from '@angular/core';
import { forEach as _forEach } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import shader from 'shader';
import { LayoutPreferenceService } from '../../../../../../../shared/layout-manager';
import { MapUtils } from '../../../../../shared/classes/map-utils';
import { LegRenderType } from '../enums/leg-render-type.enum';
import { Leg } from '../interfaces/leg.interface';
import { RightClickSubject } from '../interfaces/right-click-subject.interface';
import { TripRenderInfo } from '../interfaces/trip-render-info';

/** 1 by default */
export const INCOMPLETED_OPACITY: number = 1;
/** 0.5 by default */
export const COMPLETED_OPACITY: number = 0.5;

/** 0.5 by default */
export const INCOMPLETED_BLUR_OPACITY: number = 0.5;
/** 0.25 by default */
export const COMPLETED_BLUR_OPACITY: number = 0.25;

/** 5 by default */
export const DEFAULT_STROKE_WIDTH: number = 5;
/** 3 by default */
export const DEFAULT_CONNECTOR_STROKE_WIDTH: number = 3;

@Injectable({
  providedIn: 'root',
})
export class PolylinesService {
  private hoverOverTripSubject = new BehaviorSubject<{
    tripInstId: number;
    location: google.maps.LatLngLiteral;
  }>(undefined);
  readonly hoverOverTrip$ = this.hoverOverTripSubject.asObservable();

  private hoverOverRouteSubject = new BehaviorSubject<{
    routeInstId: number;
    location: google.maps.LatLngLiteral;
  }>(undefined);
  readonly hoverOverRoute$ = this.hoverOverRouteSubject.asObservable();

  private tripClickSubject = new BehaviorSubject<{
    tripInstId: number;
    location: google.maps.LatLngLiteral;
  }>(undefined);
  readonly tripClick$ = this.tripClickSubject.asObservable();

  private routeClickSubject = new BehaviorSubject<{
    routeInstId: number;
    location: google.maps.LatLngLiteral;
  }>(undefined);
  readonly routeClick$ = this.routeClickSubject.asObservable();

  private rightClickRouteSubject = new BehaviorSubject<RightClickSubject>(undefined);
  readonly rightClickRoute$ = this.rightClickRouteSubject.asObservable();

  constructor(private layoutPreferenceService: LayoutPreferenceService) {}

  /**
   * Returns a new Polyline Options with specified properties
   */
  polylineOptions(
    color: string,
    type: LegRenderType,
    opacity: number,
    zoomLevel: number,
    showDirectionArrows: boolean,
    showBreadcrumbArrows: boolean,
    isConnectionLost: boolean
  ): google.maps.PolylineOptions {
    const DIRECTION_ARROW_ICON: google.maps.IconSequence = {
      repeat: '100px',
      icon: {
        path: 'M5,1.02956301 L9.15024277,8.5 L6.5,8.5 L6.5,15.5 L3.5,15.5 L3.5,8.5 L0.84975723,8.5 L5,1.02956301 Z',
        strokeColor: shader(color, -0.5),
        strokeOpacity: opacity,
        fillColor: 'white',
        fillOpacity: opacity,
        scale: 1.2,
        anchor: new google.maps.Point(5, 0),
      },
    };

    const BREADCRUMB_ARROW_ICON: google.maps.IconSequence = {
      repeat: '28px',
      icon: {
        path: 'M7.30629 11.5H0.693713L4 1.58114L7.30629 11.5Z',
        strokeColor: shader(color, -0.5),
        strokeOpacity: opacity,
        fillColor: color,
        fillOpacity: 1,
        scale: 1.4,
        anchor: new google.maps.Point(5, 0),
      },
    };

    const DASHED_LINE_ICON: google.maps.IconSequence = {
      offset: '0',
      repeat: '10px',
      icon: {
        path: 'M 0,-1 0,1',
        strokeWeight: DEFAULT_CONNECTOR_STROKE_WIDTH,
        strokeOpacity: opacity,
        scale: 1,
      },
    };

    const isEagleView = zoomLevel <= MapUtils.EAGLE_VIEW_LEVEL;

    const options: google.maps.PolylineOptions = {
      strokeColor: isConnectionLost ? shader(color, -0.5) : color,
      strokeWeight: LegRenderType.solid
        ? isEagleView
          ? DEFAULT_STROKE_WIDTH / 2
          : DEFAULT_STROKE_WIDTH
        : isEagleView
        ? DEFAULT_CONNECTOR_STROKE_WIDTH / 2
        : DEFAULT_CONNECTOR_STROKE_WIDTH,
      strokeOpacity: type === LegRenderType.dashed ? 0 : opacity,
      visible: true,
      icons: [],
      zIndex: 1,
    };

    if (!isEagleView) {
      if (showDirectionArrows) {
        options.icons.push(DIRECTION_ARROW_ICON);
      }

      if (showBreadcrumbArrows && !isConnectionLost) {
        options.icons.push(BREADCRUMB_ARROW_ICON);
      }
    }

    if (type === LegRenderType.dashed) {
      options.icons.unshift(DASHED_LINE_ICON);
    }

    return options;
  }

  updatePolylines(legs: Leg[], zoomLevel: number): void {
    _forEach(legs, (leg) => {
      const legRenderType =
        leg.isSicConnector || leg.isPickupClusterConnector ? LegRenderType.dashed : LegRenderType.solid;
      const opacity = leg.hasCompletedStops ? COMPLETED_OPACITY : INCOMPLETED_OPACITY;

      leg.originalPolylineOptions = this.polylineOptions(
        leg.color,
        legRenderType,
        opacity,
        zoomLevel,
        !leg.hasCompletedStops,
        leg.isBreadcrumb,
        leg.connectionLost
      );

      if (leg.polyline) {
        leg.polyline.setMap(null);
      }

      leg.polyline = new google.maps.Polyline(leg.originalPolylineOptions);
      leg.polyline.setPath(leg.points);
    });
  }

  addEventListeners(tripInstId: number, legs: Leg[]): void {
    _forEach(legs, (leg) => {
      leg.eventListeners = [
        leg.polyline.addListener('mouseover', (event: google.maps.MouseEvent) => {
          if (this.layoutPreferenceService.isDispatcherLayout()) {
            this.onMouseOverTrip(tripInstId, {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            });
          } else {
            this.onMouseOverRoute(leg.route.routeInstId, {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            });
          }
        }),

        leg.polyline.addListener('click', (event: google.maps.MouseEvent) => {
          if (this.layoutPreferenceService.isDispatcherLayout()) {
            this.onTripClick(tripInstId, { lat: event.latLng.lat(), lng: event.latLng.lng() });
          } else {
            this.onRouteClick(leg.route.routeInstId, { lat: event.latLng.lat(), lng: event.latLng.lng() });
          }
        }),

        leg.polyline.addListener('rightclick', (event: google.maps.MouseEvent) => {
          let mouseEvent: MouseEvent;
          const keys = Object.keys(event);

          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (event[key] instanceof MouseEvent) {
              mouseEvent = event[key];
              break;
            }
          }

          const rightClickEvent = {
            latLng: event.latLng,
            ya: mouseEvent,
          };

          this.onRightClickRoute(
            tripInstId,
            leg.route.routeInstId,
            { lat: event.latLng.lat(), lng: event.latLng.lng() },
            rightClickEvent
          );
        }),

        leg.polyline.addListener('mouseout', (event: google.maps.MouseEvent) => {
          this.onMouseOutRoute();
        }),
      ];
    });
  }

  clearTripPolylines(tripRenderInfo: TripRenderInfo): void {
    _forEach(tripRenderInfo.projectedLegs, (leg) => {
      _forEach(leg.eventListeners, (listener) => listener.remove());

      leg.eventListeners = undefined;
      if (leg.polyline) {
        leg.polyline.setMap(null);
        leg.polyline = null;
      }
    });

    _forEach(tripRenderInfo.breadcrumbsLegs, (leg) => {
      _forEach(leg.eventListeners, (listener) => listener.remove());

      leg.eventListeners = undefined;
      if (leg.polyline) {
        leg.polyline.setMap(null);
        leg.polyline = null;
      }
    });
  }

  //#region Events

  /**
   * Invoked when the user moves the mouse over a leg in the trip
   * @param tripInstId
   * @param location
   * @private
   */
  private onMouseOverTrip(tripInstId: number, location: google.maps.LatLngLiteral): void {
    this.hoverOverTripSubject.next({ tripInstId, location });
  }

  /**
   * Invoked when the user moves the mouse over a leg in the route
   * @param routeInstId
   * @param location
   * @private
   */
  private onMouseOverRoute(routeInstId: number, location: google.maps.LatLngLiteral): void {
    this.hoverOverRouteSubject.next({ routeInstId, location });
  }

  /**
   * Invoked when the user moves the mouse off of a leg in the route
   * @private
   */
  private onMouseOutRoute(): void {
    this.hoverOverRouteSubject.next(undefined);
  }

  /**
   * Invoked when the user click a leg in the trip
   * @param tripInstId
   * @param location
   * @private
   */
  private onTripClick(tripInstId: number, location: google.maps.LatLngLiteral): void {
    this.tripClickSubject.next({ tripInstId, location });
  }

  /**
   * Invoked when the user click a leg in the route
   * @param routeInstId
   * @param location
   * @private
   */
  private onRouteClick(routeInstId: number, location: google.maps.LatLngLiteral): void {
    this.routeClickSubject.next({ routeInstId, location });
  }

  /**
   * Invoked when the user right click a leg in the route
   * @param tripInstId
   * @param routeInstId
   * @param location
   * @param event
   * @private
   */
  private onRightClickRoute(tripInstId: number, routeInstId: number, location: google.maps.LatLngLiteral, event): void {
    this.rightClickRouteSubject.next({ tripInstId, routeInstId, location, event });
  }

  //#endregion
}
