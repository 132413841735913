// provide enum valies tp match the XpoSnackBarStatus type
export enum NotificationMessageStatus {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export interface NotificationMessageResult {
  status: NotificationMessageStatus;
  actionTaken: boolean; // Used if snackbar is actionable.
}
