export enum MapLayerType {
  DRIVER_MARKER,
  UNASSIGNED_DELIVERIES,
  UNASSIGNED_PICKUPS,
  FORECASTED_PICKUPS,
  SERVICE_CENTER,
  PATHS,
  PICKUP_CLUSTER,
  DELIVERY_PICKUP_HEATMAP,
  DISPATCH_AREAS,
  GEO_OVERLAYS,
  WEATHER_LAYER,
  TRAFFIC_LAYER,
  MAP_LAYER,
  SPOTS_AND_DROPS,
}
