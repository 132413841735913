import { Injectable } from '@angular/core';
import * as GlobalFilterStoreSelectors from '@pnd-store/global-filters-store/global-filters-store.selectors';
import * as ModifyTripDetailsActions from '@pnd-store/modify-trip-details-store/modify-trip-details.actions';
import { ModifyTripActivityId } from '@pnd-store/modify-trip-details-store/modify-trip-details.state';
import { PndStore } from '@pnd-store/pnd-store';
import * as PndStoreState from '@pnd-store/pnd-store.state';
import { XpoLtlFormatValidationService, XpoLtlShipmentDescriptor } from '@xpo-ltl/ngx-ltl';
import { PickupFormTypeEnum } from '@xpo-ltl/ngx-ltl-pickup-request';
import { StagedTripsService } from './staged-trips.service';

import {
  Activity,
  ActivityShipment,
  CityOperationsApiService,
  ConvertStopActivitiesPath,
  ConvertStopActivitiesRqst,
  CustomerProfileNote,
  DispatchTrip,
  GetPnDTripPath,
  GetPnDTripQuery,
  GetPnDTripResp,
  ListCustomerProfileNotesResp,
  ListPnDTripStopsResp,
  MergePnDStopsRqst,
  MergePnDStopsPath,
  RouteSummary,
  SplitPnDTripStopPath,
  SplitPnDTripStopResp,
  SplitPnDTripStopRqst,
  Stop,
  TripDetail,
  TripNode,
  TripNodeActivity,
  TripNodeActivityId,
  TripNodeId,
  TripSummary,
  UnassignStopActivityRqst,
  SpotOrDropEquipment,
} from '@xpo-ltl/sdk-cityoperations';
import { TripDetailCd, TripNodeActivityCd, TripStatusCd, DataValidationError } from '@xpo-ltl/sdk-common';

import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { TripsService } from 'app/inbound-planning/shared/services/trips.service';
import {
  forEach as _forEach,
  isEmpty as _isEmpty,
  keys as _keys,
  map as _map,
  pick as _pick,
  set as _set,
  size as _size,
  uniqBy as _uniqBy,
} from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { PndRouteUtils } from 'shared/route-utils';
import { PndDialogService } from '../../../../../../core/dialogs/pnd-dialog.service';
import { PndTripUtils } from '../../../../../../shared/trip-utils';
import { EventItem, AssignedStopIdentifier } from '../../../../shared';
import { PickupRqst } from '../../../../shared/services/pickup-request-split-panel/pickup-request-split-panel.service';
import { ModifyTripDetailsPanel } from '../enums/modify-trip-details-panel.enum';
import {
  ModifyTripDetailsActivityDetailGridItem,
  ModifyTripDetailsBaseGridItem,
} from '../models/modify-trip-details-grid-item.model';
export interface CustomerNotes {
  notes: { [key: number]: CustomerProfileNote };
}
// TODO for selection state refactor: When refreshing an existing trip, we need to compare previous node list and current node list
// so that we can update the existing selection state to remove the nodes that no longer exist in the trip

@Injectable({
  providedIn: 'root',
})
export class ModifyTripDetailsService {
  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private cityOperationsService: CityOperationsApiService,
    private pndDialogService: PndDialogService,
    private formatValidationService: XpoLtlFormatValidationService,
    private tripsService: TripsService,
    private stagedTripsService: StagedTripsService,
    private locationService: LocationApiService
  ) {}
  get trip() {
    return this.tripSubject.value;
  }
  get supplementalTripDetails() {
    return this.tripSubject.value;
  }
  get tripSummary() {
    return this.tripSummarySubject.value;
  }

  // This is a hack for tender visibility. These two objects ill be combined and supplemental object will be removed
  // when panel only uses single TripDetail instead of TripDetail or DispatchTrip object in https://xpo.atlassian.net/browse/PCT-15239
  private readonly tripSubject = new BehaviorSubject<TripDetail | DispatchTrip>(undefined);
  readonly trip$ = this.tripSubject.asObservable();
  private readonly supplementalTripDetailsSubject = new BehaviorSubject<TripDetail>(undefined);
  readonly supplementalTripDetails$ = this.supplementalTripDetailsSubject.asObservable();
  private readonly notesSubject = new BehaviorSubject<CustomerNotes>(undefined);
  readonly notes$ = this.notesSubject.asObservable();

  private readonly tripSummarySubject = new BehaviorSubject<TripSummary>(undefined);
  readonly tripSummary$ = this.tripSummarySubject.asObservable();

  private readonly spotOrDropEquipmentSubject = new BehaviorSubject<SpotOrDropEquipment[]>([]);
  readonly spotOrDropEquipment$ = this.spotOrDropEquipmentSubject.asObservable();

  private customerNotesSubject = new BehaviorSubject<{ [key: number]: CustomerProfileNote }>(undefined);
  readonly customerNotes$ = this.customerNotesSubject.asObservable();

  private errorSubject = new BehaviorSubject<any>(undefined);
  readonly error$ = this.errorSubject.asObservable();

  // emits true when loading Trip summary, false when done loading
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this.isLoadingSubject.asObservable();

  private navigationPanelSubject = new BehaviorSubject<ModifyTripDetailsPanel>(ModifyTripDetailsPanel.TRIP_DETAILS);
  readonly navigationPanel$ = this.navigationPanelSubject.asObservable();

  private openPickupRequestPanelSubject = new Subject<any>();
  readonly openPickupRequestPanel$ = this.openPickupRequestPanelSubject.asObservable();

  private closeModifyTripPanelSubject = new Subject<any>();
  readonly closeModifyTripPanel$ = this.closeModifyTripPanelSubject.asObservable();

  private closeAssignDropPanelSubject = new Subject<void>();
  readonly closeAssignDropPanel$ = this.closeAssignDropPanelSubject.asObservable();

  private readonly showTripDetailsSectionSubject = new BehaviorSubject<boolean>(false);
  readonly showTripDetailsSection$ = this.showTripDetailsSectionSubject.asObservable();

  updateActivitySuccessSubject = new BehaviorSubject<boolean>(false);
  readonly updateActivitySuccess$ = this.updateActivitySuccessSubject.asObservable();

  // proximal tip instance ids [pevious, current, next]
  proximalTripInstIds: number[] = [];
  stagedTripsMap: Map<number, { tripSummary: TripSummary; tripDetail: TripDetail }> = new Map<
    number,
    { tripSummary: TripSummary; tripDetail: TripDetail }
  >();

  prevNextClicked: boolean = false;

  navigateToPanel(panel: ModifyTripDetailsPanel) {
    this.navigationPanelSubject.next(panel);
  }

  /**
   * Set the Trip to display.  If this trip is already displayed, then we just want to
   * refresh with the latest data
   */
  setTrip(trip: TripDetail | DispatchTrip, shouldReload = true) {
    if (!trip) {
      this.customerNotesSubject.next(undefined);
      this.tripSummarySubject.next(undefined);
      this.supplementalTripDetailsSubject.next(undefined);
    }

    this.tripSubject.next(trip);

    if (shouldReload) {
      this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
    }
  }
  setSupplementalTripDetails(trip: TripDetail) {
    this.supplementalTripDetailsSubject.next(trip);
  }
  toggleShowTripDetailsSection(value: boolean) {
    this.showTripDetailsSectionSubject.next(value);
  }

  getShowTripDetailsSection() {
    return this.showTripDetailsSectionSubject.value;
  }

  // Reset the staged trips on update trip panel close
  clearStagedTripsMap(): void {
    this.proximalTripInstIds = [];
    this.stagedTripsMap = new Map<number, { tripSummary: TripSummary; tripDetail: TripDetail }>();
    this.stagedTripsService.stagedTripsMapSubject.next(this.stagedTripsMap);
    this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
  }

  clearStopsFromMap(): void {
    this.closeModifyTripPanelSubject.next(undefined);
  }

  // Get and set the staged trips map
  getStagedTripSummaries(stagedTripIds: number[]): void {
    stagedTripIds.forEach((tripInstId) => {
      if (!this.stagedTripsMap.get(tripInstId) && tripInstId) {
        forkJoin([this.fetchTripSummary$(tripInstId), this.fetchSupplementalTripDetailsForTrip$(tripInstId)])
          .pipe(take(1))
          .subscribe(([tripSummary, supplementalTripDetails]: [TripSummary, TripDetail]) => {
            this.fetchCustomerNotes(tripSummary).pipe(
              take(1),
              catchError((error) => {
                this.errorSubject.next(error);
                return of(undefined);
              }),
              map((customerNotes: CustomerNotes) => {
                this.customerNotesSubject.next(customerNotes.notes);
                this.tripSummarySubject.next(tripSummary);
                this.supplementalTripDetailsSubject.next(supplementalTripDetails);
                this.errorSubject.next(undefined);
                this.assignUniqueIds(tripSummary);
              })
            );
            this.stagedTripsMap.set(tripInstId, { tripSummary: tripSummary, tripDetail: supplementalTripDetails });
            this.stagedTripsService.stagedTripsMapSubject.next(this.stagedTripsMap);
          });
      }
    });
  }

  /**
   * Refresh the latest Stop data for the current Trip
   */
  refreshTrip$(): Observable<TripSummary> {
    const tripInstId = this.getCurrentTripInstId();

    // Use staged trips map
    if (this.stagedTripsMap.get(tripInstId) && this.prevNextClicked) {
      this.isLoadingSubject.next(false);
      this.tripSummarySubject.next(this.stagedTripsMap.get(tripInstId).tripSummary);
      this.assignUniqueIds(this.stagedTripsMap.get(tripInstId).tripSummary);
      this.supplementalTripDetailsSubject.next(this.stagedTripsMap.get(tripInstId).tripDetail);
      this.errorSubject.next(undefined);
      this.prevNextClicked = false;
      return of(this.stagedTripsMap.get(tripInstId).tripSummary);
    } else if (tripInstId) {
      this.isLoadingSubject.next(true);

      return forkJoin([this.fetchTripSummary$(tripInstId), this.fetchSupplementalTripDetailsForTrip$(tripInstId)]).pipe(
        switchMap(([tripSummary, supplementalTripDetails]: [TripSummary, TripDetail]) => {
          if (!tripSummary) {
            return of(undefined);
          }

          return this.fetchCustomerNotes(tripSummary).pipe(
            take(1),
            catchError((error) => {
              this.errorSubject.next(error);
              return of(undefined);
            }),
            map((customerNotes: CustomerNotes) => {
              this.customerNotesSubject.next(customerNotes.notes);
              this.tripSummarySubject.next(tripSummary);
              this.supplementalTripDetailsSubject.next(supplementalTripDetails);
              this.errorSubject.next(undefined);
              this.assignUniqueIds(tripSummary);

              return tripSummary;
            })
          );
        }),
        finalize(() => {
          this.isLoadingSubject.next(false);
        })
      );
    } else {
      return of(undefined);
    }
  }

  private fetchTripSummary$(tripInstId: number): Observable<TripSummary> {
    if (!tripInstId) {
      return of(undefined);
    }
    return this.cityOperationsService.listPnDTripStops({ tripInstId }).pipe(
      map((resp: ListPnDTripStopsResp) => resp?.tripSummary),
      catchError((error) => {
        this.errorSubject.next(error);
        return of(undefined);
      })
    );
  }

  updateSupplementalTripDetailsForTrip$(tripInstId: number): Observable<TripDetail> {
    return this.fetchSupplementalTripDetailsForTrip$(tripInstId).pipe(
      tap((tripDetail) => {
        this.supplementalTripDetailsSubject.next(tripDetail);
      })
    );
  }

  /**
   * Fetches the main trip detail object. Note..this is DIFFERENT than refreshTrip$ which
   * updates the supplemental objects (trip summary, supplemental details, customer notes)
   * It's confusing..i know. This should all be reworked in https://xpo.atlassian.net/browse/PCT-15239
   */
  fetchTripDetailsForTrip$(tripInstId: number): Observable<TripDetail> {
    const sicCd: string = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);

    const path = {
      ...new GetPnDTripPath(),
      sicCd,
      tripInstId,
    };

    const query = {
      ...new GetPnDTripQuery(),
      tripDetailCds: undefined,
    };

    return this.cityOperationsService.getPnDTrip(path, query).pipe(
      map((resp: GetPnDTripResp) => resp?.tripDetail),
      catchError(() => of(undefined))
    );
  }

  private fetchSupplementalTripDetailsForTrip$(tripInstId: number): Observable<TripDetail> {
    if (!tripInstId) {
      return of(undefined);
    }
    const sicCd: string = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);

    const path = {
      ...new GetPnDTripPath(),
      sicCd,
      tripInstId,
    };

    const query = {
      ...new GetPnDTripQuery(),
      tripDetailCds: [
        TripDetailCd.DISPATCH_GROUPS,
        TripDetailCd.DRIVER,
        TripDetailCd.NOTES,
        TripDetailCd.EQUIPMENT,
        TripDetailCd.ROUTES,
        TripDetailCd.STOPS,
        TripDetailCd.ACTIVITIES,
        TripDetailCd.CUSTOMER,
      ],
    };

    return this.cityOperationsService.getPnDTrip(path, query).pipe(
      map((resp: GetPnDTripResp) => resp?.tripDetail),
      catchError(() => of(undefined))
    );
  }

  private fetchCustomerNotes(tripSummary: TripSummary): Observable<CustomerNotes> {
    return new Observable((observer) => {
      const customerProfileNotes: { [key: number]: CustomerProfileNote } = {};

      // Create empty map
      _forEach(tripSummary?.routeSummary || [], (routeSummary: RouteSummary) => {
        _forEach(routeSummary.stops, (stop) => {
          if (stop.customer) {
            customerProfileNotes[stop.customer.acctInstId] = undefined;
          }
        });
      });

      const customerAcctIds: number[] = _map(_keys(customerProfileNotes), (key) => +key);

      if (_size(customerAcctIds) > 0) {
        this.cityOperationsService
          .listCustomerProfileNotes({ customerAcctIds })
          .pipe(
            take(1),
            catchError((error) => {
              return of({});
            })
          )
          .subscribe((listCustomerProfileNotesResp: ListCustomerProfileNotesResp) => {
            _forEach(
              listCustomerProfileNotesResp?.customerProfileNotes ?? [],
              (customerProfileNote: CustomerProfileNote) => {
                const customerNote: CustomerProfileNote = new CustomerProfileNote();
                customerNote.customerAcctId = customerProfileNote.customerAcctId;
                customerNote.deliveryInstructions = customerProfileNote?.deliveryInstructions ?? [];
                customerNote.noaNotes = customerProfileNote?.noaNotes ?? [];
                customerNote.operationNotes = customerProfileNote?.operationNotes ?? [];
                customerNote.pickupInstructions = customerProfileNote?.pickupInstructions ?? [];
                customerProfileNotes[customerProfileNote.customerAcctId] = customerNote;
              }
            );

            this.notesSubject.next({ notes: customerProfileNotes });
            observer.next({ notes: customerProfileNotes });
            observer.complete();
          });
      } else {
        this.notesSubject.next({ notes: customerProfileNotes });
        observer.next({ notes: customerProfileNotes });
        observer.complete();
      }
    });
  }

  /**
   * Traverse the trip and give each stop/activitity/shipment a unique ID
   */
  private assignUniqueIds(tripSummary: TripSummary) {
    if (!tripSummary) {
      return;
    }

    tripSummary.routeSummary.forEach((summary: RouteSummary, stopIndex: number) => {
      _forEach(summary.stops, (stop: Stop) => {
        const routeName = PndRouteUtils.getRouteId(summary.route);
        const customer = stop?.customer?.name1 ?? 'CUSTOMER';
        const stopUniqueId = `${stop.tripNode.tripInstId}-${routeName}-${customer}-${stop.tripNode.tripNodeTypeCd}-${stop.tripNode.stopSequenceNbr}-${stopIndex}`;
        _set(stop, 'uniqueId', stopUniqueId);

        _forEach(stop.activities, (activity: Activity, activityIndex: number) => {
          const activityUniqueId = `${stopUniqueId}-${activity.tripNodeActivity.activityCd}-${activity.tripNodeActivity.tripNodeSequenceNbr}-${activity.tripNodeActivity.tripNodeActivitySequenceNbr}-${activityIndex}`;
          _set(activity, 'uniqueId', activityUniqueId);

          _forEach(activity.activityShipments, (shipment: ActivityShipment, shipmentIndex: number) => {
            const shipmentUniqueId = `${activityUniqueId}-${shipment?.shipmentInstId ?? ''}-${
              shipment.proNbr
            }-${shipmentIndex}`;
            _set(shipment, 'uniqueId', shipmentUniqueId);
          });
        });
      });
    });
  }

  /**
   * Unassign stops/activities from their currently assigned Trip
   */
  unassign$(
    stops: ModifyTripActivityId[],
    activities: ModifyTripActivityId[],
    pickupActivityToUnassign: ModifyTripActivityId[]
  ): Observable<void> {
    let unassignStopsObs$ = of(undefined) as Observable<void>;
    const allStops: ModifyTripActivityId[] = [];
    allStops.push(...stops, ...pickupActivityToUnassign, ...activities);
    if (_size(allStops)) {
      unassignStopsObs$ = this.unassignStopActivities$(allStops);
    }
    return unassignStopsObs$.pipe(
      finalize(() => {
        const routeInstIds: number[] = [
          ...stops.map((stop) => stop.routeInstId),
          ...activities.map((activity) => activity.routeInstId),
        ];

        // Clear cached stops to force api call for this routes and refetch all the stops
        this.tripsService.clearCachedStops(routeInstIds);
      })
    );
  }

  private unassignStopActivities$(activitiesToUnassign: ModifyTripActivityId[]): Observable<void> {
    if (!_size(activitiesToUnassign)) {
      return of(undefined);
    }
    const pickup = new Map<number, Set<number>>();

    activitiesToUnassign.forEach((activity) => {
      if (pickup.has(activity?.tripNodeSequenceNbr)) {
        const arr: Set<number> = pickup.get(activity?.tripNodeSequenceNbr);
        arr.add(activity?.tripNodeActivitySequenceNbr);
        pickup.set(activity?.tripNodeSequenceNbr, arr);
      } else {
        const arr = new Set<number>();
        arr.add(activity?.tripNodeActivitySequenceNbr);
        pickup.set(activity?.tripNodeSequenceNbr, arr);
      }
    });

    const tripNodeArr: TripNodeId[] = [];
    pickup.forEach((tripNodeActivitySequenceNbr: Set<number>, tripNodeSequenceNbr: number) => {
      const tripNodeId = new TripNodeId();
      tripNodeId.tripNodeSeqNbr = tripNodeSequenceNbr;
      const arr: TripNodeActivityId[] = [];
      tripNodeActivitySequenceNbr.forEach((id) => {
        const activityId = new TripNodeActivityId();
        activityId.tripNodeActivitySeqNbr = id;
        arr.push(activityId);
      });
      tripNodeId.tripNodeActivities = arr;
      tripNodeArr.push(tripNodeId);
    });

    const request = new UnassignStopActivityRqst();
    request.tripInstId = this.getCurrentTripInstId();
    request.tripNodes = tripNodeArr;
    return this.cityOperationsService.unassignStopActivity(request);
  }

  /**
   * Split the specified Activities into new Stop and returns the new TripNode
   * Throws an error if activities provided belong to more then 1 stop
   * If targetStop is provided, attempts to assign the activities to the target TripNode
   */
  splitShipments$(activityIds: ModifyTripActivityId[], targetStop?: TripNode): Observable<TripNode> {
    if (_size(activityIds) === 0) {
      // no activities to split
      return of(undefined);
    } else if (!this.areActivitiesFromSameStop(activityIds)) {
      // activities selected from multiple trip nodes, so can't split
      return throwError({ error: { message: 'Please select shipments to Split from a Single Stop' } });
    } else {
      // split activities from original TripNode

      const originalStop = _pick(activityIds[0], ['tripInstId', 'tripNodeSequenceNbr']) as TripNode;

      const splitActivities = _map(activityIds, (activityId) => {
        const activity = _pick(activityId, [
          'tripInstId',
          'tripNodeSequenceNbr',
          'tripNodeActivitySequenceNbr',
          'shipmentInstId',
        ]) as TripNodeActivity;
        return activity;
      });

      const request: SplitPnDTripStopRqst = {
        originalStop,
        targetStop,
        splitActivities,
      };

      const pathParams: SplitPnDTripStopPath = { tripInstId: originalStop.tripInstId };

      return this.cityOperationsService.splitPnDTripStop(request, pathParams).pipe(
        map((result: SplitPnDTripStopResp) => result.newStop),
        finalize(() => {
          // Clear cached stops to force api call for this route and refetch all the stops
          this.tripsService.clearCachedStops([activityIds[0].routeInstId]);
        })
      );
    }
  }

  mergeStops$(
    stops: EventItem<AssignedStopIdentifier>[],
    target: EventItem<AssignedStopIdentifier>
  ): Observable<DataValidationError | DataValidationError[]> {
    const targetStop = _pick(target.id, ['tripInstId', 'tripNodeSequenceNbr']) as TripNode;
    const overrideWarningsInd = true;
    const sourceStops: TripNode[] = [];
    stops = stops?.filter((item, i, uniqueList) => {
      return (
        uniqueList.indexOf(
          uniqueList.find(
            (t) =>
              t.id?.customerAcctIntId === item.id?.customerAcctIntId &&
              t.id?.tripNodeSequenceNbr === item.id?.tripNodeSequenceNbr
          )
        ) === i
      );
    });
    stops?.forEach((stop) => {
      if (stop.id.tripNodeSequenceNbr !== targetStop.tripNodeSequenceNbr) {
        sourceStops.push({
          tripInstId: stop.id.tripInstId,
          tripNodeSequenceNbr: stop.id.tripNodeSequenceNbr,
        } as TripNode);
      }
    });

    const request: MergePnDStopsRqst = {
      targetStop,
      sourceStops,
      overrideWarningsInd,
    };

    const pathParams: MergePnDStopsPath = { tripInstId: targetStop.tripInstId };

    return this.cityOperationsService.mergePnDStops(request, pathParams).pipe(map((result) => result.warnings));
  }

  convertActivities$(
    activityIds: ModifyTripActivityId[],
    source: TripNodeActivityCd,
    target: TripNodeActivityCd
  ): Observable<void> {
    if (_size(activityIds) === 0) {
      return of(undefined);
    } else {
      const originalStop = _pick(activityIds[0], ['tripInstId', 'tripNodeSequenceNbr']) as TripNode; // all activities will have the same
      const seqNumbers: number[] = activityIds.map((activity) => {
        return activity?.tripNodeActivitySequenceNbr;
      });

      const request: ConvertStopActivitiesRqst = {
        sourceActivityType: source,
        targetActivityType: target,
        stop: originalStop,
        tripNodeActivitySequenceNbrs: seqNumbers,
      };

      const pathParams: ConvertStopActivitiesPath = {
        tripInstId: originalStop.tripInstId,
        tripNodeSequenceNbr: activityIds[0].tripNodeSequenceNbr,
      };
      return this.cityOperationsService.convertStopActivities(request, pathParams);
    }
  }

  /**
   * returns true only if all opf the activities are located at the same Stop in the same Trip
   */
  private areActivitiesFromSameStop(activityIds: ModifyTripActivityId[]): boolean {
    const onlyOneTrip = _uniqBy(activityIds, 'tripInstId').length === 1;
    const onlyOneRoute = _uniqBy(activityIds, 'routeInstId').length === 1;
    const onlyOneStop = _uniqBy(activityIds, 'tripNodeSequenceNbr').length === 1;
    return onlyOneTrip && onlyOneRoute && onlyOneStop;
  }

  getCurrentTripInstId(): number {
    return this.tripSubject.value ? PndTripUtils.getTripId(this.tripSubject.value) : undefined;
  }

  getCurrentTripStatusCd(): TripStatusCd {
    return this.tripSubject.value ? PndTripUtils.getTripStatusCd(this.tripSubject.value) : undefined;
  }

  /**
   * Show the shipment details dialog
   */
  showShipmentDetails(id: XpoLtlShipmentDescriptor) {
    if (!_isEmpty(id.proNbr) && this.formatValidationService.isValidProNumber(id.proNbr)) {
      this.pndDialogService
        .showShipmentDetailsDialog({ proNbr: id.proNbr, shipmentInstId: id.shipmentInstId })
        .pipe(take(1))
        .subscribe();
    }
  }

  /**
   * Show the BOL dialog, (in case of DL with PRO) or eBOL (for PU)
   */
  showBoLDialogForItem(gridItem: ModifyTripDetailsBaseGridItem | ModifyTripDetailsActivityDetailGridItem) {
    if (gridItem) {
      if (gridItem.bolInstId > 0) {
        this.pndDialogService.showEBoLDialog(gridItem.bolInstId);
      } else if (!_isEmpty(gridItem.proNbr) && this.formatValidationService.isValidProNumber(gridItem.proNbr)) {
        this.pndDialogService.showBoLDialog(gridItem.proNbr);
      }
    }
  }

  createMessageString(dispatchTripButtonValidationMessages: string[]): string {
    if (dispatchTripButtonValidationMessages.length === 1) {
      return `${dispatchTripButtonValidationMessages[0]} Required`;
    }

    const lastElement = dispatchTripButtonValidationMessages.pop();
    return `${dispatchTripButtonValidationMessages.join(',')} and ${lastElement} Required`;
  }

  openPickupRequest(formType: PickupFormTypeEnum, pickupRequest?: PickupRqst) {
    this.openPickupRequestPanelSubject.next({
      formType: formType,
      pickup: pickupRequest,
    });
  }

  closeAssignDropPanel(): void {
    this.closeAssignDropPanelSubject.next();
  }

  setSpotOrDropEquipment(spotOrDropEquipment: SpotOrDropEquipment[]): void {
    this.spotOrDropEquipmentSubject.next(spotOrDropEquipment);
  }
}
