import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouteStatusCd, TripStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, withLatestFrom } from 'rxjs/operators';
import { DispatcherTripsAlert } from '../../../components/dispatcher-trips/models/dispatcher-trips-alert';
import { DispatcherTripsGridItem } from '../../../components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { DispatchExceptionTooltipCellRendererParams } from '../dispatch-exception-tooltip-cell-renderer';
import { DriverContactsService } from './../../services/driver-contacts.service';

export interface DispatchDriverCellRendererValue {
  driverName: string;
  dsrEmployeeId: string;
  labels: DispatchDriverLabel[];
  tripInstId?: number;
  isTotal?: boolean;
}
export interface DispatchDriverLabel {
  text: string;
  color: string;
}

@Component({
  selector: 'pnd-dispatch-driver-cell-renderer',
  templateUrl: './dispatch-driver-cell-renderer.component.html',
  styleUrls: ['./dispatch-driver-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispatchDriverCellRendererComponent implements ICellRendererAngularComp {
  alert: DispatcherTripsAlert;
  showContactInfo: boolean;
  dsrCellPhoneNbr: string;
  handheldPhoneNbr: string;
  private valueSubject = new BehaviorSubject<DispatchDriverCellRendererValue>(undefined);
  readonly value$: Observable<DispatchDriverCellRendererValue> = this.valueSubject.asObservable();

  readonly displayDriverNotification$: Observable<boolean>;

  constructor(private driversContactService: DriverContactsService) {
    this.displayDriverNotification$ = this.driversContactService.driverContacts$.pipe(
      withLatestFrom(this.value$),
      filter(([_, value]) => !!value),
      map(([_, value]) => {
        return this.driversContactService.getContact(value.dsrEmployeeId, value.tripInstId);
      }),
      pluck('unreadMessages'),
      map((unreadMessageCount: number) => !!unreadMessageCount),
      distinctUntilChanged()
    );
  }

  agInit(params: DispatchExceptionTooltipCellRendererParams) {
    const allowContactInfo: string[] = [RouteStatusCd.NEW, RouteStatusCd.DISPATCHED, RouteStatusCd.RETURNING];
    this.showContactInfo =
      !allowContactInfo.includes(params?.node?.key) &&
      !(params?.node?.rowPinned === 'bottom') &&
      (params?.node?.data?.dispatchTrip?.tripStatusCd === TripStatusCd.DISPATCHED ||
        params?.node?.data?.dispatchTrip?.tripStatusCd === TripStatusCd.RETURNING);
    this.alert = params
      .getAlertsFn(params.data as DispatcherTripsGridItem)
      .find((exception) => exception.type === params.alertType);
    this.valueSubject.next(params.value);
    this.dsrCellPhoneNbr = params?.data?.dispatchTrip?.dispatchDriver?.dsrCellPhoneNbr ?? '';
    this.handheldPhoneNbr = params?.data?.dispatchTrip?.dispatchDriver?.handheldPhoneNumber ?? '';
  }
  trackLablelBy(index, label: DispatchDriverLabel): string | null {
    if (!label) {
      return null;
    }
    return label?.text + index;
  }
  refresh(params: DispatchExceptionTooltipCellRendererParams) {
    this.agInit(params);
    return true;
  }
}
