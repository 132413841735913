import { Directive, OnInit } from '@angular/core';

import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { GlOnClose, GlOnHide, GlOnShow } from 'ngx-golden-layout';

import { LayoutManagerService } from '../../services/layout-manager.service';

@Directive()
export abstract class LayoutPanelComponent implements GlOnClose, GlOnHide, GlOnShow, OnInit {
  readonly componentType: string; // GoldenLayout component type name

  constructor(componentType: string, protected layoutManagerService: LayoutManagerService) {
    this.componentType = componentType;
  }

  ngOnInit() {}

  glOnShow() {}

  glOnHide() {}

  async glOnClose(): Promise<void> {}

  // Additional events we can react to
  // glOnResize() {}
  // glOnTab() {}
  // glOnPopin() {}
  // glOnPopout() {}
  // glOnUnload() {}

  /**
   * Create a keyboard ShortcutInput for this Panel
   * Activate the panel if it is not active, or toggle maximize if it is
   */
  protected createShortcut(description: string, keys: string[]): ShortcutInput[] {
    return [
      {
        key: keys,
        description,
        label: 'Panels',
        command: (e) => {
          const panel = this.layoutManagerService.getPanel(this.componentType);
          if (this.layoutManagerService.isPanelActive(panel)) {
            this.layoutManagerService.toggleMaximize(panel);
          } else {
            this.layoutManagerService.activatePanel(this.componentType);
          }
        },
        preventDefault: true,
      },
    ];
  }
}
