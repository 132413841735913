import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const GRID_BOTTOM_DRAWER_HIDE_DELAY = 200;

@Component({
  selector: 'pnd-grid-bottom-drawer',
  templateUrl: './grid-bottom-drawer.component.html',
  styleUrls: ['./grid-bottom-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false => true', animate('300ms')),
      transition('true => false', animate(GRID_BOTTOM_DRAWER_HIDE_DELAY)),
    ]),
  ],
})
export class GridBottomDrawerComponent {
  @Input()
  set isDrawerOpen(open) {
    if (open !== this.isDrawerOpenSubject.value) {
      this.isDrawerOpenSubject.next(open);
    }
  }

  private isDrawerOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isDrawerOpen$: Observable<boolean> = this.isDrawerOpenSubject.asObservable();

  constructor() {}
}
