import { Injectable } from '@angular/core';
import { padStart as _padStart } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class CallNbrService {
  constructor() {}

  getDDNNNFormat(number: string): string {
    let result: string;
    if (!!number) {
      const split_arr = number.split('-');
      let dd: string = split_arr[0];
      let nnn: string = split_arr[1];
      dd = _padStart(dd, 2, '0');
      nnn = _padStart(nnn, 3, '0');

      result = dd + '-' + nnn;
    }
    return result;
  }
}
