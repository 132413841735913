import { Injectable } from '@angular/core';
import { SpotsAndDropsSearchCriteria } from '@pnd-store/spots-and-drops-store/spots-and-drops-search-criteria.interface';
import {
  CityOperationsApiService,
  ListSpotOrDropEquipmentPath,
  ListSpotOrDropEquipmentQuery,
  ListSpotOrDropEquipmentResp,
  SpotOrDropEquipment,
} from '@xpo-ltl/sdk-cityoperations';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SpotsAndDropsService {
  private readonly spotsAndDropsSubject = new BehaviorSubject<SpotOrDropEquipment[]>([]);
  spotsAndDrops$ = this.spotsAndDropsSubject.asObservable();
  get spotsAndDrops(): SpotOrDropEquipment[] {
    return this.spotsAndDropsSubject.value;
  }

  private loadingSpotsAndDropsSubject = new BehaviorSubject<boolean>(false);
  readonly loadingSpotsAndDrops$ = this.loadingSpotsAndDropsSubject.asObservable();

  constructor(private cityOperationsApi: CityOperationsApiService) {}

  /**
   * Fetch the spot and drop that match the specified Criteria
   */
  fetchSpotsAndDrops$(criteria: SpotsAndDropsSearchCriteria): Observable<SpotOrDropEquipment[]> {
    const path: ListSpotOrDropEquipmentPath = {
      sicCd: criteria.sicCd,
    };
    const query: ListSpotOrDropEquipmentQuery = {
      zoneIndicatorCd: criteria.zoneIndicatorCd,
    };
    this.loadingSpotsAndDropsSubject.next(true);
    return this.cityOperationsApi.listSpotOrDropEquipment(path, query).pipe(
      catchError((error) => {
        console.error('ERROR: SpotsAndDrops.fetchSpotsAndDrops: ', error);
        this.loadingSpotsAndDropsSubject.next(false);
        return of(undefined);
      }),
      map((response: ListSpotOrDropEquipmentResp) => {
        const searchResults: SpotOrDropEquipment[] = [];
        if (response) {
          searchResults.push(...response?.spotOrDropEquipment);
        }
        this.spotsAndDropsSubject.next(response?.spotOrDropEquipment ?? []);
        this.loadingSpotsAndDropsSubject.next(false);
        return searchResults;
      })
    );
  }
}
