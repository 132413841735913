import { Injectable } from '@angular/core';
import { find as _find, forEach as _forEach } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomerProfileSplitPanelParams } from '../../components/planning-map-splitter/customer-profile-split-panel-params.model';
import {
  MapSplitPanelItem,
  MapSplitPanelItemData,
  SplitPanelComponentType,
} from '../../components/planning-map/components/map-split-panels/map-split-panel-item';
import { MapSplitPanelItemsId } from '../enums/map-split-panel-items-id.enum';
import { MapSplitPanelTransitionState } from '../enums/map-split-panel-transition-state.enum';

@Injectable({ providedIn: 'root' })
export class MapSplitPanelService {
  private readonly updateComponentDataSubject = new Subject<{
    componentId: string;
    updatedData: Partial<MapSplitPanelItemData>;
  }>();
  readonly updateComponentData$ = this.updateComponentDataSubject.asObservable();

  private readonly componentsListSubject = new BehaviorSubject<MapSplitPanelItem[]>([]);
  readonly componentsList$ = this.componentsListSubject.asObservable();

  private readonly transitionStateSubject = new BehaviorSubject<MapSplitPanelTransitionState>(
    MapSplitPanelTransitionState.CLOSED
  );
  readonly transitionState$ = this.transitionStateSubject.asObservable();

  private readonly newPanelOpenedSubject = new Subject();
  readonly newPanelOpened$ = this.newPanelOpenedSubject.asObservable();

  getPanelItem(panelId: string): MapSplitPanelItem {
    return _find(this.componentsListSubject.value, (oldItem) => oldItem.data.id === panelId);
  }

  addPanelItem(panelItem: MapSplitPanelItem, replacePanelIfExisting = true): void {
    let newArr: MapSplitPanelItem[] = this.componentsListSubject.value;

    const existingPanel = this.getPanelItem(panelItem.data.id);
    let update = false;
    if (!existingPanel) {
      newArr = newArr.concat([panelItem]);
      update = true;
    } else if (replacePanelIfExisting) {
      newArr = newArr.map((oldPanel) => (oldPanel.data.id === panelItem.data.id ? panelItem : oldPanel));
      update = true;
    }

    if (panelItem.data.expanded) {
      newArr.forEach((item) => (item.data.expanded = item.data.id === panelItem.data.id));
      update = true;
      this.newPanelOpenedSubject.next();
    }
    if (update) {
      this.componentsListSubject.next(newArr);
    }
  }

  removePanelById(id: string): void {
    let removedList: MapSplitPanelItem[];
    this.componentsList$.pipe(take(1)).subscribe((oldArr) => {
      removedList = oldArr.filter((item: MapSplitPanelItem) => item.data.id !== id);
    });
    this.componentsListSubject.next(removedList);
  }

  removePanelItem(panelItem: MapSplitPanelItem): void {
    let newArr: MapSplitPanelItem[] = [];
    if (this.getPanelItem(panelItem.data.id)) {
      newArr = this.componentsListSubject.value.filter((item: MapSplitPanelItem) => {
        return item.data.id !== panelItem.data.id;
      });
      this.componentsListSubject.next(newArr);
    }
  }

  collapseAllPanelItems() {
    const newArr: MapSplitPanelItem[] = this.componentsListSubject.value;
    _forEach(newArr, (item) => {
      item.data.expanded = false;
    });
    this.componentsListSubject.next(newArr);
  }

  expandPanelItem(panelId: string) {
    const panel = this.getPanelItem(panelId);
    if (!!panel) {
      _forEach(this.componentsListSubject.value, (item) => (item.data.expanded = false));
      panel.data.expanded = true;
      this.componentsListSubject.next(this.componentsListSubject.value);
    }
  }

  removeAllItems(): void {
    this.componentsListSubject.next([]);
  }

  updateComponentData(componentId: string, updatedData: Partial<MapSplitPanelItemData>) {
    this.updateComponentDataSubject.next({ componentId, updatedData });
  }

  registerTransitionEnd(newState: MapSplitPanelTransitionState) {
    this.transitionStateSubject.next(newState);
  }

  openSplitPanelProfile(acctInstId: number) {
    if (acctInstId) {
      const customerProfileItem = new MapSplitPanelItem(SplitPanelComponentType.CustomerProfile, {
        title: 'Customer Profile',
        id: MapSplitPanelItemsId.CUSTOMER_PROFILE,
        orderIndex: 1,
        expanded: true,
        params: <CustomerProfileSplitPanelParams>{
          customerAcctId: acctInstId,
        },
      });
      this.addPanelItem(customerProfileItem, true);
    }
  }

  closeSplitPanelProfile() {
    this.removePanelById(MapSplitPanelItemsId.CUSTOMER_PROFILE);
  }
}
