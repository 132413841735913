import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import {
  Activity,
  CityOperationsApiService,
  ListPnDStopsPath,
  ListPnDStopsQuery,
  ListPnDStopsResp,
  ListPnDTripStopsResp,
  Stop,
} from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd, TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  DataItemCollectionType,
  DataSet,
  Timeline,
  TimelineOptions,
  TimelineTimeAxisOption,
  TimelineTooltipOption,
} from 'vis';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { PndStore } from '../../../../store/pnd-store';
import { DispatcherTripsGridItem } from '../../../components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { TripPlanningGridItem } from '../../../components/trip-planning/models/trip-planning-grid-item.model';
import { StopDetailsService } from '../../services/stop-details.service';

export interface RouteTimelineICellRendererParams extends ICellRendererParams {
  fromDispatcherTrips: boolean;
  data: DispatcherTripsGridItem | TripPlanningGridItem;
}

@Component({
  selector: 'pnd-route-timeline-cell-renderer',
  template: `
    <div (click)="onTimelineClicked($event)" id="timeline" #visjsTimeline></div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class RouteTimelineCellRendererComponent implements ICellRendererAngularComp {
  @ViewChild('visjsTimeline', { static: true }) routeTimelineContainer: ElementRef;

  timeline: Timeline;
  params: RouteTimelineICellRendererParams;

  private readonly showTimelineSubject = new BehaviorSubject<boolean>(false);
  readonly showTimeline$ = this.showTimelineSubject.asObservable();

  private planDate: Date;

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: PndStore<PndStoreState.State>,
    private timeService: XpoLtlTimeService,
    private stopDetailService: StopDetailsService
  ) {}

  agInit(params: RouteTimelineICellRendererParams): void {
    const currentSicCd = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    const getStops$ = params.fromDispatcherTrips
      ? this.getStopsForTrip$((params.data as DispatcherTripsGridItem)?.tripInstId)
      : this.getStopsForCurrentRoute$((params.data as TripPlanningGridItem)?.route?.routeInstId);

    forkJoin([this.timeService.timezoneForSicCd$(currentSicCd), getStops$])
      .pipe(take(1))
      .subscribe(([timezoneForSicCd, stops]: [string, Stop[]]) => {
        if (stops === undefined) {
          // Don't render rows with empty ids (route or trips), for example totals row
          return;
        }

        this.planDate = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterPlanDate);
        this.params = params;

        const startTime = moment(this.planDate)
          .hour(5)
          .minute(0)
          .second(0);
        const endTime = moment(this.planDate)
          .hour(21)
          .minute(0)
          .second(0);

        const timelineDataSet: DataItemCollectionType = this.buildDataSet(stops, currentSicCd);

        const timelineOptions: TimelineOptions = this.buildTimelineOptions(startTime, endTime);

        this.timeline = new Timeline(this.routeTimelineContainer.nativeElement, timelineDataSet, timelineOptions);

        this.timeline.setCurrentTime(moment.tz(new Date(), timezoneForSicCd).format('YYYY-MM-DDTHH:mm:ss'));

        this.showTimelineSubject.next(true);
      });
  }

  refresh(params: RouteTimelineICellRendererParams): boolean {
    this.showTimelineSubject.next(false);
    this.agInit(params);
    return true;
  }

  onTimelineClicked(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  private getStopsForTrip$(tripInstId: number): Observable<Stop[]> {
    if (!tripInstId) {
      return of(undefined);
    }
    return this.cityOperationsService.listPnDTripStops({ tripInstId }).pipe(
      map((response: ListPnDTripStopsResp) => {
        const stops: Stop[] = [];
        response?.tripSummary?.routeSummary?.forEach((routeSummary) => stops.push(...routeSummary.stops));
        return stops.filter((stop: Stop) => stop.tripNode && stop.activities);
      })
    );
  }

  private getStopsForCurrentRoute$(routeInstId: number): Observable<Stop[]> {
    if (!routeInstId) {
      return of(undefined);
    }

    const pathParams: ListPnDStopsPath = { ...new ListPnDStopsPath(), routeInstId: `${routeInstId}` };
    const queryParams: ListPnDStopsQuery = { ...new ListPnDStopsQuery() };
    return this.cityOperationsService.listPnDStops(pathParams, queryParams).pipe(
      map((response: ListPnDStopsResp) => {
        return response?.stops?.filter((stop: Stop) => stop.tripNode && stop.activities);
      })
    );
  }

  private buildDataSet(stops: Stop[], currentSicCd: string): DataItemCollectionType {
    let lowerStartTime: moment.Moment;
    let upperEndTime: moment.Moment;
    const dataSet = new DataSet();

    const stopColorFunc = (activities: Activity[], status: string): string => {
      const inProgress =
        activities.findIndex((a) => a.tripNodeActivity.activityCd === TripNodeActivityCd.ARRIVE) >= 0 &&
        activities.findIndex((a) => a.tripNodeActivity.activityCd === TripNodeActivityCd.DEPART_DISPATCH) === -1;
      if (status === TripNodeStatusCd.COMPLETED) {
        return 'stop-complete';
      } else if (inProgress) {
        return 'stop-in-progress';
      } else {
        return 'stop-incomplete';
      }
    };

    let tripInstId: number;
    let routeInstId: number;
    if (this.params.fromDispatcherTrips) {
      const data: DispatcherTripsGridItem = this.params.data as DispatcherTripsGridItem;
      tripInstId = data.tripInstId;
      routeInstId = undefined;
    } else {
      const data: TripPlanningGridItem = this.params.data as TripPlanningGridItem;
      tripInstId = data.route.tripInstId;
      routeInstId = data.route.routeInstId;
    }

    stops.forEach((stop: Stop) => {
      const stopStatus = stop?.tripNode?.statusCd || '';
      const customerName = stop?.customer?.name1;

      let arrivalDateTime: string | Date = stop?.tripNode?.estimatedArriveDateTime;
      let departureDateTime: string | Date = stop?.tripNode?.estimatedDepartDateTime;

      if (stopStatus === TripNodeStatusCd.COMPLETED) {
        arrivalDateTime = this.stopDetailService.getArrivalDateTime(stop);
        departureDateTime = this.stopDetailService.getDepartureDateTime(stop);
      }

      if (arrivalDateTime && departureDateTime && customerName) {
        // format date value into current sic time.
        arrivalDateTime = this.timeService.formatDate(arrivalDateTime, undefined, currentSicCd) as string;
        departureDateTime = this.timeService.formatDate(departureDateTime, undefined, currentSicCd) as string;

        const startTime = this.timeService.to24Time(arrivalDateTime);
        const endTime = this.timeService.to24Time(departureDateTime);

        dataSet.add({
          id: `trip-${tripInstId}-route-${routeInstId}-stop-${stop?.tripNode?.tripNodeSequenceNbr}`,
          start: this.convertToVisDateTime(arrivalDateTime),
          end: this.convertToVisDateTime(departureDateTime),
          title: `<div class="item-hint">${this.stopDetailService.getStopType(
            stop
          )} - ${customerName} from ${startTime} to ${endTime}</div>`,
          className: stopColorFunc(stop?.activities ?? [], stopStatus),
        });

        const lowerStartTimeCandidate = moment(this.convertToVisDateTime(arrivalDateTime));
        const upperEndTimeCandidate = moment(this.convertToVisDateTime(departureDateTime));

        if (!lowerStartTime || (!!lowerStartTime && lowerStartTimeCandidate.isBefore(lowerStartTime))) {
          lowerStartTime = lowerStartTimeCandidate;
        }

        if (!upperEndTime || (!!upperEndTime && upperEndTimeCandidate.isAfter(upperEndTime))) {
          upperEndTime = upperEndTimeCandidate;
        }
      }
    });

    if (stops.length > 1 && lowerStartTime && upperEndTime) {
      dataSet.add({
        id: `timeline-range`,
        start: lowerStartTime.format('YYYY-MM-DD HH:mm:ss'),
        end: upperEndTime.format('YYYY-MM-DD HH:mm:ss'),
        className: 'time-between',
      });
    }

    if (!this.params.fromDispatcherTrips) {
      const itemData = this.params.data as TripPlanningGridItem;

      const routeStartEnd = {
        id: `trip-${tripInstId}-route-${itemData.route.routeInstId}`,
        start: `${moment().format('YYYY-MM-DD')} ${itemData.route.deliveryRouteDepartTime}`,
        end: `${moment(itemData.route.estimatedClearTime).format('YYYY-MM-DD HH:mm')}`,
        type: 'background',
        className: 'route',
      };
      dataSet.add(routeStartEnd);
    }

    return dataSet;
  }

  private convertToVisDateTime(dateTime: Date | string): string {
    return `${moment(this.planDate).format('YYYY-MM-DD')} ${this.timeService.formatDate(dateTime, 'HH:mm:00')}`;
  }

  private buildTimelineOptions(startDate: moment.Moment, endDate: moment.Moment): TimelineOptions {
    const timeAxis: TimelineTimeAxisOption = {
      scale: 'hour',
      step: 1,
    };

    const tooltip: TimelineTooltipOption = {
      followMouse: true,
      overflowMethod: 'cap',
    };

    const options: TimelineOptions = {
      autoResize: true,
      end: endDate.format('YYYY-MM-DD HH:mm:ss'),
      format: {
        minorLabels: {
          hour: '',
        },
      },
      margin: {
        item: 20,
      },
      maxHeight: '39px',
      moveable: false,
      selectable: false,
      showMajorLabels: false,
      showMinorLabels: false,
      stack: false,
      start: startDate.format('YYYY-MM-DD HH:mm:ss'),
      timeAxis: timeAxis,
      tooltip: tooltip,
      zoomable: false,
    };

    return options;
  }
}
