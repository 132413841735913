import { Observable } from 'rxjs';
import { LayoutConfig } from '../layout-config.interface';

/**
 * Layout Preference storage data defining various user defined Layouts
 */
export class XpoLtlLayoutPreferencesStorageData {
  activeLayout: string = undefined;
  userLayouts: LayoutConfig[] = [];
}

/**
 * Define required methods for reading/writing layout preferences
 */
export interface XpoLtlLayoutPreferencesStorage {
  getData$(): Observable<XpoLtlLayoutPreferencesStorageData>;
  setData$(data: XpoLtlLayoutPreferencesStorageData): Observable<void>;
}
