import { ViewEncapsulation, ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp, IHeaderAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-driver-collect-icon',
  template: '<xpo-icon *ngIf="isDriverCollect" class="driver-collect-svg" iconName="SS_DriverCollect"></xpo-icon>',
  styles: ['.xpo-Icon.driver-collect-svg > .mat-icon { padding: 7px 0 0 0; margin: 2px 0; height: 24px; }'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverCollectIconComponent implements ICellRendererAngularComp, IHeaderAngularComp {
  isDriverCollect: boolean;

  constructor() {}

  agInit(params: any): void {
    this.isDriverCollect = params.header ? params.header : params.value;
  }

  refresh(): boolean {
    return false;
  }
}
