import { Injectable } from '@angular/core';
import { DispatchDriver, DispatchRoute, DispatchTrip, Route } from '@xpo-ltl/sdk-cityoperations';
import { LastStopStatusCd } from 'app/inbound-planning/components/dispatcher-trips/enums/last-stop-status-cd.enum';
import { EquipmentPipe } from 'app/inbound-planning/shared';
import { forEach as _forEach, isEmpty as _isEmpty, size as _size, uniqBy as _uniqBy } from 'lodash';
import {
  DispatcherTripsDetailGridItem,
  DispatcherTripsGridItem,
} from '../../../components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { ModifyTripDetailsUtils } from '../../../components/trips/modify-trip-details/classes/modify-trip-details-utils.class';
import { DOCK_DROP, DOCK_PICKUP } from './../../../../../shared/app.constants';

@Injectable({
  providedIn: 'root',
})
export class DispatchTripsGridItemConverterService {
  private readonly invalidTimeString = '00:00:00';
  constructor() {}

  getDispatchTripsGridItems(
    sicCd: string,
    tripsAndDockRoutes: (DispatchTrip | Route)[],
    equipmentPipe?: EquipmentPipe
  ): DispatcherTripsGridItem[] {
    const dispatcherTripsGridItems: DispatcherTripsGridItem[] = [];
    _forEach(tripsAndDockRoutes, (tripAndDockRoute: DispatchTrip | Route) => {
      if ('routeInstId' in tripAndDockRoute) {
        dispatcherTripsGridItems.push(this.mapRouteToGridItem(sicCd, tripAndDockRoute, equipmentPipe));
      } else {
        if (_isEmpty(tripAndDockRoute.dispatchRoutes)) {
          dispatcherTripsGridItems.push(this.mapTripRouteToGridItem(sicCd, tripAndDockRoute));
        } else {
          const uniqueRoutes = _uniqBy(
            tripAndDockRoute.dispatchRoutes,
            (dispatchRoute: DispatchRoute) => dispatchRoute.routeInstId
          );
          uniqueRoutes.forEach((route: DispatchRoute, routeIndex: number) => {
            dispatcherTripsGridItems.push(this.mapTripRouteToGridItem(sicCd, tripAndDockRoute, route, routeIndex));
          });
        }
      }
    });

    return _uniqBy(dispatcherTripsGridItems, (gridItem: DispatcherTripsGridItem) => gridItem.uniqTripId);
  }

  private mapRouteToGridItem(sicCd: string, route: Route, equipmentPipe?: EquipmentPipe): DispatcherTripsGridItem {
    const routeInstId = route ? route.routeInstId : 0; // Trip with no route will be unique by tripInstId alone.  Default routeInstId to 0.
    const gridItem = <DispatcherTripsGridItem>{
      uniqTripId: `${route?.tripInstId}-${routeInstId}`,
      dispatchTrip: {
        ...new DispatchTrip(),
        dispatchRoutes: [
          {
            ...new DispatchRoute(),
            routeInstId: route.routeInstId,
            routePrefix: route.routePrefix,
            routeSuffix: route.routeSuffix,
          },
        ],
        tripInstId: -route.routeInstId, // Need to provide unique count to customers at dock "group" level
      },
      tripInstId: route.routeInstId, // Need to provide unique count to customers at dock "group" level
      tripStatusCd: undefined,
      sicCd,
      driver: undefined,
      displayNoAutoLabel: true,
      driverStart: '',
      driverLunch: '',
      driverEnd: '',
      equipmentTractor: '',
      tripStart: undefined,
      tripClear: undefined,
      tripComplete: undefined,

      remainingDeliveries: route?.deliveryRemainingCount ?? 0,
      completedDeliveries: route?.deliveryCompletedCount ?? 0,
      remainingPickups: route?.pickupRemainingCount ?? 0,
      completedPickups: route?.pickupCompletedCount ?? 0,
      returnCount: 0,
      totalStopCount: 0,

      lastStopTypeCd: undefined,
      lastStopStatus: undefined,
      lastStopDateTime: undefined,
      lastStopArrivalDateTime: undefined,
      lastStopDwellTime: undefined,

      weightRemainingDeliveries: route.totalWeightCount,
      weightRemainingPickups: route.totalWeightCount,
      weightCompletedDeliveries: 0,
      weightCompletedPickups: 0,
      specialServices: undefined,
      routeIndex: 0,
      totalRecordCount: '',
      routes: [
        {
          ...new DispatcherTripsDetailGridItem(),
          routePrefix: route.routePrefix,
          routeSuffix: route.routeSuffix,
          trailerNbr: equipmentPipe.transform(route?.equipmentIdPrefix, route?.equipmentIdSuffixNbr),
        },
      ],

      cmsCarrierId: 0,
      cmsTripTypeCd: undefined,
      carrierTenderGroupStatusCd: undefined,

      appointmentStatusCd: undefined, // trip?.appointmentStatusCd,

      isDockDrop: route.routeSuffix.toUpperCase() === DOCK_DROP,
      isDockPickup: route.routeSuffix.toUpperCase() === DOCK_PICKUP,
    };

    // clear out driver start/end if such times are not defined
    if (gridItem.driverStart === this.invalidTimeString && gridItem.driverEnd === this.invalidTimeString) {
      gridItem.driverStart = undefined;
      gridItem.driverEnd = undefined;
    }

    return gridItem;
  }

  private mapTripRouteToGridItem(
    sicCd: string,
    trip: DispatchTrip,
    route?: DispatchRoute,
    routeIndex?: number
  ): DispatcherTripsGridItem {
    const uniqueRoutes = _uniqBy(trip.dispatchRoutes, (dispatchRoute: DispatchRoute) => dispatchRoute.routeInstId);
    const dispatchDriver: DispatchDriver = trip?.dispatchDriver;

    const routeInstId = route ? route.routeInstId : 0; // Trip with no route will be unique by tripInstId alone.  Default routeInstId to 0.
    const gridItem = <DispatcherTripsGridItem>{
      uniqTripId: `${trip?.tripInstId}-${routeInstId}`,
      dispatchTrip: trip,
      handheldPhoneNbr: trip?.dispatchDriver?.handheldPhoneNumber,
      dsrCellPhoneNbr: trip?.dispatchDriver?.dsrCellPhoneNbr,
      tripInstId: trip.tripInstId,
      tripStatusCd: trip.tripStatusCd,
      sicCd,
      driver: {
        name: dispatchDriver?.dsrName ?? '',
        personalNbr: dispatchDriver?.handheldPhoneNumber ?? '', // TODO: handheld number and phone number should be two separate things
        mobileNbr: dispatchDriver?.handheldPhoneNumber ?? '',

        // required for messaging
        dsrEmployeeId: dispatchDriver?.dsrEmployeeId,
        deviceId: dispatchDriver?.deviceId,
        conersationId: dispatchDriver?.beaconConversation?.conversationId,
      },
      displayNoAutoLabel: trip.doNotAutoDisplayInd,
      driverStart: dispatchDriver?.startTime ?? '',
      driverLunch: dispatchDriver?.lunchTime ?? '',
      driverEnd: dispatchDriver?.endTime ?? '',
      equipmentTractor: _size(trip?.dispatchEquipment) >= 1 ? trip?.dispatchEquipment[0].tractorNbr : '',
      tripStart: trip.startDateTime,
      tripClear: trip.estimatedEmptyDateTime,
      tripComplete: trip.completeDateTime,

      remainingDeliveries: trip.deliveryRemainingCount,
      completedDeliveries: trip.deliveryCompletedCount,
      remainingPickups: trip.pickupRemainingCount,
      completedPickups: trip.pickupCompletedCount,
      returnCount: trip.shipmentReturnCount,
      totalStopCount: trip.stopCompletedCount + trip.stopRemainingCount,

      lastStopTypeCd: trip.lastStopTypeCd,
      lastStopStatus: trip.lastStopStatus as LastStopStatusCd,
      lastStopDateTime: trip.lastStopDateTime,
      lastStopArrivalDateTime: trip.lastStopArrivalDateTime,
      lastStopDwellTime: trip.lastStopDwellTime,

      weightRemainingDeliveries: trip.totalRemainingDeliveryWeightCount,
      weightRemainingPickups: trip.totalRemainingPickupWeightCount,
      weightCompletedDeliveries: 0, // N/A yet
      weightCompletedPickups: 0, // N/A yet
      specialServices: ModifyTripDetailsUtils.aggregateSpecialServiceCds(trip.specialServicesSummaries),
      routeIndex,
      routes: this.mapDispatchRouteToGridItem(uniqueRoutes),

      cmsCarrierId: trip?.cmsCarrierId,
      cmsTripTypeCd: trip?.cmsTripTypeCd,
      carrierTenderGroupStatusCd: trip?.carrierTenderGroupStatusCd,

      appointmentStatusCd: trip?.appointmentStatusCd,

      fbdsPrintCountCd: trip?.fbdsPrintCountCd,
      stopExceptionsInd: trip?.stopExceptionsInd,
    };

    // clear out driver start/end if such times are not defined
    if (gridItem.driverStart === this.invalidTimeString && gridItem.driverEnd === this.invalidTimeString) {
      gridItem.driverStart = undefined;
      gridItem.driverEnd = undefined;
    }

    return gridItem;
  }

  private mapDispatchRouteToGridItem(routes: DispatchRoute[]): DispatcherTripsDetailGridItem[] {
    return (routes || []).map((route: DispatchRoute) => {
      return <DispatcherTripsDetailGridItem>{
        uniqRouteId: `${route.routeInstId}`,
        routePrefix: route.routePrefix,
        routeSuffix: route.routeSuffix,
        routeSIC: route.terminalSicCd,
        trailerNbr: route?.trailerNbr ?? '',
        trailerStatusCd: route.trailerStatusCd,
        trailerFeetAvailable: route.feetAvailable,
        trailerYear: route.trailerManufactureYear,
        plannedDoor: route.plannedDoor,
        eventDoor: route.eventDoor,
        sequenced: route.sequenceValidInd,
      };
    });
  }
}
