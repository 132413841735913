import { Pipe, PipeTransform } from '@angular/core';
import { NoteTypes } from './../enums/note-types.enum';

@Pipe({ name: 'noteTypeCd' })
export class NoteTypeCdPipe implements PipeTransform {
  transform(value: NoteTypes): string {
    switch (value || '') {
      case NoteTypes.PICKUP_INSTRUCTIONS:
        return 'Pickup Instructions';
      case NoteTypes.PICKUP_OPERATIONAL_NOTES:
        return 'Operational Notes';
      case NoteTypes.PICKUP_REMARKS:
        return 'Pickup Remarks';
    }
    return value;
  }
}
