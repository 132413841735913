<div
    #container
    [ngClass]="{ focused: focused$ | async }"
    class="pnd-DispatchArea"
    data-test="pnd-DispatchArea"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (dblclick)="setEditModeEnable()">
  <form [formGroup]="dispatchAreaForm">
    <div *ngIf="!pndDispatchGroupRegion.dispatchGroupRegion && !editMode" class="attention-chip">
      NO DRAWN AREA DEFINED
    </div>
    <p>
      <mat-form-field floatLabel="always" class="pnd-DispatchArea-name">
        <mat-label>Dispatch Area Name</mat-label>
        <input
            (focus)="setEditModeEnable()"
            data-test="map-dispatch-area-card-name"
            matInput
            #groupName
            [formControlName]="DispatchAreaFormFields.Name"
            maxlength="4"
            autocomplete="off" />
        <mat-hint align="end">{{ groupName?.value?.length }} / 4</mat-hint>
        <mat-error *ngIf="hasErrors(DispatchAreaFormFields.Name)">
          <div *ngIf="hasError(DispatchAreaFormFields.Name, 'required')">Required</div>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field floatLabel="always" class="w-100">
        <mat-label>Description</mat-label>
        <textarea
            (focus)="setEditModeEnable()"
            data-test="map-dispatch-area-card-description"
            matInput
            #groupDescription
            [formControlName]="DispatchAreaFormFields.Description"
            maxlength="30"
            autocomplete="off"></textarea>
        <mat-hint align="end">{{ groupDescription?.value?.length }} / 30</mat-hint>
      </mat-form-field>
    </p>
    <ng-container *ngIf="editMode">
      <div class="pnd-DispatchArea-saveCancelAndDelete">
        <div>
          <button
              (click)="onSubmit()"
              [disabled]="!dispatchAreaForm.valid || (isProcessing$ | async)"
              mat-flat-button
              class="xpo-SmallButton">
            SAVE
          </button>
          <button (click)="cancel()" mat-stroked-button class="xpo-SmallButton" [disabled]="isProcessing$ | async">
            CANCEL
          </button>
        </div>
        <button
            *ngIf="!isNew()"
            (click)="deleteArea()"
            matTooltip="Delete Area"
            mat-icon-button
            aria-label="Delete Area"
            data-test="pnd-DispatchArea-delete">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!pndDispatchGroupRegion.dispatchGroupRegion && !editMode">
      <div class="pnd-DispatchArea-defineAreaAndDelete">
        <button
            [disabled]="disabled$ | async"
            (click)="defineArea()"
            mat-flat-button
            class="xpo-SmallButton"
            data-test="pnd-DispatchArea-defineArea">
          DEFINE AREA
        </button>
        <button
            *ngIf="!isNew()"
            (click)="deleteArea()"
            matTooltip="Delete Area"
            mat-icon-button
            aria-label="Delete Area"
            data-test="pnd-DispatchArea-delete">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </ng-container>
  </form>
</div>
