import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PlanningProfilesStoreEffects } from './planning-profiles-store.effects';
import { planningProfilesReducer } from './planning-profiles-store.reducers';
import { StateKey } from './planning-profiles-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, planningProfilesReducer),
    EffectsModule.forFeature([PlanningProfilesStoreEffects]),
  ],
})
export class PlanningProfilesStoreModule {}
