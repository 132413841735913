import { tassign } from 'tassign';
import { Actions, ActionTypes } from './planning-profiles-store.actions';
import { initialState, State } from './planning-profiles-store.state';

export function planningProfilesReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setPlanningProfiles:
      return tassign(state, {
        planningProfiles: action.payload.planningProfiles,
      });
    case ActionTypes.setPlanningProfile:
      return tassign(state, {
        planningProfile: action.payload.planningProfile,
      });
    default: {
      return state;
    }
  }
}
