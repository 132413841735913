import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pdoEquipmentType',
})
export class PdoEquipmentTypePipe implements PipeTransform {
  transform(value: string, ...args: []): string {
    switch (value) {
      case 'T':
        return 'Trailer';
      case 'D':
        return 'Dolly';
      case 'Z':
        return 'Straight Truck';
      default:
        return '';
    }
  }
}
