import { Injectable, OnDestroy } from '@angular/core';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '@pnd-store/dispatcher-trips-store';
import { WatchedDriver } from '@pnd-store/dispatcher-trips-store/dispatcher-trips-store.state';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber, XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  GetPnDTripDsrLocationsResp,
  StartDriverLocationEnsembleRqst,
  StartDriverLocationPayload,
  RegisterFilterCurrentDsrLocationRqst,
  DsrCurrentLocationFilter,
  UnregisterFilterCurrentDsrLocationRqst,
  StartDriverLocationEnsembleResp,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtAttributeFilter, TripStatusCd } from '@xpo-ltl/sdk-common';
import { XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import { DispatcherTripsGridItem } from 'app/inbound-planning/components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { RouteColorChangeEvent, RouteColorService } from 'app/inbound-planning/shared/services/route-color.service';
import { FeatureTypes } from 'core/services/features/feature-types.enum';
import {
  filter as _filter,
  forEach as _forEach,
  find as _find,
  first as _first,
  last as _last,
  remove as _remove,
  some as _some,
} from 'lodash';
import moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';
import { filter, take, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../../../store';
import { RegisterFilterRqst, RegisterFilterResp } from '../../../../../shared/services/auto-refresh-base.service';

export interface WatchedFilterHash {
  driverId: string;
  filterHash: string;
}

export const PULSE_TIME: number = 3000;

@Injectable({
  providedIn: 'root',
})
export class TripDriverLocationsService implements OnDestroy {
  private watchedDriversTimer: Subscription;
  protected unsubscriber = new Unsubscriber();
  private watchedFilterHashes: WatchedFilterHash[] = [];
  private messageToken: string;

  get onDemandMaxDrivers(): number {
    const sic = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    return +this.featuresService.getFeatureValue(sic, FeatureTypes.OnDemandMaxDrivers, '0');
  }

  get onDemandDuration(): number {
    const sic = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    return +this.featuresService.getFeatureValue(sic, FeatureTypes.OnDemandDuration, '0');
  }

  get onDemandPingInterval(): number {
    const sic = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    return +this.featuresService.getFeatureValue(sic, FeatureTypes.OnDemandPingInterval, '0');
  }

  get isOnDemandEnabled(): boolean {
    return this.onDemandMaxDrivers > 0 && this.onDemandDuration > 0 && this.onDemandPingInterval > 0;
  }

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private cityOperationsApiService: CityOperationsApiService,
    private routeColorService: RouteColorService,
    private messagingTokenService: XrtFireMessagingTokenService,
    private featuresService: XpoLtlFeaturesService
  ) {
    this.messagingTokenService
      .getToken$()
      .pipe(take(1))
      .subscribe((token) => {
        this.messageToken = token;
      });
    this.subscribeToColorChange();

    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(
        distinctUntilChanged(),
        filter((sicCd: string) => !!sicCd),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(() => {
        this.unregisterAll();
      });
  }

  ngOnDestroy(): void {
    this.unregisterAll();
    this.unsubscriber.complete();
  }

  unregisterAll(): void {
    _forEach(this.watchedFilterHashes, (watchedFilterHash) => {
      if (watchedFilterHash) {
        this.unregister(watchedFilterHash.filterHash);
        this.stopWatching(watchedFilterHash.driverId);
      }
    });
    this.watchedFilterHashes = [];
    this.pndStore$.dispatch(
      new DispatcherTripsStoreActions.SetWatched({
        watchedDrivers: [],
      })
    );
  }

  unregister(filterHash: string): void {
    const request = new UnregisterFilterCurrentDsrLocationRqst();
    request.connectionToken = this.messageToken;
    request.filterHash = filterHash;
    this.cityOperationsApiService
      .unregisterFilterCurrentDsrLocation(request)
      .pipe(take(1))
      .subscribe();
  }

  /**
   * Returns all the locations for a driver given a trip instance id
   * @param tripInstId
   */
  getDriverBreadcrumbs$(tripInstId: number): Observable<GetPnDTripDsrLocationsResp> {
    return this.cityOperationsApiService.getPnDTripDsrLocations({
      tripInstId,
    });
  }

  isWatchingDrivers(): boolean {
    return this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers)?.length > 0 ?? false;
  }

  isWatchedDriver(data: DispatcherTripsGridItem): boolean {
    const watchedDrivers = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers);
    if (_some(watchedDrivers, (driver) => driver.driverId === data?.dispatchTrip?.dispatchDriver?.dsrEmployeeId)) {
      return true;
    }
    return false;
  }

  canWatchDrivers(): boolean {
    return (
      this.isOnDemandEnabled &&
      this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers)?.length < this.onDemandMaxDrivers
    );
  }

  isWatchableStatus(data: DispatcherTripsGridItem): boolean {
    return data?.tripStatusCd === TripStatusCd.DISPATCHED || data?.tripStatusCd === TripStatusCd.RETURNING;
  }

  watchDriver(driverId: string, dsrName: string, tripInstId: number, isPulse: boolean): void {
    if (!this.canWatchDrivers) {
      return;
    }

    let initials = '';

    if (dsrName) {
      const dsrNameSplit: string[] = dsrName.split(' ');
      const firstInitial: string = _first(dsrNameSplit)?.[0] ?? '';
      const lastInitial: string = _last(dsrNameSplit)?.[0] ?? '';
      initials = firstInitial + lastInitial;
    }

    const watchedDriver: WatchedDriver = {
      driverId: driverId,
      tripInstId: tripInstId,
      initials: initials,
      color: this.routeColorService.getColorForRoute(tripInstId),
      lastUpdate: new Date(),
      timerCounter: isPulse ? PULSE_TIME : this.onDemandDuration,
      numberOfAdditions: 0,
      isPulse: isPulse,
    };

    this.sendOnDemandDriverRequest(watchedDriver.driverId)
      .pipe(take(1))
      .subscribe();

    const newWatchedDrivers: WatchedDriver[] = _filter(
      this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers),
      (watchedDrivers) => {
        return watchedDrivers.driverId !== driverId;
      }
    ).concat(watchedDriver);

    this.pndStore$.dispatch(
      new DispatcherTripsStoreActions.SetWatched({
        watchedDrivers: newWatchedDrivers,
      })
    );

    if (newWatchedDrivers.length === 1) {
      this.startWatchedDriversTimer();
    }
    this.registerFilter(driverId);
  }

  registerFilter(driverId: string) {
    this.watchedFilterHashes.push({ driverId: driverId, filterHash: undefined });
    const request = new RegisterFilterCurrentDsrLocationRqst();
    const empId = {
      ...new XrtAttributeFilter(),
      values: [driverId],
    };
    const baseFilter = {
      ...new DsrCurrentLocationFilter(),
      q: undefined,
      employeeId: empId,
    };
    request.filter = baseFilter;
    request.connectionToken = this.messageToken;
    this.executeRegisterFilter(request)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((result) => {
        const driver = _find(this.watchedFilterHashes, ['driverId', driverId]);
        driver.filterHash = result.filterHash;
        this.updateStoreFilterHash(driverId, result.filterHash);
      });
  }

  private updateStoreFilterHash(driverId: string, filterHash: string) {
    const drivers: WatchedDriver[] = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers);
    const thisDriver = _find(drivers, { driverId: driverId });
    if (thisDriver) {
      thisDriver.filterHash = filterHash;
      this.pndStore$.dispatch(
        new DispatcherTripsStoreActions.SetWatched({
          watchedDrivers: drivers,
        })
      );
    }
  }

  private executeRegisterFilter(request: RegisterFilterRqst): Observable<RegisterFilterResp> {
    return this.cityOperationsApiService.registerFilterCurrentDsrLocation(
      request as RegisterFilterCurrentDsrLocationRqst
    );
  }

  private startWatchedDriversTimer() {
    this.stopWatchedDriversTimer(); // just in case

    this.watchedDriversTimer = timer(1000, 1000)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(() => {
        const watchedDrivers = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers);
        const newWatchedDrivers: WatchedDriver[] = [];

        watchedDrivers.forEach((watchedDriver: WatchedDriver) => {
          watchedDriver.timerCounter = watchedDriver.timerCounter - 1000;

          const now = moment();
          if (now > moment(watchedDriver.lastUpdate).add(this.onDemandPingInterval, 'millisecond')) {
            watchedDriver.lastUpdate = moment().toDate();
            this.sendOnDemandDriverRequest(watchedDriver.driverId)
              .pipe(take(1))
              .subscribe();
          }

          if (watchedDriver.timerCounter > 0) {
            newWatchedDrivers.push(watchedDriver);
          }
        });

        this.pndStore$.dispatch(
          new DispatcherTripsStoreActions.SetWatched({
            watchedDrivers: newWatchedDrivers,
          })
        );

        if (newWatchedDrivers.length === 0) {
          this.stopWatchedDriversTimer();
        }
      });
  }

  private stopWatchedDriversTimer() {
    if (this.watchedDriversTimer) {
      this.watchedDriversTimer.unsubscribe();
      this.watchedDriversTimer = undefined;
    }
  }

  sendOnDemandDriverRequest(driverId: string): Observable<StartDriverLocationEnsembleResp> {
    if (driverId) {
      const payload = {
        ...new StartDriverLocationPayload(),
        employeeId: driverId,
      };

      const request = {
        ...new StartDriverLocationEnsembleRqst(),
        businessKey1: driverId,
        payload: payload,
        ensembleName: 'start-onDemandDriverLocation',
      };
      return this.cityOperationsApiService.startDriverLocationEnsemble(request);
    }
  }

  stopWatching(driverId: string): void {
    const driver: WatchedFilterHash = _find(this.watchedFilterHashes, ['driverId', driverId]);
    if (driver) {
      this.unregister(driver.filterHash);
      _remove(this.watchedFilterHashes, { driverId: driverId });
      const newWatchedDrivers = _filter(
        this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers),
        (watchedDrivers) => watchedDrivers.driverId !== driverId
      );

      this.pndStore$.dispatch(
        new DispatcherTripsStoreActions.SetWatched({
          watchedDrivers: newWatchedDrivers,
        })
      );

      if (!newWatchedDrivers.length) {
        this.stopWatchedDriversTimer();
      }
    }
  }

  private subscribeToColorChange(): void {
    this.routeColorService.colorChanged$
      .pipe(
        filter((change) => !!change),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((routeColorChangeEvent: RouteColorChangeEvent) => {
        const drivers: WatchedDriver[] = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.watchedDrivers);
        const thisDriver: WatchedDriver = _find(drivers, { tripInstId: routeColorChangeEvent.id });
        if (thisDriver) {
          thisDriver.color = routeColorChangeEvent.color;
          this.pndStore$.dispatch(
            new DispatcherTripsStoreActions.SetWatched({
              watchedDrivers: drivers,
            })
          );
        }
      });
  }
}
