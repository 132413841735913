import { InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';
import { AccountDetail, GeoCoordinates } from '@xpo-ltl/sdk-common';
import { Feature, FeatureCollection, GeoJSON, Polygon } from 'geojson';
import * as geom from 'jsts/org/locationtech/jts/geom';
import { isEqual as _isEqual, reduce as _reduce, uniqWith as _uniqWith } from 'lodash';
import { GeoJsonGeometryType } from '../enums/geo-json-geometry-type.enum';
import { GeoJsonType } from '../enums/geo-json-type.enum';

interface MapMarkerIconSize {
  width: number;
  height: number;
}

export class MapUtils {
  static readonly EAGLE_VIEW_LEVEL: number = 7; // levels lower than this are rendered diffrently
  static readonly MARKER_SIZE: MapMarkerIconSize = {
    width: 38,
    height: 38,
  };
  static readonly MARKER_SIZE_EAGLE_VIEW: MapMarkerIconSize = {
    width: 18,
    height: 18,
  };

  static getMapHeight(googleMap: google.maps.Map): number {
    if (googleMap) {
      return googleMap.getDiv().getBoundingClientRect().height;
    }

    return 0;
  }

  /**
   * Returns the Lat/Lng of the Stop as a LatLngLiteral.
   * @param customer
   */
  static getGoogleCoordinates(customer: InterfaceAcct | AccountDetail): google.maps.LatLng {
    const latLon = MapUtils.getCoordinates(customer);
    return new google.maps.LatLng(latLon.lat, latLon.lon);
  }

  /**
   * Returns the lat/lon coordinates from the passed InterfaceAcct/AccountDetail
   */
  static getCoordinates(customer: InterfaceAcct | AccountDetail): GeoCoordinates {
    if (!customer) {
      return { lat: 0, lon: 0 };
    } else if (customer.geoCoordinatesGeo) {
      return {
        lat: customer.geoCoordinatesGeo.lat,
        lon: customer.geoCoordinatesGeo.lon,
      };
    } else if (customer?.geoCoordinates) {
      return {
        lat: customer.geoCoordinates.latitude,
        lon: customer.geoCoordinates.longitude,
      };
    } else {
      return {
        lat: customer?.latitudeNbr || 0,
        lon: customer?.longitudeNbr || 0,
      };
    }
  }

  static convertToGeoPoint(json: GeoJSON): GeoCoordinates {
    if (json && json.type === GeoJsonType.FeatureCollection) {
      const fc = json as FeatureCollection;

      if (fc.features && fc.features[0] && fc.features[0].geometry.type === GeoJsonGeometryType.Point) {
        return { lat: fc.features[0].geometry.coordinates[1], lon: fc.features[0].geometry.coordinates[0] };
      }
    }

    return undefined;
  }

  static isValidGeoCoordinates(geoCoordinates: GeoCoordinates): boolean {
    return geoCoordinates && geoCoordinates.lat !== 0 && geoCoordinates.lon !== 0;
  }

  static polygonToGeoJson(polygon: google.maps.Polygon): GeoJSON {
    const geoJson = {
      type: GeoJsonType.Feature,
      geometry: {
        type: GeoJsonGeometryType.Polygon,
        coordinates: [[]],
      } as Polygon,
    } as Feature;

    if (polygon) {
      const coordinates: number[][] = (geoJson.geometry as Polygon).coordinates[0];
      const vertices = polygon.getPaths().getArray()[0];

      vertices.getArray().forEach((xy: google.maps.LatLng, i: number) => {
        coordinates.push([xy.lng(), xy.lat()]);
      });

      // We need to add the last one if it is different to the first one
      if (
        coordinates.length > 1 &&
        (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
          coordinates[0][1] !== coordinates[coordinates.length - 1][1])
      ) {
        coordinates.push([coordinates[0][0], coordinates[0][1]]);
      }
    }

    return geoJson;
  }

  static polygonToString(polygon: google.maps.Polygon): string {
    const geoJson = MapUtils.polygonToGeoJson(polygon) as Feature;
    const coordinates = (geoJson.geometry as Polygon).coordinates[0];

    if (coordinates.length === 0) {
      return '';
    } else {
      return JSON.stringify(geoJson);
    }
  }

  static isValidPolygon(polygon: google.maps.Polygon): boolean {
    const geoJson = MapUtils.polygonToGeoJson(polygon) as Feature;
    const coordinates = (geoJson.geometry as Polygon).coordinates[0];
    const uniqueCoordinates = _uniqWith(coordinates, _isEqual);

    return uniqueCoordinates.length > 2;
  }

  static geoJsonToPolygon(jsonString: string): Polygon {
    const geoJson = JSON.parse(jsonString);
    const type = geoJson?.type;
    let polygon: Polygon;

    switch (type) {
      case GeoJsonType.Feature:
        polygon = geoJson?.geometry as Polygon;
        break;
      case GeoJsonGeometryType.Polygon:
        polygon = geoJson as Polygon;
        break;
    }

    const coordinates = polygon.coordinates[0];

    // We need to remove the last one if it is equal to the first one
    if (
      polygon &&
      coordinates.length > 1 &&
      coordinates[0][0] === coordinates[coordinates.length - 1][0] &&
      coordinates[0][1] === coordinates[coordinates.length - 1][1]
    ) {
      coordinates.splice(polygon.coordinates.length - 1, 1);
    }

    return polygon;
  }

  static createJstsPolygon(polygon: google.maps.Polygon): geom.Polygon {
    const geometryFactory = new geom.GeometryFactory();
    const path = polygon.getPath();
    const coordinates = path.getArray().map((coord: google.maps.LatLng) => {
      return new geom.Coordinate(coord.lat(), coord.lng());
    });
    coordinates.push(coordinates[0]);

    const shell = geometryFactory.createLinearRing(coordinates);
    return geometryFactory.createPolygon(shell, []);
  }

  static difference(polygon1: google.maps.Polygon, polygon2: google.maps.Polygon): google.maps.LatLng[] {
    let difference: geom.Geometry;
    try {
      const polygon1jsts = MapUtils.createJstsPolygon(polygon1);
      const polygon2jsts = MapUtils.createJstsPolygon(polygon2);

      difference = polygon1jsts.difference(polygon2jsts);
    } catch {}

    if (difference && !difference.isEmpty()) {
      const paths = difference.getCoordinates().map((coord: geom.Coordinate) => {
        return new google.maps.LatLng(coord.x, coord.y);
      });
      return paths;
    } else {
      return polygon1.getPath().getArray();
    }
  }

  static reducePrecision(polygon: google.maps.Polygon, precision: number): google.maps.LatLng[] {
    const vertices: google.maps.LatLng[] = polygon
      .getPaths()
      .getArray()[0]
      .getArray();

    return _reduce(
      vertices,
      (newVertices: google.maps.LatLng[], currentVertices: google.maps.LatLng) => {
        newVertices.push(
          new google.maps.LatLng(+currentVertices.lat().toFixed(precision), +currentVertices.lng().toFixed(precision))
        );
        return newVertices;
      },
      []
    );
  }
}
