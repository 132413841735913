import { Component } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { AutoCompleteItem } from '@xpo-ltl/ngx-ltl';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { of } from 'rxjs';

@Component({
  selector: 'pnd-select-option-editor',
  templateUrl: './select-option-editor.component.html',
  styleUrls: ['./select-option-editor.component.scss'],
})
export class SelectOptionEditorComponent implements ICellEditorAngularComp {
  private params;
  selectedValue: string;
  options: string[];
  values: AutoCompleteItem[] = [];
  selectOptionFormControl = 'selectOption';
  focus$ = of(true);

  formGroup: FormGroup = new FormGroup({
    selectOption: new UntypedFormControl(''),
  });

  filteredOptions: string[];
  agInit(params): void {
    this.params = params;
    this.selectedValue = params.value;
    this.options = params?.options;
    this.filteredOptions = this.options.slice();

    this.filteredOptions.forEach((option) => {
      this.values.push({
        id: option,
        value: option,
      });
    });
    this.formGroup.get(this.selectOptionFormControl).setValue({
      id: params?.value,
      value: params?.value,
    });
  }

  onChange(event): void {
    this.formGroup.get(this.selectOptionFormControl).setValue({
      id: event?.value,
      value: event?.value,
    });
    this.params.api.stopEditing();
    const rowIndex = this.params?.node?.rowIndex;
    const colKey = this.params?.column?.colId;
    if (rowIndex + 1 < this.params.api.getDisplayedRowCount()) {
      this.params.api.setFocusedCell(rowIndex + 1, colKey);
    } else {
      const numericPart = colKey.match(/\d+$/)[0];
      const newNumericPart = (parseInt(numericPart, 10) + 1).toString();
      const newColkey = colKey.replace(/\d+$/, newNumericPart);
      this.params.api.setFocusedCell(0, newColkey);
    }
  }

  isPopup?(): boolean {
    return false;
  }

  isCancelAfterEnd?(): boolean {
    return false;
  }
  getGui() {
    return;
  }

  getValue(): string {
    const value = this.formGroup.get(this.selectOptionFormControl)?.value?.hasOwnProperty('id');
    if (value) {
      return this.formGroup.get(this.selectOptionFormControl)?.value?.value;
    }
    return this.formGroup.get(this.selectOptionFormControl)?.value?.value;
  }
}
