import { Injectable } from '@angular/core';
import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { NodeTypeCd, TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { MapUtils } from 'app/inbound-planning/shared/classes/map-utils';
import {
  Dictionary,
  first as _first,
  flatten as _flatten,
  forEach as _forEach,
  forOwn as _forOwn,
  groupBy as _groupBy,
  isEqual as _isEqual,
  last as _last,
  map as _map,
  size as _size,
} from 'lodash';
import { LayoutPreferenceService } from '../../../../../../../../shared/layout-manager';
import { RouteColorService } from '../../../../../../shared/services/route-color.service';
import { PickupClusterNodeTypeCd } from '../../../pickup-positioning-points-layer/pickup-positioning-points-layer.component';
import { BreadcrumbSegment } from '../../interfaces/breadcrumb-segment.interface';
import { GoogleLegInfo } from '../../interfaces/google-leg-info.interface';
import { Leg } from '../../interfaces/leg.interface';
import { TargetSegmentInterface } from '../../interfaces/target-segment.interface';
import { TripRenderInfo } from '../../interfaces/trip-render-info';
import { TripBreadcrumbsHelper } from './trip-breadcrumbs.helper';

export interface RenderStop {
  stop: Stop;
  route: Route;
}

@Injectable({
  providedIn: 'root',
})
export class TripRenderingHelper {
  constructor(
    private tripBreadcrumbsHelper: TripBreadcrumbsHelper,
    private routeColorService: RouteColorService,
    private layoutPreferenceService: LayoutPreferenceService
  ) {}

  /**
   * Determines if a stop is service center based on the node type code.
   * @param stop
   * @private
   */
  private isSic(stop: Stop): boolean {
    return stop.tripNode.nodeTypeCd === NodeTypeCd.SERVICE_CENTER;
  }

  private isSicConnector(start: RenderStop, end: RenderStop): boolean {
    return this.isSic(start.stop) || this.isSic(end.stop);
  }

  private isPickupClusterConnector(end: RenderStop): boolean {
    return (
      <NodeTypeCd | PickupClusterNodeTypeCd>end?.stop?.tripNode?.nodeTypeCd === PickupClusterNodeTypeCd.PICKUP_CLUSTER
    );
  }

  /**
   * Determines if a segment determined by 2 stops is a connector or route.
   * @param start
   * @param end
   */
  private isConnector(start: RenderStop, end: RenderStop): boolean {
    const isPickup = (): boolean => {
      return !start.route || !end.route;
    };

    const differentRoute = (): boolean => {
      return start.route.routeInstId !== end.route.routeInstId;
    };

    return this.isSicConnector(start, end) || isPickup() || differentRoute();
  }

  /**
   * Generates legs for a given trip (It modifies the tripRenderInfo reference).
   * @param tripInstId
   * @param tripRenderInfo
   */
  generateBreadcrumbsLegs(tripInstId: number, tripRenderInfo: TripRenderInfo): GoogleLegInfo {
    tripRenderInfo.breadcrumbsLegs = [];

    let nextIndexToCheck: number = 0;
    const generatedLegs: GoogleLegInfo = {
      waypointsCandidates: [],
      calculateLegsWithGoogle: false,
    };

    for (let i = 0; i < _size(tripRenderInfo.stops) - 1; i++) {
      const start: RenderStop = tripRenderInfo.stops[i];
      const end: RenderStop = tripRenderInfo.stops[i + 1];
      const hasCompletedStops: boolean = i === 0 ? this.isCompleted(end.stop) : this.isCompleted(start.stop);
      const isConnector: boolean = this.isConnector(start, end);
      const isSicConnector: boolean = this.isSicConnector(start, end);
      const isPickupClusterConnector: boolean = this.isPickupClusterConnector(end);

      let color: string = '';
      if (this.layoutPreferenceService.isDispatcherLayout()) {
        color = this.routeColorService.getColorForTrip(tripInstId);
      } else if (!isConnector) {
        color = this.routeColorService.getColorForRoute(end.route.routeInstId);
      }

      if (hasCompletedStops) {
        if (_size(tripRenderInfo.driverLocationBreadcrumbs) > 0) {
          const previousSegment: TargetSegmentInterface = this.tripBreadcrumbsHelper.breadcrumbsForTargetSegment(
            tripRenderInfo,
            MapUtils.getGoogleCoordinates(start.stop.customer),
            nextIndexToCheck
          );

          const first = _first(previousSegment.points);
          const last = _last(previousSegment.points);

          if (first && last) {
            const distanceBetweenPoints: number = google.maps.geometry.spherical.computeDistanceBetween(first, last);

            if (
              previousSegment.targetFoundInBreadcrumb &&
              distanceBetweenPoints > TripBreadcrumbsHelper.distanceThreshold
            ) {
              nextIndexToCheck = previousSegment.nextIndexToCheck;
            }
          }

          const targetSegment = this.tripBreadcrumbsHelper.breadcrumbsForTargetSegment(
            tripRenderInfo,
            MapUtils.getGoogleCoordinates(end.stop.customer),
            nextIndexToCheck
          );

          nextIndexToCheck = targetSegment.nextIndexToCheck;

          if (targetSegment.targetFoundInBreadcrumb) {
            // Full breadcrumbs
            _forEach(
              this.tripBreadcrumbsHelper.detectConnectionLossesInBreadcrumbs(targetSegment.points),
              (segment: BreadcrumbSegment) => {
                tripRenderInfo.breadcrumbsLegs.push({
                  segmentId: i,
                  hasCompletedStops: hasCompletedStops,
                  isConnector: isConnector,
                  isPickupClusterConnector: isPickupClusterConnector,
                  isBreadcrumb: true,
                  isSicConnector: isSicConnector,
                  connectionLost: segment.connectionLost,
                  color: color,
                  route: end.route,
                  points: segment.points,
                });

                if (segment.connectionLost) {
                  generatedLegs.calculateLegsWithGoogle = true;
                  generatedLegs.waypointsCandidates.push(_first(segment.points));
                  generatedLegs.waypointsCandidates.push(_last(segment.points));
                }
              }
            );
          } else if (_size(targetSegment.points) > 0) {
            // Mix case
            _forEach(
              this.tripBreadcrumbsHelper.detectConnectionLossesInBreadcrumbs(targetSegment.points),
              (segment: BreadcrumbSegment) => {
                tripRenderInfo.breadcrumbsLegs.push({
                  segmentId: i,
                  hasCompletedStops: hasCompletedStops,
                  isBreadcrumb: true,
                  isConnector: isConnector,
                  isSicConnector: isSicConnector,
                  connectionLost: segment.connectionLost,
                  color: color,
                  route: end.route,
                  points: segment.points,
                });

                if (segment.connectionLost) {
                  generatedLegs.calculateLegsWithGoogle = true;
                  generatedLegs.waypointsCandidates.push(_first(segment.points));
                  generatedLegs.waypointsCandidates.push(_last(segment.points));
                }
              }
            );

            // The Driver is in Route
            nextIndexToCheck = tripRenderInfo.driverLocationBreadcrumbs.length;

            // Fill the remaining segment with google
            generatedLegs.calculateLegsWithGoogle = true;
            generatedLegs.waypointsCandidates.push(_last(tripRenderInfo.driverLocationBreadcrumbs));

            tripRenderInfo.breadcrumbsLegs.push({
              segmentId: i,
              hasCompletedStops: hasCompletedStops,
              isBreadcrumb: false,
              isConnector: isConnector,
              isSicConnector: isSicConnector,
              color: color,
              route: end.route,
              points: [],
            });
          } else {
            generatedLegs.calculateLegsWithGoogle = true;
            generatedLegs.waypointsCandidates.push(MapUtils.getGoogleCoordinates(start.stop.customer));

            tripRenderInfo.breadcrumbsLegs.push({
              segmentId: i,
              hasCompletedStops: hasCompletedStops,
              isBreadcrumb: false,
              isConnector: isConnector,
              isSicConnector: isSicConnector,
              color: color,
              route: end.route,
              points: [],
            });
          }
        } else {
          generatedLegs.calculateLegsWithGoogle = true;
          generatedLegs.waypointsCandidates.push(MapUtils.getGoogleCoordinates(start.stop.customer));

          tripRenderInfo.breadcrumbsLegs.push({
            segmentId: i,
            hasCompletedStops: hasCompletedStops,
            isBreadcrumb: false,
            isConnector: isConnector,
            isSicConnector: isSicConnector,
            color: color,
            route: end.route,
            points: [],
          });
        }
      } else {
        generatedLegs.calculateLegsWithGoogle = true;
        generatedLegs.waypointsCandidates.push(MapUtils.getGoogleCoordinates(start.stop.customer));

        tripRenderInfo.breadcrumbsLegs.push({
          segmentId: i,
          hasCompletedStops: hasCompletedStops,
          isBreadcrumb: false,
          isConnector: isConnector,
          isSicConnector: isSicConnector,
          color: color,
          route: end.route,
          points: [],
        });
      }

      if (generatedLegs.calculateLegsWithGoogle && tripRenderInfo.stops.length - 2 === i) {
        generatedLegs.waypointsCandidates.push(MapUtils.getGoogleCoordinates(end.stop.customer));
      }
    }

    return generatedLegs;
  }

  /**
   * Generates legs for a given trip (It modifies the tripRenderInfo reference).
   * @param tripInstId
   * @param tripRenderInfo
   */
  generateProjectedLegs(tripInstId: number, tripRenderInfo: TripRenderInfo): GoogleLegInfo {
    tripRenderInfo.projectedLegs = [];

    const generatedLegs: GoogleLegInfo = {
      waypointsCandidates: [],
      calculateLegsWithGoogle: false,
    };

    for (let i = 0; i < _size(tripRenderInfo.stops) - 1; i++) {
      const start: RenderStop = tripRenderInfo.stops[i];
      const end: RenderStop = tripRenderInfo.stops[i + 1];
      const hasCompletedStops: boolean = i === 0 ? this.isCompleted(end.stop) : this.isCompleted(start.stop);
      const isConnector: boolean = this.isConnector(start, end);
      const isSicConnector: boolean = this.isSicConnector(start, end);

      const isPickupClusterConnector: boolean = this.isPickupClusterConnector(end);

      let color: string = '';
      if (this.layoutPreferenceService.isDispatcherLayout()) {
        color = this.routeColorService.getColorForTrip(tripInstId);
      } else if (!isConnector) {
        color = this.routeColorService.getColorForRoute(end.route.routeInstId);
      }

      generatedLegs.calculateLegsWithGoogle = true;
      generatedLegs.waypointsCandidates.push(MapUtils.getGoogleCoordinates(start.stop.customer));

      tripRenderInfo.projectedLegs.push({
        segmentId: i,
        hasCompletedStops: hasCompletedStops,
        isBreadcrumb: false,
        isConnector: isConnector,
        isSicConnector: isSicConnector,
        isPickupClusterConnector: isPickupClusterConnector,
        color: color,
        route: end.route,
        points: [],
      });

      if (generatedLegs.calculateLegsWithGoogle && tripRenderInfo.stops.length - 2 === i) {
        generatedLegs.waypointsCandidates.push(MapUtils.getGoogleCoordinates(end.stop.customer));
      }
    }

    return generatedLegs;
  }

  /**
   * Verifies if a stop contains valid coordinates and sequence number in order to be rendered.
   * @param stop
   */
  isAbleToRender(stop: Stop): boolean {
    const latLon = MapUtils.getCoordinates(stop.customer);
    return (
      !_isEqual(latLon, { lat: 0, lon: 0 }) && !!stop?.tripNode?.stopSequenceNbr // stop must be in sequence to generate legs
    );
  }

  /**
   * Given a date and threshold, returns if the date is overdue.
   * @param date date to compare
   * @param threshold threshold in seconds
   */
  isOverdue(date: Date, threshold: number): boolean {
    return date.getTime() + threshold * 1000 < new Date().getTime();
  }

  /**
   * It calculates a route midpoint by sorting and getting the largest segment of the list.
   * @param legs
   */
  calculateRouteMidpoint(legs: Leg[]): google.maps.LatLng {
    if (_size(legs) === 0) {
      return new google.maps.LatLng(0, 0);
    }

    const groupedLegsBySegment: Dictionary<Leg[]> = _groupBy(legs, (leg) => leg.segmentId);
    const segments: google.maps.LatLng[][] = [];

    _forOwn(groupedLegsBySegment, (groupedLegs: Leg[]) => {
      segments.push(_flatten(_map(groupedLegs, (leg) => leg.points)));
    });

    segments.sort((a, b) => b.length - a.length);

    return segments[0][Math.floor(segments[0].length / 2)];
  }

  /**
   * Determines if a stop is completed based on the trip node status code.
   * @param stop
   */
  isCompleted(stop: Stop): boolean {
    return stop?.tripNode?.statusCd === TripNodeStatusCd.COMPLETED;
  }

  /**
   * Check if the stops are equal (in length, route, stopSequenceNbr and lat/lon)
   */
  areStopsEqual(previous: RenderStop[], current: RenderStop[]): boolean {
    let isEqual: boolean = previous.length === current.length;

    for (let i = 0; isEqual && i < previous.length; i++) {
      const pre: RenderStop = previous[i];
      const curr: RenderStop = current[i];

      const preCoord = MapUtils.getCoordinates(pre.stop.customer);
      const curCoord = MapUtils.getCoordinates(curr.stop.customer);

      isEqual =
        (pre?.route?.routeInstId || 0) === (curr?.route?.routeInstId || 0) &&
        pre.stop.tripNode.stopSequenceNbr === curr.stop.tripNode.stopSequenceNbr &&
        preCoord.lat === curCoord.lat &&
        preCoord.lon === curCoord.lon;
    }

    return isEqual;
  }
}
