import { Activity, Stop } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentServiceTypeCd, ShipmentSpecialServiceCd, ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { concat as _concat, forEach as _forEach, has as _has, uniq as _uniq } from 'lodash';

export class SpecialServicesHelper {
  /**
   * Return the special services defined for a Stop (including Activities at Stop)
   */
  static getSpecialServicesForStop(stop: Stop): ShipmentSpecialServiceCd[] {
    if (stop) {
      let specialServices: ShipmentSpecialServiceCd[] = SpecialServicesHelper.getSpecialServicesForSummary(
        stop.specialServicesSummary
      );

      // aggregate services for each a activity at the stop
      _forEach(stop.activities, (activity) => {
        const activitySS = SpecialServicesHelper.getSpecialServicesForActivity(activity);
        specialServices = _concat(specialServices, activitySS);
      });

      return _uniq(specialServices);
    } else {
      return [];
    }
  }

  /**
   * Return list of active special services defined in the passed ShipmentSpecialServicesSummary
   */
  static getSpecialServicesForSummary(summary: ShipmentSpecialServiceSummary[]): ShipmentSpecialServiceCd[] {
    const specialServices: ShipmentSpecialServiceCd[] = [];

    _forEach(summary, (service) => {
      if (service.specialService && service.applicableInd) {
        specialServices.push(service.specialService);
      }
    });

    return _uniq(specialServices);
  }

  /**
   * Return all special services defined for the Activity
   */
  static getSpecialServicesForActivity(activity: Activity): ShipmentSpecialServiceCd[] {
    if (activity && _has(activity, 'routeShipment')) {
      // only care about activities that have a shipment
      const specialServices = SpecialServicesHelper.getSpecialServicesForSummary(activity.shipmentSpecialServices);

      if (activity?.tripNodeActivity?.serviceTypeCd === ShipmentServiceTypeCd.GUARANTEED_BY_12_NOON) {
        specialServices.push(ShipmentSpecialServiceCd.GUARANTEED_BY_NOON);
      }
      return _uniq(specialServices);
    } else {
      return [];
    }
  }

  static sortSpecialServiceSummary(specialServiceSummaries: ShipmentSpecialServiceCd[]): ShipmentSpecialServiceCd[] {
    specialServiceSummaries?.sort(
      (a, b) => Object.values(ShipmentSpecialServiceCd).indexOf(a) - Object.values(ShipmentSpecialServiceCd).indexOf(b)
    );
    return specialServiceSummaries;
  }
}
