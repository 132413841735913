import { some as _some, toString as _toString } from 'lodash';
import { MonoTypeOperatorFunction, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

export class PndAppUtils {
  /**
   * Retry observable, accepts an array of error codes to short circuit retry logic
   * @param giveUpAfter
   * @param retryInterval in ms
   * @param intervalSeed in ms
   * @param bypassErrorCodes array defaults to [404]
   */
  static retry<T>(
    giveUpAfter: number,
    retryInterval: number,
    intervalSeed: number,
    bypassErrorCodes: (number | string)[] = [404]
  ): MonoTypeOperatorFunction<T> {
    const shouldBypassErrorRetry = (err): boolean =>
      _some(bypassErrorCodes, (code: number) => err?.code && _toString(code) === _toString(err?.code));
    return retryWhen<T>((errors) => {
      return errors.pipe(
        concatMap((error, i) => {
          // Give up condition
          if (shouldBypassErrorRetry(error) || i >= giveUpAfter - 1) {
            return throwError({ ...error });
          }

          // Delay before retrying
          return of(error).pipe(delay(retryInterval + Math.random() * intervalSeed));
        })
      );
    });
  }
}
