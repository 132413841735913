import { Action } from '@ngrx/store';
import { EventItem, PastDueShipmentIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';

export enum ActionTypes {
  setSelectedPastDueShipments = 'pastDue.shipments.setSelectedPastDueShipments',
  setPastDueShipmentsLastUpdate = 'pastDue.shipments.setPastDueShipmentsLastUpdate',
  refresh = 'pastDue.shipments.refresh',
}

export class SetSelectedPastDueShipmentsAction implements Action {
  readonly type = ActionTypes.setSelectedPastDueShipments;
  constructor(public payload: { setSelectedPastDueShipments: EventItem<PastDueShipmentIdentifier>[] }) {}
}

export class SetPastDueShipmentsLastUpdate implements Action {
  readonly type = ActionTypes.setPastDueShipmentsLastUpdate;
  constructor(public payload: { pastDueShipmentsLastUpdate: Date }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions = SetSelectedPastDueShipmentsAction | SetPastDueShipmentsLastUpdate | Refresh;
