import { GetGeoAreasForLocationResp } from '@xpo-ltl-2.0/sdk-location';

export const StateKey = 'geoAreaState';

export interface State {
  readonly geoAreas: GetGeoAreasForLocationResp;
  readonly sicBoundaries: GetGeoAreasForLocationResp;
}

export const initialState: State = {
  geoAreas: undefined,
  sicBoundaries: undefined,
};
