import { Pipe, PipeTransform } from '@angular/core';
import { SpecialServicePipeProp } from './../components/service-icons/model/special-service-pipe-prop';

@Pipe({
  name: 'specialServiceMark',
})
export class SpecialServiceMarkPipe implements PipeTransform {
  transform(value: string, props?: SpecialServicePipeProp): unknown {
    let result = value;
    if (props?.applyMark) {
      switch (value) {
        case 'Appointment':
          result = props?.active ? 'Appointment Pending' : 'Appointment Confirmed';
          break;
      }
    }
    return result;
  }
}
