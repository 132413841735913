import { Pipe, PipeTransform } from '@angular/core';
import { BillClassCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'billClassCd' })
export class BillClassCdPipe implements PipeTransform {
  transform(value: BillClassCd, args?: any): string {
    switch (value) {
      case BillClassCd.ACCESSORIAL_ONLY:
        return 'ACCO';
      case BillClassCd.ASTRAY_FRT_SEGMENT:
        return 'MOVR';
      case BillClassCd.CLAIMS_OVRG_RPTG_BILL:
        return 'OREP';
      case BillClassCd.CO_BUS_SHPMT:
        return 'COBZ';
      case BillClassCd.EXPEDITE:
        return 'EXPD';
      case BillClassCd.GENERAL_CLAIMS_BUS_SHPMT:
        return 'GCBZ';
      case BillClassCd.MASTER_SEGMENT:
        return 'MSEG';
      case BillClassCd.MASTER_SHPMT:
        return 'MSTR';
      case BillClassCd.NORMAL_MVMT:
        return '';
      case BillClassCd.PARTIAL_SEGMENT:
        return 'PSEG';
      case BillClassCd.SPLIT_SEGMENT:
        return 'PTLT';
      default:
        return value;
    }
  }

  transformToTooltip(value: BillClassCd, args?: any): string {
    switch (value) {
      case BillClassCd.ACCESSORIAL_ONLY:
        return 'Accessorial Only';
      case BillClassCd.ASTRAY_FRT_SEGMENT:
        return 'Astray Freight Segment';
      case BillClassCd.CLAIMS_OVRG_RPTG_BILL:
        return 'Claims overage Reporting Bill';
      case BillClassCd.CO_BUS_SHPMT:
        return 'Company Business Shipment';
      case BillClassCd.EXPEDITE:
        return 'Expedite';
      case BillClassCd.GENERAL_CLAIMS_BUS_SHPMT:
        return 'Genral Claims Business Shipment';
      case BillClassCd.MASTER_SEGMENT:
        return 'Segment of a Master shipment';
      case BillClassCd.MASTER_SHPMT:
        return 'Master Shipment';
      case BillClassCd.NORMAL_MVMT:
        return 'Normal Movement';
      case BillClassCd.PARTIAL_SEGMENT:
        return 'Partial Shipment Segment';
      case BillClassCd.SPLIT_SEGMENT:
        return 'Revenue Bill for Split segments';
      default:
        return value;
    }
  }
}
