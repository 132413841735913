import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { DispatchGroup, PnDDispatchGroupRegion } from '@xpo-ltl/sdk-cityoperations';
import { size as _size } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutComponentName } from 'shared/enums/layout-component-name.enum';
import { LayoutManagerService, NamedContentItem } from 'shared/layout-manager';
import { MapToolbarService } from '../..';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { PndStore } from '../../../../store/pnd-store';
import { DispatchAreaRenderingService } from '../../services/dispatch-area/dispatch-area-rendering.service';
import { DispatchAreaService } from '../../services/dispatch-area/dispatch-area.service';

@Component({
  selector: 'app-map-dispatch-area',
  templateUrl: './map-dispatch-area.component.html',
  styleUrls: ['./map-dispatch-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapDispatchAreaComponent implements OnInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  private get mapPanel(): NamedContentItem {
    return this.layoutManagerService.getPanel(LayoutComponentName.PLANNING_MAP);
  }

  private get isMapMaximized(): boolean {
    return this.mapPanel?.parent?.isMaximised;
  }

  private dispatchGroupRegionsBehaviorSubject = new BehaviorSubject<PnDDispatchGroupRegion[]>(undefined);
  readonly dispatchGroupRegions$ = this.dispatchGroupRegionsBehaviorSubject.asObservable();

  disabled$: Observable<PnDDispatchGroupRegion>;

  @Input()
  isMapToolbarExpanded: boolean;

  @Input()
  isDrawOptionsPanelOpened: boolean;

  constructor(
    private dispatchAreaRenderingService: DispatchAreaRenderingService,
    private dispatchAreaService: DispatchAreaService,
    private mapToolbarService: MapToolbarService,
    private pndStore$: PndStore<PndStoreState.State>,
    private layoutManagerService: LayoutManagerService,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.disabled$ = this.dispatchAreaRenderingService.inDrawMode$;

    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((_) => {
        // First, we clean the map and the cards to provide feedback to the user
        this.dispatchAreaRenderingService.showPolygons(false);
        this.dispatchAreaRenderingService.enableDrawingMode(undefined);
        this.dispatchGroupRegionsBehaviorSubject.next(undefined);
      });

    this.dispatchAreaService.selectedDispatchGroups$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((_) => {
      const dispatchGroupRegions = this.dispatchAreaService.getDispatchGroupRegions();

      if (
        (_size(dispatchGroupRegions) > 0 && this.isMapMaximized) ||
        (_size(dispatchGroupRegions) === 0 && !this.isMapMaximized)
      ) {
        this.layoutManagerService.toggleMaximize(this.mapPanel);
      }

      this.dispatchGroupRegionsBehaviorSubject.next(dispatchGroupRegions);
      this.dispatchAreaRenderingService.showPolygons(true, this.dispatchGroupRegionsBehaviorSubject.value);
    });

    this.mapToolbarService.mapInstance$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((thisMap: google.maps.Map) => {
        this.dispatchAreaRenderingService.setMap(thisMap);
        this.dispatchAreaRenderingService.showPolygons(true, this.dispatchGroupRegionsBehaviorSubject.value);
      });

    this.dispatchAreaService.focusedDispatchGroupRegion$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((pnDDispatchGroupRegion: PnDDispatchGroupRegion) =>
        this.dispatchAreaRenderingService.focusedDispatchGroupChange(pnDDispatchGroupRegion)
      );

    this.dispatchAreaRenderingService.deleteDispatchGroupRegion$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((dispatchGroupRegion: PnDDispatchGroupRegion) => {
        this.dispatchAreaRenderingService.enableDrawingMode(undefined);
        this.dispatchAreaService.deleteDispatchGroup$(dispatchGroupRegion).subscribe();
      });

    this.dispatchAreaService.openUsageNotification();
  }

  ngOnDestroy(): void {
    if (this.isMapMaximized) {
      this.layoutManagerService.toggleMaximize(this.mapPanel);
    }

    this.dispatchAreaRenderingService.showPolygons(false);
    this.unsubscriber.complete();
  }

  scrollTo(childElement: ElementRef) {
    childElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  trackRegionBy(index, region: PnDDispatchGroupRegion): string | null {
    if (!region) {
      return null;
    }
    return region?.dispatchGroup?.groupId + index;
  }

  createAnotherArea() {
    const pnDDispatchGroupRegion = new PnDDispatchGroupRegion();
    pnDDispatchGroupRegion.dispatchGroup = new DispatchGroup();
    pnDDispatchGroupRegion.dispatchGroup.groupName = '';
    pnDDispatchGroupRegion.dispatchGroup.groupDescription = '';

    this.dispatchAreaRenderingService.enableDrawingMode(pnDDispatchGroupRegion);

    this.dispatchAreaService.openUsageNotification();
  }
}
