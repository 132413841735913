import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { concat as _concat, filter as _filter, some as _some } from 'lodash';
import { tassign } from 'tassign';
import { Actions, ActionTypes } from './driver-store.actions';
import { initialState, State } from './driver-store.state';

export function driversReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setDriversCurrentLocation: {
      return tassign(state, {
        driversCurrentLocation: action.payload.driversCurrentLocation,
      });
    }

    case ActionTypes.updateHighlightedDriver: {
      return tassign(state, {
        highlightedDriver: action.payload.highlightedDriverEmpId,
      });
    }

    case ActionTypes.updateFocusedDriver: {
      return tassign(state, {
        focusedDriver: action.payload.focusedDriver,
      });
    }

    case ActionTypes.updateDriversCurrentLocation: {
      const updatedDriverLocations = action.payload.updatedDriverLocations;

      const driverLocations: DriverLocation[] = _filter(state.driversCurrentLocation, (current: DriverLocation) => {
        return !_some(
          updatedDriverLocations,
          (update: DriverLocation) => current.currentLocation.employeeId === update.currentLocation.employeeId
        );
      });

      return tassign(state, {
        driversCurrentLocation: _concat(driverLocations, updatedDriverLocations),
      });
    }

    default: {
      return state;
    }
  }
}
