import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PANEL_MAP_COLORS } from './panel-map-colors';

export interface RouteColorChangeEvent {
  id: number;
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class RouteColorService {
  private assignedColors: Map<number, string>;
  private readonly colorChangedSubject = new BehaviorSubject<RouteColorChangeEvent>(undefined);
  readonly colorChanged$ = this.colorChangedSubject.asObservable();

  constructor() {
    this.clear();
  }

  private getLessRepeatedColor(): string {
    const repetitionCounter: { color: string; times: number }[] = [];

    for (let i = 0; i < PANEL_MAP_COLORS.length; i++) {
      const targetColor = PANEL_MAP_COLORS[i];
      let count = 0;

      this.assignedColors.forEach((assignedColor: string) => {
        if (targetColor === assignedColor) {
          count++;
        }
      });

      repetitionCounter.push({ color: targetColor, times: count });
    }

    const lessRepeatedColor = repetitionCounter.sort((a, b) => a.times - b.times)[0] || { color: '#FFFFFF', times: 0 };
    return lessRepeatedColor.color;
  }

  private setColor(id: number): string {
    if (!this.assignedColors.has(id)) {
      const color = this.getUnassignedColors()[0] || this.getLessRepeatedColor();
      this.assignedColors.set(id, color);
      return color;
    } else {
      return this.assignedColors.get(id);
    }
  }

  private clearColor(id: number): void {
    const color = this.assignedColors.get(id);
    if (color) {
      this.assignedColors.delete(id);
    }
    this.colorChangedSubject.next(undefined);
  }

  private changeColor(id: number, color: string): void {
    this.assignedColors.set(id, color);
    this.colorChangedSubject.next({ id, color: color });
  }

  /**
   * Clear all assigned colors
   */
  clear(): void {
    this.assignedColors = new Map<number, string>();
  }

  getUnassignedColors(): string[] {
    let allColors = [...PANEL_MAP_COLORS];
    this.assignedColors.forEach((assignedColor: string) => {
      allColors = allColors.filter((color) => color !== assignedColor);
    });
    return allColors;
  }

  //#region Routes

  /**
   * Returns color assigned to this route.
   */
  getColorForRoute(routeInstId: number): string {
    return this.setRouteColor(routeInstId);
  }

  /**
   * Returns color assigned to this route. Assigns a new color if one has not already been assigned.
   */
  setRouteColor(routeInstId: number): string {
    return this.setColor(routeInstId);
  }

  /**
   * Removes the association between a route and a specific color
   */
  clearRouteColor(routeInstId: number): void {
    this.clearColor(routeInstId);
  }

  changeRouteColor(routeInstId: number, color: string): void {
    this.changeColor(routeInstId, color);
  }

  //#endregion

  //#region Trips

  /**
   * Returns color assigned to this Trip.
   */
  getColorForTrip(tripInstId: number): string {
    return this.setTripColor(tripInstId);
  }

  /**
   * Returns color assigned to this Trip. Assigns a new color if one has not already been assigned.
   */
  setTripColor(tripInstId: number): string {
    return this.setColor(tripInstId);
  }

  clearTripColor(tripInstId: number): void {
    this.clearColor(tripInstId);
  }

  changeTripColor(tripInstId: number, color: string): void {
    this.changeColor(tripInstId, color);
  }

  /**
   * Routes created from Route Balancing section have a temporary id assigned to them
   * until the route is actually created.
   * This method replaces the association of the temp routeInstId with the actual one.
   */
  replaceTempRouteInstId(tempRouteInstId: number, actualRouteInstId: number) {
    const color = this.assignedColors.get(tempRouteInstId);
    this.assignedColors.set(actualRouteInstId, color);
    this.assignedColors.delete(tempRouteInstId);
  }

  //#endregion
}
