import { Pipe, PipeTransform } from '@angular/core';
import { HandlingUnitTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'handlingUnitTypeCd' })
export class HandlingUnitTypeCdPipe implements PipeTransform {
  transform(typeCd: HandlingUnitTypeCd): string {
    if (typeCd === HandlingUnitTypeCd.LOOSE) {
      return 'LP';
    }
    if (typeCd === HandlingUnitTypeCd.MOTOR) {
      return 'MM';
    }
    return null;
  }
}
