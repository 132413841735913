import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { PositioningPoint, Route } from '@xpo-ltl/sdk-cityoperations';
import { find as _find } from 'lodash';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { PndStoreState } from '../../../../../store';
import { PndStore } from '../../../../../store/pnd-store';
import { RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { PickupClusterPointsService } from '../../../../shared/services/pickup-cluster-points-service/pickup-cluster-points.service';
import { ResequencingRouteData } from '../../../route-balancing';
import { ContextMenuItemId } from '../../enums/contextual-menu-item.enum';
import { ContextMenuPosition } from '../marker-context-menu/marker-context-menu.component';
export interface PickupClusterContextMenuItem {
  label: string;
  id: ContextMenuItemId;
  nested?: boolean;
  triggerFor?: string;
  route?: Route;
}

/**
 * Implement a context menu that can be opened at a specific
 * screen position
 */
@Component({
  selector: 'pnd-pickup-cluster-context-menu',
  templateUrl: './pickup-cluster-context-menu.component.html',
  styleUrls: ['./pickup-cluster-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupClusterContextMenuComponent {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @Input() menuItems: PickupClusterContextMenuItem[] = [];

  private readonly menuItemsSubject = new BehaviorSubject<PickupClusterContextMenuItem[]>([]);
  readonly menuItems$ = this.menuItemsSubject.asObservable();

  private menuObserver: any;

  private readonly menuPositionSubject = new BehaviorSubject<ContextMenuPosition>({ x: 0, y: 0 });
  readonly menuPosition$ = this.menuPositionSubject.asObservable();

  private readonly routesMenuListSubject = new BehaviorSubject<Route[]>([]);
  private readonly removeMenuListSubject = new BehaviorSubject<Route[]>([]);

  readonly routesMenuList$ = this.routesMenuListSubject.asObservable();
  readonly removeMenuList$ = this.removeMenuListSubject.asObservable();

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private pickupClusterPointsService: PickupClusterPointsService
  ) {}

  /**
   * Open the context menu at the specified absolute screen position
   */
  openMenu(x: number, y: number, point: PositioningPoint): Observable<PickupClusterContextMenuItem> {
    // return Observable that resolves with the selected menu item, or undefined
    // if menu closed with no item selected
    return new Observable<PickupClusterContextMenuItem>((observer: Subscriber<PickupClusterContextMenuItem>) => {
      const pickupClustersRoutesMap: Map<number, Route[]> = this.pickupClusterPointsService.pickupClustersRoutesMap;
      const resequencedRouteData: { [routeInstId: number]: ResequencingRouteData } = this.pndStore$.selectSnapshot(
        RouteBalancingSelectors.resequencedRouteData
      );

      const selectedRoutes: Route[] = [];
      Object.keys(resequencedRouteData).forEach((routeInstId) => {
        const routeData: ResequencingRouteData = resequencedRouteData[routeInstId];
        const route = {
          ...new Route(),
          routeInstId: +routeInstId,
          routeName: routeData.routeName,
          routePrefix: routeData.routePrefix,
          routeSuffix: routeData.routeSuffix,
          statusCd: routeData.routeStatusCd,
        };

        selectedRoutes.push(route);
      });

      const assignedRoutesToPoint: Route[] = pickupClustersRoutesMap.get(point.positioningPointId);
      this.removeMenuListSubject.next(assignedRoutesToPoint);
      this.routesMenuListSubject.next(
        selectedRoutes.filter(
          (route) => !_find(assignedRoutesToPoint, (assignedRoute) => assignedRoute.routeInstId === route.routeInstId)
        )
      );
      this.menuItemsSubject.next(this.menuItems);
      // set the menu position.
      this.menuPositionSubject.next({ x, y });
      this.menuTrigger.openMenu();

      // TODO - HACK to set the position of the menu.  Should be able to do this
      // by setting the style on the element in HTML, but isn't working.
      const elem = document.querySelector('.pndContextMenu__panel') as HTMLElement;
      elem.style.left = `${x}px`;
      elem.style.top = `${y}px`;
      this.menuObserver = observer;
    });
  }

  itemSelected(item: PickupClusterContextMenuItem) {
    this.dispatchSelection(item);
  }

  routeSelected(item: PickupClusterContextMenuItem, route: Route) {
    const selectedItem: PickupClusterContextMenuItem = {
      ...item,
      route: route,
    };
    this.dispatchSelection(selectedItem);
  }
  trackMenuItemBy(index, menuItem: PickupClusterContextMenuItem): ContextMenuItemId | null {
    if (!menuItem) {
      return null;
    }
    return menuItem?.id;
  }
  trackRouteMenuBy(index, route: Route): number | null {
    if (!route) {
      return null;
    }
    return route?.routeInstId;
  }
  trackRemoveMenuBy(index, route: Route): number | null {
    if (!route) {
      return null;
    }
    return route?.routeInstId;
  }
  menuClosed() {
    this.dispatchSelection(undefined);
  }

  private dispatchSelection(selection: PickupClusterContextMenuItem) {
    if (this.menuObserver) {
      this.menuObserver.next(selection);
      this.menuObserver.complete();
      this.menuObserver = undefined;
    }
  }
}
