import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DockRoutesStoreModule } from '@pnd-store/dock-routes-store/dock-routes.store.module';
import { environment } from 'environments/environment';
import { DispatcherTripsStoreModule } from './dispatcher-trips-store';
import { DriverStoreModule } from './driver-store/driver-store.module';
import { GeoAreaStoreModule } from './geo-area-store/geo-area-store.module';
import { GeoLocationStoreModule } from './geo-location-store/geo-location-store.module';
import { GlobalFiltersStoreModule } from './global-filters-store';
import { ModifyTripDetailsStoreModule } from './modify-trip-details-store';
import { PastDueShipmentsStoreModule } from './past-due-shipments-store';
import { PlanningProfilesStoreModule } from './planning-profiles-store';
import { RouteBalancingStoreModule } from './route-balancing-store';
import { RoutesStoreModule } from './routes-store/routes-store.module';
import { SpotsAndDropsStoreModule } from './spots-and-drops-store';
import { TripsStoreModule } from './trips-store/trips-store.module';
import { UnassignedDeliveriesStoreModule } from './unassigned-deliveries-store';
import { UnassignedPickupsStoreModule } from './unassigned-pickups-store';

// Set this to TRUE in order to enable Redux Devtools.
// NOTE: There is a bug in DevTools that can cause Effects to fire multiple times.  This
// can result in the same Actions firing multiple times! Enable this at your own risk.
const enableDevTools = environment.enableDevTools;

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    // ngrx Store setup
    StoreModule.forRoot({}),
    enableDevTools ? StoreDevtoolsModule.instrument({ name: 'PndApp-Local', maxAge: 250 }) : [],
    EffectsModule.forRoot([]),

    DriverStoreModule,
    GeoAreaStoreModule,
    GeoLocationStoreModule,
    GlobalFiltersStoreModule,
    PlanningProfilesStoreModule,
    RouteBalancingStoreModule,
    RoutesStoreModule,
    TripsStoreModule,
    DispatcherTripsStoreModule,
    SpotsAndDropsStoreModule,
    UnassignedDeliveriesStoreModule,
    UnassignedPickupsStoreModule,
    ModifyTripDetailsStoreModule,
    DockRoutesStoreModule,
    PastDueShipmentsStoreModule,
  ],
})
export class PndStoreModule {}
