<ng-container *ngIf="{ merging: merging$ | async } as state">
  <div mat-dialog-title class="merge-pickups-header">
    <h1 class="merge-pickups-title">Merge Pickups Request</h1>
    <button mat-button class="merge-pickups-close-icon" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
    </div>
  <div mat-dialog-content class="merge-pickups-confirm-content">
    <app-merge-pickups
      [merging]="merging$"
      [mergeRqst]="mergeRqst"
      (mergeComplete)="onMergeComplete($event)"
    ></app-merge-pickups>
  </div>

  <div mat-dialog-actions class="merge-pickups-confirm-actions">
    <xpo-button-group>
      <button
        mat-flat-button
        xpoLargeButton
        data-test="btn-merge-pickups"
        (click)="initiateMerge()"
        [disabled]="state.merging"
      >
        Merge
      </button>
      <button
        mat-stroked-button
        xpoLargeButton
        data-test="btn-cancel-merge-pickups"
        (click)="closeDialog()"
        [disabled]="state.merging"
      >
        Cancel
      </button>
    </xpo-button-group>
  </div>

  <mat-progress-spinner
    mode="indeterminate"
    *ngIf="state.merging"
    [strokeWidth]="5"
    [diameter]="60"
    class="merging-pickups-Notification-spinner"
  >
  </mat-progress-spinner>
</ng-container>
