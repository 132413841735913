<div class="pnd-SicZonesSelector">
  <mat-form-field class="ngx-FormField--inline">
    <mat-select
      [(value)]="selectedOption"
      [placeholder]="placeholder"
      panelClass="pnd-SicZonesSelector__panel"
      (selectionChange)="handleSelectionChange()"
      [disabled]="(loading$ | async) || disabled || !selectedOption"
      data-test="pnd-SicZonesSelector"
    >
      <mat-select-trigger>
        {{ optionLabels[selectedOption] }}
        <span *ngIf="includedSics[selectedOption]?.length > 0">- {{ includedSics[selectedOption]?.join(', ') }}</span>
      </mat-select-trigger>

      <mat-option
        *ngFor="let option of options; trackBy: trackOptionsBy; let i = index"
        [value]="option"
        [disabled]="includedSics[option]?.length === 0"
        attr.data-test="pnd-SicZonesSelector-option-{{ i }}"
      >
        <div class="pnd-SicZonesSelector__option">
          <xpo-select-two-lines-container>
            <span>{{ optionLabels[option] }}</span>
            <span *ngIf="includedSics[option]">
              <span *ngFor="let sic of includedSics[option]; let isLast = last; trackBy: trackSicBy">
                <span [class.bold-text]="!isSatelliteSic[sic]">{{ sic }}</span
                ><span class="list-format" *ngIf="!isLast">, </span>
              </span>
            </span>
          </xpo-select-two-lines-container>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
