import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { truncate as _truncate } from 'lodash';
import { PriorityCd } from '../../../../../shared/enums/priority.enum';

@Component({
  selector: 'pnd-pickup-close-time-cell-renderer',
  templateUrl: './pickup-close-time-cell-renderer.component.html',
  styleUrls: ['./pickup-close-time-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickupCloseTimeCellRendererComponent implements ICellRendererAngularComp {
  content: string;
  tooltip: string;

  readonly PriorityCd = PriorityCd;
  readonly lowPriorityTooltip = 'Small PU Window';
  readonly mediumPriorityTooltip = 'Close Time in <1 Hr';
  readonly highPriorityTooltip = 'Passed Close Time';
  constructor() {}

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    const readyTime = params?.value?.readyTime;
    const closeTime = params?.value?.closeTime;
    const unformattedContent = readyTime ? readyTime : closeTime;
    this.content = unformattedContent ? _truncate(unformattedContent, { length: 5, omission: '' }) : '';
    this.getTooltip(params);
    return true;
  }

  getTooltip(params: any) {
    const priority = params.value.priority;
    if (priority === PriorityCd.LOW) {
      this.tooltip = this.lowPriorityTooltip;
    }
    if (priority === PriorityCd.MEDIUM) {
      this.tooltip = this.mediumPriorityTooltip;
    }
    if (priority === PriorityCd.HIGH) {
      this.tooltip = this.highPriorityTooltip;
    }
  }
}
