import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PndStoreState } from '../index';
import { State, StateKey } from './dispatcher-trips-store.state';

export const dispatcherTripsState = createFeatureSelector<PndStoreState.State, State>(StateKey);

export const searchCriteria = createSelector(dispatcherTripsState, (state: State) => state.criteria);

export const selectedTrips = createSelector(dispatcherTripsState, (state: State) => state.selectedTrips);

export const watchedDrivers = createSelector(dispatcherTripsState, (state: State) => state.watchedDrivers);

export const lastUpdate = createSelector(dispatcherTripsState, (state: State) => state.lastUpdate);

export const dispatcherTripsGridScrollPosition = createSelector(
  dispatcherTripsState,
  (state: State) => state.gridScrollPosition
);
