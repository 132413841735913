import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { XpoBoardState } from '@xpo-ltl/ngx-board/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Observable, timer } from 'rxjs';
import { distinctUntilChanged, filter, skip, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GridToolbarService } from '../../services/grid-toolbar.service';

@Component({
  selector: 'pnd-grid-toolbar-search',
  templateUrl: './grid-toolbar-search.component.html',
  styleUrls: ['./grid-toolbar-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridToolbarSearchComponent implements OnInit {
  @ViewChildren('searchInput') searchInput: QueryList<ElementRef>;

  @Input() boardState$: Observable<XpoBoardState>;
  @Input() searchDisplayText: string = 'Search For Anything';
  @Input() searchCriteriaKey: string;

  readonly searchActive$: Observable<boolean> = this.gridToolbarService.searchActive$;
  readonly searchFilterValue$: Observable<string> = this.gridToolbarService.searchFilterValue$;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  searchCtrl: UntypedFormControl;

  constructor(private gridToolbarService: GridToolbarService) {}

  ngOnInit() {
    this.initializeSearchControl();
    this.subscribeToSearchFilterUpdates();
  }

  toggleSearchActive(isActive: boolean): void {
    this.gridToolbarService.setSearchActive(isActive);

    if (!!isActive) {
      timer(20).subscribe(() => {
        this.searchInput.first?.nativeElement?.focus();
      });
    }
  }

  onInputFocus() {
    this.gridToolbarService.setInputFocus(true);
  }

  onInputFocusOut() {
    this.gridToolbarService.setInputFocus(false);
  }

  handleClearSearchFilter(): void {
    this.gridToolbarService.setSearchFilter(undefined);
  }

  private initializeSearchControl(): void {
    this.searchCtrl = new UntypedFormControl();
    this.searchDisplayText = this.searchDisplayText || 'Search For Anything';

    this.searchCtrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.gridToolbarService.searchActive$),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(([val, searchActive]: [string, boolean]) => {
        if (!searchActive) {
          this.gridToolbarService.setSearchActive(true);
        }

        this.gridToolbarService.setSearchFilter(val);
      });

    /*    if (this.searchCriteriaKey) {
      this.boardState$
        .pipe(
          filter((boardState: XpoBoardState) => _includes(boardState.changes, 'criteria')),
          pluck('criteria'),
          distinctUntilKeyChanged(this.searchCriteriaKey),
          pluck(this.searchCriteriaKey),
          filter(
            (criteriaValue: string) =>
              criteriaValue !== this.searchCtrl.value && !(_isEmpty(criteriaValue) && _isEmpty(this.searchCtrl.value)) // value comparison can be undefined vs null which we want to filter out
          ),
          takeUntil(this.unsubscriber.done$)
        )
        .subscribe((criteriaValue: string) => {
          this.gridToolbarService.setSearchFilter(criteriaValue);
        });
    }*/
  }

  private subscribeToSearchFilterUpdates(): void {
    this.searchFilterValue$
      .pipe(
        skip(1),
        filter((searchValue: string) => searchValue !== this.searchCtrl.value),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((searchValue: string) => {
        this.searchCtrl.setValue(searchValue);
      });
  }
}
