import { Injectable } from '@angular/core';
import { XpoBoardView, XpoBoardViewConfig, XpoBoardViewUtil, XpoGridBoardViewConfig } from '@xpo-ltl/ngx-board/core';

import {
  XpoAgGridBoardState,
  XpoAgGridBoardView,
  XpoAgGridBoardViewConfig,
  XpoAgGridBoardViewTemplate,
} from '@xpo-ltl/ngx-board/ag-grid';
import { UserPreferencesService } from 'app/inbound-planning/shared/services/user-preferences.service';
import {
  Dictionary,
  differenceBy as _differenceBy,
  includes as _includes,
  keyBy as _keyBy,
  sortBy as _sortBy,
  uniqBy as _uniqBy,
} from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, map, mapTo, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BoardStatesEnum } from 'shared/enums/board-states.enum';

export interface PndMultiGridBoardViewConfig {
  boardType: string;
  config: XpoAgGridBoardViewConfig;
}

export interface RegisteredBoardSource {
  boardStateObserver$: Observable<XpoAgGridBoardState>;
  stateChangeObserver$: ReplaySubject<XpoAgGridBoardState>;
  viewTemplate: XpoAgGridBoardViewTemplate;
  boardSourceDestroyed$: Subject<void>;
}

export interface RegisteredStateValue {
  boardType: string;
  state: XpoAgGridBoardState;
}

@Injectable()
export class PndMultiGridBoardViewService {
  private readonly multiGridBoardViewConfigsSubject = new BehaviorSubject(undefined);
  readonly multiGridBoardViewConfigs$ = this.multiGridBoardViewConfigsSubject.asObservable();
  private get multiGridBoardViewConfigs(): PndMultiGridBoardViewConfig[] {
    return this.multiGridBoardViewConfigsSubject.value;
  }

  private readonly loadingBoardViewConfigsSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly loadingBoardViewConfigs$: Observable<boolean> = this.loadingBoardViewConfigsSubject.asObservable();

  private readonly activeViewIdSubject = new BehaviorSubject<string>(undefined);
  readonly activeViewId$ = this.activeViewIdSubject.asObservable();

  private selectedBoardSubject = new BehaviorSubject<string>(undefined);
  readonly selectedBoard$ = this.selectedBoardSubject.asObservable();

  protected registeredBoardSources: Map<string, RegisteredBoardSource> = new Map();

  constructor(private userPreferencesService: UserPreferencesService) {}

  /**
   * Registers the values as a RegisteredBoardSource with the boardType as key
   *
   * @param boardStateObserver$
   * @param stateChangeObserver$
   * @param viewTemplate
   * @param boardType
   */
  registerBoardSource(
    boardStateObserver$: Observable<XpoAgGridBoardState>,
    stateChangeObserver$: ReplaySubject<XpoAgGridBoardState>,
    viewTemplate: XpoAgGridBoardViewTemplate,
    boardType: string
  ): void {
    this.registeredBoardSources.set(boardType, {
      boardStateObserver$,
      stateChangeObserver$,
      viewTemplate,
      boardSourceDestroyed$: new Subject(),
    });

    this.subscribeToBoardSourceStateUpdates(boardType);
  }

  private subscribeToBoardSourceStateUpdates(currentBoardType: string): void {
    const boardSource: RegisteredBoardSource = this.registeredBoardSources.get(currentBoardType);

    boardSource.boardStateObserver$
      .pipe(takeUntil(boardSource.boardSourceDestroyed$))
      .subscribe((state: XpoAgGridBoardState) => {
        if (state.source === BoardStatesEnum.GRID_SETTINGS_UPDATE) {
          this.updateRowHeightForInactiveBoardSources(currentBoardType, state.rowHeight);
        }
      });
  }

  private updateRowHeightForInactiveBoardSources(currentBoardType: string, updatedRowHeight: number): void {
    this.registeredBoardSources.forEach((registeredSource: RegisteredBoardSource, boardType: string) => {
      if (boardType !== currentBoardType) {
        registeredSource.boardStateObserver$
          .pipe(
            take(1),
            filter((state: XpoAgGridBoardState) => {
              return state.rowHeight !== updatedRowHeight;
            })
          )
          .subscribe(() => {
            registeredSource.stateChangeObserver$.next({
              source: BoardStatesEnum.GRID_SETTINGS_UPDATE,
              changes: ['rowHeight'],
              rowHeight: updatedRowHeight,
            });
          });
      }
    });
  }

  /**
   * Unregisters the values as a RegisteredBoardSource with the boardType as key
   * and calls complete for any active internal subscriptions listening to source updates
   *
   * @param boardType
   */
  deregisterBoardSource(boardType: string): void {
    const boardSource: RegisteredBoardSource = this.registeredBoardSources.get(boardType);

    boardSource?.boardSourceDestroyed$.next();
    boardSource?.boardSourceDestroyed$.complete();

    this.registeredBoardSources.delete(boardType);
  }

  /**
   * Sets the active board id for all multigrid registered sources
   *
   * @param viewId
   */
  setActiveBoardViewId(viewId: string): void {
    this.activeViewIdSubject.next(viewId);
  }

  /**
   * Sets the selected board value for each subscriber to the activeBoard$
   *
   * @param selectedBoard
   */
  setSelectedBoard(selectedBoard: string): void {
    this.selectedBoardSubject.next(selectedBoard);
  }

  /**
   * Loads all multigrid board views for a given component name
   *
   * @param componentName: component to load views from user preferences
   */
  loadMultiGridBoardViews(componentName: string): void {
    this.loadingBoardViewConfigsSubject.next(true);

    this.userPreferencesService
      .getPreferencesFor<PndMultiGridBoardViewConfig[]>(componentName)
      .pipe(
        catchError(() => {
          return of([]);
        }),
        take(1),
        map((configs: (PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig)[]) => {
          return this.convertToPndMultiGridBoardViewConfigs(configs) ?? [];
        })
      )
      .subscribe((configs: PndMultiGridBoardViewConfig[]) => {
        this.multiGridBoardViewConfigsSubject.next(configs);
        this.loadingBoardViewConfigsSubject.next(false);
      });
  }

  /**
   * Given an array of PndMultiGridBoardViewConfigs or XpoAgGridBoardViewConfigs, converts all values to
   * PnDMultiGridBoardViewConfig objects across all registered board sources
   *
   * @param configs: initial list of PndMultiGridBoardViewConfigs/XpoAgGridBoardViewConfigs to convert
   */
  private convertToPndMultiGridBoardViewConfigs(
    configs: (PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig)[]
  ): PndMultiGridBoardViewConfig[] {
    const transformedConfigs: PndMultiGridBoardViewConfig[] = configs?.reduce(
      (convertedConfigs, currentConfig: PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig) => {
        let shouldTranslate: boolean;
        let newConvertedConfigs: PndMultiGridBoardViewConfig[] = [];
        // config has already been converted, so we need to make sure all states have a version of it
        if ('boardType' in currentConfig) {
          // if view has already been converted, we only need to translate if the view is not an unsaved system defined view
          // as system defined views will be handled on initialization of the view data store
          shouldTranslate = !!currentConfig.config.lastSaved;

          if (shouldTranslate) {
            newConvertedConfigs = this.getTranslatedMultiGridConfigsFromMultiGridConfig(currentConfig, configs);
          }
        } else {
          // config hasn't been transformed yet so we need to convert to a multigrid board view model
          shouldTranslate = !!currentConfig.lastSaved || !!currentConfig.templateId;

          if (shouldTranslate) {
            newConvertedConfigs = this.getTranslatedMultiGridConfigsFromBoardTemplate(currentConfig);
          }
        }

        return [...convertedConfigs, ...newConvertedConfigs];
      },
      []
    );

    return transformedConfigs;
  }

  /**
   * Given a PndMultiGridBoardViewConfigs and array of PndMultiGridBoardViewConfig/XpoAgGridBoardViewConfig values, translates to
   * PnDMultiGridBoardViewConfigs across all registered board sources
   *
   * @param config: PndMultiGridBoardViewConfig to convert to all board sources
   * @param allConfigs: array of PndMultiGridBoardViewConfigs/XpoAgGridBoardViewConfigs to reference during conversion to
   * ensure config has already been converted to all registered boards
   */
  private getTranslatedMultiGridConfigsFromMultiGridConfig(
    config: PndMultiGridBoardViewConfig,
    allConfigs: (PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig)[]
  ): PndMultiGridBoardViewConfig[] {
    const configMap: Dictionary<PndMultiGridBoardViewConfig | XpoGridBoardViewConfig> = _keyBy(allConfigs, (c) =>
      this.getUniqueId(c)
    );

    const convertedConfigs: PndMultiGridBoardViewConfig[] = [];

    this.registeredBoardSources.forEach((registeredBoardSource: RegisteredBoardSource, sourceBoardType: string) => {
      const isConfigDefined: boolean = !!configMap[this.getUniqueId({ ...config, boardType: sourceBoardType })];

      if (!isConfigDefined) {
        // view isn't defined across current sub grids, so we need to create a new config for the
        // sourceBoardType and add it to the current list of configs

        const templateConfig: XpoAgGridBoardViewConfig = this.getViewConfigFromTemplate(
          registeredBoardSource.viewTemplate,
          {
            name: config.config.name,
            id: config.config.id,
            lastSaved: config.config.lastSaved,
          }
        );

        convertedConfigs.push({
          boardType: sourceBoardType,
          config: templateConfig,
        });
      } else if (!_includes(convertedConfigs, config)) {
        convertedConfigs.push(config);
      }
    });

    return convertedConfigs;
  }

  /**
   * Given an XpoBoardViewConfig, translates to PnDMultiGridBoardViewConfigs across all registered board sources
   *
   * @param config: XpoBoardViewConfig to convert to all board sources
   */
  private getTranslatedMultiGridConfigsFromBoardTemplate(config: XpoBoardViewConfig): PndMultiGridBoardViewConfig[] {
    const convertedConfigs: PndMultiGridBoardViewConfig[] = [];

    this.registeredBoardSources.forEach((registeredBoardSource: RegisteredBoardSource, sourceBoardType: string) => {
      if (config.templateId === registeredBoardSource.viewTemplate.id) {
        convertedConfigs.push({ boardType: sourceBoardType, config: config });
      } else {
        // if it is not a system defined view, find correct board template from state values and create
        // new  multigrid board view config for current board type
        if (!config.systemDefined) {
          const templateViewConfig: XpoGridBoardViewConfig = this.getViewConfigFromTemplate(
            registeredBoardSource.viewTemplate,
            {
              name: config.name,
              id: config.id,
              lastSaved: config.lastSaved,
            }
          );

          convertedConfigs.push({
            boardType: sourceBoardType,
            config: templateViewConfig,
          });
        }
      }
    });

    return convertedConfigs;
  }

  /**
   * Given an boardType, returns all currently saved multi grid board views for that board
   *
   * @param boardType: boardType to filter all current views
   */
  getViewsForBoard$(boardType: string): Observable<XpoAgGridBoardViewConfig[]> {
    return this.multiGridBoardViewConfigs$.pipe(
      skipWhile((viewConfigs: PndMultiGridBoardViewConfig[]) => !viewConfigs),
      take(1),
      map((multGridBoardViewConfigs: (PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig)[]) => {
        const currentBoardViewConfigs =
          multGridBoardViewConfigs?.filter((config: PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig) => {
            return (
              (<PndMultiGridBoardViewConfig>config).boardType === boardType ||
              (<XpoAgGridBoardViewConfig>config).templateId ===
                this.registeredBoardSources.get(boardType).viewTemplate.id
            );
          }) ?? [];

        const mappedConfigs: XpoAgGridBoardViewConfig[] = currentBoardViewConfigs.map(
          (boardView: PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig) => {
            return (<PndMultiGridBoardViewConfig>boardView).config || <XpoAgGridBoardViewConfig>boardView;
          }
        );

        return _sortBy(mappedConfigs, (c: XpoAgGridBoardViewConfig) => c.name);
      })
    );
  }

  /**
   * Given an array of XpoAgGridBoardViewConfigs, boardType, and componentName, updates the views across all registered
   * board sources
   *
   * @param boardConfigs: array of XpoAgGridBoardViewConfigs views from the active board
   * @param boardType: active board
   * @param componentName: component name to update preferences for
   */
  updateViewsForBoard$(
    boardConfigs: XpoAgGridBoardViewConfig[],
    boardType: string,
    componentName: string
  ): Observable<XpoAgGridBoardViewConfig[]> {
    // check if deleting a view by comparing length of deletable/non system defined views for that board against the multiGridBoardViewConfigs cache
    const nonSystemDefinedBoardConfigViews: XpoAgGridBoardViewConfig[] = boardConfigs.filter(
      (config: XpoAgGridBoardViewConfig) => !config.systemDefined
    );
    const nonSystemDefinedMultiGridViewsForBoardType: PndMultiGridBoardViewConfig[] = this.multiGridBoardViewConfigs.filter(
      (config: PndMultiGridBoardViewConfig) => {
        return config.boardType === boardType && !config.config.systemDefined;
      }
    );

    const isDeletingView: boolean =
      !!this.multiGridBoardViewConfigs?.length &&
      nonSystemDefinedBoardConfigViews.length < nonSystemDefinedMultiGridViewsForBoardType.length;

    const updateBoardAction$: Observable<PndMultiGridBoardViewConfig[]> = isDeletingView
      ? this.handleDeleteView$(boardConfigs, boardType, componentName)
      : this.handleSaveView$(boardConfigs, boardType, componentName);

    return updateBoardAction$.pipe(
      tap((nextBoardConfigState: PndMultiGridBoardViewConfig[]) => {
        this.multiGridBoardViewConfigsSubject.next(nextBoardConfigState);
      }),
      mapTo(boardConfigs)
    );
  }

  /**
   * Given an array of XpoAgGridBoardViewConfigs, boardType, and componentName, saves or updates the view from all registered
   * board sources and updates user preferences with the new array of configs
   *
   * @param boardConfigs: array of XpoAgGridBoardViewConfigs views from the active board
   * @param boardType: active board
   * @param componentName: component name to update preferences for
   */
  private handleSaveView$(
    boardConfigs: XpoAgGridBoardViewConfig[],
    boardType: string,
    componentName: string
  ): Observable<PndMultiGridBoardViewConfig[]> {
    // store the next states for any inactive grids so we can update the view states after a successful save
    const nextInactiveBoardStates: { state: XpoAgGridBoardState; boardType: string }[] = [];

    return this.getLatestRegisteredStateValues$().pipe(
      map((stateValues: RegisteredStateValue[]) => {
        const updatedView: XpoAgGridBoardViewConfig = this.getLatestSavedViewFromBoardViewConfigs(boardConfigs);
        const isSavingNewView: boolean = !this.multiGridBoardViewConfigs.find((config: PndMultiGridBoardViewConfig) => {
          return config.boardType === boardType && config.config.id === updatedView.id;
        });

        const viewConfigsToAdd: PndMultiGridBoardViewConfig[] = [];

        stateValues.forEach((stateValue: RegisteredStateValue) => {
          let nextState: XpoAgGridBoardState;

          if (boardType === stateValue.boardType) {
            viewConfigsToAdd.push({ boardType, config: updatedView });
          } else {
            // map to a new board config for inactive boards and for saving to preferences
            const currentBoardViewConfig: XpoAgGridBoardView = stateValue.state.availableViews.find(
              ({ id }: { id: string }) => id === stateValue.state.viewId
            ) as XpoAgGridBoardView;
            const updatedBoardViewConfig: XpoBoardViewConfig = {
              ...currentBoardViewConfig.toViewConfig(stateValue.state),
              name: updatedView.name,
              id: updatedView.id,
              systemDefined: updatedView.systemDefined,
              lastSaved: updatedView.lastSaved,
              isActive: false,
            };

            viewConfigsToAdd.push({ boardType: stateValue.boardType, config: updatedBoardViewConfig });

            // map to a new board view for inactive boards for updating board states to register new view
            const newInactiveBoardView: XpoBoardView = stateValue.state.template.createView(updatedBoardViewConfig);
            nextState = isSavingNewView
              ? XpoBoardViewUtil.addView(newInactiveBoardView, stateValue.state, BoardStatesEnum.SAVE_VIEW_AS)
              : XpoBoardViewUtil.updateView(newInactiveBoardView, stateValue.state, BoardStatesEnum.SAVE_VIEW);

            nextInactiveBoardStates.push({ state: nextState, boardType: stateValue.boardType });
          }
        });

        const uniqueConfigs: PndMultiGridBoardViewConfig[] = _uniqBy(
          [...viewConfigsToAdd, ...this.multiGridBoardViewConfigs],
          (config: PndMultiGridBoardViewConfig) => this.getUniqueId(config)
        );

        return uniqueConfigs;
      }),
      switchMap((nextMultiGridBoardConfigState: PndMultiGridBoardViewConfig[]) => {
        // update preferences with new list of multgrid board view configs
        return this.userPreferencesService.updatePreferencesFor<PndMultiGridBoardViewConfig[]>(
          componentName,
          nextMultiGridBoardConfigState
        );
      }),
      tap(() => {
        // update state of inactive boards to contain new view
        this.updateStatesForBoards(nextInactiveBoardStates);
      })
    );
  }

  /**
   * Given an array of XpoAgGridBoardViewConfigs, boardType, and componentName, deletes the view from all registered
   * board sources and updates user preferences with the new array of configs
   *
   * @param boardConfigs: array of XpoAgGridBoardViewConfigs views from the active board
   * @param boardType: active board
   * @param componentName: component name to update preferences for
   */
  private handleDeleteView$(
    boardConfigs: XpoAgGridBoardViewConfig[],
    boardType: string,
    componentName: string
  ): Observable<PndMultiGridBoardViewConfig[]> {
    // store the next states for any inactive grids so we can update the view states after a successful save
    let nextInactiveBoardStates: { state: XpoAgGridBoardState; boardType: string }[];

    return this.getLatestRegisteredStateValues$().pipe(
      map((stateValues: RegisteredStateValue[]) => {
        // find view that was deleted from active board so we can delete the same viewId from other boards
        const currentBoardTypeViewConfigs: XpoAgGridBoardViewConfig[] = this.multiGridBoardViewConfigs
          .filter(
            (config: PndMultiGridBoardViewConfig) => config.boardType === boardType && !config.config.systemDefined
          )
          .map((c: PndMultiGridBoardViewConfig) => c.config);

        const viewIdToRemove: string = _differenceBy(currentBoardTypeViewConfigs, boardConfigs, (c) => c.id)?.[0]?.id;

        // remove the views from the existing view configs so we don't save them to user preferences
        const nextViewConfigState: PndMultiGridBoardViewConfig[] = this.multiGridBoardViewConfigs.filter(
          (config) => config.config.id !== viewIdToRemove
        );

        // remove the views from the inactive board states so view states are in sync
        nextInactiveBoardStates = stateValues
          .filter((stateValue) => stateValue.boardType !== boardType)
          .map((stateValue: RegisteredStateValue) => {
            // if deleting view, need to remove corresponding view from other board sources
            if (boardType !== stateValue.boardType) {
              const nextState: XpoAgGridBoardState = XpoBoardViewUtil.deleteView(
                viewIdToRemove,
                stateValue.state,
                BoardStatesEnum.AVAILABLE_VIEWS_DELETE_VIEW
              );
              return { state: nextState, boardType: stateValue.boardType };
            }
          });

        return nextViewConfigState;
      }),
      switchMap((nextMultiGridBoardConfigState: PndMultiGridBoardViewConfig[]) => {
        // update preferences with new list of multgrid board view configs
        return this.userPreferencesService.updatePreferencesFor<PndMultiGridBoardViewConfig[]>(
          componentName,
          nextMultiGridBoardConfigState
        );
      }),
      tap(() => {
        // update state of inactive boards to contain new view
        this.updateStatesForBoards(nextInactiveBoardStates);
      })
    );
  }

  /**
   * Given an array of RegisteredStateValues, updates stateChangeObserver$ values with state
   *
   * @param nextStates: array of RegisteredStateValues to update
   */

  private updateStatesForBoards(nextStates: RegisteredStateValue[]): void {
    nextStates?.forEach((nextStateValue: { state: XpoAgGridBoardState; boardType: string }) => {
      const stateChangeObserver$: ReplaySubject<XpoAgGridBoardState> = this.registeredBoardSources.get(
        nextStateValue.boardType
      ).stateChangeObserver$;
      stateChangeObserver$.next(nextStateValue.state);
    });
  }

  /**
   * Given an array of XpoAgGridBoardViewConfigs, returns most recent saved config based on lastSaved property
   *
   * @param boardConfigs: array of XpoAgGridBoardViewConfigs to search and return config
   */
  private getLatestSavedViewFromBoardViewConfigs(boardConfigs: XpoAgGridBoardViewConfig[]): XpoAgGridBoardViewConfig {
    return boardConfigs?.reduce((latestConfig: XpoGridBoardViewConfig, currentConfig: XpoGridBoardViewConfig) => {
      if (
        (!latestConfig?.lastSaved && currentConfig.lastSaved) ||
        (latestConfig?.lastSaved &&
          currentConfig.lastSaved &&
          moment(currentConfig.lastSaved).isAfter(latestConfig.lastSaved))
      ) {
        return currentConfig;
      } else {
        return latestConfig;
      }
    }, undefined);
  }

  /**
   * Compiles the latest XpoBoardState from all registered Board Source values
   *
   */
  private getLatestRegisteredStateValues$(): Observable<RegisteredStateValue[]> {
    const currentStateValues$: Observable<RegisteredStateValue>[] = Array.from(this.registeredBoardSources).map(
      ([sourceBoardType, registeredSource]: [string, RegisteredBoardSource]) => {
        return registeredSource.boardStateObserver$.pipe(
          take(1),
          map((state: XpoAgGridBoardState) => ({ state, boardType: sourceBoardType }))
        );
      }
    );

    return forkJoin(currentStateValues$);
  }

  /**
   * Given a PndMultiGridBoardViewConfig or XpoAgGridBoardViewConfig, creates a unique identifier
   *
   * @param config: PndMultiGridBoardViewConfig or XpoAgGridBoardViewConfig to create id from
   */
  private getUniqueId(config: PndMultiGridBoardViewConfig | XpoAgGridBoardViewConfig): string {
    let uniqueId: string;

    if ('boardType' in config) {
      uniqueId = `${config.boardType}_${config.config.id}`;
    } else {
      uniqueId = `${config.id}_${config.templateId}_${config.name}`;
    }

    return uniqueId;
  }

  /**
   * Given a board template and partial config, creates a new view from the template
   *
   * @param boardTemplate: XpoAgGridBoardViewTemplate to create view from
   * @param partialConfig: additional config properties to use in the view
   */
  private getViewConfigFromTemplate(
    boardTemplate: XpoAgGridBoardViewTemplate,
    partialConfig: Partial<XpoAgGridBoardViewConfig> = {}
  ): XpoAgGridBoardViewConfig {
    return boardTemplate.createView(partialConfig as XpoAgGridBoardViewConfig).toViewConfig();
  }
}
