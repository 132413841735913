<ng-container *ngIf="showAlert">
  <xpo-icon iconName="flag" class="include-shipment-alert-icon" [xpoPopoverTriggerFor]="hoverPopover"></xpo-icon>
  <xpo-popover #hoverPopover="xpoPopover" triggerOn="hover" position="after">
    <xpo-popover-content class="pnd-OptimizerUpdateShipmentAlertPopover">
      <div class="popover-section">
        <div class="popover-first-title">
          <xpo-icon iconName="flag" class="include-shipment-alert-icon"></xpo-icon>
          <span class="popover-text normal">ALERT AND EXCEPTIONS</span>
        </div>
        <p class="popover-text normal">{{ address }}</p>
        <p class="popover-text big">
          Consignee: <span class="bold">{{ data?.customerName }}</span>
        </p>
      </div>
      <mat-divider></mat-divider>
      <div class="popover-section">
        <div class="popover-second-title">
          <mat-chip xpoTag class="xpo-Chip--red">ALERT</mat-chip>
          <span class="popover-text small">{{ currentSicTimeZone$ | async }}</span>
        </div>
        <p class="popover-text big"><span class="bold">Additional shipments expected later</span></p>
      </div>
      <mat-divider></mat-divider>
    </xpo-popover-content>
  </xpo-popover>
</ng-container>
