import { Injectable, OnDestroy } from '@angular/core';
import { GlobalFilterStoreSelectors, PndStoreState, TripsStoreActions } from '@pnd-store/.';
import { PndStore } from '@pnd-store/pnd-store';
import { ListLocationFeaturesQuery, ListLocationFeaturesResp, LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  DispatchTrip,
  ListSpotOrDropEquipmentPath,
  ListSpotOrDropEquipmentQuery,
  ListSpotOrDropEquipmentResp,
  SpotOrDropEquipment,
  TripDetail,
} from '@xpo-ltl/sdk-cityoperations';
import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import { CompleteTripService } from 'app/inbound-planning/components/trips/complete-trip/services/complete-trip.service';
import { ModifyTripDetailsService } from 'app/inbound-planning/components/trips/modify-trip-details/services/modify-trip-details.service';
import { EMPTY, merge, of } from 'rxjs';
import { catchError, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PndZoneUtils } from 'shared/zone-utils';
import { LayoutManagerService } from '../../../../../shared/layout-manager/services/layout-manager.service';
import { ModifyTripDetailsActionConfig } from '../../../components/trips/modify-trip-details/models/modify-trip-details-action-config.model';
import { ModifyTripDetailsConfig } from '../../../components/trips/modify-trip-details/models/modify-trip-details-config.model';
import { ModifyTripDetailsComponent } from '../../../components/trips/modify-trip-details/modify-trip-details.component';
import { SplitPanelUnit } from '../../components/split-panel/split-panel.component';
import { SplitPanelService } from '../split-panel-service/split-panel.service';
@Injectable({
  providedIn: 'root',
})
export class ModifyTripDetailsSplitPanelService implements OnDestroy {
  private unsubscriber = new Unsubscriber();

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private splitPanelService: SplitPanelService,
    private layoutManagerService: LayoutManagerService,
    private modifyTripDetailsService: ModifyTripDetailsService,
    private completeTripService: CompleteTripService,
    private locationService: LocationApiService,
    private cityOperationsApi: CityOperationsApiService
  ) {
    merge(
      this.pndStore$
        .select(GlobalFilterStoreSelectors.globalFilterSic)
        .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done$)),
      this.layoutManagerService.layoutActivated$.pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done$))
    ).subscribe(() => {
      // Close the panel if the SIC changes or the layout is changed.
      this.closePanel();
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  /**
   * Return true if the panel is open, else false
   */
  isPanelOpen(): boolean {
    const isOpen =
      this.splitPanelService.rightPanelComponentConfig &&
      this.splitPanelService.rightPanelComponentConfig.componentRef === ModifyTripDetailsComponent;
    return isOpen;
  }

  /**
   * Close the panel
   */
  closePanel() {
    this.layoutManagerService.deMaximizeAll();
    this.modifyTripDetailsService.clearStagedTripsMap();
    this.modifyTripDetailsService.setTrip(undefined);
    this.splitPanelService.closeRightPanel();
  }

  clearSelection() {
    this.pndStore$.dispatch(
      new TripsStoreActions.SetSelectedStopsForSelectedRoutes({
        selectedStopsForSelectedRoutes: [],
      })
    );
  }

  /**
   * Open the panel if closed, and display the passed Trip's detailss
   */
  openModifyTripDetails(
    trip: TripDetail | DispatchTrip,
    hardWidth?: number,
    callbackFn?: Function,
    shouldReload = true
  ) {
    this.modifyTripDetailsService.setTrip(trip, shouldReload);

    // open the Trip panel if it isn't already open
    if (!this.isPanelOpen()) {
      // Generating a new function if a custom callback function is passed
      let callback;
      if (callbackFn) {
        callback = () => {
          this.closePanel();
          callbackFn();
        };
      }

      const config: ModifyTripDetailsConfig = this.getBasicConfig(callback || this.closePanel.bind(this));

      this.setPLTFlags();

      this.setSpotsAndDropsTrailers();

      this.splitPanelService.openRightPanel({
        componentRef: ModifyTripDetailsComponent,
        injectionData: undefined,
        hideDefaultPanelHeader: true,
        lockSize: false,
        unit: SplitPanelUnit.Pixel,
        size: this.isPanelOpen() ? this.splitPanelService.rightPanelComponentConfig.size : hardWidth || 1250,
        inputs: { headerActions: config.headerActions, shouldReload },
        maxSize: 2000,
        minSize: 1250,
        useTransition: true,
        onClose: () => {
          if (callbackFn) {
            callbackFn();
          }

          this.splitPanelService.closeRightPanel();
        },
        style: {
          paddingPx: 15,
        },
      });
    }
  }

  private setSpotsAndDropsTrailers(): void {
    const sicCd = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    const sicZonesAndSatellites = this.pndStore$.selectSnapshot(
      GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites
    );
    const zoneIndicatorCd: ZoneIndicatorCd = PndZoneUtils.getZoneIndicatorCd(sicZonesAndSatellites);
    const path: ListSpotOrDropEquipmentPath = {
      sicCd: sicCd,
    };

    const query: ListSpotOrDropEquipmentQuery = {
      zoneIndicatorCd: zoneIndicatorCd,
    };

    this.cityOperationsApi
      .listSpotOrDropEquipment(path, query)
      .pipe(
        catchError((error) => {
          return of(undefined);
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((response: ListSpotOrDropEquipmentResp) => {
        const searchResults: SpotOrDropEquipment[] = [];
        if (response) {
          searchResults.push(...response?.spotOrDropEquipment);
          this.modifyTripDetailsService.setSpotOrDropEquipment(searchResults);
        }
      });
  }

  /**
   * Builds a basic config with a close button.
   *
   * @param cancelOrCloseFn
   */
  private getBasicConfig(cancelOrCloseFn: () => void): ModifyTripDetailsConfig {
    const headerActions: ModifyTripDetailsActionConfig[] = [
      {
        nameAction: 'close',
        tooltip: 'Close',
        dataTestAttr: 'modifyTripDetailsActionClose',
        iconName: 'close',
        callbackFn: cancelOrCloseFn,
      },
    ];
    const config: ModifyTripDetailsConfig = {
      headerActions,
      cancelCallbackFn: cancelOrCloseFn,
    };
    return config;
  }

  private setPLTFlags(): void {
    const sicCd = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    const request = new ListLocationFeaturesQuery();
    request.featureCd = 'PLT_HU_EXEMPT';

    request.sicCd = sicCd;
    this.locationService
      .listLocationFeatures(request)
      .pipe(
        takeUntil(this.unsubscriber.done$),
        catchError((err) => {
          this.completeTripService.setPltHuExemption(0);
          return EMPTY;
        })
      )
      .subscribe((resp: ListLocationFeaturesResp) => {
        this.completeTripService.setPltHuExemption(+(resp?.locationFeatures[0]?.settingValue || 0));
      });

    const requestObj = new ListLocationFeaturesQuery();
    requestObj.featureCd = 'PU_HU_OPTOUT';
    requestObj.sicCd = sicCd;
    this.locationService
      .listLocationFeatures(requestObj)
      .pipe(
        takeUntil(this.unsubscriber.done$),
        catchError((err) => {
          this.completeTripService.setPltOptOut(false);
          return EMPTY;
        })
      )
      .subscribe((resp: ListLocationFeaturesResp) => {
        this.completeTripService.setPltOptOut(resp?.locationFeatures[0]?.settingValue === 'Y');
      });
  }
}
