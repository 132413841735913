<div class="processing-spinner" *ngIf="showSpinner">
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</div>
<div class="carriers-selector-dialog" [ngClass]="{ 'processing-overlay': showSpinner }">
  <h1 mat-dialog-title>
    Select Carrier
    <xpo-dialog-close-button (click)="cancel()"></xpo-dialog-close-button>
  </h1>

  <div mat-dialog-content>
    <label>
      <h3>Search Carriers</h3>
    </label>
    <mat-form-field [floatLabel]="'never'">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" #filterInput autocomplete="off" />
    </mat-form-field>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="scacCd">
          <th mat-header-cell *matHeaderCellDef>SCAC</th>
          <td mat-cell *matCellDef="let element">{{ element.scacCd }}</td>
        </ng-container>

        <ng-container matColumnDef="carrierName">
          <th mat-header-cell *matHeaderCellDef>Carrier</th>
          <td mat-cell *matCellDef="let element">{{ element.carrierName }}</td>
        </ng-container>

        <ng-container matColumnDef="selected">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.carrierId === selectedCarrierId">done</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.mat-row__selected]="row.carrierId === selectedCarrierId"
            (click)="selectCarrier(row.carrierId)"></tr>
      </table>
    </div>
  </div>

  <div mat-dialog-actions>
    <div>
      <button mat-flat-button (click)="convert()" [disabled]="!selectedCarrierId || !isCarrierSelected">
        CREATE
      </button>
      <button mat-stroked-button (click)="cancel()">CANCEL</button>
    </div>
  </div>
</div>
