import { PipeTransform, Pipe } from '@angular/core';
import { InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';

@Pipe({ name: 'interfaceAcctName' })
export class InterfaceAcctNamePipe implements PipeTransform {
  transform(interfaceAcct: InterfaceAcct): string {
    const fullName: string = `${(interfaceAcct?.name1 ?? '').trim()} ${(interfaceAcct?.name2 ?? '').trim()}`;
    return fullName.trim();
  }
}
