import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UnassignedPickupsStoreEffects } from './unassigned-pickups-store.effects';
import { unassignedPickupsReducer } from './unassigned-pickups-store.reducers';
import { StateKey } from './unassigned-pickups-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, unassignedPickupsReducer),
    EffectsModule.forFeature([UnassignedPickupsStoreEffects]),
  ],
})
export class UnassignedPickupsStoreModule {}
