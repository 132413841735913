import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ComponentChangeUtils } from '../../classes/component-change-utils';

export interface CircleAndTextCellRendererValue {
  showCircle: boolean;
  appendText?: string;
}

export interface CircleAndTextCellRendererParams extends ICellRendererParams {
  color: string;
  value: CircleAndTextCellRendererValue;
}
@Component({
  selector: 'circle-and-text-cell-renderer',
  templateUrl: './circle-and-text-cell-renderer.component.html',
  styleUrls: ['./circle-and-text-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-CircleAndTextCellRenderer' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleAndTextCellRendererComponent implements ICellRendererAngularComp {
  color = '';
  value: CircleAndTextCellRendererValue;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  agInit(params: CircleAndTextCellRendererParams): void {
    this.value = {
      showCircle: params.value?.showCircle,
      appendText: params.value?.appendText,
    };

    this.color = params.color || 'black';
  }

  refresh(params: CircleAndTextCellRendererParams): boolean {
    this.agInit(params);
    ComponentChangeUtils.detectChanges(this.changeDetectorRef);
    return true;
  }
}
