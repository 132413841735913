import { GoogleMapsAPIWrapper } from '@agm/core';
import * as mapTypes from '@agm/core/services/google-maps-types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LayoutPreferenceService } from '../../../../../../../shared/layout-manager/services/layout-preference.service';
import { MapToolbarService } from '../../../../services';

@Component({
  selector: 'pnd-selective-zoom',
  templateUrl: './selective-zoom.component.html',
  styleUrls: ['./selective-zoom.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectiveZoomComponent implements OnDestroy, AfterViewInit {
  @Output() toggleDrawMode = new EventEmitter<boolean>();

  private unsubscriber = new Unsubscriber();

  private disabledSubject = new BehaviorSubject<boolean>(false);
  readonly disabled$ = this.disabledSubject.asObservable();

  private googleMap: google.maps.Map;

  private newShape: google.maps.Rectangle;
  private firstPoint: google.maps.LatLng;
  private lastPoint: google.maps.LatLng;
  private bounds: google.maps.LatLngBounds;
  private mouseMoveDrawer: mapTypes.MapsEventListener;
  private mouseUpDrawer: mapTypes.MapsEventListener;
  private mouseDownDrawer: mapTypes.MapsEventListener;
  private drawing: boolean;
  private strokeColor: string = '#304FFE';
  inDrawMode = false;

  constructor(
    private googleMapsApi: GoogleMapsAPIWrapper,
    private mapToolbarService: MapToolbarService,
    private layoutPreferences: LayoutPreferenceService
  ) {}

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.inDrawMode) {
      this.togglePolygonDraw();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    if (this.googleMap) {
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'mousedown');
    }
  }

  ngAfterViewInit(): void {
    this.googleMapsApi.getNativeMap().then((nativeMap) => {
      this.googleMap = (nativeMap as any) as google.maps.Map;
    });

    this.mapToolbarService.setDrawModeState$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((enabled) => {
      if (this.inDrawMode && !enabled) {
        this.togglePolygonDraw();
      }
      this.disabledSubject.next(this.inDrawMode ? false : enabled);
    });

    this.layoutPreferences.activeLayout$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((layout) => {
      this.inDrawMode = false;
      this.disabledSubject.next(false);
    });
  }

  togglePolygonDraw(): void {
    this.inDrawMode = !this.inDrawMode;

    this.mapToolbarService.setDrawModeState(this.inDrawMode);

    this.toggleDrawMode.emit(this.inDrawMode);

    if (this.inDrawMode) {
      this.mouseDownDrawer = google.maps.event.addListener(this.googleMap, 'mousedown', () => {
        if (this.inDrawMode && !this.drawing) {
          this.disableMap();
          this.initPolygonSelection();
        }
      });
    } else {
      google.maps.event.removeListener(this.mouseDownDrawer);
    }
  }

  private initPolygonSelection(): void {
    const polylineOptions: google.maps.RectangleOptions = {
      map: this.googleMap,
      strokeColor: this.strokeColor,
      strokeWeight: 2,
      clickable: false,
      zIndex: 100,
      editable: false,
    };

    this.newShape = new google.maps.Rectangle(polylineOptions);

    this.mouseMoveDrawer = google.maps.event.addListener(this.googleMap, 'mousemove', ($event) => {
      if (!!this.firstPoint) {
        this.lastPoint = $event.latLng;
        this.bounds = new google.maps.LatLngBounds();
        this.bounds.extend(this.firstPoint);
        this.bounds.extend(this.lastPoint);
        this.newShape.setBounds(this.bounds);
      } else {
        this.firstPoint = $event.latLng;
      }
    });

    this.mouseUpDrawer = google.maps.event.addListener(this.googleMap, 'mouseup', () => {
      google.maps.event.removeListener(this.mouseMoveDrawer);
      this.firstPoint = null;
      this.newShape.setMap(null);
      this.googleMap.fitBounds(this.bounds);
      this.onPolygonDrawn();
    });
  }

  private disableMap() {
    this.drawing = true;
    this.googleMap.setOptions({
      draggable: false,
    });
  }

  private enableMap() {
    this.drawing = false;
    this.googleMap.setOptions({
      draggable: true,
    });
  }

  // User select all items contained within the polygon
  private onPolygonDrawn(): void {
    google.maps.event.removeListener(this.mouseUpDrawer);
    if (this.inDrawMode) {
      this.togglePolygonDraw();
    }
    this.enableMap();
  }
}
