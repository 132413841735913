import { Injectable } from '@angular/core';
import { PndStore } from '@pnd-store/pnd-store';
import * as TripsStoreSelectors from '@pnd-store/trips-store/trips-store.selectors';
import { XpoLtlChangeFeedService } from '@xpo-ltl/change-feed';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  CityOperationsApiService,
  RegisterFilterTripsResp,
  RegisterFilterTripsRqst,
  TripDetailChangeRecord,
  TripDetailFilter,
  UnregisterFilterTripsRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtFireMessagingService } from '@xpo/ngx-xrt-firebase';
import { PndXrtService } from 'core/services/pnd-xrt.service';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PndZoneUtils } from '../../../../shared/zone-utils';
import * as GlobalFilterStoreSelectors from '../../../store/global-filters-store/global-filters-store.selectors';
import * as PndStoreState from '../../../store/pnd-store.state';
import { TripsSearchCriteria } from '../../../store/trips-store/trips-search-criteria.interface';
import { AutoRefreshBaseService, PndXrtChangedDocument } from './auto-refresh-base.service';

// TODO - this should be defined in XpoLtlChangeFeedComponent
export const XpoLtlChangeFeedComponent_TRIP = 'trips';

@Injectable({
  providedIn: 'root',
})
export class TripPlanningAutoRefreshService extends AutoRefreshBaseService<
  TripsSearchCriteria,
  TripDetailChangeRecord
> {
  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private cityOperationsApiService: CityOperationsApiService,
    private pndXrtService: PndXrtService,

    // following used for AutoRefreshBaseService
    protected xpoLtlChangeFeedService: XpoLtlChangeFeedService,
    protected configManagerService: ConfigManagerService,
    protected xpoLtlAuthenticationService: XpoLtlAuthenticationService,
    protected xrtFireMessagingService: XrtFireMessagingService
  ) {
    super(xpoLtlChangeFeedService, configManagerService, xpoLtlAuthenticationService, xrtFireMessagingService);
    this.onInit();
  }

  /**
   * Return a valid connection token
   */
  protected getMessagingToken$(): Observable<string> {
    return of(this.xpoLtlChangeFeedService.generateConnectionToken());
  }

  /**
   * Begin listening for document changes
   */
  protected subscribeToAutoRefresh() {
    // update the registered filters when the search criteria changes
    this.pndStore$
      .select(TripsStoreSelectors.searchCriteria)
      .pipe(
        switchMap((criteria: TripsSearchCriteria) => {
          return this.registerFilter(criteria);
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(() => {});

    this.pndStore$
      .select(TripsStoreSelectors.tripsLastUpdate)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(() => {
        this.clearChangedDocuments();
      });
  }

  protected executeRegisterFilter(request: RegisterFilterTripsRqst): Observable<RegisterFilterTripsResp> {
    return this.cityOperationsApiService.registerFilterTrips(request as RegisterFilterTripsRqst);
  }

  protected executeUnregisterFilter(request: UnregisterFilterTripsRqst): Observable<void> {
    return this.cityOperationsApiService.unregisterFilterTrips(request as UnregisterFilterTripsRqst);
  }

  /**v
   * Register filter criteria to receive notifications when matching documents change
   */
  protected registerFilter(criteria: TripsSearchCriteria): Observable<string> {
    const planDate = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterPlanDate);
    const sicZonesAndSatellites = this.pndStore$.selectSnapshot(
      GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites
    );

    if (sicZonesAndSatellites) {
      const currentSelectionSics: string[] = PndZoneUtils.getCurrentSelectionSics(sicZonesAndSatellites, false);

      // NOTE: backend API only supports the tripDate and sicCd zones with satellites.  All other search criteria is ignored!

      const request = new RegisterFilterTripsRqst();
      request.filter = {
        ...new TripDetailFilter(),
        sicCd: this.pndXrtService.toXrtFilterValues(currentSelectionSics),
        tripDate: this.pndXrtService.toXrtFilterEqualsDate(planDate),
      };

      return this.performRegisterRequest(request).pipe(
        tap((filterHash: string) => {
          this.registerForChangeFeed(XpoLtlChangeFeedComponent_TRIP, filterHash);
        })
      );
    } else {
      return of(undefined);
    }
  }

  /**
   * Unregister for updates
   */
  protected unregisterFilter(): Observable<void> {
    // NOTE: the filterHash is set in the performUnregisterRequest.
    const request = new UnregisterFilterTripsRqst();
    return this.performUnregisterRequest(request);
  }

  /**
   * Apply the passed changes to an existing trip
   * @param changeData changes to apply to a trip
   */
  protected updateFromChangeFeed(changeData: TripDetailChangeRecord[]) {
    // NOTE: Even though Trips grid only need the first element from TripDetailChangeRecord[],
    // we are passing as array since Unassigned Pickups needs more than the first element.
    const changedDoc: PndXrtChangedDocument = { documentKey: changeData?.[0]?.tripInstId, record: changeData[0] };
    this.updateChangedDocuments({
      filterHash: this.filterHash,
      documents: [changedDoc],
    });
  }
}
