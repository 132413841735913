import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './geo-location-store.state';

export const geoLocationState = createFeatureSelector<State>(StateKey);

export const stopToEdit = createSelector(geoLocationState, (state: State) => state.stopToEdit);

export const status = createSelector(geoLocationState, (state: State) => state.status);

export const editMode = createSelector(geoLocationState, (state: State) => state.editMode);
