import { DockRoutesGridFields } from '../../components/trips/dock-routes/enums/dock-routes-grid-fields.enum';
import { ModifyTripDetailsSharedGridFields } from '../../components/trips/modify-trip-details/enums/modify-trip-details-shared-grid-fields.enum';
import { ModifyTripDetailsShipmentsGridFields } from '../../components/trips/modify-trip-details/modify-trip-details-shipments/modify-trip-details-shipments-grid-fields.enum';
import { UnassignedDeliveriesGridFields } from '../../components/unassigned-deliveries/enums/unassigned-deliveries-grid-fields.enum';
import { TypeCountTotal } from '../components/type-count-cell-renderer/interfaces/type-count-total.interface';

export interface TripPlanningGrandTotals {
  route: {
    totalBillCount: number;
    totalWeightCount: number;
    totalMmCount: number;
    routeSuffix: number; // put the totals in to the suffix column so we can show it
    totalPalletSpcNbr: number;
  };
  tripStopCount: number;
}

export interface RouteStopsGrandTotals {
  totalBills: number;
  totalMotorMoves: number;
  totalWeight: number;
  totalCube: number;
  totalPalletSpcNbr: number;
}

export interface RoutePlanningGrandTotals {
  totalBillCount: number;
  totalMmCount: number;
  totalWeightCount: number;
  routeName: number;
}

export interface PlanningRouteShipmentsStopsGrandTotals {
  totalShipmentsCount: number;
  totalWeightLbs: number;
  motorizedPiecesCount: number;
  totalCubePercentage: number;
  loosePcsCnt: number;
}

export interface PastDueShipmentsStopsGrandTotals {
  totalShipmentsCount: number;
  totalWeightLbs: number;
  motorizedPiecesCount: number;
  totalCubePercentage: number;
}

export interface PlanningRouteShipmentsShipmentsGrandTotals {
  proNbr: number;
  totalWeightLbs: number;
  motorizedPiecesCount: number;
  totalCubePercentage: number;
}

export interface PastDueShipmentsShipmentsGrandTotals {
  proNbr: number;
  totalWeightLbs: number;
  motorizedPiecesCount: number;
  totalCubePercentage: number;
}

export interface UnassignedDeliveriesStopsGrandTotals {
  totalShipmentsCount: number;
  motorizedPiecesCount: number;
  totalWeightLbs: number;
  loosePcsCnt: number;
  totalCubePercentage: number;
  totalConsignees: number;
}

export interface UnassignedDeliveriesShipmentsGrandTotals {
  totalShipmentsCount: number;
  totalWeightLbs: number;
  motorizedPiecesCount: number;
  loosePcsCnt: number;
  totalCubePercentage: number;
  totalConsignees: number;
}

export interface UnassignedDeliveriesShipmentsGroupTotals {
  [UnassignedDeliveriesGridFields.LP]: number;
  [UnassignedDeliveriesGridFields.MM]: number;
  [UnassignedDeliveriesGridFields.WEIGHT]: number;
  [UnassignedDeliveriesGridFields.CUBE]: number;
  [UnassignedDeliveriesGridFields.CONSIGNEE]: number;
  [UnassignedDeliveriesGridFields.PRO]: number;
}

export interface UnassignedPickupsGrandTotals {
  pickupHeader: {
    loosePiecesCount: number;
    motorMovesNbr: number;
    weightLbs: number;
    cubeNbr: number;
    palletsCount: number;
  };
  rowSelected: TypeCountTotal[];
}

export interface DispatcherTripsGrandTotals {
  remainingDeliveries: number;
  completedDeliveries: number;
  remainingPickups: number;
  completedPickups: number;
  returnCount: number;
  weightRemainingDeliveries: number;
  weightRemainingPickups: number;
  weightCompletedDeliveries: number;
  weightCompletedPickups: number;
  totalRecordCount: string;
  driver: {
    name: string;
  };
  dispatchTrip: {
    stopRemainingCount: number;
    stopCompletedCount: number;
  };
}

export interface ModifyTripDetailsStopsGrandTotals {
  [ModifyTripDetailsSharedGridFields.ROW_SELECTED]: number; // use expand mixed stop to show total stops
  [ModifyTripDetailsSharedGridFields.ACTIVITY_TYPE]: TypeCountTotal[];
  [ModifyTripDetailsSharedGridFields.LOOSE_PIECES]: number;
  [ModifyTripDetailsSharedGridFields.MOTOR_MOVES]: number;
  [ModifyTripDetailsSharedGridFields.WEIGHT]: number;
  [ModifyTripDetailsSharedGridFields.CUBE]: number;
  [ModifyTripDetailsSharedGridFields.BILLS]: number;
}

export interface ModifyTripDetailsShipmentsGrandTotals {
  [ModifyTripDetailsSharedGridFields.ROW_SELECTED]: number; // use row selected to show total stops
  [ModifyTripDetailsSharedGridFields.LOOSE_PIECES]: number;
  [ModifyTripDetailsSharedGridFields.MOTOR_MOVES]: number;
  [ModifyTripDetailsSharedGridFields.WEIGHT]: number;
  [ModifyTripDetailsSharedGridFields.CUBE]: number;
  [ModifyTripDetailsShipmentsGridFields.PRO]: string;
  [ModifyTripDetailsSharedGridFields.ACTIVITY_TYPE]: TypeCountTotal[];
}

export interface ModifyTripDetailsDetailsGrandTotals {
  [ModifyTripDetailsSharedGridFields.PALLETS]: number;
  [ModifyTripDetailsSharedGridFields.LOOSE_PIECES]: number;
  [ModifyTripDetailsSharedGridFields.WEIGHT]: number;
  [ModifyTripDetailsSharedGridFields.MOTOR_MOVES]: number;
  [ModifyTripDetailsSharedGridFields.CUBE]: number;
  [ModifyTripDetailsShipmentsGridFields.PRO]: string;
}

export interface DockRoutesStopsGrandTotals {
  [DockRoutesGridFields.ROW_SELECTED]: number;
  [DockRoutesGridFields.CUSTOMER_NAME]: TypeCountTotal[];
  [DockRoutesGridFields.PIECES_COUNT]: number;
  [DockRoutesGridFields.MOTOR_MOVE_NBR]: number;
  [DockRoutesGridFields.WEIGHT_LBS]: number;
  [DockRoutesGridFields.CUBE_NBR]: number;
  [DockRoutesGridFields.BILL_COUNT]: number;
}

export interface DockRoutesShipmentsGrandTotals {
  [DockRoutesGridFields.ROW_SELECTED]: number;
  [DockRoutesGridFields.CUSTOMER_NAME]: TypeCountTotal[];
  [DockRoutesGridFields.PIECES_COUNT]: number;
  [DockRoutesGridFields.MOTOR_MOVE_NBR]: number;
  [DockRoutesGridFields.WEIGHT_LBS]: number;
  [DockRoutesGridFields.CUBE_NBR]: number;
  [DockRoutesGridFields.PRO]: string;
}

export interface DockRoutesDetailsGrandTotals {
  [DockRoutesGridFields.PALLET_COUNT]: number;
  [DockRoutesGridFields.PIECES_COUNT]: number;
  [DockRoutesGridFields.MOTOR_MOVE_NBR]: number;
  [DockRoutesGridFields.WEIGHT_LBS]: number;
  [DockRoutesGridFields.CUBE_NBR]: number;
  [DockRoutesGridFields.PRO]: string;
}

export type GrandTotals =
  | TripPlanningGrandTotals
  | RouteStopsGrandTotals
  | RoutePlanningGrandTotals
  | PlanningRouteShipmentsStopsGrandTotals
  | PlanningRouteShipmentsShipmentsGrandTotals
  | UnassignedDeliveriesStopsGrandTotals
  | UnassignedDeliveriesShipmentsGrandTotals
  | UnassignedPickupsGrandTotals
  | DispatcherTripsGrandTotals
  | ModifyTripDetailsStopsGrandTotals
  | ModifyTripDetailsShipmentsGrandTotals
  | ModifyTripDetailsDetailsGrandTotals
  | DockRoutesStopsGrandTotals
  | DockRoutesShipmentsGrandTotals
  | DockRoutesDetailsGrandTotals
  | UnassignedDeliveriesShipmentsGroupTotals;
