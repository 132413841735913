import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { ICellRendererParams } from 'ag-grid-community';
import { SpecialServiceMark } from '../service-icons/model/special-service-mark';

export interface ISpecialServicesCellRendererParams extends ICellRendererParams {
  getMarks: (params) => SpecialServiceMark[];
}

@Component({
  selector: 'app-special-services-cell-renderer',
  templateUrl: './special-services-cell-renderer.component.html',
  styleUrls: ['./special-services-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialServicesCellRendererComponent implements ICellRendererAngularComp {
  specialServices: ShipmentSpecialServiceCd[];
  specialServiceMarks: SpecialServiceMark[];

  constructor() {}

  agInit(params: ISpecialServicesCellRendererParams): void {
    const value = params?.value;

    if (value) {
      this.specialServices = value;
    }

    if (params?.getMarks) {
      this.specialServiceMarks = params.getMarks(params);
      this.sanitizeServices();
    }
  }

  sanitizeServices() {
    this.specialServiceMarks?.forEach((mark) => {
      if (!this.specialServices.some((specialService) => specialService === mark.specialService)) {
        this.specialServices.push(mark.specialService);
      }
    });
  }
  refresh(): boolean {
    return false;
  }
}
