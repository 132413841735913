import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DriverStoreEffects } from './driver-store.effects';
import { driversReducer } from './driver-store.reducers';
import { StateKey } from './driver-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, driversReducer),
    EffectsModule.forFeature([DriverStoreEffects]),
  ],
})
export class DriverStoreModule {}
