import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DispatcherTripsStoreEffects } from './dispatcher-trips-store.effects';
import { dispatcherTripsReducer } from './dispatcher-trips-store.reducers';
import { StateKey } from './dispatcher-trips-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, dispatcherTripsReducer),
    EffectsModule.forFeature([DispatcherTripsStoreEffects]),
  ],
})
export class DispatcherTripsStoreModule {}
