import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PndStore } from '@pnd-store/pnd-store';
import { ZoneSatelliteInfo } from '@xpo-ltl-2.0/sdk-location';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { isEmpty as _isEmpty, uniq as _uniq } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GlobalFilterStoreActions, GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { SicZoneSelectionType } from '../../enums/sic-zone-selection-type';
import { SicZonesAndSatellites } from '../../models/sic-zones-and-satellites.model';
import { SicZonesAndSatellitesService } from '../../services/sic-zones-and-satellites.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
  selector: 'pnd-sic-zones-selector',
  templateUrl: './sic-zones-selector.component.html',
  styleUrls: ['./sic-zones-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SicZonesSelectorComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean = false;

  private loadingSubject = new BehaviorSubject<boolean>(true);
  private unsubscriber = new Unsubscriber();
  private readonly lastSicZoneSelectionUserPreferencesKey = 'pnd-lastSicZoneSelection';
  readonly loading$ = this.loadingSubject.asObservable();
  readonly placeholder: string = '';
  options = [SicZoneSelectionType.HOST_ONLY, SicZoneSelectionType.ZONES_ONLY, SicZoneSelectionType.HOST_AND_ZONES];

  selectedOption: SicZoneSelectionType;
  optionLabels: { [selectionType in SicZoneSelectionType]: string } = {
    [SicZoneSelectionType.HOST_ONLY]: 'SIC only',
    [SicZoneSelectionType.ZONES_ONLY]: 'Zones only',
    [SicZoneSelectionType.HOST_AND_ZONES]: 'SIC + Zones',
  };
  includedSics: { [selectionType in SicZoneSelectionType]: string[] } = {
    [SicZoneSelectionType.HOST_ONLY]: [],
    [SicZoneSelectionType.ZONES_ONLY]: [],
    [SicZoneSelectionType.HOST_AND_ZONES]: [],
  };
  isSatelliteSic: { [sic: string]: boolean } = {};
  sicSatellitesCache: { [sicCd: string]: string[] } = {};

  private host: string;
  private hostSatellites: string[] = [];
  private zones: string[] = [];
  private zoneSatellites: string[] = [];

  constructor(
    private pndStore$: PndStore<PndStoreState.State>,
    private sicZonesAndSatellitesService: SicZonesAndSatellitesService,
    private userPreferencesService: UserPreferencesService
  ) {}

  ngOnInit() {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(
        takeUntil(this.unsubscriber.done$),
        filter((sic) => !!sic),
        distinctUntilChanged(),
        tap(() => {
          this.selectedOption = undefined;
          this.loadingSubject.next(true);
          this.pndStore$.dispatch(
            new GlobalFilterStoreActions.SetSicZonesAndSatellites({
              sicZonesAndSatellites: undefined,
            })
          );
        }),
        switchMap((sic: string) => {
          this.host = sic;
          return forkJoin([
            this.sicZonesAndSatellitesService.getZoneSatelliteInfo(sic, true, true).pipe(catchError(() => of([]))),
            this.getLastSelectionFromUserPreferences(),
          ]);
        })
      )
      .subscribe(([zoneSatelliteInfo, lastSelectedOption]: [ZoneSatelliteInfo[], SicZoneSelectionType]) => {
        this.populateIncludedSics(this.host, zoneSatelliteInfo);
        if (lastSelectedOption && this.options.includes(lastSelectedOption)) {
          this.selectedOption = lastSelectedOption;
        } else {
          this.selectedOption = SicZoneSelectionType.HOST_ONLY;
        }

        // check if there are no zones
        if (_isEmpty(this.includedSics[SicZoneSelectionType.ZONES_ONLY])) {
          this.includedSics[SicZoneSelectionType.HOST_AND_ZONES] = []; // disable also this option
          this.selectedOption = SicZoneSelectionType.HOST_ONLY;
        }

        this.handleSelectionChange();
        this.loadingSubject.next(false);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  handleSelectionChange() {
    this.saveLastSelectionToUserPreferences();

    this.pndStore$.dispatch(
      new GlobalFilterStoreActions.SetSicZonesAndSatellites({
        sicZonesAndSatellites: <SicZonesAndSatellites>{
          currentSelection: this.selectedOption,
          host: this.host,
          hostSatellites: [...this.hostSatellites],
          zones: [...this.zones],
          zoneSatellites: [...this.zoneSatellites],
        },
      })
    );
  }
  trackOptionsBy(index, option): number {
    return index;
  }
  trackSicBy(index, sic) {
    return index;
  }
  /**
   * Save the last SicZoneSelectionType for the selected host sic to userPreferencesService API.
   */
  private saveLastSelectionToUserPreferences() {
    this.userPreferencesService
      .updatePreferencesFor<SicZoneSelectionType>(
        `${this.lastSicZoneSelectionUserPreferencesKey}-${this.host}`,
        this.selectedOption
      )
      .subscribe();
  }

  /**
   * Retrieve the last SicZoneSelectionType for the selected host sic from userPreferencesService,
   * or undefined if it's not present.
   */
  private getLastSelectionFromUserPreferences(): Observable<SicZoneSelectionType> {
    return this.userPreferencesService
      .getPreferencesFor<SicZoneSelectionType>(`${this.lastSicZoneSelectionUserPreferencesKey}-${this.host}`)
      .pipe(catchError(() => of(undefined)));
  }

  /**
   * Populate includedSics array for each type, filtering zoneSatelliteInfo accordingly.
   * Keep the host always in front of its satellites (so we can show the hosts in bold, and identify its satellites)
   *
   * @param hostSic
   * @param zoneSatelliteInfo
   */
  private populateIncludedSics(hostSic: string, zoneSatelliteInfo: ZoneSatelliteInfo[]) {
    this.host = hostSic;
    this.zoneSatellites = [];
    this.zones = [];
    this.hostSatellites = [];

    this.isSatelliteSic = {};
    this.sicSatellitesCache = {};

    zoneSatelliteInfo.forEach((zone) => {
      if (zone.zoneTerminal) {
        if (zone.satelliteParent) {
          this.zoneSatellites.push(zone.locationSic);
          this.isSatelliteSic[zone.locationSic] = true;
        } else {
          this.zones.push(zone.locationSic);
        }
      } else {
        this.hostSatellites.push(zone.locationSic);
        this.isSatelliteSic[zone.locationSic] = true;
      }

      // populate sicSatellitesCache
      if (zone.satelliteParent) {
        const prevValue = this.sicSatellitesCache[zone.satelliteParent] || [];
        this.sicSatellitesCache[zone.satelliteParent] = _uniq([...prevValue, zone.locationSic]);
      }
    });

    const zonesAndZoneSatellitesInOrder = () => {
      const zonesAndSatellites: string[] = [];
      this.zones.forEach((zone) => {
        zonesAndSatellites.push(zone);
        if (this.sicSatellitesCache[zone]) {
          zonesAndSatellites.push(...this.sicSatellitesCache[zone]);
        }
      });
      return zonesAndSatellites;
    };

    // host selected sic along with the satellites of the host, as well as the zones and zones' satellites, if any
    this.includedSics[SicZoneSelectionType.HOST_AND_ZONES] = [
      this.host,
      ...this.hostSatellites,
      ...zonesAndZoneSatellitesInOrder(),
    ];

    // host selected sic along with its satellites
    this.includedSics[SicZoneSelectionType.HOST_ONLY] = [this.host, ...this.hostSatellites];

    // zone sics for the host selected as well as the zones' satellites
    this.includedSics[SicZoneSelectionType.ZONES_ONLY] = [...zonesAndZoneSatellitesInOrder()];
  }
}
