/**
 * Common Maps for I18nPluralPipe
 */
export interface PluralKeyValue {
  [k: string]: string;
}

export namespace PluralMaps {
  export const Customers: PluralKeyValue = {
    '=1': 'Customer',
    other: 'Customers',
  };

  export const Pickups: PluralKeyValue = {
    '=1': 'Pickup',
    other: 'Pickups',
  };

  export const items: PluralKeyValue = {
    '=1': 'item',
    other: 'items',
  };

  export const Stops: PluralKeyValue = {
    '=1': 'Stop',
    other: 'Stops',
  };

  export const Needs: PluralKeyValue = {
    '=1': 'needs',
    other: 'need',
  };

  export const shipments: PluralKeyValue = {
    '=1': 'shipment',
    other: 'shipments',
  };

  export const Routes: PluralKeyValue = {
    '=1': 'Route',
    other: 'Routes',
  };

  export const merges: PluralKeyValue = {
    '=1': 'merge',
    other: 'merges',
  };

  export const trips: PluralKeyValue = {
    '=1': 'Trip',
    other: 'Trips',
  };

  export const deliveries: PluralKeyValue = {
    '=1': 'Delivery',
    other: 'Deliveries',
  };

  export const locations: PluralKeyValue = {
    '=1': 'Location',
    other: 'Locations',
  };

  export const Errors: PluralKeyValue = {
    '=1': 'Error',
    other: 'Errors',
  };
}
