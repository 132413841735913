import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PndStore } from '@pnd-store/pnd-store';
import { SpotsAndDropsService } from 'app/inbound-planning/shared/services/spots-and-drops-services/spots-and-drops.service';
import { Observable } from 'rxjs';
import { catchError, concatMap, concatMapTo, switchMap, take } from 'rxjs/operators';
import * as PndStoreState from '../pnd-store.state';
import { ActionTypes, Refresh, SetLastUpdate, SetSearchCriteria } from './spots-and-drops-store.actions';
import { searchCriteria } from './spots-and-drops-store.selectors';

@Injectable()
export class SpotsAndDropsStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: PndStore<PndStoreState.State>,
    private spotsAndDropsService: SpotsAndDropsService
  ) {}

  /**
   * Trigger a Refresh when the Search Criteria is changed
   */
  @Effect()
  setSearchCriteria$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria), concatMapTo([new Refresh()]))
  );

  /**
   * Refresh the Spots and drop data, remove non-existing trips from selection, and update lastUpdated time
   */

  refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<Refresh>(ActionTypes.refresh),
      concatMap(() => this.store$.select(searchCriteria).pipe(take(1))),
      switchMap((criteria) => this.spotsAndDropsService.fetchSpotsAndDrops$(criteria)),
      concatMap(() => {
        return [new SetLastUpdate({ spotsAndDropsLastUpdate: new Date() })];
      }),
      catchError(() => {
        return [new SetLastUpdate({ spotsAndDropsLastUpdate: new Date() })];
      })
    )
  );
}
