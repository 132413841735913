<div
    *ngIf="isVisible()"
    (click)="onClickFunction($event)"
    (mouseover)="mouseOverFunction($event)"
    (mouseout)="mouseOverFunction($event)">
  <ng-container *ngIf="params.useIcon then useIcon else useValue"></ng-container>
</div>

<ng-template #useValue>
  {{ params.formatValue(params.getValue()) }}
</ng-template>

<ng-template #useIcon>
  <mat-icon>{{ params.useIcon }}</mat-icon>
</ng-template>
