import { ListLocationsResp, LocationSic } from '@xpo-ltl-2.0/sdk-location';
import { XpoMultiSelectAutocompleteFilter } from '@xpo-ltl/ngx-board/core';
import { reduce as _reduce, startsWith as _startsWith, toUpper as _toUpper, size as _size } from 'lodash';
import { delay, map } from 'rxjs/operators';
import { ListLocationsCacheService } from '../services/list-locations.service';
import { SicFilterService } from '../services/sic-filter.service';

export class CurrentSicFilter extends XpoMultiSelectAutocompleteFilter {
  constructor(field: string, name: string = 'SIC', listLocationsCacheService: ListLocationsCacheService) {
    super({
      field: field,
      label: name,
      optionsResolver$: (partialName: string = '') =>
        listLocationsCacheService.request({ meetAndTurnInd: true }).pipe(
          // we need a small delay for the selected items to populate in the filter
          delay(100),
          map((locationCache: ListLocationsResp) => {
            return _reduce(
              locationCache.locationSic,
              (filteredLocationSics, locationSic: LocationSic) => {
                if (_startsWith(locationSic.sicCd, _toUpper(partialName))) {
                  filteredLocationSics.push({ code: locationSic.sicCd, title: locationSic.sicCd });
                }

                return filteredLocationSics;
              },
              []
            );
          })
        ),
    });
  }

  getDisplayValue(criteriaValue: string[]): string {
    // this is pretty hacky but when setting programatically, there is a chance that the criteria is set before
    // the selected options, so we need to force set the selected options on the filter

    if (!!_size(criteriaValue)) {
      this.setSelectedOptions(
        criteriaValue.map((sicCd) => {
          return { value: sicCd, label: sicCd };
        })
      );
    }

    return super.getDisplayValue(criteriaValue);
  }
}

/**
 * Filter destination sics
 */
export class DestinationSicFilter extends XpoMultiSelectAutocompleteFilter {
  constructor(field: string, name: string = 'Destination SIC', sicFilterService: SicFilterService) {
    super({
      field: field,
      label: name,
      // we need to add a small delay here to set the selected items
      optionsResolver$: (partialName: string) => sicFilterService.fetchSatelliteSics(partialName).pipe(delay(100)),
    });
  }

  getDisplayValue(criteriaValue: string[]): string {
    // this is pretty hacky but when setting programatically, there is a chance that the criteria is set before
    // the selected options, so we need to force set the selected options on the filter
    if (!_size(this.selectedOptions) && !!_size(criteriaValue)) {
      this.setSelectedOptions(
        criteriaValue.map((sicCd) => {
          return { value: sicCd, label: sicCd };
        })
      );
    }

    return super.getDisplayValue(criteriaValue);
  }
}
