export enum AssignShipmentsSelectionSource {
  UnmappedDeliveries = 'UnmappedDeliveries',
  UnassignedDeliveries = 'UnassignedDeliveries',
  PlanningRouteShipments = 'PlanningRouteShipments',
  PastDueShipments = 'PastDueShipments',
  RouteStops = 'RouteStops',
  TripDetails = 'TripDetails',
  UnassignedPickups = 'UnassignedPickups',
  DockRoutes = 'DockRoutes',
}
