import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './routes-store.state';

export const routesState = createFeatureSelector<State>(StateKey);

// Misc
export const updateUnassignedDeliveriesGrid = createSelector(
  routesState,
  (state: State) => state.updateUnassignedDeliveriesGrid
);
export const routeMarkers = createSelector(routesState, (state: State) => state.routeMarkers);

// Planning Routes
export const searchCriteria = createSelector(routesState, (state: State) => state.searchCriteria);
export const planningRoutesLastUpdate = createSelector(routesState, (state: State) => state.planningRoutesLastUpdate);
export const loadingPlanningRoutes = createSelector(routesState, (state: State) => state.loadingPlanningRoutes);

export const focusedPlanningRoute = createSelector(routesState, (state: State) => state.focusedPlanningRoute);
export const planningRouteShipmentFocused = createSelector(
  routesState,
  (state: State) => state.focusedPlanningRouteShipment
);

export const selectedPlanningRoutes = createSelector(routesState, (state: State) => state.selectedPlanningRoutes);

export const stopsForSelectedPlanningRoutesLastUpdate = createSelector(
  routesState,
  (state: State) => state.stopsForSelectedPlanningRoutesLastUpdate
);

export const selectedPlanningRoutesShipments = createSelector(
  routesState,
  (state: State) => state.selectedPlanningRoutesShipments
);

export const filteredPlanningRoutesIds = createSelector(routesState, (state: State) => state.filteredPlanningRouteIds);
export const stopsForSelectedPlanningRoutes = createSelector(
  routesState,
  (state: State) => state.stopsForSelectedPlanningRoutes
);
