import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TripsStoreEffects } from './trips-store.effects';
import { tripsReducer } from './trips-store.reducers';
import { StateKey } from './trips-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, tripsReducer),
    EffectsModule.forFeature([TripsStoreEffects]),
  ],
})
export class TripsStoreModule {}
