import { Injectable } from '@angular/core';
import { DsrLocation, GetPnDTripDsrLocationsResp } from '@xpo-ltl/sdk-cityoperations';
import { chunk as _chunk, flatten as _flatten, forEach as _forEach, map as _map, size as _size } from 'lodash';
import { Observable, of, zip, Subscriber } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PndAppUtils } from '../../../../../../../shared/app-utils';
import { TripRenderInfo } from '../interfaces/trip-render-info';
import { GoogleRenderingService } from './google-rendering.service';

@Injectable({
  providedIn: 'root',
})
export class TripBreadcrumbsService {
  constructor(private googleRenderingService: GoogleRenderingService) {}

  /**
   * Given a list of breadcrumbs it will try to snap every location to the closest route.
   * @param breadcrumbs
   * @private
   */
  private smoothBreadcrumbs(breadcrumbs: google.maps.LatLng[]): Observable<google.maps.LatLng[]> {
    return new Observable((observer: Subscriber<google.maps.LatLng[]>) => {
      if (_size(breadcrumbs) > 1) {
        // Snap to roads API takes a max of 100 coordinates
        const breadcrumbsChunksToSmooth: google.maps.LatLng[][] = _chunk(breadcrumbs, 100);
        const smoothedBreadcrumbChunkObservers: Observable<{
          chunkOrder: number;
          smoothedBreadcrumbs: google.maps.LatLng[];
        }>[] = [];

        _forEach(breadcrumbsChunksToSmooth, (breadcrumbsChunk: google.maps.LatLng[], chunkOrder: number) => {
          smoothedBreadcrumbChunkObservers.push(
            this.googleRenderingService.smoothPath(breadcrumbsChunk).pipe(
              PndAppUtils.retry(10, 1000, 500),
              catchError(() => {
                return of(breadcrumbsChunk);
              }),
              map((smoothedBreadcrumbs) => {
                return {
                  chunkOrder,
                  smoothedBreadcrumbs,
                };
              })
            )
          );
        });

        zip(...smoothedBreadcrumbChunkObservers).subscribe((smoothedBreadcrumbChunks) => {
          smoothedBreadcrumbChunks.sort((a, b) => a.chunkOrder - b.chunkOrder);

          observer.next(_flatten(smoothedBreadcrumbChunks.map((chunk) => chunk.smoothedBreadcrumbs)));
          observer.complete();
        });
      } else {
        observer.next(breadcrumbs);
        observer.complete();
      }
    });
  }

  /**
   * It extracts locations from a raw list of breadcrumbs and tries to remove noise from the GPS.
   * @param response
   * @param tripRenderInfo
   */
  processBreadcrumbs(response: GetPnDTripDsrLocationsResp, tripRenderInfo: TripRenderInfo): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const processedDsrLocations: DsrLocation[] =
        response?.dsrLocations?.sort(
          (a: DsrLocation, b: DsrLocation) =>
            new Date(a.eventDateTimeUtc).getTime() - new Date(b.eventDateTimeUtc).getTime()
        ) || [];

      const rawBreadcrumbs: google.maps.LatLng[] = _map(
        processedDsrLocations,
        (location: DsrLocation) => new google.maps.LatLng(location.latitudeNbr, location.longitudeNbr)
      );

      this.smoothBreadcrumbs(rawBreadcrumbs).subscribe((smoothedBreadcrumbs: google.maps.LatLng[]) => {
        tripRenderInfo.driverLocationBreadcrumbs = smoothedBreadcrumbs;

        observer.next(_size(tripRenderInfo.driverLocationBreadcrumbs) > 0);
        observer.complete();
      });
    });
  }
}
