import { Action } from '@ngrx/store';
import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { RouteStopsGridItem } from '../../inbound-planning/components/route-stops';
import { TripPlanningGridItem } from '../../inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import {
  AssignedStopIdentifier,
  EventItem,
  RouteItemIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { NumberToValueMap } from '../number-to-value-map';
import { TripsSearchCriteria } from './trips-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'trips.setSearchCriteria',
  setLastUpdate = 'trips.setLastUpdate',
  setSelected = 'trips.setSelected',
  refresh = 'trips.refresh',

  setGridScrollPosition = 'trips.setGridScrollPosition',

  setSelectedRoutes = 'trips.setSelectedRoutes',
  setClickedRouteStop = 'trips.setClickedRouteStop',

  setFocusedStopForSelectedRoute = 'trips.setFocusedStopForSelectedRoute',
  setFocusedRoute = 'trips.setFocusedRoute',

  setStopsForSelectedRoutes = 'trips.setStopsForSelectedRoutes',
  setSelectedStopsForSelectedRoutes = 'trips.setSelectedStopsForSelectedRoutes',
  updateStopsForSelectedRoute = 'trips.updateStopsForSelectedRoute',

  setShowCompletedStops = 'trips.setShowCompletedStops',
}

export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: TripsSearchCriteria }) {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { lastUpdate: Date }) {}
}

export class SetSelected implements Action {
  readonly type = ActionTypes.setSelected;
  constructor(public payload: { selectedTrips: TripPlanningGridItem[] }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export class SetGridScrollPosition implements Action {
  readonly type = ActionTypes.setGridScrollPosition;
  constructor(public payload: { position: number }) {}
}

export class SetSelectedRoutes implements Action {
  readonly type = ActionTypes.setSelectedRoutes;
  constructor(public payload: { selectedRoutes: RouteItemIdentifier[] }) {}
}

export class SetClickedRouteStop implements Action {
  readonly type = ActionTypes.setClickedRouteStop;
  constructor(public payload: { clickedRouteStop: RouteStopsGridItem }) {}
}

export class SetFocusedRoute implements Action {
  readonly type = ActionTypes.setFocusedRoute;
  constructor(public payload: { focusedRoute: EventItem<RouteItemIdentifier> }) {}
}

export class SetFocusedStopForSelectedRoute implements Action {
  readonly type = ActionTypes.setFocusedStopForSelectedRoute;
  constructor(public payload: { focusedStopForSelectedRoute: EventItem<AssignedStopIdentifier> }) {}
}

export class SetStopsForSelectedRoutes implements Action {
  readonly type = ActionTypes.setStopsForSelectedRoutes;
  constructor(public payload: { stopsForSelectedRoutes: NumberToValueMap<Stop[]> }) {}
}

export class SetSelectedStopsForSelectedRoutes implements Action {
  readonly type = ActionTypes.setSelectedStopsForSelectedRoutes;
  constructor(public payload: { selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[] }) {}
}

export class UpdateStopsForSelectedRoute implements Action {
  readonly type = ActionTypes.updateStopsForSelectedRoute;
  constructor(public payload: { route: Route }) {}
}

export class SetShowCompletedStops implements Action {
  readonly type = ActionTypes.setShowCompletedStops;
  constructor(public payload: { showCompletedStops: boolean }) {}
}

export type Actions =
  | SetSearchCriteria
  | SetLastUpdate
  | SetSelected
  | Refresh
  | SetGridScrollPosition
  | SetSelectedRoutes
  | SetClickedRouteStop
  | SetFocusedRoute
  | SetFocusedStopForSelectedRoute
  | SetStopsForSelectedRoutes
  | UpdateStopsForSelectedRoute
  | SetSelectedStopsForSelectedRoutes
  | SetShowCompletedStops;
