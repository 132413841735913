<input
  type="text"
  matInput
  #input
  class="xpo-AgGrid-cellEditor__autocomplete-editor-input"
  [formControl]="formControl"
  [matAutocomplete]="autoValue"
  [maxLength]="maxLength"
  (focusin)="onFocusIn()"
  (keydown)="onKeyDown($event)"
  xpoAutocomplete
/>
<mat-autocomplete #autoValue="matAutocomplete" autoActiveFirstOption>
  <mat-option *ngFor="let option of filterValues$ | async; trackBy: trackOptionsBy" [value]="option">
    {{ option }}
  </mat-option>
</mat-autocomplete>
