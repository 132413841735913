import { UserRole } from './user-role.enum';

class UserRoleHelperClass {
  protected _values: string[] = [];
  protected _keys: string[] = [];

  values(): string[] {
    return this._values;
  }

  keys(): string[] {
    return this._keys;
  }
  constructor() {
    // tslint:disable-next-line:no-for-in
    for (const obj in UserRole) {
      if (!!obj) {
        this._values.push(UserRole[obj]);
        this._keys.push(obj);
      }
    }
  }

  toEnum(value: string): UserRole {
    // tslint:disable-next-line:no-for-in
    for (const obj in UserRole) {
      if (UserRole[obj] === value) {
        return UserRole[obj] as UserRole;
      }
    }
  }
}

const UserRoleHelper = new UserRoleHelperClass();
export default UserRoleHelper;
