import { Injectable } from '@angular/core';
import { Route } from '@xpo-ltl/sdk-cityoperations';
import { find as _find } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LayoutPreferenceService } from '../../../../../../../../shared/layout-manager';
import { AssignedStopMapMarker, DriverMapMarker, DriverRouteInfo } from '../../../../../../shared';
import { DispatcherTripsRouteRenderingService } from '../../../../../dispatcher-trips/services/dispatcher-trips-route-rendering.service';
import { TripRenderingService } from '../trip-rendering.service';

export enum BreadcrumbsOptions {
  SHOW = 'Show',
  HIDE = 'Hide',
}

@Injectable({
  providedIn: 'root',
})
export class TripBreadcrumbsLayerHelperService {
  constructor(
    private tripRenderingService: TripRenderingService,
    private dispatcherTripsRouteRenderingService: DispatcherTripsRouteRenderingService,
    private layoutPreferenceService: LayoutPreferenceService
  ) {}

  private isDriverInSelectedRoutes(selectedRoutes: Route[], driverRoutes: (Route | DriverRouteInfo)[]): boolean {
    let isDriverInSelectedRoute = false;

    for (let i = 0; i < driverRoutes.length && !isDriverInSelectedRoute; i++) {
      const driverRoute = driverRoutes[i];
      if (_find(selectedRoutes, (selectedRoute) => selectedRoute.routeInstId === driverRoute.routeInstId)) {
        isDriverInSelectedRoute = true;
      }
    }

    return isDriverInSelectedRoute;
  }

  shouldHideOption(
    option: BreadcrumbsOptions,
    tripInstId: number,
    marker?: DriverMapMarker | AssignedStopMapMarker
  ): Observable<boolean> {
    return new Observable((observer) => {
      this.dispatcherTripsRouteRenderingService.selectedRoutes$.pipe(take(1)).subscribe((selectedRoutes: Route[]) => {
        const hideCurrentOption =
          (option === BreadcrumbsOptions.SHOW && this.tripRenderingService.isShowingBreadcrumbs(tripInstId)) ||
          (option === BreadcrumbsOptions.HIDE && !this.tripRenderingService.isShowingBreadcrumbs(tripInstId));

        if (marker && marker instanceof DriverMapMarker) {
          const driverRoutes: DriverRouteInfo[] = marker?.markerInfo?.routes || [];
          const isDriverInSelectedRoutes = this.isDriverInSelectedRoutes(selectedRoutes, driverRoutes);

          observer.next(!isDriverInSelectedRoutes || hideCurrentOption);
          observer.complete();
        } else {
          observer.next(hideCurrentOption);
          observer.complete();
        }
      });
    });
  }
}
