import { Component } from '@angular/core';
import { RouteCategoryCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionLinkCellRendererComponent } from '../action-link-cell-renderer/action-link-cell-renderer';

@Component({
  selector: 'pnd-route-action-link-cell-renderer',
  templateUrl: './route-action-link-cell-renderer.html',
})
export class RouteActionLinkCellRendererComponent extends ActionLinkCellRendererComponent
  implements ICellRendererAngularComp {
  displayLink = true;

  agInit(params: ICellRendererParams): void {
    this.displayLink = params.data?.route?.categoryCd === RouteCategoryCd.DELIVERY;
    super.agInit(params);
  }
}
