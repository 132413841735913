import { Pipe, PipeTransform } from '@angular/core';
import { CarrierTenderStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'carrierTenderStatusCd' })
export class CarrierTenderStatusCdPipe implements PipeTransform {
  private statusMap: { [key: string]: string } = {
    [CarrierTenderStatusCd.ACCEPTED]: 'Accepted',
    [CarrierTenderStatusCd.ACCEPTED_WITH_EXCEPTION]: 'Accepted With Exception',
    [CarrierTenderStatusCd.ACKNOWLEDGED]: 'Acknowledged',
    [CarrierTenderStatusCd.REJECTED]: 'Rejected',
    [CarrierTenderStatusCd.ROUTED]: 'Routed',
    [CarrierTenderStatusCd.TENDERED]: 'Tendered',
    [CarrierTenderStatusCd.TENDER_FAILED]: 'Tender Failed',
    [CarrierTenderStatusCd.WITHDRAWN]: 'Withdrawn',
    ['NotTendered']: 'Not Tendered',
  };

  transform(value: CarrierTenderStatusCd | string): string {
    return this.statusMap[value] || value;
  }
}
