import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UnassignedDeliveriesStoreEffects } from './unassigned-deliveries-store.effects';
import { unassignedDeliveriesReducer } from './unassigned-deliveries-store.reducers';
import { StateKey } from './unassigned-deliveries-store.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(StateKey, unassignedDeliveriesReducer),
    EffectsModule.forFeature([UnassignedDeliveriesStoreEffects]),
  ],
})
export class UnassignedDeliveriesStoreModule {}
