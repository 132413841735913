import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isEmpty as _isEmpty, padStart as _padStart, padEnd as _padEnd, size as _size } from 'lodash';
import moment from 'moment-timezone';
import { TimeFormatErrorEnum } from '../enums/time-format-error.enum';
import { TimeValidationEnum } from '../enums/time-validation.enum';
import { TimeInputModel } from '../models/time-input.model';

export class TimeUtil {
  static TimeFormatValidatorRegExp = '(([0-1][0-9]|[2][0-3]):([0-5][0-9]))';
  static TimeFormatValidatorRegExpWithAnchors = `^${TimeUtil.TimeFormatValidatorRegExp}$`;
  static TimeProgressiveFormatValidatorRegExp = `([0-2]|[0-1][0-9]|[2][0-3]|[0-1][0-9][:]|[2][0-3][:]|[0-1][0-9][:][0-5]|[2][0-3][:][0-5]|[0-1][0-9][:][0-5][0-9]|[2][0-3][:][0-5][0-9])`;
  static TimeProgressiveFormatValidatorRegExpWithAnchors = `^${TimeUtil.TimeProgressiveFormatValidatorRegExp}$`;
  static FullDateTimeMomentFormat = `YYYY-MM-DD HH:mm`;
  static FullDateMomentFormat = `YYYY-MM-DD`;

  static inputTimeFormControlEnlightening(timeInput: TimeInputModel): TimeInputModel {
    const regExpProgressiveMatch = new RegExp(TimeUtil.TimeProgressiveFormatValidatorRegExpWithAnchors);

    const separatorRegExp = new RegExp(/[,]|[;]|[:]|[.]/);

    const separatorWithColonRepeatedRegExp = new RegExp(/[,]|[;]|[:]{2}|[.]/);

    const startingDigitForTwoDigitsMinuteWithHourWithoutColon = new RegExp(/^([0-1][0-9][0-5]|[2][0-3][0-5])$/);

    const nonStartingDigitForTwoDigitsMinuteWithHourWithoutColon = new RegExp(/^([0-1][0-9][6-9]|[2][0-3][6-9])$/);

    const nonStartingDigitForTwoDigitsMinuteWithHourWithColon = new RegExp(/^([0-1][0-9][:][6-9]|[2][0-3][:][6-9])$/);

    const nonStartingDigitForTwoDigitsHour = /^([3-9])$/;

    if (!regExpProgressiveMatch.test(timeInput.nextValue)) {
      if (!separatorRegExp.test(timeInput.previousValue) && separatorRegExp.test(timeInput.nextValue)) {
        return TimeUtil.fixSeparatorInputPositionAndReplaceByColon(
          new TimeInputModel({
            previousValue: timeInput.previousValue,
            nextValue: timeInput.nextValue,
          })
        );
      } else if (
        separatorRegExp.test(timeInput.previousValue) &&
        separatorWithColonRepeatedRegExp.test(timeInput.nextValue)
      ) {
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (startingDigitForTwoDigitsMinuteWithHourWithoutColon.test(timeInput.nextValue)) {
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:${timeInput.nextValue.substr(2, 1)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (nonStartingDigitForTwoDigitsMinuteWithHourWithoutColon.test(timeInput.nextValue)) {
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:0${timeInput.nextValue.substr(2, 1)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (nonStartingDigitForTwoDigitsMinuteWithHourWithColon.test(timeInput.nextValue)) {
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:0${timeInput.nextValue.substr(3, 1)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (nonStartingDigitForTwoDigitsHour.test(timeInput.nextValue)) {
        timeInput.previousValue = `0${timeInput.nextValue.substr(0, 1)}:`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (timeInput.previousValue === '' && timeInput.nextValue === '') {
        return timeInput;
      } else {
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      }
    } else if (!separatorRegExp.test(timeInput.previousValue) && separatorRegExp.test(timeInput.nextValue)) {
      return TimeUtil.fixSeparatorInputPositionAndReplaceByColon(
        new TimeInputModel({
          previousValue: timeInput.previousValue,
          nextValue: timeInput.nextValue,
        })
      );
    } else {
      timeInput.previousValue = timeInput.nextValue;
      return timeInput;
    }
  }

  static fixSeparatorInputPositionAndReplaceByColon(timeInput: TimeInputModel): TimeInputModel {
    // deleting next value reference
    const auxNextValue = timeInput.nextValue + '';
    delete timeInput.nextValue;
    timeInput.nextValue = auxNextValue;

    const separatorIndex: number = TimeUtil.indexOfSeparator(timeInput.nextValue);
    switch (separatorIndex) {
      case 0:
        timeInput.previousValue = `00:${timeInput.nextValue.substr(3, 2)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        break;

      case 1:
        timeInput.previousValue = `0${timeInput.nextValue.substr(0, 1)}:${timeInput.nextValue.substr(3, 2)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        break;

      case 2:
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:${timeInput.nextValue.substr(3, 2)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        break;

      default:
        timeInput.previousValue = timeInput.nextValue;
        timeInput.nextValue = '';
        break;
    }
    return timeInput;
  }

  static indexOfSeparator(value: string): number {
    return value.indexOf(':') > -1
      ? value.indexOf(':')
      : value.indexOf(',') > -1
      ? value.indexOf(',')
      : value.indexOf(';') > -1
      ? value.indexOf(';')
      : value.indexOf('.') > -1
      ? value.indexOf('.')
      : -1;
  }

  static formatStringToTimeStringByFillingWithZeros(invalidTime: string): string {
    invalidTime = invalidTime.replace(/:/g, '');

    invalidTime = _size(invalidTime) === 3 ? (invalidTime = `0${invalidTime}`) : invalidTime;
    const hours = invalidTime.substr(0, 2);
    const minutes = invalidTime.substr(2, 2);

    return `${_padStart(`${hours}`, 2, '0')}:${_padEnd(`${minutes}`, 2, '0')}`;
  }

  static timeFormatValidatorFunction(): ValidatorFn {
    return TimeUtil.timeFormatValidation;
  }

  static timeFormatValidation(
    control: AbstractControl,
    acceptMidNight: boolean = false
  ): { [key: string]: any } | null {
    const validTimeRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);
    let val = '';
    if (control.value) {
      if (typeof control.value === 'string') {
        val = control.value;
      } else if (typeof control.value === 'object') {
        val = control.value.value;
      }
    }
    // validate the control value is in the form "HH:MM",
    // where HH is between [00, 23], and MM is between [00, 59]
    return !_isEmpty(val)
      ? !validTimeRegExp.test(val) || (!acceptMidNight && val === '00:00')
        ? { [TimeFormatErrorEnum.InvalidFormat]: true }
        : null
      : null;
  }

  static timeFormatValidationWithLocalDate(
    value: string,
    planDate: Date,
    timeZone: string
  ): { [key: string]: any } | null {
    const validTimeRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);
    const valAsString = moment(
      `${moment(planDate)
        .tz(timeZone)
        .format('YYYY-MM-DD')} ${value || '00:00'}`,
      'YYYY-MM-DD HH:mm'
    );

    const nowAsString = moment()
      .seconds(0)
      .milliseconds(0)
      .tz(timeZone)
      .format('YYYY-MM-DD HH:mm');

    // validate that the value is in the form "HH:MM",
    // where HH is between [00, 23], and MM is between [00, 59]
    if (_isEmpty(value)) {
      return { [TimeFormatErrorEnum.Required]: true };
    }

    if (!validTimeRegExp.test(value) || value === '00:00') {
      return { [TimeFormatErrorEnum.InvalidFormat]: true };
    }

    return moment(valAsString).isBefore(nowAsString) ? { [TimeFormatErrorEnum.InThePast]: true } : null;
  }

  static isValidDate(date: Date | number) {
    return new Date(date).getTime() > 0;
  }

  /**
   * Returns the noon UTC value of the date.
   *
   * Example: 2021-01-01T12:00:00.000Z
   * @param date Format: 'YYYY-MM-DD'
   */
  static toNoonUTC(date: string): string {
    return `${date}T12:00:00.000Z`;
  }

  /**
   * Returns if the given date is toda's date
   * @param date Date to be compared with today's date
   */
  static isToday(date: Date): boolean {
    return moment(date).isSame(moment(), 'day');
  }
}
