import { EventItem, RouteItemIdentifier } from 'app/inbound-planning/shared';
import { SpotsAndDropsSearchCriteria } from './spots-and-drops-search-criteria.interface';

export const StateKey = 'spotsAndDropsState';

export interface State {
  readonly criteria: SpotsAndDropsSearchCriteria;
  readonly lastUpdate: Date;
  readonly selectedTrips: number[];
  readonly selectedTrip: number;
  readonly focusedSpotAndDrop: EventItem<RouteItemIdentifier>;
}

export const initialState: State = {
  criteria: {},
  lastUpdate: new Date(0),
  selectedTrips: [],
  selectedTrip: undefined,
  focusedSpotAndDrop: {
    id: undefined,
    source: undefined,
  },
};
