<div class="xpo-LtlSaveLayoutDialog">
  <h1 mat-dialog-title>
    Save Layout
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>

  <form (ngSubmit)="dialogRef.close(layoutName)" #saveForm="ngForm">
    <div mat-dialog-content>
      <mat-form-field>
        <input
            matInput
            required
            placeholder="Enter Layout Name"
            name="layoutName"
            [(ngModel)]="layoutName"
            data-test="xpo-LtlSaveLayoutDialog__input" />
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <xpo-button-group>
        <button
            mat-button
            color="primary"
            type="button"
            (click)="dialogRef.close()"
            data-test="xpo-LtlSaveLayoutDialog__cancel">
          Cancel
        </button>
        <button
            mat-flat-button
            type="submit"
            [disabled]="!saveForm.form.valid"
            data-test="xpo-LtlSaveLayoutDialog__save">
          <mat-icon>save</mat-icon> Save
        </button>
      </xpo-button-group>
    </div>
  </form>
</div>
