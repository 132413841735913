import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import {
  CityOperationsApiService,
  DeletePnDDispatchGroupPath,
  CreatePnDDispatchGroupRqst,
  DispatchGroup,
  DispatchGroupRegion,
  ListPnDDispatchGroupsQuery,
  ListPnDDispatchGroupsResp,
  PnDDispatchGroupRegion,
  UpdatePnDDispatchGroupRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { Polygon, Position } from 'geojson';
import { map as _map, orderBy as _orderBy, isEqual as _isEqual, size as _size, filter as _filter } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { UnassignedPickupsService } from '..';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../../core';
import { PndAppUtils } from '../../../../../shared/app-utils';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../../store';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '../../../../store/dispatcher-trips-store';
import { PndStore } from '../../../../store/pnd-store';
import { MapUtils } from '../../classes/map-utils';
import { SicZoneSelectionType } from '../../enums/sic-zone-selection-type';
import { SicZonesAndSatellites } from '../../models/sic-zones-and-satellites.model';
import { DispatchAreaPolygon } from './dispatch-area-rendering.service';

@Injectable({
  providedIn: 'root',
})
export class DispatchAreaService implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  private dispatchGroupRegionsBehaviorSubject = new BehaviorSubject<PnDDispatchGroupRegion[]>([]);
  readonly dispatchGroupRegions$ = this.dispatchGroupRegionsBehaviorSubject.asObservable();

  private selectedDispatchGroupsBehaviorSubject = new BehaviorSubject<DispatchGroup[]>(undefined);
  readonly selectedDispatchGroups$ = this.selectedDispatchGroupsBehaviorSubject.asObservable();

  private focusedDispatchGroupRegionBehaviorSubject = new BehaviorSubject<PnDDispatchGroupRegion>(undefined);
  readonly focusedDispatchGroupRegion$ = this.focusedDispatchGroupRegionBehaviorSubject.asObservable();

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: PndStore<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService,
    protected unassignedPickupsService: UnassignedPickupsService
  ) {
    // global sic can change before sic zone and satellites are reset, need to wait for zone/satellites
    // to be set before loading groups
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites)
      .pipe(
        filter((globalFilterSicZonesAndSatellites) => !!globalFilterSicZonesAndSatellites),
        distinctUntilChanged((a, b) => _isEqual(a, b)),
        // withLatestFrom(this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic)),
        switchMap((globalFilterSicZonesAndSatellites) => this.loadDispatchGroups$(globalFilterSicZonesAndSatellites)),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  loadDispatchGroups$(globalFilterSicZonesAndSatellites?: SicZonesAndSatellites): Observable<PnDDispatchGroupRegion[]> {
    let sicZonesAndSatellites;
    if (globalFilterSicZonesAndSatellites) {
      sicZonesAndSatellites = globalFilterSicZonesAndSatellites;
    } else {
      sicZonesAndSatellites = this.pndStore$.selectSnapshot(
        GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites
      );
    }

    if (!sicZonesAndSatellites) {
      // if no sic zone and satellite set, then no dispatch group regions
      this.dispatchGroupRegionsBehaviorSubject.next([]);
      return of([]);
    } else {
      // get the dispatch groups for the passed Sic cds
      let sicCds: string[];

      if (sicZonesAndSatellites.currentSelection === SicZoneSelectionType.HOST_ONLY) {
        sicCds = [sicZonesAndSatellites.host];
      } else if (sicZonesAndSatellites.currentSelection === SicZoneSelectionType.ZONES_ONLY) {
        sicCds = [...sicZonesAndSatellites.zones, ...sicZonesAndSatellites.zoneSatellites];
      } else {
        sicCds = [sicZonesAndSatellites.host, ...sicZonesAndSatellites.zones, ...sicZonesAndSatellites.zoneSatellites];
      }

      const query = { ...new ListPnDDispatchGroupsQuery(), sicCds: sicCds };

      return this.cityOperationsService.listPnDDispatchGroups(query).pipe(
        PndAppUtils.retry(5, 200, 50),
        take(1),
        catchError(() => of({ ...new ListPnDDispatchGroupsResp() })),
        map((response) => {
          const regions: PnDDispatchGroupRegion[] = _orderBy(
            response?.dispatchGroupsAndRegions ?? [],
            (area: PnDDispatchGroupRegion) => area?.dispatchGroup?.auditInfo?.updatedTimestamp,
            'desc'
          );
          this.dispatchGroupRegionsBehaviorSubject.next(regions);

          return regions;
        })
      );
    }
  }

  setSelectedDispatchGroup(dispatchGroups: DispatchGroup[]): void {
    const selectedOptions = _filter(
      dispatchGroups,
      (selectedOption: DispatchGroup) => selectedOption.groupName !== 'All'
    );

    this.selectedDispatchGroupsBehaviorSubject.next(selectedOptions);

    this.refreshDispatcherTrips();
    this.refreshUnassignedDeliveries();
    this.refreshUnassignedPickups();
  }

  getDispatchGroupRegions(): PnDDispatchGroupRegion[] {
    return this.dispatchGroupRegionsBehaviorSubject.value;
  }

  getDispatchGroups(): DispatchGroup[] {
    return _map(
      this.dispatchGroupRegionsBehaviorSubject.value,
      (dispatchGroupRegion) => dispatchGroupRegion.dispatchGroup
    );
  }

  getSelectedDispatchGroups(): DispatchGroup[] {
    if (
      _size(this.selectedDispatchGroupsBehaviorSubject.value) === _size(this.dispatchGroupRegionsBehaviorSubject.value)
    ) {
      return [];
    }
    return this.selectedDispatchGroupsBehaviorSubject.value;
  }

  createDispatchGroup$(dispatchGroup: DispatchGroup, polygon: DispatchAreaPolygon): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (!MapUtils.isValidPolygon(polygon)) {
        this.notificationMessageService
          .openNotificationMessage(
            NotificationMessageStatus.Error,
            'Invalid area.  A minimum of three points are required.'
          )
          .subscribe();

        observer.next(false);
        observer.complete();
        return;
      }

      const request = new CreatePnDDispatchGroupRqst();
      request.dispatchGroup = dispatchGroup;
      request.dispatchGroupRegion = new DispatchGroupRegion();
      request.dispatchGroupRegion.regionShapeGeojson = MapUtils.polygonToString(polygon);

      this.cityOperationsService
        .createPnDDispatchGroup(request)
        .pipe(take(1))
        .subscribe(
          () => {
            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Success,
                `Dispatch Area ${dispatchGroup.groupName} successfully created.`
              )
              .subscribe();

            observer.next(true);
            observer.complete();
          },
          (error) => {
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error?.error?.message ?? '')
              .subscribe();

            observer.next(false);
            observer.complete();
          }
        );
    });
  }

  updateDispatchGroup$(
    pndDispatchGroupRegion: PnDDispatchGroupRegion,
    polygon: DispatchAreaPolygon
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const request = new UpdatePnDDispatchGroupRqst();
      request.dispatchGroup = pndDispatchGroupRegion.dispatchGroup;
      request.dispatchGroupRegion =
        pndDispatchGroupRegion.dispatchGroupRegion && pndDispatchGroupRegion.dispatchGroupRegion.regionId !== 0
          ? pndDispatchGroupRegion.dispatchGroupRegion
          : new DispatchGroupRegion();
      request.dispatchGroupRegion.regionShapeGeojson = MapUtils.polygonToString(polygon);

      if (!MapUtils.isValidPolygon(polygon)) {
        this.notificationMessageService
          .openNotificationMessage(
            NotificationMessageStatus.Error,
            'Invalid area.  A minimum of three points are required.'
          )
          .subscribe();

        observer.next(false);
        observer.complete();
        return;
      }

      this.cityOperationsService
        .updatePnDDispatchGroup(request)
        .pipe(take(1))
        .subscribe(
          () => {
            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Success,
                `Dispatch Area ${pndDispatchGroupRegion.dispatchGroup.groupName} successfully updated.`
              )
              .subscribe();

            observer.next(true);
            observer.complete();
          },
          (error) => {
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error?.error?.message ?? '')
              .subscribe();

            observer.next(false);
            observer.complete();
          }
        );
    });
  }

  deleteDispatchGroup$(dispatchGroupRegion: PnDDispatchGroupRegion): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const httpOptions = {
        headers: {
          'Transaction-Timestamp': `${new Date().getTime()}`,
        },
      };

      // TODO - use the correct type for error
      // tslint:disable-next-line:no-any
      const errorFunc = (error: any) => {
        this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Error, error).subscribe();

        observer.next(false);
        observer.complete();
      };

      const pathParams = { ...new DeletePnDDispatchGroupPath(), groupId: dispatchGroupRegion.dispatchGroup.groupId };
      this.cityOperationsService
        .deletePnDDispatchGroup(pathParams, {}, httpOptions)
        .pipe(take(1))
        .subscribe(
          () => {
            this.loadDispatchGroups$()
              .pipe(take(1))
              .subscribe();

            if (dispatchGroupRegion?.dispatchGroupRegion?.regionShapeGeojson) {
              // Undo is only supported when a dispatch region already has a shape...
              this.notificationMessageService
                .openNotificationMessage(
                  NotificationMessageStatus.Success,
                  `Dispatch Area ${dispatchGroupRegion.dispatchGroup.groupName} successfully deleted.`,
                  'Undo',
                  () => {
                    this.createDispatchGroup$(
                      dispatchGroupRegion.dispatchGroup,
                      this.createDispatchAreaPolygon(dispatchGroupRegion)
                    ).subscribe();
                  }
                )
                .subscribe(
                  () => {
                    this.loadDispatchGroups$()
                      .pipe(take(1))
                      .subscribe();
                  },
                  (error) => errorFunc(error)
                );

              observer.next(true);
              observer.complete();
            } else {
              this.notificationMessageService
                .openNotificationMessage(
                  NotificationMessageStatus.Success,
                  `Dispatch Area ${dispatchGroupRegion.dispatchGroup.groupName} successfully deleted.`
                )
                .subscribe(
                  () => {
                    observer.next(true);
                    observer.complete();
                  },
                  (error) => errorFunc(error)
                );
            }
          },
          (error) => errorFunc(error)
        );
    });
  }

  setFocusedDispatchGroupRegion(dispatchGroupRegion: PnDDispatchGroupRegion): void {
    this.focusedDispatchGroupRegionBehaviorSubject.next(dispatchGroupRegion);
  }

  createDispatchAreaPolygon(dispatchGroupRegion: PnDDispatchGroupRegion): DispatchAreaPolygon {
    if (!dispatchGroupRegion?.dispatchGroupRegion?.regionShapeGeojson) {
      return undefined;
    }

    const geoJson: Polygon = MapUtils.geoJsonToPolygon(dispatchGroupRegion.dispatchGroupRegion.regionShapeGeojson);
    const polygon = new google.maps.Polygon() as DispatchAreaPolygon;
    const path: google.maps.LatLng[] = [];

    geoJson.coordinates[0].forEach((position: Position) => {
      path.push(new google.maps.LatLng(position[1], position[0]));
    });

    polygon.setPath(path);

    return polygon;
  }

  getDispatchAreaPanelHeight(): number {
    const regions = this.getDispatchGroupRegions();
    const initialheight = 40; // Create area button;
    const cardHeight = 211; // card + margin
    return regions ? initialheight + regions.length * cardHeight : initialheight;
  }

  openUsageNotification(): void {
    const msg =
      _size(this.dispatchGroupRegionsBehaviorSubject.value) === 0 &&
      _size(this.selectedDispatchGroupsBehaviorSubject.value) === 0
        ? `Create your first Dispatch Group by drawing an area on the map.`
        : `Edit existing groups on the map by clicking the shape, or create more by drawing.`;

    this.notificationMessageService.openNotificationMessage(NotificationMessageStatus.Info, msg).subscribe();
  }

  private refreshUnassignedDeliveries(): void {
    const criteria = this.pndStore$.selectSnapshot(UnassignedDeliveriesStoreSelectors.searchCriteria);
    if (criteria) {
      criteria.dispatchGroupIds = _map(
        this.getSelectedDispatchGroups(),
        (selectedDispatchGroup: DispatchGroup) => selectedDispatchGroup.groupId
      );
      this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.SetSearchCriteria({ criteria: criteria }));
    }
  }

  private refreshUnassignedPickups(): void {
    const previousCriteria = this.pndStore$.selectSnapshot(UnassignedPickupsStoreSelectors.searchCriteria);

    if (previousCriteria) {
      const newCriteria = this.pndStore$.selectSnapshot(UnassignedPickupsStoreSelectors.searchCriteria);

      newCriteria.dispatchGroupIds = _map(
        this.getSelectedDispatchGroups(),
        (selectedDispatchGroup: DispatchGroup) => selectedDispatchGroup.groupId
      );
      if (this.unassignedPickupsService.isValidCriteriaChange(previousCriteria, newCriteria)) {
        this.pndStore$.dispatch(new UnassignedPickupsStoreActions.SetSearchCriteria({ criteria: newCriteria }));
      }
    }
  }

  private refreshDispatcherTrips(): void {
    const criteria = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.searchCriteria);
    if (criteria) {
      criteria.groupIds = _map(
        this.getSelectedDispatchGroups(),
        (selectedDispatchGroup: DispatchGroup) => selectedDispatchGroup.groupId
      );
      criteria.tripStatusCd = criteria.tripStatusCd || []; // TODO: this needs to be revisited!

      this.pndStore$.dispatch(new DispatcherTripsStoreActions.SetSearchCriteria({ criteria: criteria }));
    }
  }
}
