import { ConditioningService } from '@xpo-ltl/common-services';
import { Activity, DeliveryShipmentSearchRecord, InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';
import { RouteStatusCd, ShipmentSpecialServiceCd, StopWindow, TripStatusCd, PickupTypeCd } from '@xpo-ltl/sdk-common';
import { DeliveryWindowService } from '../services/delivery-window.service';
import { EtaWindowService } from '../services/eta-window.service';
import { StopWindowService } from '../services/stop-window.service';
import { SpecialServiceMark } from './../components/service-icons/model/special-service-mark';
import { TripNodeActivityExtendedCd } from './stop-type.model';

/**
 * Data required by the MapMarker
 */
export interface MapMarkerInfoData {
  consignee?: InterfaceAcct;
  shipper?: InterfaceAcct;

  stopWindow?: StopWindow[];
  activities?: Activity[];
  deliveryShipments?: DeliveryShipmentSearchRecord[];

  etaSicCd?: string;

  driverName?: string;
  scheduleETA?: Date;
  totalWeightLbs?: number;
  motorizedPiecesCount?: number;
  totalShipmentsCount?: number;
  totalPalletCount?: number;
  totalLoosePieces?: number;
  totalCube?: number;
  stopType?: TripNodeActivityExtendedCd;
  pickupTypeCd?: PickupTypeCd;
  color?: string;
  textAndBorderColor?: string;
}

export class MapMarkerInfo {
  readonly routeStatus: RouteStatusCd;
  readonly tripStatus: TripStatusCd;
  readonly driverName: string;
  readonly consigneeName: string;
  readonly consigneeName2: string;
  readonly consigneeAddress: string;
  readonly consigneeCity: string;
  readonly consigneeState: string;
  readonly consigneeZipCode: string;
  readonly estimatedArrival: Date;
  readonly totalWeight: number;
  readonly totalShipments: number;
  readonly totalPallets: number;
  readonly totalLoosePieces: number;
  readonly totalCube: number;
  readonly totalMotorMoves: number;
  readonly acctInstId: number;
  readonly acctMadCd: string;
  readonly routeId: string;
  readonly stopType: TripNodeActivityExtendedCd;
  readonly stopWindowType: string;
  readonly stopWindowTime: string;
  readonly etaSicCd: string;
  readonly etaWindow: string;
  readonly deliveryWindow: string;
  readonly proList: string;

  readonly color: string; // HACK to let us set original color of marker
  readonly textAndBorderColor: string;

  specialServices: ShipmentSpecialServiceCd[];
  specialServiceMarks: SpecialServiceMark[];

  routeName?: string;

  constructor(
    private stopWindowService: StopWindowService,
    private etaWindowService: EtaWindowService,
    private deliveryWindowService: DeliveryWindowService,
    private conditioningService: ConditioningService,
    data: MapMarkerInfoData,
    routeStatus: RouteStatusCd,
    tripStatus: TripStatusCd,
    routeId?: string,
    routeName?: string
  ) {
    this.routeStatus = routeStatus;
    this.tripStatus = tripStatus;
    this.routeId = routeId;
    this.routeName = routeName;

    const account = data?.consignee ?? data?.shipper;
    if (account) {
      this.acctInstId = account.acctInstId;
      this.acctMadCd = account.acctMadCd;
      this.consigneeName = account.name1;
      this.consigneeName2 = account.name2;
      this.consigneeAddress = account.addressLine1;
      this.consigneeCity = account.cityName;
      this.consigneeState = account.stateCd;
      this.consigneeZipCode = account.postalCd;
    }
    this.driverName = data.driverName;
    this.estimatedArrival = data.scheduleETA;
    this.totalWeight = data.totalWeightLbs;
    this.totalMotorMoves = data.motorizedPiecesCount;
    this.totalShipments = data.totalShipmentsCount;
    this.totalPallets = data.totalPalletCount;
    this.totalLoosePieces = data.totalLoosePieces;
    this.totalCube = data.totalCube;
    this.stopType = data.stopType;
    this.color = data.color;
    this.textAndBorderColor = data.textAndBorderColor;

    this.stopWindowType = this.stopWindowService.getStopWindowType(data.stopWindow);
    this.stopWindowTime = this.stopWindowService.getStopWindowTime(data.stopWindow, false);

    if (data.activities) {
      this.etaSicCd = data.etaSicCd;
      this.etaWindow = this.etaWindowService.getEtaWindowFromActivities(this.etaSicCd, data.activities);
      this.deliveryWindow = this.deliveryWindowService.getDeliveryWindowFromActivities(this.etaSicCd, data.activities);
    }
    if (data.deliveryShipments) {
      this.etaSicCd = data.deliveryShipments[0].destinationSicCd;
      this.etaWindow = this.etaWindowService.getEtaWindowFromDeliveryShipments(this.etaSicCd, data.deliveryShipments);
      this.deliveryWindow = this.deliveryWindowService.getDeliveryWindowFromDeliveryShipments(
        this.etaSicCd,
        data.deliveryShipments
      );
      let list = data.deliveryShipments
        ?.map((searchRecord) => {
          return this.conditioningService.conditionProNumber(searchRecord.proNbr, 10);
        })
        ?.sort();
      if (list) {
        if (list.length > 30) {
          list = list.slice(0, 29);
          list.push('...');
        }
        this.proList = list.join(', ');
      } else {
        this.proList = '';
      }
    }
  }
}
