<div class="pnd-selectOptionEditor">
  <pnd-autocomplete
    floatLabel="always"
    maxlength="3"
    placeholder="SIC"
    [strictSelection]="true"
    [optionTemplate]="timeSelectorTemplate"
    [controlName]="selectOptionFormControl"
    [group]="formGroup"
    [items]="values"
    [focus$]="focus$"
    (selectionChanged)="onChange($event)"
  >
  </pnd-autocomplete>
</div>

<ng-template #timeSelectorTemplate let-item>
  <span>
    {{ item.value }}
  </span>
</ng-template>
