export enum ModifyTripDetailsShipmentsGridHeaders {
  // Shared headers are defined in ModifyTripDetailsSharedGridHeaders

  PRO = 'PRO',
  ORIGIN_SIC = 'Origin SIC',
  SERVICE_DATE = 'Service Date',
  LOADED = 'Loaded',
  DESTINATION_ETA = 'Dest. ETA',
  CURRENT_LOCATION = 'Current Location',
  HANDLING_UNITS = 'HU',
}
