import { isInteger as _isInteger, startsWith as _startsWith, isEmpty as _isEmpty } from 'lodash';

import { AbstractRouteSimpleBoardLane } from '../app/inbound-planning/components/route-balancing/classes/board/abstract-route-simple-board-lane.model';

export class PndRouteUtils {
  /**
   * Return the RouteId (prefix-sufix), or an empty string if not available in
   * passed object
   */
  static getRouteId(route: { routePrefix: string; routeSuffix: string }): string {
    return route &&
      route.routePrefix &&
      route.routeSuffix &&
      !_isEmpty(route.routePrefix.trim()) &&
      !_isEmpty(route.routeSuffix.trim())
      ? `${route.routePrefix}-${route.routeSuffix}`
      : '';
  }

  /**
   * Comparator function for sorting routes by its prefix/suffix ascending.
   *
   * @param thisRoute
   * @param thatRoute
   */
  static compareByPrefixSuffixAsc(
    thisRoute: AbstractRouteSimpleBoardLane,
    thatRoute: AbstractRouteSimpleBoardLane
  ): number {
    const aPrefixSuffix = `${thisRoute.data.routePrefix}-${thisRoute.data.routeSuffix}`;
    const bPrefixSuffix = `${thatRoute.data.routePrefix}-${thatRoute.data.routeSuffix}`;
    return aPrefixSuffix.localeCompare(bPrefixSuffix);
  }

  /**
   * Ag-grid Sort Comparator function for sorting routes by its prefix.
   *
   * @param routeAPrefix
   * @param routeASuffix
   * @param routeBPrefix
   * @param routeBSuffix
   */
  static routePrefixSuffixComparator(
    routeAPrefix: string,
    routeASuffix: string,
    routeBPrefix: string,
    routeBSuffix: string
  ): number {
    const isComparingNumericSuffixOnSameRoutePrefix =
      routeAPrefix === routeBPrefix &&
      _isInteger(+routeASuffix) &&
      _isInteger(+routeBSuffix) &&
      !_startsWith(routeASuffix, '0') &&
      !_startsWith(routeBSuffix, '0');

    if (isComparingNumericSuffixOnSameRoutePrefix) {
      if (+routeBSuffix < +routeASuffix) {
        return 1;
      } else if (+routeBSuffix > +routeASuffix) {
        return -1;
      } else {
        return 0;
      }
    } else {
      const routeACompareVal: string = `${routeAPrefix}-${routeASuffix}`;
      const routeBCompareVal: string = `${routeBPrefix}-${routeBSuffix}`;

      return routeACompareVal.localeCompare(routeBCompareVal, 'en', { numeric: true });
    }
  }

  /**
   * Ag-grid Sort Comparator function for sorting routes by its suffix.
   *
   * @param routeAPrefix
   * @param routeASuffix
   * @param routeBPrefix
   * @param routeBSuffix
   */
  static routeSuffixPrefixSortComparator(
    routeAPrefix: string,
    routeASuffix: string,
    routeBPrefix: string,
    routeBSuffix: string
  ): number {
    const isComparingNumericSuffix =
      _isInteger(+routeASuffix) &&
      _isInteger(+routeBSuffix) &&
      !_startsWith(routeASuffix, '0') &&
      !_startsWith(routeBSuffix, '0');

    if (isComparingNumericSuffix) {
      if (+routeBSuffix < +routeASuffix) {
        return 1;
      } else if (+routeBSuffix > +routeASuffix) {
        return -1;
      } else {
        return routeAPrefix.localeCompare(routeBPrefix, 'en', { numeric: true });
      }
    } else {
      const routeACompareVal: string = `${routeASuffix}-${routeAPrefix}`;
      const routeBCompareVal: string = `${routeBSuffix}-${routeBPrefix}`;

      return routeACompareVal.localeCompare(routeBCompareVal, 'en', { numeric: true });
    }
  }
}
