import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ag-grid';
import { ProFormatterPipe } from '@xpo-ltl/ngx-ltl';
import { DockActivityCd } from '@xpo-ltl/sdk-common';
import { ColDef, ValueGetterParams, ValueFormatterParams, ICellRendererParams, ColSpanParams } from 'ag-grid-community';
import { XpoColors } from 'app/inbound-planning/shared/services/xpo-colors';
import { BoardUtils } from '../../../../../../shared/board-utils';
import {
  SpecialServicesService,
  TotalTextCellRendererParams,
  DetailGridToggleCellRendererParams,
} from '../../../../shared';
import { PluralMaps } from '../../../../shared/classes/plural-maps';
import { CustomerNameLinkCellRenderParams } from '../../../../shared/components/customer-name-link-cell-render/customer-name-link-cell-render';
import { SpecialServiceMark } from '../../../../shared/components/service-icons/model/special-service-mark';
import { DockActivityCdPipe } from '../../../../shared/pipes/dock-activity-cd.pipe';
import { DockRoutesGridFields } from '../enums/dock-routes-grid-fields.enum';
import { DockRoutesGridHeaders } from '../enums/dock-routes-grid-headers.enum';
import { DockRoutesMainGroupType } from '../enums/dock-routes-main-group-type.enum';
import { DockRoutesActivityGridItem, DockRoutesBaseGridItem } from './dock-routes-grid-item.model';

export class DockRoutesColumnDefinitions {
  static [DockRoutesGridFields.ROW_SELECTED]: ColDef = {
    headerName: DockRoutesGridHeaders.ROW_SELECTED,
    headerValueGetter: () => '',
    field: DockRoutesGridFields.ROW_SELECTED,
    colId: DockRoutesGridFields.ROW_SELECTED,
    pinned: 'left',
    lockPosition: true,
    sortable: false,
    cellClass: 'xpo-AgGrid-checkboxColumn',
    headerClass: 'xpo-AgGrid-checkboxColumn',
    width: 45,
    suppressMenu: true,
    filter: false,
    headerCheckboxSelection: false,
    cellRendererSelector: (params: ICellRendererParams) => {
      const totalsRenderer: string = 'totalTextCellRenderer';
      let component: string;
      let componentParams: ICellRendererParams | TotalTextCellRendererParams = params;

      if (BoardUtils.isTotalsRow(params.node)) {
        component = totalsRenderer;

        componentParams = {
          ...params,
          displayValue: PluralMaps.Stops,
          suppressTotalText: !!params.node.aggData,
        } as TotalTextCellRendererParams;
      } else {
        component = null;
      }

      return {
        component: component,
        params: componentParams,
      };
    },
    cellRendererParams: <DetailGridToggleCellRendererParams>{
      smallIconsStyle: true,
    },
    colSpan: (params: ColSpanParams) => {
      if (params.node.isRowPinned() && !!params.node.data?.proNbr) {
        return 2;
      } else {
        return BoardUtils.isTotalsRow(params.node) ? 2 : 1;
      }
    },
    checkboxSelection: (params) => {
      return [DockRoutesMainGroupType.INCOMPLETE_DOCK_PICKUPS, DockRoutesMainGroupType.COMPLETED_DOCK_DROPS].includes(
        params.data?.mainGroupValue
      );
    },
  };

  static [DockRoutesGridFields.MAIN_GROUP_VALUE]: ColDef = {
    headerName: DockRoutesGridHeaders.MAIN_GROUP_VALUE,
    field: DockRoutesGridFields.MAIN_GROUP_VALUE,
    colId: DockRoutesGridFields.MAIN_GROUP_VALUE,
    hide: true,
    rowGroup: true,
    sortable: false,
    pinned: 'left',
    cellStyle: { 'justify-content': 'flex-start' },
    headerClass: 'text-capitalize',
    suppressMenu: true,
    cellRenderer: 'xpoAccordionGroupCellRendererComponent',
  };

  static [DockRoutesGridFields.CUSTOMER_NAME]: ColDef = {
    headerName: DockRoutesGridHeaders.CUSTOMER_NAME,
    field: DockRoutesGridFields.CUSTOMER_NAME,
    colId: DockRoutesGridFields.CUSTOMER_NAME,
    headerTooltip: DockRoutesGridHeaders.CUSTOMER_NAME,
    width: 250,
    minWidth: 250,
    sortable: true,
    cellRendererSelector: (params: ICellRendererParams) => {
      const typeCountRenderer: string = 'typeCountCellRenderer';
      const customerLink: string = 'CustomerNameLinkCellRender';
      let component: string;
      let componentParams: ICellRendererParams | CustomerNameLinkCellRenderParams = params;
      if (!BoardUtils.isTotalsRow(params.node)) {
        component = customerLink;

        componentParams = {
          customerAcctId: (data) => data?.customer?.acctInstId,
        } as CustomerNameLinkCellRenderParams;
      } else {
        component = typeCountRenderer;
      }

      return {
        component: component,
        params: componentParams,
      };
    },
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as DockRoutesBaseGridItem;
      return item?.customer?.name1;
    },
  };

  static [DockRoutesGridFields.CONSIGNEE_CITY]: ColDef = {
    headerName: DockRoutesGridHeaders.CONSIGNEE_CITY,
    field: DockRoutesGridFields.CONSIGNEE_CITY,
    colId: DockRoutesGridFields.CONSIGNEE_CITY,
    headerTooltip: DockRoutesGridHeaders.CONSIGNEE_CITY,
    width: 150,
    minWidth: 150,
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as DockRoutesBaseGridItem;
      return item?.customer?.cityName;
    },
  };

  static [DockRoutesGridFields.BILL_COUNT]: ColDef = {
    headerName: DockRoutesGridHeaders.BILL_COUNT,
    field: DockRoutesGridFields.BILL_COUNT,
    colId: DockRoutesGridFields.BILL_COUNT,
    headerTooltip: DockRoutesGridHeaders.BILL_COUNT,
    width: 80,
    minWidth: 80,
    sortable: true,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
  };

  static [DockRoutesGridFields.FBDS_PRINT_COUNT_CD]: ColDef = {
    headerName: DockRoutesGridFields.FBDS_PRINT_COUNT_CD,
    field: DockRoutesGridFields.FBDS_PRINT_COUNT_CD,
    colId: DockRoutesGridFields.FBDS_PRINT_COUNT_CD,
    headerTooltip: DockRoutesGridHeaders.FBDS_PRINT_COUNT_CD,
    headerComponent: 'iconHeaderRenderer',
    headerComponentParams: {
      iconName: 'print',
      tooltip: 'DR Count',
    },
    cellStyle: { justifyContent: 'center' },
    cellRenderer: 'circleCellRenderer',
    cellRendererParams: (params) => {
      const colors = [XpoColors.XPO_GREEN_450, XpoColors.XPO_YELLOW_350, XpoColors.XPO_GREY_700];
      const fbdsPrintCountCodes = ['AllPrinted', 'PartialPrints', 'NoPrints'];
      const tooltipStatuses = ['All Printed', 'Partial Prints', 'No Prints'];
      const index = fbdsPrintCountCodes.indexOf(params.value);
      return {
        color: colors[index],
        tooltip: tooltipStatuses[index],
      };
    },
    width: 50,
    minWidth: 50,
    pinned: 'left',
  };

  static [DockRoutesGridFields.PIECES_COUNT]: ColDef = {
    headerName: DockRoutesGridHeaders.PIECES_COUNT,
    field: DockRoutesGridFields.PIECES_COUNT,
    colId: DockRoutesGridFields.PIECES_COUNT,
    headerTooltip: DockRoutesGridHeaders.PIECES_COUNT,
    width: 90,
    minWidth: 90,
    sortable: true,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
  };

  static [DockRoutesGridFields.DEST_ZIP]: ColDef = {
    headerName: DockRoutesGridHeaders.DEST_ZIP,
    field: DockRoutesGridFields.DEST_ZIP,
    colId: DockRoutesGridFields.DEST_ZIP,
    headerTooltip: DockRoutesGridHeaders.DEST_ZIP,
    width: 90,
    minWidth: 90,
    sortable: true,
    cellStyle: { justifyContent: 'flex-end' },
  };

  static [DockRoutesGridFields.PALLET_COUNT]: ColDef = {
    headerName: DockRoutesGridHeaders.PALLET_COUNT,
    field: DockRoutesGridFields.PALLET_COUNT,
    colId: DockRoutesGridFields.PALLET_COUNT,
    headerTooltip: DockRoutesGridHeaders.PALLET_COUNT,
    width: 90,
    minWidth: 90,
    sortable: true,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
  };

  static [DockRoutesGridFields.WEIGHT_LBS]: ColDef = {
    headerName: DockRoutesGridHeaders.WEIGHT_LBS,
    field: DockRoutesGridFields.WEIGHT_LBS,
    colId: DockRoutesGridFields.WEIGHT_LBS,
    headerTooltip: DockRoutesGridHeaders.WEIGHT_LBS,
    width: 100,
    minWidth: 100,
    sortable: true,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
  };

  static [DockRoutesGridFields.CUBE_NBR]: ColDef = {
    headerName: DockRoutesGridHeaders.CUBE_NBR,
    field: DockRoutesGridFields.CUBE_NBR,
    colId: DockRoutesGridFields.CUBE_NBR,
    headerTooltip: DockRoutesGridHeaders.CUBE_NBR,
    width: 110,
    minWidth: 110,
    sortable: true,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
  };

  static [DockRoutesGridFields.MOTOR_MOVE_NBR]: ColDef = {
    headerName: DockRoutesGridHeaders.MOTOR_MOVE_NBR,
    field: DockRoutesGridFields.MOTOR_MOVE_NBR,
    colId: DockRoutesGridFields.MOTOR_MOVE_NBR,
    headerTooltip: DockRoutesGridHeaders.MOTOR_MOVE_NBR,
    width: 90,
    minWidth: 90,
    sortable: true,
    type: 'numericColumn',
    cellStyle: { justifyContent: 'flex-end' },
    valueFormatter: XpoAgGridFormatters.formatNumber,
  };

  static [DockRoutesGridFields.ORIGIN_SIC]: ColDef = {
    headerName: DockRoutesGridHeaders.ORIGIN_SIC,
    field: DockRoutesGridFields.ORIGIN_SIC,
    colId: DockRoutesGridFields.ORIGIN_SIC,
    headerTooltip: DockRoutesGridHeaders.ORIGIN_SIC,
    width: 100,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as DockRoutesActivityGridItem;
      return item?.originSicCd;
    },
  };

  static [DockRoutesGridFields.DEST_SIC]: ColDef = {
    headerName: DockRoutesGridHeaders.DEST_SIC,
    field: DockRoutesGridFields.DEST_SIC,
    colId: DockRoutesGridFields.DEST_SIC,
    headerTooltip: DockRoutesGridHeaders.DEST_SIC,
    width: 100,
    valueGetter: (params: ValueGetterParams) => {
      const item = params.data as DockRoutesActivityGridItem;
      return item?.destinationSicCd;
    },
  };

  static [DockRoutesGridFields.SPECIAL_SERVICES] = (specialServicesService: SpecialServicesService): ColDef => {
    return {
      headerName: DockRoutesGridHeaders.SPECIAL_SERVICES,
      field: DockRoutesGridFields.SPECIAL_SERVICES,
      colId: DockRoutesGridFields.SPECIAL_SERVICES,
      headerTooltip: DockRoutesGridHeaders.SPECIAL_SERVICES,
      width: 300,
      cellRenderer: 'specialServicesCellRenderer',
      cellRendererParams: {
        getMarks: (params): SpecialServiceMark[] =>
          specialServicesService.getSpecialServiceAppointmentMark(params.data, 'appointmentStatusCd'),
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if (nodeA.group || nodeB.group) {
          return 0;
        }
        if (valueA) {
          if (valueB) {
            return specialServicesService.getSpecialServicesComparator(nodeA, nodeB);
          } else {
            return -1;
          }
        } else {
          return 1;
        }
      },
      valueGetter: (params) => {
        if (BoardUtils.isTotalsRow(params.node)) {
          return '';
        }
        const item = params.data as DockRoutesBaseGridItem;
        return item?.specialServices;
      },
    };
  };

  static [DockRoutesGridFields.ACTIVITY_CD] = (dockActivityCdPipe: DockActivityCdPipe): ColDef => {
    return {
      headerName: DockRoutesGridHeaders.ACTIVITY_CD,
      field: DockRoutesGridFields.ACTIVITY_CD,
      colId: DockRoutesGridFields.ACTIVITY_CD,
      headerTooltip: DockRoutesGridHeaders.ACTIVITY_CD,
      width: 90,
      minWidth: 90,
      pinned: 'left',
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        const item: DockRoutesBaseGridItem = params.data;
        const activityType: DockActivityCd = item?.activityCd;
        return dockActivityCdPipe.transform(activityType);
      },
    };
  };

  static [DockRoutesGridFields.PRO_CALL_NBR] = (
    proFormatterPipe: ProFormatterPipe,
    onProOrCallNbrCallBack: (gridItem: DockRoutesBaseGridItem) => void
  ): ColDef => {
    return {
      headerName: DockRoutesGridHeaders.PRO_CALL_NBR,
      field: DockRoutesGridFields.PRO_CALL_NBR,
      colId: DockRoutesGridFields.PRO_CALL_NBR,
      headerTooltip: DockRoutesGridHeaders.PRO_CALL_NBR,
      width: 100,
      minWidth: 100,
      pinned: 'left',
      sortable: true,
      cellRenderer: 'actionLinkCellRenderer',
      cellRendererParams: { onClick: onProOrCallNbrCallBack },
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as DockRoutesBaseGridItem;
        return item?.proNbr ?? item?.callNbr;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return proFormatterPipe.transform(params.value, 10);
      },
    };
  };

  static [DockRoutesGridFields.CALL_NBR] = (
    callNbrCallBack: (gridItem: DockRoutesActivityGridItem) => void
  ): ColDef => {
    return {
      headerName: DockRoutesGridHeaders.CALL_NBR,
      field: DockRoutesGridFields.CALL_NBR,
      colId: DockRoutesGridFields.CALL_NBR,
      headerTooltip: DockRoutesGridHeaders.CALL_NBR,
      width: 90,
      cellRenderer: 'actionLinkCellRenderer',
      cellRendererParams: { onClick: callNbrCallBack },
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as DockRoutesActivityGridItem;
        return item?.callNbr;
      },
    };
  };

  static [DockRoutesGridFields.PRO] = (
    proFormatterPipe: ProFormatterPipe,
    onProNbrCallBack: (gridItem: DockRoutesActivityGridItem) => void
  ): ColDef => {
    return {
      headerName: DockRoutesGridHeaders.PRO,
      field: DockRoutesGridFields.PRO,
      colId: DockRoutesGridFields.PRO,
      headerTooltip: DockRoutesGridHeaders.PRO,
      width: 120,
      minWidth: 120,
      sortable: true,
      cellRendererSelector: (params: ICellRendererParams) => {
        let componentName = 'actionLinkCellRenderer';
        if (BoardUtils.isTotalsRow(params.node)) {
          componentName = null;
        }

        return {
          component: componentName,
          params,
        };
      },
      cellRendererParams: { onClick: onProNbrCallBack },
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as DockRoutesActivityGridItem;
        return item?.proNbr;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return proFormatterPipe.transform(params.value, 10);
      },
    };
  };

  static [DockRoutesGridFields.ADDRESS] = (
    addressClickCallback: (gridItem: DockRoutesBaseGridItem) => void
  ): ColDef => {
    return {
      headerName: DockRoutesGridHeaders.ADDRESS,
      field: DockRoutesGridFields.ADDRESS,
      colId: DockRoutesGridFields.ADDRESS,
      headerTooltip: DockRoutesGridHeaders.ADDRESS,
      width: 200,
      cellRenderer: 'actionLinkCellRenderer',
      cellRendererParams: { onClick: addressClickCallback },
      valueGetter: (params: ValueGetterParams) => {
        const item = params.data as DockRoutesBaseGridItem;
        return item?.customer?.addressLine1;
      },
    };
  };
}
