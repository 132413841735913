import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { castArray as _castArray, intersectionBy as _intersectionBy, map as _map } from 'lodash';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRole } from '../../enums/user-role/user-role.enum';
import UserRoleHelper from '../../enums/user-role/user-role.helper';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  private user: User;
  private isProduction = false;
  private roleNames = {};
  private readonly removeDomainRegExp = /.*\//gm;

  constructor(private configManagerService: ConfigManagerService) {
    this.isProduction =
      this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion) === 'local-version';
  }

  setUser(user: User): void {
    this.user = user;
  }

  getUser(): User {
    return this.user;
  }

  getUserId(): string {
    return this.user?.userId;
  }

  getEmployeeId(): string {
    return this.user?.employeeId;
  }

  // TODO: this may change based on how we define role based access!!
  isAuthorizedUser(user: User): boolean {
    return true;
  }

  hasMultipleRoles(user: User): boolean {
    return false;
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _castArray(roleOf);
    const splitUserRolesArr = _map(userRoles, (role: string) => {
      return role.replace(this.removeDomainRegExp, '');
    });
    const results = _intersectionBy(splitUserRolesArr, roleOfArray, (value) => value.toUpperCase());
    return !!results && !!results.length;
  }

  setRole(role: UserRole) {
    if (!!this.user && !this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production)) {
      const roleEnum = UserRoleHelper.toEnum(role);
      if (!!roleEnum) {
        this.user.roles.length = 0;
        _castArray(this.roleNames[roleEnum]).forEach((roleName) => this.user.roles.push(roleName));
      }
    }
  }

  getRole(): UserRole {
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.User])) {
      return UserRole.User;
    }
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.SuperUser])) {
      return UserRole.SuperUser;
    }
  }
}
