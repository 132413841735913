import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionTypes, SetSelectedDockActivities } from '@pnd-store/dock-routes-store/dock-routes.actions';
import { DockStop } from '@xpo-ltl/sdk-cityoperations';
import { Observable } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { DockRoutesService } from '../../inbound-planning/shared/services/dock-routes-services/dock-routes.service';

@Injectable()
export class DockRoutesStoreEffects {
  constructor(private actions$: Actions, private dockRoutesService: DockRoutesService) {}

  refresh$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.refresh),
      switchMap(() => this.dockRoutesService.refreshStops$()),
      concatMap((result: DockStop[]) => {
        if (!result) {
          return [new SetSelectedDockActivities({ selectedDockActivities: [] })];
        }
      })
    )
  );
}
