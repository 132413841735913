import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GeoAreaStoreSelectors } from '@pnd-store/geo-area-store';
import { SetGeoAreaGeoAreasAction } from '@pnd-store/geo-area-store/geo-area-store.actions';
import { PndStore } from '@pnd-store/pnd-store';
import { Unsubscriber, XpoLtlFeaturesService } from '@xpo-ltl/ngx-ltl';
import { GeoArea } from '@xpo-ltl/sdk-cityoperations';
import { PolygonTypeCd } from '@xpo-ltl/sdk-common';
import { NotificationMessageService, NotificationMessageStatus } from 'core';
import { isEmpty as _isEmpty, sortBy as _sortBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, finalize, take, takeUntil } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { MapUtils } from '../../classes/map-utils';
import { MapToolbarService } from '../../services';
import { GeoAreaDraw } from './classes/GeoAreaDraw';
import { GeoAreaDrawMode } from './classes/GeoAreaDrawMode';
import { GeoAreaColorService } from './services/geo-area-color.service';
import { GeoAreaDrawerService } from './services/geo-area-drawer.service';
const NOT_ALLOWED_NAME = 'a';
const AREA_TRESHOLD = 170;
const PRECISION_TRESHOLD = 10;
const NOTIFICATION_TIME = 2000;
const REFRESH_TIME = 250;
@Component({
  selector: 'pnd-geo-area-drawer',
  templateUrl: './geo-area-drawer.component.html',
  styleUrls: ['./geo-area-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoAreaDrawerComponent implements OnInit, OnDestroy {
  @ViewChild('searchField') searchField: ElementRef;
  @Input() showGeoAreaDetails: boolean;
  isCreating: boolean = false;
  private unsubscriber = new Unsubscriber();
  title$: Observable<string>;
  private readonly showSpinnerSubject = new BehaviorSubject<boolean>(false);
  showSpinner$ = this.showSpinnerSubject.asObservable();
  private geoAreaDrawListSubject = new BehaviorSubject<GeoAreaDraw[]>([]);
  searchCtrl = new UntypedFormControl();
  private googleMap: google.maps.Map;
  geoAreaDrawList$ = this.geoAreaDrawListSubject.asObservable();
  originalGeoAreaList: GeoAreaDraw[] = [];
  get geoAreaDrawList(): GeoAreaDraw[] {
    return this.geoAreaDrawListSubject.value;
  }

  constructor(
    private mapToolbarService: MapToolbarService,
    private notificationMessageService: NotificationMessageService,
    private pndStore$: PndStore<PndStoreState.State>,
    private geoAreaColorService: GeoAreaColorService,
    private geoAreaDrawerService: GeoAreaDrawerService,
    private featureService: XpoLtlFeaturesService
  ) {}

  ngOnInit(): void {
    this.subscribeToMapInstance();
    this.subscribeToGeoAreaChanges();
    this.subscribetoSearchFiltering();
    this.subscribeToGeoFilterOrDrawModeOrEditGeoAreaMode();
    this.clearAllFilters();
    this.subscribeToGeoAreaDrawerState();
  }

  private subscribeToGeoAreaDrawerState() {
    this.mapToolbarService.isGeoAreasDrawerActive$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((isVisible: boolean) => {
        if (isVisible) {
          this.notificationMessageService
            .openNotificationMessage(
              NotificationMessageStatus.Info,
              `Click on EDIT button  to create/update the Geo areas`,
              null,
              null,
              NOTIFICATION_TIME
            )
            .pipe(take(1))
            .subscribe(() => {});
        }
      });
  }

  private clearAllFilters(): void {
    this.mapToolbarService.clickOnEditGeoAreaButton$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((isEditing) => {
      if (!isEditing && this.searchCtrl.value && !_isEmpty(this.searchCtrl.value)) {
        this.searchCtrl.enable();
        this.searchCtrl.setValue('');
      }
    });
  }

  private subscribeToGeoFilterOrDrawModeOrEditGeoAreaMode(): void {
    combineLatest([this.mapToolbarService.setDrawModeState$, this.mapToolbarService.clickOnEditGeoAreaButton$])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([isDrawModeOn, isEditing]) => {
        if (isEditing) {
          isDrawModeOn = !isEditing;
        }

        this.originalGeoAreaList.forEach((geoArea) => {
          geoArea.mapPolygon?.setOptions({
            clickable: !isDrawModeOn && isEditing,
          });
          geoArea.areaLabelMaker?.setOptions({
            clickable: !isDrawModeOn && isEditing,
          });
        });
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }
  trackGeoAreaBy(index, geoArea: GeoAreaDraw): string | null {
    if (!geoArea) {
      return null;
    }
    return geoArea?.areaColor + index;
  }
  private subscribeToMapInstance() {
    this.mapToolbarService.mapInstance$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((map: google.maps.Map) => {
      this.googleMap = map;
    });
  }

  private setGeoAreaDrawList(list: GeoAreaDraw[]) {
    this.geoAreaDrawListSubject.next(list);
  }

  private subscribeToGeoAreaChanges() {
    combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic),
      this.pndStore$.select(GeoAreaStoreSelectors.geoAreaGeoAreas),
    ])
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([sicCd, geoAreaData]) => {
        const geoAreaList: GeoAreaDraw[] = [];
        geoAreaData?.geoArea?.forEach((geoArea: GeoArea) => {
          geoAreaList.push({
            ...geoArea,
            mode: GeoAreaDrawMode.VIEW,
            areaColor: this.geoAreaColorService.getColorForGeoArea(+geoArea.areaInstId),
            sicCd: geoArea?.sicCd,
          });
        });
        this.originalGeoAreaList = geoAreaList.sort((a, b) => a.geoAreaName?.localeCompare(b.geoAreaName));
        this.setGeoAreaDrawList(this.originalGeoAreaList);
        this.refreshGeoAreasPolygon(this.originalGeoAreaList);
      });
  }

  private subscribetoSearchFiltering() {
    this.searchCtrl.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscriber.done$)).subscribe((searchTerm) => {
      if (searchTerm && !_isEmpty(searchTerm)) {
        const filteredList = this.originalGeoAreaList.filter((geoArea) =>
          geoArea.geoAreaName?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        this.refreshGeoAreasPolygon(filteredList);
        this.setGeoAreaDrawList(filteredList);
      } else if (_isEmpty(searchTerm) && !this.isCreating && searchTerm !== null) {
        this.refreshGeoAreasPolygon(this.originalGeoAreaList);
        this.setGeoAreaDrawList(this.originalGeoAreaList);
      }
    });
  }

  drawNewGeoArea() {
    this.originalGeoAreaList.unshift({
      geoAreaDesc: null,
      geoAreaName: null,
      polygon: [],
      mode: GeoAreaDrawMode.CREATE,
      polygonTypeCd: PolygonTypeCd.AREA,
    } as GeoAreaDraw);
    this.setGeoAreaDrawList(this.originalGeoAreaList);
    this.mapToolbarService.setGeoAreaDrawModeState(true);
    this.searchCtrl.disable();
    this.isCreating = true;
  }

  onGeoAreaReady(newGeoArea: GeoAreaDraw) {
    const polygonPath = newGeoArea.mapPolygon
      ?.getPath()
      .getArray()
      .map((path) => {
        return { lat: path.lat(), lng: path.lng() };
      });
    const polygonCenter = this.geoAreaDrawerService.getPolygonCenter(polygonPath);
    this.originalGeoAreaList
      .filter((area) => area.areaInstId || area?.areaInstId !== newGeoArea?.areaInstId)
      .forEach((geoArea: GeoAreaDraw) => {
        const originalGeoAreaPolygonPath = geoArea?.mapPolygon
          ?.getPath()
          .getArray()
          .map((path) => {
            return { lat: path.lat(), lng: path.lng() };
          });
        const originalGeoAreaPolygonCenter = this.geoAreaDrawerService.getPolygonCenter(originalGeoAreaPolygonPath);
        const distance = (
          google.maps.geometry.spherical.computeDistanceBetween(polygonCenter, originalGeoAreaPolygonCenter) / 1000
        ).toFixed(2);
        if (+distance <= AREA_TRESHOLD) {
          newGeoArea.mapPolygon.setPath(MapUtils.difference(newGeoArea.mapPolygon, geoArea.mapPolygon));
        }
      });
    newGeoArea.mapPolygon.setPath(
      this.geoAreaDrawerService.reducerDP(
        MapUtils.reducePrecision(newGeoArea.mapPolygon, PRECISION_TRESHOLD),
        polygonPath.length * 2
      )
    );
    newGeoArea.geoAreaJson = JSON.stringify(MapUtils.polygonToGeoJson(newGeoArea.mapPolygon));
    newGeoArea.polygon = [];
    newGeoArea.mapPolygon.getPaths().forEach((paths) => {
      paths.getArray().forEach((path) => {
        newGeoArea.polygon.push({
          latitude: path.lat(),
          longitude: path.lng(),
        });
      });
    });
  }

  onGeoAreaEdit(geoArea: GeoAreaDraw) {
    if (geoArea.mode === GeoAreaDrawMode.EDIT) {
      this.searchCtrl.disable();
      this.isCreating = true;
      this.deleteAnyUnSavedAreas();
      const geoAreaToEdit = this.originalGeoAreaList.find(
        (geoAreaOriginal) => geoAreaOriginal.areaInstId === geoArea.areaInstId
      );
      geoAreaToEdit.mode = GeoAreaDrawMode.EDIT;
      this.setGeoAreaDrawList(_sortBy(this.originalGeoAreaList, 'mode'));
      this.refreshGeoAreasPolygon(this.originalGeoAreaList);
      setTimeout(() => {
        const areaCard = document.getElementById('areaCard-' + geoArea.areaInstId);
        if (areaCard) {
          areaCard.classList.add('focus');
          areaCard.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        }
      }, REFRESH_TIME);
    } else {
      // doble check if the cancelled area is on view mode otherwise change it to view mode
      const area = this.originalGeoAreaList.find((areaEdit) => areaEdit.areaInstId === geoArea.areaInstId);
      if (area && area.mode === GeoAreaDrawMode.EDIT) {
        area.mode = GeoAreaDrawMode.VIEW;
      }
      // re order the list based on mode and then by name
      this.setGeoAreaDrawList(_sortBy(_sortBy(this.originalGeoAreaList, 'geoAreaName'), 'mode'));
      this.searchCtrl.enable();
      this.isCreating = false;
    }
  }

  private deleteAnyUnSavedAreas() {
    const itemsToDelete = this.originalGeoAreaList.filter((area) => !area.areaInstId);
    if (itemsToDelete.length > 0) {
      itemsToDelete.forEach((toDelete, index) => {
        toDelete.mapPolygon?.setMap(null);
        toDelete.areaLabelMaker?.setMap(null);
        this.originalGeoAreaList.splice(index, 1);
      });
    }
  }

  private refreshGeoAreasPolygon(filteredList: GeoAreaDraw[]) {
    this.originalGeoAreaList?.forEach((geoArea) => {
      geoArea.mapPolygon?.setMap(null);
      geoArea.areaLabelMaker?.setMap(null);
    });
    filteredList?.forEach((geoArea) => {
      setTimeout(() => {
        geoArea.mapPolygon?.setMap(this.googleMap);
        geoArea.areaLabelMaker?.setMap(this.googleMap);
      }, REFRESH_TIME);
    });
  }

  onGeoAreaSaved(areaSaved: { geoArea: GeoAreaDraw; originalState: GeoAreaDraw; edited: boolean }): void {
    const originalState = {
      state: { ...areaSaved.originalState },
      index: this.originalGeoAreaList.findIndex((area) => area.areaInstId === areaSaved.geoArea.areaInstId),
    };
    const currentList = this.originalGeoAreaList.filter((geoArea) => geoArea.areaInstId);
    const exist = currentList.filter(
      (area) => areaSaved.geoArea.geoAreaName.toLocaleLowerCase() === area.geoAreaName.toLocaleLowerCase()
    );
    if (
      (exist.length > 1 && areaSaved.edited) ||
      (exist.length >= 1 && !areaSaved.edited) ||
      areaSaved.geoArea.geoAreaName.toLowerCase() === NOT_ALLOWED_NAME
    ) {
      this.notificationMessageService
        .openNotificationMessage(NotificationMessageStatus.Warn, 'The Geo Area name already exist!.')
        .pipe(take(1))
        .subscribe();
      const itemToDelete = this.originalGeoAreaList.findIndex((area) => !area.areaInstId);
      if (itemToDelete !== -1 && !areaSaved.geoArea.areaInstId) {
        this.originalGeoAreaList[itemToDelete].mapPolygon?.setMap(null);
        this.originalGeoAreaList[itemToDelete].areaLabelMaker?.setMap(null);
        this.originalGeoAreaList.splice(itemToDelete, 1);
      } else {
        originalState.state.areaLabelMaker?.setMap(null);
        originalState.state.mapPolygon?.setMap(null);
        this.originalGeoAreaList[originalState.index].areaLabelMaker?.setMap(null);
        this.originalGeoAreaList[originalState.index].mapPolygon?.setMap(null);
        this.originalGeoAreaList[originalState.index] = { ...originalState.state };
      }
      this.setGeoAreaDrawList(this.originalGeoAreaList);
      this.refreshGeoAreasPolygon(this.originalGeoAreaList);
      this.searchCtrl.enable();
      this.searchCtrl.patchValue(null);
    } else {
      this.showSpinnerSubject.next(true);
      this.searchCtrl.enable();
      this.searchCtrl.patchValue(null);
      const toEditArea = this.originalGeoAreaList.filter(
        (geoArea) => geoArea.mode === GeoAreaDrawMode.EDIT || geoArea.mode === GeoAreaDrawMode.CREATE
      );
      const toSaveList: GeoAreaDraw[] = [];
      if (toEditArea.length > 0) {
        for (const geoArea of toEditArea) {
          this.onGeoAreaReady(geoArea);
          toSaveList.push(geoArea);
        }
      }
      this.onGeoAreaReady(areaSaved.geoArea);
      toSaveList.push(areaSaved.geoArea);

      const sicCd: string = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
      this.geoAreaDrawerService
        .saveGeoAreaChanges(toSaveList, sicCd)
        .pipe(
          take(1),
          finalize(() => this.showSpinnerSubject.next(false))
        )
        .subscribe(
          (response) => {
            if (response.geoAreas.length > 0) {
              const itemIndex = this.originalGeoAreaList.findIndex((area) => !area.areaInstId);
              if (itemIndex !== -1) {
                this.originalGeoAreaList[itemIndex].areaInstId = response.geoAreas[0].areaId.toString();
              }
            } else {
              toSaveList.forEach((area) => {
                const index = this.originalGeoAreaList.findIndex((gArea) => gArea.areaInstId === area.areaInstId);
                this.originalGeoAreaList[index].areaLabelMaker?.setMap(null);
                this.originalGeoAreaList[index].mapPolygon?.setMap(null);
                this.originalGeoAreaList[index] = { ...area };
              });
            }
            this.pndStore$.dispatch(new SetGeoAreaGeoAreasAction({ geoAreas: { geoArea: this.originalGeoAreaList } }));
            this.geoAreaDrawerService.updateTheCache(this.originalGeoAreaList);
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Success, 'Geo Area Saved successfully.')
              .pipe(take(1))
              .subscribe();
          },
          (err) => {
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, err)
              .pipe(take(1))
              .subscribe();
            const itemToDelete = this.originalGeoAreaList.findIndex((area) => !area.areaInstId);
            if (itemToDelete !== -1) {
              areaSaved.geoArea.mapPolygon?.setMap(null);
              areaSaved.geoArea.areaLabelMaker?.setMap(null);
              this.originalGeoAreaList[itemToDelete].mapPolygon?.setMap(null);
              this.originalGeoAreaList[itemToDelete].areaLabelMaker?.setMap(null);
              this.originalGeoAreaList.splice(itemToDelete, 1);
            } else {
              toSaveList.forEach((area) => {
                if (originalState.state.areaInstId === area.areaInstId) {
                  originalState.state.areaLabelMaker?.setMap(null);
                  originalState.state.mapPolygon?.setMap(null);
                  this.originalGeoAreaList[originalState.index].areaLabelMaker?.setMap(null);
                  this.originalGeoAreaList[originalState.index].mapPolygon?.setMap(null);
                  this.originalGeoAreaList[originalState.index] = { ...originalState.state };
                } else {
                  const index = this.originalGeoAreaList.findIndex((gArea) => gArea.areaInstId === area.areaInstId);
                  this.originalGeoAreaList[index].areaLabelMaker?.setMap(null);
                  this.originalGeoAreaList[index].mapPolygon?.setMap(null);
                  this.originalGeoAreaList[index] = { ...area };
                }
              });
            }
            this.setGeoAreaDrawList(this.originalGeoAreaList);
            this.refreshGeoAreasPolygon(this.originalGeoAreaList);
          }
        );
    }
    this.isCreating = false;
  }

  onGeoAreaDisabled(): void {
    this.originalGeoAreaList?.forEach((geoArea) => {
      geoArea.mapPolygon?.setMap(null);
      geoArea.areaLabelMaker?.setMap(null);
    });
  }

  onGeoAreaDeleted(geoAreaDeleted: { geoArea: GeoAreaDraw; cancel: boolean }): void {
    if (geoAreaDeleted.geoArea.areaInstId) {
      this.showSpinnerSubject.next(true);
      geoAreaDeleted.geoArea.geoAreaJson = undefined;
      const sicCd: string = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);

      this.geoAreaDrawerService
        .saveGeoAreaChanges([geoAreaDeleted.geoArea], sicCd)
        .pipe(
          take(1),
          finalize(() => {
            this.showSpinnerSubject.next(false);
            this.searchCtrl.enable();
            this.searchCtrl.patchValue(null);
          })
        )
        .subscribe(
          () => {
            geoAreaDeleted.geoArea.mapPolygon?.setMap(null);
            geoAreaDeleted.geoArea.areaLabelMaker?.setMap(null);
            const itemToDelete = this.originalGeoAreaList.findIndex(
              (area) => area.areaInstId === geoAreaDeleted.geoArea.areaInstId
            );
            if (itemToDelete !== -1) {
              this.originalGeoAreaList.splice(itemToDelete, 1);
            }
            this.pndStore$.dispatch(new SetGeoAreaGeoAreasAction({ geoAreas: { geoArea: this.originalGeoAreaList } }));
            this.geoAreaDrawerService.updateTheCache(this.originalGeoAreaList);
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Success, 'Geo Area deleted successfully.')
              .pipe(take(1))
              .subscribe();
          },
          (err) =>
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, err)
              .pipe(take(1))
              .subscribe()
        );
    } else {
      geoAreaDeleted.geoArea.mapPolygon?.setMap(null);
      geoAreaDeleted.geoArea.areaLabelMaker?.setMap(null);
      const itemToDelete = this.originalGeoAreaList.findIndex((area) => !area.areaInstId);
      if (itemToDelete !== -1) {
        this.originalGeoAreaList.splice(itemToDelete, 1);
      }
      this.setGeoAreaDrawList(this.originalGeoAreaList);
      this.refreshGeoAreasPolygon(this.originalGeoAreaList);
      if (geoAreaDeleted.cancel) {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Info, 'Geo Area edition/creation canceled.')
          .pipe(take(1))
          .subscribe();
      } else {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Success, 'Geo Area deleted successfully.')
          .pipe(take(1))
          .subscribe();
      }
      this.searchCtrl.enable();
      this.searchCtrl.patchValue(null);
    }
    this.isCreating = false;
  }
}
